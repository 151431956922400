<template>
  <whatsapp-warmup v-if="!isWhatsappAllowed" />
  <div
    v-else
    class="main-content"
  >
    <div class="container-fluid">
      <header-component
        title="Omnichannel"
      >
        <template #buttons>
          <div
            v-if="!loadingFlags.fetchGroupChannels"
            class="d-flex align-items-center"
          >
            <connections-tooltip
              :licenses="user.company.whatsapp_licenses"
            />
            <div class="ms-4">
              <app-button
                v-if="
                  isManager &&
                    user.company.whatsapp_licenses > totalActive
                "
                style="min-width: 312px"
                color="btn-gray-blue"
                class="me-4"
                @click="this.$router.push('/manager/whatsapp/new')"
                data-test="newConnection-button"
              >
                {{ $t('new_group_channels') }}
              </app-button>
              <app-button
                v-if="
                  isSupervisorOrManager &&
                    user.company.whatsapp_licenses > totalActive 
                "
                style="min-width: 312px"
                @click="this.$router.push('/whatsapp')"
              >
                <i class="fa-regular fa-messages me-1" />
                {{ $t('access_chats') }}
              </app-button>
            </div>
          </div>
        </template>
      </header-component>
      <list-metrics />
      <list-connections />
      <list-agents />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

import WhatsappWarmup from "./whatsapp-warmup.vue";
import ConnectionsTooltip from "./components/connections-tooltip.vue";
import HeaderComponent from "@/components/header-component.vue";
import ListMetrics from "@whatsapp/manager/pages/dashboards/connection/components/list-metrics.vue";
import ListConnections from "@whatsapp/manager/pages/dashboards/connections-list/components/list-connections.vue";
import ListAgents from "@whatsapp/manager/pages/dashboards/connection/components/list-agents.vue";
import AppButton from "@components/app-button.vue";

export default {
  components: {
    AppButton,
    ListMetrics,
    ListConnections,
    ListAgents,
    ConnectionsTooltip,
    HeaderComponent,
    WhatsappWarmup
  },

  data() {
    return {
      connectionsTooltip: null,
    };
  },

  created() {
    this.fetchWhatsappDashboard()
    localStorage.removeItem("instanceId");
  },

  computed: {
    ...mapGetters("manager/whatsapp", {
      loadingFlags: "getLoadingFlags",
      totalActive: "getTotalActiveInstances",
    }),
    ...mapGetters("whatsapp/instance", {
      groupChannel: "getGroupChannel"
    }),

    ...mapGetters("auth", {
      user: "getUser",
      isManager: "isManager",
      isSupervisorOrManager: "isSupervisorOrManager",
      isWhatsappAllowed: "isWhatsappAllowed"
    })
  },

  methods: {
    ...mapActions("manager/whatsapp", ["fetchWhatsappDashboard"]),
  },
};
</script>
