<template>
  <div class="main-content pb-6 ">
    <div class="container-fluid ">
      <div class="row justify-content-center ">
        <div class="col-12">
          <header-component
            :pretitle="$t('settings_uppercase')"
            :title="$t('office_hours_capitalize')"
          >
            <template #buttons>
              <router-link
                class="header-button btn btn-primary float-end px-6"
                to="/manager/office-hours/new"
              >
                {{ $t("add_new_office_hour") }}
              </router-link>
            </template>
          </header-component>
          <flash-message class="mb-5" />

          <div class="card">
            <div class="card-header ">
              <search-Input @search="search" />
            </div>

            <div class="card-body p-0">
              <table class="table app-datatable my-0">
                <thead>
                  <tr>
                    <th>{{ $t("name_data") }}</th>
                    <th>
                      {{ $t("days_capitalize") }}
                    </th>
                    <th />
                  </tr>
                </thead>

                <tbody>
                  <template v-if="loading">
                    <loading-table-component
                      :columns="3"
                      :lines="15"
                      :list-width="[150, 50, 50]"
                      :text-center="false"
                    />
                  </template>

                  <tr v-else-if="!officeHours.length">
                    <td
                      class="text-center text-muted"
                      colspan="3"
                    >
                      <span class="fe fe-alert-octagon" /> {{ $t("no_office_hour") }}
                    </td>
                  </tr>

                  <tr
                    v-for="(item, i) of officeHours"
                    v-else
                    :key="i"
                    class="cursor-pointer"
                    @click="$router.push(`/manager/office-hours/${item.id}/edit`)"
                    @mouseleave="unSelectItem()"
                    @mouseover="selectItem(item.id)"
                  >
                    <td>{{ item.name }}</td>
                    <td>
                      {{ item.hours_items.length }}
                    </td>

                    <td class="text-end">
                      <i
                        :style="{
                          visibility:
                            item.id == selectedItem ? 'visible' : 'hidden'
                        }"
                        class="fa-regular fa-chevron-right text-primary"
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div
              v-if="!loading"
              class="card-footer"
            >
              <div class="col-lg-12">
                <paginator
                  v-if="!loading"
                  :pagination="pagination"
                  @change-page="changePage"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import paginator from "@/components/app-paginator";
import flashMessage from "@/components/flash-message";
import LoadingTableComponent from "@dialer/manager/components/loading-table-component.vue";
import HeaderComponent from "@/components/header-component.vue";

import searchInput from "@/components/search-input";
import _ from "lodash";

export default {
  components: {
    paginator,
    flashMessage,
    searchInput,
    LoadingTableComponent,
    HeaderComponent
  },

  data() {
    return {
      selectedItem: false
    };
  },

  mounted() {
    this.setFilters({
      search: ""
    });
    this.findOfficeHours();
  },

  computed: {
    ...mapGetters("manager/office_hours", {
      officeHours: "getOfficeHours",
      pagination: "getPagination",
      loading: "isLoading",
      params: "getParams"
    }),
    ...mapGetters("auth", {
      user: "getUser"
    })
  },

  methods: {
    ...mapActions("manager/office_hours", ["findOfficeHours", "getSoundFile"]),
    ...mapMutations("manager/office_hours", ["setFilters", "setCurrentPage"]),
    ...mapMutations("system", ["setFlashMessage"]),

    getFullAudioPath(audio_path) {
      return process.env.VUE_APP_BA12AS.replace("/api/v1", "") + audio_path + "&api_token=" + this.user.api_token;
    },

    changePage(page) {
      this.setCurrentPage(page);
      this.findOfficeHours();
    },

    search: _.debounce(function(term) {
      this.setFilters({
        search: term
      });
      this.setCurrentPage(1);
      this.findOfficeHours();
    }, 300),

    selectItem(item) {
      this.selectedItem = item;
    },

    unSelectItem() {
      this.selectedItem = false;
    }
  }
};
</script>
