<template>
  <div
    :class="step.order === 0 ? 'bg-light' : 'bg-default'"
    class="card rounded-4"
  >
    <div
      :style="{backgroundColor: step.color}"
      class="w-100 rounded-3"
      style="height: 8px"
    />
    <div
      class="card-header card-header-flush"
    >
      <div
        v-if="isEditingStep"
        class="row"
      >
        <div class="col-8">
          <div class="input-group">
            <input
              v-model="stepName"
              :placeholder="$t('column_name')"
              autofocus
              class="form-control"
              type="text"
            >
          </div>
        </div>
        <div class="col-4 px-0">
          <color-picker-dropdown
            v-model="stepColor"
            class="col"
          />
        </div>
        <div class="col-6 pe-0 d-flex w-100 justify-content-center align-items-center gap-3 mt-3">
          <button
            class="btn w-50 cancel-btn"
            @click="closeDropdown"
          >
            {{ $t('cancel_general') }}
          </button>
          <app-button
            size="w-50"
            @click="confirmEditStep"
          >
            {{ $t('save') }}
          </app-button>
        </div>
      </div>

      <div
        v-else
        :class="step?.order === 0 ? 'cursor-not-allowed' : 'cursor-grab'"
        class="d-flex align-items-center justify-content-between w-100 column-drag-handler"
      >
        <h4
          class="mb-0 text-truncate"
          :title="step?.name"
        >
          {{ step?.name }}
        </h4>
        <div
          class="d-flex align-items-center"
          style="gap: 8px"
        >
          <step-dropdown
            v-show="step.order > 0 && isManager"
            :disable-move-next-option="step.order +1 < totalSteps"
            :disable-move-prev-option="step.order === 1"
            :step-id="step.id"
            @edit-step="editStep"
            @remove-step="removeStep"
            @move-next="moveNext"
            @move-prev="movePrev"
          />
          <button
            v-show="isManager"
            ref="newOpportunityTooltip"
            class="btn new-opportunity-btn d-flex justify-content-center align-items-center"
            @click="newOpportunity"
          >
            <i class="fa-regular fa-plus tx-text-gray" />
          </button>
        </div>
      </div>
    </div>
    <div
      class="card-body h-100"
      style="padding: 0 6px 100px 8px;"
    >
      <div
        class="d-flex justify-content-between flex-wrap gap-3 w-100"
        style="padding: 0 8px 16px 8px;"
      >
        <small
          v-if="opportunitiesPagination[step.id]?.total > 0 "
          class="text-truncate col fs-6"
        >
          {{ opportunitiesPagination[step?.id].total }}
          {{ opportunitiesPagination[step?.id].total === 1 ? $t('opportunity') : $t('opportunities') }}
        </small>
        <small
          v-else
          class="text-truncate col fs-6"
        >
          {{ $t('no_opportunity') }}
        </small>
        <custom-tooltip-component
          v-if="step"
          :items="stepAmounts"
          placement="bottom"
        >
          <template #activator>
            <small class="fs-6 fw-medium cursor-pointer">{{ formatCurrency(parseFloat(step.stepTotal || 0)) }}</small>
          </template>
        </custom-tooltip-component>
      </div>
      <slot
        v-if="step"
        :opportunities="opportunities[step.id]"
        class="position-relative"
        name="body"
      />
    </div>
  </div>
</template>

<script>
import stepDropdown from './step-dropdown.vue';
import {mapActions, mapGetters} from "vuex";
import ColorPickerDropdown from "@components/color-picker-dropdown.vue";
import AppButton from "@components/app-button.vue";
import {generateTooltip, Utils} from "@/utils";
import CustomTooltipComponent from "@components/custom-tooltip-component.vue";

export default {
  components: {
    CustomTooltipComponent,
    AppButton,
    ColorPickerDropdown,
    stepDropdown
  },

  emits: ['newOpportunity'],

  props: {
    step: {
      type: Object,
      default: null
    },
    funnelId: {
      type: Number,
      default: null
    },
    totalSteps: {
      type: Number,
      default: 0
    },
  },

  data() {
    return {
      isEditingStep: false,
      stepName: '',
      stepColor: '',
      newOpportunityTooltip: null,
    };
  },

  mounted() {
    this.newOpportunityTooltip = this.generateTooltip(this.$t('new_opportunity'), this.$refs.newOpportunityTooltip, 'bottom');
  },

  computed: {
    ...mapGetters("auth", {
      isManager: "isManager",
    }),
    ...mapGetters("crm/opportunities", {
      opportunities: "getOpportunities",
      opportunitiesPagination: "getOpportunitiesPagination",
    }),

    stepAmounts() {
      if (!this.step.product_values) return [];
      return [
        {label: 'unique', value: this.step.product_values.unique},
        {label: 'recurrent', value: this.step.product_values.recurrent},
        {label: 'average_ticket', value: this.step.product_values.average},
        {label: 'max_value', value: this.step.product_values.max},
        {label: 'min_value', value: this.step.product_values.min},
      ]
    }
  },

  methods: {
    formatCurrency: Utils.formatCurrency,
    generateTooltip,
    ...mapActions('crm/kanban', [
      'updateStep',
      'deleteStep',
      'moveSteps'
    ]),

    closeDropdown() {
      this.isEditingStep = false
      this.stepName = ''
      this.stepColor = ''
    },

    moveNext() {
      this.moveSteps({
        removedIndex: this.step.order,
        addedIndex: this.step.order + 1,
        funnel_id: this.step.funnel_id,
        step_id: this.step.id
      })
    },

    movePrev() {
      this.moveSteps({
        removedIndex: this.step.order,
        addedIndex: this.step.order - 1,
        funnel_id: this.step.funnel_id,
        step_id: this.step.id
      })
    },

    editStep() {
      this.stepName = this.step.name
      this.stepColor = this.step.color
      this.isEditingStep = true
    },

    confirmEditStep() {
      this.updateStep({
        funnel_id: this.funnelId,
        name: this.stepName,
        color: this.stepColor,
        order: this.step.order,
        step_id: this.step.id
      }).then(() => {
        this.closeDropdown()
      })
    },

    removeStep() {
      this.deleteStep({
        step_id: this.step.id,
        funnel_id: this.funnelId
      })
    },

    newOpportunity() {
      this.newOpportunityTooltip.hide();
      this.$emit('newOpportunity');
    }
  }
};
</script>

<style lang="scss" scoped>
.cursor-grab {
  cursor: grab;
}

.cursor-not-allowed {
  cursor: not-allowed;
}

.card-header {
  height: auto;
  padding: 16px;
}

.cancel-btn, .btn-primary {
  font-size: 12px;
}

.cancel-btn {
  background-color: $color-gray-blue-300;
  color: $color-text-gray;

  &:hover {
    background-color: $color-gray-200;
  }
}

.bg-default {
  background-color: $color-gray-blue-100;
  box-shadow: none;
  border: none;
}

.new-opportunity-btn {
  width: 32px;
  height: 32px;
  background-color: $color-gray-blue-300;

  &:hover {
    background-color: $color-gray-200;
  }
}
</style>
