<template>
  <div class="card">
    <div class="card-header">
      <h4 class="m-0">
        {{ $t('list_abstract') }}
      </h4>
    </div>
    <div class="card-body">
      <div class="row align-items-center">
        <div class="col-8">
          <ul class="list-group list-group-flush">
            <li class="list-group-item d-flex align-items-center justify-content-between py-1 px-0">
              <span>{{ $t('correctly_filled') }}:</span>
              <div class="alert whatsapp-tag green mb-0">
                {{ validationCsv.success_count }}
                <i class="fa-regular fa-circle-check" />
              </div>
            </li>
            <li class="list-group-item d-flex align-items-center justify-content-between py-1 px-0">
              <span>{{ $t('with_empty_fields') }}:</span>
              <div
                class="alert whatsapp-tag mb-0"
                :class="[validationCsv.required_error_count ? 'red' : 'blue']"
              >
                {{ validationCsv.required_error_count }}
                <i class="fa-regular fa-circle-xmark" />
              </div>
            </li>
            <li class="list-group-item d-flex align-items-center justify-content-between py-1 px-0">
              <span>{{ $t('contacts_with_actives_opportunities') }}:</span>
              <div
                class="alert whatsapp-tag mb-0"
                :class="[validationCsv.already_inserted_error_count ? 'red' : 'blue']"
              >
                {{ validationCsv.already_inserted_error_count }}
                <i class="fa-regular fa-circle-xmark" />
              </div>
            </li>
            <li class="list-group-item d-flex align-items-center justify-content-between py-1 px-0">
              <span>{{ $t('incorrect_phones') }}:</span>
              <div
                class="alert whatsapp-tag mb-0"
                :class="[validationCsv.incorrect_phone_error_count ? 'red' : 'blue']"
              >
                {{ validationCsv.incorrect_phone_error_count }}
                <i class="fa-regular fa-circle-xmark" />
              </div>
            </li>
          </ul>
        </div>
        <div class="col-4">
          <div class="alert whatsapp-tag blue p-4 m-0">
            <div class="d-flex gap-3 align-items-center">
              <i class="fa-regular fa-warning" />
              <h4 class="m-0">
                {{ $t('attention') }}
              </h4>
            </div>
            <div style="margin-top: 1rem">
              {{
                $t('contacts_with_errors') }}<strong>{{ $t('contacts_correctly_filled') }}</strong>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "ListStatusCard",

  computed: {
    ...mapGetters('crm/imports', {
      validationCsv: "getValidationCsv",
    }),
  }
}
</script>

<style scoped lang="scss">
.whatsapp-tag{
  padding: 0.5rem;
  &.green {
    .fa-regular,i {
      color: #015d0d !important;
    }
  }
  &.red {
    .fa-regular,i {
      color: #920000 !important;
    }
  }
  &.blue {
    .fa-regular,i {
      color: #1a4a89 !important;
    }
  }
}
</style>
