import { AgentStatus } from "@/utils";
import { ref } from "vue";

function createAgent(agent) {
  const agentData = ref(agent);
  agentData.value.metrics = initializeMetrics(agentData.value.metrics);
  agentData.value.metrics.speaking = calculateSpeakingTime(agentData.value.metrics);
  agentData.value.total_time = calculateTotalTime(agentData.value.metrics);
  agentData.value.timer = incrementMetrics(agentData.value);
  return agentData;
}

function initializeMetrics(metrics) {
  if (!metrics || !Object.values(metrics).length) {
    return {
      acw: 0,
      calls: 0,
      idle: 0,
      interval: 0,
      interval_quantity: 0,
      manual: 0,
      manual_acw: 0,
      manual_calls: 0,
      manual_calls_acw: 0,
      manual_calls_acw_answered: 0,
      manual_calls_acw_calling: 0,
      manual_calls_acw_made: 0,
      manual_calls_acw_speaking: 0,
      manual_calls_answered: 0,
      manual_calls_calling: 0,
      manual_calls_made: 0,
      manual_calls_speaking: 0,
      speaking: 0,
      _id: 0
    };
  }
  return metrics;
}

function calculateSpeakingTime(metrics) {
  return metrics.speaking + metrics.manual_calls_acw_speaking + metrics.manual_calls_speaking;
}

function calculateTotalTime(metrics) {
  return (
    Number(metrics.idle) +
    Number(metrics.speaking) +
    Number(metrics.manual_calls_acw_speaking) +
    Number(metrics.manual_calls_speaking) +
    Number(metrics.acw) +
    Number(metrics.manual_acw) +
    Number(metrics.manual) +
    Number(metrics.interval)
  );
}

function incrementMetrics(agent) {
  const { status } = agent;
  if (status !== AgentStatus.OFFLINE) {
    return setInterval(() => {
      agent.total_time++;
      status === AgentStatus.IDLE && agent.metrics.idle++;
      [AgentStatus.ON_CALL, AgentStatus.ON_MANUAL_CALL_CONNECTED, AgentStatus.MANUAL_CALL_CONNECTED].includes(status) &&
        agent.metrics.speaking++;
      status === AgentStatus.ACW && agent.metrics.acw++;
      status === AgentStatus.ON_MANUAL_CALL_ACW && agent.metrics.manual_acw++;
      status === AgentStatus.ON_MANUAL_CALL && agent.metrics.manual++;
      status === AgentStatus.ON_WORK_BREAK && agent.metrics.interval++;
    }, 1000);
  }
}

export default {
  startLoadingFlag: (state, flag) => (state.loadingFlags[flag] = true),
  stopLoadingFlag: (state, flag) => (state.loadingFlags[flag] = false),
  setAgentsFilters: (state, filters) => state.agentsFilters = filters,
  setAgentsPagination: (state, agentsPagination) => (state.agentsPagination = agentsPagination),
  setAgentsCurrentPage: (state, page) => state.agentsPagination.current_page = page,
  setAgents: (state, agents) => {
    state.agents = agents.reduce((agentsById, agent) => {
      agentsById[agent.id] = createAgent(agent);
      return agentsById;
    }, {});
  },

  updateAgentOnline: (state, event) => {
    const { agent, work_break, mailing, campaignId, work_break_group } = event;
    const status = agent.status || agent.agent_status.manual_call_status || agent.agent_status.status;

    if (work_break) {
      state.agents[agent.id].work_break = work_break;
    }

    if (mailing) {
      state.agents[agent.id].mailing = mailing.data;
    }

    if  (campaignId) {
      state.agents[agent.id].logged_campaign = campaignId;
    }

    if (work_break_group) {
      state.agents[agent.id].work_break_group = work_break_group;
    }

    state.agents[agent.id].status = status;
    clearInterval(state.agents[agent.id].timer);
    state.agents[agent.id].timer = incrementMetrics(state.agents[agent.id]);
  },

  incrementCallsAndConvertedMetrics: (state, { agent, qualification }) => {
    state.agents[agent.id].total_calls++;
    if (qualification.conversion) {
      state.agents[agent.id].converted_metrics++;
    }
  },

  clearAgents: state => {
    Object.values(state.agents).forEach(agent => {
      clearInterval(agent.timer);
    });
    state.agents = {};
  },

  setWorkBreakExitPending: (state, { id, work_break_exit_pending }) => {
    state.agents[id].work_break_exit_pending = work_break_exit_pending;
  },
};
