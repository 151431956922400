<template>
  <div v-if="protocolDetails">
    <div
      :class="{ 'is-close': activeMap[protocolDetails.id] }"
      class="protocol-item w-100 bg-white tx-text-200"
      @click="openProtocol(protocolDetails.id)"
    >
      <div
        class="d-flex justify-content-between"
      >
        <h5 style="margin-bottom: 4px;">
          {{ protocolDetails.protocol_number }}
        </h5>
        <small>{{ formatTimestamp(protocolDetails.start_time, 'DD/MM') }}</small>
      </div>
      <div class="d-flex justify-content-between align-items-center">
        <div>
          <small
            v-if="protocolDetails.end_time == 'Em andamento'"
            class="me-2"
          >{{ $t('in_progress') }}</small>
          <small
            v-else
            class="me-2"
          >{{ $t('finalized') }}</small>
          <DotStatus
            :color="'#677C92'"
            :size="'4px'"
          />
          <small class="ms-2">{{ protocolDetails.agent_name }}</small>
        </div>
        <div
          v-if="loading.findAnchor && loadingRef === protocolDetails.protocol_number"
          class="spinner-border text-primary spinner-border-sm"
          role="status"
        >
          <span class="visually-hidden">Loading...</span>
        </div>
        <i
          v-else
          class="fa-solid fa-chevron-down"
        />
      </div>
    </div>
    <div
      :class="{ 'is-close': !activeMap[protocolDetails.id] }"
      class="card protocol-card w-100 m-0"
      @click="openProtocol(protocolDetails.id)"
    >
      <div class="d-flex justify-content-between">
        <h5 style="margin-bottom: 4px;">
          {{ protocolDetails.protocol_number }}
        </h5>
        <small>{{ formatTimestamp(protocolDetails.start_time, 'DD/MM') }}</small>
      </div>
      <div class="d-flex justify-content-between align-items-center">
        <div>
          <small
            v-if="protocolDetails.end_time == 'Em andamento'"
            class="me-2"
          >{{ protocolDetails.end_time }}</small>
          <small
            v-else
            class="me-2"
          >{{ $t('finalized') }}</small>
          <dot-status
            :color="'#677C92'"
            :size="'4px'"
          />
          <small class="ms-2">{{ protocolDetails.agent_name }}</small>
        </div>
        <i class="fa-solid fa-chevron-up" />
      </div>
      <hr>
      <div class="d-flex justify-content-between">
        <h6 class="fw-normal">
          {{ $t('protocol') }}
        </h6>
        <h6 class="fw-bold">
          {{ protocolDetails.protocol_number }}
        </h6>
      </div>
      <div class="d-flex justify-content-between">
        <h6 class="fw-normal">
          {{ $t('agent_capitalize') }}
        </h6>
        <h6 class="fw-bold">
          {{ protocolDetails.agent_name }}
        </h6>
      </div>
      <div class="d-flex justify-content-between">
        <h6 class="fw-normal">
          {{ $t('start') }}
        </h6>
        <h6 class="fw-bold">
          {{ formatTimestamp(protocolDetails.start_time, 'DD/MM/YYYY') }} às
          {{ formatTimestamp(protocolDetails.start_time, 'HH:mm') }}
        </h6>
      </div>
      <div class="d-flex justify-content-between">
        <h6 class="fw-normal">
          {{ $t('status_omni') }}
        </h6>
        <h6
          v-if="protocolDetails.end_time == 'Em andamento'"
          class="fw-bold"
        >
          {{ $t('in_progress') }}
        </h6>
        <h6
          v-else
          class="fw-bold"
        >
          {{ $t('finalized') }}
        </h6>
      </div>
      <button
        :disabled="loading.findAnchor"
        class="btn btn-protocol btn-primary d-flex justify-content-center align-items-center gap-2"
        type="button"
        @click="searchTargetProtocol"
      >
        <span
          v-if="loading.findAnchor && loadingRef === protocolDetails.protocol_number"
          aria-hidden="true"
          class="spinner-border spinner-border-sm"
        />
        <i
          v-else
          class="fa fa-chevron-right me-2"
        />
        <small class="d-flex justify-content-center">
          {{ $t('access') }}
        </small>
      </button>
    </div>
  </div>
</template>
<script>
import {mapActions, mapGetters, mapMutations} from "vuex";
import {formatTimestamp} from "@/utils";
import {ref} from "vue";
import DotStatus from "@/components/dot-status.vue";

export default {
  props: {
    protocolDetails: {type: Object, default: null},
  },

  components: { DotStatus },

  data () {
    return {
      activeMap: ref({}),
      loadingRef: null
    }
  },
  computed: {
    ...mapGetters("whatsapp/chat", {
      protocol: "getProtocol",
      currentChatData: "getCurrentChat",
      protocolFound: "getProtocolFound",
      loadMessagesContext: "getLoadMessagesContext",
      loading: "getLoadingFlags"
    }),

    currentChat () {
      return this.currentChatData[this.protocolDetails.chat_id]
    }
  },
  methods: {
    formatTimestamp,
    ...mapActions("whatsapp/chat", [
      "findProtocol",
      "addMessagesToChat",
      "findMessagesHistoricByNumber",
      "searchOldRecords"
    ]),

    ...mapMutations("whatsapp/message", ["setDisableAutoScroll"]),

    ...mapMutations("whatsapp/chat", [
      "setLoadMessagesContext",
      "startLoading",
      "stopLoading"
    ]),

    openProtocol (index){
      if (!this.loading.findAnchor)
        this.activeMap[index] = !this.activeMap[index]
    },

    async searchTargetProtocol (){
      this.startLoading('findAnchor')
      this.loadingRef = this.protocolDetails.protocol_number
      await this.findProtocol({
        chat_id: this.currentChat.id,
        protocol_number: this.protocolDetails.protocol_number,
        pagination: this.currentChat.pagination
      }).then(async() => {
        const initialPage = this.currentChat.sumTotalPages - this.currentChat.pagination.total_pages + 1
        if(this.protocolFound.context === "historic"){
          if(this.loadMessagesContext === "current"){
            this.setLoadMessagesContext("historic")
            await this.callCurrentPages(initialPage)
            await this.callHistoricPages(1);
          } else if(this.loadMessagesContext === "historic"){
            await this.callHistoricPages(initialPage);
          }
        } else if(this.protocolFound.context === "current"){
          await this.callCurrentPages(this.currentChat.pagination.current_page)
        }
      }).catch(() => {
        this.loadingRef = null
      }).finally(() => {
        this.stopLoading('findAnchor')
      })
    },

    async callCurrentPages (inicialPage){
      if(this.currentChat.pagination.current_page < this.currentChat.pagination.total_pages){
        for (let i = inicialPage; i <= this.protocolFound.page; i++){
          this.searchOldRecords({
            page: i,
            instance_id: this.protocolFound.instance_id,
            chat_id: this.$route.params.id
          }).then(() => {
            if(this.currentChat.stashedMessages){
              this.setDisableAutoScroll(true);
              this.addMessagesToChat({
                messages: this.currentChat.stashedMessages,
                chat_id: this.$route.params.id
              })
            }
          });
        }
      }
      this.scrollToProtocolAnchor()
    },

    async callHistoricPages (inicialPage){
      if(this.currentChat.pagination.current_page <= this.currentChat.sumTotalPages){
        for (let i = inicialPage; i <= this.protocolFound.page + 1; i++){
          await this.findMessagesHistoricByNumber({
            number: this.currentChat.number,
            page: i,
            instance_id: this.protocolFound.instance_id,
            chat_id: this.$route.params.id
          })
          if(this.currentChat.stashedMessages){
            this.setDisableAutoScroll(false);
            this.addMessagesToChat({
              messages: this.currentChat.stashedMessages,
              chat_id: this.$route.params.id
            })
          }
        }
      }
      this.scrollToProtocolAnchor()
    },
    scrollToProtocolAnchor (){
      const findProtocol = setTimeout(() => {
        if(this.protocolFound && document.getElementById(this.protocolFound.id)){
          this.stopLoading('findAnchor')
          this.loadingRef = null
          document.getElementById(this.protocolFound.id).scrollIntoView({
            behavior: "smooth",
            block: "center",
            inline: "nearest"
          })
          if(document.getElementById(this.protocolFound?.id)){
            clearInterval(findProtocol)
          }
        }
        this.stopLoading('findAnchor')
      }, 500)
    }
  }
};
</script>
<style lang="scss" scoped>
li:last-child {
  border-color: #fff !important;
}

.protocol-item {
  padding: $whatsapp-spacing-1;
  border-radius: $whatsapp-border-radius !important;

  .fa-chevron-down {
    display: none;
  }

  &:hover, &:active {
    background-color: $color-gray-200 !important;

    .fa-chevron-down {
      display: inline-block;
      color: $color-blue-300;
      font-size: 17px;
    }
  }
}

.protocol-card {
  padding: 8px !important;
  background-color: #fff !important;
  color: #373753 !important;

  .fa-chevron-up {
    color: $color-blue-300 !important;
    font-size: 17px;
  }

  .btn-protocol {
    border: 1px solid $color-blue-300 !important;
    height: 32px !important;
    border-radius: $whatsapp-border-radius !important;
    color: $color-blue-300 !important;
    background-color: #fff !important;
    margin-top: 16px !important;

    &:hover {
      background-color: #5778F5 !important;
    }
  }
}

.is-close {
  display: none;
}
</style>
