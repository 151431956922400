<template>
  <div class="card rounded opportunity-card">
    <div class="card-body">
      <div
        class="d-flex gap-4"
        style="margin-bottom: 1rem"
      >
        <card-loading
          v-if="isLoading"
          :height="49"
          margin-bottom="0"
          class="w-50"
        />
        <div
          v-else
          class="col"
        >
          <button
            v-if="!isUpdatingName"
            @click="toggleUpdateName(true)"
            class="contact-name fs-2 fw-medium w-100 text-start bg-white"
          >
            {{ name || $t('opportunity_name') }}
          </button>
          <input
            v-else
            v-model="name"
            autofocus
            @blur="changeName()"
            :aria-label="$t('opportunity_name')"
            class="contact-name rounded border fs-2 fw-medium w-100"
            :placeholder="$t('opportunity_name')"
            type="text"
          >
          <div
            style="padding: 0 0.5rem"
            class="invalid-feedback d-block"
          >
            {{ errors && errors[0] }}
          </div>
        </div>
      </div>
      <card-loading
        v-if="isLoading"
        :height="28"
        margin-bottom="0"
      />
      <opportunity-stepper
        v-else
        @step-selected="selectStep"
        :enable-choice="enableChoice"
      />

      <div class="d-flex align-items-end mt-4">
        <div
          v-if="enableProducts"
          class="col-6"
        >
          <card-loading
            v-if="isLoading"
            :height="29"
            margin-bottom="0"
          />
          <opportunity-product-indicator
            v-else
            :opportunity-status="opportunity.status"
          />
        </div>


        <div

          :class="enableProducts? 'col-6' : 'col-12'"
        >
          <card-loading
            class="w-50 ms-auto"
            v-if="isLoading"
            :height="29"
            margin-bottom="0"
          />
          <span
            v-else
            class="tx-text-200 text-end d-block"
          >
            {{ currentFunnel.name }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import OpportunityStepper from "@crm/pages/opportunity/components/opportunity-stepper.vue";
import {mapGetters} from "vuex";
import OpportunityProductIndicator from "@crm/pages/opportunity/components/opportunity-product-indicator.vue";
import CardLoading from "@components/card-loading.vue";

export default {
  name: 'OpportunityCard',
  components: {
    CardLoading,
    OpportunityProductIndicator,
    OpportunityStepper
  },
  emits: [
    'selectStep',
    'name'
  ],

  props: {
    errors: {
      type: Array,
      default: () => []
    },

    enableChoice: {
      type: Boolean,
      default: false
    },

    enableProducts: {
      type: Boolean,
      default: false
    },

    isLoading: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      name: null,
      selectedStep: null,
      isUpdatingName: false,
    }
  },

  computed: {
    ...mapGetters("crm/opportunities", {
      opportunity: "getOpportunity",
    }),
    ...mapGetters("crm/funnels", {
      currentFunnel: "getCurrentFunnel",
    }),
  },

  methods: {
    selectStep(id) {
      this.$emit("selectStep", id)
    },

    changeName() {
      this.$emit('name', this.name);
      this.toggleUpdateName(false);
    },

    toggleUpdateName(toggle) {
      this.isUpdatingName = toggle;
    }
  },

  watch: {
    opportunity() {
      this.name = this.name? this.name : this.opportunity.name;
    }
  }
}
</script>

<style scoped lang="scss">
.opportunity-card {
  margin-top: 1rem;
  box-shadow: 0 12px 24px 0 rgba(18, 38, 63, 0.03);
  border-color: #E1E9F4
}

.contact-name {
  appearance: none;
  border: none;
  outline: none;
  color: #1B1B2B;
  padding: 0.5rem 0;


  &[type='text'] {
    padding: 0.5rem;
  }

  &::placeholder {
    color: #1B1B2B;
    opacity: 1;
  }

  &::-ms-input-placeholder {
    color: #1B1B2B;
  }
}

.cards-container {
  max-height: 80vh;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    display: none !important;
  }
}

.loading-card {
  margin-bottom: 0;
}
</style>