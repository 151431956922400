<template>
  <edit-contact
    v-if="currentChat && !chatIsGroup"
    :contact-id="currentChat.contact?.id"
    :show="showEditContact"
    @close="showEditContact = false"
  />
  <edit-group
    v-if="currentChat && chatIsGroup"
    :show="showEditGroup"
    @close="showEditGroup = false"
    @contact-updated="updateChatData(true)"
  />
  <div
    class="whatsapp-topbar w-100 d-flex"
    data-test="chatTopBar-div"
  >
    <div class="cursor-pointer list-group-item border-0 p-0 user-info m-0 col">
      <div class="row align-items-center info-card">
        <div
          class="col-auto ps-0 position-relative d-flex align-items-center"
          style="height: 46px"
          @click="handleEditOffCanvas"
        >
          <emoji-chat-mood
            v-if="currentChat?.mood !== null"
            :mood="currentChat?.mood"
            class="emoji-chat"
          />
          <span
            class="avatar"
            @mouseenter="avatarHovered = true"
            @mouseleave="avatarHovered = false"
          >
            <img
              v-if="image"
              :src="image"
              alt="contact-img"
              class="avatar-img rounded-circle"
              :class="{'hovered-avatar': avatarHovered}"
              @error="handleImageError"
            >
          </span>
        </div>
        <div class="col ps-0">
          <div class="mb-1 d-flex justify-content-start align-items-center">
            <h4
              v-if="chatContact"
              @click="handleEditOffCanvas()"
              class="mb-0 name-container"
            >
              <span
                class="px-2 container-loading"
                @mouseenter="nameHovered = true "
                @mouseleave="nameHovered = false"
                :class="{'hovered-name': nameHovered}"
              >
                {{ name }}
              </span>
            </h4>
            <ul
              class="list-unstyled d-flex align-items-center m-0 gap-2 col"
              @click="handleEditOffCanvas"
              ref="tagsList"
            >
              <tag-contact
                class="card-tag-sm"
                v-for="contactTag in visibleTags"
                :key="contactTag.name"
                :tag="contactTag"
              />
              <div
                v-if="ocultTagsCount"
                class="card-tag-sm tag-blue"
              >
                + {{ ocultTagsCount }}
              </div>
            </ul>
          </div>
          <div class="d-flex align-items-center">
            <span
              v-if="currentChat?.agent_name"
              class="agent-name small"
            >
              {{ $t('agent_capitalize') }}: {{ currentChat.agent_name }}
            </span>
            <div v-if="instances.length > 1">
              <div
                ref="dropdown"
                class="d-flex justify-content-start align-items-center"
                @click="openInstances"
              >
                <small
                  v-if="instanceName"
                  class="small status"
                >
                  <div class="ball" />
                  {{ instanceName }}
                  <div
                    class="spinner-border spinner-border-sm"
                    style="height: 10px; width: 10px;"
                    v-if="loadingInstances"
                  />
                  <span
                    v-else
                    class="far fa-chevron-down"
                  />
                </small>
              </div>
              <div
                class="dropdown-menu"
                style="width: 330px"
              >
                <instances-list
                  v-if="contactInstances"
                  :contact="chatContact"
                />
              </div>
            </div>
            <small
              v-else-if="instanceName"
              class="small status container-loading connection-name"
            >
              <div class="ball background-loading" />
              {{ instanceName }}
            </small>
          </div>
        </div>
      </div>
    </div>

    <div
      v-if="$route.params.id && currentChat"
      class="d-flex align-items-center"
      style="padding-right: 1.5rem"
    >
      <div
        v-if="
          currentChat &&
            !currentChat.finished
            || chatIsGroup
        "
        class="opts d-flex justify-content-center align-items-center"
      >
        <done-chat
          v-if="showButtons.done"
          :loading="loading"
        />
        <top-bar-call
          v-if="showButtons.call"
        />
        <transfer-form
          v-if="showButtons.transfer"
          :loading="loading"
        />
        <snooze-dropdown
          v-if="showButtons.snooze"
          @send-snooze-note="event => $emit('sendSnoozeNoteToChat', event)"
          :loading="loading"
        />
        <more-options-chat-dropdown
          v-if="showButtons.more"
          :is-group="currentChat.is_group"
          @show-edit="handleEditOffCanvas"
        />
      </div>
      <div
        class="opts d-flex"
        v-else-if="(!currentChat.agent_id && !currentChat.is_group) || currentChat.finished"
      >
        <top-bar-call />
      </div>
    </div>
  </div>
</template>
<script>
import DoneChat from "./top-bar-buttons/done-chat.vue";
import SnoozeDropdown from "./top-bar-buttons/snooze-dropdown.vue";
import EditContact from "./edit-contact.vue";
import EditGroup from "./edit-group.vue";
import InstancesList from "./instances-list.vue";
import TransferForm from "./transfer-form/transfer-form.vue"
import {Dropdown} from "bootstrap";

import {mapActions, mapGetters, mapMutations} from "vuex";
import TopBarCall from './top-bar-buttons/top-bar-call.vue';
import MoreOptionsChatDropdown from "@whatsapp/components/top-bar-buttons/more-options-chat-dropdown.vue";
import {handleImageError, isValidImageUrl} from "@/utils";
import TagContact from "@whatsapp/components/tags/tag-contact.vue";
import EmojiChatMood from "@whatsapp/components/emoji-chat-mood.vue";

export default {
  emits: ["sendSnoozeNoteToChat"],

  data() {
    return {
      loading: false,
      showDoneForm: false,
      showTransferForm: false,
      showEditContact: false,
      showEditGroup: false,
      dropdown: null,
      dropIsOpenned: false,
      loadingInstances: false,
      nameHovered: false,
      avatarHovered: false
    };
  },

  mounted() {
    if (this.enableDropdown) {
      this.dropdown = new Dropdown(this.$refs["dropdown"]);
    }

    if(this.currentChat){
      this.findContactByID({
        id: this.currentChat.contact.contact_id ?
            this.currentChat.contact.contact_id :
            this.currentChat.contact.id
      });
    }
  },

  computed: {
    ...mapGetters("whatsapp/instance", {
      instances: "getConnectedInstances",
      allowedAgents: "getAllowedAgents",
    }),
    ...mapGetters("whatsapp/chat", {
      loadingFlags: "getLoadingFlags",
      chat: "getCurrentChat",
      emptyChatContact: "getEmptyChatContact"
    }),
    ...mapGetters("whatsapp/contact", {
      contactInstances: "getContactInstances",
      contact: "getContact",
    }),
    ...mapGetters("auth", {
      user: "getUser",
    }),

    enableDropdown() {
      return !this.chatIsGroup && this.instances.length > 1
    },

    visibleTags() {
      const numberOfTagsList = this.calculateNumberOfTags()
      return this.contact?.contact_tags.slice(0, numberOfTagsList)
    },

    ocultTagsCount() {
      return this.contact? this.contact.contact_tags.length - this.visibleTags.length : 0
    },

    currentChat() {
      return this.chat[this.$route.params.id];
    },

    enableTopBarActionButtons () {
      return this.currentChat &&
        !this.currentChat.finished &&
        (this.currentChat.agent_id || this.chatIsGroup)
    },

    chatContact() {
      if (this.currentChat) return this.currentChat.contact;
      if (this.emptyChatContact) return this.emptyChatContact;
      return null;
    },

    image() {
      if (this.chatContact && this.chatContact.image && this.isValidImageUrl(this.chatContact.image))
        return this.chatContact.image;
      else
        return require("@/assets/img/avatar.svg")
    },

    name() {
      if (this.chatContact?.name_alias) return this.chatContact.name_alias;
      if (this.chatContact?.name) return this.chatContact.name;
      if (this.chatContact?.number) return this.chatContact.number;
      return "";
    },

    instanceName(){
      if(this.currentChat){
        if(this.currentChat.instance.data) return this.currentChat.instance.data.name
        return this.currentChat.instance.name
      }
      if (this.chatContact && this.chatContact?.instance) return this.chatContact.instance.name
      return ""
    },

    chatIsGroup() {
      return this.currentChat && this.currentChat.is_group;
    },

    showButtons(){
      const chat = this.currentChat
      return {
        done: (chat.agent_id && !chat.finished) || chat.chatbot_active,
        call: !chat.is_group,
        transfer: !chat.finished && !chat.is_group || chat.chatbot_active,
        snooze: chat.agent_id && !chat.in_snooze && !chat.finished,
        more: (chat.agent_id && !chat.finished) || chat.is_group
      }
    }
  },

  components: {
    EmojiChatMood,
    TagContact,
    MoreOptionsChatDropdown,
    DoneChat,
    SnoozeDropdown,
    EditContact,
    EditGroup,
    InstancesList,
    TransferForm,
    TopBarCall
  },

  methods: {
    ...mapMutations("whatsapp/contact", ["setSelectedContact"]),
    ...mapActions("whatsapp/contact", ["findContactInstances", "findContactByID"]),
    ...mapActions("whatsapp/chat", [
      "findQueue",
      "findInProgress",
      "findInSnooze",
      "findGroups",
      "findFinished",
      "resetChatsPagination"
    ]),
    handleImageError,
    isValidImageUrl,

    updateChatData(isContactEdited) {
      if (isContactEdited) {
        this.resetChatsPagination();
        this.findInProgress();
        this.findGroups();
        this.findQueue();
        this.findInSnooze();
        this.findFinished();
      }
    },

    openInstances() {
      if(this.dropIsOpenned){
        this.dropdown.hide();
        this.dropIsOpenned = false
      }
      else{
        this.loadingInstances = true;
        this.setSelectedContact({})
        this.findContactInstances(this.chatContact.number).then(() => {
          this.dropIsOpenned = true
          this.dropdown.show();
        }).finally(()=>{
          this.loadingInstances = false;
        })
      }
    },

    handleEditOffCanvas(){
      this.nameHovered = false
      this.avatarHovered = false
      this.chatIsGroup ? (this.showEditGroup = true) : (this.showEditContact = true)
    },

    calculateNumberOfTags() {
      if (this.$refs.tagsList) {
        const width = this.$refs.tagsList.offsetWidth;
        const possibleTags = Math.floor((width - 46) / 166);

        return possibleTags;
      }
      return 2;
    }
  },

  watch: {
    contactInstances() {
      if(this.enableDropdown) {
        this.dropdown.hide()
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.whatsapp-topbar {
  height: 70px;
  background-color: white;
  border-left: 1px solid #dddfe3;
  border-bottom: 1px solid #dddfe3;
  border-right: 1px solid #dddfe3;
  border-bottom-right-radius: 20px;

  .name-container{
    padding: 0 $whatsapp-spacing-1 0 0;
  }
  .container-loading {
    border-radius: 0.5rem !important;
  }

  .list-group-item{
    padding-left: 0.76rem;
  }
  .user-info {
    margin-top: 6px;
    .ball {
      background-color: rgb(94, 184, 99);
      width: 8px;
      display: inline-block;
      height: 8px;
      border-radius: 50%;
    }
    .status {
      font-weight: 200;
      font-size: 11px;
    }
  }
  .opts {
    button, .button-whatsapp {
      min-width: 1.244rem;
      min-height: 1.244rem;
      background-color: #e1e9f4;
      border: none;
      color: #95aac9;
      border-radius: $whatsapp-border-radius;
      &:hover:not(.no-hover), .whatsapp-calling {
        background-color: $color-blue-300 !important;
        color: #e3ebf6 !important;
      }
    }
    &.skeleton-loading {
      button {
        border-radius: $whatsapp-border-radius;
        user-select: none;
        color: transparent !important;
      }
    }
  }
  .info-card {
    margin: 0.75rem 0 0.75rem 1.5rem;
  }
  .button-top-bar {
    margin-left: 1rem !important;
    padding: $whatsapp-button-padding 0.807rem;
    height: $whatsapp-button-size !important;
    color: $color-text-gray !important;

    &:not(:first-child) {
      padding: $whatsapp-button-padding !important;
      height: $whatsapp-button-size !important;
      width: $whatsapp-button-size !important;
    }

    &:nth-of-type(1) {
      margin-right: 1rem !important;
    }
  }

  .v-popper {
    .button-top-bar {
      margin-right: 0 !important;
      margin-left: 0 !important;
    }
  }
  .hovered-name{
    background-color: $color-gray-blue-300;
    border-radius: $border-radius-xsm;
  }
  .hovered-avatar{
    box-shadow: 0 0 0 3px $color-gray-300;
  }
  .agent-name{
    padding-right: $whatsapp-spacing-1;
    border-right: 1px solid $color-gray-300;
    margin-right: $whatsapp-spacing-1;
  }
}

.card-tag-sm {
  width: fit-content;
  height: 1.25rem;
  border-radius: 0.375rem;
  padding: 0 0.5rem;
  font-size: 0.675rem;
}

.tag-blue {
  background-color: #F0F4FA;
  color: #3057F2;
}

.avatar {
  width: 2.5rem;
  height: 2.5rem;
}

.emoji-chat {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}
</style>
