<template>
  <div class="main-content pb-6 ">
    <div class="container-fluid ">
      <div class="row justify-content-center">
        <div class="col-12">
          <header-component
            :title="$t('capitalizes.receptive_IVR')"
            :pretitle="$t('uppercases.settings')"
            redirect-link="/manager/voice-settings"
          >
            <template #buttons>
              <router-link
                class="header-button btn btn-primary float-end px-6"
                to="/manager/receptive-ivrs/new"
              >
                {{ $t('add.receptive_IVR') }}
              </router-link>
            </template>
          </header-component>

          <flash-message />

          <div class="col-lg-12 ">
            <div class="card ">
              <div class="card-header ">
                <search-Input @search="search" />
              </div>
              <div class="card-body  p-0">
                <table class="table app-datatable m-0 ">
                  <thead class="">
                    <tr>
                      <th>{{ $t('uppercases.name') }}</th>
                      <th :class="{ 'text-center': !loading }">
                        {{ $t('capitalizes.keyboard_keys') }}
                      </th>
                      <th>{{ $t('uppercases.warning_audio') }}</th>
                      <th />
                    </tr>
                  </thead>

                  <tbody>
                    <template v-if="loading">
                      <loading-table-component
                        :columns="3"
                        :lines="15"
                        :list-width="[150, 50, 50]"
                        :text-center="false"
                      />
                    </template>

                    <tr v-else-if="!list.length">
                      <td
                        class="text-center text-muted"
                        colspan="4"
                      >
                        <span class="fe fe-alert-octagon" /> {{ $t('errors.no_receptive_ivr_found') }}
                      </td>
                    </tr>

                    <tr
                      v-for="item of list"
                      v-else
                      :key="item.id"
                      class="cursor-pointer"
                      @click="
                        $router.push(`/manager/receptive-ivrs/${item.id}/edit`)
                      "
                      @mouseleave="unSelectItem()"
                      @mouseover="selectItem(item.id)"
                    >
                      <td>{{ item.name }}</td>

                      <td class="text-center">
                        {{ item.keys }}
                      </td>

                      <td>
                        <short-player
                          :url="getFullAudioPath(item.audio_path)"
                          class="mx-2"
                        />

                        {{ item.original_audio_name }}
                      </td>

                      <td
                        class="text-right py-0"
                        style="text-align: right"
                      >
                        <span
                          :style="{
                            visibility:
                              item.id == selectedItem ? 'visible' : 'hidden'
                          }"
                          class="h3 fe fe-chevron-right text-primary my-0"
                        />
                      </td>
                    <!-- <td>{{ item.keys.length }}</td> -->
                    </tr>
                  </tbody>
                </table>
              </div>

              <div
                v-if="!loading"
                class="card-footer"
              >
                <div class="col-lg-12">
                  <paginator
                    v-if="!loading"
                    :pagination="pagination"
                    @change-page="changePage"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from "vuex";

import searchInput from "@/components/search-input";
import flashMessage from "@/components/flash-message";
import paginator from "@/components/app-paginator";
import shortPlayer from "@components/short-player.vue";
import _ from "lodash";

import LoadingTableComponent from "@dialer/manager/components/loading-table-component.vue";
import HeaderComponent from "@/components/header-component.vue";

export default {
  data() {
    return {
      player: null,
      audioPlaying: false,
      audioPlayContext: "",
      selectedItem: false
    };
  },
  /**
   *
   */
  mounted() {
    this.setFilters({
      search: ""
    });
    this.getList();
  },

  /**
   *
   */
  components: {
    flashMessage,
    searchInput,
    paginator,
    shortPlayer,
    LoadingTableComponent,
    HeaderComponent
  },

  /**
   *
   */
  computed: {
    /**
     * Getters
     */
    ...mapGetters("manager/receptive_ivrs", {
      list: "getList",
      errors: "getErrors",
      loading: "getLoadingFlags",
      pagination: "getPagination"
    }),

    /**
     *
     */
    ...mapGetters("auth", {
      user: "getUser"
    })
  },

  /**
   *
   */

  methods: {
    /**
     *
     */
    ...mapActions("manager/receptive_ivrs", ["getList"]),
    ...mapMutations("manager/receptive_ivrs", ["setFilters", "setCurrentPage", "setInclude"]),

    /**
     *
     */

    getFullAudioPath(audio_path) {
      return (
        process.env.VUE_APP_BA12AS.replace("/api/v1", "") +
        audio_path +
        "&api_token=" +
        this.user.api_token
      );
    },

    /**
     *  change page
     */
    changePage(page) {
      this.setCurrentPage(page);
      this.getList();
    },

    /**
     *
     */
    search: _.debounce(function (term) {
      this.setFilters({
        search: term
      });
      this.setCurrentPage(1);
      this.getList();
    }, 300),
    /**
     *
     */
    selectItem(item) {
      this.selectedItem = item;
    },
    /**
     *
     */
    unSelectItem() {
      this.selectedItem = false;
    }
  }
};
</script>

<style lang="scss">
.header-body.no-subtitle {
  .header-button {
    margin-top: 0.5px;
  }

  h1 {
    line-height: 42.2px;
  }
}

.header-body {
  .back {
    width: 65.5px;
  }
}
</style>
