<template>
  <div
    ref="protocolCanvas"
    data-bs-scroll="true"
    data-bs-backdrop="false"
    tabindex="-1"
    class="offcanvas offcanvas-protocol offcanvas-end shadow"
  >
    <div class="offcanvas-header border-0 p-0">
      <h5
        class="fw-normal"
        style="margin-bottom: 31px;"
      >
        {{ $t('chats_protocols') }}
      </h5>
      <button
        type="button"
        data-test="closeGroupOffCanvas-button"
        class="btn-close btn-protocol-close text-reset h5"
        data-bs-dismiss="offcanvas"
        aria-label="Close"
      />
    </div>
    <div
      class="offcanvas-body p-0"
    >
      <div
        v-if="!protocols || protocols.length === 0"
        class="d-flex flex-column justify-content-center align-items-center my-5"
      >
        <img
          src="@assets/img/empty.svg"
          class="img-fluid mb-4"
          alt="empty-funnel"
          width="100"
        >
        <small class="mt-2">
          {{ $t('protocols_not_found') }}
        </small>
      </div>
      <ul
        v-else
        class="list-group list-protocol-group list-group-flush"
      >
        <li
          v-for="protocol in protocols"
          :key="protocol.id"
          :aria-disabled="loading.findAnchor"
          class="list-group-item list-group-protocol-item w-100"
          :class="{'disabled': loading.findAnchor}"
        >
          <protocol-card
            :protocol-details="protocol"
          />
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { Offcanvas } from "bootstrap";
import { mapGetters, mapActions } from "vuex";
import { ref } from 'vue';
import { formatTimestamp } from "@/utils";
import ProtocolCard from "./protocol-card.vue";

export default {
  emits: ['update:modelValue'],
  components: {ProtocolCard},

  props: {
    modelValue: {type: Boolean, default: false}
  },

  data (){
    return {
      offCanvas: null,
      showDetails: false,
      activeMap: ref({})
    };
  },

  computed: {
    ...mapGetters("whatsapp/chat", {
      protocols: "getAllProtocols",
      loading: "getLoadingFlags"
    }),
  },
  mounted() {
    this.offCanvas = new Offcanvas(this.$refs.protocolCanvas);
    this.$refs.protocolCanvas.addEventListener("hidden.bs.offcanvas", this.closeCanvas);
  },

 watch: {
   modelValue (value){
     if (value) this.showCanvas()
   },
   'loading.findAnchor' (value) {
     if(value === false) this.closeCanvas()
   }
 },

  methods: {
    formatTimestamp,

    ...mapActions("whatsapp/chat", [
      "findAllProtocols"
    ]),
    showCanvas () {
      this.offCanvas.show()
      this.findAllProtocols(this.$route.params.id);
    },
    closeCanvas () {
      if(this.loading.findAnchor === false){
        this.$emit('update:modelValue', false)
        this.offCanvas.hide();
      }
    }
  },
  beforeUnmount (){
    if (this.offCanvas)
      this.$refs.protocolCanvas.removeEventListener("hidden.bs.offcanvas", this.closeCanvas());
  },
};
</script>

<style lang="scss" scoped>

.offcanvas-protocol {
  padding: 24px !important;
  width: 309px !important;
}
.btn-protocol-close {
  background-color: #fff !important;
  color: $color-text-gray !important;
  position: absolute !important;
  right: 2rem !important;
  top: 2rem !important;
}

.list-protocol-group {
  overflow-y: scroll !important;
  overflow-x: hidden !important;

  .list-group-protocol-item {
    border-bottom: 1.2px solid $color-gray-blue-300 !important;
    cursor: pointer !important;
    padding: 10px 0 !important;
  }
}

</style>
