import store from "@store";

import IndexReceptiveIvrs from "@dialer/manager/pages/settings/receptive_ivrs/index-receptive-ivrs";
import ListReceptiveIvrs from "@dialer/manager/pages/settings/receptive_ivrs/list-receptive-ivrs";
import NewReceptiveIvrs from "@dialer/manager/pages/settings/receptive_ivrs/new-receptive-ivr";
import EditReceptiveIvrs from "@dialer/manager/pages/settings/receptive_ivrs/edit-receptive-ivrs";

export default {
  path: "receptive-ivrs",
  name: "receptive-ivrs",
  components: {
    main: IndexReceptiveIvrs
  },
  children: [
    {
      path: "",
      components: {
        "receptive-ivr": ListReceptiveIvrs
      }
    },
    {
      path: "new",
      components: {
        "receptive-ivr": NewReceptiveIvrs
      }
    },
    {
      path: ":id/edit",
      components: {
        "receptive-ivr": EditReceptiveIvrs
      }
    }
  ],
  beforeEnter: (to, from, next) => {
    if (store.getters["auth/isManager"]) next();
    else next({
      name: "not-found",
      params: {
        pathMatch: to.path.split('/').slice(1)
      }
    });
  }
};
