<template>
  <tr class="">
    <td class="ps-0 py-0 w-min">
      <div class="form-check form-switch">
        <input
          v-model="is_active"
          :id="'flexSwitchCheckDefault-' + index"
          class="form-check-input"
          role="switch"
          type="checkbox"
        >
      </div>
    </td>
    <td
      class="ps-0 col-3"
      colspan="2"
    >
      <single-select-with-hierarchy
        v-if="productByCategory"
        v-model="selectedProduct"
        :disabled="!formData.is_active"
        :list-data="productByCategory"
        group-key="products"
        label="name"
        return-all-object
        title-key="name"
      />
    </td>
    <td class="ps-0 py-0">
      <input
        v-model="quantity"
        :disabled="!formData.is_active"
        class="form-control"
        min="0"
        placeholder="0"
        type="number"
      >
    </td>
    <td class="ps-0 py-0">
      <currency-input
        ref="priceInput"
        v-model="formData.price"
        :currency="currency"
        :disabled="!formData.is_active"
        custom-class="form-bg-light"
        readonly
      />
    </td>
    <td class="ps-0 py-0">
      <input
        :value="formData.recurrence"
        :disabled="!formData.is_active"
        class="form-control bg-light"
        :placeholder="$t('unique_f')"
        readonly
        type="text"
      >
    </td>
    <td class="ps-0 py-0 position-relative">
      <div class="input-group">
        <input
          v-model="discount_amount"
          :class="{'is-invalid text-danger': selectedProduct?.maximum_discount < formData.discount_amount}"
          :disabled="!formData.is_active"
          class="form-control border-end-0"
          v-maska="{mask: '##.##', placeholder: '0.00'}"
          placeholder="0"
          type="text"
        >
        <span
          :class="{'border-danger text-danger': selectedProduct?.maximum_discount < formData.discount_amount}"
          class="input-group-text border-start-0"
        >
          <i class="fa-regular fa-percent" />
        </span>
      </div>
    </td>
    <td class="ps-0 py-0">
      <currency-input
        ref="valueInput"
        v-model="formData.value"
        :currency="currency"
        :disabled="!formData.is_active"
        readonly
        custom-class=""
      />
    </td>
    <td class="ps-0 py-0">
      <button
        class="btn btn-transparent btn-sm w-auto"
        @click="removeRow"
      >
        <i class="fa-regular fa-trash" />
      </button>
    </td>
    <td
      v-if="selectedProduct?.maximum_discount < formData.discount_amount"
      class="error-message"
    >
      <small
        class="text-danger"
      >
        {{ $t('discount_greater_than_allowed') }}
      </small>
    </td>
  </tr>
</template>
<script>
import {defineComponent} from 'vue'
import {mapGetters} from "vuex";
import SingleSelectWithHierarchy from "@components/select/single-select-with-hierarchy.vue";
import CurrencyInput from "@components/currency-input.vue";

export default defineComponent({
  name: "ProductFormRow",
  components: {CurrencyInput, SingleSelectWithHierarchy},
  props: {
    row: {type: Object, required: true},
    index: {type: Number, required: true},
    productByCategory: {type: Array, required: true},
    currency: {type: String, required: true},
  },
  emits: ['remove-row', 'update'],
  data() {
    return {
      is_active: true,
      quantity: 0,
      discount_amount: '0,00',

      formData: {
        is_active: true,
        is_recurrent: false,
        product: null,
        currency: 'BRL',
        quantity: 0,
        price: '0,00',
        maximum_discount: 0,
        recurrence: this.$t('unique_f'),
        discount_amount: '0,00',
        value: '0,00',
        discount_percentage: true
      },
      selectedProduct: {}
    }
  },
  computed: {
    ...mapGetters("manager/products_and_services", {
      products: "getProducts",
    }),

    availableCurrencyFormats() {
      const currency = [
        {name: this.$t('currency_format_brl'), locale: 'pt-BR', abbr: 'BRL', symbol: "R$"},
        {name: this.$t('currency_format_usd'), abbr: 'USD', locale: 'en-US', symbol: "$"},
        {name: this.$t('currency_format_eur'), abbr: 'EUR', locale: 'en-EN', symbol: "€"}
      ]
      return currency.find(currency => currency.abbr === this.formData?.currency) || 'pt-BR'
    }
  },

  mounted() {
    this.formData = {
      ...this.row,
      currency: this.currency
    }
    this.is_active = this.formData.is_active
    this.quantity = this.formData.quantity
    this.discount_amount = this.formData.discount_amount
    this.selectedProduct = this.products.find(product => product.id === this.row.id)
  },

  updated (){
    this.$nextTick(() => {
      if(this.row.id){
        this.selectedProduct = this.products.find(product => product.id === this.row.id)
      }
    })
  },

  watch: {
    selectedProduct (value){
      if(value){
        this.formData = {
          ...this.formData,
          id: value.id,
          maximum_discount: value.maximum_discount,
          is_recurrent: value.is_recurrent,
          recurrence: value.is_recurrent ? this.$t('recurrent') : this.$t('unique_f'),
          price: value.price,
          currency: value.currency,
        }
      }
      this.$emit('update', {data: this.formData, index: this.index})
    },

    'is_active'(value){
      this.formData = {
        ...this.formData,
        is_active: value
      }
      this.$emit('update', {data: this.formData, index: this.index})
    },

    quantity(value){
      this.value = this.formData.price * value * (100 - this.formData.discount_amount)/100
      this.formData = {
        ...this.formData,
        quantity: value,
        value: this.value,
      }
      this.$emit('update', {data: this.formData, index: this.index})
    },

    'discount_amount'(value){
      this.value = this.formData.price * this.formData.quantity * (100 - value)/100
      this.formData = {
        ...this.formData,
        discount_amount: value,
        value: this.value,
      }
      this.$emit('update', {data: this.formData, index: this.index})
    },
  },

  methods: {
    removeRow (){
      this.$emit('remove-row', this.index)
    },
  }

})
</script>
<style
  lang="scss"
  scoped
>
.table td {
  border: none !important;
}

th {
  border: none !important;
}

.w-min {
  width: min-content;
}

.error-message {
  position: absolute;
  left: 68%;
  margin-top: 2.5rem;
}
</style>