import { AgentStatus, CallMode } from "@/utils";
import moment from "moment";

const isUra = (call) => call['ura'] === '1' && call['hold'] !== '1'

export default {
  startLoadingFlag: (state, flag) => state.loadingFlags[flag] = true,
  stopLoadingFlag: (state, flag) => state.loadingFlags[flag] = false,
  setRealTimeCampaignContext: (state, campaign) => state.realTimeCampaignContext = campaign,
  setAgents: (state, agents) => {
    state.agents = {};

    state.countAgentsStatuses = {
      [AgentStatus.OFFLINE]: 0,
      [AgentStatus.IDLE]: 0,
      [AgentStatus.ON_CALL]: 0,
      [AgentStatus.ACW]: 0,
      [AgentStatus.ON_MANUAL_CALL]: 0,
      [AgentStatus.ON_MANUAL_CALL_CONNECTED]: 0,
      [AgentStatus.ON_WORK_BREAK]: 0,
      [AgentStatus.ON_CONSULT]: 0,
      [AgentStatus.ON_MANUAL_CALL_ACW]: 0,
      [AgentStatus.MANUAL_CALL_CONNECTED]: 0,
      [AgentStatus.CONSULT_HOLD]: 0,
      [AgentStatus.CONSULT_CONNECTED]: 0
    }

    for (let agent of agents) {
      state.agents[agent.id] = agent
      state.agents[agent.id].timer = Math.round(moment
        .duration(moment().diff(moment.unix(agent.status_start_time)))
        .asSeconds())
      state.countAgentsStatuses[agent.status]++;
    }
  },
  setUpdateAgentsRealTimeActive: (state, update) => state.updateAgentsRealTimeActive = update,
  resetAgentsStatusesCount: state => {
    state.countAgentsStatuses = {
      [AgentStatus.OFFLINE]: 0,
      [AgentStatus.IDLE]: 0,
      [AgentStatus.ON_CALL]: 0,
      [AgentStatus.ACW]: 0,
      [AgentStatus.ON_MANUAL_CALL]: 0,
      [AgentStatus.ON_MANUAL_CALL_CONNECTED]: 0,
      [AgentStatus.ON_WORK_BREAK]: 0,
      [AgentStatus.ON_CONSULT]: 0,
      [AgentStatus.ON_MANUAL_CALL_ACW]: 0,
      [AgentStatus.MANUAL_CALL_CONNECTED]: 0,
      [AgentStatus.CONSULT_HOLD]: 0,
      [AgentStatus.CONSULT_CONNECTED]: 0
    }
  },
  updateAgentsTime(state) {
    for (let i in state.agents)
      if (state.agents[i].status !== AgentStatus.OFFLINE)
        state.agents[i].timer = Math.round(moment
          .duration(moment().diff(moment.unix(state.agents[i].status_start_time)))
          .asSeconds())
  },
  isIdle(state, payload) {
    if (!state.agents[payload.agent.id]) return;
    let status = state.agents[payload.agent.id].status;

    if(payload.agent.status === AgentStatus.ON_MANUAL_CALL ||
        payload.agent.status === AgentStatus.ON_MANUAL_CALL_CONNECTED) {
      state.countAgentsStatuses[status]--;
      state.countAgentsStatuses[AgentStatus.ON_MANUAL_CALL]++;
      state.agents[payload.agent.id].status = AgentStatus.ON_MANUAL_CALL;
      state.agents[payload.agent.id].manual_call_status = null;
      state.agents[payload.agent.id].manual_call_acw_status = null;
      state.agents[payload.agent.id].status_start_time = moment().unix();

      return;
    }

    state.countAgentsStatuses[status]--;
    state.countAgentsStatuses[AgentStatus.IDLE]++;
    state.agents[payload.agent.id].status = AgentStatus.IDLE
    state.agents[payload.agent.id].manual_call_status = null;
    state.agents[payload.agent.id].manual_call_acw_status = null;
    state.agents[payload.agent.id].status_start_time = moment().unix()


  },
  loggedOut(state, payload) {
    if (!state.agents[payload.agent.id]) return;
    let status = state.agents[payload.agent.id].status
    state.countAgentsStatuses[status]--;
    state.countAgentsStatuses[AgentStatus.OFFLINE]++;
    state.agents[payload.agent.id].manual_call_status = null;
    state.agents[payload.agent.id].manual_call_acw_status = null;
    state.agents[payload.agent.id].status = AgentStatus.OFFLINE;
  },
  wasConnected(state, payload) {
    state.mailings[payload.agent.id] = payload.mailing;
    if (!state.agents[payload.agent.id]) return;
    let status = state.agents[payload.agent.id].status
    state.countAgentsStatuses[status]--;
    state.countAgentsStatuses[AgentStatus.ON_CALL]++;
    state.agents[payload.agent.id].status = AgentStatus.ON_CALL;
    state.agents[payload.agent.id].manual_call_status = null;
    state.agents[payload.agent.id].manual_call_acw_status = null;
    state.agents[payload.agent.id].status_start_time = moment().unix();
  },
  manualWasConnected(state, payload) {
    if (!state.agents[payload.agent.id]) return;
    let status = state.agents[payload.agent.id].status
    state.countAgentsStatuses[status]--;
    state.countAgentsStatuses[AgentStatus.ON_MANUAL_CALL]++;
    state.agents[payload.agent.id].status = AgentStatus.ON_MANUAL_CALL;
    state.agents[payload.agent.id].manual_call_status = AgentStatus.ON_MANUAL_CALL_CONNECTED;
    state.agents[payload.agent.id].status_start_time = moment().unix()
  },
  enterManual(state, payload) {
    if (!state.agents[payload.agent.id]) return;
    let status = state.agents[payload.agent.id].status
    state.countAgentsStatuses[status]--;
    state.countAgentsStatuses[AgentStatus.ON_MANUAL_CALL]++;
    state.agents[payload.agent.id].status = AgentStatus.ON_MANUAL_CALL;
    state.agents[payload.agent.id].manual_call_status = null;
    state.agents[payload.agent.id].manual_call_acw_status = null;
    state.agents[payload.agent.id].status_start_time = moment().unix()
  },
  inAcw(state, payload) {
    if (!state.agents[payload.agent.id]) return;
    let status = state.agents[payload.agent.id].status
    state.countAgentsStatuses[status]--;
    state.countAgentsStatuses[AgentStatus.ACW]++;
    state.agents[payload.agent.id].status = AgentStatus.ACW;
    state.agents[payload.agent.id].manual_call_status = null;
    state.agents[payload.agent.id].manual_call_acw_status = null;
    state.agents[payload.agent.id].status_start_time = moment().unix()
  },
  enterManualCallAcw(state, payload) {
    if (!state.agents[payload.agent.id]) return;
    let status = state.agents[payload.agent.id].status
    state.countAgentsStatuses[status]--;
    state.countAgentsStatuses[AgentStatus.ON_MANUAL_CALL_ACW]++;
    state.agents[payload.agent.id].status = AgentStatus.ON_MANUAL_CALL_ACW;
    state.agents[payload.agent.id].status_start_time = moment().unix()
  },

  setManualCallAcwConnected(state, payload) {
    if (!state.agents[payload.agent.id]) return;
    state.agents[payload.agent.id].manual_call_acw_status = AgentStatus.MANUAL_CALL_CONNECTED;
    state.agents[payload.agent.id].status_start_time = moment().unix()
  },

  clearManualCallAcw(state, payload) {
    if (!state.agents[payload.agent.id]) return;
    state.agents[payload.agent.id].manual_call_acw_status = null;
    state.agents[payload.agent.id].status_start_time = moment().unix()
  },


  /**
   *
   */
  leftManualCallAcw(state, payload) {
    if (!state.agents[payload.agent.id]) return;
    let status = state.agents[payload.agent.id].status
    state.countAgentsStatuses[status]--;
    state.countAgentsStatuses[AgentStatus.ACW]++;
    state.agents[payload.agent.id].status = AgentStatus.ACW;
    state.agents[payload.agent.id].manual_call_status = null;
    state.agents[payload.agent.id].manual_call_acw_status = null;
    state.agents[payload.agent.id].status_start_time = moment().unix()
  },
  enteredWorkBreak(state, payload) {
    if (!state.agents[payload.agent.id]) return;
    let status = state.agents[payload.agent.id].status
    state.countAgentsStatuses[status]--;
    state.countAgentsStatuses[AgentStatus.ON_WORK_BREAK]++;
    state.agents[payload.agent.id].status = AgentStatus.ON_WORK_BREAK;
    state.agents[payload.agent.id].work_break = payload.work_break;
    state.agents[payload.agent.id].manual_call_status = null;
    state.agents[payload.agent.id].manual_call_acw_status = null;
    state.agents[payload.agent.id].status_start_time = moment().unix()
  },
  setCallsWorkingFromCurrenteCampaign: (state, payload) => {
    state.callsWorking = {}
    let groups = payload.data;
    for (let group of Object.values(groups)) {
      for (let call of group) {
        if (state.realTimeCampaignContext.amd_enabled && call.status == '2') { //1
          if (call.amd_status) {
            if (isUra(call))
              state.callsWorking[call.telephony_id] = 'ura'
            else
              state.callsWorking[call.telephony_id] = '2'
          } else
            state.callsWorking[call.telephony_id] = 'amd'
        } else state.callsWorking[call.telephony_id] = call.status
      }
    }
    state.countCallsWorkingStatuses.trying = (groups['1'] ? groups['1'].length : 0);
    state.countCallsWorkingStatuses.in_progress = (groups['3'] ? groups['3'].length : 0);
    if (state.realTimeCampaignContext?.amd_enabled && groups['2']) {
      for (let call of groups['2']) {
        if (call.amd_status) {
          if (isUra(call))
            state.countCallsWorkingStatuses.ura++;
          else {
            state.countCallsWorkingStatuses.waiting++;
          }
        } else state.countCallsWorkingStatuses.amd++;
      }
    } else if (groups['2']) {
      for (let call of groups['2']) {
        if (isUra(call))
          state.countCallsWorkingStatuses.ura++;
        else
          state.countCallsWorkingStatuses.waiting++;
      }
    }
    state.countCallsWorkingStatuses.total =
      state.countCallsWorkingStatuses.trying +
      state.countCallsWorkingStatuses.in_progress
      + (groups['2'] ? groups['2'].length : 0)
    state.updateCallsInRealTimeActive = true
  },
  setMailings: (state, payload) => {
    // only calls in progress have mailing data
    let mailings = {};
    let callsInProgress = payload['3']
    if(callsInProgress) {
      callsInProgress.forEach(mailing => {
        mailings[mailing.agent] = mailing.mailing_data
      });
    }
    state.mailings = mailings
  },
  setTrying: (state, payload) => {
    state.callsWorking[payload.call.telephony_id] = '1';
    state.countCallsWorkingStatuses.trying++;
    state.countCallsWorkingStatuses.total++;
  },
  setAmd: (state, payload) => {
    if (state.callsWorking[payload.call.telephony_id] &&
      state.callsWorking[payload.call.telephony_id] == '1') {
      state.callsWorking[payload.call.telephony_id] = 'amd'
      state.countCallsWorkingStatuses.trying--;
      state.countCallsWorkingStatuses.amd++;
    }
  },
  removeUraStatus: (state) => {
    delete state.countCallsWorkingStatuses.ura
  },
  setUra: (state, payload) => {
    switch (state.callsWorking[payload.call.telephony_id]) {
      case '1':
        state.callsWorking[payload.call.telephony_id] = 'ura'
        state.countCallsWorkingStatuses.trying--;
        state.countCallsWorkingStatuses.ura++;
        break;
      case 'amd':
        state.callsWorking[payload.call.telephony_id] = 'ura'
        state.countCallsWorkingStatuses.amd--;
        state.countCallsWorkingStatuses.ura++;
        break;
    }
  },
  setWaiting(state, payload) {
    if (state.callsWorking[payload.call.telephony_id]) {
      switch (state.callsWorking[payload.call.telephony_id]) {
        case '1':
          state.callsWorking[payload.call.telephony_id] = '2'
          state.countCallsWorkingStatuses.trying--;
          state.countCallsWorkingStatuses.waiting++;
          break;
        case 'amd':
          state.callsWorking[payload.call.telephony_id] = '2'
          state.countCallsWorkingStatuses.amd--;
          state.countCallsWorkingStatuses.waiting++;
          break;
        case 'ura':
          state.callsWorking[payload.call.telephony_id] = '2'
          state.countCallsWorkingStatuses.ura--;
          state.countCallsWorkingStatuses.waiting++;
          break;
        default:
          if (payload.call.ura && state.countCallsWorkingStatuses.ura > 0)
            state.countCallsWorkingStatuses.ura--;
          break;
      }
    }
  },
  setInProgress(state, payload) {
    if (state.callsWorking[payload.call.telephony_id]) {
      switch (state.callsWorking[payload.call.telephony_id]) {
        case '1':
          state.callsWorking[payload.call.telephony_id] = '3'
          state.countCallsWorkingStatuses.trying--;
          state.countCallsWorkingStatuses.in_progress++;
          break;
        case '2':
          state.callsWorking[payload.call.telephony_id] = '3'
          state.countCallsWorkingStatuses.waiting--;
          state.countCallsWorkingStatuses.in_progress++;
          break;
        case 'ura':
          state.callsWorking[payload.call.telephony_id] = '3'
          state.countCallsWorkingStatuses.ura--;
          state.countCallsWorkingStatuses.in_progress++;
          break;
        case 'amd':
          state.callsWorking[payload.call.telephony_id] = '3'
          state.countCallsWorkingStatuses.amd--;
          state.countCallsWorkingStatuses.in_progress++;
          break;
      }
    }
  },
  removeMailing(state, agentId) {
    state.mailings[agentId] = null;
  },
  removeCall(state, payload) {
    if (state.callsWorking[payload.call.telephony_id]) {
      switch (state.callsWorking[payload.call.telephony_id]) {
        case '1':
          state.countCallsWorkingStatuses.trying--;
          state.countCallsWorkingStatuses.total--;
          break;
        case '2':
          state.countCallsWorkingStatuses.waiting--;
          state.countCallsWorkingStatuses.total--;
          break;
        case 'amd':
          state.countCallsWorkingStatuses.amd--;
          state.countCallsWorkingStatuses.total--;
          break;
        case 'ura':
          state.countCallsWorkingStatuses.ura--;
          state.countCallsWorkingStatuses.total--;
          break;
        case '3':
          state.countCallsWorkingStatuses.in_progress--;
          state.countCallsWorkingStatuses.total--;
          break;
      }
      delete state.callsWorking[payload.call.telephony_id]
    }
  },
  enqueueFinalizedCalls(state, payload) {
    //todo: imporove
    if (payload.callHistory.call_mode === CallMode.RECEPTIVE) return
    state.lastFinalizedCalls.unshift({
      code: payload.callHistory.hangup_cause,
      txt: payload.hangupCause.text,
      color: payload.hangupCause.color,
      phone: payload.callHistory.number,
      speaking_time: payload.callHistory.speaking_time,
      calling_time: payload.callHistory.calling_time,
      status: payload.callHistory.status
    });
    if (state.lastFinalizedCalls.length > 20)
      state.lastFinalizedCalls.pop()
  },
  clearCallsWorkingStatuses: state => {
    state.callsWorking = {}
  },
  clearLastFinalizedCalls: state => {
    state.lastFinalizedCalls = []
  },
  setUpdateCallsInRealTimeActive: (state, updateCallsInRealTimeActive) => {
    state.updateCallsInRealTimeActive = updateCallsInRealTimeActive
  },
  resetCallsWorkingStatuses(state) {
    state.countCallsWorkingStatuses = {
      total: 0,
      trying: 0,
      amd: 0,
      ura: 0,
      waiting: 0,
      in_progress: 0
    }
  },
}
