<template>
  <div
    class="manager"
    :class="this.blur ? 'blur-effect' : ''"
  >
    <div
      class="modal fade"
      id="modalCredit"
      tabindex="-1"
      aria-labelledby="modalCredit"
      aria-hidden="true"
    >
      <add-credit-modal v-if="user && isManager" />
    </div>
    <manager-sidebar :expand="getActiveRouteMeta()['short-side-bar']" />
    <div class="main-content">
      <router-view name="main" />
    </div>
  </div>
  <div v-if="user && isManager && !this.inImpersonate">
    <temporary-automatic-unlock
      v-if="isCompanyBlocked"
      @company-was-unlocked="checkIfCompanyIsBlocked"
    />
    <semester-data-update />
    <password-update />
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from "vuex";
import moment from "moment";
import SemesterDataUpdate from "@dialer/manager/components/semester-data-update.vue";
import AddCreditModal from "@dialer/manager/components/user-profile/add-credit-modal.vue";
import ManagerSidebar from "@dialer/manager/components/manager-sidebar";
import PasswordUpdate from "@dialer/manager/components/password-update.vue";
import TemporaryAutomaticUnlock from "../components/temporary-automatic-unlock.vue";

export default {
  data() {
    return {
      blur: null,
      isCompanyBlocked: false
    };
  },

  mounted() {
    this.checkIfCompanyIsBlocked();
    this.setIncludes(["campaigns-landline-routes", "campaigns-mobile-routes"]);
    this.loadRoutes();
  },

  beforeUnmount() {
    if (!this.socketIOStatus) this.setSocketIOActive(false);
  },

  computed: {
    ...mapGetters("auth", {
      user: "getUser",
      isManager: "isManager",
      inImpersonate: "inImpersonate",
    }),
    ...mapGetters("system", {
      socketIOStatus: "getSocketIOActive"
    }),

    ...mapGetters("criterion", {
      alert: "getAlert"
    }),
  },

  /**
   */
  components: {
    ManagerSidebar,
    AddCreditModal,
    SemesterDataUpdate,
    PasswordUpdate,
    TemporaryAutomaticUnlock
  },

  methods: {
    ...mapMutations("system", ["setJsSIPActive", "setSocketIOActive"]),
    ...mapActions("manager/voice_3c", ["loadRoutes"]),
    ...mapMutations("manager/voice_3c", ["setIncludes"]),

    logout() {
      this.$store.dispatch("auth/logout", this.fields);
    },

    getActiveRouteMeta() {
      const meta = this.$route.matched.map(el => el.meta).filter(el => Object.keys(el).length);
      return this.$route.matched && meta[0] ? meta[0] : "";
    },

    checkIfCompanyIsBlocked() {
      moment.locale("pt-br");
      const blockedAt = this.user.company.blocked_at;
      this.isCompanyBlocked = blockedAt ? blockedAt < moment().unix() : false;
      if (!this.isCompanyBlocked) {
        this.setSocketIOActive(true);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
::-webkit-scrollbar {
  width: 14px;
}
::-webkit-scrollbar-track {
  background: transparent !important;
}

::-webkit-scrollbar-thumb {
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
  background: $color-gray-400;
  background-clip: padding-box;
  border-radius: 6px;
}

.blur-effect {
  filter: blur(2px) !important;
}
</style>
