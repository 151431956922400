<template>
  <div class="list-group list-group-flush list-group-activity my-n3 ms-4 me-5">
    <template
      v-for="(item, index) in formattedItems"
      :key="index"
    >
      <div
        v-if="loading"
        class="d-flex gap-3 align-items-center mx-n3 my-0 list-group-item"
      >
        <card-loading
          class="rounded-circle ms-2"
          style="width: 25px"
          :height="25"
        />
        <card-loading
          class="w-100 m-0"
          :height="70"
        />
      </div>
      <list-group-activity-item
        v-else
        :log="item"
        @selected-activity="openModalActivity"
      />
    </template>
  </div>

  <modal-edit-activity
    @close="showEditActivityModal = false"
    :show-edit-activity-modal="showEditActivityModal"
    :activity="currentActivity"
  />
</template>

<script>
import CardLoading from "@components/card-loading.vue";
import ListGroupActivityItem from "@crm/components/activity/list-group-activity/list-group-activity-item.vue";
import ModalEditActivity from "@crm/components/activity/modals/modal-edit-activity.vue";

export default {
  emits: ["update:currentPage"],
  data() {
    return {
      showEditActivityModal: false,
      currentActivity: null
    }
  },

  components: {
    ModalEditActivity,
    CardLoading,
    ListGroupActivityItem
  },

  props: {
    items: {
      type: Object,
      default: () => {}
    },

    loading: {
      type: Boolean,
      default: false
    },

    itemsCountToLoading: {
      type: Number,
      default: 0
    },

    currentPage: {
      type: Number,
      default: 1
    }
  },

  computed: {
    formattedItems() {
      return this.loading ? this.itemsCountToLoading : this.items;
    }
  },

  methods: {
    openModalActivity(data) {
      this.currentActivity = data;
      this.showEditActivityModal = true;
    }
  }
};
</script>
<style scoped lang="scss">
.list-group-activity {
  .list-group-item {
    &:before {
      box-sizing: border-box;
      border-left: 2px solid $color-gray-100;
      opacity: 50%;
      content: "";
      height: 100%;
      left: 1.18rem;
      position: absolute;
      top: 50%;
    }
  }
  &:last-child {
    .list-group-item {
      &:before {
        display: none;
      }
    }
  }
}
</style>
