<template>
  <div
    ref="canvas"
    class="offcanvas offcanvas-top offcanvas-new-chat"
  >
    <div
      class="offcanvas-header border-0"
      style="padding: 1.5rem"
    >
      <h5
        class="offcanvas-title text-large lh-sm"
      >
        {{ $t('new_chat') }}
      </h5>
      <button
        type="button"
        @click="offcanvas.hide()"
        class="btn-close fs-2 tx-text-gray"
        data-test="closeOffcanvas-button"
      />
    </div>
    <div class="offcanvas-body pt-0">
      <div style="margin-bottom: 1.5rem">
        <label class="form-label">
          {{ $t('sending_from') }}
        </label>
        <select-channel @select-channel="(channel) => this.fields.instance_id = channel.id" />
      </div>
      <div>
        <label
          for="numbers"
          class="form-label fw-medium"
          style="margin-bottom: 0.25rem"
        >
          {{ $t('number') }}
          <span
            class="far fa-circle-info ms-2 tx-text-gray"
            ref="phoneTooltip"
            data-bs-toggle="tooltip"
            data-bs-html="true"
            :data-bs-title="$t('new_chat_number_tooltip')"
          />
        </label>
        <div
          class="form-text text-small lh-sm tx-text-gray"
          style="margin-bottom: 1rem"
        >
          {{ $t('multiple_number_description') }}
        </div>
      </div>
      <div v-if="!checkedPhones?.length">
        <textarea
          class="form-control"
          style="resize: none; margin-bottom: 1.5rem"
          placeholder="Ex: +00 (00) 00000-0000"
          v-model="phonesString"
          @input="handlePhones"
          :rows="textAreaRows"
          data-test="phonesWhatsapp-textarea"
        />
        <app-button
          class="w-100"
          :loading="loading"
          :disabled="!phonesString || loading"
          @click="submit"
          data-test="startChat-button"
        >
          {{ $t('start_new_chat') }}
        </app-button>
      </div>

      <div v-else>
        <ul
          class="list-group list-group-flush"
          style="margin-bottom: 1rem"
        >
          <li
            class="list-group-item d-flex align-items-center phone"
            :class="{'disabled': !phone.isWhatsapp}"
            style="gap: 0.5rem"
            v-for="phone in checkedPhones"
            :key="phone.number"
            @click="newChat(phone)"
          >
            <span
              class="fa-kit fa-whatsapp"
              :class="{
                'fa-whatsapp tx-text-gray': phone.isWhatsapp,
                'fa-whatsapp-none': !phone.isWhatsapp,
              }"
            />
            <p class="m-0 col lh-base fw-medium">
              {{ formatInternationalPhone(phone.number) }}
            </p>
            <div v-if="phone.isWhatsapp">
              <div
                v-if="loadingFlags.createNewChat && openedPhone == phone"
                class="spinner-border spinner-border-sm"
                role="status"
              >
                <span class="visually-hidden">Loading...</span>
              </div>
              <span
                v-else
                class="far fa-arrow-right tx-text-gray"
              />
            </div>
          </li>
        </ul>
        <div class="d-flex flex-column">
          <app-button
            @click="showWhatsOnly"
            color="btn-outline-primary"
            data-test="showWhatsOnly-button"
          >
            {{ $t('only_whats_numbers') }}
          </app-button>
          <button
            class="btn btn-clear"
            @click="checkedPhones = []"
            data-test="clearNumbers-button"
          >
            {{ $t('clear_list') }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AppButton from "@components/app-button.vue";
import SelectChannel from "@components/select-channel.vue";
import {mapActions, mapGetters} from "vuex";
import {Offcanvas, Tooltip} from "bootstrap";
import { formatInternationalPhone } from "@/utils"

export default {
  name: "OffcanvasNewChat",

  components: {
    SelectChannel,
    AppButton
  },

  data() {
    return {
      offcanvas: null,
      tooltip: null,
      phonesString: "",
      textAreaRows: 1,
      checkedPhones: [],
      openedPhone: null,
      loading: false,
      fields: {
        instance_id: null,
        phones: [],
      }
    }
  },

  mounted() {
    this.offcanvas = new Offcanvas(this.$refs.canvas);
    this.tooltip = new Tooltip(this.$refs.phoneTooltip, {placement: "right"});
  },

  computed: {
    ...mapGetters("whatsapp/chat", {
      loadingFlags: "getLoadingFlags",
    }),
  },

  methods: {
    formatInternationalPhone,
    ...mapActions("whatsapp/chat", [
      "createNewChat",
      "checkContacts"
    ]),

    open() {
      this.offcanvas.show()
    },

    handlePhones({target}) {
      const text = target.value
      this.phonesString = text.replace(/[^0-9+\n]/g, "");

      let phonesArray = this.phonesString.split(/[;\n]/);
      this.textAreaRows = this.getNumberOfRows(phonesArray)

      let parsedPhonesArray = phonesArray.filter((phoneNumber) => phoneNumber != "")
      if (parsedPhonesArray.length > 15) {
        parsedPhonesArray = parsedPhonesArray.slice(0, 15);
        this.phonesString = parsedPhonesArray.join("\n")
        this.textAreaRows = this.getNumberOfRows(parsedPhonesArray)
      }

      this.fields.phones = parsedPhonesArray
    },

    getNumberOfRows(phonesArray) {
      if (!phonesArray.length){
        return 1
      }
      return phonesArray.length > 15 ? 15 : phonesArray.length
    },

    submit() {
      if (this.loading) {
        return;
      }

      this.loading = true
      if(this.fields.phones.length === 1) {
        this.createNewChat({
          fields: {
            instance_id: this.fields.instance_id,
            number: this.fields.phones[0],
          }
        }).then((chatId) => {
          this.$router.push({path: "/whatsapp/" + chatId});
          this.offcanvas.hide()
        }).finally(()=>{
          this.loading = false
        })
      }
      else {
        this.checkContacts({fields: this.fields}).then((checkedPhones)=> {
          this.checkedPhones = checkedPhones.numbers
          this.clearTextArea()
        }).finally(()=> {
          this.loading = false
        })
      }
    },

    newChat(phone) {
      this.openedPhone = phone
      this.createNewChat({
        fields: {
          instance_id: this.fields.instance_id,
          number: phone.number.replace(/([()-])/g, ""),
        }
      }).then((chatId) => {
        this.$router.push({path: "/whatsapp/" + chatId});
      }).finally(()=>{
        this.checkedPhones = this.checkedPhones.filter(el => el !== phone)
        this.openedPhone = null
      })
    },

    showWhatsOnly() {
      this.checkedPhones = this.checkedPhones.filter(phone => phone.isWhatsapp)
    },

    clearTextArea() {
      this.textAreaRows = 1
      this.phonesString = ""
      this.fields.phones = [];
    },
  }
}
</script>

<style lang="scss" scoped>
.offcanvas-new-chat {
  position: absolute;
  top: 0;
  border-radius: 0 0 0.75rem 0.75rem;
  height: min-content;
}

.btn-clear{
  color: $color-text-gray;
  &:hover{
    color: $color-blue-300;
  }
}

.phone {
  border-radius: 10px;
  cursor: pointer;
  padding: 0.5rem;
  margin: 0.25rem 0;

  &:hover{
    background-color: #E1E9F4;
  }
}
</style>
