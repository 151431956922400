<template>
  <div>
    <header-component title="CRM">
      <template #buttons>
        <div>
          <div class="d-flex gap-3 top-buttons">
            <router-link
              class="btn btn-gray"
              :to="{ path: '/manager/users' }"
              :title="$t('users_capitalize')"
            >
              <i class="fa-light fa-user" />
            </router-link>
            <router-link
              class="btn btn-gray"
              :to="{ path: '/manager/teams' }"
              :title="$t('teams_capitalize')"
            >
              <i class="fa-regular fa-users" />
            </router-link>
            <router-link
              class="btn btn-gray"
              :to="{ path: '/manager/products-and-services' }"
              :title="$tc('product', 2)"
            >
              <i class="fa-regular fa-box-taped" />
            </router-link>
            <router-link
              class="btn btn-gray"
              :to="{ path: '/manager/qualifications' }"
              :title="$t('qualifications_capitalize')"
            >
              <i class="fa-light fa-circle-check" />
            </router-link>
          </div>
        </div>
      </template>
    </header-component>
    <div class="banner d-flex align-items-center p-4 justify-content-between mb-4">
      <p class="m-0">
        <strong>{{ user.name }}</strong>, {{ phrase }}
      </p>
      <p class="m-0 text-capitalize">
        {{ date }}
      </p>
    </div>
    <funnels-list @open-modal="openModal" />
    <tour-steps @open-modal="openModal" />
    <other-tools />
    <img
      src="@assets/img/3czinhos.svg"
      class="d-block m-auto me-auto p-5"
    >
    <new-funnel-modal
      :show-modal="showModal"
      @close="closeModal"
    />
  </div>
</template>
<script>
import HeaderComponent from "@/components/header-component.vue";
import NewFunnelModal from "@crm/components/funnel/new-funnel-modal.vue";
import FunnelsList from "@crm/pages/manager/crm-tour/components/funnels-list.vue";
import {mapGetters} from "vuex";
import TourSteps from "@crm/pages/manager/crm-tour/components/tour-steps.vue";
import OtherTools from "@crm/pages/manager/crm-tour/components/other-tools.vue";


export default {
  name: "CrmHome",
  components: {OtherTools, TourSteps, FunnelsList, NewFunnelModal, HeaderComponent},
  computed: {
    ...mapGetters('auth', {
      user: 'getUser'
    }),
    ...mapGetters('crm/tour', {
      tourSteps: 'getTourSteps'
    })
  },
  data() {
    return {
      showModal: false,
      date: null,
      phrase: '',
      phrasesArray: [],
    }
  },
  mounted() {
    this.phrasesArray = this.$tm("crm_manager_phrases")
    this.generatePhrase();

  },
  methods: {

    generatePhrase() {
      this.date = new Date().toLocaleDateString('pt-BR', {
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric'
      });
      this.phrase = this.phrasesArray[Math.floor(Math.random() * this.phrasesArray.length)];
    },

    openModal() {
      this.showModal = true;
    },
    closeModal() {
      this.showModal = false;
    },


  }
}
</script>


<style scoped>
.btn-gray {
  color: #677C92;
  border-radius: 10px;
  background: #E1E9F4;
  width: 40px;
  height: 40px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.banner {
  background-image: url("~@assets/img/crmTexture.png");
  height: 88px;
  border-radius: 10px;
  border: 1px solid #E1E9F4;
  box-shadow: 0 12px 24px 0 rgba(18, 38, 63, 0.03);
  background-repeat: round;
}

.top-buttons .btn-gray:hover {
  background-color: #D2DDEC;
  color: #1B1B2B;
}
</style>
