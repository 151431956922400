import axios from "@crm/plugins/axios-config";

export default {
  fetchFunnels({commit}, teamId) {
    return new Promise((resolve, reject)=>{
      axios.get("/get-funnels-to-attach", {params: { team_id: teamId }})
        .then((response)=>{
          commit("setFunnels", response.data.data)
          resolve()
      }).catch(()=>{
        reject()
      })
    })
  },

  createFunnelAttach({commit}, {phoneNumber, funnelId}) {
    commit("startLoading", "createFunnelAttach")
    return new Promise((resolve, reject)=>{
      axios.post(`/contact/${phoneNumber}/funnel/${funnelId}/attach`)
        .then(()=>{
          commit('system/setSuccessMessage', "success_message.attach_contact_to_funnel", {root: true})
          resolve()
        }).catch((error)=>{
          if (error.response) {
            const firstKey = Object.keys(error.response.data.errors)[0]
            commit('system/setErrorMessage', error.response.data.errors[firstKey][0], { root: true })
          }
          else commit('system/setErrorMessage', "Erro ao atribuir contato ao funil", { root: true })
          reject()
      }).finally(()=>{
        commit("stopLoading", "createFunnelAttach")
      })
    })
  },
}
