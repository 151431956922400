<template>
  <svg
    width="40px"
    viewBox="0 0 40 16"
  >
    <line
      x1="0"
      y1="8"
      x2="39"
      y2="8"
      stroke="#5517E6"
      stroke-width="2"
    />
    <polygon
      points="32,0 40,8 32,16"
      fill="#5517E6"
      stroke-width="2"
    />
  </svg>
</template>

<script>
export default {
  name: "DefaultArrow"
};
</script>
