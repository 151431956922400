<template>
  <card-loading
    v-if="loadingFlagsPnS.syncProductOpportunity"
    :height="29"
  />
  <div
    v-else
    class="d-flex align-items-center gap-3"
  >
    <span
      v-if="hasProducts"
      class="fw-bold tx-text-200"
    >{{ formatLocaleMoney(totalGeneral, productCurrency) }}</span>

    <div
      v-if="hasProducts"
      class="product-badge d-flex align-items-center gap-2 rounded"
    >
      <span>{{ formatLocaleMoney(totalSingle, productCurrency) }}</span>
      <dot-status
        color="#575778"
        size="4px"
      />
      <i
        style="font-size: 0.625rem"
        class="fa-regular fa-arrows-repeat"
      />
      <span>{{ formatLocaleMoney(totalRecurrence, productCurrency) }}</span>
    </div>
    <dot-status
      v-if="hasProducts"
      color="#373753"
      size="4px"
    />
    <div
      class="tx-text-200"
      v-if="hasProducts"
    >
      <span>{{ opportunityProducts.length }}</span>
      <span class="ms-2">{{ $tc('product', opportunityProducts.length) }}</span>
    </div>

    <button
      v-if="opportunityStatus !== 'lost'"
      class="btn btn-link d-flex align-items-center gap-2  m-0 p-0 cursor-pointer border-0"
      type="button"
      @click="openNewProductModal"
    >
      <span v-if="!hasProducts">
        {{ $t('add_product') }}
      </span>
      <span class="btn btn-xs d-flex justify-content-center align-items-center border-0 p-0">
        <i class="fa-regular fa-plus" />
      </span>
    </button>
  </div>
  <new-product-modal
    :fetch-products="false"
    :show-modal="showNewProductModal"
    @close="closeNewProductModal"
  />
</template>
<script>
import DotStatus from "@components/dot-status.vue";
import {mapGetters} from "vuex";
import {formatLocaleMoney} from "@/utils"
import NewProductModal from "@crm/components/contact/products/new-product-modal.vue";
import CardLoading from "@components/card-loading.vue";

export default {
  name: "OportunityProductIndicator",
  components: {CardLoading, NewProductModal, DotStatus},
  props:{
    opportunityStatus: {
      type: String,
      default: 'active'
    }
  },
  data() {
    return {
      showNewProductModal: false,
      totalQuantity: 0,
      totalRecurrence: 0,
      totalSingle: 0,
      totalGeneral: 0,
      productCurrency: 'BRL',
    }
  },

  computed: {
    ...mapGetters("manager/products_and_services", {
      opportunityProducts: "getSynchronizedProducts",
      opportunityTotalAmount: "getOpportunityTotalAmount",
      loadingFlagsPnS: "getLoadingFlag"
    }),
  },

  watch: {
    opportunityProducts: {
      handler: 'calcProductAmount',
      immediate: true
    }
  },

  methods: {
    formatLocaleMoney,

    hasProducts() {
      return this.opportunityProducts && this.opportunityProducts.length > 0;
    },

    calcProductAmount() {
      this.totalSingle = 0;
      this.totalRecurrence = 0;
      this.totalQuantity = 0;
      this.totalGeneral = 0;

      this.opportunityProducts.forEach((row) => {
        if (row.is_active) {
          if (row.is_recurrent) {
            this.totalRecurrence += row.price_per_unit * row.quantity
          } else {
            this.totalSingle += row.price_per_unit * row.quantity
          }
          this.totalQuantity += row.quantity
          this.productCurrency = row.currency
        }
      })
      this.totalGeneral = this.totalSingle + this.totalRecurrence
    },

    openNewProductModal() {
      this.showNewProductModal = true;
    },

    closeNewProductModal() {
      this.showNewProductModal = false;
    }
  }
}
</script>

<style lang="scss" scoped>
.product-badge {
  padding: 0.25rem 0.5rem;
  color: #575778;
  background-color: $color-gray-blue-200;
  font-size: 0.875rem;
}

.btn-link {
  color: $color-text-200;

  .btn-xs {
    min-width: 1rem;
    min-height: 1rem;
    background-color: $color-gray-blue-300;
    color: $color-text-gray;
    border-radius: 4px;
    font-size: 0.625rem;
  }

  &:hover,
  &:focus,
  &:focus-visible {
    color: $color-blue-300;


    .btn-xs {
      background-color: $color-blue-300;
      color: #fff;
    }
  }
}
</style>