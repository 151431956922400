<template>
  <div
    ref="forwardedModal"
    class="modal prevent-multiples-connections"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div
        class="modal-content"
      >
        <div class="d-flex justify-content-end">
          <button
            type="button"
            class="btn-close fs-2 pb-0 my-2"
            data-bs-dismiss="modal"
            @click="selectedChat = null"
            aria-label="Close"
          />
        </div>
        <div class="modal-body">
          <div class="">
            <h2>{{ $t('forward_to') }}</h2>
            <search-input
              @search="search"
              style="color: red !important;
                  background-color: #edf2f9;
                  border-radius: 10px;
                  "
              class="col px-3"
              :placeholder="$t('to_search')"
            />
          </div>
          <hr class="my-4">
          <span style="color: #3057F2;"> {{ $t('recent_chats') }} </span>
          <div class="contact-list">
            <template v-if="Object.values(groups).length">
              <div
                class="py-0"
                style="border-bottom: none"
                v-for="item in groups"
                :key="item.id"
              >
                <div
                  :class="{
                    'row align-items-center py-2 truncate': true,
                    active: currentChat[this.$route.params.id] && item.id == currentChat[this.$route.params.id].id,
                  }"
                  style="cursor: pointer"
                  :style="selectedItem == item.id ? 'background-color: #E1E9F4' : ''"
                  @mouseover="selectedItem = item.id"
                  @mouseleave="selectedItem = null"
                  @click="selectedChat == item ? selectedChat = null : selectedChat = item"
                >
                  <div class="col-auto">
                    <span class="avatar">
                      <img
                        v-if="item.contact && item.contact.image && this.isValidImageUrl(item.contact.image)"
                        :src="item.contact.image"
                        alt="contact-image"
                        class="avatar-img rounded-circle"
                        @error="handleImageError"
                      >
                      <img
                        v-else
                        alt="contact-placeholder"
                        src="@assets/img/avatars/profiles/nome-user.png"
                        class="avatar-img rounded-circle"
                      >
                    </span>
                  </div>
                  <div class="col ms-n2">
                    <h4
                      class="mb-1"
                      v-if="item.contact.name_alias"
                    >
                      {{ item.contact.name_alias }}
                    </h4>
                    <h4
                      class="mb-1"
                      v-else-if="item.contact.name"
                    >
                      {{ item.contact.name }}
                    </h4>
                    <span
                      class="mb-1 text-muted fs-4 d-inline-block text-truncate"
                      style="max-width: 300px"
                    >
                      <div
                        class="d-inline"
                        v-for="subitem in item.participants"
                        :key="subitem.id"
                      >
                        {{ subitem.name != "" ? subitem.name : subitem.pushname }},
                      </div>
                    </span>
                  </div>
                </div>
              </div>
            </template>
            <template v-if="Object.values(inProgress).length">
              <div
                class="my-2"
                v-for="item in inProgress"
                :key="item.id"
                style="max-height: 100px !important;"
              >
                <div
                  class="row align-items-center py-2"
                  style="cursor: pointer"
                  :style="selectedItem == item.id ? 'background-color: #E1E9F4' : ''"
                  @mouseover="selectedItem = item.id"
                  @mouseleave="selectedItem = null"
                  @click="selectedChat == item ? selectedChat = null : selectedChat = item"
                >
                  <div class="col-auto">
                    <span class="avatar">
                      <img
                        v-if="item.contact && item.contact.image"
                        :src="item.contact.image"
                        class="avatar-img rounded-circle"
                      >
                      <img
                        v-else
                        src="@assets/img/avatars/profiles/nome-user.png"
                        class="avatar-img rounded-circle"
                      >
                    </span>
                  </div>
                  <div class="col ms-n2">
                    <h4
                      class="mb-1"
                      v-if="item.contact.name_alias"
                    >
                      {{ item.contact.name_alias }}
                    </h4>
                    <h4
                      class="mb-1"
                      v-else-if="item.contact.name"
                    >
                      {{ item.contact.name }}
                    </h4>
                    <span
                      class="mb-1 text-muted fs-4"
                    >
                      {{ mask(item.number, "+## ## #####-####") }}
                    </span>
                  </div>
                </div>
              </div>
            </template>
          </div>
        </div>
        <div
          v-if="selectedChat"
          class="col-12 footer shadow border-top rounded-bottom"
        >
          <div class="mx-5 mt-4">
            <h4
              class="mb-1"
              v-if="selectedChat.contact.name_alias"
            >
              {{ selectedChat.contact.name_alias }}
            </h4>
            <h4
              class="mb-1"
              v-else-if="selectedChat.contact.name"
            >
              {{ selectedChat.contact.name }}
            </h4>
          </div>
          <div
            class="mx-5"
            v-if="selectedChat.is_group"
          >
            <span
              class="mb-1 text-muted fs-4 d-inline-block text-truncate"
              style="max-width: 300px"
            >
              <div
                class="d-inline"
                v-for="item in selectedChat.participants"
                :key="item.id"
              >
                {{ item.name != "" ? item.name : item.pushname }},
              </div>
            </span>
          </div>
          <div
            class="mx-5"
            v-else
          >
            <span
              class="mb-1 text-muted fs-4"
            >
              {{ mask(selectedChat.number, "+## ## #####-####") }}
            </span>
          </div>
          <div class="float-end">
            <button
              @click="submit()"
              class="btn btn-primary btn-sm mb-4 me-4 forward-button"
              :disabled="loadingFlags.sendForward"
            >
              {{ $t('forward') }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import _ from "lodash";

import { Modal } from "bootstrap"
import SearchInput from "@components/search-input";
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { Utils, isValidImageUrl, handleImageError } from "@/utils";

export default {
  emits: ["sendForward"],
  data() {
    return {
      forwardedModal: null,
      selectedItem: "",

      selectedChat: null,
      fields: {
        message_id: null,
      },
    }
  },

  mounted() {
    this.forwardedModal = new Modal (this.$refs.forwardedModal, {
      backdrop: 'static',
    })
  },


  components: {
    SearchInput,
  },

  computed: {
    ...mapGetters("whatsapp/chat", {
      currentChat: "getCurrentChat",
      inProgress: "getInProgress",
      groups: "getGroups",
    }),

    ...mapGetters("whatsapp/message", {
      loadingFlags: "getLoadingFlags",
    }),

    ...mapGetters("auth", {
      user: "getUser",
    }),
  },

  methods: {
    mask: Utils.mask,
    isValidImageUrl,
    handleImageError,

    ...mapMutations("whatsapp/chat", [
      "setFilters",
    ]),
    ...mapActions("whatsapp/chat", [
      "clearChats"
    ]),

    submit() {
      this.$emit('sendForward', this.selectedChat, this.fields)
      this.selectedChat = null;
    },

    open(item) {
    this.fields = item;
      this.forwardedModal.show();
    },


    close(){
      this.forwardedModal.hide();
    },

    search: _.debounce(function(event) {
      this.clearChats();
      this.setFilters({
        search: event,
      });
      this.findChats();
    }, 500)
  },
}
</script>

<style lang="scss" scoped>
  .search {
    background-color: #f5f5f5;
    border-radius: 20px;
    font-weight: 200;
  }
  .footer {
    bottom: 0;
    left: 0;
    background-color: #FFFFFF;
  }
  .contact-list {
    max-height: 300px !important;
    overflow-y: scroll !important;
    overflow-x: hidden;
  }
  .forward-button {
    border-radius: 6px;
  }
</style>
