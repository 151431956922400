<template>
  <div>
    <div class="form-group mb-4">
      <div
        class="card mb-0"
        :class="{
          'is-invalid': errors && errors.length
        }"
      >
        <div class="card-header">
          <h4 class="m-0">
            {{ $t('contacts_list') }}
          </h4>
        </div>
        <div class="card-body">
          <span
            class="form-text fs-5"
            style="margin-bottom: 1rem"
          >
            {{ $t('upload_csv_file') }}
          </span>
          <file-uploader
            @select-file="selectFile"
            @removed-file="file = null"
            filter="text/csv,text/plain"
            icon="fa-regular fa-database"
          />
        </div>
      </div>
      <small
        v-if="errors && errors.length > 0"
        class="text-danger"
      >
        {{ errors[0] }}
      </small>
    </div>
    <app-button
      class="w-100"
      :loading="loadingFlags.createUploadCsv"
      @click="submit"
    >
      {{ $t('validate_list') }}
    </app-button>
  </div>
</template>

<script>
import FileUploader from "@components/file-uploader.vue";
import {mapActions, mapGetters} from "vuex";
import AppButton from "@components/app-button.vue";

export default {
  name: "CsvUpload",

  components: {
    AppButton,
    FileUploader
  },

  data() {
    return {
      csvFile: null,
      file: null
    }
  },

  computed: {
    ...mapGetters("crm/imports", {
      errors: "getErrors",
      loadingFlags: "getLoadingFlags"
    })
  },

  methods: {
    ...mapActions('crm/imports', ['createUploadCsv']),

    selectFile (file) {
      this.file = file;
    },

    submit () {
      let formData = new FormData();
      formData.append('opportunities', this.file)
      formData.append("separator", ',');
      formData.append("delimiter", 'quotes');
      formData.append("has_header", 1);
      this.createUploadCsv({ formData })
    }
  }
}
</script>
