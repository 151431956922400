import axios from "axios";

export default {

  /**
   * List active receptive queues that belongs to the company
   * of the authenticated user
   * @param {Object} state
   * @param {Object} payload
   * @returns {void}
   */
  loadActiveReceptiveQueues({ commit }, payload) {
    commit("startLoading");
    return new Promise((resolve, reject) => {
      axios
        .get("/receptive_queues", { params: payload })
        .then(response => {
          commit("setQueues", response.data.data);
          resolve();
        })
        .catch((error) => {
          commit("system/setErrorMessage", error.response.data, { root: true });
          reject();
        })
        .finally(() => {
          commit("stopLoading");
        });
    });
  },

  /**
   * List all active receptive calls group by queue id
   * @param {Object} state
   * @returns {void}
   */
  loadReceptiveCalls({ commit }) {
    commit("startLoading");

    return new Promise((resolve, reject) => {
      axios
        .get("/calls/receptive")
        .then(response => {
          commit("setCalls", response.data.data);
          resolve();
        })
        .catch((error) => {
          commit("system/setErrorMessage", error.response.data, { root: true });
          reject();
        })
        .finally(() => {
          commit("stopLoading");
        });
    });
  },

  /**
   * Bring a list of the metrics of each receptive queue from the company
   * @param {Object} state
   * @returns {void}
   */
  loadReceptiveMetrics({ commit }) {
    commit("startLoading");

    return new Promise((resolve, reject) => {
      axios
        .get("/company/receptive_metrics")
        .then(response => {
          commit("setMetrics", response.data.data);
          resolve();
        })
        .catch((error) => {
          commit("system/setErrorMessage", error.response.data, { root: true });
          reject();
        })
        .finally(() => {
          commit("stopLoading");
        });
    });
  },

  /**
   * List receptive queues along with their agents that
   * belongs to the company of the authenticated user
   *
   * @param {Object} state
   * @returns {void}
   */
  loadReceptiveAgents({ commit }) {
    commit("startLoading");

    return new Promise((resolve, reject) => {
      axios
        .get("/receptive_queues/agents")
        .then(response => {
          commit("setAgents", response.data.data);
          resolve();
        })
        .catch((error) => {
          commit("system/setErrorMessage", error.response.data, { root: true });
          reject();
        })
        .finally(() => {
          commit("stopLoading");
        });
    });
  },

  /**
   * Retrieve a receptive queue from the user's company
   * based on an given ID
   *
   * @param {Object} state
   * @param {Object} payload
   * @returns {void}
   */
  findReceptiveQueueById({ commit }, payload) {
    commit("startLoadingContext", "find_receptive_queue_by_id");
    commit("setReceptiveQueue", null);
    commit("clearErrors");
    return new Promise((resolve, reject) => {
      axios
        .get(`/receptive_queues/${payload.id}`)
        .then(response => {
          commit("setReceptiveQueue", response.data.data);
          resolve();
        })
        .catch((error) => {
          commit("system/setErrorMessage", error.response.data, { root: true });
          reject();
        })
        .finally(() => {
          commit("stopLoadingContext", "find_receptive_queue_by_id");
        });
    });
  },

  activeQueue({ commit }, payload) {
    commit("startLoading");
    commit("setQueues", []);
    return new Promise((resolve, reject) => {
      axios
        .get("/receptive_queues/active", {
          params: {
            search: payload ? payload.search : undefined,
          },
        })
        .then(response => {
          commit("setQueues", response.data.data);
          resolve();
        })
        .catch(() => {
          reject();
        })
        .finally(() => {
          commit("stopLoading");
        });
    });
  },

  /**
   *  Retrieve only the active agents of the company
   *
   * @param {Object} param0
   * @returns
   */
  findActiveAgents({ commit }) {
    commit("setActiveAgents", []);
    commit("startLoadingContext", "find_active_agents");
    return new Promise((resolve, reject) => {
      axios
        .get("/agents?all=true&status=active")
        .then(response => {
          commit("setActiveAgents", response.data.data);
          resolve();
        })
        .catch((error) => {
          commit("system/setErrorMessage", error.response.data, { root: true });
          reject();
        })
        .finally(() => {
          commit("stopLoadingContext", "find_active_agents");
        });
    });
  },

  /**
   *
   * @param {Object} param0
   * @param {Object} payload
   * @returns
   */
  createReceptiveQueue({ commit }, payload) {
    const headers = { "Content-Type": "multipart/form-data" };

    commit("clearErrors");
    commit("startLoadingContext", "create_receptive_queue");
    return new Promise((resolve, reject) => {
      axios
        .post("/receptive_queues", payload.formData, { headers })
        .then((response) => {
          commit(
            "system/setSuccessMessage",
            "success_message.create_receptive_queue",
            { root: true },
          );
          resolve({ id: response.data.data.id });
        })
        .catch(error => {
          commit("setErrors", error.response.data.errors);
          commit("system/setErrorMessage", error.response.data, { root: true });
          reject();
        })
        .finally(() => {
          commit("stopLoadingContext", "create_receptive_queue");
        });
    });
  },

  /**
   *
   * @param {Object} param0
   * @param {Object} payload
   * @returns
   */
  updateReceptiveQueue({ commit }, payload) {
    const headers = { "Content-Type": "multipart/form-data" };

    commit("clearErrors");
    commit("startLoadingContext", "update_receptive_queue");
    return new Promise((resolve, reject) => {
      axios
        .post(`/receptive_queues/${payload.id}`, payload.formData, { headers })
        .then(() => {
          commit(
            "system/setSuccessMessage",
            "success_message.update_receptive_queue",
            { root: true },
          );
          resolve();
        })
        .catch(error => {
          commit("setErrors", error.response.data.errors);
          commit("system/setErrorMessage", error.response.data, { root: true });
          reject();
        })
        .finally(() => {
          commit("stopLoadingContext", "update_receptive_queue");
        });
    });
  },

  /**
   *
   * @param {Object} param0
   * @param {Object} payload
   * @returns
   */
  deleteReceptiveQueue({ commit }, payload) {
    commit("clearErrors");
    commit("startLoadingContext", "delete_receptive_queue");
    return new Promise((resolve, reject) => {
      axios
        .delete(`/receptive_queues/${payload.id}`)
        .then(() => {
          commit(
            "system/setSuccessMessage",
            "success_message.delete_receptive_queue",
            { root: true },
          );
          resolve();
        })
        .catch(error => {
          error.response.data.errors && commit("setErrors", error.response.data.errors);
          commit("system/setErrorMessage", error.response.data, { root: true });
          reject();
        })
        .finally(() => {
          commit("stopLoadingContext", "delete_receptive_queue");
        });
    });
  },

  findQualificationsMetrics({ commit }, payload) {
    commit("startLoading");
    commit("setQualificationsMetrics", null);

    return new Promise((resolve, reject) => {
      axios
        .get(`/receptive_queues/${payload.id}/metrics/qualifications`)
        .then(response => {
          commit("setQualificationsMetrics", response.data.data);
          resolve();
        })
        .catch(response => {
          reject(response);
        })
        .finally(() => {
          commit("stopLoading");
        });
    });
  },
  clearQualificationsMetrics({ commit }) {
    commit("setQualificationsMetrics", null);
  },
  clearReceptiveQueuesData({ commit }) {
    commit("clearReceptiveQueuesData");
  },
};
