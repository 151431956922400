<template>
  <select
    class="form-select"
    :class="{
      'is-invalid': errors
    }"
    :disabled="loadingFlags.findFilters"
    @input="$emit('update:modelValue', $event.target.value)"
    :value="modelValue"
  >
    <option
      :label="$t('none')"
      value=""
    />
    <option
      :label="work_break_group.name"
      v-for="work_break_group in work_break_groups"
      :key="work_break_group.id"
      :value="work_break_group.id"
    />
  </select>
  <div class="invalid-feedback">
    {{ errors && errors[0] }}
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  emits: ["update:modelValue"],
  data() {
    return {
      work_break_groups: []
    }
  },

  mounted() {
    this.setFiltersIncludes(["work_break_groups"]);
    this.findFilters();
  },

  computed: {
    ...mapGetters("manager/filters", {
      filters: "getFilters",
      loadingFlags: "getLoadingFlags"
    })
  },

  props: {
    modelValue: {
      default: "",
      type: [Number, String]
    },

    errors: {
      type: Array,
      default: null
    }
  },

  methods: {
    ...mapActions("manager/filters", ["findFilters", "setFiltersIncludes"]),
  },

  watch: {
    filters: {
      handler() {
        if (this.filters && this.filters.work_break_groups) {
          this.work_break_groups = this.filters.work_break_groups;
        }
      },
      deep: true
    }
  }
};
</script>
