import axios from "axios";
import router from "../../../router";

export default {
  /**
   * autenticate
   *
   * @param {*} param0
   * @param {*} payload
   */
  login({ commit }, payload) {
    commit("clearErrors");
    commit("startLoading");
    commit("startLoading", "login");
    commit("setLogoutCause", "");

    return new Promise((resolve, reject) => {
      axios
        .post(
          `/authenticate?user=${payload.user}&include=company.partner,company.tags,teams.instances,permissions&check_conflict=1`,
          payload,
        )
        .then(response => {
          if (response.data.status === 203) {
            if (response.data.data.two_factor_location_status === "otp_required") {
              commit("setUserLogin", payload);
              commit("setTwoFa", response.data.data);
              router.push({
                name: "two-fa-code",
              });
            } else if (response.data.data.two_factor_location_status === "expired") {
              commit("setUserLogin", payload);
              const twoFaInfo = response.data.data;
              twoFaInfo.location_identifier = localStorage.getItem("location_identifier");
              commit("setTwoFa", response.data.data);
              router.push({
                name: "two-fa-code",
              });
            } else {
              commit("setUserLogin", payload);
              commit("setTwoFa", response.data.data);
              localStorage.setItem("user", payload.user);
              localStorage.setItem("location_identifier", response.data.data.location_identifier);
              router.push({
                name: "two-fa-qr-code",
              });
            }
          } else {
            commit("setUser", response.data.data);
            commit("setIsRecentlyLogged");

            let roleName = response.data.data.role.name.charAt(0).toUpperCase() + response.data.data.role.name.slice(1);
            commit("trackEvents", `[${roleName}] Login`);

            axios.defaults.headers["Authorization"] = "Bearer " + response.data.data.api_token;
          }
          resolve();
        })
        .catch(response => {
          if (response.response.status == 423) {
            commit("setErrors", response.response.data.errors);
            reject();
          }
          if (response.response.status == 409) {
            reject({ conflict: true });
          } else {
            commit("system/setErrorMessage", response.response.data, { root: true });

            response.response.status == 422 ||
            (response.response.status == 401 && commit("setErrors", response.response.data.errors));
            reject();
          }
        })
        .finally(() => {
          commit("stopLoading", "login");
        });
    });
  },

  loginTwoFa({ commit }, payload) {
    commit("clearErrors");
    commit("startLoading", "loginTwoFa");
    commit("setLogoutCause", "");

    return new Promise((resolve, reject) => {
      axios
        .post("/two-factor/callback", payload)
        .then(() => {
          resolve();
        })
        .catch(response => {
          if (response.response.status == 422 || response.response.status == 401) {
            commit("setErrors", response.response.data.errors);
          }
          reject();
        })
        .finally(() => {
          commit("stopLoading", "loginTwoFa");
        });
    });
  },

  /**
   * notify another session to self logout
   *
   * @param {*} param0
   * @param {*} payload
   */
  notifyLogout({ commit }, payload) {
    commit("startLoading", "notifyLogout");
    return new Promise((resolve, reject) => {
      axios
        .post("/forced-logout", payload)
        .then(() => {
          resolve();
        })
        .catch(response => {
          commit("system/setErrorMessage", response.response.data, { root: true });
          response.response.status == 422 ||
          (response.response.status == 401 && commit("setErrors", response.response.data.errors));
          reject();
        })
        .finally(() => {
          commit("stopLoading", "notifyLogout");
        });
    });
  },

  /**
   *
   */
  ping() {
    axios.post("/ping");
  },

  /**
   *
   * @param {*} param0
   */
  logout({ commit, dispatch }) {
    commit("startLoading", "logout");
    return new Promise((resolve, reject) => {
      axios
        .get("/logout")
        .then(() => {
          delete axios.defaults.headers["Authorization"];
          localStorage.removeItem("instanceId");
          dispatch("resetState", null, { root: true });

          commit("setUser", null);
          commit("system/setJsSIPActive", false, { root: true });
          commit("system/setSocketIOActive", false, { root: true });
          resolve();
        })
        .catch(response => {
          commit("system/setErrorMessage", response.response.data, { root: true });
          response.response.status == 422 ||
          (response.response.status == 401 && commit("setErrors", response.response.data.detail));
          reject();
        })
        .finally(() => {
          commit("stopLoading", "logout");
        }).then(() => {
      });
    });
  },

  /**
   *
   * @param {*} param0
   * @returns
   */
  changeFrontEnd({ commit }) {
    commit("clearErrors");
    commit("startLoading");
    return new Promise((resolve, reject) => {
      axios
        .get("/company/change-front-end")
        .then(() => {
          resolve();
        })
        .catch(response => {
          response.response.status == 422 &&
          response.response.data.errors &&
          commit("setErrors", response.response.data.errors);
          reject({ message: response.response.data.detail });
        })
        .finally(() => {
          commit("stopLoading");
        });
    });
  },
  /**
   *
   * @param {*} param0
   * @returns
   */
  me({ commit, getters }) {
    commit("startLoading", "updateUser");

    return new Promise((resolve, reject) => {
      axios
        .get(`/me`, { params: getters.getParams })
        .then(response => {
          commit("refreshUser", response.data.data);
          resolve();
        })
        .catch(response => {
          response.response.status == 422 &&
          response.response.data.errors &&
          commit("setErrors", response.response.data.errors);
          reject({ message: response.response.data.detail });
        })
        .finally(() => {
          commit("stopLoading", "updateUser");
        });
    });
  },

  /**
   *
   */
  enterImpersonateMode({ commit, getters }, payload) {
    commit("startLoading");
    commit("setImpersonate", false);
    commit("setImpersonateUserBackup", null);
    return new Promise((resolve, reject) => {
      axios
        .post(
          "/companies/" +
          payload.id +
          "/impersonate?include=company.partner,company.tags,permissions,teams.instances,ring_groups",
        )
        .then(response => {
          commit("setImpersonateUserBackup", getters.getUser);
          commit("setUser", response.data.data);
          commit("setImpersonate", true);
          resolve();
        })
        .catch(response => {
          commit("system/setErrorMessage", response.response.data, { root: true });
          reject();
        })
        .finally(() => {
          commit("stopLoading");
        });
    });
  },

  /**
   *
   * @param {*} param0
   */
  leftImpersonateMode({ commit, getters }) {
    return new Promise(resolve => {
      commit("endAllRealTimeSessions");
      commit("setUser", getters.getImpersonateUserBackup);
      commit("setImpersonateUserBackup", null);
      commit("setImpersonate", false);
      resolve();
    });
  },

  /**
   *
   * @param {*} param0
   * @param {*} payload
   */
  requestPasswordChange({ commit }, payload) {
    commit("startLoading", "requestPasswordChange");
    return new Promise((resolve, reject) => {
      axios
        .post("/request-password-change", payload)
        .then(response => {
          commit("system/setSuccessMessage", response.data.detail, { root: true });
          resolve();
        })
        .catch(response => {
          response.response.status === 422 && commit("setErrors", response.response.data.errors);
          commit("system/setErrorMessage", response.response.data, { root: true });
          reject();
        })
        .finally(() => {
          commit("stopLoading", "requestPasswordChange");
        });
    });
  },

  /**
   * Updates the user's password
   *
   * @param commit
   * @param getters
   * @param payload
   * @returns {Promise<unknown>}
   */
  resetPassword({ commit }, payload) {
    commit("startLoading", "resetPassword");
    return new Promise((resolve, reject) => {
      axios
        .post("/reset-password?include=company.tags,tags", payload)
        .then(response => {
          commit("system/setSuccessMessage", response.data.detail, { root: true });
          commit("setUser", response.data.data);
          resolve();
        })
        .catch(error => {
          error.response.status === 422 && commit("setErrors", error.response.data.errors);
          commit("system/setErrorMessage", error.response.data, { root: true });
          reject();
        })
        .finally(() => {
          commit("stopLoading", "resetPassword");
        });
    });
  },
  createPartner({ commit }, payload) {
    commit("startLoading", "createPartner");
    return new Promise((resolve, reject) => {
      axios
        .post("/partners/", payload)
        .then(() => {
          resolve();
        })
        .catch(error => {
          commit("system/setErrorMessage", error.response.data, { root: true });
          error.response.status == 422 && error.response.data.errors && reject();
        })
        .finally(() => {
          commit("stopLoading", "createPartner");
        });
    });
  },
  checkIsValidEmail({ commit }, payload) {
    commit("startLoading", "checkEmail");
    return new Promise((resolve, reject) => {
      axios
        .post("/partners/check_email", payload)
        .then(() => {
          resolve();
        })
        .catch(error => {
          commit("system/setErrorMessage", error.response.data, { root: true });
          reject();
        })
        .finally(() => {
          commit("stopLoading", "checkEmail");
        });
    });
  },
  resendEmail({ commit }, payload) {
    commit("startLoading", "resendEmail");
    return new Promise((resolve, reject) => {
      axios
        .post("/partner/resend_email_verification", payload)
        .then(() => {
          commit("system/setSuccessMessage", "success_message.send_email", { root: true });
          resolve();
        })
        .catch(error => {
          commit("system/setErrorMessage", error.response.data, { root: true });
          reject();
        })
        .finally(() => {
          commit("stopLoading", "resendEmail");
        });
    });
  },
  confirmationEmail({ commit }, { id, payload }) {
    commit("startLoading", "confirmationEmail");
    return new Promise((resolve, reject) => {
      axios
        .post(`/partners/verify_email/${id}`, payload)
        .then(() => {
          resolve();
        })
        .catch(error => {
          commit("system/setErrorMessage", error.response.data, { root: true });
          reject();
        })
        .finally(() => {
          commit("stopLoading", "confirmationEmail");
        });
    });
  },
  getAllStates({ commit, rootState }) {
    commit("setAllStates", rootState);
  },

  getCurrentCompany({ commit, rootState }) {
    commit("setCurrentCompany", rootState.admin.companies.register);
  },
  clearErrors({ commit }) {
    commit("clearErrors");
  },
};
