import axios from "axios";

export default {
  loadRingGroups({ commit, getters }) {
    commit('startLoadingFlag', 'loadRingGroups')

    return new Promise((resolve, reject) => {
      axios
        .get('/ring-groups', { params: getters.getParams })
        .then(response => {
          commit('setPagination', response.data.meta.pagination);
          commit('setRingGroups', response.data.data);
          resolve();
        })
        .catch((error) => {
          commit("system/setErrorMessage", error.response.data, { root: true });
          reject();
        })
        .finally(() => {
          commit('stopLoadingFlag', 'loadRingGroups');
        });
    });
  },

  loadRingGroupCalls({ commit }) {
    commit("startLoadingFlag", "loadRingGroupCalls");

    return new Promise((resolve, reject) => {
      axios
        .get("/calls/receptive/ring-groups")
        .then(response => {
          commit("setCalls", response.data.data);
          resolve();
        })
        .catch((error) => {
          commit("system/setErrorMessage", error.response.data, { root: true });
          reject();
        })
        .finally(() => {
          commit("stopLoadingFlag", "loadRingGroupCalls");
        });
    });
  },

  loadRingGroupMetrics({ commit }) {
    commit("startLoadingFlag", "loadRingGroupMetrics");

    return new Promise((resolve, reject) => {
      axios
        .get(`/company/ring-groups/receptive-metrics`)
        .then(response => {
          commit("setMetrics", response.data.data);
          resolve();
        })
        .catch((error) => {
          commit("system/setErrorMessage", error.response.data, { root: true });
          reject();
        })
        .finally(() => {
          commit("stopLoadingFlag", "loadRingGroupMetrics");
        });
    });
  },

  loadRingGroupAgents({ commit }) {
    commit("startLoadingFlag", "loadRingGroupAgents");

    return new Promise((resolve) => {
      axios
        .get("/ring-groups/users")
        .then(response => {
          commit("setAgents", response.data.data);
          resolve();
        })
        // .catch((error) => {
        //   // commit("system/setErrorMessage", error.response.data, { root: true });
        //   reject();
        // })
        .finally(() => {
          commit("stopLoadingFlag", "loadRingGroupAgents");
        });
    });
  },

  findRingGroupById({ commit }, payload) {
    commit("startLoadingFlag", "findRingGroupById");
    commit("setRingGroup", null);
    commit("clearErrors");
    return new Promise((resolve, reject) => {
      axios
        .get(`/ring-group/${payload.id}`)
        .then(response => {
          commit("setRingGroup", response.data.data);
          resolve();
        })
        .catch((error) => {
          commit("system/setErrorMessage", error.response.data, { root: true });
          reject();
        })
        .finally(() => {
          commit("stopLoadingFlag", "findRingGroupById");
        });
    });
  },

  activeRingGroup({ commit }, payload) {
    commit("startLoadingFlag", "activeRingGroup");
    commit("setGroups", []);
    return new Promise((resolve, reject) => {
      axios
        .get("/ring-groups/active", {
          params: {
            search: payload ? payload.search : undefined
          }
        })
        .then(response => {
          commit("setGroups", response.data.data);
          resolve();
        })
        .catch(() => {
          reject();
        })
        .finally(() => {
          commit("stopLoadingFlag", "activeRingGroup");
        });
    });
  },

  findActiveAgents({ commit }, payload) {
    commit("setActiveAgents", []);
    commit("startLoadingFlag", "findActiveAgents");
    return new Promise((resolve, reject) => {
      axios
        .get(`/ring-group/${payload.id}/users-status`)
        .then(response => {
          commit("setActiveAgents", response.data.data);
          resolve();
        })
        .catch((error) => {
          commit("system/setErrorMessage", error.response.data, { root: true });
          reject();
        })
        .finally(() => {
          commit("stopLoadingFlag", "findActiveAgents");
        });
    });
  },

  createRingGroup({ commit }, payload) {
    const headers = { "Content-Type": "multipart/form-data" };

    commit("clearErrors");
    commit("startLoadingFlag", "createRingGroup");
    return new Promise((resolve, reject) => {
      axios
        .post("/ring-group", payload.formData, { headers })
        .then((response) => {
          commit(
            'system/setSuccessMessage',
            "success_message.create_extension_group",
            { root: true }
          );
          resolve({ id: response.data.data.id });
        })
        .catch(error => {
          commit("setErrors", error.response.data.errors);
          commit("system/setErrorMessage", error.response.data, { root: true });
          reject();
        })
        .finally(() => {
          commit("stopLoadingFlag", "createRingGroup");
        });
    });
  },

  updateRingGroup({ commit }, payload) {
    const headers = { "Content-Type": "multipart/form-data" };

    commit("clearErrors");
    commit("startLoadingFlag", "updateRingGroup");
    return new Promise((resolve, reject) => {
      axios
        .post(`/ring-group/${payload.id}`, payload.formData, { headers })
        .then((response) => {
          commit(
            'system/setSuccessMessage',
            "success_message.update_extension_group",
            { root: true }
          );
          resolve({ id: response.data.data.id });
        })
        .catch(error => {
          commit("setErrors", error.response.data.errors);
          commit("system/setErrorMessage", error.response.data, { root: true });
          reject();
        })
        .finally(() => {
          commit("stopLoadingFlag", "updateRingGroup");
        });
    });
  },

  deleteRingGroup({ commit }, payload) {
    commit("clearErrors");
    commit("startLoadingFlag", "deleteRingGroup");
    return new Promise((resolve, reject) => {
      axios
        .delete(`/ring-group/${payload.id}`)
        .then(() => {
          commit(
            'system/setSuccessMessage',
            "success_message.delete_extension_group",
            { root: true }
          );
          resolve();
        })
        .catch(error => {
          commit("setErrors", error.response.data.errors);
          commit("system/setErrorMessage", error.response.data, { root: true });
          reject();
        })
        .finally(() => {
          commit("stopLoadingFlag", "deleteRingGroup");
        });
    });
  },

  findMetrics({ commit }, payload) {
    commit("startLoadingFlag", "findMetrics");
    commit("setRingGroupMetrics", []);

    return new Promise((resolve, reject) => {
      axios
        .get(`/ring-group/${payload.id}/metrics`)
        .then(response => {
          commit("setRingGroupMetrics", response.data.data);
          resolve();
        })
        .catch(response => {
          reject(response);
        })
        .finally(() => {
          commit("stopLoadingFlag", "findMetrics");
        });
    });
  },

  findConsultMetrics({ commit }, payload) {
    commit("startLoadingFlag", "findConsultMetrics");
    commit("setConsultMetrics", null);

    return new Promise((resolve, reject) => {
      axios
        .get(`/ring-group/${payload.id}/consult/metrics`)
        .then(response => {
          commit("setConsultMetrics", response.data.data);
          resolve();
        })
        .catch(response => {
          reject(response);
        })
        .finally(() => {
          commit("stopLoadingFlag", "findConsultMetrics");
        });
    });
  },

  findQualificationsMetrics({ commit }, payload) {
    commit("startLoadingFlag", "findQualificationsMetrics");
    commit("setQualificationsMetrics", null);

    return new Promise((resolve, reject) => {
      axios
        .get(`/ring-group/${payload.id}/metrics/qualifications`)
        .then(response => {
          commit("setQualificationsMetrics", response.data.data);
          resolve();
        })
        .catch(response => {
          reject(response);
        })
        .finally(() => {
          commit("stopLoadingFlag", "findQualificationsMetrics");
        });
    });
  },

  findCalls({ commit }, payload) {
    commit("startLoadingFlag", "findCalls");

    commit("setActiveCalls", []);

    return new Promise((resolve, reject) => {
      axios
        .get(`/ring-group/${payload.id}/calls`)
        .then(response => {
          commit("setActiveCalls", response.data.data);
          resolve();
        })
        .catch(response => {
          reject(response);
        })
        .finally(() => {
          commit("stopLoadingFlag", "findCalls");
        });
    });
  },

  setFilters({ commit }, filters) {
    commit("setFilters", filters );
  },

  setIncludes({ commit }, includes) {
    commit("setIncludes", includes);
  },

  clearRingGroupData({ commit }) {
    commit("clearRingGroupData");
  }
};
