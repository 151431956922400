<template>
  <div
    id="offcanvasNewAction"
    ref="offcanvas"
    aria-labelledby="offcanvasNewActionLabel"
    class="offcanvas offcanvas-end"
    tabindex="-1"
  >
    <div class="offcanvas-header">
      <h5
        id="offcanvasNewActionLabel"
        class="offcanvas-title"
      >
        {{ $t('new_step') }}
      </h5>
      <button
        aria-label="Close"
        class="btn-close"
        type="button"
        @click="offcanvas.hide()"
      />
    </div>
    <div
      v-if="!selectedAction"
      class="offcanvas-body d-flex flex-column"
      style="gap: 1rem"
    >
      <template v-if="enabledComapnies.includes(user.company.id)">
        <p class="m-0 fw-medium">
          {{ $t('generate_answers_by_ai') }}
        </p>
        <div
          class="d-flex flex-column"
          style="padding-left: 1rem; gap: 1rem"
        >
          <action-component
            class="cursor-pointer"
            icon-bg="#E1E9F4"
            icon-class="fa-regular fa-microchip-ai"
            icon-color="#3057F2"
            :name="$t('sdr_ai_qualification')"
            @click="selectedAction = 'sdr'"
          />
        </div>
      </template>
      <p class="m-0 fw-medium">
        {{ $t('messages') }}
      </p>
      <div
        class="d-flex flex-column"
        style="padding-left: 1rem; gap: 1rem"
      >
        <action-component
          class="cursor-pointer"
          icon-bg="#D6E9D9"
          icon-class="fa-regular fa-text"
          icon-color="#015D0D"
          :name="$t('send_a_message')"
          @click="selectedAction = 'message'"
        />
        <action-component
          class="cursor-pointer"
          icon-bg="#D6E9D9"
          icon-class="fa-regular fa-question"
          icon-color="#015D0D"
          :name="$t('make_a_question')"
          @click="selectedAction = 'question'"
        />
        <action-component
          class="cursor-pointer"
          icon-bg="#D6E9D9"
          icon-class="fa-regular fa-messages-question"
          icon-color="#015D0D"
          :name="$t('multiple_choice_question')"
          @click="selectedAction = 'choice'"
        />
      </div>
      <p class="m-0 fw-medium">
        {{ $t('action_capitalize') }}
      </p>
      <div
        class="d-flex flex-column"
        style="padding-left: 1rem; gap: 1rem"
      >
        <action-component
          class="cursor-pointer"
          icon-bg="#F7EAC4"
          icon-class="fa-regular fa-arrow-right-arrow-left"
          icon-color="#754000"
          :name="$t('transfer_to_wait_queue')"
          @click="selectedAction = 'transfer'"
        />
        <action-component
          class="cursor-pointer"
          icon-bg="#F7EAC4"
          icon-class="fa-regular fa-rotate-left"
          icon-color="#754000"
          :name="$t('back_capitalize')"
          @click="createBack"
        />
        <action-component
          class="cursor-pointer"
          icon-bg="#F7EAC4"
          icon-class="fa-regular fa-play"
          icon-color="#754000"
          :name="$t('restart_chatbot')"
          @click="createRestart"
        />
        <action-component
          class="cursor-pointer"
          icon-bg="#F7EAC4"
          icon-class="fa-regular fa-x"
          icon-color="#754000"
          :name="$t('finalize_chatbot')"
          @click="createFinish"
        />
      </div>
    </div>
    <create-sdr
      v-else-if="selectedAction === 'sdr'"
      @close="cleanAndCloseOffcanvas"
      @return="selectedAction = null"
    />
    <create-message
      v-else-if="selectedAction === 'message'"
      @close="cleanAndCloseOffcanvas"
      @return="selectedAction = null"
    />
    <create-question
      v-else-if="selectedAction === 'question'"
      @close="cleanAndCloseOffcanvas"
      @return="selectedAction = null"
    />
    <create-choice
      v-else-if="selectedAction === 'choice'"
      @close="cleanAndCloseOffcanvas"
      @return="selectedAction = null"
    />
    <create-transfer
      v-else-if="selectedAction === 'transfer'"
      @close="cleanAndCloseOffcanvas"
      @return="selectedAction = null"
    />
  </div>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from "vuex";
import {Offcanvas} from "bootstrap";

import ActionComponent from "@chatbot/pages/workflow/components/action-component.vue";
import CreateMessage from "./create-message.vue";
import CreateQuestion from "./create-question.vue";
import CreateChoice from "./create-choice.vue";
import CreateTransfer from "./create-transfer.vue";
import CreateSdr from "@chatbot/pages/workflow/components/offcanvas/offcanvas-new-action/create-sdr.vue";

export default {
  name: "OffcanvasNewAction",

  components: {
    CreateSdr,
    CreateTransfer,
    CreateChoice,
    CreateQuestion,
    CreateMessage,
    ActionComponent
  },

  props: {
    show: {
      type: Boolean,
      default: false,
    }
  },

  data() {
    return {
      offcanvas: null,
      selectedAction: null,
      enabledComapnies: [64, 1, 3283]
    }
  },

  mounted() {
    this.offcanvas = new Offcanvas(this.$refs.offcanvas)
    this.$refs.offcanvas.addEventListener("hide.bs.offcanvas", this.onHideOffcanvas);
  },

  computed: {
    ...mapGetters("chatbot/actions", {
      actions: "getActions",
      prevActionId: "getPrevActionId"
    }),
    ...mapGetters("auth", {
      user: "getUser",
    })
  },

  methods: {
    ...mapActions("chatbot/actions", ["updateAction"]),

    ...mapMutations("chatbot/actions", [
      "setOffcanvasNewActionIsOpen",
    ]),

    onHideOffcanvas() {
      this.setOffcanvasNewActionIsOpen(false)
    },

    cleanAndCloseOffcanvas() {
      this.selectedAction = null
      this.onHideOffcanvas()
    },

    createBack() {
      const body = {
        payload: {
          default_next_action: "back"
        },
        actionId: this.prevActionId
      }
      this.updateAction(body).then(() => {
        this.onHideOffcanvas()
      })
    },

    createRestart() {
      const body = {
        payload: {
          default_next_action: "restart"
        },
        actionId: this.prevActionId
      }
      this.updateAction(body).then(() => {
        this.onHideOffcanvas()
      })
    },

    createFinish() {
      const body = {
        payload: {
          default_next_action: "finish"
        },
        actionId: this.prevActionId
      }
      this.updateAction(body).then(() => {
        this.onHideOffcanvas()
      })
    }
  },

  watch: {
    show(value) {
      value ? this.offcanvas.show() : this.offcanvas.hide()
    },
  }
}
</script>
