import { AgentStatus, CallStatus, ReceptiveStatus } from "@/utils.js";

/**
 *
 * @returns An object with the receptive queue structure but with no data
 */
const emptyTotal = () => {
  return {
    hold: 0,
    active_calls: 0,
    abandoned: 0,
    connected: 0,
    awt: 0,
    ast: 0,
    agents: {
      status_count: {
        [AgentStatus.ONLINE]: 0,
        [AgentStatus.ON_CALL]: 0,
        [AgentStatus.ACW]: 0,
        [AgentStatus.ON_MANUAL_CALL]: 0,
        [AgentStatus.ON_MANUAL_CALL_ACW]: 0,
        [AgentStatus.IDLE]: 0,
        [AgentStatus.OFFLINE]: 0,
        [AgentStatus.ON_WORK_BREAK]: 0,
      },
      agents: [],
    },
    qualifications: {
      calls: 0,
      qualifications: [],
    },
  };
};

/**
 * Count the amount of online agents inside an array.
 * @param {Array} agents
 * @returns The amount of online agents inside the received array.
 */
const totalAgentsOnline = agents => {
  let total = 0;

  for (const agent of agents) {
    if (agent.status !== AgentStatus.OFFLINE) total++;
  }

  return total;
};

/**
 * Update the agent status based on a new status
 * passed by params.
 *
 * @param {Array} queue
 * @param {Number} newStatus
 * @param {Number} agentId
 * @returns {void}
 */
const changeAgentStatus = (queue, newStatus, agentId) => {
  const agent = queue.agents.agents.find(a => a.id === agentId);
  if (!agent) return;

  queue.agents.status_count[agent.status]--;
  queue.agents.status_count[newStatus]++;

  agent.status = newStatus;

  queue.agents.status_count[AgentStatus.ONLINE] = totalAgentsOnline(queue.agents.agents);
}

export default {
  setReceptiveQueue: (state, receptiveQueue) => state.receptiveQueue = receptiveQueue,
  startLoading: state => state.loading = true,
  stopLoading: state => state.loading = false,
  startLoadingContext: (state, loadingContext) => state.loadingContext[loadingContext] = true,
  stopLoadingContext: (state, loadingContext) => state.loadingContext[loadingContext] = false,
  setErrors: (state, errors) => (state.errors = errors),
  clearErrors: state => state.errors = {},
  setList: (state, list) => (state.list = list),

  setPagination: (state, pagination) => (state.pagination = pagination),

  setCurrentPage: (state, page) => (state.pagination.current_page = page),
  setFilter: (state, filters) => (state.filters = filters),

  setRegister: (state, register) => (state.register = register),

  setFilters: (state, filters) => (state.filters = filters),
  setIncludes: (state, includes) => (state.includes = includes),

  setDataSelect: (state, dataSelect) => (state.dataSelect = dataSelect),
  setConsultMetrics: (state, consultMetrics) =>
    (state.consultMetrics = consultMetrics),
  setQualificationsMetrics: (state, qualificationMetrics) =>
    (state.qualificationsMetrics = qualificationMetrics),
  clearQueues: state => state.queues = [],

  /**
   * Fill an array with an object that contains information
   * about real time and static data from a receptive queue.
   *
   * @param {Object} state
   * @param {Array} queues
   */
  setQueues: (state, queues) => {
    state.queues = [];
    state.total = emptyTotal();

    for (let index in queues) {
      const queue = queues[index];
      let newQueue = {
        id: queue.id,
        name: queue.name,
        extension_number: queue.extension.extension_number,
        priority: queue.priority,
        hold: 0,
        active_calls: 0,
        abandoned: 0,
        connected: 0,
        awt: 0,
        ast: 0,
        agents: {
          status_count: {
            [AgentStatus.ONLINE]: 0,
            [AgentStatus.ON_CALL]: 0,
            [AgentStatus.ACW]: 0,
            [AgentStatus.ON_MANUAL_CALL]: 0,
            [AgentStatus.ON_MANUAL_CALL_ACW]: 0,
            [AgentStatus.IDLE]: 0,
            [AgentStatus.OFFLINE]: 0,
            [AgentStatus.ON_WORK_BREAK]: 0,
          },
          agents: [],
        },
        qualifications: {
          calls: 0,
          qualifications: [],
        },
        number_settings: queue.number_settings,
      };
      state.queues.push(newQueue);
    }
  },

  /**
   * Fill an array with information about
   * all the receptive calls happening at
   * the moment.
   *
   * @param {Object} state
   * @param {Array} calls
   */
  setCalls(state, calls) {
    let totalHold = 0;
    let totalActiveCalls = 0;

    for (let index in state.queues) {
      const queue = state.queues[index];
      let hold = 0;
      let active_calls = 0;
      if (Object.prototype.hasOwnProperty.call(calls, queue.id)) {
        let callsQueue = calls[queue.id];
        for (let i in callsQueue) {
          let call = callsQueue[i];
          if (call.queue_id !== undefined) {
            if (call.agent !== undefined) {
              active_calls++;
              totalActiveCalls++;
            } else {
              hold++;
              totalHold++;
            }
          }
        }
      }

      state.queues[index].hold = hold;
      state.queues[index].active_calls = active_calls;
    }

    if (totalActiveCalls + totalHold) {
      state.total.active_calls = totalActiveCalls;
      state.total.hold = totalHold;
    }
  },

  /**
   * Add in an Object all the metrics from all the receptive
   * queues that belongs to the company.
   *
   * @param {Object} state
   * @param {Array} metrics
   */
  setMetrics(state, metrics) {
    let allCalls = 0;
    let allconnectedCalls = 0;

    for (const data of metrics) {
      let queue = state.queues.find(q => q.id === data["queue_id"]);

      if (!queue) continue;

      let calls = data["abandoned"] + data["connected"];
      allCalls += calls;
      allconnectedCalls += data["connected"];

      queue.abandoned = data["abandoned"];
      queue.connected = data["connected"];

      if (calls > 0) {
        queue.awt = data["waiting_time"] / calls;
      }

      if (data["connected"] > 0) {
        queue.ast = data["speaking_with_agent"] / data["connected"];
      }

      state.total.connected += data["connected"];
      state.total.abandoned += data["abandoned"];
      state.total.awt += data["waiting_time"];
      state.total.ast += data["speaking_with_agent"];
    }

    if (allCalls > 0) {
      state.total.awt = state.total.awt / allCalls;
    }

    if (allconnectedCalls > 0) {
      state.total.ast = state.total.ast / allconnectedCalls;
    }
  },

  /**
   * Add in an Object all the agent metrics from
   * all the receptive queues that belongs to the
   * company.
   *
   * @param {Object} state
   * @param {Array} queues
   */
  setAgents(state, queues) {
    const getStatus = agent => {
      if (agent.manual_call_status) {
        let isConnectedManualOrAcwCall =
          agent.manual_call_status === AgentStatus.MANUAL_CALL_ACW_CONNECTED ||
          agent.manual_call_status === AgentStatus.MANUAL_CALL_CONNECTED;

        if (isConnectedManualOrAcwCall) {
          return AgentStatus.ON_CALL;
        }

        agent.status = agent.manual_call_status;
      }

      if (agent.status === AgentStatus.MANUAL_CALL_CONNECTED)
        return AgentStatus.ON_CALL;

      return agent.status;
    };

    const setStatus = queue => {
      for (const agent of queue.agents.agents) {
        agent.status = getStatus(agent);
        queue.agents.status_count[agent.status]++;
      }
    };

    for (let queue of queues) {
      let agents = queue.agents;
      queue = state.queues.find(q => q.id === queue.id);
      if (!queue) continue;

      queue.agents.status_count[AgentStatus.ONLINE] = totalAgentsOnline(agents);
      queue.agents.agents = agents;
      setStatus(queue);

      for (const agent of agents) {
        if (state.total.agents.agents.find(a => a.id === agent.id)) {
          continue;
        }
        state.total.agents.agents.push(Object.assign({}, agent));
      }
    }

    let agents = state.total.agents.agents;
    state.total.agents.status_count[AgentStatus.ONLINE] = totalAgentsOnline(
      agents,
    );
    setStatus(state.total);
  },

  /**
   * Sort the receptive queues table based on what
   * column the user clicks.
   *
   * @param {Object} state
   * @param {Object} sorting
   */
  sortReceptiveQueues(state, sorting) {
    state.queues.sort((a, b) => {
      if (sorting.column == "online_agents") {
        return a.agents.status_count[AgentStatus.ONLINE] < b.agents.status_count[AgentStatus.ONLINE] ?
          -1 : a.agents.status_count[AgentStatus.ONLINE] > b.agents.status_count[AgentStatus.ONLINE] ?
            1 : 0;
      }
      if (sorting.column === "received_calls") {
        return a["connected"] + a["abandoned"] < b["connected"] + b["abandoned"] ?
          -1 : a["connected"] + a["abandoned"] > b["connected"] + b["abandoned"] ?
            1 : 0;
      }
      return a[sorting.column] < b[sorting.column] ?
        -1 : a[sorting.column] > b[sorting.column] ?
          1 : 0;
    });
    if (sorting.dir === "desc") state.queues.reverse();
  },

  // Real time
  /**
   * Updates the call status that is
   * happening at the moment.
   *
   * @param {Object} state
   * @param {Object} payload
   */
  setStatusQueue(state, payload) {
    let queue = state.queues.find(q => q.id === payload.queueId);

    switch (payload.status) {
      case ReceptiveStatus.IN_QUEUE:
        queue.hold++;
        state.total.hold++;
        break;
      case ReceptiveStatus.CONNECTED:
        state.total.hold--;
        state.total.active_calls++;
        queue.hold--;
        queue.active_calls++;
        break;
      case ReceptiveStatus.ABANDONED:
        state.total.hold--;
        queue.hold--;
        break;
      case ReceptiveStatus.ENDED:
        state.total.active_calls--;
        queue.active_calls--;
        break;
    }
  },

  /**
   * Updates the agent status based on the
   * event received by socket
   *
   * @param {Object} state
   * @param {Object} payload
   */
  setAgentStatus(state, payload) {
    changeAgentStatus(state.total, payload.status, payload.id);

    for (const queueId of payload.queues) {
      let queue = state.queues.find(q => q.id === queueId)
      if (!queue) continue;

      changeAgentStatus(queue, payload.status, payload.id);
    }
  },

  setActiveAgents: (state, activeAgents) => (state.activeAgents = activeAgents),
  setActiveCalls: (state, activeCalls) => {
    for (let item of activeCalls) {
      state.activeCalls[item.telephony_id] = item;
    }

    state.statusCallsCount[CallStatus.ANSWERED] = activeCalls.filter((el) => el.status == CallStatus.ANSWERED).length;
    state.statusCallsCount[CallStatus.CONNECTED] = activeCalls.filter((el) => el.status == CallStatus.CONNECTED).length;

  },
  setReceptiveMetrics: (state, receptiveMetrics) => state.receptiveMetrics = receptiveMetrics,
  clearReceptiveQueuesData: (state) => {
    state.queues = [];
    state.total = emptyTotal();
  },
};
