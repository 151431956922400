<template>
  <header-component
    :title="metricsCampaigns.name"
    pretitle="INSIGHTS IA"
    redirect-link="/manager/insights-ai"
    text-color-class="tx-text-gray"
  />
  <div class="pb-4">
    <div
      v-if="!loadingFlags['loadCampaignsAgentsById'] && Object.values(metricsCampaigns).length"
      class="mb-4"
      style="height: 353px;"
    >
      <card-general-metrics :metrics="metricsCampaigns" />
    </div>
    <div
      v-else
      class="row"
    >
      <div class="col-7">
        <card-loading
          v-if="loadingFlags['loadCampaignsAgentsById']"
          :height="108"
        />
        <card-loading
          v-if="loadingFlags['loadCampaignsAgentsById']"
          :height="224"
        />
      </div>
      <card-loading
        class="col-5"
        v-if="loadingFlags['loadCampaignsAgentsById']"
        :height="348"
      />
    </div>
    <table-campaigns-i-a :table-head="tableHeadNames" />
  </div>
</template>

<script>
import HeaderComponent from "@/components/header-component.vue";
import { mapActions, mapGetters } from "vuex";
import TableCampaignsIA from "@dialer/manager/pages/insights-ai/components/table-campaigns-ia.vue";
import CardLoading from "@components/card-loading.vue";
import CardGeneralMetrics from "@dialer/manager/pages/insights-ai/components/card-general-metrics.vue";

export default {
  name: "CampaignInsights",
  components: {
    CardGeneralMetrics,
    CardLoading,
    TableCampaignsIA,
    HeaderComponent
  },
  data() {
    return {
      tableHeadNames: [
        {
          title: this.$t('agents'),
          customClass: "col-2"
        },
        {
          title: this.$t('uppercases.grades_average'),
          customClass: "text-center w-25"
        },
        {
          title:this.$t('uppercases.grades')
        },
        {
          title: this.$t('uppercases.feedbacks_total'),
          customClass: "text-center"
        }
      ],
      metricsCampaigns: {},
      icon: "",
      color: ""
    };
  },
  mounted() {
    this.loadCampaignsAgentsById(this.$route.params.id);
    this.campaignsAgents(this.$route.params.id);
  },
  computed: {
    ...mapGetters("manager/insights", {
      campaign: "getMetricsCampaign",
      loadingFlags: "getLoadingFlags"
    })
  },
  methods: {
    ...mapActions("manager/insights", ["loadCampaignsAgentsById", "campaignsAgents"]),
    receiveMetrics() {
      this.metricsCampaigns = this.campaign;
    },
    scoreFixed(value) {
      if (value) {
        return value.toFixed(1);
      }
    },
    scoreIcon() {
      const metric = this.metricsCampaigns.overall_score;
      let icon, bgColor, txtColor;
      if (metric >= 6) {
        icon = "fa-thumbs-up";
        bgColor = "good";
        txtColor = "tx-green-400";
      } else if (metric >= 3 && metric < 6) {
        icon = "fa-meh";
        bgColor = "alert";
        txtColor = "tx-yellow-400";
      } else {
        icon = "fa-thumbs-down";
        bgColor = "danger";
        txtColor = "tx-red-400";
      }

      return { icon, bgColor, txtColor };
    },
    attention() {
      let icon;
      let bgColor = "";
      let isAttention = false;

      if (this.metricsCampaigns.attention_calls_count > 0) {
        icon = "fa-triangle-exclamation";
        bgColor = "alert";
        isAttention = true;
      } else {
        icon = "fa-phone";
        bgColor = "default";
        isAttention = false;
      }

      return { isAttention, icon, bgColor };
    }
  },
  watch: {
    campaign: {
      handler() {
        this.receiveMetrics();
      },
      deep: true
    }
  }
};
</script>
