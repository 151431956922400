<template>
  <div class="main-content">
    <div class="row">
      <div class="col-lg-6 my-3">
        <label class="form-label">Nome da empresa</label>
        <input
          v-model="fields.company_name"
          :class="{
            'form-control': true,
            'is-invalid': errors.company_name
          }"
          :placeholder="item.name"
          type="text"
        >
        <div class="invalid-feedback">
          {{ errors.company_name && errors.company_name[0] }}
        </div>
      </div>
      <div class="col-lg-6 my-3">
        <label class="form-label">Domínio</label>
        <input
          v-model="fields.domain"
          disabled
          class="form-control"
          :placeholder="item.domain"
          type="text"
        >
        <div class="invalid-feedback">
          {{ errors.domain && errors.domain[0] }}
        </div>
      </div>
      <div
        class="col-lg-6 my-3"
        v-if="!loadingFilters['findFilters']"
      >
        <p style="margin-bottom: 8px;">
          Pais
        </p>
        <single-select
          label="name"
          :list-array="countries"
          :current-option="fields.country.name ? fields.country.name : fields.country"
          id="singleOne"
          :z-index="10"
          @selected-option="fields.country = $event"
        />
      </div>
      <div class="col-lg-6 my-3">
        <label class="form-label">Moeda</label>
        <div class="input-group input-group-merge">
          <input
            disabled
            v-model="fields.currency"
            :class="{
              'is-invalid': errors.currency
            }"
            class="form-control"
            :placeholder="item.currency"
            type="text"
          >
          <div class="input-group-text">
            <span class="fe fe-lock" />
          </div>
        </div>
        <div class="invalid-feedback">
          {{ errors.currency && errors.currency[0] }}
        </div>
      </div>
      <div class="col-lg-6 my-3">
        <label class="form-label">Idioma</label>
        <div class="input-group input-group-merge">
          <input
            class="form-control"
            disabled
            :placeholder="language"
            type="text"
          >
          <div
            class="input-group-text"
            style="opacity: 0.4"
          >
            <span class="fe fe-lock" />
          </div>
        </div>
      </div>
      <div class="col-lg-6 my-3">
        <label class="form-label">Limite de crédito</label>
        <div
          :class="{
            'input-group input-group-merge': true,
            'is-invalid': errors.credit_limit
          }"
        >
          <input
            v-model="fields.credit_limit"
            :class="{
              'form-control': true,
              'is-invalid': errors.credit_limit
            }"
            :placeholder="item.credit_limit"
            aria-describedby="validationServer03Feedback"
            type="text"
          >
          <div class="input-group-text">
            {{ fields.currency === "BRL" ? "R$" : "USD" }}
          </div>
        </div>
        <div class="invalid-feedback">
          {{ errors.credit_limit && errors.credit_limit[0] }}
        </div>
      </div>
      <div class="col-lg-6 my-3">
        <p style="margin-bottom: 8px;">
          Plano
        </p>
        <single-select
          label="name"
          :list-array="countryPlans"
          id="singleTwo"
          @selected-option="fields.plan = $event.id"
          :current-option="isCurrentPlan"
        />
      </div>
      <div class="col-lg-6 my-3">
        <label class="form-label">Tags</label>
        <VueMultiselect
          v-model="fields.tags"
          :class="{
            'is-invalid': errors.tags
          }"
          :close-on-select="false"
          :multiple="true"
          :options="tags"
          :preserve-search="true"
          :deselect-label="$t('click_or_press_enter_to_remove')"
          label="name"
          placeholder="Selecione as tags"
          :select-label="$t('click_or_press_enter_to_select')"
          :selected-label="$t('selected')"
          track-by="id"
        />
        <div class="invalid-feedback">
          {{ errors.tags && errors.tags[0] }}
        </div>
      </div>
      <div class="col-lg-6 my-3">
        <label class="form-label">Logo</label>
        <input
          v-model="fields.logo_image_link"
          class="form-control"
          :class="{
            'is-invalid': errors.logo_image_link
          }"
          placeholder="https://"
          type="text"
        >
        <div class="invalid-feedback">
          {{ errors.logo_image_link && errors.logo_image_link[0] }}
        </div>
      </div>
      <div class="col-lg-6 my-3">
        <div
          class="card card-body mb-0 p-0 px-3 d-flex justify-content-center shadow-none rounded"
          style="height: 43px;border: 1px solid #D2DDEC;margin-top: 31px;"
        >
          <div class="form-check form-switch">
            <input
              v-model="fields.integration_enabled"
              class="form-check-input"
              type="checkbox"
            >
            <label class="form-check-label mx-3">Habilitar integração</label>
          </div>
        </div>
        <div class="invalid-feedback">
          {{ errors.integration_enabled && errors.integration_enabled[0] }}
        </div>
      </div>
      <div class="col-lg-12 my-3">
        <label class="form-label">Partner</label>
        <single-select
          id="partner"
          label="customName"
          :list-array="handlePartnerData"
          :current-option="fields.customName"
          initial-text="Nenhum"
          @selected-option="fields.partner_id = $event.id"
        />
      </div>

      <div class="col-lg-12 my-3">
        <div
          class="card shadow-none rounded"
          style="border: 1px solid #D2DDEC;"
        >
          <div
            class="card-header px-3 py-0 m-0"
            style="height: 42px;"
          >
            <div class="form-check form-switch">
              <input
                v-model="fields.two_factor"
                class="form-check-input"
                type="checkbox"
              >
              <label class="form-check-label mx-3">Verificação em duas etapas</label>
            </div>
          </div>
          <div
            class="card-body d-flex justify-content-between"
            style="background-color: #E1E9F4;"
            v-if="fields.two_factor"
          >
            <div class="col col-lg-6 pe-5">
              <label class="form-label">Frequência da autenticação</label>
              <input
                v-model="fields.two_factor_interval_days"
                :class="{
                  'form-control': true,
                  'is-invalid': errors.two_factor_interval_days
                }"
                type="number"
                v-if="showDays"
                @blur="showDays = false"
                @focusout="preventInvalidValue"
              >
              <div
                class="px-3 py-2 rounded"
                :class="{
                  'form-control': true,
                  'is-invalid': errors.two_factor_interval_days
                }"
                style="background-color: white; border: 1px solid #D2DDEC; height: 42px;"
                @click="showDays = true"
                v-if="!showDays"
              >
                {{
                  fields.two_factor_interval_days > 1
                    ? `${fields.two_factor_interval_days} dias`
                    : `${fields.two_factor_interval_days} dia`
                }}
              </div>
              <div class="invalid-feedback">
                {{ errors.two_factor_interval_days && errors.two_factor_interval_days[0] }}
              </div>
              <div
                class="text-danger fs-5"
                v-if="fields.two_factor_interval_days > 30 || fields.two_factor_interval_days < 1"
              >
                O valor deve ser entre 1 e 30
              </div>
            </div>

            <div
              class="col col-lg-6"
              v-if="fields.two_factor_setup_deadline_edit"
            >
              <label class="form-label">Data limite para autenticação</label>

              <div
                class="input-group input-group-merge"
                ref="date"
              >
                <flat-pickr
                  v-model="fields.two_factor_setup_deadline"
                  :config="config"
                  class="form-control rounded-start pe-3"
                  placeholder="Data"
                  data-input
                />
                <div
                  class="input-group-text cursor-pointer"
                  data-toggle
                >
                  <i class="fe fe-calendar tx-blue-300" />
                </div>
              </div>

              <div
                class="text-danger fs-5"
                v-if="errors.two_factor_setup_deadline"
              >
                {{ errors.two_factor_setup_deadline && errors.two_factor_setup_deadline[0] }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <app-button
      :loading="loadingFlags.editCompanySettings"
      class="col-lg-12 btn btn-primary mb-4"
      @click="submit()"
    >
      Salvar alterações
    </app-button>
    <div class="text-center mb-4">
      <span
        class="btn p-0 px-4 company-exclusion"
        @click="openRemoveCompanyModal"
      >
        <span class="fe fe-trash-2 me-1" />
        Excluir empresa
      </span>
    </div>
    <remove-company-modal
      ref="removeCompanyModal"
      :company-domain="fields.domain"
      :company-name="fields.company_name"
    />
  </div>
</template>

<script>
import FlatPickr from "vue-flatpickr-component";
import { Portuguese } from "flatpickr/dist/l10n/pt.js";
import AppButton from "@/components/app-button.vue";
import VueMultiselect from "vue-multiselect";
import { mapActions, mapGetters, mapMutations } from "vuex";
import removeCompanyModal from "../../components/remove-company-modal.vue";
import moment from "moment";
import SingleSelect from "@components/single-select.vue";

export default {
  data() {
    return {
      fields: {
        company_name: this.item.name,
        credit_limit: this.item.credit_limit,
        country: this.item.country_id,
        currency: this.item.currency,
        domain: this.item.domain,
        integration_enabled: this.item.integration_enabled,
        logo_image_link: this.item.logo_image_link,
        partner_id: this.item.partner_id || null,
        is_partner: false,
        plan: this.item.plan,
        tags: this.item.tags,
        language: this.item.language,
        two_factor: this.item.two_factor,
        two_factor_interval_days: this.item.two_factor_interval_days == null ? 7 : this.item.two_factor_interval_days,
        two_factor_setup_deadline_edit: this.item.two_factor_setup_deadline_edit,
        two_factor_setup_deadline:
          this.item.two_factor_interval_days != null
            ? moment.unix(this.item.two_factor_setup_deadline).format("YYYY-MM-DD")
            : moment()
                .add(7, "days")
                .format("YYYY-MM-DD"),
        customName: "Nenhum"
      },
      countries: [],
      plans: [
        { id: 1, name: "Ilimitado" },
        { id: 2, name: "Minutagem" },
        { id: 3, name: "Telefonia externa" }
      ],
      language: "",
      showDays: false,
      config: {
        locale: Portuguese,
        inline: false,
        altInput: true,
        altFormat: "d/m/Y",
        enableTime: false,
        wrap: true,
        maxDate: new Date().fp_incr(30)
      }
    };
  },
  components: {
    SingleSelect,
    AppButton,
    VueMultiselect,
    removeCompanyModal,
    FlatPickr
  },

  beforeMount() {
    this.companiesListPartners().then(() => {
      if (this.item.partner_id) {
        this.fields.customName = this.getPartnerName(this.item.partner_id);
      }
    });
    this.setFiltersIncludes(["countries"]);
    this.findFilters();
  },

  props: {
    item: {
      type: Object,
      default: () => null
    },

    tags: {
      type: Object,
      default: () => null
    }
  },
  computed: {
    ...mapGetters("admin/partners", {
      partners: "getListPartners"
    }),
    ...mapGetters("admin/companies", {
      loadingFlags: "getLoadingFlags",
      errors: "getErrors"
    }),
    ...mapGetters("manager/filters", {
      filters: "getFilters",
      loadingFilters: "getLoadingFlags"
    }),

    countryPlans() {
      if (this.fields.country === 33 || this.fields.country === "Brasil") {
        return [
          { id: 1, name: "Ilimitado" },
          { id: 2, name: "Minutagem" },
          { id: 3, name: "Telefonia externa" }
        ];
      } else {
        return [
          { id: 2, name: "Minutagem" },
          { id: 3, name: "Telefonia externa" }
        ];
      }
    },
    isCurrentPlan() {
      const plan = this.plans.find(plan => plan.id === this.fields.plan);
      if (!plan) return "Selecione";
      return plan.name;
    },
    handlePartnerData() {
      const partner = this.partners.map(item => {
        return {
          customName: item.commercial_name ? item.commercial_name : item.name,
          ...item
        };
      });
      partner.unshift({ customName: "Nenhum", id: null });
      return partner;
    }
  },

  methods: {
    ...mapActions("admin/partners", ["companiesListPartners"]),
    ...mapActions("admin/companies", ["editCompanySettings", "findById"]),
    ...mapActions("manager/filters", ["findFilters", "setFiltersIncludes"]),
    ...mapMutations("system", ["setErrorMessage", "setSuccessMessage"]),
    submit() {
      if (this.fields.partner_id !== null) {
        this.fields.is_partner = true;
      }
      const country = this.countries.find(id => id.id === this.fields.country.id || id.name === this.fields.country);
      this.editCompanySettings({
        id: this.$route.params.id,
        company_name: this.fields.company_name,
        credit_limit: this.fields.credit_limit,
        country_id: country.id,
        currency: this.fields.currency,
        domain: this.fields.domain,
        integration_enabled: this.fields.integration_enabled,
        logo_image_link: this.fields.logo_image_link,
        partner_id: this.fields.partner_id,
        is_partner: this.fields.is_partner,
        plan: this.fields.plan,
        tags: this.fields.tags.map(tag => tag.id),
        language: this.fields.language,
        two_factor: this.fields.two_factor,
        two_factor_interval_days: this.fields.two_factor_interval_days,
        two_factor_setup_deadline: moment(this.fields.two_factor_setup_deadline).unix()
      }).then(() => this.findById({ id: this.$route.params.id }));
    },

    openRemoveCompanyModal() {
      this.$refs.removeCompanyModal.open();
    },
    currentCoin() {
      if (this.fields.country.country_code === 55 || this.fields.country === "Brasil") {
        this.fields.currency = "BRL";
      } else {
        this.fields.currency = "USD";
      }

      this.language = this.getCountryLanguage(this.fields.language);
    },


    preventInvalidValue(event) {
      if (event.target.value > 30) {
        this.fields.two_factor_interval_days = 30;
      } else if (event.target.value < 1) {
        this.fields.two_factor_interval_days = 1;
      }
    },
    handlerCountries() {
      if (this.filters.countries) this.countries = this.filters.countries;
    },
    getCountryLanguage(language) {
      if (language === "pt-br") {
        return "Português (BR)";
      } else if (language === "es-es") {
        return "Espanhol (Espanha)";
      } else {
        return "Inglês (USA)";
      }
    },

    getPartnerName(partnerId) {
      const partner = this.partners.find(partner => partner.id === partnerId);
      return partner.commercial_name ? partner.commercial_name : partner.name;
    }
  },
  watch: {
    filters: {
      handler() {
        this.handlerCountries();
        this.currentCoin();
      },
      deep: true
    },
    "fields.country": {
      handler() {
        this.currentCoin();
      },
      deep: true
    },

    preventInvalidDate(event) {
      if (moment(event.target.value) > moment().add(30, "days")) {
        this.fields.two_factor_setup_deadline = moment()
          .add(30, "days")
          .format("YYYY-MM-DD");
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.company-exclusion {
  color: $color-red-300;
}
</style>
