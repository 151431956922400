<template>
  <dragable-area
    v-if="showDragableArea"
    @leave="showDragableArea = false"
    @send-file="setFile"
  />
  <file-preview
    v-if="file"
    :file="file"
    @close="file = null"
    @send-image="sendImageMessage"
    @send-video="sendVideoMessage"
    @send-audio="sendAudioMessage"
    @send-document="sendDocumentMessage"
  />
  <div
    :class="{
      'file-preview-active': file,
      'agent-top-bar-active': isAgent
    }"
    class="chat-main flex-column d-flex background-chat"
    @dragenter="dragenter"
    @mouseenter="cursorInChat = true"
    @mouseleave="cursorInChat = false"
  >
    <tob-bar />

    <div
      v-if="currentChat"
      ref="panel"
      class="main col"
      @scroll="scroll"
    >
      <div
        ref="messages-container"
        class="panel b3"
        data-test="chatPanel-div"
      >
        <div
          v-if="loadingFlags.findMessages"
          class="text-center b4"
        >
          <div class="spinner-border spinner-border-sm text-muted mt-2 b1">
            <span class="visually-hidden b3" />
          </div>
        </div>
        <div v-else>
          <template
            v-for="(day, i) of days"
            :key="i"
          >
            <div class="col-lg-12 b1 text-center">
              <div class="day py-2 px-3">
                {{ day }}
              </div>
            </div>
            <template
              v-for="(index, j) of currentChat?.messagesRefs[day]"
              :key="j"
            >
              <template v-if="index">
                <show-note
                  v-if="currentChat.messagesData[index]?.type === 'active-ivr-message'"
                  :gray="true"
                  class="my-2"
                >
                  {{ currentChat.messagesData[index]?.body }}
                </show-note>
                <show-note
                  v-else-if="currentChat.messagesData[index]?.type === 'internal-message'"
                  class="my-2"
                >
                  <strong>
                    {{ $t('internal_note') }} - {{ currentChat.messagesData[index].agent?.name }}:
                  </strong>
                  {{ currentChat.messagesData[index].body }}
                  -
                  {{ formatHour(currentChat.messagesData[index].time) }}
                </show-note>
                <div v-else-if="currentChat.messagesData[index].type === 'transfer'">
                  <show-note class="my-2">
                    {{ $t('transferred_from') }}
                    {{ currentChat.messagesData[index].inter_message_data?.previous_agent.name }}
                    {{ $t('transferred_to.to') }}
                    {{
                      currentChat.messagesData[index].inter_message_data?.current_agent ?
                        currentChat.messagesData[index].inter_message_data?.current_agent.name :
                        $t('transferred_to.to_queue')
                    }}
                    {{
                      currentChat.messagesData[index].inter_message_data?.previous_agent.id !=
                        currentChat.messagesData[index].agent.id ? $t('by') + ' ' + currentChat.messagesData[index].agent.name : ''
                    }}
                    -
                    {{ formatHour(currentChat.messagesData[index].time) }}
                  </show-note>
                </div>
                <show-note
                  v-else-if="currentChat.messagesData[index].type === 'snooze-message'"
                  :gray="true"
                  class="my-2"
                >
                  {{ $t('put_on_hold_by') }}
                  {{ currentChat.messagesData[index].agent?.name }}
                  {{ $t('for') }}
                  {{
                    formatSnoozeDuration(currentChat.messagesData[index].time, currentChat.messagesData[index]?.inter_message_data?.end_snooze)
                  }}
                  {{ $t('until') }}
                  {{ formatEndSnoozeData(currentChat.messagesData[index]?.inter_message_data?.end_snooze) }}
                </show-note>
                <div
                  v-else-if="currentChat.messagesData[index].type === 'qualification-message' && currentChat.messagesData[index].body === 'Tempo de espera excedido'"
                >
                  <show-note
                    :gray="true"
                    class="my-2"
                  >
                    {{ currentChat.messagesData[index]?.inter_message_data?.name }}.
                    {{ $t('auto_end_chat_note') }} -
                    {{ formatHour(currentChat.messagesData[index].time) }}
                  </show-note>
                  <show-note>
                    <strong>{{ $t('internal_note') }} - {{ currentChat.messagesData[index].agent?.name }}</strong>
                    {{ currentChat.messagesData[index].body }}-{{ formatHour(currentChat.messagesData[index].time) }}
                  </show-note>
                </div>
                <div v-else-if="currentChat.messagesData[index].type === 'qualification-message'">
                  <show-note
                    v-if="currentChat.messagesData[index]?.inter_message_data?.name === 'Não qualificada'"
                    :gray="true"
                    class="my-2"
                  >
                    {{ $t('end_chat_note_no_qualification') }}
                    {{
                      currentChat.messagesData[index]?.agent?.name
                    }}-{{ formatHour(currentChat.messagesData[index].time) }}
                  </show-note>
                  <show-note
                    v-else
                    :gray="true"
                    class="my-2"
                  >
                    {{ currentChat.messagesData[index]?.inter_message_data?.name }}
                    . {{ $t('end_chat_by') }}
                    {{ currentChat.messagesData[index]?.agent?.name }}
                    -
                    {{ formatHour(currentChat.messagesData[index].time) }}
                  </show-note>
                  <show-note>
                    <strong>{{ $t('internal_note') }} -
                      {{ currentChat.messagesData[index].agent?.name }}:</strong>
                    {{ currentChat.messagesData[index].body }}
                    -
                    {{ formatHour(currentChat.messagesData[index].time) }}
                  </show-note>
                </div>
                <show-note
                  v-else-if="currentChat.messagesData[index].type === 'protocol-message'"
                  :id="currentChat.messagesData[index].id"
                  :blink="protocolFound?.id === currentChat.messagesData[index].id"
                  :gray="true"
                  class="my-2"
                >
                  <span>{{
                    $t("rich_text.internal_notes.protocol", {
                      name: currentChat.messagesData[index]?.agent?.name,
                      protocol: formatProtocol(currentChat.messagesData[index].body),
                      time: formatHour(currentChat.messagesData[index].time),
                    })
                  }}</span>
                </show-note>
                <message-insight
                  v-else-if="currentChat.messagesData[index].type === 'ai-chat-evaluation-message'"
                  class="mx-auto"
                  :insight="currentChat.messagesData[index].body"
                />
                <ballon
                  v-else
                  :ref="currentChat.messagesData[index].id"
                  :ack="currentChat.messagesData[index].ack"
                  :current-chat="currentChat"
                  :error="currentChat.messagesData[index].failed"
                  :failed="currentChat.messagesData[index].failed"
                  :from-me="currentChat.messagesData[index].fromMe"
                  :is-deleted="currentChat.messagesData[index].is_deleted"
                  :is-external="currentChat.messagesData[index].isExternal"
                  :is-forwarded="currentChat.messagesData[index].isForwarded"
                  :is-sticker="currentChat.messagesData[index].type === 'sticker'"
                  :locked-send-message="lockedSendMessage"
                  :message-data="currentChat.messagesData[index]"
                  :message-id="currentChat.messagesData[index].internal_id"
                  :name="currentChat.messagesData[index].agent?.name || currentChat.messagesData[index].message_from"
                  :color="getAuthorColor(currentChat.messagesData[index].agent?.name || currentChat.messagesData[index].message_from, index)"
                  :previous-msg="getPreviousMsg(j, day)"
                  :time="currentChat.messagesData[index].time"
                  @delete="deleteChatMessages(currentChat.messagesData[index])"
                  @download="download"
                  @hover="setHover"
                  @quote="setQuoteMessage(index)"
                  @resend="tryResendFailedMessage"
                  @open-modal="openModal(currentChat.messagesData[index])"
                >
                  <template #message="{message}">
                    <message-template
                      v-if="message.type === 'template'"
                      :is-deleted="message.is_deleted && dashText !== message.id"
                      :message="message.template"
                      @toggle-resize-observer="disableResizeObserver = true"
                    />
                    <message-text
                      v-if="message.type === 'chat'"
                      :text="message.body"
                      :is-deleted="message.is_deleted && dashText !== message.id"
                      @toggle-resize-observer="disableResizeObserver = true"
                    />
                    <message-video
                      v-if="message.type === 'video'"
                      v-show="false"
                      :text="message.body"
                      :media="message.media"
                      :is-deleted="message.is_deleted && dashText !== message.id"
                    />
                    <message-image
                      v-else-if="message.type === 'image'"
                      :text="message.body"
                      :media="message.media"
                      :is-deleted="message.is_deleted && dashText !== message.id"
                    />
                    <message-audio
                      v-else-if="
                        message.type === 'ptt' ||
                          message.type === 'audio' ||
                          message.type === 'voice'
                      "
                      :type="message.type"
                      :path="message.media"
                      :from-me="message.fromMe"
                      :message-ack="message.ack"
                      @audio-duration="(e) => setAudioDurations(e, message.id)"
                    />
                    <message-document
                      v-else-if="message.type === 'document'"
                      :file-name="message.media_original_name"
                      :size="message.size"
                      :url="message.media"
                      :is-deleted="message.is_deleted && dashText !== message.id"
                    />
                    <message-contact
                      v-else-if="message.type === 'vcard'"
                      :body="message.body"
                      :instance-id="currentChat?.instance_id"
                    />
                    <message-sticker
                      v-else-if="message.type === 'sticker'"
                      :media="message.media"
                    />
                  </template>
                  <template
                    v-if="verifyShowQuoted(currentChat.messagesData[index])"
                    #quote="{message}"
                  >
                    <ballon-quote>
                      <div class="col d-flex align-items-center quote-content">
                        <div class="col d-flex flex-column px-3 py-4 gap-1">
                          <p
                            v-if="message.quoted_msg.type === 'chat'"
                            class="text-medium lh-1 m-0"
                          >
                            {{ currentChat.messagesData[index].quoted_msg.body }}
                          </p>
                          <display-type
                            v-else
                            :msg-id="message.quoted_msg.id"
                            :body="message.quoted_msg.body"
                            :media="message.quoted_msg.media"
                            :type="message.quoted_msg.type"
                          />
                        </div>
                        <img
                          v-if="message.quoted_msg.type === 'image'"
                          :src="getQuotedMessageMedia(message)"
                          alt="reply-image"
                          class="reply-image"
                        >
                      </div>
                    </ballon-quote>
                  </template>
                </ballon>
              </template>
              <div
                v-else
                class="separator"
              />
            </template>
          </template>
          <div
            v-if="isWabaChannelSelected && !currentChat.is_group && !currentChat.finished && (isWabaMessageReceived.waba_after_template_send_message || isWabaMessageReceived.waba_start_chat_template_request)"
          >
            <info-chat-card
              v-if="isWabaMessageReceived.waba_after_template_send_message"
              :img="require(`@assets/img/plusinha-waba.svg`)"
              alt="plusinha-waba"
            >
              <template #message>
                <div
                  class="d-flex flex-column"
                  v-html="$t('waba_after_template_send_message')"
                />
              </template>
            </info-chat-card>
            <info-chat-card
              v-else-if="!currentChat?.waba_message_received?.sended_message_template && !currentChat?.waba_message_received?.message_received && currentChat.agent_id !== null"
              :img="require(`@assets/img/plusinha-waba.svg`)"
              alt="plusinha-waba"
            >
              <template #message>
                <div>
                  <span v-html="$t('waba_start_chat_template_request.start')" />
                  <i class="fal fa-memo fw-medium" />
                  <span v-html="$t('waba_start_chat_template_request.end')" />
                </div>
              </template>
            </info-chat-card>
          </div>
        </div>
        <show-note
          v-if="finalizedByManager && finalizedByManager.id == currentChat.id && !finalizedByManager.automatic_finalization"
          :gray="true"
          class="mt-5 mb-2"
        >
          {{ $t('end_chat_by_manager') }} - {{ formatTimestamp(finalizedByManager.updated_at) }}
        </show-note>
      </div>
    </div>

    <template v-if="!showStartChat && !isCurrentChatAvailable">
      <info-chat-card
        v-if="transferedByManager && transferedByManager.id === currentChat.id"
        :img="require(`@assets/img/transferedChat.svg`)"
        :subtitle="$t('transferred_chat.subtitle')"
        :title="$t('transferred_chat.title')"
      />
      <info-chat-card
        v-else-if="finalizedByManager && finalizedByManager.id == currentChat.id"
        :img="require(`@assets/img/transferedChat.svg`)"
        :subtitle="$t('finalized_chat.subtitle')"
        :title="$t('finalized_chat.title')"
      />
      <info-chat-card
        v-else-if="snoozedByManager.in_snooze && snoozedByManager.id == currentChat.id"
        :img="require(`@assets/img/snoozedChat.svg`)"
        :subtitle="$t('snooze_chat.subtitle')"
        :title="$t('snooze_chat.title')"
      />
      <info-chat-card
        v-else-if="currentChat?.by_active_ivr && !currentChat.agent_id"
        :img="require(`@assets/img/aloisio/Pluzinha_Espera.svg`)"
        :title="'Cliente incluso na fila via URA Receptiva.'"
      />
    </template>

    <template
      v-if="(currentChat && isCurrentChatAvailable && !currentChat?.chatbot_active) || showStartChat"
    >
      <start-chat
        v-if="showStartChat"
        :chat-data="finalizedByManager.id == currentChat.id ? finalizedByManager : currentChat"
      />
      <message-input
        v-else-if="allTalksLoaded && !currentChat.isReadOnly"
        :previous-message="getPreviousMsg"
        :is-locked-send="lockedSendMessage"
        :quote-message="quoteMessage"
        @send-template="sendTemplateMessage"
        @send-text="sendTextMessage"
        @send-internal-note="sendInternalNote"
        @set-file="setFile"
        @send-voice="sendVoiceMessage"
        @delete-quote="quoteMessage = null"
      />
    </template>
    <chatbot-chat-options v-if="currentChat?.chatbot_active" />
    <forward-modal
      ref="forwardedModal"
      @send-forward="sendForwardMessage"
    />
    <remove-group v-if="currentChat?.isReadOnly" />
  </div>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from "vuex";
import {Utils} from "@/utils";
import TobBar from "./top-bar.vue";
import MessageInput from "./message-input/message-input.vue";
import moment from "moment";
import FilePreview from "./file-preview.vue";
import DragableArea from "./dragable-area.vue";
import Ballon from "./ballon-message.vue";
import MessageImage from "./messages-types/message-image.vue";
import MessageDocument from "./messages-types/message-document.vue";
import MessageText from "./messages-types/message-text.vue";
import MessageAudio from "./messages-types/message-audio.vue";
import MessageVideo from "./messages-types/message-video.vue";
import MessageContact from "./messages-types/message-contact.vue";
import MessageSticker from "./messages-types/message-sticker.vue";
import ShowNote from "./messages-types/show-note.vue";
import StartChat from "./start-chat.vue";
import ForwardModal from "./forward-message.vue";
import RemoveGroup from "./remove-group.vue";

import {Fancybox} from "@fancyapps/ui";
import BallonQuote from "./ballon-quote.vue";
import DisplayType from "./display-type.vue";
import InfoChatCard from "@whatsapp/components/info-chat-card.vue";
import ChatbotChatOptions from "@whatsapp/components/chatbot-chat-options.vue";
import MessageTemplate from "@whatsapp/components/messages-types/message-template.vue";
import MessageInsight from "@whatsapp/components/messages-types/message-insight.vue";

export default {
  data() {
    return {
      companyDomain: null,
      resizeObserver: null,
      list: {},
      showDragableArea: false,
      file: null,
      quoteMessage: null,
      backupHeight: 0,
      showForwardModal: false,
      audioDurations: {},
      dashText: null,
      id: null,
      cursorInChat: false,
      disableResizeObserver: false,
      authorNameList: [],
    };
  },

  components: {
    MessageInsight,
    InfoChatCard,
    MessageTemplate,
    ChatbotChatOptions,
    DragableArea,
    Ballon,
    FilePreview,
    MessageInput,
    MessageImage,
    MessageDocument,
    MessageAudio,
    MessageVideo,
    MessageContact,
    MessageText,
    StartChat,
    TobBar,
    ShowNote,
    BallonQuote,
    DisplayType,
    MessageSticker,
    ForwardModal,
    RemoveGroup
  },

  async mounted() {
    if (this.$route.params.id && this.currentChat?.pagination) {
      if (!Object.keys(this.currentChat?.pagination).length) {
        await this.preLoadMessages({
          id: this.currentChat.id,
          instance_id: this.currentChat.instance_id
        }).then(() => {
          if (
            this.currentChat.pagination.current_page <
            this.currentChat.pagination.total_pages
          ) {
            this.findOlderMessages({
              id: this.$route.params.id,
              page:
                this.currentChat.pagination.current_page +
                1,
              instance_id: this.currentChat.instance_id
            });
          } else if (
            this.currentChat.pagination.total_pages ==
            this.currentChat.pagination.current_page
          ) {
            this.setLoadMessagesContext("historic");
            this.findMessagesHistoricByNumber({
              number: this.currentChat.number,
              page: 1,
              instance_id: this.currentChat.instance_id,
              chat_id: this.$route.params.id
            }).then(() => {
              if (this.$refs.panel && this.$refs.panel.clientHeight >= this.$refs.panel.scrollHeight) {
                this.addMessagesToChat({
                  messages: this.currentChat
                    .stashedMessages,
                  chat_id: this.$route.params.id
                });
                this.findMessagesHistoricByNumber({
                  number: this.currentChat.number,
                  page:
                    this.currentChat.pagination
                      .current_page + 1,
                  instance_id: this.currentChat
                    .instance_id,
                  chat_id: this.$route.params.id
                });
              }
            });
          }
        });
      } else {
        if (
          this.currentChat?.pagination.current_page <
          this.currentChat?.pagination.total_pages
        ) {
          this.findOlderMessages({
            id: this.$route.params.id,
            page:
              this.currentChat.pagination.current_page + 1,
            instance_id: this.currentChat.instance_id
          });
        } else if (
          this.currentChat.pagination.total_pages ==
          this.currentChat.pagination.current_page
        ) {
          this.setLoadMessagesContext("historic");
          this.findMessagesHistoricByNumber({
            number: this.currentChat.number,
            page: 1,
            instance_id: this.currentChat.instance_id,
            chat_id: this.$route.params.id
          }).then(() => {
            if (this.$refs.panel && this.$refs.panel.clientHeight >= this.$refs.panel.scrollHeight) {
              this.addMessagesToChat({
                messages: this.currentChat.stashedMessages,
                chat_id: this.$route.params.id
              });
              this.findMessagesHistoricByNumber({
                number: this.currentChat.number,
                page:
                  this.currentChat.pagination
                    .current_page + 1,
                instance_id: this.currentChat.instance_id,
                chat_id: this.$route.params.id
              });
            }
          });
        }
      }
    }
    if (
      this.currentChat?.agent_id &&
      !this.currentChat?.is_group &&
      this.currentChat?.unread > 0 &&
      this.user.role.name == "agent"
    )
      this.decrementUnreadConversation();
    else if (
      this.currentChat?.is_group &&
      this.currentChat?.unread > 0 &&
      this.user.role.name == "agent"
    )
      this.decrementUnreadGroupsConversation();
    //TODO: use isManager
    //TODO: fix backend to return false instead "0"
    if (
      (this.currentChat?.agent_id ||
        this.currentChat?.is_group) &&
      this.user.role.name == "agent" &&
      !this.currentChat.finished
    ) {
      this.clearUnreadMessageCount({
        id: this.$route.params.id
      });
    }
    //past file event
    window.addEventListener("paste", this.paste);
    this.resizeObserver = new ResizeObserver((entries) => {
      if (this.currentChat.states.scrollY) {
        this.$refs.panel.scrollTo(0, this.currentChat.states.scrollY);
        this.setChatState({
          chatId: this.$route.params.id,
          chatState: "scrollY",
          value: null,
        })
        return
      }
      if (this.disableResizeObserver) {
        this.disableResizeObserver = false;
      } else {
        if (this.disableAutoScroll) {
          let diff = entries[0].contentRect.height - this.backupHeight;
          if (diff > 0) this.$refs.panel.scrollTop = diff;
          this.setDisableAutoScroll(false);
        } else {
          this.scrollToDown();
        }
      }
      this.backupHeight = entries[0].contentRect.height;
    });
    if (this.$refs["messages-container"]) {
      this.resizeObserver.observe(this.$refs["messages-container"]);
    }
    Fancybox.bind("[data-fancybox]", {
      groupAll: true
    });
    this.getCompanyDomain();

    if (this.isAgent || this.isSupervisor) this.fetchFunnels(this.user.teams[0].id);
    else this.fetchFunnels();
  },

  beforeUnmount() {
    Fancybox.unbind();
    if (this.$refs["messages-container"] && this.resizeObserver) {
      this.resizeObserver.unobserve(this.$refs["messages-container"]);
    }
    window.removeEventListener("paste", this.paste);
  },

  beforeRouteUpdate() {
    if (this.$refs.panel) {
      this.setChatState({
        chatId: this.$route.params.id,
        chatState: "scrollY",
        value: this.$refs.panel.scrollTop,
      })
    }
  },

  beforeRouteLeave() {
    if (this.$refs.panel) {
      this.setChatState({
        chatId: this.$route.params.id,
        chatState: "scrollY",
        value: this.$refs.panel.scrollTop,
      })
    }
  },


  computed: {
    ...mapGetters("whatsapp/chat", {
      chat: "getCurrentChat",
      snoozeNote: "getSnoozeNote",
      transferedByManager: "getTransferedByManager",
      snoozedByManager: "getSnoozedByManager",
      finalizedByManager: "getFinalizedByManager",
      loadingFlags: "getLoadingFlags",
      allTalksLoaded: "getAllTalksLoaded",
      protocolFound: "getProtocolFound",
      messageFound: "getMessageFound",
      loadMessagesContext: "getLoadMessagesContext",
      channelTypeSelected: "getChatChannelTypeSelected",
    }),
    ...mapGetters("whatsapp/message", {
      disableAutoScroll: "getDisableAutoScroll"
    }),

    ...mapGetters("auth", {
      isAgent: "isAgent",
      isSupervisor: "isSupervisor",
      user: "getUser",
      isManager: "isManager"
    }),

    isCurrentChatAvailable() {
      return Object.values(this.chat)?.some(chat => chat?.id === this.currentChat?.id)
    },

    isWabaMessageReceived() {
      const waba_after_template_send_message = !this.currentChat?.waba_message_received?.message_received && this.currentChat?.waba_message_received?.sended_message_template;
      const waba_start_chat_template_request = !this.currentChat?.waba_message_received?.sended_message_template && this.currentChat.agent_id !== null;
      return {
        waba_after_template_send_message,
        waba_start_chat_template_request,
      }
    },

    currentChat() {
      return this.chat[this.$route.params.id]
    },

    days() {
      if (this.currentChat) {
        return Object.keys(
          this.currentChat.messagesRefs
        ).reverse();
      }
      return null
    },

    showStartChat() {
      if (this.currentChat.is_group) return false;
      if (this.finalizedByManager.id === this.currentChat.id)
        return true;
      if (!this.currentChat.agent_id && !this.currentChat?.agent?.id) return true;
      else if (this.currentChat.finished) return true;
      return false;
    },

    currentInstanceId() {
      return this.channelTypeSelected?.id
    },

    isWabaChannelSelected() {
      return this.channelTypeSelected && (this.channelTypeSelected.type === 'waba' || this.currentChat.states.channel === 'waba')
    },

    lockedSendMessage() {
      const isGroup = this.currentChat.is_group
      const wabaIsSelected = this.channelTypeSelected && this.channelTypeSelected.type === 'waba'
      const messageReceived = this.currentChat?.waba_message_received?.message_received
      const templateSended = this.currentChat?.waba_message_received?.sended_message_template

      return !isGroup && wabaIsSelected && !messageReceived && !templateSended
    }
  },

  methods: {
    passedTime: Utils.passedTime,
    formatTimestamp: Utils.formatTimestamp,
    getColor: Utils.getColor,

    ...mapActions("whatsapp/chat", [
      "clearUnreadMessageCount",
      "putOnSnooze",
      "findMessagesHistoricByNumber",
      "findOlderMessages",
      "addMessagesToChat",
      "preLoadMessages",
      "fetchVerifyNotifiedChats"
    ]),

    ...mapActions("whatsapp/message", [
      "sendMessage",
      "sendDocument",
      "sendImage",
      "sendVoice",
      "sendVideo",
      "sendAudio",
      "sendInternal",
      "sendForward",
      "deleteWhatsappMessage"
    ]),

    ...mapActions("whatsapp/crm", ["fetchFunnels"]),

    ...mapMutations("whatsapp/message", ["setDisableAutoScroll"]),
    ...mapMutations("whatsapp/chat", [
      "setLoadMessagesContext",
      "updateInProgressTalkOrder",
      "updateGroupTalkOrder",
      "setChatLastMessage",
      "decrementUnreadConversation",
      "decrementUnreadGroupsConversation",
      "addRecentMessage",
      "removeMessageById",
      "setAllowNewMessages",
      "setChatState",
      "updateWabaMessageReceived"
    ]),

    ...mapMutations("whatsapp/waba", ["setSelectedMessageTemplate"]),
    ...mapActions("whatsapp/waba", ["sendMessageTemplates"]),

    getAuthorColor(authorName) {
      if (!this.authorNameList.includes(authorName))
        this.authorNameList.push(authorName)
      return this.getColor(this.authorNameList.indexOf(authorName));
    },

    getPreviousMsg(i, day) {
      if (this.currentChat && this.currentChat.messagesRefs) {
        const index = this.currentChat.messagesRefs[day][i - 1]
        const prevMsg = this.currentChat.messagesData[index]
        return prevMsg
      }
    },

    getCompanyDomain() {
      let arr = window.location.host.split(".");
      let subdomain = arr[0];
      this.companyDomain = process.env.VUE_APP_BA12AS.replace(
        "app",
        subdomain
      ).replace("/api/v1", "");
    },

    formatHour(time) {
      return moment.unix(time).format("HH:mm");
    },

    formatSnoozeDuration(start, end) {
      start = moment.unix(start);
      end = moment.unix(end);
      let hours = start.diff(end, "hours");
      return hours * -1 + 1 + " horas";
    },

    numberOfCharactersForFakeLoadingMessage(number) {
      return ".".repeat(number);
    },

    setFile(file) {
      this.file = file;
    },

    paste(e) {
      if (e.clipboardData.files.length) this.file = e.clipboardData.files[0];
    },

    generateTempId() {
      return (
        moment().unix() +
        "_" +
        Math.random()
          .toString(16)
          .slice(2)
      );
    },

    sendSnoozeNote(event) {
      if (!event) return;
      let tempId = this.generateTempId();
      let note = {
        id: tempId,
        chat_id: this.$route.params.id,
        end_snooze: event.timestamp,
        time: moment().unix(),
        ack: null,
        fromMe: true,
        failed: false,
        type: "snooze-message"
      };
      this.putOnSnooze({
        chat_id: this.$route.params.id,
        note: note,
        fields: {
          end_snooze: event.time_formatted
        }
      }).then(() => {
        this.preLoadMessages({
          id: this.$route.params.id,
          instance_id: this.currentInstanceId
        })
      });
    },

    sendInternalNote({text, instance_id}) {
      if (text == "") return;
      let tempId = this.generateTempId();
      let note = {
        id: tempId,
        chat_id: this.$route.params.id,
        body: text,
        time: moment().unix(),
        ack: null,
        fromMe: true,
        failed: false,
        type: "internal-message"
      };
      this.addRecentMessage({message: note});
      this.sendedSnooze = true;

      let fields = {
        chat_id: this.$route.params.id,
        body: text,
        instance_id: instance_id
      };

      if (this.currentChat.is_group) {
        fields.instance_id = this.currentChat.instance_id
      }

      this.sendInternal({
        tempId: tempId,
        fields: fields,
      });
    },

    sendTextMessage({text, instance_id}) {
      if (text === "") return;
      let tempId = this.generateTempId();

      let tempMessage = {
        id: tempId,
        ack: null,
        body: text,
        chat_id: this.$route.params.id,
        failed: false,
        fromMe: true,
        time: moment().unix(),
        type: "chat",
        instance: {
          type: this.channelTypeSelected?.type
        },
        agent: {
          id: this.user.id,
          name: this.user.name
        }
      };

      this.addRecentMessage({message: tempMessage});

      let fields = {
        chat_id: this.$route.params.id,
        body: text,
        instance_id: this.quoteMessage ? this.quoteMessage.instance_id : instance_id
      };

      if (this.quoteMessage) {
        fields["message_id"] = this.quoteMessage.id || this.quoteMessage.internal_id;
        tempMessage["quoted_msg"] = {
          id: this.quoteMessage.id || this.quoteMessage.internal_id,
          body: this.quoteMessage.body,
          type: this.quoteMessage.type,
          media: this.quoteMessage.media
        };
      }
      this.sendMessage({
        fields: fields,
        tempId: tempId,
        chat: this.currentChat
      }).then(() => {
        this.fetchVerifyNotifiedChats("in_progress")
      });
      this.quoteMessage = null;
    },

    sendTemplateMessage(template) {
      let tempMessage = {
        id: template.id,
        ack: null,
        body: null,
        chat_id: this.$route.params.id,
        backupToTryResend: template,
        failed: false,
        fromMe: true,
        time: moment().unix(),
        type: "template",
        template: {
          components: template.components
        },
        agent: {
          id: this.user.id,
          name: this.user.name
        },
        instance: {
          type: 'waba'
        }
      }
      this.addRecentMessage({message: tempMessage});

      let fields = {
        chat_id: this.$route.params.id,
        body: template.components
      };

      if (this.currentChat.is_group) {
        fields.instance_id = this.currentInstanceId
      }

      if (this.quoteMessage) {
        fields["message_id"] = this.quoteMessage.id;
        tempMessage["quoted_msg"] = {
          id: this.quoteMessage.id,
          body: this.quoteMessage.body,
          type: this.quoteMessage.type,
          media: this.quoteMessage.media
        };
        this.quoteMessage = null;
      }

      this.setSelectedMessageTemplate(null)
    },

    sendImageMessage(text) {
      let tempId = this.generateTempId();

      let tempMessage = {
        id: tempId,
        ack: null,
        agent_id: this.currentChat.agent_id,
        backupToTryResend: this.file,
        body: text,
        chat_id: this.$route.params.id,
        failed: false,
        fromMe: true,
        media: URL.createObjectURL(this.file),
        time: moment().unix(),
        type: "image",
        instance: {
          type: this.channelTypeSelected?.type
        },
        agent: {
          id: this.user.id,
          name: this.user.name
        }
      };

      this.addRecentMessage({message: tempMessage});

      let formData = new FormData();
      formData.append("chat_id", this.$route.params.id);
      formData.append("image", this.file);
      formData.append("caption", text);
      formData.append("body", text);
      formData.append("instance_id", this.currentInstanceId)

      if (this.quoteMessage) {
        formData.append("message_id", this.quoteMessage.id);
        formData.message_id = this.quoteMessage.id
        tempMessage["quoted_msg"] = {
          id: this.quoteMessage.id,
          body: this.quoteMessage.body,
          media: this.quoteMessage.media,
          type: this.quoteMessage.type
        };
        // this.quoteMessage = null;
      }

      this.sendImage({
        formData: formData,
        tempId: tempId,
        chat: this.currentChat,
      }).then(() => {
        this.fetchVerifyNotifiedChats("in_progress")
      });

      this.file = null;
      this.quoteMessage = null;
    },

    sendVoiceMessage(blob) {
      let tempId = this.generateTempId();

      let tempMessage = {
        id: tempId,
        ack: null,
        backupToTryResend: blob,
        chat_id: this.$route.params.id,
        media: URL.createObjectURL(blob),
        failed: false,
        fromMe: true,
        time: moment().unix(),
        type: "voice",
        instance: {
          type: this.channelTypeSelected?.type
        },
        agent: {
          id: this.user.id,
          name: this.user.name
        }
      };

      this.addRecentMessage({message: tempMessage});

      let formData = new FormData();

      formData.append("chat_id", this.$route.params.id);
      formData.append("audio", blob);
      formData.append("instance_id", this.currentInstanceId);

      if (this.quoteMessage) {
        formData.append("message_id", this.quoteMessage.id);
        tempMessage["quoted_msg"] = {
          id: this.quoteMessage.id,
          body: this.quoteMessage.body,
          media: this.quoteMessage.media,
          type: this.quoteMessage.type
        };
        // this.quoteMessage = null;
      }

      this.sendVoice({
        formData: formData,
        tempId: tempId,
        chat: this.currentChat
      }).then(() => {
        this.fetchVerifyNotifiedChats("in_progress")
      });
      this.quoteMessage = null;
    },

    sendVideoMessage(text) {
      let tempId = this.generateTempId();

      let tempMessage = {
        id: tempId,
        ack: "",
        backupToTryResend: this.file,
        body: text,
        chat_id: this.$route.params.id,
        media: URL.createObjectURL(this.file),
        failed: false,
        fromMe: true,
        time: moment().unix(),
        type: "video",
        instance: {
          type: this.channelTypeSelected?.type
        },
        agent: {
          id: this.user.id,
          name: this.user.name
        }
      };

      this.addRecentMessage({message: tempMessage});

      let formData = new FormData();

      formData.append("chat_id", this.$route.params.id);
      formData.append("video", this.file);
      formData.append("caption", text);
      formData.append("body", text);
      formData.append("instance_id", this.currentInstanceId);

      if (this.quoteMessage) {
        formData.append("message_id", this.quoteMessage.id);
        tempMessage["quoted_msg"] = {
          id: this.quoteMessage.id,
          body: this.quoteMessage.body,
          media: this.quoteMessage.media,
          type: this.quoteMessage.type
        };
        // this.quoteMessage = null;
      }

      this.sendVideo({
        formData,
        tempId,
        chat: this.currentChat,
      }).then(() => {
        this.fetchVerifyNotifiedChats("in_progress")
      });

      this.file = null;
      this.quoteMessage = null;
    },

    sendAudioMessage() {
      let tempId = this.generateTempId();

      let tempMessage = {
        id: tempId,
        ack: "",
        backupToTryResend: this.file,
        chat_id: this.$route.params.id,
        media: URL.createObjectURL(this.file),
        failed: false,
        fromMe: true,
        time: moment().unix(),
        type: "audio",
        instance: {
          type: this.channelTypeSelected?.type
        },
        agent: {
          id: this.user.id,
          name: this.user.name
        }
      };

      let formData = new FormData();

      this.addRecentMessage({message: tempMessage});

      formData.append("chat_id", this.$route.params.id);
      formData.append("audio", this.file);
      formData.append("instance_id", this.currentInstanceId);

      if (this.quoteMessage) {
        formData.append("message_id", this.quoteMessage.id);
        tempMessage["quoted_msg"] = {
          id: this.quoteMessage.id,
          body: this.quoteMessage.body,
          media: this.quoteMessage.media,
          type: this.quoteMessage.type
        };
        // this.quoteMessage = null;
      }

      this.sendAudio({
        formData,
        tempId,
        chat: this.currentChat
      }).then(() => {
        this.fetchVerifyNotifiedChats("in_progress")
      });

      this.file = null;
      this.quoteMessage = null;
    },

    sendForwardMessage(selectedChat, fields) {
      let tempId = this.generateTempId();

      let tempMessage = {
        id: tempId,
        ack: "device",
        body: fields.body,
        chat_id: selectedChat.id,
        failed: false,
        fromMe: true,
        media_original_name: fields.media_original_name,
        number: selectedChat.number,
        size: fields.size,
        isForwarded: true,
        time: moment().unix(),
        type: fields.type,
        instance: {
          type: this.channelTypeSelected?.type
        },
        agent: {
          id: this.user.id,
          name: this.user.name
        }
      };

      this.addRecentMessage({message: tempMessage});

      let formData = new FormData();
      formData.append("chat_id", this.$route.params.id);
      formData.append("message_id", fields.internal_id);
      this.sendForward({
        data: {
          chat_ids: [selectedChat.id],
          message_id: fields.internal_id,
          instance_id: this.currentInstanceId
        },
        chat: selectedChat,
        message: fields,
        tempId: tempId,
        formData: formData
      }).then(() => {
        this.$refs.forwardedModal.close();
        this.fetchVerifyNotifiedChats("in_progress")
      });
    },

    sendDocumentMessage() {
      let tempId = this.generateTempId();

      let tempMessage = {
        id: tempId,
        ack: "",
        backupToTryResend: this.file,
        body: this.file.name,
        chat_id: this.$route.params.id,
        media: URL.createObjectURL(this.file),
        failed: false,
        fromMe: true,
        time: moment().unix(),
        type: "document",
        instance: {
          type: this.channelTypeSelected?.type
        },
        agent: {
          id: this.user.id,
          name: this.user.name
        }
      };

      this.addRecentMessage({message: tempMessage});

      let formData = new FormData();

      formData.append("chat_id", this.$route.params.id);
      formData.append("file", this.file);
      formData.append("instance_id", this.currentInstanceId);

      if (this.quoteMessage) {
        //TDO: change parameter to quote_id
        formData.append("message_id", this.quoteMessage.id);
        tempMessage["quoted_msg"] = {
          id: this.quoteMessage.id,
          body: this.quoteMessage.body,
          media: this.quoteMessage.media,
          type: this.quoteMessage.type
        };
        // this.quoteMessage = null;
      }

      this.sendDocument({
        formData,
        tempId: tempId,
        chat: this.currentChat
      }).then(() => {
        this.fetchVerifyNotifiedChats("in_progress")
      });

      this.file = null;
      this.quoteMessage = null;
    },

    deleteChatMessages(message) {
      this.deleteWhatsappMessage({payload: message, chat_id: this.$route.params.id});
    },

    setHover(messageId) {
      this.dashText = messageId;
    },

    scroll() {
      if (this.$refs.panel && this.$refs.panel.scrollTop === 0) {
        if (this.updateContact) {
          this.preLoadMessages({
            id: this.currentChat.id,
            instance_id: this.currentChat.instance_id
          }).then(() => {
            this.loadMessagesContext = "historic";
            this.findMessagesHistoricByNumber({
              number: this.currentChat.number,
              page: this.protocolFound || this.messageFound ? this.currentChat.pagination.current_page : 1,
              instance_id: this.currentChat.instance_id,
              chat_id: this.$route.params.id
            }).then(() => {
              if (this.$refs.panel && this.$refs.panel.clientHeight >= this.$refs.panel.scrollHeight) {
                this.addMessagesToChat({
                  messages: this.currentChat.stashedMessages,
                  chat_id: this.$route.params.id
                });
                this.findMessagesHistoricByNumber({
                  number: this.currentChat.number,
                  page: this.currentChat.pagination.current_page + 1,
                  instance_id: this.currentChat.instance_id,
                  chat_id: this.$route.params.id
                });
              }
            });
          });
        }
        if (this.currentChat.pagination) {
          if (this.currentChat.allowNewMessages && (!this.messageFound || !this.protocolFound)) {
            this.setDisableAutoScroll(true);
            this.addMessagesToChat({
              messages: this.currentChat.stashedMessages,
              chat_id: this.$route.params.id
            });
          }
          if (this.currentChat.pagination.current_page < this.currentChat.sumTotalPages) {
            if (this.loadMessagesContext === "current") {
              this.findOlderMessages({
                id: this.$route.params.id,
                page: this.currentChat.pagination.current_page + 1,
                instance_id: this.currentChat.instance_id
              });
              if (this.currentChat.pagination.current_page === this.this.currentChat.totalPages.current) {
                this.setLoadMessagesContext('historic')
              }
            } else if (this.loadMessagesContext === "historic") {
              this.findMessagesHistoricByNumber({
                number: this.currentChat.number,
                page: this.currentChat.pagination.current_page + 1,
                instance_id: this.currentChat.instance_id,
                chat_id: this.$route.params.id
              });
            }
          } else if (this.currentChat.pagination.total_pages === this.currentChat.sumTotalPages) {
            if (this.loadMessagesContext === "current") {
              this.loadMessagesContext = "historic";
              this.findMessagesHistoricByNumber({
                number: this.currentChat.number,
                page: 1,
                instance_id: this.currentChat.instance_id,
                chat_id: this.$route.params.id
              });
            } else if (this.isManager) {
              this.findMessagesHistoricByNumber({
                number: this.currentChat.number,
                page: 1,
                instance_id: this.currentChat.instance_id,
                chat_id: this.$route.params.id
              }).then(() => {
                this.addMessagesToChat({
                  messages: this.currentChat.stashedMessages,
                  chat_id: this.$route.params.id
                });
                this.findMessagesHistoricByNumber({
                  number: this.currentChat.number,
                  page: this.currentChat.pagination.current_page + 1,
                  instance_id: this.currentChat.instance_id,
                  chat_id: this.$route.params.id
                });
              });
            }
          }
        }
      }
      if (this.currentChat &&
        this.currentChat?.pagination &&
        this.currentChat?.pagination.sumTotalPages === this.currentChat?.pagination.current_page &&
        this.loadMessagesContext === "historic" &&
        !this.currentChat?.stashedMessages) {
        this.setAllowNewMessages({payload: this.currentChat, allowNewMessages: false})
      }
    },

    formatEndSnoozeData(time) {
      return moment.unix(time).format(`DD/MM/YYYY [às] HH:mm`);
    },

    download(url) {
      window.open(url, "_blank");
    },

    tryResendFailedMessage(data) {
      let message = this.currentChat.messagesData[data.id];
      if (message.quoted_msg)
        this.quoteMessage = message.quoted_msg;
      this.removeMessageById(data);
      switch (message.type) {
        case "chat":
          if (message.isForwarded) this.sendForwardMessage(this.currentChat, message);
          else
            this.sendTextMessage({
              text: message.body,
              instance_id: this.currentInstanceId
            });
          break;
        case "image":
          this.file = message.backupToTryResend;
          this.sendImageMessage(message.body);
          break;
        case "document":
          this.file = message.backupToTryResend;
          this.sendDocumentMessage();
          break;
        case "audio":
          this.file = message.backupToTryResend;
          this.sendAudioMessage();
          break;
        case "video":
          this.file = message.backupToTryResend;
          this.sendVideoMessage();
          break;
        case "ptt":
          this.sendVoiceMessage(message.backupToTryResend);
          break;
        case "template":
          this.sendMessageTemplates({
            instance_id: this.currentInstanceId,
            chat_id: this.currentChat.id,
            template_name: message.backupToTryResend.name,
            template_language: message.backupToTryResend.language,
            template_id: message.backupToTryResend.id
          });
          this.sendTemplateMessage(message.backupToTryResend);
          break;
      }
    },

    setQuoteMessage(id) {
      this.quoteMessage = this.currentChat.messagesData[id];
      this.scrollToDown();
    },

    scrollToDown() {
      this.$refs.panel.scrollTo(0, this.$refs.panel.scrollHeight);
    },

    openModal(item) {
      this.$refs.forwardedModal.open(item);
    },

    dragenter() {
      if (!this.cursorInChat) this.showDragableArea = true;
    },

    getQuotedMessageMedia(message) {
      let media = "";

      if (message.fromMe) media = this.companyDomain + message.quoted_msg.media;
      else media = message.quoted_msg.media;

      return media;
    },

    formatProtocol(message) {
      return message.replace("Protocolo do Atendimento: ", "");
    },

    verifyShowQuoted(message) {
      const quotedMessage = message.quoted_msg
      const hasId = !!quotedMessage?.id
      const hasBodyIfNecessary = ['chat', 'template'].includes(quotedMessage?.type) && quotedMessage?.body

      if (!quotedMessage || !hasId || !hasBodyIfNecessary) {
        return false
      }
      return true
    }
  }
};
</script>

<style lang="scss"
       scoped>
.loading-ballon {
  overflow-wrap: break-word;
  word-break: break-all;
  white-space: pre-wrap;
  display: inline-block;
  border-radius: 8px !important;
  padding: 3px;
  padding-bottom: 3px;
  position: relative;
  font-weight: 400;
  font-size: 14px;
  padding: $ballon-padding !important;
  padding-bottom: $ballon-padding-bottom !important;
}

.loading-message-container {
  overflow: hidden !important;
}

.disabled {
  pointer-events: none !important;
  opacity: 0.6;
}

.file-preview-active {
  display: none !important;
}

.chat-main {
  height: 100vh;
  width: 100%;
  position: relative;

  &.agent-top-bar-active {
    height: calc(100vh - 56px);
  }

  .header {
    background-color: green;
    height: 60px;
  }

  .main {
    .separator {
      height: $long-message-space;
    }

    padding: 0px $whatsapp-spacing-3;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;

    &::-webkit-scrollbar {
      width: $scrollbar-width !important;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #b0c5c2 !important;
      border-radius: $scrollbar-radius !important;
    }

    .panel {
      display: block;
      margin-top: auto !important;

      .day {
        background-color: white;
        display: inline-block;
        border-radius: $whatsapp-border-radius;
        text-transform: uppercase;
        margin-bottom: 16px;
        margin-top: 15px;
        font-size: 12px;
      }
    }
  }

  .reply-image {
    height: 68px;
    width: 68px;
    object-fit: cover;
    border-radius: 0 $whatsapp-border-radius $whatsapp-border-radius 0;
  }

  .quote-content {
    background-color: #f0f4fa;
    border-radius: $whatsapp-border-radius $whatsapp-border-radius $whatsapp-border-radius 0;

    p {
      max-height: 28px;
      overflow: hidden;
      text-overflow: clip;
    }
  }
}
</style>
