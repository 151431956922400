export default {
  actions: [],
  actionToEdit: {},
  prevActionId: null,

  offcanvasEditActionIsOpen: false,
  offcanvasNewActionIsOpen: false,

  groupChannels: [],
  loadingFlags: {
    createAction: false,
    updateAction: false,
    deleteAction: false,
    groupChannels: false,
  },

  updateCoordinates: false,
  zoom: 1.0
}
