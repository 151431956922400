<template>
  <div
    class="offcanvas-body d-flex flex-column"
    style="gap: 1rem"
  >
    <action-component
      icon-bg="#D6E9D9"
      icon-class="fa-regular fa-messages-question"
      icon-color="#015D0D"
      :name="$t('multiple_choice_question')"
    />

    <div class="col-auto">
      <label
        class="form-label"
        for="message"
      >{{ $t('message_text') }}</label>
      <input
        id="message"
        v-model="textContent"
        :class="{'is-invalid': !textContent}"
        class="form-control"
        :placeholder="$t('what_is_your_message')"
        readonly
        type="text"
      >
    </div>
    <p class="m-0 fw-medium">
      {{ $t('options') }}
    </p>
    <div
      v-for="(item,index) in choices"
      :key="index"
      class="col-auto"
    >
      <label
        class="form-label"
        for="optionTitle"
      >{{ item.expected_message_response }}</label>
      <input
        id="optionTitle"
        v-model="choices[index].text_content"
        :class="{'is-invalid': !choices[index].text_content}"
        :placeholder="$t('option') + ' ' + item.expected_message_response"
        class="form-control"
        readonly
      >
    </div>
    <div
      v-for="(item, index) in new_choices"
      :key="index"
      class="col-auto"
    >
      <label
        class="form-label"
        for="optionTitle"
      >{{ item.expected_message_response }}
      </label>

      <div class="input-group">
        <input
          id="optionTitle"
          v-model="new_choices[index].text_content"
          :class="{'is-invalid':hasErrors && !new_choices[index].text_content}"
          :placeholder="$t('option') + ' ' + item.expected_message_response"
          class="form-control"
        >
        <span
          class="input-group-text fe fe-x cursor-pointer"
          @click="new_choices.splice(index, 1)"
        />
      </div>
    </div>
    <hr class="w-100">
    <button
      class="btn btn-sm btn-light align-self-center"
      style="width: 141px"
      @click="addNewChoiceItem"
    >
      + {{ $t('new_option') }}
    </button>
    <div class="mt-auto" />
    <app-button
      :disabled="hasErrors && Object.values(new_choices).some(el => !el.text_content) || !new_choices.length"
      class="col-auto"
      @click="editAction"
    >
      {{ $t('capitalizes.save') }}
    </app-button>
    <app-button
      class="col-auto"
      color="text-danger"
      @click="_deleteAction"
    >
      <span class="fa-regular fa-trash" />
      {{ $t('capitalizes.exclude') }}
    </app-button>
  </div>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from "vuex";

import ActionComponent from "@chatbot/pages/workflow/components/action-component.vue";
import AppButton from "@components/app-button.vue";

export default {
  name: "EditChoice",

  components: {
    AppButton,
    ActionComponent
  },

  data() {
    return {
      textContent: "",
      choices: [],
      new_choices: [],
      hasErrors: false,
    }
  },

  mounted() {
    this.textContent = this.actionToEdit.text_content
    this.choices = this.actionToEdit.choices.data
  },

  watch: {
    actionToEdit(action) {
      if (action.type === "multiple_choice") {
        this.textContent = action.text_content
        this.choices = action.choices.data
      }
    }
  },

  computed: {
    ...mapGetters("chatbot/actions", {
      actionToEdit: "getActionToEdit"
    })
  },

  methods: {
    ...mapActions("chatbot/chatbots", ["fetchChatbot"]),
    ...mapActions("chatbot/actions", [
      "createAction",
      "deleteAction"
    ]),
    ...mapMutations("chatbot/actions", [
      "setOffcanvasEditActionIsOpen",
      "setActionToEdit",
      "changeUpdateCoordinates"
    ]),

    addNewChoiceItem() {
      const totalChoices = this.choices.length + this.new_choices.length
      if (totalChoices == 12) {
        return
      }

      this.new_choices.push({
        text_content: "",
        expected_message_response: String(totalChoices + 1),
        default_next_action: "",
      })
    },

    editAction() {
      if (!this.textContent || Object.values(this.new_choices).some(el => !el.text_content)) {
        this.hasErrors = true
        return
      } else {
        this.hasErrors = false
      }

      const asyncCreateActions = this.new_choices.map((choice) => {
        const payload = {
          type: "multiple_choice_item",
          expected_message_response: choice.expected_message_response,
          chatbot_action_id: this.actionToEdit.id,
          text_content: choice.text_content,
          chatbot_id: this.$route.params.id
        }
        return this.createAction(payload)
      })

      Promise.all(asyncCreateActions).then(() => {
        this.new_choices = []
        this.setOffcanvasEditActionIsOpen(false)
        this.fetchChatbot(this.$route.params.id).then(() => {
          this.changeUpdateCoordinates()
        })
      })
    },

    _deleteAction() {
      this.deleteAction(this.actionToEdit.id).then(() => {
        this.setOffcanvasEditActionIsOpen(false)
        this.fetchChatbot(this.$route.params.id).then(() => {
          this.changeUpdateCoordinates()
        })
      })
    }
  }
}
</script>
