<template>
  <div
    ref="canvas"
    class="offcanvas offcanvas-end rounded-start background-color"
    style="width: 686px !important;"
  >
    <div class="offcanvas-header border-0 py-0">
      <header-component
        class="ms-3"
        data-bs-dismiss="offcanvas"
        show-redirect-link-button
        style="margin-bottom:40px"
        :title="mode === 'new' ? $t('create_interval') : $t('update_interval')"
      />
    </div>

    <div class="offcanvas-body">
      <div class="row">
        <div class="col-lg-12">
          <div
            v-if="errorMessage"
            class="alert alert-danger fade show"
            role="alert"
          >
            <span class="fe fe-alert-octagon" />&nbsp; {{ errorMessage }}
          </div>
        </div>
      </div>
      <div class="card">
        <div class="card-header">
          <h4 class="mb-0">
            {{ $t("information_interval") }}
          </h4>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-6">
              <div class="form-group">
                <label class="form-label">{{ $t("name_data") }}</label>
                <input
                  v-model="fields.name"
                  :class="{
                    'form-control': true,
                    'is-invalid': errorsInterval.name
                  }"
                  placeholder="Nome"
                >
                <div class="invalid-feedback">
                  {{ errorsInterval.name && errorsInterval.name[0] }}
                </div>
              </div>
            </div>

            <div class="col-6">
              <div class="form-group">
                <label class="form-label">{{ $t("color_general") }} </label>
                <color-picker-dropdown
                  v-model="fields.color"
                  :errors="errorsInterval.color && errorsInterval.color[0]"
                />
              </div>
            </div>

            <div class="col-6">
              <div class="form-group">
                <label class="form-label">{{ $t("max_break_time") }} </label>
                <div class="input-group input-group-merge">
                  <input
                    v-model="fields.minutes"
                    :class="{
                      'is-invalid': errorsInterval.minutes
                    }"
                    class="form-control border-end-0"
                    min="0"
                    placeholder="10"
                    type="number"
                  >
                  <span class="input-group-text text-secondary border-start-0">{{ $t("min") }}</span>
                </div>
                <div class="text-danger text-small">
                  {{ errorsInterval.minutes && errorsInterval.minutes[0] }}
                </div>
              </div>
            </div>

            <div class="col-6">
              <div class="form-group">
                <label class="form-label">{{ $t("max_daily_break_time") }}</label>
                <div class="input-group input-group-merge">
                  <input
                    v-model="fields.limit"
                    :class="{
                      'is-invalid': errorsInterval.limit
                    }"
                    class="form-control border-end-0"
                    min="0"
                    placeholder="60"
                    type="number"
                  >
                  <span class="input-group-text text-secondary border-start-0">{{ $t("min") }}</span>
                </div>
                <div class="text-danger text-small">
                  {{ errorsInterval.limit && errorsInterval.limit[0] }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card">
        <div class="card-header">
          <h4 class="mb-0">
            {{ $t("interval_config") }}
          </h4>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-6">
              <div class="form-group">
                <label class="form-label">{{ $t("interval_qualification") }}</label>
                <single-select
                  id="intervalClassification"
                  ref="selectIntervalClassification"
                  :list-array="intervalClassificationList"
                  label="name"
                  :error="this.errorsInterval.type && this.errorsInterval.type[0]"
                  :current-option="fields.type.name"
                  @selected-option="fields.type = $event"
                />
              </div>
            </div>
            <div class="col-6">
              <div class="form-group">
                <label
                  class="form-label"
                  :class="{
                    'text-muted': fields.auto_start
                  }"
                >
                  {{ $t("interval_return") }}
                </label>
                <single-select
                  id="intervalReturn"
                  ref="selectIntervalReturn"
                  :list-array="intervalReturnList"
                  label="name"
                  :error="this.errorsInterval.return_type && this.errorsInterval.return_type[0]"
                  :current-option="fields.return_type.name"
                  @selected-option="fields.return_type = $event"
                  :disabled="fields.auto_start"
                />
              </div>
            </div>
            <div class="col-12 mb-4">
              <div class="form-check form-switch d-flex align-items-center gap-3">
                <input
                  v-model="fields.auto_start"
                  class="form-check-input"
                  :class="{
                    'is-invalid': errorsInterval.auto_start
                  }"
                  @change="clearTeamsAndVerifyIfAutomaticReturnShouldBeTrue"
                  type="checkbox"
                  id="autoStart"
                >
                <label
                  class="form-check-label text-large fw-bold"
                  for="autoStart"
                >
                  {{ $t("automatic_interval") }}
                </label>
                <tooltip-component
                  :title="$t('automatic_interval_form_description')"
                  placement="top"
                />
              </div>
              <div class="text-small text-danger">
                {{ errorsInterval.auto_start && errorsInterval.auto_start[0] }}
              </div>
            </div>
            <div
              v-if="fields.auto_start"
              class="col-12"
            >
              <div class="text-danger">
                {{ errorsInterval.teams && errorsInterval.teams[0] }}
              </div>
              <interval-teams-automations
                @send-teams-start-time-cards="fields.teams = $event"
                :teams-auto-break-intervals="interval?.team_auto_break_intervals"
              />
            </div>
          </div>
        </div>
      </div>

      <div
        class="mx-auto"
        style="width: 261px"
      >
        <app-button
          :loading="loadingFlags.addInterval || loadingFlags.updateInterval"
          class="w-100"
          @click="submit"
        >
          {{ mode === "new" ? $t("add_capitalize") : $t("update_omni") }}
        </app-button>
        <app-button
          v-if="mode === 'edit'"
          :loading="loadingFlags.removeInterval"
          class="fw mb-4"
          color="text-danger"
          @click="deleteInterval"
        >
          <span class="fe fe-trash" />
          {{ $t("capitalizes.exclude") }}
        </app-button>
      </div>
    </div>
  </div>
</template>

<script>
import { Offcanvas } from "bootstrap";
import { colors, IntervalsReturnType } from "@/utils";
import appButton from "@/components/app-button.vue";
import { mapActions, mapGetters, mapMutations } from "vuex";
import ColorPickerDropdown from "@/components/color-picker-dropdown.vue";
import HeaderComponent from "@admin/pages/3c-partners/partners/components/header-component.vue";
import SingleSelect from "@components/single-select.vue";
import TooltipComponent from "@/components/tooltip-component.vue";
import IntervalTeamsAutomations from "./interval-teams-automations.vue";
export default {
  data() {
    return {
      IntervalsReturnType,
      offcanvas: null,
      interval: null,
      colors: [],
      fields: {
        name: "",
        color: "",
        minutes: 0,
        limit: 0,
        type: "",
        return_type: "",
        auto_start: false,
        teams: []
      },
      mode: "new",
      errorMessage: "",
      intervalClassificationList: [
        {
          id: 1,
          name: "Produtivo",
          send: "productive"
        },
        {
          id: 2,
          name: "Improdutivo",
          send: "unproductive"
        },
        {
          id: 3,
          name: "NR 17",
          send: "NR17"
        }
      ],

      intervalReturnList: [
        {
          id: 1,
          name: this.$t("flexible_return"),
          send: IntervalsReturnType.FLEXIBLE_RETURN
        },
        {
          id: 2,
          name: this.$t("automatic_return"),
          send: IntervalsReturnType.AUTOMATIC_RETURN
        },
        {
          id: 3,
          name: this.$t("request_return"),
          send: IntervalsReturnType.REQUEST_RETURN
        }
      ]
    };
  },
  mounted() {
    this.colors = colors;
    this.errorMessage = "";
    this.offcanvas = new Offcanvas(this.$refs.canvas);
  },
  components: {
    SingleSelect,
    HeaderComponent,
    appButton,
    ColorPickerDropdown,
    TooltipComponent,
    IntervalTeamsAutomations
  },
  computed: {
    ...mapGetters("manager/intervals", {
      workBreakGroup: "getWorkBreakGroup",
      loadingFlags: "getLoadingFlags",
      errorsInterval: "getErrorsInterval"
    })
  },
  methods: {
    ...mapActions("manager/intervals", [
      "updateInterval",
      "addInterval",
      "findWorkBreakGroupById",
      "removeInterval"
    ]),

    ...mapMutations("manager/intervals", ["clearErrorsInterval"]),
    clearErrors() {
      if (this.errorsInterval) {
        this.clearErrorsInterval();
      }
    },
    convertType(interval) {
      return this.intervalClassificationList.find(item => item.send === interval) || {};
    },
    getIntervalReturn(interval) {
      return this.intervalReturnList.find(item => item.send === interval);
    },
    openOffCanvas() {
      setTimeout(() => {
        this.offcanvas.show();
      }, 100);
    },
    edit(item) {
      this.mode = "edit";
      this.$refs.selectIntervalClassification.closeCollapse();
      this.$refs.selectIntervalReturn.closeCollapse();
      this.clearErrors();
      this.interval = item;
      this.fields = {
        name: item.name,
        color: item.color.toLowerCase(),
        minutes: item.minutes,
        limit: item.limit,
        type: this.convertType(item.type),
        return_type: this.getIntervalReturn(item.return_type),
        auto_start: item.auto_start,
        teams: item.team_auto_break_intervals
      };
      this.openOffCanvas();
    },
    new() {
      this.mode = "new";
      this.$refs.selectIntervalClassification.closeCollapse();
      this.$refs.selectIntervalReturn.closeCollapse();
      this.clearErrors();
      this.interval = null;
      this.fields = {
        name: "",
        color: "",
        minutes: 5,
        limit: 30,
        type: "",
        return_type: "",
        auto_start: false,
        teams: []
      };
      this.openOffCanvas();
    },
    handleSubmit(action) {
      this.clearErrorsInterval();
      let {
        type: { send },
        return_type: { send: return_type },
        ...otherFields
      } = this.fields;

      let fields = {
        type: send,
        return_type,
        ...otherFields
      };

      action({
        list_id: this.$route.params.id,
        id: this.interval?.id,
        fields
      }).then(() => {
        this.findWorkBreakGroupById({ id: this.workBreakGroup.id, include: ["intervals"] });
        this.offcanvas.hide();
      });
    },
    create() {
      this.handleSubmit(this.addInterval);
    },
    update() {
      this.handleSubmit(this.updateInterval);
    },
    async deleteInterval() {
      let response = await this.showConfirmationMessage({
        title: this.$t("delete_breaks_question"),
        text: this.$t("delete_breaks_confirmation"),
        type: "badWarning"
      });

      if (response.isConfirmed) {
        await this.removeInterval({
          list_id: this.$route.params.id,
          id: this.interval.id
        });

        this.findWorkBreakGroupById({ id: this.workBreakGroup.id, include: ["intervals"] });
      }
    },
    submit() {
      this.mode === "new" ? this.create() : this.update();
    },
    clearTeamsAndVerifyIfAutomaticReturnShouldBeTrue() {
      if (!this.fields.auto_start) {
        this.fields.teams = [];
      }

      if (this.fields.auto_start) {
        this.fields.return_type = {
          id: 2,
          name: this.$t("automatic_return"),
          send: IntervalsReturnType.AUTOMATIC_RETURN
        }
      }
    }
  }
};
</script>
<style scoped lang="scss">
.background-color {
  background-color: $color-background;
}
</style>
