<template>
  <div
    class="d-flex align-items-center"
    style="height: 160px"
  >
    <div
      class="card mb-0"
      style="min-width: 350px"
    >
      <div class="card-body p-0">
        <div
          class="d-flex align-items-center"
          style="padding: 1rem 1rem 0 1rem; width: 350px"
        >
          <action-component
            icon-bg="#D6E9D9"
            icon-class="fa-regular fa-text"
            icon-color="#015D0D"
            :name="$t('send_a_message')"
          />
          <app-button
            class="btn-md col-auto"
            color="btn-gray-blue"
            @click="editAction"
          >
            <span class="fa-regular fa-pen-to-square" />
          </app-button>
        </div>
        <hr class="w-100">
        <div
          class="d-flex flex-column justify-content-center"
          style="padding: 0 1rem 1rem 1rem; width: 350px"
        >
          <card-text :text="action.text_content" />
        </div>
      </div>
    </div>
    <default-arrow />
  </div>
</template>

<script>
import {mapMutations} from "vuex";

import ActionComponent from "@chatbot/pages/workflow/components/action-component.vue";
import CardText from "@chatbot/pages/workflow/components/card-text.vue";
import DefaultArrow from "@chatbot/pages/workflow/components/arrows/default-arrow.vue";
import AppButton from "@components/app-button.vue";

export default {
  name: "CardMessage",

  props: {
    action: {
      type: Object,
      required: true
    }
  },

  components: {
    AppButton,
    DefaultArrow,
    CardText,
    ActionComponent
  },

  methods: {
    ...mapMutations("chatbot/actions", [
      "setOffcanvasEditActionIsOpen",
      "setActionToEdit"
    ]),

    editAction() {
      this.setActionToEdit(this.action)
      this.setOffcanvasEditActionIsOpen(true)
    }
  }
}
</script>
