<template>
  <div class="main-content pb-6">
    <div class="container-fluid">
      <div class="row justify-content-center">
        <div class="col-12 col-lg-10 col-xl-8">
          <header-component
            redirect-link="/manager/receptive-ivrs"
            :title="$t('receptive_IVR_capitalize')"
          />

          <flash-message />

          <!-- row  -->
          <div class="row">
            <div class="col-lg-12">
              <div class="form-group 0">
                <label class="form-label required">{{ $t('capitalizes.name') }}</label>
                <input
                  type="email"
                  :class="{
                    'form-control': true,
                    'is-invalid': errors.name,
                  }"
                  v-model="fields.name"
                  :placeholder="$t('capitalizes.name')"
                >

                <div class="invalid-feedback">
                  {{ errors.name && errors.name[0] }}
                </div>
              </div>
            </div>

            <div class="col-lg-12">
              <div class="form-group">
                <label
                  for="name"
                  class="form-label required"
                >{{ $t('warning_audio') }}</label>
                <span class="form-text">{{ $t('send_mp3_file') }}</span>

                <div
                  :class="{
                    'card mb-0': true,
                    'is-invalid': errors.audio,
                  }"
                >
                  <div class="card-body">
                    <file-upload
                      @select-file="selectFile"
                      filter="audio/mp3,audio/mpeg"
                      icon="fe fe-headphones"
                    />
                  </div>
                </div>
                <div class="invalid-feedback">
                  {{ errors.audio && errors.audio[0] }}
                </div>
              </div>
            </div>

            <div class="col-lg-12">
              <div class="form-group 0">
                <div class="card mb-0">
                  <div class="card-header">
                    <div class="row align-items-center">
                      <div class="col">
                        <h4 class="card-header-title">
                          {{ $t('redirect_keys') }}
                        </h4>
                      </div>

                      <div class="col-auto">
                        <button
                          class="btn-primary btn btn-sm px-5"
                          :disabled="fields.keys.length >= 12"
                          @click="
                            fields.keys.push({
                              id: '',
                              key: '',
                              type: 'ReceptiveQueue',
                            })
                          "
                        >
                          {{ $t('add_key') }}
                        </button>
                      </div>
                    </div>
                  </div>
                  <div class="card-body">
                    <div
                      class="row"
                      v-if="fields.keys.length"
                    >
                      <div class="col-lg-6">
                        <label class="form-label">{{ $t('key') }}</label>
                      </div>
                      <div class="col-lg-5 4">
                        <label class="form-label">{{ $t('capitalizes.redirection') }}</label>
                      </div>
                    </div>
                    <div
                      class="row"
                      v-else
                    >
                      <span class="col-lg-12 text-muted b7 text-center">
                        {{ $t('no_key_added') }}
                      </span>
                    </div>
                    <div
                      class="row my-2"
                      v-for="(item, i) of fields.keys"
                      :key="i"
                    >
                      <div class="col-lg-6">
                        <div
                          :class="{
                            'btn-group group-keys mr-2 w-100': true,
                            'is-invalid': errors.hasOwnProperty(
                              'keys.' + i + '.key'
                            ),
                          }"
                          role="group"
                          aria-label="First group"
                        >
                          <button
                            type="button"
                            :class="{
                              'btn px-1': true,
                              'btn-outline-secondary': item.key != j,
                              'btn-primary': item.key == j,
                              'opacity-25': !key.avaliable && item.key != j
                            }"
                            :disabled="!key.avaliable && item.key != j"
                            v-for="(key, j) of keys"
                            :key="j"
                            @click="selectKey(item, j)"
                          >
                            {{ j }}
                          </button>
                        </div>

                        <div class="invalid-feedback">
                          {{
                            errors.hasOwnProperty("keys." + i + ".key") &&
                              errors["keys." + i + ".key"][0]
                          }}
                        </div>
                        <!-- {{
                          errors.errors["keys." + i + ".id"][0]
                        }} -->
                      </div>
                      <div class="col-lg-5">
                        <select
                          :class="{
                            'form-select w-100': true,
                            'is-invalid': errors.hasOwnProperty(
                              'keys.' + i + '.id'
                            ),
                          }"
                          v-model="item.id"
                          :disabled="receptiveQueuesLoading"
                        >
                          <option />
                          <option
                            v-for="queue of receptiveQueues"
                            :key="queue.id"
                            :value="queue.id"
                          >
                            {{ queue.name.split(" - ")[1] }}
                          </option>
                        </select>

                        <div class="invalid-feedback">
                          {{
                            errors.hasOwnProperty("keys." + i + ".id") &&
                              errors["keys." + i + ".id"][0]
                          }}
                        </div>
                      </div>
                      <div class="col-lg-1 0">
                        <a
                          class="btn text-primary w-100 px-1"
                          @click="removeKey(i)"
                        >
                          <span class="fe fe-trash" />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="invalid-feedback" />
              </div>
            </div>

            <div class="col-lg-12">
              <div class="form-group 0">
                <label class="form-label required">{{ $t('redirect_if_no_key') }}
                </label>

                <select
                  :class="{
                    'form-select w-100': true,
                    'is-invalid': errors.redirect_id,
                  }"
                  v-model="fields.redirect_id"
                  :disabled="receptiveQueuesLoading"
                >
                  <option />
                  <option
                    v-for="queue of receptiveQueues"
                    :key="queue.id"
                    :value="queue.id"
                  >
                    {{ queue.name.split(" - ")[1] }}
                  </option>
                </select>

                <div class="invalid-feedback">
                  {{ errors.redirect_id && errors.redirect_id[0] }}
                </div>
              </div>
            </div>

            <div class="col-lg-6">
              <label class="form-label required">{{ $t('press_key_waiting_time') }}</label>
              <div class="form-group 0">
                <input-text-range
                  color="#2c7be5"
                  v-model="fields.timeout"
                  :class="{
                    'is-invalid': errors.timeout,
                  }"
                />
                <div class="invalid-feedback">
                  {{ errors.timeout && errors.timeout[0] }}
                </div>
              </div>
            </div>

            <div class="col-lg-12">
              <app-button
                color="btn-primary"
                :loading="receptiveIvrsLoading"
                class="w-100"
                @click="submit()"
              >
                {{ $t('send_capitalize') }}
              </app-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import fileUpload from "@components/file-uploader";
import { mapActions, mapMutations, mapGetters } from "vuex";

import InputTextRange from "@components/input-text-range";
import flashMessage from "@/components/flash-message";
import AppButton from "@components/app-button";
import HeaderComponent from "@/components/header-component.vue";

export default {
  /**
   *
   */
  mounted() {
    this.setIncludes(["queues"]);
    this.findFilters();
  },

  /**
   *
   */
  components: {
    fileUpload,
    InputTextRange,
    AppButton,
    flashMessage,
    HeaderComponent
  },
  /**
   *
   */
  data() {
    return {
      keys: {
        1: { avaliable: true },
        2: { avaliable: true },
        3: { avaliable: true },
        4: { avaliable: true },
        5: { avaliable: true },
        6: { avaliable: true },
        7: { avaliable: true },
        8: { avaliable: true },
        9: { avaliable: true },
        0: { avaliable: true },
        "*": { avaliable: true },
        "#": { avaliable: true },
      },

      fields: {
        name: "",
        timeout: 0,
        redirect_id: "",
        keys: [],
      },
      receptiveQueues: []
    };
  },

  /**
   *
   */
  computed: {
    ...mapGetters("manager/filters", {
      filters: "getFilters",
    }),

    ...mapGetters("manager/receptive_ivrs", {
      errors: "getErrors",
      receptiveIvrsLoading: "getLoadingFlags",
    }),
  },

  /**
   *
   */
  methods: {
    /**
     *
     */

    ...mapActions("manager/filters", ["findFilters"]),
    ...mapMutations("manager/filters", ["setIncludes"]),
    ...mapActions("manager/receptive_ivrs", ["create"]),
    ...mapMutations("system", ["setFlashMessage"]),

    /**
     *
     */
    selectKey(item, key) {
      if (this.keys[item.key]) this.keys[item.key].avaliable = true;
      this.keys[key].avaliable = false;
      item.key = key;
    },

    /**
     *
     */
    selectFile(file) {
      this.fields.audio = file;
    },

    /**
     *
     */
    removeKey(i) {
      if (this.fields.keys[i].key)
        this.keys[this.fields.keys[i].key].avaliable = true;
      this.fields.keys.splice(i, 1);
    },

    /**
     *
     */
    createFormData() {
      let formData = new FormData();

      if (this.fields.audio) formData.append("audio", this.fields.audio);

      formData.append("name", this.fields.name);
      formData.append("timeout", this.fields.timeout);
      formData.append("redirect_id", this.fields.redirect_id);
      formData.append("redirect_type", "ReceptiveQueue");

      for (let i = 0; i < this.fields.keys.length; i++) {
        formData.append("keys[" + i + "][id]", this.fields.keys[i].id);
        formData.append("keys[" + i + "][key]", this.fields.keys[i].key);
        formData.append("keys[" + i + "][type]", this.fields.keys[i].type);
      }
      return formData;
    },

    /**
     *
     */
    submit() {
      let formData = this.createFormData();
      this.create({ formData })
        .then((data) => {
          this.$router
            .push({
              path: `/manager/receptive-ivrs`,
            })
            .then(() => {
              this.setFlashMessage({
                message: data.message,
                type: "success",
              });
            });
        })
        .catch((data) => {
          this.setFlashMessage({
            message: data.message,
            type: "danger",
          });
        });
    },
  },
  watch: {
    filters: {
      handler(data) {
        if(data.queues)
          this.receptiveQueues = data.queues;
      },
      deep: true,
    }
  }
  /**
   * name: saddfs
timeout: 0
keys[0][id]: 1
keys[0][key]: 8
keys[0][type]: ReceptiveQueue
keys[1][id]: 9
keys[1][key]: 4
keys[1][type]: ReceptiveQueue
   */
};
</script>


<style lang="scss">
.btn-group.is-invalid {
  button {
    border-top: 1px solid red !important;
    border-bottom: 1px solid red !important;
  }
  button:first-child {
    border-left: 1px solid red !important;
  }
  button:last-child {
    border-right: 1px solid red !important;
  }
}

.btn-group.group-keys {
  :disabled {
    opacity: 0.4;
  }
}
</style>
