<template>
  <main-loading v-if="loadingFlags.fetchTeamById" />
  <div v-show="!loadingFlags.fetchTeamById">
    <header-component
      :title="mode === 'new' ? 'Nova equipe': fields.name"
      pretitle="EQUIPES"
      redirect-link="/manager/teams"
    />

    <div class="row">
      <div class="col-lg-8">
        <div class="form-group mb-4">
          <label
            for="exampleInputEmail1"
            class="form-label required"
          >
            Nome
          </label>
          <input
            :class="{
              'form-control': true,
              'is-invalid': errors?.name,
            }"
            v-model="fields.name"
          >
          <div class="invalid-feedback">
            {{ errors?.name && errors.name[0] }}
          </div>
        </div>
      </div>

      <div
        class="col-lg-4"
      >
        <div class="form-group">
          <label class="form-label required">{{ $t('color_general') }}</label>
          <color-picker-dropdown
            v-model="fields.color"
            :errors="errors?.color ? errors?.color[0] : ''"
          />
        </div>
      </div>
    </div>

    <div class="card">
      <div class="card-header">
        <h4 class="card-header-title">
          Supervisores
        </h4>
        <ul class="nav nav-tabs nav-tabs-sm card-header-tabs">
          <li class="nav-item">
            <button
              class="nav-link active two-lines"
              data-bs-toggle="tab"
              data-bs-target="#actives"
            >
              <span class="text-muted"> TOTAL </span>
              <br>
              <span class="subtitle">
                {{ fields.supervisors.length }}
              </span>
            </button>
          </li>
        </ul>
      </div>

      <div class="card-body">
        <VueMultiselect
          v-model="fields.supervisors"
          label="name"
          track-by="id"
          placeholder="Selecione os supervisores"
          select-label="Clique ou pressione enter para selecionar"
          deselect-label="Clique ou pressione enter para remover"
          selected-label="Selecionado"
          :multiple="true"
          :close-on-select="false"
          :preserve-search="true"
          :options="listSupervisor"
        />
      </div>
    </div>

    <div class="card">
      <div class="card-header">
        <h4 class="card-header-title">
          Agentes
          <div
            v-if="isLoadingAgent"
            class="spinner-border spinner-border-sm"
          >
            <span class="visually-hidden">Loading...</span>
          </div>
        </h4>
        <ul class="nav nav-tabs nav-tabs-sm card-header-tabs">
          <li class="nav-item">
            <button
              class="nav-link active two-lines"
              data-bs-toggle="tab"
              data-bs-target="#actives"
            >
              <span class="text-muted"> TOTAL </span>
              <br>
              <span class="subtitle">
                {{ fields.agents.length }}
              </span>
            </button>
          </li>
        </ul>
      </div>

      <div class="card-body">
        <VueMultiselect
          v-model="fields.agents"
          label="name"
          track-by="id"
          placeholder="Selecione os agents"
          select-label="Clique ou pressione enter para selecionar"
          deselect-label="Clique ou pressione enter para remover"
          selected-label="Selecionado"
          :multiple="true"
          :close-on-select="false"
          :preserve-search="true"
          :options="listAgent"
          :disabled="isLoadingAgent"
        />
        <div class="invalid-teams text-danger">
          {{ agentsErrorMessage }}
        </div>
      </div>
    </div>

    <div class="card">
      <div class="card-header">
        <h4 class="card-header-title">
          Campanha(s)
          <button
            type="button"
            class="btn btn-link p-0"
            ref="campaignsInfo"
          >
            <span class="fe fe-info text-primary ms-2 cursor-pointer" />
          </button>
          <small class="form-text text-muted">Adicione campanhas a sua equipe.</small>
        </h4>
      </div>

      <div class="card-body">
        <VueMultiselect
          label="name"
          track-by="id"
          placeholder="Selecione uma ou mais campanhas"
          select-label="Clique ou pressione enter para selecionar"
          deselect-label="Clique ou pressione enter para remover"
          selected-label="Selecionado"
          open-direction="bottom"
          v-model="fields.campaigns"
          :multiple="true"
          :close-on-select="false"
          :preserve-search="true"
          :options="activeCampaigns"
        />
      </div>
    </div>

    <div
      v-if="user.company.whatsapp_licenses"
      class="col-lg-12"
    >
      <div class="card">
        <div class="card-header">
          <h4 class="card-header-title">
            Whatsapp
          </h4>
          <div class="form-check form-switch">
            <input
              class="form-check-input"
              type="checkbox"
              v-model="fields.whatsapp"
            >
            <label class="form-check-label">Função {{ fields.whatsapp ? 'ativada' : 'desativada' }}</label>
          </div>
        </div>
        <div
          v-if="fields.whatsapp"
          class="card-body"
        >
          <div class="form-label">
            Qualificação
          </div>

          <select-qualification-list
            :show-only-whatsapp-qualifications="true"
            :errors="errors?.qualification_list_id"
            v-model="fields.qualification_list_id"
          />
        </div>
      </div>
    </div>

    <div class="col-lg-12">
      <app-button
        class="fw mb-3"
        :loading="loadingFlags.createTeam || loadingFlags.updateTeam"
        @click="submit()"
      >
        <span class="fe fe-save" /> {{ mode === 'new' ? 'Criar' : 'Salvar' }}
      </app-button>
    </div>

    <div
      class="col-lg-12"
      @click="_deleteTeam()"
    >
      <app-button
        class="fw mb-4"
        :loading="loadingFlags.deleteTeam"
        color="text-danger"
      >
        <span class="fe fe-trash" />
        Excluir
      </app-button>
    </div>
    <modal-transfer-multiple-whatsapp-chats
      v-if="mode === 'edit' && Object.values(errors).length"
      :team-id="this.$route.params.id"
      :errors="errors"
      :team-list="team.agents"
      :whatsapp-qualification-id="team.qualification_list_id"
      @on-transfer-conversations="submit()"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { generateTooltip } from "@/utils";

import AppButton from "@/components/app-button.vue";
import MainLoading from "@/components/main-loading.vue";
import VueMultiselect from 'vue-multiselect'
import SelectQualificationList from '@dialer/manager/components/select-qualification-list.vue';
import ColorPickerDropdown from "@/components/color-picker-dropdown.vue";
import ModalTransferMultipleWhatsappChats from "@dialer/manager/pages/settings/teams/components/modal-transfer-multiple-whatsapp-chats.vue";
import HeaderComponent from "@/components/header-component.vue";

export default {
  name: "FormTeams",
  data() {
    return {
      mode: 'new', // new, edit
      agentsErrorMessage: "",
      fields: {
        agents: [],
        supervisors: [],
        campaigns: [],
        color: "",
        name: "",
        whatsapp: false,
        qualification_list_id: null,
      },
      campaignsInfoTooltip: null,
    }
  },

  computed: {
    ...mapGetters("manager/teams", {
      team: "getTeam",
      loadingFlags: "getLoadingFlags",
      errors: "getErrors",
    }),
    ...mapGetters("manager/supervisor", {
      listSupervisor: "getList"
    }),
    ...mapGetters("manager/agent", {
      listAgent: "getList",
      isLoadingAgent: "isLoading",
    }),
    ...mapGetters("manager/campaign", {
      activeCampaigns: "getListActive"
    }),
    ...mapGetters("auth", {
      user: "getUser",
    }),
  },

  components: {
    AppButton,
    MainLoading,
    VueMultiselect,
    SelectQualificationList,
    ColorPickerDropdown,
    ModalTransferMultipleWhatsappChats,
    HeaderComponent
  },

  mounted() {
    if(this.$route.params.id) {
      this.mode = 'edit';
      this.fetchTeamById({ id: this.$route.params.id }).then(() => {
        this.fields.agents = this.team.agents;
        this.fields.supervisors = this.team.supervisors;
        this.fields.color = this.team.color;
        this.fields.name = this.team.name;
        this.fields.whatsapp = this.team.whatsapp;
        this.fields.qualification_list_id = this.team.qualification_list_id;
        this.fields.campaigns = this.team.campaigns;
      });
    }
    this.getActivedList();
    this.loadAgentList();
    this.loadSupervisorList();
    this.campaignsInfoTooltip = this.generateTooltip("Todos os agentes da equipe terão acesso a campanha.", this.$refs.campaignsInfo);
  },

  methods: {
    ...mapActions("manager/teams", ["createTeam","updateTeam", "deleteTeam", "fetchTeamById"]),
    ...mapActions("manager/agent", ["loadAgentList"]),
    ...mapActions("manager/campaign", ["getActivedList"]),
    ...mapActions("manager/supervisor", ["loadSupervisorList"]),
    ...mapActions("system", ["showConfirmationMessage"]),
    generateTooltip,
    submit() {
      this.agentsErrorMessage = "";
      let fields = JSON.parse(JSON.stringify(this.fields));

      fields.supervisors = fields.supervisors.map(({id}) => id);
      fields.agents = fields.agents.map(({id}) => id);
      fields.campaigns= fields.campaigns.map(({id}) => id);

      if(!fields.whatsapp)
        delete fields.qualification_list_id;

      if(this.mode === "new") {
        this.createTeam({ fields: fields })
        .then(() => {
          this.$router.push({ path: '/manager/teams' });
        });
      } else {
        this.updateTeam({
          id: this.team.id,
          fields: fields,
        }).then(() => this.$router.push({ path: '/manager/teams' }));
      }
    },

    async _deleteTeam() {
      let response = await this.showConfirmationMessage({
        title: "Você realmente deseja excluir essa equipe?",
        text: "A exclusão dessa equipe não poderá ser desfeita.",
        type: "badWarning"
      })

      if(response.isConfirmed) {
        this.deleteTeam({
          id: this.team.id,
        })
        .then(() => {
          this.$router.push({ path: `/manager/teams`, })
        })
      }
    },
  },
};
</script>
