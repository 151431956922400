<template>
  <div
    id="average-sales-chart-container"
    style="gap: 40px"
    class="d-flex align-items-end"
  >
    <div
      id="average-sales-chart-label"
      style="padding-bottom: 1rem"
      class="d-block lh-1 col-auto"
    >
      <span
        style="font-size: 2.5rem"
        class="tx-text-200 fw-medium d-inline-block"
      >
        {{ totalTime }}
      </span>
      <span
        style="font-size: 0.75rem"
        class="tx-text-gray fw-medium text-capitalize d-inline-block"
      >
        DIAS
      </span>
    </div>

    <div
      :style="`max-width: ${minWidth}px`"
      class="slider-container"
    >
      <swiper
        ref="swiperContainer"
        :slides-per-view="7.5"
        :space-between="8"
        :pagination="{
          dynamicBullets: true,
          clickable: true,
        }"
        :modules="modules"
      >
        <swiper-slide
          class="h-auto"
          v-for="(slide, index) in items"
          :key="`item-${index}-${slide.id}`"
        >
          <div
            :id="`slide${index}`"
            class="progress-rate-item w-100 d-flex flex-column justify-content-end h-100"
          >
            <span
              style="font-size: 0.75rem"
              class="tx-text-200 d-block fw-medium"
            >{{ `${slide.value}d` }}</span>
            <div
              :style="`height: ${slide.value}px; background-color: ${slide?.color || colors[index]}`"
              class="shape"
            />
            <div
              :title="slide.name"
              class="progress-rate-item-name text-truncate tx-text-gray"
            >
              <span>
                {{ slide.name }}
              </span>
            </div>
          </div>
        </swiper-slide>
      </swiper>
    </div>
  </div>
</template>
<script setup>
import {Swiper, SwiperSlide} from 'swiper/swiper-vue.mjs';
import 'swiper/swiper.css';
import 'swiper/modules/pagination.css'
import {Pagination} from 'swiper/modules';
import {
  ref,
  defineProps, onMounted, onBeforeUnmount
} from "vue";

import {elementObserverById} from "@/utils";

const props = defineProps({
  items: {
    type: Array,
    required: true
  },

  totalTime: {
    type: String,
    required: true
  }
})
const modules = ref([Pagination]);

const minWidth = ref(0)

function randomColor() {
  return `#${Math.floor(Math.random() * 16777215).toString(16)}`
}

const colors = ref([])

setMinWidth()

function setMinWidth() {
  elementObserverById('average-sales-chart-container').then((el) => {
    minWidth.value = (el.clientWidth - document.getElementById('average-sales-chart-label').clientWidth) - 40;
  });
}

onMounted(() => {
    if(props?.items?.length) {
      props.items.forEach(() => {
        colors.value.push(randomColor())
      });
    }

  window.addEventListener('resize', setMinWidth);
})

onBeforeUnmount(() => {
  window.removeEventListener('resize', setMinWidth);
})
</script>


<style scoped lang="scss">
.progress-rate-item {
  &-name {
    font-size: 0.75rem;
  }
}

.shape {
  width: 100%;
  background-color: black;
  border-radius: 2px;
}

:global(.swiper) {
  padding-bottom: 1rem;
}

:global(.swiper-pagination) {
  bottom: 0 !important;
}

.progress-badge {
  min-width: 59px;
  aspect-ratio: 59/64;
  gap: 0.5rem;
  font-size: 0.75rem;
  color: #015901;
  background-color: #C9F2CD;
  height: 100%;
}

.slider-container {
  max-width: 80%;
}
</style>