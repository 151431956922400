<template>
  <div class="col">
    <button
      v-if="isManager"
      class="btn btn-xs btn-rounded-circle btn-white float-start  me-3 p-0"
      style="height: 40px"
      @click="$router.push('/manager/crm/home')"
    >
      <i class="fe fe-arrow-left" />
    </button>
    <h6
      class="header-pretitle mb-1 tx-text-gray"
    >
      {{ $t('funnels') }}
    </h6>
    <select-funnel-dropdown
      :funnels="funnels"
      @open-modal="showModal = true"
    />
  </div>
  <new-funnel-modal
    v-if="isManager"
    :show-modal="showModal"
    @close="showModal = false"
  />
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import NewFunnelModal from "../funnel/new-funnel-modal.vue";
import SelectFunnelDropdown from "./select-funnel-dropdown.vue";

export default {
  components: {
    NewFunnelModal,
    SelectFunnelDropdown
  },

  data() {
    return {
      showModal: false
    }
  },

  computed: {
    ...mapGetters("auth", {
      isAgent: 'isAgent',
      isManager: 'isManager',
      user: 'getUser'
    }),
    ...mapGetters("crm/funnels", {
      funnels: "getFunnels",
      currentFunnel: "getCurrentFunnel",
    }),
  },

  methods: {
    ...mapActions('crm/funnels', ['setSelectedCurrentFunnel']),
  }
}
</script>
