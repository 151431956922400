<template>
  <div
    class="info-container shadow col-12 row bg-white pt-5 d-flex justify-content-center"
    :style="spy ? 'padding-bottom: 10px;' : 'padding-bottom: 34px;'"
  >
    <div
      class="col-6 p-0 d-flex flex-row"
      v-if="infoClient"
    >
      <div class="w-100 me-1">
        <h4 class="mb-4 px-4">
          {{ $t('info_client') }}
        </h4>
        <div
          class="w-100 px-4 w-100"
          style="max-height: 300px; overflow-y: hidden; overflow-y: auto;"
        >
          <div
            v-for="(value, key, index) in infoClient"
            :key="key"
          >
            <p class="text-dark m-0 fs-5">
              {{ key }}
            </p>
            <p class="text-secondary m-0 fs-6">
              {{ value }}
            </p>
            <hr
              class="my-2"
              v-if="index !== Object.keys(infoClient).length - 1"
            >
          </div>
        </div>
      </div>
      <div
        class="border"
        style="height: 100%; width: 1px;"
      />
    </div>
    <div
      class="col-6 px-4"
      :class="!infoClient && 'col-12'"
    >
      <h4 class="mb-4">
        {{ $t('info_agent') }}
      </h4>
      <div
        v-for="(value, key) in infoAgent"
        :key="key"
      >
        <div v-if="key !== 'team' && value.data">
          <p class="text-dark m-0 fs-5">
            {{ value.title }}
          </p>
          <p class="text-secondary m-0 fs-6">
            {{ value.data }}
          </p>
          <hr
            class="my-2"
          >
        </div>
        <div v-if="key === 'team' && infoAgent.team.data.length">
          <p class="text-dark mb-3">
            {{ value.title }}
          </p>
          <div
            v-for="(team, index) in value.data"
            :key="index"
            class="border p-2 px-4 mb-3"
            style="border-radius: 0.625rem;"
          >
            <span
              class="team-circle avatar rounded-circle me-2"
              :style="{ backgroundColor: team.color }"
            />
            <span> {{ team.title }}</span>
          </div>
        </div>
      </div>
      <agent-tooltip-status
        class="mt-2 py-2"
        :color="statuses[agent.status].color"
        :title="statuses[agent.status].title"
        :limit-call-time="campaign.limit_call_time"
        :agent="agent"
      />
    </div>
    <div
      v-if="spy"
      class="px-4"
    >
      <hr class="p-0 mt-4 mb-0">
    </div>
  </div>
</template>
<script>
import AgentTooltipStatus from './agent-tooltip-status.vue'
import { AgentStatus } from "@/utils";
import { mapGetters } from 'vuex';

export default {
  data() {
    return {
      statuses: {
        [AgentStatus.IDLE]: { color: "#D2DDEC", title: this.$t('idle_capitalize') },
        [AgentStatus.ON_CALL]: { color: "#008A35", title: this.$t('capitalizes.talking') },
        [AgentStatus.ACW]: { color: "#3057F2", title: this.$t('acronym.act') },
        [AgentStatus.ON_MANUAL_CALL_ACW]: { color: "#5517E6", title: this.$t('acronym.mact') },
        [AgentStatus.MANUAL_CALL_CONNECTED]: { color: "#008A35", title: this.$t('acronym.mact') },
        [AgentStatus.ON_MANUAL_CALL]: { color: "#E67C0B", title: this.$t('manual_capitalize') },
        [AgentStatus.ON_MANUAL_CALL_CONNECTED]: { color: "#008A35", title: this.$t('manual_capitalize') },
        [AgentStatus.ON_WORK_BREAK]: { color: "#FFBD00", title: this.$t('interval') },
        [AgentStatus.OFFLINE]: { color: "#DFDFDF", title: "Offline" },
      },
      infoClient: null,
      infoAgent: {
        name: {
          title: this.$t('agent_capitalize'),
          data: ''
        },
        count: {
          title: this.$t('calls_today'),
          data: 0
        },
        average: {
          title: this.$t('average'),
          data: 0
        },
        team: {
          title: this.$t('team_omni'),
          data: []
        }
      },
    }
  },

  computed: {
    ...mapGetters("manager/real-time", {
      mailings: "getMailings"
    })
  },

  mounted() {
    for (const key of Object.keys(this.infoAgent)) {
      this.infoAgent[key].data = this.agent[key]
    }

    this.infoAgent.team.data = this.agent.teams ? this.agent.teams.map(team => {
      return {
        title: team.name || this.$t('no_team'),
        color: team.color || 'red'
      }
    }) : [];

    if (this.infoAgent.team.data.length > 1) {
      this.infoAgent.team.title = this.$t('teams');
    }

    if (this.mailings[this.agent.id]) {
      this.infoClient = this.mailings[this.agent.id];
    }

    if (this.agent.mailing) {
      this.infoClient = this.agent.mailing;
    }
  },

  components: {
    AgentTooltipStatus
  },

  props: {
    agent: {
      type: Object,
      default: () => {},
    },
    campaign: {
      type: Object,
      default: null,
    },
    spy: {
      type: Boolean,
      default: true
    }
  },

  watch: {
    mailings: {
      handler(mailings) {
        this.infoClient = mailings[this.agent.id]
      },
      deep: true
    }
  }
}
</script>
<style lang="scss" scoped>
$box-width: 690px;

.info-container {
  margin-bottom: -24px;
  width: $box-width - 20px;
  border-radius: 0.625rem;

  .team-circle {
    width: 16px;
    height: 16px;
    margin-bottom: -3px;
  }

  // info client scrollbar
  ::-webkit-scrollbar {
    width: 4px;
  }
  ::-webkit-scrollbar-track {
    background: transparent;
  }
  ::-webkit-scrollbar-thumb {
    background:  #D9D9D9;
  }
}
</style>
