import moment from "moment";
import axiosOmni from "@/plugins/axios-omni.js"

export default {

sendMessage({ commit }, {fields, tempId, chat}) {
    return new Promise((resolve, reject) => {
      axiosOmni.post('/whatsapp/message/send_chat', fields)
        .then((response) => {
          let message = response.data.data;
          message.time = moment().unix()
          message.ack = null

          commit("whatsapp/chat/replaceTemporaryMessage", {
            temp_id: tempId,
            message: message
          },  { root: true });

          if (chat.lag_to_response.response_is_late || chat.queue_response_is_late.response_is_late) {
            commit("whatsapp/chat/setRemoveUnanswerMark", chat.id, { root: true });
          }

          if (chat.in_snooze) {
            commit("whatsapp/chat/removeChatFromSnooze", chat, { root: true });
          }

          if (chat.is_group) {
            commit("whatsapp/chat/updateGroupTalkOrder", chat, { root: true });
            commit("whatsapp/chat/setChatLastMessage", {
              chatType: "groups",
              message: message
            }, { root: true });
          }
          else {
            commit("whatsapp/chat/updateInProgressTalkOrder", chat, { root: true });
            commit("whatsapp/chat/setChatLastMessage", {
              chatType: "inProgress",
              message: message
            }, { root: true });
          }
          resolve();
        }).catch(() => {
          const payload = {
            tempId: tempId,
            fields
          }
        commit("whatsapp/chat/setMessageFailed", payload, { root: true });
          reject();
        });
    });
  },

sendDocument({ commit }, {formData, tempId, chat}) {
    return new Promise((resolve, reject) => {
      axiosOmni.post('/whatsapp/message/send_document', formData)
        .then((response) => {
          let message = response.data.data;
          message.time = moment().unix()
          message.ack = null

          commit("whatsapp/chat/replaceTemporaryMessage", {
            temp_id: tempId,
            message: message
          }, { root: true });

          if (chat.lag_to_response.response_is_late || chat.queue_response_is_late.response_is_late) {
            commit("whatsapp/chat/setRemoveUnanswerMark", chat.id, { root: true });
          }

          if (chat.in_snooze) {
            commit("whatsapp/chat/removeChatFromSnooze", chat, { root: true });
          }

          if (chat.is_group) {
            commit("whatsapp/chat/updateGroupTalkOrder", chat, { root: true });
            commit("whatsapp/chat/setChatLastMessage", {
              chatType: "groups",
              message: message
            }, { root: true });
          }
          else {
            commit("whatsapp/chat/updateInProgressTalkOrder", chat, { root: true });
            commit("whatsapp/chat/setChatLastMessage", {
              chatType: "inProgress",
              message: message
            }, { root: true });
          }

          resolve();
        }).catch(() => {
          const payload = {
            tempId: tempId,
            formData
          }
          commit("whatsapp/chat/setMessageFailed", payload, { root: true });
          reject();
        });
    });
  },

  sendImage({ commit }, {formData, tempId, chat}) {
    return new Promise((resolve, reject) => {
      axiosOmni.post('/whatsapp/message/send_image', formData)
        .then((response) => {
          let message = response.data.data;
          message.time = moment().unix();
          message.ack = null

          commit("whatsapp/chat/replaceTemporaryMessage", {
            temp_id: tempId,
            message: message
          }, { root: true });

          if (chat.lag_to_response.response_is_late || chat.queue_response_is_late.response_is_late) {
            commit("whatsapp/chat/setRemoveUnanswerMark", chat.id, { root: true });
          }

          if (chat.in_snooze) {
            commit("whatsapp/chat/removeChatFromSnooze", chat, { root: true });
          }

          if (chat.is_group) {
            commit("whatsapp/chat/updateGroupTalkOrder", chat, { root: true });
            commit("whatsapp/chat/setChatLastMessage", {
              chatType: "groups",
              message: message
            }, { root: true });
          }
          else {
            commit("whatsapp/chat/updateInProgressTalkOrder", chat, { root: true });
            commit("whatsapp/chat/setChatLastMessage", {
              chatType: "inProgress",
              message: message
            }, { root: true });
          }

          resolve();
        }).catch(() => {
          const payload = {
            tempId: tempId,
            fields: formData
          }
          commit("whatsapp/chat/setMessageFailed", payload, { root: true });
          reject();
        });
    });
  },

  sendVideo({ commit }, {formData, tempId, chat}) {
    return new Promise((resolve, reject) => {
      axiosOmni.post('/whatsapp/message/send_video', formData)
        .then((response) => {
          let message = response.data.data;
          message.time = moment().unix();
          message.ack = null

          commit("whatsapp/chat/replaceTemporaryMessage", {
            temp_id: tempId,
            message: message
          }, { root: true });

          if (chat.lag_to_response.response_is_late || chat.queue_response_is_late.response_is_late) {
            commit("whatsapp/chat/setRemoveUnanswerMark", chat.id, { root: true });
          }

          if (chat.in_snooze) {
            commit("whatsapp/chat/removeChatFromSnooze", chat, { root: true });
          }

          if (chat.is_group) {
            commit("whatsapp/chat/updateGroupTalkOrder", chat, { root: true });
            commit("whatsapp/chat/setChatLastMessage", {
              chatType: "groups",
              message: message
            }, { root: true });
          }
          else {
            commit("whatsapp/chat/updateInProgressTalkOrder", chat, { root: true });
            commit("whatsapp/chat/setChatLastMessage", {
              chatType: "inProgress",
              message: message
            }, { root: true });
          }

          resolve();
        }).catch(() => {
          const payload = {
            tempId: tempId,
            formData: formData
          }
          commit("whatsapp/chat/setMessageFailed", payload, { root: true });
          reject();
        });
    });
  },

  sendVoice({ commit }, {formData, tempId, chat}) {
    return new Promise((resolve, reject) => {
      axiosOmni.post('/whatsapp/message/send_voice', formData)
        .then((response) => {
          let message = response.data.data;
          message.time = moment().unix()
          message.ack = null

          commit("whatsapp/chat/replaceTemporaryMessage", {
            temp_id: tempId,
            message: message
          }, { root: true });

          if (chat.lag_to_response.response_is_late || chat.queue_response_is_late.response_is_late) {
            commit("whatsapp/chat/setRemoveUnanswerMark", chat.id, { root: true });
          }

          if (chat.in_snooze) {
            commit("whatsapp/chat/removeChatFromSnooze", chat, { root: true });
          }

          if (chat.is_group) {
            commit("whatsapp/chat/updateGroupTalkOrder", chat, { root: true });
            commit("whatsapp/chat/setChatLastMessage", {
              chatType: "groups",
              message: message
            }, { root: true });
          }
          else {
            commit("whatsapp/chat/updateInProgressTalkOrder", chat, { root: true });
            commit("whatsapp/chat/setChatLastMessage", {
              chatType: "inProgress",
              message: message
            }, { root: true });
          }

          resolve();
        }).catch(() => {
          const payload = {
            tempId: tempId,
            formData: formData
          }
          commit("whatsapp/chat/setMessageFailed", payload, { root: true });
          reject();
        });
    });
  },

  sendAudio({ commit }, {formData, tempId, chat}) {
    return new Promise((resolve, reject) => {
      axiosOmni.post('/whatsapp/message/send_audio', formData)
        .then((response) => {
          let message = response.data.data;
          message.time = moment().unix()
          message.ack = null

          commit("whatsapp/chat/replaceTemporaryMessage", {
            temp_id: tempId,
            message: message
          }, { root: true });

          if (chat.lag_to_response.response_is_late || chat.queue_response_is_late.response_is_late) {
            commit("whatsapp/chat/setRemoveUnanswerMark", chat.id, { root: true });
          }

          if (chat.in_snooze) {
            commit("whatsapp/chat/removeChatFromSnooze", chat, { root: true });
          }

          if (chat.is_group) {
            commit("whatsapp/chat/updateGroupTalkOrder", chat, { root: true });
            commit("whatsapp/chat/setChatLastMessage", {
              chatType: "groups",
              message: message
            }, { root: true });
          }
          else {
            commit("whatsapp/chat/updateInProgressTalkOrder", chat, { root: true });
            commit("whatsapp/chat/setChatLastMessage", {
              chatType: "inProgress",
              message: message
            }, { root: true });
          }

          resolve();
        }).catch(() => {
          const payload = {
            tempId: tempId,
            formData: formData
          }
          commit("whatsapp/chat/setMessageFailed", payload, { root: true });
          reject();
        });
    });
  },

  sendInternal({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axiosOmni.post('/whatsapp/message/send_internal_chat', payload.fields)
        .then((response) => {
          let message = response.data.data;
          message.time = moment().unix()
          message.ack = null
          commit("whatsapp/chat/replaceTemporaryMessage", {
            temp_id: payload.tempId,
            message: message
          }, { root: true });
          resolve();
        }).catch(() => {
          commit('system/setErrorMessage', "errors_message.send_message", { root: true })
          commit("setUnconfirmedMessageFailed", { id: payload.tempId, failed: true }, { root: true });
          reject();
        });
    })
  },

  sendForward({ commit }, {data, chat, message, tempId, formData}) {
    commit('startLoading', 'sendForward')
    return new Promise((resolve, reject) => {
      axiosOmni.post('/whatsapp/message/forward_message', data)
        .then((response) => {
          // FIXME: back return the message on the endpoint
          // let message = response.data.data;
          // message.time = moment().unix()
          // message.ack = null
          //
          // commit("whatsapp/chat/replaceTemporaryMessage", {
            //   temp_id: tempId,
            //   message: message
            // }, { root: true });

          message.chat_id = chat.id
            message.id = tempId

          if (chat.is_group) {
            commit("whatsapp/chat/updateGroupTalkOrder", chat, { root: true });
            commit("whatsapp/chat/setChatLastMessage", {
              chatType: "groups",
              message: message
            }, { root: true });
          }
          else {
            commit("whatsapp/chat/updateInProgressTalkOrder", chat, { root: true });
            commit("whatsapp/chat/setChatLastMessage", {
              chatType: "inProgress",
              message: message
            }, { root: true });
            }

          commit('system/setSuccessMessage', response.data.detail, { root: true })
          resolve();
        })
        .catch( () => {
            const payload = {
                tempId: tempId,
                formData: formData
            }
            commit("whatsapp/chat/setMessageFailed", payload, { root: true });
            reject();
        })
        .finally(() => {
          commit("stopLoading", "sendForward");
        })
        ;
    });
  },

  deleteWhatsappMessage({ commit }, { payload, chat_id}) {
    return new Promise((resolve, reject) => {
      axiosOmni.delete(`/whatsapp/message/delete?chat_id=${payload.chat_id}&message_id=${payload.internal_id}`)
        .then((response) => {
          commit('whatsapp/chat/markMessageAsDeleted', {data: response.data.data, chat_id}, {root: true})
          commit('system/setSuccessMessage', "success_message.delete_message", { root: true })
          resolve();
        }).catch(() => {
          commit('system/setErrorMessage', "errors_message.delete_message", { root: true })
          reject();
        });
    });
  },

  openIaImprove({ commit }, payload) {
    commit('startLoading', 'openIaImprove')
    return new Promise((resolve, reject) => {
      axiosOmni.post('/whatsapp/message/improve', payload)
        .then((response) => {
          commit('setImprovedMessage', response.data.message)
          resolve();
        }).catch((response) => {
          commit('system/setErrorMessage', response.response.data, { root: true })
          reject();
        }).finally(() => {
          commit("stopLoading", "openIaImprove")
        })
    });
  },

  openIaSuggest({ commit }, payload) {
    commit('startLoading', 'openIaSuggest')
    return new Promise((resolve, reject) => {
      axiosOmni.post('/whatsapp/message/suggest', payload)
        .then((response) => {
          commit('setSuggestedMessage', response.data.message)
          resolve();
        }).catch((response) => {
          commit('system/setErrorMessage', response.response.data, { root: true })
          reject();
        }).finally(() => {
          commit("stopLoading", "openIaSuggest")
        })
    });
  },
}
