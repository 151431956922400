<template>
  <div
    id="general-tab-pane"
    aria-labelledby="general-tab"
    class="tab-pane fade"
    role="tabpanel"
    tabindex="0"
  >
    <div class="card mt-4">
      <div
        v-if="currentFunnel"
        class="card-body"
      >
        <div class="row">
          <div class="col-6">
            <h4>{{ $t('name') }}</h4>
            <input
              v-model="fields.name"
              :placeholder="currentFunnel.name"
              class="form-control"
              type="text"
              @input="$emit('updateFields', {key: 'name', value: fields.name})"
            >
          </div>
          <div class="col-6">
            <h4>{{ $t('qualification_group') }}</h4>

            <single-select
              id="id"
              :current-option="fields.qualification_list?.name"
              :initial-text="$t('select')"
              :list-array="list"
              label="name"
              @selected-option="selectQualification"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import SingleSelect from "@components/single-select.vue";

export default {
  name: "GeneralTab",
  components: {SingleSelect},

  emits: ["updateFields"],

  data() {
    return {
      fields: {
        name: "",
        qualification_list: null,
      }
    }
  },

  mounted() {
    this.fields.name = this.currentFunnel.name
  },

  computed: {
    ...mapGetters("crm/funnels", {
      currentFunnel: "getCurrentFunnel",
    }),

    ...mapGetters("manager/qualification", {
      list: "getList",
    }),
  },

  methods: {
    selectQualification(qualification) {
      this.fields.qualification_list = qualification
      this.$emit("updateFields", {key: 'qualification_list_id', value: qualification.id})
    }
  },

  watch: {
    list: {
      handler(value) {
        this.fields.qualification_list = value.find((qualification) => qualification.id === this.currentFunnel.qualification_list_id)
      },
      deep: true
    }
  }
}
</script>

<style lang="scss" scoped>
.card-body {
  height: auto !important;
}

.card-body::-webkit-scrollbar {
  display: none !important;
}

.card-body {
  -ms-overflow-style: none !important;
  scrollbar-width: none !important;
}
</style>

