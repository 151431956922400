export default {
  loadingFlags: {
    findQuickMessages: false
  },
  errors: {},
  quickMessages: [],
  //params
  filters: {},
  include: [],
  pagination: {
    per_page: -1,
  },
}
