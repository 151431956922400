<template>
  <div
    class="skeleton-loading"
  >
    <div class="row m-0 p-2 list">
      <div class="col-lg-12">
        <div class="list-group list-group-flush transparent">
          <div
            v-for="i in 25"
            :key="i"
            class="list-group-item py-0"
          >
            <div class="row align-items-center py-2">
              <div class="col-auto">
                <div class="avatar bg-loading rounded-circle mt-1" />
              </div>
              <div class="col ms-n2">
                <div class="bg-loading text" />
                <div class="bg-loading text my-2" />
                <div class="bg-loading text" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {

}
</script>


<style scoped lang="scss">
.skeleton-loading {
  .transparent {
    mask-image: -webkit-gradient(
        linear,
        left top,
        left bottom,
        from(rgba(0, 0, 0, 1)),
        to(rgba(0, 0, 0, 0))
    ) !important;
  }

  .list {
    .list-group-item {
      border-bottom: none;

      .text {
        height: $whatsapp-border-radius;
        &:nth-child(1) {
          width: 55px;
        }
        &:nth-child(2) {
          width: 155px;
        }
        &:nth-child(3) {
          width: 80px;
        }
      }
    }
  }
}
</style>
