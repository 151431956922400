export default {
  loadingFlags: false,
  errors: {},
  list: [],
  register: null,
  filters: {},
  include: ['redirect'],
  pagination: {
    per_page: 12,
    current_page: 1
  },
  dataSelect: []
}
