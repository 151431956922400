<template>
  <div
    class="nav-item dropup"
    v-if="!inImpersonate"
  >
    <div class="d-flex justify-content-start mx-3">
      <div class="changelog col" />
      <div
        class="dropup col-11"
        v-if="user"
      >
        <a
          class="dropdown-toggle nav-link"
          id="userProfileDropDown"
          role="button"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          <span class="w-100 text-truncate">
            {{ user.name }}
          </span>
        </a>
        <div
          class="dropdown-menu dropdown-menu-start col-12 shadow dropdown-manager-sidebar"
          style="left: 240px; bottom: 10px; width: 220px"
          aria-labelledby="userProfileDropDown"
        >
          <div class="ps-3 d-flex justify-content-center my-2">
            <iframe
              src="https://status.3c.plus/badge"
              frameborder="0"
              class="iframe"
              style="max-height: 30px; max-width: 100%"
            />
          </div>

          <div class="dropdown-item px-4 my-2">
            {{ $t("your_ip") }}: {{ userIp }}
          </div>

          <div
            v-if="user && user.role.name === 'manager'"
            class="dropdown-divider my-1 mx-3"
          />
          <a
            v-if="user && user.role.name === 'manager'"
            href="#"
            class="btn dropdown-item px-4 my-2"
            @click="this.$router.push({ path: `/user/settings` })"
          >
            <i class="fa-regular fa-user" />
            {{ $t("my_account_capitalized") }}
          </a>
          <div class="dropdown-divider my-1 mx-3" />
          <a
            class="btn dropdown-item py-1 px-4 mt-2"
            @click="_logout()"
            href="#"
          ><span class="fe fe-log-out" /> {{ $t("logout") }}</a>
        </div>
      </div>
    </div>
  </div>
  <div
    class="nav-link"
    v-else
  >
    <div class="w-100 text-truncate">
      {{ user.name }}
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  emits: ["myAccountManager"],

  computed: {
    ...mapGetters("auth", {
      user: "getUser",
      inImpersonate: "inImpersonate"
    }),

    ...mapGetters("system", {
      userIp: "getUserIp"
    })
  },

  methods: {
    ...mapActions("auth", ["logout"]),
    _logout() {
      this.logout().then(() => {
        this.$router.push({
          name: "login"
        });
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.iframe {
  -ms-overflow-style: none;
  ::-webkit-scrollbar {
    display: none;
  }
}
</style>
