<template>
  <div class="main-content pb-6">
    <div class="container-fluid">
      <div class="row justify-content-center">
        <div
          v-if="register"
          class="col-12 col-lg-10 col-xl-8"
        >
          <header-component
            :title="register.name"
            :pretitle="$t('receptive_IVR')"
            redirect-link="/manager/receptive-ivrs"
          />

          <flash-message />

          <div class="row">
            <div class="col-lg-12">
              <div class="form-group 0">
                <label class="form-label required">{{ $t('capitalizes.name') }}</label>
                <input
                  v-model="fields.name"
                  :class="{
                    'form-control': true,
                    'is-invalid': errors.name,
                  }"
                  :placeholder="$t('capitalizes.name')"
                  type="email"
                >

                <div class="invalid-feedback">
                  {{ errors.name && errors.name[0] }}
                </div>
              </div>
            </div>

            <div class="col-lg-12">
              <div class="form-group">
                <label
                  class="form-label required"
                  for="name"
                >{{ $t('warning_audio') }}</label>
                <span class="form-text">{{ $t('upload_a_mp3_file') }}</span>

                <div class="card mb-0">
                  <div class="card-body">
                    <div class="text-center my-1 text-muted">
                      <small> {{ register.original_audio_name }}</small>

                      <audio
                        class="w-100 mb-4"
                        controls
                      >
                        <source
                          :src="getFullAudioPath()"
                          type="audio/mpeg"
                        >
                      </audio>
                    </div>

                    <file-upload
                      filter="audio/mp3,audio/mpeg"
                      icon="fe fe-headphones"
                      @select-file="selectFile"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div class="col-lg-12">
              <div class="form-group 0">
                <div class="card mb-0">
                  <div class="card-header">
                    <div class="row align-items-center">
                      <div class="col">
                        <h4 class="card-header-title">
                          {{ $t('redirect_keys') }}
                        </h4>
                      </div>

                      <div class="col-auto">
                        <button
                          :disabled="fields.keys.length >= 12"
                          class="btn-primary btn btn-sm px-5"
                          @click="
                            fields.keys.push({
                              id: '',
                              key: '',
                              type: 'ReceptiveQueue',
                            })
                          "
                        >
                          {{ $t('add_key') }}
                        </button>
                      </div>
                    </div>
                  </div>
                  <div class="card-body">
                    <div
                      v-if="fields.keys.length"
                      class="row"
                    >
                      <div class="col-lg-6">
                        <label class="form-label">{{ $t('key') }}</label>
                      </div>
                      <div class="col-lg-5 4">
                        <label class="form-label">{{ $t('capitalizes.redirection') }}</label>
                      </div>
                    </div>
                    <div
                      v-else
                      class="row"
                    >
                      <span class="col-lg-12 text-muted text-center">
                        {{ $t('no_key_added') }}
                      </span>
                    </div>
                    <div
                      v-for="(item, i) of fields.keys"
                      :key="i"
                      class="row my-2"
                    >
                      <div class="col-lg-6">
                        <div
                          :class="{
                            'btn-group group-keys mr-2 w-100': true,
                            'is-invalid': errors.hasOwnProperty(
                              'keys.' + i + '.key'
                            ),
                          }"
                          aria-label="First group"
                          role="group"
                        >
                          <button
                            v-for="(key, j) of keys"
                            :key="j"
                            :class="{
                              'btn px-1': true,
                              'btn-outline-secondary': item.key != j,
                              'btn-primary': item.key == j,
                            }"
                            :disabled="!key.avaliable && item.key != j"
                            type="button"
                            @click="selectKey(item, j)"
                          >
                            {{ j }}
                          </button>
                        </div>

                        <div class="invalid-feedback">
                          {{
                            errors.hasOwnProperty("keys." + i + ".key") &&
                              errors["keys." + i + ".key"][0]
                          }}
                        </div>
                      </div>
                      <div class="col-lg-5">
                        <select
                          v-model="item.id"
                          :class="{
                            'form-select w-100': true,
                            'is-invalid': errors.hasOwnProperty(
                              'keys.' + i + '.id'
                            ),
                          }"
                          :disabled="receptiveQueuesLoading"
                        >
                          <option />
                          <option
                            v-for="queue of receptiveQueues"
                            :key="queue.id"
                            :value="queue.id"
                          >
                            {{ queue.name.split(" - ")[1] }}
                          </option>
                        </select>

                        <div class="invalid-feedback">
                          {{
                            errors.hasOwnProperty("keys." + i + ".id") &&
                              errors["keys." + i + ".id"][0]
                          }}
                        </div>
                      </div>
                      <div class="col-lg-1 0">
                        <a
                          class="btn text-primary w-100 px-1"
                          @click="removeKey(i)"
                        >
                          <span class="fe fe-trash" />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="invalid-feedback" />
              </div>

              <div class="col-lg-12">
                <div class="form-group 0">
                  <label class="form-label required">{{ $t('redirect_if_no_key') }}
                  </label>

                  <select
                    v-model="fields.redirect_id"
                    :class="{
                      'form-select w-100': true,
                      'is-invalid': errors.redirect_id,
                    }"
                    :disabled="receptiveQueuesLoading"
                  >
                    <option />
                    <option
                      v-for="queen of receptiveQueues"
                      :key="queen.id"
                      :value="queen.id"
                    >
                      {{ queen.name.split(" - ")[1] }}
                    </option>
                  </select>

                  <div class="invalid-feedback">
                    {{ errors.redirect_id && errors.redirect_id[0] }}
                  </div>
                </div>
              </div>

              <div class="col-lg-6">
                <label class="form-label required">{{ $t('press_key_waiting_time') }}</label>
                <div class="form-group 0">
                  <span class="form-text">{{ $t("max_10_sec") }}.</span>
                  <input-text-range
                    v-model="fields.timeout"

                    :class="{
                      'is-invalid': errors.timeout,
                    }"
                  />
                  <div class="invalid-feedback">
                    {{ errors.timeout && errors.timeout[0] }}
                  </div>
                </div>
              </div>

              <div class="col-lg-12">
                <app-button
                  :loading="loading"
                  class="w-100"
                  color="btn-primary"
                  @click="submit()"
                >
                  {{ $t('send_capitalize') }}
                </app-button>
              </div>

              <div class="col-lg-12 mt-3">
                <app-button
                  :loading="loading"
                  class="w-100"
                  color="text-danger"
                  @click="remove_()"
                >
                  <span class="fe fe-trash" /> {{ $t('exclude') }}
                </app-button>
              </div>
            </div>
          </div>
        </div>
        <main-loading v-else-if="loading" />
      </div>
    </div>
  </div>
</template>


<script>
import mainLoading from "@/components/main-loading.vue";

import {mapActions, mapGetters, mapMutations} from "vuex";
import fileUpload from "@components/file-uploader";

import flashMessage from "@/components/flash-message";

import InputTextRange from "@components/input-text-range";
import AppButton from "@components/app-button";
import HeaderComponent from "@/components/header-component.vue";

export default {
  /**
   *
   */
  data() {
    return {
      keys: {
        1: {avaliable: true},
        2: {avaliable: true},
        3: {avaliable: true},
        4: {avaliable: true},
        5: {avaliable: true},
        6: {avaliable: true},
        7: {avaliable: true},
        8: {avaliable: true},
        9: {avaliable: true},
        0: {avaliable: true},
        "*": {avaliable: true},
        "#": {avaliable: true},
      },

      fields: {
        name: "",
        timeout: 0,
        redirect_id: "",
        keys: [],
      },

      receptiveQueues: []
    };
  },

  /**
   *
   */
  mounted() {
    this.setIncludes(["queues"]);
    this.findFilters();
    this.setRegister(null);
    this.findById({id: this.$route.params.id});
  },

  /**
   *
   */
  computed: {
    /**
     * Getters
     */
    ...mapGetters("manager/receptive_ivrs", {
      register: "getRegister",
      errors: "getErrors",
      loading: "isLoading",
    }),

    ...mapGetters("manager/filters", {
      filters: "getFilters",
    }),

    /**
     *
     */
    ...mapGetters("manager/receptive_queues", {
      receptiveQueuesLoading: "isLoading",
    }),

    /**
     *
     */
    ...mapGetters("auth", {
      user: "getUser",
    }),
  },

  /**
   *
   */
  watch: {
    /**
     *
     */
    register(register) {
      if (register) {
        this.fields.name = register.name;
        this.fields.timeout = register.timeout;
        this.fields.redirect_id = register.redirect_id;

        for (let item of register.keys) {
          this.fields.keys.push({
            id: item.redirect.id,
            key: item.key,
            type: "ReceptiveQueue",
          });

          this.keys[item.key].avaliable = false;
        }
      }
    },

    filters: {
      handler(data) {
        if (data.queues)
          this.receptiveQueues = data.queues;
      },
      deep: true,
    }
  },
  /**
   *
   */
  components: {
    mainLoading,
    fileUpload,
    InputTextRange,
    AppButton,
    flashMessage,
    HeaderComponent
  },

  /**
   *
   */
  methods: {
    /**
     *Actions
     */
    ...mapActions("manager/receptive_ivrs", ["update", "remove", "findById"]),
    ...mapActions("manager/filters", ["findFilters"]),

    /**
     * Mutations
     */
    ...mapMutations("manager/receptive_ivrs", ["setRegister"]),
    ...mapMutations("manager/filters", ["setIncludes"]),
    ...mapMutations("system", ["setFlashMessage"]),

    /**
     *
     */
    selectKey(item, key) {
      if (this.keys[item.key]) this.keys[item.key].avaliable = true;
      this.keys[key].avaliable = false;
      item.key = key;
    },

    /**
     *
     */
    selectFile(file) {
      this.fields.audio = file;
    },

    /**
     *
     */

    getFullAudioPath() {
      return (
        process.env.VUE_APP_BA12AS.replace("/api/v1", "") +
        this.register.audio_path +
        "&api_token=" +
        this.user.api_token
      );
    },

    /**
     *
     */
    removeKey(i) {
      if (this.fields.keys[i].key)
        this.keys[this.fields.keys[i].key].avaliable = true;
      this.fields.keys.splice(i, 1);
    },

    /**
     *
     */
    createFormData() {
      let formData = new FormData();

      if (this.fields.audio) formData.append("audio", this.fields.audio);
      formData.append("_method", "PUT");

      formData.append("name", this.fields.name);
      formData.append("timeout", this.fields.timeout);
      formData.append("redirect_id", this.fields.redirect_id);
      formData.append("redirect_type", "ReceptiveQueue");

      for (let i = 0; i < this.fields.keys.length; i++) {
        formData.append("keys[" + i + "][id]", this.fields.keys[i].id);
        formData.append("keys[" + i + "][key]", this.fields.keys[i].key);
        formData.append("keys[" + i + "][type]", this.fields.keys[i].type);
      }
      return formData;
    },

    /**
     *
     */
    submit() {
      let formData = this.createFormData();
      this.update({formData, id: this.register.id})
      .then((data) => {
        this.$router
        .push({
          path: `/manager/receptive-ivrs`,
        })
        .then(() => {
          this.setFlashMessage({
            message: data.message,
            type: "success",
          });
        });
      })
      .catch((data) => {
        this.setFlashMessage({
          message: data.message,
          type: "danger",
        });
      });
    },

    /**
     * delete
     */
    remove_() {
      if (
        window.confirm(
          this.$t('delete_ivr_confirm')
        )
      ) {
        this.remove({
          id: this.register.id,
        })
        .then((data) => {
          this.$router
          .push({
            path: `/manager/receptive-ivrs`,
          })
          .then(() => {
            this.setFlashMessage({
              message: data.message,
              type: "success",
            });
          });
        })
        .catch((data) => {
          this.errorMessage = data.message;
          this.setFlashMessage({
            message: data.message,
            type: "danger",
          });
        });
      }
    },
  },
};
</script>


<style lang="scss">
.btn-group.group-keys {
  :disabled {
    opacity: 0.4;
  }
}
</style>
