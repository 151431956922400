if (process.env.VUE_APP_CD69ED == "production" && location.protocol !== "https:") location.protocol = "https:";

import { createApp, h, provide } from "vue";
import "./plugins/facebook-sdk-config";
import App from "./App.vue";
import store from "./store";
import router from "./router";
import { vMaska } from "maska";
import { i18n } from "./i18n";
import { DefaultApolloClient } from "@vue/apollo-composable";
import apolloClient from "@/plugins/apolloClient";
import VueSlider from "vue-slider-component";
import "vue-slider-component/theme/default.css";
import "vue-multiselect/dist/vue-multiselect.css";
import "bootstrap";
import "flatpickr/dist/flatpickr.css";
import "@fancyapps/ui/dist/fancybox.css";
import FloatingVue from "floating-vue";
import "floating-vue/dist/style.css";
import moment from "moment";

/**
 * plugins
 */
import "@/plugins/dashkit-2.0/chart.js";
import "@/plugins/axios.js";

const isDevMode = process.env.VUE_APP_CD69ED === "dev";
const debugKey = "debug-front-end";

if (isDevMode) {
  console.log("Running in development mode, setting debug-front-end to true");
  if (!localStorage.getItem(debugKey)) {
    localStorage.setItem(debugKey, true);
  }
}

//prevent jssip connection error if update page
window.onbeforeunload = () => {
  store.commit("system/setSocketIOStatus", "disconnected");
  store.commit("system/setJsSipStatus", "disconnected");
};

const app = createApp({
  setup() {
    provide(DefaultApolloClient, apolloClient);
  },
  render: () => h(App)
});

app.component("VueSlider", VueSlider);
app.use(i18n);
app.use(store);
app.use(router);
app.use(FloatingVue);
app.directive("maska", vMaska);
app.mount("#app");
moment.locale("pt-br");
