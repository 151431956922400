<template>
  <div
    v-if="multiSelect && selectedItems.length"
    style="margin-bottom: 0.5rem; padding-bottom: 1rem; gap: 0.5rem"
    class="border-bottom d-flex flex-wrap justify-content-start"
  >
    <span
      v-for="(item, iIndex) in selectedItems"
      :key="`item-${iIndex}`"
      class="tag"
    >
      {{ item[label] }}
      <button
        @click="selectItem(item[returnLabel])"
        class="btn btn-remove"
      >
        <i class="fa-regular fa-xmark" />
      </button>
    </span>
  </div>
  <div
    v-if="!hideTabs"
    style="gap: 0.5rem"
    class="d-flex flex-wrap justify-content-between"
  >
    <div
      v-for="(role, rIndex) in rolesList"
      :key="`role-${rIndex}`"
      class="col"
    >
      <input
        type="radio"
        class="btn-check"
        :id="`role-${role.value}-${id}`"
        v-model="selectedRole"
        :value="role.value"
      >
      <label
        style="font-size: 0.75rem; line-height: 1.33; min-width: 90px"
        class="w-100 btn border-0 d-inline-flex justify-content-center align-items-center"
        :class="{
          'btn-primary': selectedRole === role.value,
          'btn-gray-blue': selectedRole !== role.value
        }"
        :for="`role-${role.value}-${id}`"
      >
        {{ role.label }}
      </label>
    </div>
  </div>

  <div :style="hideTabs ? 'margin: 0 auto 0.5rem auto' : 'margin: 0.5rem auto'">
    <search-input
      @search="search"
      @out="out"
    />
  </div>

  <template v-if="loading">
    <card-loading
      class="mb-0"
      height="36.77"
    />
  </template>

  <template v-else>
    <div
      v-for="user in users"
      :key="user[returnLabel]"
      :class="{'disabled': disabled(user[returnLabel])}"
      class="dropdown-item d-flex align-items-center cursor-pointer tx-text-200 rounded option"
      @click="selectItem(user[returnLabel])"
      @mouseleave="hovered.user = null"
      @mouseover="hovered.user = user[returnLabel]"
    >
      <span class="col-3 text-truncate m-0 tx-text-gray">
        {{ user.extension?.data?.extension_number || user?.extension?.extension_number }}
      </span>
      <span class="col text-truncate">
        {{ user.name }}
      </span>
      <i
        v-show="hovered.user === user[returnLabel] && !disabled(user[returnLabel])"
        class="col-1 fa-regular fa-arrow-right tx-text-gray"
      />
    </div>
  </template>
  <div
    v-if="multiSelect"
    class="d-block"
  >
    <button
      style="font-size: 0.75rem; line-height: 1.2"

      @click="selectAllItems"
      class="btn btn-gray-blue w-100 mt-3"
    >
      {{
        isCurrentItemsSelected ? $t("deselect_all") : $t("select_all")
      }}
    </button>
    <button
      style="font-size: 0.75rem; line-height: 1.2"
      class="btn btn-link w-100"
      @click="clearSelectedItems"
    >
      {{ $t("clear") }}
    </button>
  </div>
</template>
<script>
import SearchInput from "@components/search-input.vue";
import CardLoading from "@components/card-loading.vue";
import _ from "lodash"

export default {
  name: "UsersTab",
  components: {CardLoading, SearchInput},
  props: {
    id: {
      type: String,
      default: 'id'
    },
    users: {
      type: Array,
      required: true,
    },
    label: {
      type: String,
      default: 'id'
    },
    returnLabel: {
      type: String,
      default: 'id'
    },
    currentUserId: {
      type: [Number, String],
      required: true,
    },
    currentOwnerId: {
      type: [Number, String],
      default: null
    },

    multiSelect: {
      type: Boolean,
      default: false,
    },

    modelValue: {
      type: Array,
      default: () => []
    },

    loading: {
      type: Boolean,
      default: false
    },

    hideTabs: {
      type: Boolean,
      default: false
    }
  },
  emits: [
    'search',
    'out',
    'role',
    'update:modelValue',
    'select-item'
  ],


  data() {
    return {
      hovered: {
        user: null,
      },

      once: true,

      usersHistory: [],

      selectedItems: [],
      selectedRole: 'agent',
      rolesList: [
        {
          label: this.$tc("capitalizes.agent", 1),
          value: 'agent'
        },
        {
          label: this.$tc("capitalizes.supervisor", 1),
          value: 'supervisor'
        },
        {
          label: this.$tc("capitalizes.manager", 1),
          value: 'manager'
        }
      ],

      searchQuery: null,
    }
  },

  methods: {
    disabled(userId) {
      return userId === this.currentOwnerId;
    },

    selectItem(userId) {
      if (!this.disabled(userId)) {
        const item = this.usersHistory?.find((user) => user[this.returnLabel] === userId);
        const duplicated = this.selectedItems.findIndex((listedItem) => listedItem[this.returnLabel] === item[this.returnLabel] )
        if (duplicated >= 0) {
          return this.selectedItems.splice(duplicated, 1)
        }

        return this.selectedItems.push(item)
      }
      return false;
    },

    selectAllItems() {
      if (this.selectedItems.length < this.users.length) {
        this.selectedItems = [];
      }

      this.users.forEach((user) => {
        this.selectItem(user.id)
      })
    },

    search(searchQuery) {
      this.$emit('search', searchQuery);
    },

    out() {
      this.$emit('out');
    },

    clearSelectedItems() {
      if (this.selectedItems.length) {
        this.selectedItems = [];
      }
      return false;
    },

    isSelected(item) {
      return this.selectedItems.some((selected) => selected[this.returnLabel] === item[this.returnLabel]);
    }
  },


  computed: {
    isCurrentItemsSelected() {
      return this.users?.every((user) => this.isSelected(user))
    }
  },


  watch: {
    modelValue: {
      handler(items) {
        if(!_.isEqual(items, this.selectedItems) && this.once) {
          this.selectedItems = items;
          this.once = false;
        }
      },
      immediate: true,
      deep: true,
      flush: 'post'
    },
    users: {
      handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          if (!this.usersHistory.length) {
            this.usersHistory = this.users;
            return true;
          }

          const found = newVal?.some((user) => this.usersHistory.some((uHistory) => uHistory[this.returnLabel] === user[this.returnLabel]))

          if (!found) {
            this.usersHistory = this.usersHistory.concat(newVal)
          }
        }
      },
      immediate: true,
    },


    selectedRole(newRole, oldRole) {
      if (newRole !== oldRole) {
        this.$emit('role', newRole)
      }
    },

    selectedItems: {
      handler(items) {
        if (this.multiSelect) {
          return this.$emit('update:modelValue', items.length ? items : []);
        }

        return this.$emit('select-item', items[0][this.returnLabel] ? items[0][this.returnLabel] : false);
      },
      deep: true
    }
  }
}
</script>
<style scoped lang="scss">

.tag {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  line-height: 1;
  padding: 0.25rem 0.5rem;
  border-radius: 6px;
  font-size: 0.875rem;
  color: $color-blue-500;
  background-color: #E1E9F4;
  border: 0;
  gap: 0.25rem;
}

.btn-remove {
  color: $color-blue-500;
  font-size: 0.75rem;
  line-height: 1;
  padding: 2px;
  min-width: 16px;
  min-height: 16px;
  border: 0;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.option {
  padding: 0.5rem;
  line-height: 1.3;

  &:not(.disabled) {
    &:hover,
    &:focus,
    &:focus-visible {
      background-color: $color-gray-blue-300;
    }
  }

  &.disabled {
    opacity: 0.6 !important;
  }
}

.btn-primary,
.btn-gray-blue {
  box-shadow: none !important;
}

.btn-check:checked + .btn-primary {
  background-color: #3057f2;
}

.btn-gray-blue {
  background-color: #E1E9F4;
  color: #677C92;

  &:hover {
    background-color: #D2DDEC;
  }
}
</style>