import axiosOmni from "@/plugins/axios-omni.js"

export default {
  /**
   * Retrieve all quick messages from
   * the company
   *
   * @param {Object} state
   * @param {Object} payload
   * @returns {Promise}
   */
  findQuickMessages({ commit, getters }, payload) {
    commit('startLoading', 'findQuickMessages');
    return new Promise((resolve, reject) => {
      axiosOmni
        .get(`/group-channel/${payload.group_channel_id}/quick-messages`, { params: getters.getParams })
        .then(response => {
          commit("setQuickMessages", response.data.data);
          resolve();
        })
        .catch((error) => {
          commit('system/setErrorMessage', error.response.data, { root: true });
          reject();
        })
        .finally(() => {
          commit('stopLoading', 'findQuickMessages');
        });
    });
  },
}
