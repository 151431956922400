<template>
  <div class="main-content">
    <div class="container-fluid">
      <div
        class="row justify-content-center"
        v-if="register"
      >
        <div class="header">
          <div class="header-body">
            <div class="row align-items-center">
              <button
                class="btn btn-rounded-circle btn-white float-start ms-3"
                @click="$router.push('/admin/companies')"
              >
                <span class="fe fe-arrow-left" />
              </button>
              <div class="col">
                <div class="d-flex">
                  <div>
                    <h6 class="header-pretitle">
                      EMPRESAS
                    </h6>
                    <h1 class="header-title">
                      {{ fields.name }}
                    </h1>
                  </div>

                  <div class="col d-flex align-items-end mx-3 mb-2">
                    <img
                      width="34"
                      height="34"
                      src="@assets/img/icons/pro-mode.svg"
                      alt="Ícone modo pro"
                      v-if="fields.pro_mode"
                    >
                    <div
                      class="mx-3 mb-2"
                      v-for="item in fields.tags"
                      :key="item.id"
                    >
                      <span
                        class="badge mx-1"
                        :style="`background-color:${item.color}`"
                      >{{ item.name }}</span>
                    </div>
                    <button
                      class="btn btn-outline-primary align-items-end border-0 btn-sm mx-1 mb-2 py-0 my-0"
                      @click="this.modals.modalTags.show()"
                    >
                      +
                    </button>
                  </div>
                </div>
              </div>
              <div
                class="col-auto"
                v-if="user && [0, 2, 3].includes(Number(user.settings.access))"
              >
                <button
                  @click="impersonate(fields.id)"
                  class="btn btn-primary float-end px-6 mx-3"
                >
                  Acessar empresa
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="main-content">
          <div class="row align-items-stretch">
            <div
              class="col-12 col-lg-6 col-xl-1 cursor-pointer"
              @click="$refs.pixModal.openPixModal()"
            >
              <div class="card card-fill p-3 d-flex align-items-center justify-content-center">
                <img
                  src="~@assets/img/icons/pix.svg"
                  height="32"
                >
              </div>
            </div>
            <div class="col-12 col-lg-6 col-xl-2">
              <div class="card">
                <div class="card-body p-3 my-2">
                  <div class="row align-items-start gx-0">
                    <div
                      @click="this.modals.modalCredits.toggle()"
                      class="col d-flex justify-content-between align-items-center cursor-pointer"
                    >
                      <div>
                        <small class="text-muted d-block">
                          Saldo
                        </small>
                        <span
                          v-if="this.fields.balance"
                          class="h3"
                        >{{
                          fields.balance.toLocaleString("pt-br", {
                            style: "currency",
                            currency: "BRL",
                          })
                        }}</span>
                      </div>
                      <span class="fe fe-edit-2 text-muted mx-2" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 col-lg-6 col-xl-2">
              <div class="card card-fill">
                <div class="card-body p-3 my-2">
                  <div class="row align-items-start gx-0">
                    <div class="col">
                      <small class="text-muted d-block">
                        PLANO
                      </small>
                      <span
                        v-if="fields.plan == 1"
                        class="h3"
                      >Ilimitado</span>
                      <span
                        v-else-if="fields.plan == 2"
                        class="h3"
                      >Minutagem</span>
                      <span
                        v-else
                        class="h3"
                      >Telefonia externa</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col">
              <div class="card">
                <div class="card-body p-3">
                  <div class="row align-center gx-0">
                    <div class="col text-center">
                      <div class="d-flex justify-content-between mb-1">
                        <small class="">
                          {{ $t("agents_capitalize") }}
                        </small>
                        <small class="text-muted"> Logados/Deslogados</small>
                      </div>
                      <div
                        v-if="fields.max_agents_login"
                        class="align-items-center"
                      >
                        <div class="d-flex justify-content-between">
                          <span style="color: #5CB85C">{{ getMaxLoggedAgents() }}</span>
                          <span v-if="fields.max_agents_login">{{
                            fields.max_agents_login - getMaxLoggedAgents()
                          }}</span>
                        </div>
                        <progressbar-multiple
                          class="col-lg-12"
                          style="height: 8px; background-color: #B1C2D9"
                          :data="[
                            {
                              color: '#5CB85C',
                              percentage: (getMaxLoggedAgents() / fields.max_agents_login) * 100,
                              count: getMaxLoggedAgents(),
                              title: 'Agentes logados'
                            }
                          ]"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col">
              <div class="card">
                <div class="card-body p-3">
                  <div class="row align-center gx-0">
                    <div class="col text-center">
                      <div class="d-flex justify-content-between mb-1">
                        <small class="">
                          Ligações
                        </small>
                        <small class="text-muted">
                          Ativas/Disponível
                        </small>
                      </div>
                      <div
                        v-if="fields.calls && callStats"
                        class="align-items-center"
                      >
                        <div class="d-flex justify-content-between">
                          <span style="color: #5CB85C">{{ fields.calls.data.active_calls }}</span>
                          <span
                            v-if="callStats.max_calls_per_agent"
                            style="color: #5CB85C"
                          >{{
                            callStats.max_calls_per_agent * getMaxLoggedAgents() - fields.calls.data.active_calls
                          }}</span>
                        </div>
                        <progressbar-multiple
                          v-if="fields.calls && callStats.max_calls_per_agent"
                          class="col-lg-12"
                          style="height: 8px; background-color: #B1C2D9"
                          :data="[
                            {
                              color: '#5CB85C',
                              percentage:
                                getMaxLoggedAgents() == 0
                                  ? 0
                                  : (fields.calls.data.active_calls /
                                    (callStats.max_calls_per_agent * getMaxLoggedAgents() -
                                      fields.calls.data.active_calls)) *
                                    100,
                              count: fields.calls.data.active_calls,
                              title: 'Ligações ativas'
                            }
                          ]"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-12">
          <ul
            class="nav nav-tabs"
            id="myTab"
            role="tablist"
          >
            <li
              class="nav-item"
              role="presentation"
            >
              <button
                class="nav-link active"
                id="licenses-tab"
                data-bs-toggle="tab"
                data-bs-target="#licenses"
                type="button"
                role="tab"
                aria-controls="licenses"
                aria-selected="true"
                ref="tab-licenses"
              >
                Licenças
              </button>
            </li>
            <li
              class="nav-item"
              role="presentation"
            >
              <button
                class="nav-link"
                id="routes-tab"
                data-bs-toggle="tab"
                data-bs-target="#routes"
                type="button"
                role="tab"
                aria-controls="routes"
                aria-selected="false"
                ref="tab-routes"
              >
                Rotas
              </button>
            </li>
            <li
              class="nav-item"
              role="presentation"
            >
              <button
                class="nav-link"
                id="credit-tab"
                data-bs-toggle="tab"
                data-bs-target="#credit"
                type="button"
                role="tab"
                aria-controls="credit"
                aria-selected="false"
                ref="tab-credit"
              >
                Crédito
              </button>
            </li>
            <li
              class="nav-item"
              role="presentation"
            >
              <button
                class="nav-link"
                id="consumo-tab"
                data-bs-toggle="tab"
                data-bs-target="#consumo"
                type="button"
                role="tab"
                aria-controls="consumo"
                aria-selected="false"
                ref="tab-consumo"
              >
                Consumo
              </button>
            </li>
            <li
              class="nav-item"
              role="presentation"
            >
              <button
                class="nav-link"
                id="receptive-tab"
                data-bs-toggle="tab"
                data-bs-target="#receptive"
                type="button"
                role="tab"
                aria-controls="receptive"
                aria-selected="false"
                ref="tab-receptive"
                data-test="receptiveCompany-button"
              >
                Receptivo
              </button>
            </li>
            <li
              class="nav-item"
              role="presentation"
            >
              <button
                class="nav-link"
                id="register-tab"
                data-bs-toggle="tab"
                data-bs-target="#register"
                type="button"
                role="tab"
                aria-controls="register"
                aria-selected="false"
                ref="tab-register"
                data-test="registerCompany-button"
              >
                Cadastro
              </button>
            </li>
            <li
              class="nav-item"
              role="presentation"
            >
              <button
                class="nav-link"
                id="history-tab"
                data-bs-toggle="tab"
                data-bs-target="#history"
                type="button"
                role="tab"
                aria-controls="register"
                aria-selected="false"
                ref="tab-history"
                data-test="registerCompany-button"
              >
                Histórico e alterações
              </button>
            </li>
          </ul>
        </div>
        <div class="col-lg-12">
          <div class="tab-content">
            <div
              class="tab-pane fade show active"
              id="licenses"
              role="tabpanel"
              aria-labelledby="home-tab"
            >
              <div
                class="tab-pane fade show active"
                id="licenses"
                role="tabpanel"
                aria-labelledby="home-tab"
              >
                <div class="row mt-4">
                  <div class="col-12">
                    <div class="card">
                      <div class="card-body p-0">
                        <div class="d-flex justify-content-between mx-4 mt-4 mb-1">
                          <div>
                            <h3 class="mx-3 my-3 fw-normal">
                              Discadora
                            </h3>
                          </div>

                          <div class="d-flex align-items-center">
                            <div class="form-check">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                v-model="fields.pro_mode"
                                @change="switchProMode"
                                id="switchProMode"
                              >
                              <label
                                class="form-check-label"
                                for="switchProMode"
                              >
                                MODO PRO
                              </label>
                            </div>
                          </div>
                        </div>

                        <hr class="navbar-divider mx-4">
                        <div class="d-flex justify-content-between">
                          <div
                            class="card-body py-0 cursor-pointer border-end"
                            @mouseover="selectItem('buttonLoggedAgents')"
                            @mouseleave="selectItem()"
                            @click="$refs.editMaxAgentsLogin.openModal()"
                            :style="selectedItem == 'buttonLoggedAgents' ? 'color: #2C7BE5 !important' : ''"
                          >
                            <div
                              :style="selectedItem == 'buttonLoggedAgents' ? 'background-color: #d5e5fa;' : ''"
                              style="border-radius: 7px"
                              class="d-flex justify-content-around py-2"
                            >
                              <span :class="selectedItem == 'buttonLoggedAgents' ? '' : 'text-muted'">Limite de agentes logados</span>
                              <div>
                                <span class="fw-normal fs-3 mx-2">
                                  {{ this.register.max_agents_login }}
                                </span>
                                <span
                                  class="fe fe-edit-2"
                                  :class="selectedItem == 'buttonLoggedAgents' ? '' : 'text-muted'"
                                />
                              </div>
                              <div />
                            </div>
                          </div>
                          <div
                            class="card-body py-0 cursor-pointer border-end"
                            @mouseover="selectItem('buttonLimitCallPerAgent')"
                            @mouseleave="selectItem()"
                            @click="$refs.editLimitCallPerAgent.openModal()"
                            :style="selectedItem == 'buttonLimitCallPerAgent' ? 'color: #2C7BE5 !important' : ''"
                          >
                            <div
                              :style="selectedItem == 'buttonLimitCallPerAgent' ? 'background-color: #d5e5fa;' : ''"
                              style="border-radius: 7px"
                              class="d-flex justify-content-around py-2"
                            >
                              <span :class="selectedItem == 'buttonLimitCallPerAgent' ? '' : 'text-muted'">Limite de chamadas por agente</span>
                              <div>
                                <span class="mb-0 mt-1 fw-normal mx-2">
                                  {{ this.register.limit_call_per_agent }}
                                </span>
                                <span
                                  class="fe fe-edit-2"
                                  :class="selectedItem == 'buttonLimitCallPerAgent' ? '' : 'text-muted'"
                                />
                              </div>
                            </div>
                          </div>
                          <div
                            class="card-body cursor-pointer py-0"
                            @mouseover="selectItem('buttonWebphoneLicenses')"
                            @mouseleave="selectItem()"
                            @click="$refs.editWebphoneLicenses.openModal()"
                            :style="selectedItem == 'buttonWebphoneLicenses' ? 'color: #2C7BE5 !important' : ''"
                          >
                            <div
                              :style="selectedItem == 'buttonWebphoneLicenses' ? 'background-color: #d5e5fa;' : ''"
                              style="border-radius: 7px"
                              class="d-flex justify-content-around py-2"
                            >
                              <span :class="selectedItem == 'buttonWebphoneLicenses' ? '' : 'text-muted'">Licenças Webphone</span>
                              <div>
                                <span class="mb-0 mt-1 fw-normal mx-2">
                                  {{ this.register.webphone_licenses }}
                                </span>
                                <span
                                  class="fe fe-edit-2"
                                  :class="selectedItem == 'buttonWebphoneLicenses' ? '' : 'text-muted'"
                                />
                              </div>
                            </div>
                          </div>
                          <div
                            class="card-body py-0"
                            :class="{
                              'cursor-pointer': this.register.pabx,
                              'cursor-not-allowed': !this.register.pabx,
                              'button-disabled-pabx-licenses': !this.register.pabx
                            }"
                            @mouseover="this.register.pabx && selectItem('buttonRingGroupLicenses')"
                            @mouseleave="this.register.pabx && selectItem()"
                            @click="openRingGroupLicensesModal"
                            :style="selectedItem == 'buttonRingGroupLicenses' ? 'color: #2C7BE5 !important' : ''"
                          >
                            <div
                              :style="selectedItem == 'buttonRingGroupLicenses' ? 'background-color: #d5e5fa;' : ''"
                              style="border-radius: 7px"
                              class="d-flex justify-content-around py-2"
                            >
                              <span :class="selectedItem == 'buttonRingGroupLicenses' ? '' : 'text-muted'">Ramais</span>
                              <div>
                                <span class="mb-0 mt-1 fw-normal mx-2">
                                  {{ this.register.used_ring_group_licenses }}/{{ this.register.ring_group_licenses }}
                                </span>
                                <span
                                  class="fe fe-edit-2"
                                  :class="selectedItem == 'buttonWebphoneLicenses' ? '' : 'text-muted'"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          class="col-lg-12 mb-4"
                          style="margin-top: 1rem;"
                        >
                          <div class="d-flex align-items-center gap-3">
                            <hr class="w-100">
                            <button
                              class="btn text-nowrap text-small show-more-button"
                              @click="showAdvancedOptions = !showAdvancedOptions"
                              :class="!showAdvancedOptions ? 'btn-light' : 'btn-primary'"
                              data-test="showAdvancedOptions-button"
                            >
                              <i
                                :class="!showAdvancedOptions ? 'fe fe-chevron-down' : 'fe fe-chevron-up'"
                                class="me-2"
                              />
                              Configurações avançadas
                            </button>
                            <hr class="w-100">
                          </div>
                          <div v-show="showAdvancedOptions">
                            <div class="row mt-4 mx-4">
                              <div class="col-lg-5 b9">
                                <div class="form-group">
                                  <div class="form-check form-switch">
                                    <input
                                      id="enabledDownloadAudiosInBatch"
                                      class="form-check-input"
                                      type="checkbox"
                                      v-model.lazy="fields.enabled_download_audios_in_batch"
                                      @change="saveAudiosSettings"
                                      data-test="activeListNotify-checkbox"
                                    >
                                    <label
                                      class="form-check-label"
                                      for="enabledDownloadAudiosInBatch"
                                    >
                                      Baixar áudios em massa
                                    </label>
                                  </div>
                                  <span class="form-text text-small">
                                    Habilita o gestor baixar áudios em massa
                                  </span>
                                </div>
                              </div>

                              <div class="col-lg-5 b9">
                                <div class="form-group">
                                  <div class="form-check form-switch">
                                    <input
                                      id="enabledRecordingsInStereo"
                                      class="form-check-input"
                                      type="checkbox"
                                      v-model.lazy="fields.enabled_recordings_in_stereo"
                                      @change="saveAudiosSettings"
                                      data-test="activeListNotify-checkbox"
                                      :disabled="fields.ai_call_evaluation"
                                    >
                                    <label
                                      class="form-check-label"
                                      for="enabledRecordingsInStereo"
                                    >
                                      Gravações em estéreo
                                    </label>
                                  </div>
                                  <span class="form-text text-small">
                                    Habilita o gestor baixar áudios em estéreo
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-4">
                    <div class="card card-fill pb-4">
                      <div class="p-0">
                        <div class="d-flex justify-content-between mx-2 mt-4 mb-1">
                          <div>
                            <h3 class="mx-4 my-3 fw-normal">
                              URA
                            </h3>
                          </div>
                        </div>
                        <hr class="navbar-divider mx-4">
                        <div
                          class="card-body py-0 cursor-pointer"
                          @mouseover="selectItem('buttonIvrLicenses')"
                          @mouseleave="selectItem()"
                          @click="$refs.editIvrLicenses.openModal()"
                          :style="selectedItem == 'buttonIvrLicenses' ? 'color: #2C7BE5 !important' : ''"
                        >
                          <div
                            :style="selectedItem == 'buttonIvrLicenses' ? 'background-color: #d5e5fa;' : ''"
                            style="border-radius: 7px"
                            class="d-flex justify-content-around py-2"
                          >
                            <span :class="selectedItem == 'buttonIvrLicenses' ? '' : 'text-muted'">Licenças URA</span>
                            <div>
                              <span class="mb-0 mt-1 fw-normal mx-2">
                                {{ this.register.ura_licenses - this.register.ura_limit }}/{{
                                  this.register.ura_licenses
                                }}
                              </span>
                              <span
                                class="fe fe-edit-2"
                                :class="selectedItem == 'buttonIvrLicenses' ? '' : 'text-muted'"
                              />
                            </div>
                          </div>
                        </div>
                        <hr class="navbar-divider mx-4">
                      </div>
                    </div>
                  </div>
                  <div class="col-4">
                    <div class="card pb-4">
                      <div class="card-body p-0">
                        <div class="d-flex justify-content-between mx-2 mt-4 mb-1">
                          <div>
                            <h3 class="mx-4 my-3 fw-normal">
                              Whatsapp
                            </h3>
                          </div>
                        </div>
                        <hr class="navbar-divider mx-4">
                        <div
                          class="card-body cursor-pointer py-0"
                          @mouseover="selectItem('buttonWhatsappLicenses')"
                          @mouseleave="selectItem()"
                          @click="this.modals.modalWhatsappLicenses.toggle()"
                          :style="selectedItem == 'buttonWhatsappLicenses' ? 'color: #2C7BE5 !important' : ''"
                        >
                          <div
                            :style="selectedItem == 'buttonWhatsappLicenses' ? 'background-color: #d5e5fa;' : ''"
                            style="border-radius: 7px"
                            class="d-flex justify-content-around py-2"
                          >
                            <span :class="selectedItem == 'buttonWhatsappLicenses' ? '' : 'text-muted'">Conexões</span>
                            <div>
                              <span class="mb-0 mt-1 fw-normal mx-2">
                                {{ this.register.whatsapp_licenses }}
                              </span>
                              <span
                                class="fe fe-edit-2"
                                :class="selectedItem == 'buttonWhatsappLicenses' ? '' : 'text-muted'"
                              />
                            </div>
                          </div>
                        </div>
                        <hr class="navbar-divider mx-4">
                        <div
                          class="card-body cursor-pointer py-0"
                          @mouseover="selectItem('buttonWhatsapp_max_concurrent_logins')"
                          @mouseleave="selectItem()"
                          @click="this.modals.modalWhatsappMaxConcurrentLogins.toggle()"
                          :style="
                            selectedItem == 'buttonWhatsapp_max_concurrent_logins' ? 'color: #2C7BE5 !important' : ''
                          "
                        >
                          <div
                            :style="
                              selectedItem == 'buttonWhatsapp_max_concurrent_logins' ? 'background-color: #d5e5fa' : ''
                            "
                            style="border-radius: 7px"
                            class="d-flex justify-content-around py-2"
                          >
                            <span
                              :class="selectedItem == 'buttonWhatsapp_max_concurrent_logins' ? '' : 'text-muted fs-5'"
                            >Limite de agentes logados</span>
                            <div>
                              <span class="mb-0 mt-1 fw-normal mx-2">
                                {{ this.register.whatsapp_max_concurrent_logins }}
                              </span>
                              <span
                                class="fe fe-edit-2"
                                :class="selectedItem == 'buttonWhatsapp_max_concurrent_logins' ? '' : 'text-muted'"
                              />
                            </div>
                          </div>
                        </div>
                        <hr class="navbar-divider mx-4">
                      </div>
                    </div>
                  </div>
                  <div class="col-4">
                    <div class="card pb-4">
                      <div class="card-body p-0">
                        <div class="d-flex justify-content-between mx-2 mt-4 mb-1">
                          <div>
                            <h3 class="mx-4 my-3 fw-normal">
                              SMS
                            </h3>
                          </div>
                        </div>
                        <hr class="navbar-divider mx-4">
                        <div
                          class="card-body cursor-pointer py-0"
                          @mouseover="selectItem('buttonSMS')"
                          @mouseleave="selectItem()"
                          @click="this.modals.modalSMS.toggle()"
                          :style="selectedItem == 'buttonSMS' ? 'color: #2C7BE5 !important' : ''"
                        >
                          <div
                            :style="selectedItem == 'buttonSMS' ? 'background-color: #d5e5fa;' : ''"
                            style="border-radius: 7px"
                            class="d-flex justify-content-around py-2"
                          >
                            <span :class="selectedItem == 'buttonSMS' ? '' : 'text-muted'">Valor por SMS</span>
                            <div>
                              <span
                                v-if="this.register.value_sms"
                                class="mb-0 mt-1 fw-normal mx-2"
                              >
                                {{
                                  this.register.value_sms.toLocaleString("pt-br", {
                                    style: "currency",
                                    currency: "BRL",
                                  })
                                }}
                              </span>
                              <span
                                class="fe fe-edit-2"
                                :class="selectedItem == 'buttonSMS' ? '' : 'text-muted'"
                              />
                            </div>
                          </div>
                        </div>
                        <hr class="navbar-divider mx-4">
                        <div
                          style="border-radius: 7px"
                          class="d-flex justify-content-around py-2"
                        >
                          <span class="text-muted">SMS Disponíveis:</span>
                          <div>
                            <span class="">{{ fields.credit_sms }}</span>
                          </div>
                        </div>
                        <hr class="navbar-divider mx-4">
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-4">
                    <div class="card p-4">
                      <div class="d-flex justify-content-between mx-2 mt-3 mb-1 gap-3">
                        <h3 class="fw-normal mb-0">
                          Vencimento
                        </h3>
                      </div>
                      <hr class="nav-divider">
                      <div
                        class="cursor-pointer py-0"
                        @mouseover="selectItem('buttonBlockDate')"
                        @mouseleave="selectItem()"
                        @click="showModalChangeBlockedDate"
                        :class="{
                          'bg-gray-100': selectedItem == 'buttonBlockDate'
                        }"
                      >
                        <div
                          :style="selectedItem == 'buttonBlockDate' ? 'background-color: #d5e5fa;' : ''"
                          style="border-radius: 7px"
                          class="d-flex justify-content-around py-2"
                        >
                          <span
                            :class="{
                              'text-muted': !(selectedItem == 'buttonBlockDate'),
                              'tx-blue-300': selectedItem == 'buttonBlockDate'
                            }"
                          >
                            Bloqueio da empresa
                          </span>
                          <div
                            :class="{
                              'tx-blue-300': selectedItem == 'buttonBlockDate'
                            }"
                            class="d-flex"
                          >
                            <div
                              v-if="this.fields.blocked_at == null"
                              class="me-5"
                            >
                              -
                            </div>
                            <div
                              v-else
                              class="mb-0 fw-normal mx-2"
                            >
                              {{ timestampToDate(this.fields.blocked_at, "DD/MM/Y") }}
                            </div>
                            <span
                              class="fe fe-edit-2"
                              :class="!(selectedItem == 'buttonBlockDate') && 'text-muted'"
                            />
                          </div>
                        </div>
                      </div>
                      <hr class="nav-divider">
                      <div class="py-2">
                        <div
                          v-if="
                            this.register.company_financial_status.days > 0 &&
                              this.register.company_financial_status.status != 'Bloqueada'
                          "
                          class="d-flex justify-content-around"
                        >
                          <span
                            class="badge fs-5 px-2 py-1"
                            :class="this.register.company_financial_status.days > 5 ? 'success' : 'warning'"
                          >
                            {{ this.register.company_financial_status.status }}
                          </span>
                          <span>Restam {{ this.register.company_financial_status.days }} dias</span>
                        </div>
                        <div v-else>
                          <div
                            v-if="this.fields.blocked_at == null"
                            class="d-flex justify-content-around"
                          >
                            <span class="badge success fs-5 px-2 py-1">
                              Em dia
                            </span>
                            <span class="text-muted">Tempo indeterminado</span>
                          </div>
                          <div
                            v-else
                            class="d-flex justify-content-around"
                          >
                            <span class="badge error fs-5 px-2 py-1">
                              Bloqueada
                            </span>
                            <span class="text-muted">Tempo expirado</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-4">
                    <div class="card pb-4">
                      <div class="card-header">
                        <div class="form-check form-switch d-flex align-items-center ">
                          <input
                            style="width: 28px;height: 16px;"
                            class="form-check-input"
                            type="checkbox"
                            role="switch"
                            id="flexSwitchCheckChecked"
                            @change="switchAI"
                            :checked="fields.ai_call_evaluation"
                            v-model="fields.ai_call_evaluation"
                          >
                          <label
                            style="margin-left: 16px;"
                            class="form-check-label"
                            for="flexSwitchCheckChecked"
                          >Insights IA</label>
                        </div>
                      </div>
                      <div class="card-body py-0">
                        <div
                          class="cursor-pointer mt-4"
                          @mouseover="selectItem('insightsAI')"
                          @mouseleave="selectItem()"
                          @click="resetValueInsightIA"
                          :style="selectedItem == 'insightsAI' ? 'color: #2C7BE5 !important' : ''"
                        >
                          <div
                            :style="selectedItem == 'insightsAI' ? 'background-color: #d5e5fa;' : ''"
                            style="border-radius: 7px"
                            class="d-flex justify-content-around py-2"
                          >
                            <span :class="selectedItem == 'insightsAI' ? '' : 'text-muted'">Quantidade de ligações no mês</span>
                            <div class="d-flex align-items-center">
                              <p
                                class="mb-0"
                                style="margin-right: 8px;"
                              >
                                {{ register.ai_evaluation_balance }}
                              </p>
                              <i
                                class="fe fe-edit-2"
                                :class="selectedItem == 'insightsAI' ? '' : 'text-muted'"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <modal-change-blocked-date
              ref="modalChangeBlockedDate"
              :blocked-at="fields.blocked_at"
              :original-block-date="
                register.original_block_date || register.blocked_at || Math.round(Date.now() / 1000)
              "
              :id="fields.id"
            />
            <div
              class="tab-pane fade"
              id="register"
              role="tabpanel"
              aria-labelledby="register"
            >
              <settings-companies
                :item="fields"
                :tags="tags"
              />
            </div>
            <div
              class="tab-pane fade"
              id="routes"
              role="tabpanel"
              aria-labelledby="routes"
            >
              <routes-companies />
            </div>
            <div
              class="tab-pane fade"
              id="credit"
              role="tabpanel"
              aria-labelledby="credit"
            >
              <credit-companies />
            </div>
            <div
              class="tab-pane fade"
              id="consumo"
              role="tabpanel"
              aria-labelledby="consumo"
            >
              <consumo-companies />
            </div>
            <div
              class="tab-pane fade"
              id="receptive"
              role="tabpanel"
              aria-labelledby="receptive"
            >
              <receptive-companies />
            </div>
            <div
              class="tab-pane fade"
              id="history"
              role="tabpanel"
              aria-labelledby="history"
            >
              <history-companies />
            </div>
          </div>
        </div>
      </div>
      <main-loading v-else-if="loading?.findById" />
      <div
        class="modal fade"
        ref="modalTags"
        tabindex="-1"
      >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content align-items-center">
            <h1 class="form-label fw-light text-center my-3">
              Tags
            </h1>
            <div class="col-lg-11">
              <label class="form-label h3 fw-normal mt-5">Tags</label>
              <div class="d-flex justify-content-center mb-3">
                <VueMultiselect
                  v-model="fields.tags"
                  label="name"
                  track-by="id"
                  placeholder="Selecione os agentes"
                  :select-label="$t('click_or_press_enter_to_select')"
                  :deselect-label="$t('click_or_press_enter_to_remove')"
                  :selected-label="$t('selected')"
                  open-direction="bottom"
                  :multiple="true"
                  :close-on-select="false"
                  :preserve-search="true"
                  :options="tags"
                />
              </div>
            </div>
            <app-button
              @click="editCompanyTag()"
              :loading="loading.editTags"
              color="btn-primary"
              class="col-lg-11 my-3"
            >
              {{ $t("capitalizes.save") }}
            </app-button>
          </div>
        </div>
      </div>
      <div
        class="modal fade"
        ref="modalCredits"
        tabindex="-1"
      >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content align-items-center">
            <h1 class="form-label fw-light text-center my-3">
              Crédito
            </h1>
            <div class="col-lg-11">
              <label class="form-label h3 fw-normal mt-5">Operação</label>
              <div class="d-flex justify-content-center">
                <select
                  class="form-select"
                  v-model="creditFields.method"
                >
                  <option value="subtract">
                    Débito
                  </option>
                  <option value="add">
                    Crédito
                  </option>
                </select>
              </div>
            </div>
            <div class="col-lg-11">
              <label class="form-label h3 fw-normal mt-3">Motivo</label>
              <div class="d-flex justify-content-center">
                <select
                  class="form-select"
                  v-model="creditFields.reason"
                >
                  <option value="pay">
                    Pagamento
                  </option>
                  <option value="discount">
                    Desconto
                  </option>
                  <option value="chargeback">
                    Estorno
                  </option>
                </select>
              </div>
            </div>
            <div class="col-lg-11">
              <label class="form-label h3 fw-normal mt-3">Valor</label>
              <div class="d-flex justify-content-center mb-5">
                <div class="input-group input-group-merge input-group-reverse">
                  <input
                    v-model="creditFields.value"
                    type="number"
                    class="form-control"
                    placeholder="0,00"
                    aria-label="0,00"
                    aria-describedby="inputGroupReverse"
                  >
                  <div
                    class="input-group-text"
                    id="inputGroupReverse"
                  >
                    <span class="text-dark">R$</span>
                  </div>
                </div>
              </div>
            </div>
            <app-button
              @click="submitChangeCredits()"
              :loading="loading.changeCredits"
              color="btn-primary"
              class="col-lg-11 my-3"
            >
              {{ $t("capitalizes.save") }}
            </app-button>
          </div>
        </div>
      </div>
      <add-modal
        ref="editMaxAgentsLogin"
        title="Alteração de agentes logados"
        v-model="this.fields.max_agents_login"
        license-type="max_agents_login"
      />
      <add-modal
        ref="editLimitCallPerAgent"
        title="Alteração de chamadas por agente"
        v-model="fields.limit_call_per_agent"
        license-type="limit_call_per_agent"
      />
      <add-modal
        ref="editWebphoneLicenses"
        title="Alteração de licenças webphone"
        v-model="fields.webphone_licenses"
        license-type="webphone_licenses"
      />
      <add-modal
        ref="editRingGroupLicenses"
        title="Alteração de licenças de Ramais"
        v-model="fields.ring_group_licenses"
        license-type="ring_group_licenses"
        :quantity-licenses="true"
      />
      <add-modal
        ref="editIvrLicenses"
        title="Alteração de licenças URA"
        v-model="fields.ura_licenses"
        license-type="ura_licenses"
        :quantity-licenses="true"
      />
      <div
        class="modal fade"
        ref="modalSMS"
        tabindex="-1"
      >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content align-items-center">
            <h1 class="form-label fw-light text-center my-3">
              Valor por SMS
            </h1>
            <div class="col-lg-11">
              <label class="form-label h3 fw-normal mt-5">Valor</label>
              <div class="d-flex justify-content-center mb-5">
                <div class="input-group input-group-merge input-group-reverse">
                  <input
                    v-model="fields.value_sms"
                    type="number"
                    class="form-control"
                    placeholder="0,00"
                    aria-label="0,00"
                    aria-describedby="inputGroupReverse"
                  >
                  <div
                    class="input-group-text"
                    id="inputGroupReverse"
                  >
                    <span class="text-dark">R$</span>
                  </div>
                </div>
              </div>
            </div>
            <app-button
              @click="submitChanges('value-sms', 'value_sms', this.fields.value_sms, 'modalSMS')"
              :loading="loading['value-sms']"
              color="btn-primary"
              class="col-lg-11 my-3"
            >
              {{ $t("capitalizes.save") }}
            </app-button>
          </div>
        </div>
      </div>
      <modal-component
        center
        :show="modals.modalInsightsAI"
        @close="modals.modalInsightsAI = false"
      >
        <template #content>
          <div
            class="modal-header"
            style="padding: 16px 24px;height: 56px;"
          >
            <h4 class="mb-0">
              Insights IA
            </h4>
            <button
              class="btn p-0"
              @click="modals.modalInsightsAI = false"
            >
              <i class="fal fa-xmark" />
            </button>
          </div>
          <div class="modal-body">
            <p
              class="text-center tx-text-gray"
              style="margin-bottom: 16px;margin-top: 8px;"
            >
              Quantidade de ligações no mês
            </p>
            <div
              class="d-flex justify-content-center"
              style="margin-bottom: 4px;"
            >
              <plus-minus-input
                show-buttons
                v-model="fields.ai_evaluation_balance"
                max="infinity"
              />
            </div>
          </div>
          <div class="modal-footer py-0 border-0">
            <div class="d-flex flex-column align-items-center w-100">
              <app-button
                @click="
                  submitChanges(
                    'ai-evaluation-balance',
                    'balance',
                    Number(this.fields.ai_evaluation_balance),
                    'modals.modalInsightsAI'
                  )
                "
                :loading="loading['value-insights']"
                color="btn-primary"
                class="col-lg-11 my-3"
              >
                Atualizar
              </app-button>

              <button
                class="btn tx-text-gray"
                @click="modals.modalInsightsAI = false"
              >
                Voltar
              </button>
            </div>
          </div>
        </template>
      </modal-component>
      <div
        class="modal fade"
        ref="modalWhatsappLicenses"
        tabindex="-1"
      >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content align-items-center px-6">
            <h2 class="form-label fw-light text-center my-3">
              Limite de conexões Whatsapp
            </h2>
            <div class="d-flex justify-content-center my-5">
              <span
                @click="fields.whatsapp_licenses >= activeInstances && fields.whatsapp_licenses--"
                :class="{ 'h1 fe fe-minus ml-5 my-0 cursor-pointer': true }"
                :style="{ visibility: fields.whatsapp_licenses < activeInstances ? 'hidden' : 'visible' }"
              />
              <input
                v-model="fields.whatsapp_licenses"
                type="number"
                :class="{
                  'form-control-flush col-2 text-center h1': true,
                  'tx-red-300': fields.whatsapp_licenses < activeInstances
                }"
              >
              <span
                @click="fields.whatsapp_licenses++"
                class="h1 fe fe-plus my-0 mr-5 cursor-pointer"
              />
            </div>
            <app-button
              @click="
                submitChanges(
                  'whatsapp-licenses',
                  'whatsapp_licenses',
                  this.fields.whatsapp_licenses,
                  'modalWhatsappLicenses'
                )
              "
              :loading="loading['whatsapp-licenses']"
              v-if="fields.whatsapp_licenses >= activeInstances"
              color="btn-primary"
              class="col-lg-11 my-3"
            >
              Atualizar
            </app-button>
            <span
              v-else
              class="text-center tx-red-300 my-3"
            >
              *Não é possível realizar esta ação, todas as conexões disponíveis estão sendo utilizadas por esta empresa
            </span>
            <app-button
              @click="this.modals.modalWhatsappLicenses.toggle()"
              color="btn"
              class="col-lg-11 my-3"
            >
              Cancelar
            </app-button>
          </div>
        </div>
      </div>
      <div
        class="modal fade"
        ref="modalWhatsappMaxConcurrentLogins"
        tabindex="-1"
      >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content align-items-center">
            <h1 class="form-label fw-light text-center my-3">
              Limite de agentes logados
            </h1>
            <div class="d-flex justify-content-center my-5">
              <span
                @click="fields.whatsapp_max_concurrent_logins--"
                class="h1 fe fe-minus text-primary ml-5 my-0 cursor-pointer"
                :style="{
                  visibility:
                    fields.whatsapp_max_concurrent_logins == 0 || fields.whatsapp_max_concurrent_logins <= 0
                      ? 'hidden'
                      : 'visible'
                }"
              />
              <input
                v-model="fields.whatsapp_max_concurrent_logins"
                type="number"
                class="form-control-flush col-2 text-center h1"
              >
              <span
                @click="fields.whatsapp_max_concurrent_logins++"
                class="h1 fe fe-plus text-primary my-0 mr-5 cursor-pointer"
              />
            </div>
            <app-button
              @click="
                submitChanges(
                  'whatsapp-max-concurrent-logins',
                  'whatsapp_max_concurrent_logins',
                  this.fields.whatsapp_max_concurrent_logins,
                  'modalWhatsappMaxConcurrentLogins'
                )
              "
              :loading="loading['whatsapp-max-concurrent-logins']"
              color="btn-primary"
              class="col-lg-11 my-3"
            >
              {{ $t("capitalizes.save") }}
            </app-button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <pix-modal ref="pixModal" />
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";

import { Modal, Tab } from "bootstrap";

import { generateTooltip } from "@/utils.js";

import VueMultiselect from "vue-multiselect";
import mainLoading from "@/components/main-loading.vue";
import ProgressbarMultiple from "@components/progressbar-multiple";
import AppButton from "@components/app-button";
import { AgentStatus, Utils } from "@/utils";
import ModalChangeBlockedDate from "./licenses/modal-change-blocked-date.vue";

import routesCompanies from "./routes/routes-companies.vue";
import settingsCompanies from "./settings-companies.vue";
import consumoCompanies from "./consumo-companies.vue";
import creditCompanies from "./credit-companies.vue";
import receptiveCompanies from "./receptive-companies.vue";
import HistoryCompanies from "./history-companies.vue";
import PixModal from "./pix-payment/pix-modal";
import AddModal from "./components/add-licenses";
import PlusMinusInput from "@dialer/manager/pages/dashboards/campaign/components/tablist/plus-minus-input.vue";
import ModalComponent from "@components/modal-component.vue";

export default {
  data() {
    return {
      AgentStatus: AgentStatus,
      blockedInfoTooltip: null,
      fields: {
        access_bi: null,
        agentStatus: [],
        balance: null,
        blocked_at: null,
        blocked_sms: null,
        calls: null,
        created_at: "",
        credit_limit: "",
        credit_sms: null,
        country_id: "",
        language: "",
        currency: "",
        date: null,
        domain: "",
        enabled_download_audios_in_batch: false,
        enabled_recordings_in_stereo: false,
        ai_call_evaluation: false,
        id: null,
        integration_enabled: null,
        instances: null,
        international_route_group_id: null,
        international_route_id: null,
        is_partner: null,
        limit_call_per_agent: 0,
        ai_evaluation_balance: 0,
        limit_unproductive_calls: null,
        logo_image_link: "",
        logo_name: "",
        max_agents_login: null,
        name: "",
        new_front_end: null,
        pabx: false,
        partner_id: null,
        plan: null,
        pro_mode: false,
        ring_group_licenses: null,
        route_group_landline_id: null,
        route_group_mobile_id: null,
        route_landline_id: null,
        route_mobile_id: null,
        socket_channel: "",
        tags: [],
        two_factor: false,
        two_factor_interval_days: 0,
        two_factor_setup_deadline: 0,
        two_factor_setup_deadline_edit: false,
        ura_licenses: null,
        ura_limit: null,
        url: "",
        value_sms: null,
        webphone_licenses: null,
        whatsapp_licenses: null,
        whatsapp_max_concurrent_logins: null,
      },
      originalLicenses: {
        webphone_licenses: null,
        whatsapp_licenses: null,
        ura_licenses: null,
      },
      creditFields: {
        method: "",
        reason: "",
        value: null,
      },
      modals: {
        modalCredits: null,
        modalSMS: null,
        modalInsightsAI: false,
        modalWhatsappLicenses: null,
        modalWhatsappMaxConcurrentLogins: null,
        modalTags: null,
        modalBlockDate: null,
      },
      selectedItem: false,
      tab_licenses: null,
      tab_routes: null,
      tab_credit: null,
      tab_consumo: null,
      tab_register: null,
      tab_receptive: null,
      tab_history: null,
      showAdvancedOptions: false,
    };
  },
  components: {
    ModalComponent,
    PlusMinusInput,
    mainLoading,
    ProgressbarMultiple,
    AppButton,
    VueMultiselect,
    settingsCompanies,
    routesCompanies,
    consumoCompanies,
    creditCompanies,
    receptiveCompanies,
    HistoryCompanies,
    ModalChangeBlockedDate,
    PixModal,
    AddModal,
  },

  beforeMount() {
    this.findCallStats();
  },

  mounted() {
    this.findById({ id: this.$route.params.id }).then(() => {
      this.createNewTabs();
      this.createModals();
      this.getCurrentCompany();
      this.blocked_sms = this.fields.blocked_sms;
    });
    this.setPerPage(-1)
    this.findTags();
  },

  computed: {
    ...mapGetters("admin/companies", {
      loading: "getLoadingFlags",
      register: "getRegister",
      callStats: "getCallStats",
    }),

    ...mapGetters("admin/tags", {
      tags: "getList",
    }),

    ...mapGetters("auth", {
      user: "getUser",
    }),

    activeInstances() {
      return this.fields.instances?.reduce((count, el) => {
        return el.status !== "deleted" ? count + 1 : count;
      }, 0);
    },
  },

  watch: {
    register(register) {
      if (register) {
        this.fields.access_bi = register.access_bi;
        this.fields.agentStatus = register["agent-status"];
        this.fields.balance = register.balance;
        this.fields.blocked_at = register.blocked_at;
        this.fields.blocked_sms = register.blocked_sms;
        this.fields.calls = register.calls;
        this.fields.country_id = register["company-data"].data.country.name;
        this.fields.created_at = register.created_at;
        this.fields.credit_limit = register.credit_limit;
        this.fields.credit_sms = register.credit_sms;
        this.fields.currency = register.currency;
        this.fields.domain = register.domain;
        this.fields.enabled_download_audios_in_batch = register.enabled_download_audios_in_batch;
        this.fields.enabled_recordings_in_stereo = register.enabled_recordings_in_stereo;
        this.fields.id = register.id;
        this.fields.integration_enabled = register.integration_enabled;
        this.fields.instances = register.instances.data;
        this.fields.international_route_group_id = register.international_route_group_id;
        this.fields.international_route_id = register.international_route_id;
        this.fields.is_partner = register.is_partner;
        this.fields.language = register["company-data"].data.language;
        this.fields.limit_call_per_agent = parseInt(register.limit_call_per_agent);
        this.fields.limit_unproductive_calls = register.limit_unproductive_calls;
        this.fields.logo_image_link = register.logo_image_link;
        this.fields.logo_name = register.logo_name;
        this.fields.max_agents_login = register.max_agents_login;
        this.fields.name = register.name;
        this.fields.new_front_end = register.new_front_end;
        this.fields.pabx = register.pabx;
        this.fields.partner_id = register.partner_id;
        this.fields.plan = register.plan;
        this.fields.pro_mode = register.pro_mode;
        this.fields.ring_group_licenses = register.ring_group_licenses;
        this.fields.route_group_landline_id = register.route_group_landline_id;
        this.fields.route_group_mobile_id = register.route_group_mobile_id;
        this.fields.route_landline_id = register.route_landline_id;
        this.fields.route_mobile_id = register.route_mobile_id;
        this.fields.socket_channel = register.socket_channel;
        this.fields.tags = register.tags;
        this.fields.two_factor = register.two_factor;
        this.fields.two_factor_interval_days = register.two_factor_interval_days;
        this.fields.two_factor_setup_deadline = register.two_factor_setup_deadline;
        this.fields.two_factor_setup_deadline_edit = register.two_factor_setup_deadline_edit;
        this.fields.ura_licenses = register.ura_licenses;
        this.fields.ura_limit = register.ura_limit;
        this.fields.url = register.url;
        this.fields.value_sms = register.value_sms;
        this.fields.webphone_licenses = register.webphone_licenses;
        this.fields.whatsapp_licenses = register.whatsapp_licenses;
        this.fields.whatsapp_max_concurrent_logins = register.whatsapp_max_concurrent_logins;
        this.fields.ai_call_evaluation = register.ai_call_evaluation;
        this.fields.ai_evaluation_balance = register.ai_evaluation_balance;
        this.originalLicenses.whatsapp_licenses = register.whatsapp_licenses;
        this.originalLicenses.webphone_licenses = register.webphone_licenses;
      }
    },
  },
  methods: {
    ...mapActions("admin/companies", [
      "findById",
      "change",
      "changeStatusInsightsAI",
      "changeCredits",
      "editTags",
      "findCallStats",
      "updateAudiosSettings",
      "changeProMode",
    ]),
    ...mapActions("admin/sms", ["blockCompanySMS"]),
    ...mapActions("admin/tags", ["findTags", "setPerPage"]),
    ...mapActions("auth", ["enterImpersonateMode", "getCurrentCompany"]),
    ...mapMutations("auth", ["trackEvents"]),

    generateTooltip,
    timestampToDate: Utils.timestampToDate,
    showModalChangeBlockedDate() {
      this.$refs.modalChangeBlockedDate.showModal();
    },

    saveAudiosSettings() {
      this.updateAudiosSettings({
        id: this.fields.id,
        enabled_download_audios_in_batch: this.fields.enabled_download_audios_in_batch,
        enabled_recordings_in_stereo: this.fields.enabled_recordings_in_stereo,
      });
    },

    selectItem(item) {
      this.selectedItem = item;
    },
    unSelectItem() {
      this.selectedItem = false;
    },

    generateBlockedTooltip() {
      this.blockedInfoTooltip = this.generateTooltip(`${this.$t("bocked_company_sms")}`, this.$refs.blockedInfo);

    },

    impersonate(id) {
      this.enterImpersonateMode({
        id: id,
      }).then(() => {
        this.$router.push("/manager/voice-panel");
      });
      this.getCurrentCompany().then(() => {
        this.trackEvents(`[Admin] Impersonate Access inside the company`);
      });
    },

    createNewTabs() {
      this.tab_licenses = new Tab(this.$refs["tab-licenses"]);
      this.tab_routes = new Tab(this.$refs["tab-routes"]);
      this.tab_credit = new Tab(this.$refs["tab-credit"]);
      this.tab_consumo = new Tab(this.$refs["tab-consumo"]);
      this.tab_register = new Tab(this.$refs["tab-register"]);
      this.tab_receptive = new Tab(this.$refs["tab-receptive"]);
      this.tab_history = new Tab(this.$refs["tab-history"]);
    },

    createModals() {
      this.modals.modalCredits = new Modal(this.$refs.modalCredits, {});
      this.modals.modalSMS = new Modal(this.$refs.modalSMS, {});
      this.modals.modalWhatsappLicenses = new Modal(this.$refs.modalWhatsappLicenses, {});
      this.modals.modalWhatsappMaxConcurrentLogins = new Modal(this.$refs.modalWhatsappMaxConcurrentLogins, {});
      this.modals.modalTags = new Modal(this.$refs.modalTags, {});
    },

    blockSMSCompany() {
      this.blockCompanySMS({
        id: this.fields.id,
        fields: {
          blocked_sms: this.fields.blocked_sms,
        },
      });
    },

    getMaxLoggedAgents() {
      let count = 0;
      this.fields.agentStatus.forEach(status => {
        status.status != 0 ? count++ : null;
      });
      return count;
    },

    editCompanyTag() {
      this.editTags({
        id: this.fields.id,
        tags: this.fields.tags.map(tags => tags.id),
        domain: this.fields.domain,
        company_name: this.fields.name,
      }).then(() => {
        this.modals.modalTags.toggle();
        this.findById({ id: this.$route.params.id });
      });
    },

    countAgentsOnline(agentStatus) {
      let total = 0;
      for (let agent of agentStatus) total += agent.status != AgentStatus.OFFLINE ? 1 : 0;
      return total;
    },

    submitChangeCredits() {
      this.changeCredits({
        id: this.fields.id,
        method: this.creditFields.method,
        reason: this.creditFields.reason,
        value: this.creditFields.value,
      }).then(() => {
        this.modals.modalCredits.toggle();
        this.findById({ id: this.$route.params.id });
      });
      this.getCurrentCompany().then(() => {
        this.trackEvents(`[Admin] Change Company Credits`);
      });
    },

    submitChanges(dataName, data, dataValue, modal) {
      this.change({
        id: this.fields.id,
        dataName: dataName,
        [data]: dataValue,
      }).then(() => {
        if (modal === "modals.modalInsightsAI") {
          this.modals.modalInsightsAI = false;
        } else {
          this.modals[modal].toggle();
        }
        this.getCurrentCompany().then(() => {
          if (dataValue < this.originalLicenses[data]) {
            this.trackEvents(`[Admin] Decrease Licenses`);
          }
        });
        this.findById({ id: this.$route.params.id });
      });
    },

    switchProMode() {
      this.changeProMode({
        companyId: this.fields.id,
        proMode: this.fields.pro_mode,
      }).then(() => {
        this.findById({ id: this.$route.params.id });
      });
    },

    openRingGroupLicensesModal() {
      if (this.fields.pabx) {
        this.$refs.editRingGroupLicenses.openModal();
      }
    },
    switchAI() {
      let fields = {
        id: this.fields.id,
        status: this.fields.ai_call_evaluation,
      };
      if (this.fields.ai_call_evaluation) {
        this.fields.enabled_recordings_in_stereo = true;
        this.saveAudiosSettings();
      }
      this.changeStatusInsightsAI(fields);
    },
    resetValueInsightIA() {
      this.fields.ai_evaluation_balance = this.register.ai_evaluation_balance;
      this.modals.modalInsightsAI = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.success {
  color: #015d0d;
  background-color: #d6e9d9;
}

.warning {
  color: #754000;
  background-color: #f7eac4;
}

.error {
  color: #920000;
  background-color: #f2c6d1;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.button-disabled-pabx-licenses {
  opacity: 0.5;
}
</style>
