<template>
  <main-loading v-if="loadingFlags.findGroupChannelById" />
  <div v-else>
    <!-- <connect-number-modal
      v-if="
        isManager && groupChannel &&
          (groupChannel.status == 'disconnected' ||
            (groupChannel.status == 'deleted' &&
              user.company.whatsapp_licenses > totalActive))
      "
      :groupChannel-id="groupChannel.id"
      :status="groupChannel.status"
    /> -->
    <div class="main-content">
      <div class="container-fluid">
        <header-component
          :title="groupChannel.name"
          pretitle="OMNICHANNEL"
          redirect-link="/manager/whatsapp"
        >
          <template #buttons>
            <div
              v-if="isManager"
            >
              <router-link
                style="min-width: 308px;"
                :to="'/manager/whatsapp/' + $route.params.id + '/edit'"
                class="btn btn-primary px-6"
              >
                <i
                  v-if="groupChannel.status !== 'deleted'"
                  class="fa-regular fa-gear me-2"
                />
                {{ groupChannel.status == 'deleted'
                  ? $t('reactivate')
                  : $t('settings_omni') }}
              </router-link>
            </div>
          </template>
        </header-component>
        <div class="col-12 col-lg-12 col-xl-12">
          <list-metrics v-if="groupChannel.status != 'deleted'" />
          <div
            v-if="instancesStatus &&
              !loadingFlags.findGroupChannelById &&
              !loadingFlags.findGroupChannelInstanceById"
            class="row"
          >
            <div
              class="col-12 col-sm-6 col-md-6 mb-4"
              v-for="(item, id) in groupChannel.channels.data"
              :key="id"
            >
              <instances-metrics
                :item="item"
                :channel="item.type == 'ultramsg' ?
                  'WhatsApp 3C+' :
                  'WABA'"
              />
            </div>
          </div>
          <list-agents />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MainLoading from "@/components/main-loading.vue";
import ListMetrics from "./components/list-metrics.vue";
import ListAgents from "./components/list-agents.vue";
import InstancesMetrics from "./components/instances-metrics.vue"
import HeaderComponent from "@/components/header-component.vue";

import { mapGetters, mapActions, mapMutations } from "vuex";

export default {
  components: {
    MainLoading,
    ListAgents,
    ListMetrics,
    InstancesMetrics,
    // NotificationCard,
    // ConnectNumberModal,
    HeaderComponent
  },

  created() {
    this.fetchWhatsappDashboardById({group_channel_id: this.$route.params.id})
    this.findGroupChannelById({ id: this.$route.params.id }).then(() => {
      this.groupChannel.channels.data.forEach((item) => {
        this.findGroupChannelInstanceById({id: item.id})
      })
    })
  },

  mounted() {
    this.clearGroupChannelInstance()
    // this.setInstanceId(this.$route.params.id);
    localStorage.setItem("instanceId", this.$route.params.id);
  },

  computed: {
    ...mapGetters("manager/whatsapp", {
      metrics: "getWhatsappMetrics",
      instance: "getInstance",
      activeInstances: "getActiveInstances",
      totalActive: "getTotalActiveInstances"
    }),
    ...mapGetters("manager/whatsapp_group_channel", {
      groupChannel: "getGroupChannel",
      loadingFlags: "getLoadingFlags",
    }),
    ...mapGetters("auth", {
      user: "getUser",
      isManager: "isManager"
    }),

    instancesStatus(){
      if(this.groupChannel.channels.data.length < 2)
        return false
      return this.groupChannel.channels.data.every((el) => el.status == 'connected')
    },
  },

  methods: {
    ...mapActions("system", ["showConfirmationMessage"]),
    ...mapActions("manager/whatsapp_group_channel", ["findGroupChannelById", "findGroupChannelInstanceById"]),
    ...mapMutations("manager/whatsapp_group_channel", ["clearGroupChannelInstance"]),
    ...mapActions("manager/whatsapp", [
      "fetchWhatsappDashboardById",
      "reactivateWhatsappInstance",
    ]),

    ...mapMutations("manager/whatsapp", ["setInstanceId"]),

    async reactivate() {
      let response = await this.showConfirmationMessage({
        title: this.$t('reactivate_connection'),
        text: "",
        type: "goodWarning"
      });

      if (response.isConfirmed) {
        this.reactivateWhatsappInstance({ id: this.instance.id }).then(() => {
          this.$router.push({ path: "/manager/whatsapp" });
        });
      }
    }
  }
};
</script>
