import axios from "@crm/plugins/axios-config";

export default {
  fetchActivities({commit, getters}, type = "pending") {
    const {loadingFlag, setter, setterPagination, getter} = getActivityTypeProperties(type);
    commit("startLoading", loadingFlag);
    commit(setter, []);

    return new Promise((resolve, reject) => {
      axios
        .get("/activity", {params: getters[getter]})
        .then(response => {
          commit(setter, response.data.data);
          commit(setterPagination, response.data.meta);
          resolve();
        })
        .catch(response => {
          commit("system/setErrorMessage", response.response.data, {root: true});
          reject();
        })
        .finally(() => {
          commit("stopLoading", loadingFlag);
        });
    });
  },

  fetchStatisticsActivities({commit, getters}) {
    commit("startLoading", "fetchStatisticsActivities");
    return new Promise((resolve, reject) => {
      axios
        .get("/activity/statistics", {params: getters.getStatisticsActivitiesParams})
        .then(response => {
          commit("setStatisticsActivities", response.data.data);
          resolve();
        })
        .catch(response => {
          commit("system/setErrorMessage", response.response.data, {root: true});
          reject();
        })
        .finally(() => {
          commit("stopLoading", "fetchStatisticsActivities");
        });
    });
  },

  completeActivity({commit, dispatch}, payload) {
    commit("startLoading", "completeActivity");
    return new Promise((resolve, reject) => {
      axios
        .patch(`/activity/${payload.activityId}/complete`)
        .then(() => {
          commit("system/setSuccessMessage", payload.completed_at ? "success_message.reopen_activity" : "success_message.conclude_activity", {root: true});
          if (payload.opportunityId) {
            const tabByType = formatTabByType(payload.activityType);
            if (!payload.currentTab) {
              dispatch("crm/opportunities/fetchOpportunityLogs", {
                opportunityId: payload.opportunityId,
                payload: {}
              }, {root: true});
            } else if (payload.currentTab === tabByType) {
              dispatch("crm/opportunities/fetchOpportunityLogs",
                {
                  opportunityId: payload.opportunityId,
                  payload: {
                    type: tabByType,
                  }
                }, {root: true});
            }
            dispatch("fetchActivities");
          } else {
            dispatch("fetchActivities", "future");
            dispatch("fetchActivities", "pendingOfDay");
          }
          resolve();
        })
        .catch(response => {
          commit("system/setErrorMessage", response.response.data, {root: true});
          reject();
        })
        .finally(() => {
          commit("stopLoading", "completeActivity");
        });
    });
  },

  createActivity({commit, dispatch}, {activity, opportunityId, contactsIds, currentTab}) {
    commit("startLoading", "createActivity");
    commit("setErrors", {errorFlag: "createActivity", errors: {}});
    return new Promise((resolve, reject) => {
      axios
        .post("/activity", activity)
        .then(() => {
          commit("system/setSuccessMessage", "success_message.create_activity", {root: true});
          if (opportunityId) {
            dispatch("fetchActivities");
            if (activity.get("is_completed")) {
              if (!currentTab) {
                dispatch("crm/opportunities/fetchOpportunityLogs", {
                  opportunityId: opportunityId,
                  payload: {
                    contact_ids: contactsIds
                  }
                }, {root: true});
              } else if (currentTab === formatTabByType(activity.get("type"))) {
                dispatch("crm/opportunities/fetchOpportunityLogs",
                  {
                    opportunityId: opportunityId,
                    payload: {
                      type: formatTabByType(activity.get("type"))
                    }
                  }, {root: true});
              }
            }
          } else {
            dispatch("fetchActivities", "future");
            dispatch("fetchActivities", "pendingOfDay");
          }
          resolve();
        })
        .catch(response => {
          if (response.response.data.status === 422) {
            commit("setErrors", {errorFlag: "createActivity", errors: response.response.data.errors});
          }
          commit("system/setErrorMessage", response.response.data, {root: true});
          reject();
        })
        .finally(() => {
          commit("stopLoading", "createActivity");
        });
    });
  },

  updateActivity({commit, dispatch}, {activity, opportunityId}) {
    commit("startLoading", "updateActivity");
    commit("setErrors", {errorFlag: "updateActivity", errors: {}});
    return new Promise((resolve, reject) => {
      axios
        .post(`/activity/${activity.get("id")}`, activity)
        .then(() => {
          commit("system/setSuccessMessage", "success_message.update_activity", {root: true});
          if (opportunityId) {
            dispatch("fetchActivities");
          } else {
            dispatch("fetchActivities", "future");
            dispatch("fetchActivities", "pendingOfDay");
          }
          resolve();
        })
        .catch(response => {
          commit("system/setErrorMessage", response.response.data, {root: true});
          reject();
        })
        .finally(() => {
          commit("stopLoading", "updateActivity");
        });
    });
  },

  deleteActivity({commit, dispatch}, {activityId, opportunityId}) {
    commit("startLoading", "deleteActivity");
    return new Promise((resolve, reject) => {
      axios
        .delete(`/activity/${activityId}`)
        .then(() => {
          commit("system/setSuccessMessage", "success_message.delete_opportunity_activity", {root: true});
          if (opportunityId) {
            dispatch("fetchActivities");
          } else {
            dispatch("fetchActivities", "future");
            dispatch("fetchActivities", "pendingOfDay");
          }
          resolve();
        })
        .catch(response => {
          commit("system/setErrorMessage", response.response.data, {root: true});
          reject();
        })
        .finally(() => {
          commit("stopLoading", "completeActivity");
        });
    });
  },

  closeModal() {
    return new Promise(resolve => resolve);
  },

  fetchPinNotes({commit}, {opportunityId, funnelId}) {
    commit("startLoading", "fetchPinNotes");
    return new Promise((resolve, reject) => {
      axios
        .get(`/opportunity/${opportunityId}/note?funnel_id=${funnelId}`)
        .then(response => {
          commit("setPinNotes", response.data.data);
          resolve();
        })
        .catch(response => {
          commit("system/setErrorMessage", response.response.data, {root: true});
          reject();
        })
        .finally(() => {
          commit("stopLoading", "fetchPinNotes");
        });
    });
  },

  createNote({commit, dispatch}, payload) {
    commit("startLoading", "createNote");
    commit("setErrors", {errorFlag: "createNote", errors: {}});
    return new Promise((resolve, reject) => {
      axios
        .post(`/opportunity/${payload.opportunityFunnelId}/note`, payload.note)
        .then(() => {
          if (payload.note.is_pinned) {
            dispatch("fetchPinNotes", {opportunityId: payload.opportunityId, funnelId: payload.funnelId});
          }
          if (!payload.currentTab) {
            dispatch("crm/opportunities/fetchOpportunityLogs", {
                opportunityId: payload.opportunityId,
                payload: {
                  contact_ids: payload.contactIds,
                }
              },
              {root: true}
            );
          }
          if (payload.currentTab === "ACTIVITY_NOTE") {
            dispatch("crm/opportunities/fetchOpportunityLogs",
              {
                opportunityId: payload.opportunityId,
                payload: {
                  type: payload.currentTab,
                }
              }, {root: true});
          }
          resolve();
        })
        .catch(response => {
          if (response.response.data.status === 422) {
            commit("setErrors", {errorFlag: "createNote", errors: response.response.data.errors});
          }
          commit("system/setErrorMessage", response.response.data, {root: true});
          reject();
        })
        .finally(() => {
          commit("stopLoading", "createNote");
        });
    });
  },

  pinNote({commit, dispatch}, {opportunityId, funnelId, noteId}) {
    commit("startLoading", "pinNote");
    return new Promise((resolve, reject) => {
      axios
        .patch(`/note/${noteId}/pin`)
        .then(() => {
          dispatch("fetchPinNotes", {opportunityId, funnelId});
          resolve();
        })
        .catch(response => {
          commit("system/setErrorMessage", response.response.data, {root: true});
          reject();
        })
        .finally(() => {
          commit("stopLoading", "pinNote");
        });
    });
  },

  unpinNote({commit, dispatch}, {opportunityId, funnelId, noteId}) {
    commit("startLoading", "unpinNote");
    return new Promise((resolve, reject) => {
      axios
        .patch(`/note/${noteId}/unpin`)
        .then(() => {
          dispatch("fetchPinNotes", {opportunityId, funnelId});
          resolve();
        })
        .catch(response => {
          commit("system/setErrorMessage", response.response.data, {root: true});
          reject();
        })
        .finally(() => {
          commit("stopLoading", "unpinNote");
        });
    });
  },

  setPendingActivitiesFilter({commit}, filter) {
    commit("setPendingActivitiesFilter", filter);
  },

  setPendingActivitiesOfDayFilter({commit}, filter) {
    commit("setPendingActivitiesOfDayFilter", filter);
  },

  setFutureActivitiesFilter({commit}, filter) {
    commit("setFutureActivitiesFilter", filter);
  },

  setPendingActivitiesCurrentPage({commit}, currentPage) {
    commit("setPendingActivitiesCurrentPage", currentPage);
  },

  setPendingActivitiesOfDayCurrentPage({commit}, currentPage) {
    commit("setPendingActivitiesOfDayCurrentPage", currentPage);
  },

  setFutureActivitiesCurrentPage({commit}, currentPage) {
    commit("setFutureActivitiesCurrentPage", currentPage);
  },

  setStatisticsActivitiesFilters({commit}, filter) {
    commit("setStatisticsActivitiesFilters", filter);
  },
};

function formatTabByType(tabName) {
  return `ACTIVITY_${tabName.toUpperCase()}`;
}

function getActivityTypeProperties(type) {
  const loadingFlag =
    type === 'pending' ? 'fetchPendingActivities'
      : type === 'pendingOfDay' ? 'fetchPendingActivitiesOfDay'
        : 'fetchFutureActivities';

  const setter =
    type === 'pending' ? 'setPendingActivities'
      : type === 'pendingOfDay' ? 'setPendingActivitiesOfDay'
        : 'setFutureActivities';

  const setterPagination =
    type === 'pending' ? 'setPendingActivitiesPagination'
      : type === 'pendingOfDay' ? 'setPendingActivitiesOfDayPagination'
        : 'setFutureActivitiesPagination';

  const getter =
    type === 'pending' ? 'getPendingActivitiesParams'
      : type === 'pendingOfDay' ? 'getPendingActivitiesOfDayParams'
        : 'getFutureActivitiesParams';

  return {loadingFlag, setter, setterPagination, getter};
}
