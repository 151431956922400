<template>
  <div
    :class="{
      'agent-top-bar-active': isAgent
    }"
    class="chat-main background-chat d-flex flex-column"
  >
    <top-bar />
    <div class="col empty-chat" />
    <start-chat :chat-data="emptyChatContact" />
  </div>
</template>

<script>
import TopBar from "./top-bar.vue";
import StartChat from "./start-chat.vue";

import {mapGetters, mapMutations} from "vuex";

export default {
  components: {
    TopBar,
    StartChat
  },
  computed: {
    ...mapGetters("whatsapp/chat", {
      emptyChatContact: "getEmptyChatContact",
    }),
    ...mapGetters("auth", {
      isAgent: 'isAgent'
    })
  },

  methods: {
    ...mapMutations("whatsapp/chat", ["setEmptyChatContact"]),
  },
};
</script>

<style lang="scss" scoped>
.chat-main {
  height: 100vh;
  width: 100%;
  position: relative;

  &.agent-top-bar-active {
    height: calc(100vh - 56px);
  }
}

.background-chat {
  background-color: #eaf1fb;
  background-image: url("~@/assets/img/whatsapp-background.svg") !important;
  background-size: 10%;
  background-repeat: repeat;
  background-blend-mode: color-burn;
}

.empty-chat {
  background-image: url("~@/assets/img/empty-chat.svg") !important;
  height: calc(100vh - 56px);
  width: 100%;
  background-size: 50% 50%;
  background-repeat: no-repeat;
  background-position: center center;
}
</style>
