import axios from 'axios'
import store from '@/store'

let urlAPI = process.env.VUE_APP_ST12UV;

const axiosConfig = {
  headers: {
    'Access-Control-Allow-Origin': '*',
    'Accept': 'application/json',
    "Content-Type": "application/json"
  }
}

const crmApiAxios = axios.create((axiosConfig))

crmApiAxios.interceptors.request.use((config) => {
  config.url = urlAPI + config.url
  const user = store.getters['auth/getUser'];
  if (user != null && user.api_token !== undefined) {
    config.headers["Authorization"] = "Bearer " + user.api_token;
  }
  if (config.params === undefined) {
    config.params = {}
  }

  return config;
});

crmApiAxios.interceptors.response.use((response) => {
  return response
}, (error) => {
  if (error.response && error.response.status === 401) {
    if (store.getters['auth/getUser'] && !store.getters['auth/getLoadingFlags'].logout)
      store.dispatch('auth/logout');
    return Promise.reject(error);
  }


  if (error.response && error.response.status === 429) {
    store.commit("system/setRequestLimitExceeded", true);
    /**
     * Cancel all the pending requests after the system
     * retrieves an 429 error.
     */
    // source.cancel();
  }
  return Promise.reject(error)
})

export default crmApiAxios
