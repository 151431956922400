<template>
  <action-input
    v-model="emailField"
    @update:model-value="updateField()"
    enable-delete-action
    @delete-action="destroyField()"
    input-type="text"
  />
</template>
<script>
import {mapActions} from "vuex";
import ActionInput from "@crm/pages/opportunity/components/action-input.vue";

export default {
  components: {ActionInput},
  props: {
    email: {
      type: Object,
      required: true
    },
    contactId: {
      type: Number,
      default: null
    },
  },

  data() {
    return {
      emailField: "",
    }
  },

  created() {
    this.emailField = this.email.email
  },

  methods: {
    ...mapActions('crm/contacts', ['updateEmailChannel', 'destroyEmailChannel']),

    updateField() {
      if (!this.emailField) {
        this.emailField = this.email.email
        return
      }

      this.updateEmailChannel({
        channel_id: this.email.id,
        contact_id: this.contactId,
        email: this.emailField,
      })
    },

    destroyField() {
      this.destroyEmailChannel({
        channel_id: this.email.id,
        contact_id: this.contactId
      });
    },
  }
};
</script>
