<template>
  <div class="col-12 col-xl-6">
    <div class="card calls-status">
      <div class="card-header text-capitalize">
        {{ $t("calls") }}
      </div>

      <div class="card-body px-0">
        <div class="row text-center">
          <div
            class="col"
            v-for="(status, i) in statuses"
            :key="i"
          >
            <span class="fs-4"> {{ countStatuses[i] }} </span>
            <br>
            <span
              class="h5 mx-1"
              :style="'color:' + status.color"
            >
              <dot-status
                :color="status.color"
                :size="'8px'"
              />
            </span>
            <small
              :title="status.title"
              class="text-uppercase text-muted overflow-ellipsis"
            >
              {{ $t(status.title) }}
            </small>
          </div>
        </div>
      </div>

      <button
        class="btn d-flex align-items-center mx-auto"
        @click="showCallsChart = !showCallsChart"
      >
        <span
          :class="{
            'fe text-primary me-1': true,
            'fe-chevron-up': !showCallsChart,
            'fe-chevron-down': showCallsChart
          }"
        />
        {{ $t("connection_graph") }}
      </button>

      <div
        class="chart"
        v-if="showCallsChart"
      >
        <campaign-statistics-chart :campaign="campaign" />
      </div>

      <div
        class="table-responsive"
        v-if="lastFinalizedCalls.length > 0"
      >
        <table class="table table-nowrap card-table table-hover">
          <thead>
            <tr>
              <th scope="col">
                {{ $t("phone") }}
              </th>
              <th scope="col">
                Status
              </th>
              <th scope="col">
                {{ $t("talking_capitalize") }}
              </th>
            </tr>
          </thead>
          <tbody style="border-top: none">
            <tr
              v-for="(call, i) in lastFinalizedCalls"
              :key="i"
            >
              <td class="col">
                <span
                  class="mx-2"
                  :style="'color:' + getStatusInfo(call.status).color"
                >
                  <dot-status
                    :color="getStatusInfo(call.status).color"
                    :size="'12px'"
                  />
                </span>
                <span v-if="isBrazil">
                  {{ formatPhoneNumber(call.phone) }}
                </span>
                <span v-else>
                  {{ call.phone }}
                </span>
              </td>
              <td class="col">
                <span
                  :class="getStatusInfo(call.status).icon"
                  :style="'color:' + getStatusInfo(call.status).color"
                />
                {{ call.status == CallStatus.FAILED ? getStatusInfo(call.status).text + " - " + hangupCauses[call.code] : getStatusInfo(call.status).text
                }}
              </td>
              <td class="col">
                {{ timeFormat(call.speaking_time) }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { CallStatus, Utils, formatPhoneNumber } from "@/utils";
import hangupCauses from "@/utils";
import CampaignStatisticsChart from "./campaign-statistics-chart.vue";
import DotStatus from "@/components/dot-status.vue";

export default {
  /**
   *
   */
  data() {
    return {
      statuses: [
        { color: "#f1f2f3", title: "Total" },
        { color: "#babec3", title: "calling" },
        { color: "#b71c1c", title: "amd" },
        { color: "#654112", title: "ivr" },
        { color: "#f2a948", title: "on_hold" },
        { color: "#5cb85c", title: "talking" },
      ],
      countStatuses: [0, 0, 0, 0, 0, 0],
      CallStatus,
      hangupCauses,
      timer: "",
      showCallsChart: false,
    };
  },

  /**
   *
   */
  components: {
    CampaignStatisticsChart,
    DotStatus,
  },

  /**
   *
   */
  mounted() {
    if (this.campaign.ura_limit == 0)
      this.removeUraStatus();

    if (this.campaign)
      this.startUpdateRealTimeCalls({ campaign: this.campaign });
  },

  /**
   *
   */
  unmounted() {
    this.stopUpdateRealTimeCalls();
  },

  /**
   *
   */
  watch: {
    /**
     *
     */
    campaign() {
      if (this.campaign)
        this.startUpdateRealTimeCalls({ campaign: this.campaign });
    },
    /**
     *
     */

    countCallsWorkingStatuses: {
      handler(data) {
        this.countStatuses = [
          data.total,
          data.trying,
          data.amd,
          data.ura,
          data.waiting,
          data.in_progress,
        ];
      },
      deep: true,
    },
  },

  /**
   *
   */
  computed: {
    /**
     *
     */
    ...mapGetters("manager/real-time", {
      countCallsWorkingStatuses: "getCountCallsWorkingStatuses",
      lastFinalizedCalls: "getLastFinalizedCalls",
    }),

    ...mapGetters("auth", {
      user: "getUser",
      isBrazil: "isBrazil",
    }),

  },

  /**
   *
   */
  props: {
    campaign: {
      type: Object,
      default: null,
      required: true,
    },
  },

  /**
   *
   */
  methods: {

    /**
     *
     */
    timeFormat: Utils.timeFormat,

    /**
     *
     */
    mask: Utils.mask,

    /**
     *
     */
    formatPhoneNumber,

    /**
     *
     */
    ...mapActions("manager/real-time", [
      "startUpdateRealTimeCalls",
      "stopUpdateRealTimeCalls",
    ]),

    /**
     *
     */
    getStatusInfo(status) {
      switch (status) {
        case 5:
          return {
            icon: "mx-2 fe fe-minus",
            color: "#989898",
            text: this.$t("not_answer"),
          };
        case 6:
          return {
            icon: "mx-2 fe fe-alert-triangle",
            color: "#DE7912",
            text: this.$t("abandoned_capitalize"),
          };
        case 7:
          return {
            icon: "mx-2 fe fe-phone",
            color: "#5cb85c",
            text: this.$t("connected_general"),
          };
        case 8:
          return {
            icon: "mx-2 fe fe-x",
            color: "#B2B3C1",
            text: this.$t("fail"),
          };
        case 9:
          return {
            icon: "mx-2 fe fe-voicemail",
            color: "#ce4c35",
            text: this.$t("post_call_ivr"),
          };
        case 15:
          return {
            icon: "mx-2 fe fe-phone-off",
            color: "#ce4c35",
            text: this.$t("pre_call_ivr"),
          };
        default:
          return {
            icon: "mx-2 fe fe-help-circle",
            color: "#B2B3C1",
            text: "",
          };
      }
    },

    /**
     *
     */
    removeUraStatus() {
      let newStatusList = Object.assign({}, this.statuses);
      delete newStatusList[3];
      this.statuses = newStatusList;
    },
    /**
     *
     */
    // updateTime() {
    //   for (let id in this.callsPerCampaign[this.campaign.id]) {
    //     this.callsPerCampaign[this.campaign.id][id].time = this.formatHours(
    //       moment
    //         .duration(
    //           moment().diff(
    //             moment.unix(
    //               this.callsPerCampaign[this.campaign.id][id].dialed_time
    //             )
    //           )
    //         )
    //         .asSeconds()
    //     );
    //   }
    // },

    /**
     *
     */
    // formatHours(seconds) {
    //   if (seconds < 1) {
    //     return "00:00:00";
    //   } else
    //     return (
    //       Math.floor(seconds / 3600)
    //         .toString()
    //         .padStart(2, "0") +
    //       ":" +
    //       Math.floor((seconds / 60) % 60)
    //         .toString()
    //         .padStart(2, "0") +
    //       ":" +
    //       Math.floor(seconds % 60)
    //         .toString()
    //         .padStart(2, "0")
    //     );
    // },

    /**
     *
     */
    // mask(val, mask) {
    //   let str = "";
    //   let p = 0;
    //   for (let c of mask) {
    //     if (c == "#") {
    //       str += val[p];
    //       p++;
    //     } else str += c;
    //     if (val.length < p) break;
    //   }
    //   return str;
    // },
    // ...mapActions("manager/campaign", ["findCallsPerCampaign"]),
  },
};
</script>
