<template>
  <div class="card mb-0">
    <div class="card-header">
      <h4 class="mb-0">
        {{ $t("general_capitalize") }}
      </h4>
      <p class="mb-0 tx-text-gray text-small">
        {{ $t("total_uppercase") }}
        <span class="ms-2 fs-4 tx-text-200">{{ metrics.calls_quantity.total }}</span>
      </p>
    </div>
    <div class="card-body">
      <div class="row">
        <div class="col-4">
          <h5 class="mb-0 tx-text-gray fw-normal">
            {{ $t("good") }}
          </h5>
          <span class="my-2  fw-medium fs-2 tx-green-500">{{ calculateRoundedPercentages.good }}%</span>
          <p class="mb-0 fw-medium">
            {{ metrics.calls_quantity.good }} Feedbacks
          </p>
        </div>
        <div class="col-4">
          <h5 class="mb-0 tx-text-gray fw-normal">
            {{ $t("neutral") }}
          </h5>
          <span class="my-2  fw-medium fs-2 tx-yellow-400">{{ calculateRoundedPercentages.neutral }}%</span>
          <p class="mb-0 fw-medium">
            {{ metrics.calls_quantity.neutral }}
            Feedbacks
          </p>
        </div>
        <div class="col-4">
          <h5 class="mb-0 tx-text-gray fw-normal">
            {{ $t("bad") }}
          </h5>
          <span
            class="my-2  fw-medium fs-2"
            style="color:#F23F2C;"
          >{{ calculateRoundedPercentages.bad }}%</span>
          <p class="mb-0 fw-medium">
            {{ metrics.calls_quantity.bad }} Feedbacks
          </p>
        </div>
      </div>
      <ProgressBarMultiple
        :data="[
          {
            title: 'Bom',
            percentage: calculatePercentage(metrics.calls_quantity.good),
            color: '#34C759',
            count: metrics.calls_quantity.good,
            icon: 'fas fa-circle text-small'
          },
          {
            title: 'Neutro',
            percentage: calculatePercentage(metrics.calls_quantity.neutral),
            color: '#FFCC00',
            count: metrics.calls_quantity.neutral,
            icon: 'fas fa-circle text-small'
          },
          {
            title: 'Ruim',
            percentage: calculatePercentage(metrics.calls_quantity.bad),
            color: '#F23F2C',
            count: metrics.calls_quantity.bad,
            icon: 'fas fa-circle text-small'
          }
        ]"
        class="w-100"
        style="margin-top: 16px;"
      />
    </div>
  </div>
</template>

<script>
import ProgressBarMultiple from "@components/progressbar-multiple-lg.vue";

export default {
  name: "CardGeralAvaliation",
  components: { ProgressBarMultiple },
  props: {
    metrics: {
      type: [Object, Array],
      required: true
    }
  },
  methods: {
    calculatePercentage(value) {
      const total = this.metrics.calls_quantity.total;
      const result = total ? (value / total) * 100 : 0;
      return result;
    }
  },

  computed: {
    calculateRoundedPercentages() {
      const { bad, good, neutral, total } = this.metrics.calls_quantity;

      if (total === 0) {
        return {
          bad: 0,
          good: 0,
          neutral: 0,
        };
      }

      let badPercentage = (bad / total) * 100;
      let goodPercentage = (good / total) * 100;
      let neutralPercentage = (neutral / total) * 100;

      let roundedBad = Math.round(badPercentage);
      let roundedGood = Math.round(goodPercentage);
      let roundedNeutral = Math.round(neutralPercentage);

      let roundedPercentages = {
        bad: Math.round(roundedBad),
        good: Math.round(roundedGood),
        neutral: Math.round(roundedNeutral)
      };

      let totalRounded = roundedPercentages.bad + roundedPercentages.good + roundedPercentages.neutral;
      let difference = 100 - totalRounded;

      if (difference !== 0) {
        let adjustments = [
          { name: "bad", value: badPercentage, roundedValue: roundedBad },
          { name: "good", value: goodPercentage, roundedValue: roundedGood },
          { name: "neutral", value: neutralPercentage, roundedValue: roundedNeutral }
        ];

        adjustments.sort((a, b) => b.value - b.roundedValue - a.value - a.roundedValue);
        adjustments[0].roundedValue += difference;
        roundedPercentages[adjustments[0].name] = adjustments[0].roundedValue;
      }

      return {
        bad: roundedPercentages.bad,
        good: roundedPercentages.good,
        neutral: roundedPercentages.neutral,
      };
    }
  }
};
</script>
