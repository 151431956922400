import axiosOmni from "@/plugins/axios-omni";

export default {
  fetchMessageTemplates({ commit, getters }, payload) {
    commit('startLoading', 'fetchMessageTemplates')
    return new Promise((resolve, reject) => {
      const paginationParams = getters['getTemplatePagination']
      if (paginationParams.total_pages && paginationParams.total_pages < payload.current_page) { return }
      axiosOmni.get(`/whatsapp/instance/${ payload.waba_channel_id }/message_templates`, {
        params: payload
      })
        .then((response) => {
          commit('setMessageTemplate', response.data.data)
          commit('setTemplatePagination', response.data.meta.pagination)
          resolve();
        }).catch(() => {
        reject();
      }).finally(() => {
        commit('stopLoading', 'fetchMessageTemplates')
      });
    });
  },

  sendMessageTemplates({ commit }, payload) {
    commit('startLoading', 'sendMessageTemplates')
    return new Promise((resolve, reject) => {
      axiosOmni.post(`/whatsapp/message/send_template`, payload)
        .then((response) => {
          commit("whatsapp/chat/replaceTemporaryMessage", {
            temp_id: payload.template_id,
            message: response.data.data
          }, { root: true });
          resolve();
        }).catch(() => {
        commit("whatsapp/chat/setMessageFailed", payload, { root: true });
        commit('system/setErrorMessage', 'Erro de envio de mensagem. Verificar conta WABA', {root: true})
        reject();
      }).finally(() => {
        commit('stopLoading', 'sendMessageTemplates')
      });
    });
  },
}
