<template>
  <div class="row pt-2">
    <h3 class="fs-3">
      {{ $t("value") }}
    </h3>
  </div>
  <div class="row align-items-start mb-3 py-1">
    <div
      v-for="(item, index) in amountValues"
      :key="index"
      class="col-md-4 d-flex g-3"
    >
      <div
        class="card col-12 d-flex flex-row align-items-center py-4 px-3 my-0 cursor-pointer"
        @click="transactionAmount = item.value"
      >
        <div class="col-10 d-flex flex-column text-start px-2">
          <h3
            class="my-0"
            for="valueCredit"
          >
            {{ item.label }}
          </h3>
        </div>
        <input
          v-model="transactionAmount"
          :data-test="`transactionAmount${item.value}-input`"
          class="form-check-input radio"
          type="radio"
          id="valueCredit"
          :value="item.value"
        >
      </div>
      <div
        v-if="item.value === 'outro' && transactionAmount === 'outro'"
        class="col-12 row align-items-center"
      >
        <div
          class="input-group input-group-merge input-group-reverse"
          :class="{
            'is-invalid':
              otherAmount !== null && otherAmount.replace(',', '.') < 50 || errors.transaction_amount,
          }"
        >
          <input
            v-model="otherAmount"
            data-test="paymentTypeCredits-input"
            v-maska
            data-maska="9 99#,##"
            data-maska-tokens="9:[0-9]:repeated"
            data-maska-reversed
            type="text"
            class="form-control"
            :class="{
              'is-invalid':
                otherAmount !== null && otherAmount.replace(',', '.') < 50 || errors.transaction_amount,
            }"
            id="otherValue"
          >
          <div class="input-group-text">
            R$
          </div>
        </div>
        <div class="invalid-feedback">
          {{ errors.transaction_amount && errors.transaction_amount[0] }}
        </div>
      </div>
    </div>
  </div>
  <p class="my-0">
    {{ $t("value_superior") }}
    {{ paymentType === "pix" ? "49,99" : "300,00" }}
  </p>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: {
    initialValue: {
      type: [String, Number],
      required: true,
    },
    modelValue: {
      type: Object,
      required: true,
    },
    paymentType: {
      type: String,
      default: "pix",
    },
    amountValues: {
      type: Array,
      required: true,
    },
  },
  emits: ["update:modelValue"],
  data() {
    return {
      transactionAmount: this.initialValue,
      otherAmount: null,
    };
  },
  watch: {
    transactionAmount(value) {
      if (value !== null) {
        this.$emit("update:modelValue", value);
      }
    },
    otherAmount(value) {
      if (this.transactionAmount === "outro") {
        this.$emit("update:modelValue", value.replace(/\s/g, "").replace(",", "."));
      }
    },
  },
  computed: {
    ...mapGetters("manager/user_profile", {
      errors: "getErrors",
    }),
  },
};
</script>

<style lang="scss" scoped>
.g-3 {
  row-gap: 1rem;
  column-gap: 1rem;
}

.pe-5-1 {
  padding-right: $whatsapp-spacing-4;
}
</style>
