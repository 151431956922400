export default {
  getLoadingFlags: state => state.loadingFlags,
  getErrors: state => state.errors,
  getOpportunities: state => state.opportunities,
  getOpportunitiesPagination: state => state.opportunitiesPagination,
  getOpportunitiesLoading: state => state.opportunitiesLoading,
  getOpportunity: state => state.opportunity,
  getFormInputs: (state) => state.formInputs.CRM,
  getFormInputsRD: (state) => state.formInputs.rd_station,
  getOpportunityLogs: (state) => state.opportunityLogs,
  getCurrentTabTimeline: (state) => state.currentTabTimeline,
  getOpportunityFilters: (state) => state.opportunityFilters,
  getCardOpportunityId: (state) => state.cardOpportunityId,
  getContactsList: (state) => state.contactsList
}
