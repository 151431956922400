import { AgentStatus, Utils } from "@/utils";
import timer from "@/plugins/timer";
import axios from "axios";
import store from "@/store";

export default {
  connect({ commit }) {
    commit("clearErrors");
    commit("startLoading");
    return new Promise((resolve, reject) => {
      axios
        .post("/agent/connect_novo")
        .then(() => {
          resolve();
        })
        .catch(response => {
          reject({ message: response.response.data.detail });
        })
        .finally(() => {
          commit("stopLoading");
        });
    });
  },

  /**
   * start spy agent
   */
  startAgentSpy({ commit, getters }, payload) {
    commit("startLoadingFlag", "startAgentSpy");
    return new Promise((resolve, reject) => {
      axios
        .post(`/spy/${payload.agent_id}/start`, getters.getSpyParams)
        .then(() => {
          resolve();
        })
        .catch(response => {
          switch (response.status) {
            case 404:
              commit("system/setErrorMessage", "errors_message.agent_spy_404", { root: true });
              break;
            case 409:
              commit(
                "system/setErrorMessage",
                "errors_message.agent_spy_409",
                { root: true }
              );
              break;
            default:
              commit("system/setErrorMessage", "errors_message.agent_spy", { root: true });
          }
          reject();
          commit("stopLoadingFlag", "startAgentSpy");
        });
    });
  },

  /**
   * stop spy agent
   */
  stopAgentSpy({ commit }) {
    commit("startLoadingFlag", "stopAgentSpy");
    return new Promise((resolve, reject) => {
      axios
        .post("/spy/stop")
        .then(() => {
          resolve();
          commit("stopLoadingFlag", "stopAgentSpy");
        })
        .catch(response => {
          switch (response.status) {
            case 404:
              commit("Ramal não encontrado.", { root: true });
              break;
            default:
              commit("system/setErrorMessage", "errors_message.end_agent_spy", { root: true });
          }
          reject();
          commit("stopLoadingFlag", "stopAgentSpy");
        });
    });
  },

  enterManual({ commit }) {
    commit("startLoading");
    commit("clearErrors");
    commit("setLoadingContext", "enter-manual");
    commit("startLoadingFlag", "enterManual");
    return new Promise((resolve, reject) => {
      axios
        .post("/agent/manual_call/enter")
        .then(() => resolve())
        .catch(response => {
          commit("stopLoading");
          commit("system/setErrorMessage", response.response.data, { root: true });
          response.response.status == 422 &&
            response.response.data.errors &&
            commit("setErrors", response.response.data.errors);
          reject();
        });
    });
  },

  exitManual({ commit }) {
    commit("startLoadingFlag", "exitManual");

    return new Promise((resolve, reject) => {
      axios
        .post("/agent/manual_call/exit")
        .then(() => resolve())
        .catch(response => {
          commit("system/setErrorMessage", response.response.data, { root: true });
          reject();
        })
        .finally(() => commit("stopLoadingFlag", "exitManual"));
    });
  },

  enterManualCallACW({ commit }) {
    commit("startLoadingFlag", "enterManualCallACW");

    return new Promise((resolve, reject) => {
      axios
        .post("/agent/manual_call_acw/enter")
        .then(() => resolve())
        .catch(response => {
          commit("system/setErrorMessage", response.response.data, { root: true });
          commit("stopLoadingFlag", "enterManualCallACW");
          reject();
      });
    })
  },

  exitManualCallACW({ commit }) {
    commit("startLoadingFlag", "exitManualCallACW");

    return new Promise((resolve, reject) => {
      axios
        .post("/agent/manual_call_acw/exit")
        .then(() => resolve())
        .catch(response => {
          commit("system/setErrorMessage", response.response.data, { root: true });
          commit("stopLoadingFlag", "exitManualCallACW");
          reject();
        });
    });
  },

  showConfirmationMessage({ commit, state }, message) {
    return new Promise(resolve => {
      commit("setConfirmationMessage", message);
      commit("setConfirmationMessageResponse", "");

      const unsubscribe = store.watch(
        () => state.confirmationMessageResponse,
        newResponse => {
          if (newResponse === "confirm") {
            resolve({
              isConfirmed: true
            });
            commit("setConfirmationMessage", null);
            unsubscribe();
          } else if (newResponse === "cancel" || newResponse === "close") {
            resolve({
              isConfirmed: false
            });
            commit("setConfirmationMessage", null);
            unsubscribe();
          }
        }
      );
    });
  },

  handleAgentStatusAfterManualCallACW({ commit, getters, rootGetters }) {
    if (getters.isManualTimeLimited) {
      commit("setManualTimeLimited", true);
      commit("setManualTimeout", rootGetters["agent/agent/getCampaign"].exit_manual_mode);

      let options = { startValues: { seconds: rootGetters["agent/agent/getCampaign"].exit_manual_mode } };
      options.countdown = true;

      commit("setTimerTargetAchieved", false);
      options.target = { seconds: 0 };

      commit("setTimeAsString", Utils.timeFormat(rootGetters["agent/agent/getCampaign"].exit_manual_mode));
      timer.stop();
      timer.start(options);
      commit("setStatus", AgentStatus.IDLE);
    } else {
      commit("setTimeAsString", Utils.timeFormat(0));
      timer.stop();
      timer.start({
        startValues: {
          seconds: 0
        },
        countdown: false
      });
      commit("setStatus", AgentStatus.IDLE);
    }
  },

  fetchUserIp({ commit }) {
    return new Promise((resolve, reject) => {
      fetch('https://api.ipify.org?format=json')
      .catch(() => {
        reject();
      })
      .then(response => response.json())
      .then(data => {
        commit("setUserIp", data.ip);
        resolve();
      })
    });
  },

  stopInterval({ commit }, payload) {
    commit("startLoadingFlag", "stopInterval");
    const params = payload?.agent_id ? { agent_id: payload.agent_id } : {};

    return new Promise((resolve, reject) => {
      axios
        .post("/agent/work_break/exit", params)
        .then(() => resolve())
        .catch(response => {
          commit("system/setErrorMessage", response.response.data, { root: true });
          reject();
        })
        .finally(() => commit("stopLoadingFlag", "stopInterval"));
    });
  },

  setConfirmationMessage({ commit }, message) {
    commit("setConfirmationMessage", message);
  },

  setConfirmationMessageResponse({ commit }, message) {
    commit("setConfirmationMessageResponse", message);
  },

  setSelectedAgentToSpy({ commit }, message) {
    commit("setSelectedAgentToSpy", message);
  },

  setShowSpy({ commit }, message) {
    commit("setShowSpy", message);
  },

  setInfoMessage({ commit }, message) {
    commit("setInfoMessage", message);
  },

  setTimeMessage({ commit }, message) {
    commit("setTimeMessage", message);
  },

  setCustomizedTitleMessage({ commit }, message) {
    commit("setCustomizedTitleMessage", message);
  },

  toggleShowTransferCard({ commit }) {
    commit("toggleShowTransferCard");
  },

  setCurrentAudioUrl({ commit }, audio) {
    commit("setCurrentAudioUrl", audio);
  },
  setAgentInConsult({ commit }, agentInConsult) {
    commit("setAgentInConsult", agentInConsult);
  },

  setConsultingAgentName({ commit }, consultingAgentName) {
    commit("setConsultingAgentName", consultingAgentName);
  }
};
