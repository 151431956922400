export default {
  loadingFlags: {
    createOpportunity: false,
    fetchFunnelOpportunities: false,
    fetchOpportunityLogs: false,
    createChangeStatus: false,
    createChangeOwner: false,
    updateOpportunity: false,
    createContactInOpportunity: false,
    fetchOpportunity: false,
    fetchFormInputs: false,
    qualifyOpportunity: false,
    fetchUsersByTeam: false
  },
  errors: [],
  opportunities: {},
  opportunitiesPagination: {},
  opportunitiesLoading: {},
  currentTabTimeline: "",
  opportunity: null,
  opportunityLogs: [],
  opportunityFilters: {
    search: null,
    owner_id: [],
    status: "active"
  },
  formInputs: {
    rd_station: [],
    CRM: []
  },
  cardOpportunityId: null,
  contactsList: {
    agent: [],
    supervisor: [],
    manager: []
  }
}
