<template>
  <nav
    :class="{
      'sidebar navbar navbar-vertical navbar-expand-md navbar-light': true,
      expand: expand,
      'fixed-start': !expand,
      'navbar-vertical-sm': expand && !expanded
    }"
    @mouseover="expanded = true"
    @mouseleave="expanded = false"
  >
    <div class="container-fluid">
      <div class="collapse navbar-collapse">
        <div v-show="expanded || !expand">
          <router-link
            class="navbar-brand"
            to="/"
          >
            <img
              src="@assets/img/logo.svg"
              class="navbar-brand-img mx-auto"
            >
          </router-link>
          <hr
            class="navbar-divider d-none d-md-block mt-0 mb-3"
            v-if="expand"
          >
          <ul
            class="navbar-nav b2"
            ref="expansive-sidebar"
          >
            <template
              v-for="(item, i) in menu"
              :key="i"
            >
              <template v-if="user && item.allowedLevels.includes(user.settings.access)">
                <li
                  class="nav-item"
                  v-if="item.type == 'link'"
                >
                  <router-link
                    :class="{
                      'nav-link': true,
                      active: item.active_name == getActiveRouteName()
                    }"
                    :to="item.path"
                  >
                    <i :class="item.icon" /> {{ item.title }}
                  </router-link>
                </li>
                <li
                  v-else-if="item.type == 'group'"
                  class="nav-item"
                >
                  <a
                    :class="{ 'nav-link collapsed': true }"
                    :href="'#' + item.id"
                    data-bs-toggle="collapse"
                    role="button"
                    aria-expanded="false"
                    aria-controls="sidebarPages"
                  >
                    <i
                      :class="item.icon"
                      @click="$router.push({ path: item.path })"
                      v-if="item.path"
                    />
                    <i
                      style="width: 28px"
                      :class="item.icon"
                      v-else
                    />
                    <span
                      @click="$router.push({ path: item.path })"
                      v-if="item.path"
                    >{{ item.title }} </span>
                    <span v-else>{{ item.title }} </span>
                  </a>
                  <div
                    class="collapse"
                    :id="item.id"
                  >
                    <ul class="nav nav-sm flex-column overflow-ellipsis">
                      <li class="nav-item">
                        <router-link
                          v-for="(subitem, j) of item.children"
                          :key="j"
                          :to="subitem.path"
                          :class="{
                            'nav-link': true,
                            active: subitem.active_name == getActiveRouteName()
                          }"
                        >
                          {{ subitem.title }}
                        </router-link>
                      </li>
                    </ul>
                  </div>
                </li>
                <hr
                  class="navbar-divider mx-4"
                  v-else-if="item.type == 'separator'"
                >
              </template>
            </template>
          </ul>
        </div>
        <div class="mt-auto" />
      </div>

      <div
        class="navbar-user d-none d-md-flex"
        id="sidebarUser"
      >
        <!-- Dropup -->
        <div class="dropup">
          <!-- Toggle -->
          <button
            class="btn dropdown-toggle w-100 d-flex align-items-center justify-content-between px-0"
            type="button"
            data-bs-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            {{ user ? user.name : "" }}
          </button>

          <!-- Menu -->
          <div
            class="dropdown-menu"
            aria-labelledby="sidebarIconCopy"
          >
            <a
              class="btn dropdown-item"
              style="border-radius: 10px"
              @click="_logout()"
              href="#"
            >Sair</a>
          </div>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import menu from "@admin/pages/menu-config.js";
import { mapActions, mapGetters } from "vuex";
import { Collapse } from "bootstrap";

export default {
  data() {
    return {
      expanded: false,
      menu: menu,
      collapses: {}
    };
  },

  props: {
    expand: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    ...mapGetters("auth", {
      user: "getUser"
    })
  },

  mounted() {
    this.initCollapses();
  },

  methods: {
    ...mapActions("auth", ["logout"]),

    initCollapses() {
      let elements = this.$refs["expansive-sidebar"].querySelectorAll(".navbar-nav .collapse");
      for (let element of elements) {
        this.collapses[element.id] = new Collapse(element, {
          toggle: false
        });
        element.addEventListener("show.bs.collapse", e => {
          for (let id in this.collapses) if (id != e.target.id) this.collapses[id].hide();
        });
      }
    },

    getActiveRouteName() {
      const lastIndex = this.$route.matched.length - 1;
      return this.$route.matched && this.$route.matched[lastIndex] ? this.$route.matched[lastIndex].name : "";
    },

    _logout() {
      this.logout().then(() => {
        this.$router.push({
          name: "login"
        });
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.sidebar.navbar {
  &.expand {
    cursor: pointer;
    z-index: 999;
    border-width: 0 1px 0 0 !important;
    border-top-width: 0px !important;
    border-right-width: 1px !important;
    border-bottom-width: 0px !important;
    border-left-width: 0px !important;

    &.navbar-expand-md .navbar-brand-img {
      min-height: 3rem !important;
      width: auto !important;
    }

    &.navbar-expand-md .navbar-brand {
      padding-bottom: 1.20312rem !important;
      padding-top: 0.45312rem !important;
    }

    & ~ .main-content {
      padding-left: 66px;
    }
  }

  &.navbar-vertical.navbar-expand-md {
    transition: max-width 0.1s !important;
  }

  overflow: hidden !important;
}

.overflow-ellipsis {
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}

.fa-light {
  font-size: 1.125rem;
  margin-right: 0.6rem;
}
</style>
