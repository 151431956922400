<template>
  <div class="row mb-3">
    <div class="col-12">
      <a
        href="#"
        @click.prevent="showMoreDetails = !showMoreDetails"
      >
        <small>
          <span v-if="!showMoreDetails">+ </span>
          <span v-else>- </span>
          {{ $t('details') }}
        </small>
      </a>
    </div>
  </div>
  <div
    class="row"
    v-if="showMoreDetails"
  >
    <div class="col-12 col-xl-6 col-lg-6 mb-3">
      <small class="text-muted">{{ $t('call_id') }}</small><br>
      <small>{{ call.id }}</small>
    </div>

    <div class="col-12 col-xl-6 col-lg-6 mb-3">
      <small class="text-muted">{{ $t('mailing_list') }}</small><br>
      <small>{{ call.list }}</small>
    </div>

    <div class="col-12 col-xl-6 col-lg-6 mb-3">
      <small class="text-muted">{{ $t("call_date_format") }}</small><br>
      <small>{{ call.call_date_rfc3339 }}</small>
    </div>

    <div class="col-12 col-xl-6 col-lg-6 mb-3">
      <small class="text-muted">{{ $t('receptive_queue') }}</small><br>
      <small>{{ call.queue_name }}</small>
    </div>

    <div class="col-12 col-xl-6 col-lg-6 mb-3">
      <small class="text-muted">{{ $t('ivr_name') }}</small><br>
      <small>{{ call.ivr_name }}</small>
    </div>

    <div class="col-12 col-xl-6 col-lg-6 mb-3">
      <small class="text-muted">{{ $t('receptive_name') }}</small><br>
      <small>{{ call.receptive_name }}</small>
    </div>

    <div class="col-12 col-xl-6 col-lg-6 mb-3">
      <small class="text-muted">{{ $t('receptive_number') }}</small><br>
      <small>{{ call.receptive_phone }}</small>
    </div>

    <div class="col-12 col-xl-6 col-lg-6 mb-3">
      <small class="text-muted">{{ $t('ivr_time') }}</small><br>
      <small>{{ call.ivr_time }}</small>
    </div>

    <div class="col-12 col-xl-6 col-lg-6 mb-3">
      <small class="text-muted">{{ $t("amd_time") }}</small><br>
      <small>{{ call.amd_time }}</small>
    </div>

    <div class="col-12 col-xl-6 col-lg-6 mb-3">
      <small class="text-muted">{{ $t('talking_to_agent_time') }}</small><br>
      <small>{{ call.speaking_with_agent_time }}</small>
    </div>

    <div class="col-12 col-xl-6 col-lg-6 mb-3">
      <small class="text-muted">{{ $t('ivr_after_call_time') }}</small><br>
      <small>{{ call.ivr_after_call_time }}</small>
    </div>

    <div class="col-12 mb-3">
      <small class="text-muted">{{ $t('IVR_after_call') }}</small><br>
      <div
        v-for="(element, key, index) in call.ivr_after_call"
        :key="index"
      >
        <div
          v-if="key !== 'id'"
          class="row"
        >
          <div
            v-if="key === 'name'"
            class="col-12 col-md-6"
          >
            <small>
              {{ $t('name_capitalize') }}: {{ element != "null" ? element: "null" }}
            </small>
          </div>
          <div
            v-if="key === 'keys_pressed'"
            class="col-12 col-md-6"
          >
            <small>
              {{ $t('Key_pressed') }}: {{ element != "null" ? element: "null" }}
            </small>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showMoreDetails: false,
    }
  },

  props: {
    /**
     *
     */
    call: {
      type: Object,
      default: () => null,
    },
  }
}
</script>

<style>

</style>
