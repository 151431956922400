export default {
    startLoadingFlag: (state, flag) => state.loadingFlags[flag] = true,
    stopLoadingFlag: (state, flag) => state.loadingFlags[flag] = false,
    setErrorFlags: (state, { flag, errors }) => state.errorFlags[flag] = errors,
    setList: (state, list) => state.list = list,
    setRegister: (state, register) => state.register = register,
    clearList: (state) => state.list = [],
    setAlert: (state, alert) => {
        state.alert = alert
    },
    setErrors: (state, errors) => { state.errors = errors;  },
    clearErrors: (state, flag) => state.errorFlags[flag] = {},
    setFilters: (state, filters) => {
        state.filters = filters;
    },
    setPagination: (state, pagination) => {
        state.pagination = pagination;
    },
    setCurrentPage: (state, page) => {
        state.pagination.current_page = page;
    },
    setDataSelect: (state, dataSelect) => state.dataSelect = dataSelect,
}
