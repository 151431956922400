<template>
  <div
    ref="selectContainer"
    :class="{'is-invalid': errors[errorLabel] }"
    :style="`height: ${selectHeight}; width: ${selectWidth};`"
    class="position-relative"
  >
    <div
      ref="select"
      :style="`padding: ${padding};`"
      :class="[
        isOpenedSelect ? 'opened-select' : 'closed-select',
        {'cursor-pointer': !disabled },
      ]"
      class="d-flex bg-white justify-content-between align-items-center gap-3"
      @click="toggleSelect"
    >
      <span
        v-if="prefix"
        class="fs-6"
      >{{ prefix }}</span>
      <template v-if="!isOpenedSelect && multiselect && hasTags && selectedItem.length && !disabled">
        <div
          ref="tagContainer"
          class="d-flex gap-2 tag-container"
        >
          <select-tag
            v-for="item in selectedItem"
            :key="item.id"
            :label="label"
            :option="item"
            @remove="$emit('remove', item.id)"
          />
        </div>
      </template>
      <span
        :style="`font-size: ${fontSize};`"
        v-else-if="!isOpenedSelect"
        :id="selectTitleId"
        :class="[
          disabled ? 'tx-gray-300' : 'tx-text-200',
          {'tx-text-gray': !selectedItem}
        ]"
        class="m-0 text-display w-100"
      >
        {{ showSelectedItem }}
      </span>
      <div
        v-else
        ref="inputSearch"
        class="d-flex align-items-center gap-3 w-100"
      >
        <i class="fa-regular fa-search" />
        <input
          autofocus
          class="w-100 border-0 py-1"
          style="z-index: 1000; outline: 0;"
          type="text"
          @click="handleInputClick"
          @input="$emit('updateTerm', $event.target.value)"
        >
      </div>
      <i
        ref="selectChevron"
        :class="disabled ? 'tx-gray-300' : 'tx-text-gray cursor-pointer'"
        class="fa-regular fa-chevron-down fs-3"
      />
    </div>
    <div
      v-show="isOpenedSelect"
      ref="optionBox"
      :class="{'opened-select-box': isOpenedSelect}"
      class="position-absolute bg-white"
      style="z-index: 2000;"
    >
      <slot
        ref="optionContent"
        name="opened-select-content"
      />
    </div>
  </div>
  <div class="invalid-feedback">
    {{ errors[errorLabel] && errors[errorLabel][0] }}
  </div>
</template>

<script>
import {newUUID} from "jssip/lib/Utils";
import SelectTag from "@components/select/base/select-tag.vue";

export default {
  name: "BaseSelect",
  data() {
    return {
      isOpenedSelect: false,
      selectTitleId: newUUID(),
    }
  },

  components: {
    SelectTag,
  },

  props: {
    selectHeight: {
      type: String,
      required: true,
    },

    selectedItem: {
      type: Array,
      required: true,
    },

    selectWidth: {
      type: String,
      required: true,
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    label: {
      type: String,
      required: true,
    },

    placeholder: {
      type: String,
      required: true,
    },

    errors: {
      type: Object,
      required: true,
    },

    errorLabel: {
      type: String,
      required: true
    },

    prefix: {
      type: String,
      required: true,
    },

    multiselect: {
      type: Boolean,
      required: true,
    },

    hasTags: {
      type: Boolean,
      required: true,
    },

    fontSize: {
      type: String,
      default: '16px'
    },

    padding: {
      type: String,
      default: '8px 16px'
    }
  },

  emits: ['updateTerm', 'remove'],

  mounted() {
    document.addEventListener("click", this.handleClickOutside);
    window.addEventListener("resize", this.adjustOptionBoxWidth);
    this.adjustOptionBoxWidth();
  },

  beforeUnmount() {
    document.removeEventListener("click", this.handleClickOutside);
  },

  updated() {
    this.adjustOptionBoxWidth();
  },

  computed: {
    showSelectedItem() {
      if (this.selectedItem && this.selectedItem.length) {
        return this.selectedItem;
      }

      return this.placeholder;
    }
  },

  methods: {
    toggleSelect() {
      if (this.disabled) return;
      this.isOpenedSelect = !this.isOpenedSelect;
      this.rotateSelectChevron()
    },

    adjustOptionBoxWidth() {
      if (this.$refs.optionBox && this.$refs.selectContainer) {
        this.$refs.optionBox.style.width = this.$refs.selectContainer.offsetWidth + 'px';
      }
    },

    rotateSelectChevron() {
      if (this.isOpenedSelect) {
        this.$refs.selectChevron.classList.add('rotate-up')
        this.$refs.selectChevron.classList.remove('rotate-down')
      } else {
        this.$refs.selectChevron.classList.remove('rotate-up')
        this.$refs.selectChevron.classList.add('rotate-down')
        this.$emit('updateTerm', '');
      }
    },

    handleClickOutside(event) {
      const selectContainerEl = this.$refs.selectContainer;
      const selectEl = this.$refs.select;
      const inputEl = this.$refs.inputSearch;

      if (
          !this.isOpenedSelect ||
          (this.multiselect && selectContainerEl && selectContainerEl.innerHTML.includes(event.target.innerHTML))
      ) return;

      if (
          selectEl &&
          inputEl &&
          !selectEl.contains(event.target) &&
          !inputEl.contains(event.target) &&
          !event.target.id.includes(this.selectTitleId)
      ) {
        this.isOpenedSelect = false;
        this.rotateSelectChevron();
      }
    },

    handleInputClick(event) {
      event.stopPropagation();
    },
  },

  watch: {
    '$refs.selectContainer.offsetWidth': {
      handler(newVal) {
        if (newVal) {
          this.adjustOptionBoxWidth()
        }
      },
      immediate: true
    }
  }
}
</script>

<style lang="scss" scoped>
.opened-select, .closed-select, .opened-select-box {
  border: 1px solid $color-gray-blue-300;
}

.opened-select, .closed-select {
  padding: 8px 16px;
}

.opened-select {
  border-radius: 10px 10px 0 0;
  border-bottom: none;
}

.closed-select {
  border-radius: 10px;
}

.opened-select-box {
  border-radius: 0 0 10px 10px;
  border-top: none;
  padding: 8px 8px 8px 16px;
}

.fa-regular {
  transition: transform 0.3s ease;
}

.rotate-up {
  transform: rotate(180deg);
}

.rotate-down {
  transform: rotate(0deg);
}

.is-invalid {
  .opened-select, .closed-select, .opened-select-box {
    border-color: $color-red-300;
  }
}

.tag-container {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%;
}
</style>