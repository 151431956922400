<template>
  <div
    :id="id"
    :ref="`componentRef${id}`"
    :style="{ 'z-index': zIndex }"
    class="position-relative mb-0  shadow-none"
    style="height: 40px;"
  >
    <div
      class="card position-absolute w-100 mb-0 shadow-none"
      :class="{ 'border-error': error }"
    >
      <div
        :class="{ 'p-0 pe-1': showSearch }"
        class="card-header border-bottom-0"
        style="height: 40px; padding: 12px 16px !important;"
      >
        <div :disabled="animation">
          <a
            v-show="!showSearch"
            aria-expanded="false"
            class="justify-content-between align-items-center cursor-pointer tx-text-200"
            :class="{
              'd-flex': !showSearch
            }"
            data-bs-toggle="collapse"
            @click="toggle"
          >
            <span
              :class="{
                'text-muted': disabled
              }"
            >
              {{ optionSelected || currentOption || initialText || $t('select') }}
            </span>
            <i
              class="far fa-chevron-down m-0 fs-5"
              :class="{
                'text-muted': disabled
              }"
            />
          </a>
          <div
            v-show="showSearch"
            class="input-group"
          >
            <span class="input-group-text border-0  bg-white rounded-0 rounded-top p-0">
              <i class="far fa-search m-0 fs-5" />
            </span>
            <input
              v-model="termValue"
              class="form-control border-0"
              :placeholder="$t('type_here_placeholder')"
              type="text"
            >
            <a
              :href="`#toggleCollapse${id}`"
              class="input-group-text border-0 rounded-0 rounded-top  bg-white cursor-pointer p-0"
              data-bs-toggle="collapse"
              @click="toggle"
            >
              <i class="far fa-chevron-up m-0 fs-5" />
            </a>
          </div>
        </div>
      </div>

      <div
        :id="`toggleCollapse${id}`"
        :ref="`toggleCollapse${id}`"
        class="collapse"
      >
        <div
          class="card-body overflow-auto py-2"
          style="max-height: 240px;padding: 6px 16px !important;"
        >
          <ul class="p-0 m-0 transition list-unstyled">
            <template v-if="filterList.length">
              <li
                v-for="(item, index) in filterList"
                :key="index"
                :class="{ 'list-hover': activeHover }"
                :href="`#toggleCollapse${id}`"
                class="m-0 list"
                data-bs-toggle="collapse"
                @click="selected(item)"
                @mouseenter="activeHover = true"
                @mouseleave="activeHover = false"
              >
                {{ item[label] }}
              </li>
            </template>
            <li
              v-else
              class="text-center w-100 pb-2"
            >
              {{ message }}
            </li>
          </ul>
          <div
            v-if="notFound"
            class="text-center w-100 pb-2"
          >
            {{ message }}
          </div>
        </div>
      </div>
    </div>
    <div
      class=""
      style="padding-top: 40px"
    >
      <small class="text-danger"> {{ error }}</small>
    </div>
  </div>
</template>

<script>
import { Collapse } from "bootstrap";

export default {
  props: {
    id: {
      type: String,
      required: true
    },
    listArray: {
      type: Array,
      required: true
    },
    label: {
      type: String,
      required: true
    },
    message: {
      type: String,
      default: "Nenhum resultado encontrado"
    },
    currentOption: {
      type: [String, Number],
      default: ""
    },
    initialText: {
      type: String,
      default: "Selecione"
    },
    zIndex: {
      type: [Number, String],
      default: 5
    },
    error: {
      type: String,
      default: ""
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  emits: ["selectedOption"],
  data() {
    return {
      showSearch: false,
      animation: false,
      activeHover: false,
      termValue: "",
      optionSelected: "",
      notFound: false,
      collapse: null
    };
  },
  computed: {
    filterList() {
      return this.listArray.filter(item => item[this.label].toLowerCase().includes(this.termValue.toLowerCase()));
    }
  },
  methods: {
    toggle() {
      if (!this.animation && !this.disabled) {
        this.animation = true;
        this.showSearch = !this.showSearch;
        this.showSearch ? this.collapse.show() : this.collapse.hide();
        setTimeout(() => {
          this.animation = false;
        }, 400);
      }
    },
    selected(item) {
      this.$emit("selectedOption", item);
      this.optionSelected = item[this.label];
      this.showSearch = false;
    },
    handleClickOutside(event) {
      const isClickInsideComponent = this.$refs[`componentRef${this.id}`].contains(event.target);
      if (this.showSearch && !isClickInsideComponent) {
        this.toggle();
        this.collapse.hide();
      }
    },
    closeCollapse() {
      this.optionSelected = "";
      this.collapse.hide();
    }
  },
  watch: {
    currentOption: {
      handler() {
        this.optionSelected = this.currentOption;
      },
      deep: true
    }
  },
  mounted() {
    this.collapse = new Collapse(this.$refs[`toggleCollapse${this.id}`], {
      toggle: false
    });
    document.addEventListener("click", this.handleClickOutside);
  },
  unmounted() {
    document.removeEventListener("click", this.handleClickOutside);
  }
};
</script>
<style
  lang="scss"
  scoped
>
.list-hover:hover {
  background-color: $color-gray-blue-300;
  border-radius: 10px;
}

.card {
  border-color: #d2ddec !important;
}

.list {
  line-height: 32px;
  padding: 0 16px !important;
}
.border-error {
  border-color: $color-red-300 !important;
}
</style>
