<template>
  <div
    @mouseenter="toggleHovered(true)"
    @mouseleave="toggleHovered(false)"
    @focusin="toggleHovered(true)"
    @focusout="toggleHovered(false)"
    class="position-relative rounded"
  >
    <button
      :disabled="!!modelValue || readonly"
      @click="toggleEdit(true)"
      v-if="!edit && modelValue && !isCreation"
      class="d-block w-100 text-start custom-input border-0 rounded item-hover"
      :class="{'tx-text-200': model , 'tx-text-gray': !model, customClass, 'is-invalid': errors.length}"
    >
      {{ model || placeholder }}
    </button>
    <template v-else>
      <input-date
        v-if="inputType === 'date'"
        :custom-class="errors.length? 'custom-input is-invalid item-hover' : 'item-hover'"
        :preset="model"
        @update="(val) => updateModel(val)"
      />
      <infinite-textarea
        @lines-count="updateActionButtons"
        v-else-if="inputType === 'textarea'"
        :class="{customClass, 'is-invalid': errors.length}"
        class="item-hover"
        ref="textarea"
        ref-input="input"
        v-model="model"
        @blur="handleBlur"
      />
      <input
        v-else
        ref="input"
        :type="inputType"
        v-maska
        :data-maska="mask"
        class="form-control custom-input rounded tx-text-200 border-0 rounded item-hover"
        :class="{customClass, 'is-invalid': errors.length}"
        :placeholder="placeholder"
        v-model="model"
        @blur="handleBlur"
      >
    </template>

    <div
      v-if="!readonly"
      style="right: 0.5rem; z-index: 10"
      class="position-absolute top-50 translate-middle-y"
      :style="resized ? 'padding: 0.5rem 0' : ''"
      :class="{
        'd-flex': resized,
        'flex-column': resized,
        'justify-content-between': resized,
        'h-100': resized
      }"
    >
      <button
        v-if="edit"
        class="btn btn-action p-0 py-1 px-2 fs-6"
        @click="cancelEdit"
      >
        <i class="fa-regular fa-xmark" />
      </button>
      <button
        v-if="edit && !isCreation"
        class="btn btn-action p-0 py-1 px-2 fs-6"
        @click="updateModelValue()"
      >
        <i class="fa-regular fa-check" />
      </button>
      <button
        v-if="hovered && modelValue && !edit && !isCreation"
        class="btn btn-action p-0 py-1 px-2 fs-6"
        @click="toggleEdit(true)"
      >
        <i class="fa-regular fa-pen" />
      </button>
      <button
        v-if="enableDeleteAction && hovered && !edit && !isCreation"
        class="btn btn-action p-0 py-1 px-2 fs-6"
        @click="$emit('deleteAction')"
      >
        <i class="fa-regular fa-trash" />
      </button>
    </div>
  </div>
</template>
<script>
import InputDate from "@components/input-date.vue";
import InfiniteTextarea from "@crm/pages/opportunity/components/infinite-textarea.vue";
import {Mask} from "maska";

export default {
  name: "ActionInput",
  components: {InfiniteTextarea, InputDate},
  emits: ['update:modelValue', 'deleteAction'],
  props: {
    inputType: {
      type: String,
      default: "text"
    },
    customClass: {
      type: String,
      default: null
    },
    placeholder: {
      type: String,
      default: null
    },

    modelValue: {
      type: [String, Number],
      required: true
    },
    enableDeleteAction: {
      type: Boolean,
      default: false
    },

    errors: {
      type: Array,
      default: () => []
    },

    mask: {
      type: String,
      default: null
    },

    readonly: {
      type: Boolean,
      default: false,
    },

    isCreation: {
      type: Boolean,
      default: false
    }
  },

  watch: {
    modelValue: {
      handler(val) {
        this.model = val;
      },
      immediate: true
    }
  },

  data() {
    return {
      edit: false,
      hovered: false,
      model: null,
      resized: false,
    }
  },

  mounted() {
    if (this.mask) {
      this.applyMask();
    }
  },

  methods: {
    cancelEdit() {
      this.model = this.modelValue;
      this.applyMask()
      this.toggleEdit(false)
    },

    updateModelValue() {
      this.$emit('update:modelValue', this.model);
      this.toggleEdit(false)
    },

    updateModel(val) {
      this.model = val;
      if (this.isCreation) {
        this.$emit('update:modelValue', this.model);
      }
    },

    handleBlur() {
      if (!this.edit) {
        this.updateModelValue()
      }
    },

    toggleEdit(toggle) {
      this.edit = toggle;

      if (toggle && this.inputType !== "date") {
        this.$nextTick(() => {
          if (this.inputType === "textarea") {
            this.$refs.textarea.$refs.input.focus();
            return
          }

          this.$refs.input.focus();
        })
      }
    },

    toggleHovered(toggle) {
      this.hovered = toggle;
    },

    updateActionButtons(val) {
      this.resized = val > 2
    },

    applyMask() {
      if (!this.mask) {
        return
      }

      const patterns = JSON.parse(this.mask.replace(/'/g, '"'))

      const mask = new Mask({
        mask: patterns
      });

      this.model = mask.masked(this.model)
    },
  },
}
</script>

<style lang="scss" scoped>
.custom-input {
  background-color: transparent;
  appearance: none;
  padding: 0.5rem 3.625rem 0.5rem 0.75rem;
  word-break: break-all;
  outline: none;

  &.is-invalid {
    outline: 1px solid $color-red-300;
  }

  &::-webkit-inner-spin-button,
  &::-webkit-calendar-picker-indicator {
    display: none;
    -webkit-appearance: none;
  }

  &:focus,
  &:focus-visible {
    background-color: $color-gray-blue-300;
  }

  &::placeholder {
    color: $color-text-gray;
    opacity: 1;
  }

  &::-ms-input-placeholder {
    color: $color-text-gray;
  }
}

.item-hover {
  &:hover {
    background-color: $color-gray-blue-200;
  }
}

.btn-action {
  color: $color-text-gray;

  &:hover,
  &:focus,
  &:focus-visible {
    color: $color-text-100;
  }
}
</style>