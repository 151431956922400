<template>
  <div class="d-flex justify-content-center w-100">
    <div
      v-if="loading"
      class="observer spinner-border text-secondary mx-auto"
      role="status"
    >
      <span class="visually-hidden">Loading...</span>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    margin: {type: String, default: '0px'},
    threshold: {type: Number, default: 0.5},
    parentContainer: {type: String, default: ''},
    loading: {type: Boolean, default: true},
    searchBy: {type: String, default: ''},
  },

  emits: ["reload"],

  data: () => ({
    observer: null,
    element: null
  }),

  mounted () {
    let searchByElement = null;

    if (this.searchBy) {
      searchByElement = document.getElementById(this.searchBy);
    }

    const options = searchByElement ?
      searchByElement : {
        root: document.getElementById(`${ this.parentContainer }`),
        rootMargin: this.margin,
        threshold: this.threshold
      };

    if (options && options.root || searchByElement) {
      this.observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry && entry.isIntersecting) {
            this.$emit("reload");
          }
        });
      }, options);

      this.observer.observe(searchByElement ? searchByElement : this.$el);
    }
  },

  unmounted() {
    if (this.observer !== null) this.observer.disconnect();
  }
};
</script>
