<template>
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-header">
          <div class="row align-items-center">
            <div class="col">
              <h4 class="card-header-title">
                {{ $t('all_agents') }}
              </h4>
            </div>
            <div
              v-if="$route.params.id"
              class="col-auto"
            >
              <button
                class="btn btn-outline-primary text-small"
                @click="chats"
                data-test="connectionSpy-button"
              >
                {{ $t('view_all_chats') }}
              </button>
            </div>
            <div
              v-else
              class="col-auto d-flex"
              style="gap: 16px"
            >
              <filter-dropdown
                title="agents_omni"
                :list="agentsList"
                :loading="filterLoading.fetchAgents"
                @radio-selected="filterByAgent($event)"
                data-test="agentsFilter-button"
              />
              <filter-dropdown
                title="status_omni"
                :list="statusList"
                @radio-selected="filterByStatus($event)"
                data-test="statusFilter-button"
              />
              <filter-dropdown
                title="team_omni"
                :list="teamsList"
                :loading="filterLoading.fetchTeams"
                @radio-selected="filterByTeam($event)"
                data-test="teamsFilter-button"
              />
              <filter-dropdown
                v-if="groupChannels"
                title="channel_group"
                :list="groupChannels"
                :loading="filterLoading.fetchGroupChannels"
                @radio-selected="filterByGroupChannel($event)"
                data-test="connectionsFilter-button"
              />
            </div>
          </div>
        </div>
        <div class="table-responsive mb-0">
          <table
            id="agentsTable"
            class="table table-hover table-nowrap card-table"
          >
            <thead>
              <tr>
                <th>
                  {{ $t("status_name") }}
                </th>
                <th>
                  {{ $t("chats") }}
                </th>
                <th class="text-hover">
                  sla <span
                    class="fal fa-circle-info"
                    ref="sla"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    :data-bs-title="$t('SLA.sla_description')"
                  />
                </th>
                <th>
                  {{ $t("AHT") }}
                </th>
                <th>
                  {{ $t('team') }}
                </th>
                <th>
                  {{ $t('channel_group') }}
                </th>
                <th>
                  {{ $t("more_options") }}
                </th>
              </tr>
            </thead>
            <tbody class="list">
              <loading-table-component
                v-if="loading"
                :lines="15"
                :columns="7"
              />
              <tr
                v-else
                v-for="item in agentsData"
                :key="item.id"
              >
                <td>
                  <dot-status
                    :class="{'bg-green-300' : item.whatsapp_status, 'bg-red-300': !item.whatsapp_status}"
                    size="10px"
                  />
                  {{ item.extension_number }} - {{ item.name }}
                </td>
                <td>
                  {{ item.metrics.total }}
                  <talks-progressbar-multiple :metrics="item.metrics" />
                </td>
                <td>{{ formatSecondsDateForTables(item.SLA) }}</td>
                <td>{{ formatSecondsDateForTables(item.TMA) }}</td>
                <td>{{ item.team_name }}</td>
                <td>
                  <div v-if="item.group_channels && getActiveChannels(item.group_channels.data).length === 1">
                    <dot-status
                      size="8px"
                      :color="getActiveChannels(item.group_channels.data)[0].color"
                    />
                    <span style="margin-left: 8px">
                      {{ getActiveChannels(item.group_channels.data)[0].name }}
                    </span>
                  </div>
                  <div v-else-if="item.group_channels && getActiveChannels(item.group_channels.data).length != 0">
                    <agent-connections-tooltip :group-channels="getActiveChannels(item.group_channels.data)" />
                  </div>
                </td>
                <td style="width: 174px">
                  <app-button
                    color="btn-outline-primary"
                    class="btn-sm show-when-hovered"
                    @click="agentChats( item )"
                    data-test="agentSpy-button"
                  >
                    <span class="fe fe-eye" /> {{ $t('spy_mode') }}
                  </app-button>
                  <div class="btn-group">
                    <app-button
                      class="btn-sm show-when-hovered button-more"
                      data-bs-toggle="dropdown"
                      data-bs-auto-close="true"
                      aria-expanded="false"
                      data-test="agentOptions-button"
                    >
                      <span class="fe fe-more-vertical" />
                    </app-button>
                    <ul class="dropdown-menu show-when-hovered">
                      <li>
                        <h6 class="dropdown-header">
                          {{ $t("more_options") }}
                        </h6>
                      </li>
                      <li>
                        <a
                          @click="editUser(item.id)"
                          class="dropdown-item cursor-pointer"
                          data-test="editUser-button"
                        >{{ $t('edit_user_omni') }}</a>
                      </li>
                    </ul>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div
          v-if="agentsData && agentsData.length === 0 && !loading"
          class="card-body p-0 d-flex align-items-center justify-content-center empty-background"
        >
          <div
            class="d-flex flex-column align-items-center"
            style="width: 185px"
          >
            <img src="@assets/img/empty-table.svg">
            <p class="text-center">
              {{ $t('nothing_to_show') }}
            </p>
          </div>
        </div>
        <div class="col-lg-12">
          <paginator
            :pagination="pagination"
            @change-page="changePage"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LoadingTableComponent from "@dialer/manager/components/loading-table-component.vue";
import AppButton from "@components/app-button.vue";
import DotStatus from "@components/dot-status.vue";
import Paginator from "@components/app-paginator.vue";
import FilterDropdown from "@components/filter-dropdown.vue";
import TalksProgressbarMultiple from "../../components/talks-progressbar-multiple.vue";
import AgentConnectionsTooltip from "../../connections-list/components/agent-connections-tooltip.vue";
import {formatSecondsDateForTables} from "@/utils";

import {mapActions, mapGetters, mapMutations} from "vuex";
import {Tooltip} from "bootstrap";


export default {
  components: {
    FilterDropdown,
    Paginator,
    DotStatus,
    LoadingTableComponent,
    AppButton,
    TalksProgressbarMultiple,
    AgentConnectionsTooltip
  },

  data(){
    return{
      params: {
        agent_id: "",
        status: "",
        team_id: "",
        group_channel_id: "",
        page: 1,
      },
      slaTooltip: null,
    }
  },

  created() {
    if (!this.$route.params.id) {
      this.fetchFiltersData()
    }
  },

  mounted() {
    const tooltip = this.$refs.sla;
    this.slaTooltip = new Tooltip(tooltip)
  },

  computed: {
    ...mapGetters("manager/whatsapp", {
      agents: "getAgents",
      agentsPagination: "getAgentsPagination",
      groupChannelsAgents: "getGroupChannelAgents",
      groupChannelAgentsPagination: "getGroupChannelAgentsPagination",
      loadingFlags: "getLoadingFlags"
    }),

    ...mapGetters("auth", {
      user: "getUser",
    }),

    ...mapGetters("manager/whatsapp_filters", {
      agentsList: "getAgents",
      statusList: "getStatus",
      teamsList: "getTeams",
      groupChannels: "getGroupChannels",
      filterLoading: "getLoadingFlags"
    }),

    agentsData () {
      return this.$route.params.id ? this.groupChannelsAgents : this.agents
    },

    pagination () {
      return this.$route.params.id ? this.groupChannelAgentsPagination : this.agentsPagination
    },

    loading () {
      return this.$route.params.id ? this.loadingFlags.fetchWhatsappDashboardById : this.loadingFlags.fetchWhatsappDashboard
    }
  },

  methods: {
    formatSecondsDateForTables,

    ...mapActions("system", ["showConfirmationMessage"]),
    ...mapActions("whatsapp/chat", ["clearChats"]),

    ...mapActions("manager/whatsapp", [
      "postLogoutAgent",
      "fetchWhatsappDashboard",
      "fetchWhatsappDashboardById"
    ]),

    ...mapActions("manager/whatsapp_filters", [
      "fetchAgents",
      "fetchTeams",
      "fetchGroupChannels"
    ]),

    ...mapMutations("whatsapp/chat", ["setFilters"]),

    fetchFiltersData() {
      this.fetchAgents()
      this.fetchTeams()
      this.fetchGroupChannels()
    },

    chats() {
      this.clearChats();
      this.setFilters({
        agent_id: null,
        group_channel_id: this.$route.params.id
      });
      this.$router.push(`/whatsapp`);
    },

    agentChats(item) {
      this.clearChats();
      this.setFilters({
        agent_id: item.id,
        team_id: this.params.team_id,
        group_channel_id: this.$route.params.id,
      });
      this.$router.push(`/whatsapp`);
    },

    editUser(userId){
      this.$router.push(`/manager/users/${userId}/edit`)
    },

    changePage(page) {
      this.params.page = page
      this.reloadWhatsappDashboard()
    },

    filterByAgent(id) {
      this.params.agent_id = id
      this.reloadWhatsappDashboard()
    },

    filterByStatus(status) {
      this.params.status = status
      this.reloadWhatsappDashboard()
    },

    filterByTeam(id) {
      this.params.team_id = id
      this.reloadWhatsappDashboard()
    },

    filterByGroupChannel(id) {
      this.params.group_channel_id = id
      this.reloadWhatsappDashboard()
    },

    reloadWhatsappDashboard(){
      if (!this.$route.params.id) this.fetchWhatsappDashboard(this.params)
      else this.fetchWhatsappDashboardById({group_channel_id:  this.$route.params.id, params: this.params})
    },

    getActiveChannels(groupChannels){
      if (!groupChannels.length) {
        return []
      }

      const channels = groupChannels.filter((groupChannel) => {
        return groupChannel.status != "deleted"
      })
      return channels
    }
  },
};
</script>

<style scoped lang="scss">
.empty-background{
  height: 360px;

  img {
    height: 55%;
  }
}

@media (pointer: fine) {
  #agentsTable > tbody > tr:not(:hover) .show-when-hovered {
    display: none;
  }
}

tbody > tr{
  height: 61px;
}

.text-hover:hover{
  color: $color-blue-300 !important;
}

.button-more{
  width: 28px;
  background-color: #F9FBFD;
  color: $color-text-gray;
  margin-left: 0.75rem;
  border: none;
  border-radius: 0.5rem !important;

  &:hover {
    background-color: $color-blue-300;
    color: $color-text-light;
  }
}
</style>
