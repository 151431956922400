<template>
  <div
    class="container-fluid vh-100 d-flex justify-content-center align-items-center p-0"
  >
    <div class="empty-state d-flex flex-column overflow-hidden rounded-2 bg-text-light">
      <div class="d-flex bg-textures position-relative align-items-center">
        <div
          class="d-flex flex-column gap-4"
          style="margin-left: 68px; max-width: 30%"
        >
          <img
            alt="3c Plus"
            src="@/assets/img/3czinhos.svg"
            style="width: 85px"
          >
          <h1
            class="m-0"
            style="line-height: 1.2"
          >
            {{ $t('warmup_insights_ia_chats_title') }}
          </h1>
        </div>
        <img
          alt="Chatbot screens"
          class="position-absolute chatbot-screens"
          src="@/assets/img/screens-insights-ia-chats.png"
        >
      </div>
      <div
        class="flex-grow-1 d-flex align-items-center rounded-2"
        style="padding: 40px"
      >
        <div class="d-flex align-items-end justify-content-between gap-4 w-100">
          <ul class="m-0">
            <li>{{ $t('warmup_insights_ia_chats_item1') }}</li>
            <li>{{ $t('warmup_insights_ia_chats_item2') }}</li>
            <li>{{ $t('warmup_insights_ia_chats_item3') }}</li>
          </ul>
          <router-link
            @click="setCurrentTab('tab-omni')"
            class="btn btn-primary btn-users"
            to="/manager/insights-ai/settings"
            style="width: 261px;"
          >
            <i class="fa-regular fa-gear" />
            {{ $t('configure_ia_insights') }}
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapMutations} from "vuex";

export default {
  name: "WarmupInsightsAiChats",

  methods:{
    ...mapMutations("manager/insights", ['setCurrentTab'])
  }
}
</script>

<style scoped lang="scss">
.container-fluid, .bg-textures {
  background-repeat: no-repeat;
  background-size: cover;
}

.container-fluid {
  background-image: url("~@assets/img/bg-insights-ia-chats.png");
  background-position: top right;
  max-width: 1622px;
  max-height: 1058px;
}

.empty-state {
  width: 80%;
  height: 54%;
}

.bg-textures {
  background-image: url("~@assets/img/textures.svg");
  background-position: top center;
  height: 65%;
}

.chatbot-screens {
  right: 0;
  height: 100%;
  object-position: bottom;
}

@media (max-width: 1366px) {
  h1 {
    font-size: 1.5rem;
  }

  li {
    font-size: 0.875rem;
  }
}

@media (min-width: 1920px) {
  .empty-state {
    max-width: 1298px;
    max-height: 584px;
  }

  h1 {
    font-size: 2.5rem;
  }

  li {
    font-size: 1.5rem;
  }
}
</style>