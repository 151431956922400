<template>
  <div class="card">
    <div class="card-header">
      <h4 class="m-0">
        {{ $t('pending_activities') }}
      </h4>
    </div>
    <div
      class="card-body px-4 pt-4"
      :style="{
        paddingBottom: '8px !important'
      }"
    >
      <div class="d-flex flex-row flex-wrap p-0">
        <div
          v-for="activity in formattedStatistics"
          :key="activity.type"
          class="col-4 d-flex flex-column"
        >
          <div class="d-flex flex-column border border-1 rounded pending-activity-card gap-2 fw-medium">
            <div class="avatar avatar-xs">
              <div
                class="avatar-title fs-lg bg-danger-subtle rounded-circle"
                :class="
                  activity.type === 'expired_activities' ? 'bg-red-1aa00  text-danger' : 'activity-icon  text-primary'
                "
              >
                <i
                  class="fa-regular fs-6"
                  :class="
                    activity.type === 'expired_activities' ? 'fa-triangle-exclamation' : getActivityIcon(activity.type)
                  "
                />
              </div>
            </div>
            <span
              class="fs-5"
              :class="activity.type === 'expired_activities' ? 'text-danger' : ''"
            >{{
              activity.total + " " + activity.label
            }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getActivityIcon } from "@crm/utills";

export default {
  name: "PendingActiveties",
  data() {
    return {
      pendingActivities: [
        {
          type: "expired_activities",
          label: this.$t('late_f'),
          total: 0
        },
        {
          type: "task",
          label: this.$t('tasks'),
          total: 0
        },
        {
          type: "call",
          label: this.$t('calls_capitalizes'),
          total: 0
        },
        {
          type: "message",
          label: this.$t('messages'),
          total: 0
        },
        {
          type: "email",
          label: "E-mails",
          total: 0
        },
        {
          type: "meeting",
          label: this.$t('meetings'),
          total: 0
        }
      ]
    };
  },

  props: {
    statistics: { type: Object, required: true, default: null }
  },

  computed: {
    formattedStatistics() {
      this.pendingActivities.forEach(activity => {
        if (activity.type === "expired_activities") {
          activity.total = this.statistics?.expired_activities || 0;
          return;
        }

        const pendingActivity = this.statistics?.pending_activities_by_type?.find(stat => stat.type === activity.type);
        activity.total = pendingActivity?.total || 0;
      });

      return this.pendingActivities;
    }
  },

  methods: {
    getActivityIcon
  }
};
</script>
<style scoped lang="scss">
.pending-activity-card {
  margin: 0 16px 16px 0;
  padding: 16px;
}
.activity-icon {
  background: #f0f4fa;
}
.bg-red-1aa00 {
  background: rgba(240, 109, 142, 0.2);
}
</style>
