<template>
  <div
    class="row"
    style="height: 353px;"
  >
    <div
      class="col-7"
      style="height: 353px;"
    >
      <div class="d-flex gap-4 justify-content-between">
        <card-details
          :badge="scoreIcon.badge"
          class="w-50"
          :icon="`fa-regular ${scoreIcon.note}`"
          :title="$t('uppercases.company_average')"
        >
          <template #content>
            <p class="mb-0 tx-text-gray">
              <span
                :class="scoreIcon.textColor"
                class="fw-medium fs-3"
              >{{ scoreFixed }}</span>
              /10
            </p>
          </template>
        </card-details>
        <card-details
          :number="metrics.attention_calls_count"
          :badge="attention.bgColor"
          class="w-50"
          :icon="attention.icon"
          :title="$t('uppercases.attention_calls')"
        />
      </div>
      <div
        class="h-100"
        style="max-height: 226px;"
      >
        <card-geral-avaliation
          class="col-12 h-100"
          :metrics="metrics"
        />
      </div>
    </div>
    <div
      class="col-5"
      style="height: 353px;"
    >
      <div
        v-if="!metrics.criteria.length"
        class="h-100"
      >
        <div
          class="card mb-0"
          style="height: 353px;"
        >
          <div class="card-header">
            <h5 class="mb-0">
              {{ $t("uppercases.criteria") }}
            </h5>
          </div>
          <div class="card-body">
            <div class="d-flex justify-content-center align-items-center h-100">
              <div class="d-flex flex-column align-items-center justify-content-center">
                <img
                  src="@/assets/img/aloisio/Pluzinha_busca.svg"
                  alt="Pluzinha"
                >
                <p class="mb-0">
                  {{ $t("no_rating_yet") }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <card-criteria
        v-else
        :metrics="metrics"
      />
    </div>
  </div>
</template>

<script>
import CardDetails from "@dialer/manager/pages/insights-ai/components/card-details.vue";
import CardCriteria from "@dialer/manager/pages/insights-ai/components/card-criteria.vue";
import CardGeralAvaliation from "@dialer/manager/pages/insights-ai/components/card-geral-avaliation.vue";

export default {
  name: "CardGeneralMetrics",
  components: { CardGeralAvaliation, CardCriteria, CardDetails },
  props: {
    metrics: {
      type: [Object, Array],
      required: true
    }
  },
  data() {
    return {};
  },
  computed: {
    attention() {
      let icon, bgColor;
      let isAttention = false;

      if (this.metrics.attention_calls_count > 0) {
        icon = "fa-regular fa-triangle-exclamation";
        bgColor = "alert";
        isAttention = true;
      } else {
        icon = "fa-regular fa-phone";
        bgColor = "default";
        isAttention = false;
      }

      return { isAttention, icon, bgColor };
    },
    scoreIcon() {
      let value = this.metrics.overall_score;
      let note, badge, textColor;
      if (value >= 6) {
        note = "fa-thumbs-up";
        badge = "good";
        textColor = "tx-green-400";
      } else if (value >= 3) {
        note = "fa-meh";
        badge = "alert";
        textColor = "tx-yellow-400";
      } else {
        note = "fa-thumbs-down";
        badge = "danger";
        textColor = "text-danger";
      }

      return { note, badge, textColor };
    },
    scoreFixed() {
      let value = this.metrics.overall_score;
      return value.toFixed(1);
    }
  }
};
</script>
