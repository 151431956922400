<template>
  <div
    v-if="isAgent && activity.type === 'email'"
    class="btn-group col-12"
  >
    <a
      class="complete-button btn col-10 btn-light text-gray"
      type="button"
      target="_blank"
      @click.stop
      :href="`mailto:${activity.email}`"
    >
      <i class="fa-regular fa-envelope me-2" />
      {{ $t('open_email') }}
    </a>
    <button
      type="button"
      class="complete-button btn btn-light text-gray dropdown-toggle dropdown-toggle-split"
      data-bs-toggle="dropdown"
      aria-expanded="false"
      @click.stop
    />
    <ul class="dropdown-menu p-3">
      <li @click.stop="completeActivity">
        <i class="fa-regular fa-check" />
        {{ $t('conclude') }}
      </li>
    </ul>
  </div>
  <div
    v-else-if="false"
    class="btn-group col-12"
  >
    <button
      class="complete-button btn col-10 btn-light text-gray"
      type="button"
      @click.stop="_startWhatsappCall"
    >
      <i class="fa-regular fa-phone me-2" />
      {{ $t('call_now') }}
    </button>
    <button
      type="button"
      class="complete-button btn btn-light text-gray dropdown-toggle dropdown-toggle-split"
      data-bs-toggle="dropdown"
      aria-expanded="false"
      @click.stop
    />
    <ul class="dropdown-menu p-3">
      <li @click.stop="completeActivity">
        <i class="fa-regular fa-check" />
        {{ $t('conclude') }}
      </li>
    </ul>
  </div>
  <div
    v-else-if="isAgent && activity.type === 'message'"
    class="btn-group col-12"
  >
    <button
      class="complete-button btn col-10 btn-light text-gray"
      type="button"
      @click.stop="open"
    >
      <i class="fa-regular fa-messages me-2" />
      {{ $t('send_message') }}
    </button>
    <button
      type="button"
      class="complete-button btn btn-light text-gray dropdown-toggle dropdown-toggle-split"
      data-bs-toggle="dropdown"
      aria-expanded="false"
      @click.stop
    />
    <ul class="dropdown-menu p-3">
      <li @click.stop="completeActivity">
        <i class="fa-regular fa-check" />
        {{ $t('conclude') }}
      </li>
    </ul>
  </div>
  <button
    v-else
    class="complete-button btn col-12 btn-light px-5 me-3 d-flex justify-content-center align-items-center gap-2 text-gray"
    @click.stop="completeActivity"
  >
    <i class="fa-regular fa-check" />
    {{ $t('conclude') }}
  </button>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { parsePhoneNumber, isValidPhoneNumber } from "libphonenumber-js";

export default {
  emits: ["complete"],
  name: "CompleteButton",
  props: {
    activity: {
      type: Object,
      default: null
    }
  },
  computed: {
    ...mapGetters("auth", {
      user: "getUser",
      isAgent: "isAgent",
      instances: "getUserConnectedInstances",
      hasAccessToWhatsapp: "hasAccessToWhatsapp"
    }),
    ...mapGetters("agent/call", {
      call: "getCall",
    })
  },
  methods: {
    ...mapActions("whatsapp/chat", ["checkContacts", "findChat"]),
    ...mapActions("whatsapp/contact", ["findContactInstances"]),
    ...mapActions("agent/call", ["startWhatsappCall"]),
    ...mapMutations("whatsapp/contact", ["setSelectedContact"]),
    ...mapMutations("auth", ["trackEvents"]),

    completeActivity() {
      this.$emit("complete", this.activity);
    },

    open() {
      if (this.instances.length <= 1) {
        this.whatsappRedirect();
      } else {
        this.openInstances();
      }
    },

    whatsappRedirect() {
      if (!this.hasAccessToWhatsapp) {
        this.redirectToWhatsappWeb();
        return;
      }

      let number = String(this.activity.phone);
      if (isValidPhoneNumber(number, "BR")) {
        number = parsePhoneNumber(number, 'BR').number.replace("+", "");
      }

      this.checkContacts({
        fields: {
          phones: [number],
          instance_id: this.instances[0].id
        }
      }).then(() => {
        this.redirectToChat(`${number}${this.instances[0].id}`);
      });

    },
    redirectToWhatsappWeb() {
      this.trackEvents("[Agent] Click on Mobile (WhatsApp Web)");
      window.open(
        "https://api.whatsapp.com/send?phone=" + this.activity.phone,
        "_blank"
      );
    },

    redirectToChat(chatId) {
      this.findChat(chatId)
        .then(chat => {
          this.$router.push("/whatsapp/" + chat.id);
        })
        .catch(status => {
          if (status === 404) {
            let emptyChatContact = this.activity.phone;
            emptyChatContact.instance = this.instances[0];

            this.setEmptyChatContact(emptyChatContact);

            this.$router.push("/whatsapp/empty-chat");
          }
        });
    },

    openInstances() {
      let number = String(this.activity.phone);
      if (isValidPhoneNumber(number, "BR")) {
        number = parsePhoneNumber(number, 'BR').number.replace("+", "");
      }

      this.checkContacts({
        fields: {
          phones: number,
          instance_id: this.instances[0].id
        }
      }).then(() => {
        this.setSelectedContact({});
        this.findContactInstances(number);
      });
    },

    _startWhatsappCall(number) {
      this.startWhatsappCall(number)
        .then(() => {
          this.$router.push('/agent')
          this.trackEvents("[Agent] Omni Dialer Call")
        })
    }
  }
}
</script>

<style scoped lang="scss">
.dropdown-toggle{
  border-left: 2px solid white !important;
}
.complete-button {
  &:hover {
    background-color: $color-blue-300;
    color: white !important;
  }
  &:focus {
    outline: 0 !important;
    border: none !important;
    box-shadow: 0 0 0 0.15rem rgba(79, 112, 244, 0.5);
  }
}
</style>