<template>
  <div class="my-2 text-center">
    <div class="draggable-item col-12 card d-flex align-items-center mb-0 py-3">
      <div
        class="card-body col-12 table-card d-flex justify-content-between align-items-center gap-4 text-center py-2 px-3"
      >
        <span class="col-1 fw-light">
          {{ fields.length + 1 }}
        </span>
        <div class="col-2">
          <div class="btn-group d-flex flex-column justify-content-start text-start w-100">
            <div class="dropdown">
              <button
                :class="{ 'is-invalid': errors['mask'] }"
                aria-expanded="false"
                aria-haspopup="true"
                data-bs-toggle="dropdown"
                class="btn dropdown-toggle d-flex justify-content-center"
                @focusout="checkField('mask')"
              >
                <span>
                  <i
                    :class="form.mask.icon"
                    class="me-3"
                  />
                  <span class="w-100">{{ form.mask.label }}</span>
                </span>
              </button>
              <ul class="dropdown-menu w-100 p-3">
                <li
                  v-for="(item, index) in fieldTypes"
                  :key="index"
                  class="px-3 d-flex align-items-center gap-3 dropdown-item my-3"
                  @click="selectMask(item)"
                >
                  <i :class="item.icon" />
                  <span class="">{{ item.label }}</span>
                </li>
              </ul>
            </div>
            <small
              v-if="errors['mask']"
              class="text-danger"
            >{{ errors['mask'] }}</small>
          </div>
        </div>
        <div class="col-4 d-flex flex-column text-start">
          <div class="d-flex justify-content-center align-items-center text-center gap-3">
            <input
              v-model="form.name"
              :class="{ 'is-invalid': errors['name'] }"
              class="form-control"
              placeholder="Nome"
              type="text"
              @focusout="checkField('name')"
            >
          </div>
          <small
            v-if="errors['name']"
            class="text-danger"
          >{{ errors['name'] }}</small>
        </div>
        <div class="d-flex justify-content-between">
          <div class="col-auto form-check form-switch d-flex justify-content-center align-items-center px-auto">
            <input
              id="required-check"
              v-model="form.required"
              class="form-check-input"
              type="checkbox"
            >
          </div>
        </div>
        <div class="d-flex justify-content-between align-items-center">
          <button
            class="btn"
            @click="submitForm()"
          >
            <i class="fal fa-check fw-medium" />
          </button>
          <button
            class="btn"
            @click="close"
          >
            <i class="fal fa-xmark fw-medium" />
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {mapActions, mapGetters} from "vuex";

export default {
  data () {
    return {
      form: {
        name: null,
        required: true,
        mask: {value: 'string', label: this.$t('text'), icon: 'fa-solid fa-align-left'},
      },
      errors: {
        name: null,
        // required: null,
        mask: null,
      }
    }
  },

  name: "AddFieldForm",

  emits: ['close'],

  props: {
    fieldTypes: {type: Array, default: null},
    totalFieldsLength: {type: Number, default: 0}
  },

  computed: {
    ...mapGetters("auth", {user: "getUser"}),
    ...mapGetters('crm/funnels', {currentFunnel: 'getCurrentFunnel'}),
    ...mapGetters('crm/opportunities', {fields: 'getFormInputs'}),
    ...mapGetters('crm/tour', { tourSteps: 'getTourSteps' }),
  },

  methods: {
    ...mapActions('crm/opportunities', ['addFormInputs']),
    ...mapActions('crm/tour', { updateTourSteps: 'updateTourSteps' }),

    checkField (fieldName) {
      if (!this.form[fieldName])
        this.errors[fieldName] = this.$t('this_field_is_required')
      else
        this.errors[fieldName] = null
    },

    submitForm () {
      for (let i = 0; i < Object.keys(this.errors).length; i++) {
        this.checkField(Object.keys(this.errors)[i])
      }
      if (!Object.values(this.errors).includes(this.$t('this_field_is_required'))) {
        this.addFormInputs({
          funnel_id: parseInt(this.$route.params.id),
          label: this.form.name,
          mask: this.form.mask.value,
          is_primary: true,
          is_required: this.form.required,
          order: this.totalFieldsLength + 1,
        }).then(() => {
          if(!this.tourSteps?.add_custom_fields){
            this.updateTourSteps({
              add_custom_fields: true,
            })
          }
        })

        this.close()
      }
    },

    selectMask (item) {
      this.form.mask = item
    },

    close () {
      this.$emit('close')
    }
  }
}
</script>

<style lang="scss" scoped>
.form-check-input {
  height: $spacing-2;
  width: 28px;
}

.dropdown-item {
  border-radius: 8px !important;
  cursor: pointer;

  &:hover {
    background-color: $color-gray-100 !important;
  }
}
</style>
