export default {
  setMetrics: (state, metrics) => state.metrics = metrics,
  setChatbots: (state, chatbots) => state.chatbots = chatbots,
  setChatbotsPagination: (state, chatbotsPagination) => state.chatbotsPagination = chatbotsPagination,
  setChatbotsParams: (state, {flag, value}) => state.chatbotsParams[flag] = value,
  setDefaultParams: (state) => state.chatbotsParams = {
    page: 1,
    search: ""
  },
  setChatbot: (state, chatbot) => state.chatbot = chatbot,
  startLoading: (state, flag) => state.loadingFlags[flag] = true,
  stopLoading: (state, flag) => state.loadingFlags[flag] = false,
}
