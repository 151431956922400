<template>
  <div class="card">
    <div class="card-header">
      <div class="d-flex align-items-center text-muted">
        <i class="fa-regular fa-magnifying-glass" />
        <input
          v-model="term"
          :placeholder="`${type === 'future' ? $t('search_future_activities') : $t('search_day_activities')}`"
          class="ms-3 form-control form-control-flush"
          type="text"
          @keyup="search"
        >
      </div>

      <div class="ms-2 d-flex align-items-center gap-2">
        <small class="text-secondary">{{ $t('filter') }}</small>
        <div
          v-for="(activityType, index) in activitiesType"
          :key="index"
          :class="{
            'bg-primary': activityType.type === currentActivityFilter,
            'bg-secondary-soft': activityType.type !== currentActivityFilter
          }"
          class="badge cursor-pointer py-2 px-3"
          @click="filterByActivityType(activityType.type)"
        >
          {{ activityType.name }}
        </div>
      </div>
    </div>
    <div class="card-body p-0">
      <div class="tab-content">
        <div
          id="actives"
          class="table-responsive tab-pane active"
          role="tabpanel"
        >
          <table
            class="table table-hover table-nowrap card-table"
            style="padding: 0 !important;"
          >
            <thead>
              <tr>
                <th class="col-1">
                  <a
                    class="list-sort text-muted fw-normal"
                    href="#"
                  >{{ $t('type_general') }}</a>
                </th>
                <th class="col-2 ps-0">
                  <a
                    class="list-sort text-muted fw-normal"
                    href="#"
                  >{{ $t('title') }}</a>
                </th>
                <th class="col-2">
                  <a
                    class="list-sort text-muted fw-normal"
                    href="#"
                  >{{ $t("date_capitalize") }}</a>
                </th>
                <th class="col-2">
                  <span class="">{{ $t('responsible') }}</span>
                </th>
                <th class="col-2">
                  <span class="">{{ $t('opportunity') }}</span>
                </th>
                <th class="col-1">
                  <span class="visually-hidden" />
                </th>
                <th class="col-2">
                  <span class="visually-hidden" />
                </th>
              </tr>
            </thead>
            <tbody class="list">
              <loading-table-component
                v-if="loading"
                :columns="7"
                :lines="5"
                :list-width="[20, 120, 100, 100, 100, 20, 100]"
                :text-center="false"
                height="75px"
              />
              <template v-else-if="!loading && !activities.length">
                <tr class="justify-content-center">
                  <td
                    class="col-12"
                    colspan="10"
                  >
                    <div class="d-flex justify-content-center gap-4 p-5">
                      <img src="@assets/img/aloisio/new.svg">
                      <div class="d-flex justify-content-center align-items-center gap-2">
                        <div>
                          <h5>
                            {{ $t('no_activity') }}
                          </h5>
                          <p class="fs-6">
                            {{ $t('click_to_add_activity') }}
                          </p>
                          <button
                            class="btn btn-primary"
                            style="min-width: 200px"
                            @click="showActivityModal = true"
                          >
                            {{ $t('new_activity') }}
                          </button>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
              </template>
              <template v-else>
                <tr
                  v-for="(item, index) in activities"
                  :key="index"
                  class="col-12 cursor-pointer"
                  @click="openEditActivityModal(item)"
                >
                  <td class="col-1">
                    <div class="avatar avatar-sm">
                      <div class="avatar-title fs-lg bg-primary-subtle rounded-circle text-primary">
                        <i
                          :class="getActivityIcon(item.type)"
                          class="fa-regular"
                        />
                      </div>
                    </div>
                  </td>
                  <td
                    :title="item.title"
                    class="col-2 fw-medium text-truncate ps-0"
                    style="max-width: 220px"
                  >
                    {{ item.title }}
                  </td>
                  <td
                    :class="{ 'text-danger': item.is_expired }"
                    class="col-2 fw-medium"
                  >
                    <div class="d-flex align-items-center gap-2 my-auto">
                      <i
                        :class="{
                          'fa-regular fa-triangle-exclamation': item.is_expired
                        }"
                      />
                      <span class="d-flex align-items-center gap-2">
                        {{ getDate(item.due_date) }}
                        <dot-status
                          :color="item.is_expired ? 'red' : 'black'"
                          class="my-2"
                          size="0.25rem"
                        />
                        {{ getTime(item.due_date) }}
                      </span>
                    </div>
                  </td>
                  <td class="col-2">
                    <i class="fa-regular fa-user" />
                    {{ item.owner.name }}
                  </td>
                  <td class="col-2">
                    <i class="fa-regular fa-building-user" />
                    {{ item.opportunity_name }}
                  </td>
                  <td class="col-1">
                    <tooltip-component
                      v-if="item.description"
                      :title="item.description"
                      color="text-secondary"
                      icon="fa-regular fa-memo"
                      placement="left"
                    />
                  </td>
                  <td class="col-3 px-0 ">
                    <complete-button
                      :activity="item"
                      @complete="complete"
                    />
                  </td>
                </tr>
              </template>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div
      v-if="activities.length"
      class="card-footer"
    >
      <div class="col-lg-12">
        <app-paginator
          :pagination="pagination"
          @change-page="changePage"
        />
      </div>
    </div>
  </div>

  <modal-activity
    :show-activity-modal="showActivityModal"
    @close="closeModal"
  />

  <modal-edit-activity
    :activity="currentActivity"
    :show-edit-activity-modal="showEditActivityModal"
    @close="showEditActivityModal = false"
  />
</template>

<script>
import DotStatus from "@components/dot-status.vue";
import moment from "moment";
import _ from "lodash";
import {mapActions, mapGetters, useStore} from "vuex";
import AppPaginator from "@components/app-paginator";
import LoadingTableComponent from "@dialer/manager/components/loading-table-component.vue";
import TooltipComponent from "@components/tooltip-component.vue";
import {getActivityIcon} from "@crm/utills";
import ModalActivity from "@crm/components/activity/modals/modal-activity.vue";
import ModalEditActivity from "@crm/components/activity/modals/modal-edit-activity.vue";
import CompleteButton from '@crm/components/activity/list/complete-button.vue'

export default {
  data() {
    return {
      term: "",
      showActivityModal: false,
      currentActivity: null,
      showEditActivityModal: false,
      unsubscribe: () => {
      },
      currentActivityFilter: null,
      activitiesType: [
        {
          name: this.$t('everything'),
          type: null
        },
        {
          name: this.$t('tasks'),
          type: "task"
        },
        {
          name: this.$t('calls_capitalizes'),
          type: "call"
        },
        {
          name: this.$t('messages'),
          type: "message"
        },
        {
          name: "E-mails",
          type: "email"
        },
        {
          name: this.$t('meetings'),
          type: "meeting"
        }
      ]
    };
  },

  props: {
    type: {
      type: String,
      required: true
    },

    ownersIds: {
      type: Array,
      default: () => []
    }
  },

  components: {
    DotStatus,
    AppPaginator,
    LoadingTableComponent,
    TooltipComponent,
    ModalActivity,
    ModalEditActivity,
    CompleteButton
  },

  computed: {
    ...mapGetters("crm/activities", {
      loadingFlag: "getLoadingFlags"
    }),

    ...mapGetters("crm/activities", {
      pendingActivitiesOfDay: "getPendingActivitiesOfDay",
      pendingActivitiesOfDayPagination: "getPendingActivitiesOfDayPagination",
      futureActivities: "getFutureActivities",
      futureActivitiesPagination: "getFutureActivitiesPagination"
    }),

    activities() {
      if (this.type === "pendingOfDay") return this.pendingActivitiesOfDay;
      if (this.type === "future") return this.futureActivities;
      return [];
    },

    pagination() {
      if (this.type === "pendingOfDay") return this.pendingActivitiesOfDayPagination;
      if (this.type === "future") return this.futureActivitiesPagination;
      return {};
    },

    loading() {
      if (this.type === "pendingOfDay") return this.loadingFlag.fetchPendingActivitiesOfDay;
      if (this.type === "future") return this.loadingFlag.fetchFutureActivities;
      return true;
    }
  },

  mounted() {
    if (this.type === "future") {
      this.setFutureActivitiesFilter({
        funnel_id: this.$route.params.id,
        is_future: 1,
        owner_id: this.ownersIds
      });
    }
    if (this.type === "pendingOfDay") {
      this.setPendingActivitiesOfDayFilter({
        funnel_id: this.$route.params.id,
        is_future: 0,
        owner_id: this.ownersIds
      });
    }
    this.fetchPendingActivities(this.type);
    this.unsubscribe = useStore().subscribeAction((action) => {
      if (action.type === "crm/activities/closeModal") {
        this.showActivityModal = false
      }
    })
  },

  beforeUnmount() {
    this.unsubscribe()
  },

  methods: {
    getActivityIcon,

    ...mapActions("crm/activities", {
      setPendingActivitiesFilter: "setPendingActivitiesFilter",
      setPendingActivitiesCurrentPage: "setPendingActivitiesCurrentPage",
      fetchPendingActivities: "fetchActivities",
      completeActivity: "completeActivity",

      setPendingActivitiesOfDayFilter: "setPendingActivitiesOfDayFilter",
      setPendingActivitiesOfDayCurrentPage: "setPendingActivitiesOfDayCurrentPage",

      setFutureActivitiesFilter: "setFutureActivitiesFilter",
      setFutureActivitiesCurrentPage: "setFutureActivitiesCurrentPage"
    }),

    getDate(date) {
      const momentDate = moment(date, "YYYY-MM-DD HH:mm:ss");

      const today = moment();
      if (momentDate.isSame(today, "day")) {
        return this.$t('today_capitalize');
      }

      const tomorrow = moment().add(1, "day");
      if (momentDate.isSame(tomorrow, "day")) {
        return this.$t('tomorrow');
      }

      const yesterday = moment().subtract(1, "day");
      if (momentDate.isSame(yesterday, "day")) {
        return this.$t('yesterday_capitalize');
      }

      return momentDate.format("DD/MM");
    },

    closeModal() {
      this.showActivityModal = false
    },

    getTime(date) {
      return moment(date).format("HH:mm");
    },

    search: _.debounce(function () {
      const filter = {};
      if (this.term) {
        filter.name = "search";
        filter.value = this.term;
      }
      if (this.type === "pendingOfDay") {
        filter.is_future = 0;
        filter.funnel_id = this.$route.params.id;
        filter.activity_type = this.currentActivityFilter
        this.setPendingActivitiesOfDayFilter(filter);
        this.fetchPendingActivities(this.type)
      }

      if (this.type === "future") {
        filter.is_future = 1;
        filter.funnel_id = this.$route.params.id;
        filter.activity_type = this.currentActivityFilter
        this.setFutureActivitiesFilter(filter);
        this.fetchPendingActivities(this.type)
      }
    }, 500),

    changePage(page) {
      if (this.type === "pendingOfDay") {
        this.setPendingActivitiesOfDayCurrentPage(page);
      }

      if (this.type === "future") {
        this.setFutureActivitiesCurrentPage(page);
      }

      this.fetchPendingActivities(this.type);
    },

    complete(activity) {
      const payload = {
        activityId: activity.id
      }
      this.completeActivity(payload);
    },

    openEditActivityModal(activity) {
      this.currentActivity = activity;
      this.showEditActivityModal = true
    },

    filterByActivityType(type) {
      this.currentActivityFilter = type;

      if (this.type === "future") {
        this.setFutureActivitiesCurrentPage(1);
        this.setFutureActivitiesFilter({
          activity_type: type,
          is_future: 1,
          funnel_id: this.$route.params.id,
          search: this.term,
          owner_id: this.ownersIds
        });
      }

      if (this.type === "pendingOfDay") {
        this.setPendingActivitiesOfDayCurrentPage(1);
        this.setPendingActivitiesOfDayFilter({
          activity_type: type,
          is_future: 0,
          funnel_id: this.$route.params.id,
          search: this.term,
          owner_id: this.ownersIds
        });
      }
      this.fetchPendingActivities(this.type);
    }
  }
};
</script>
<style lang="scss" scoped>
.bg-primary-subtle {
  background-color: $color-gray-blue-300;
}

// custom-tooltip only in no scoped style
.custom-tooltip {
  max-width: 303.5px;
}

.custom-tooltip .tooltip-inner {
  padding: 8px;
}

.complete-button {
  &:hover {
    background-color: $color-blue-300;
    color: white !important;
  }

  &:focus {
    outline: 0 !important;
    border: none !important;
    box-shadow: 0 0 0 0.15rem rgba(79, 112, 244, 0.5);
  }
}
</style>
