export default {
  loadingFlags: {
    fetchDetailsPartner: false,
    listPartners: false,
    companyDetails: false,
  },
  detailsPartner: {},
  listPartners: [],

  companyDetails: {},
  requestWithdraw: {
    pix: "",
    amount: null
  },

  partnerDetailsPagination: {
    per_page: 15,
    current_page: 1
  },
};
