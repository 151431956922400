<template>
  <div class="mailing-form">
    <div
      class="modal"
      ref="modal"
    >
      <div class="modal-dialog modal-xl">
        <div class="modal-content form-background">
          <div class="modal-body pt-0">
            <div class="container-fluid">
              <div class="row">
                <header-component
                  :title="$t('include_mailing_list')"
                  :pretitle="$t('acronym.sms')"
                />
                <ul
                  class="nav nav-tabs mb-3"
                  id="nav-tab"
                  role="tablist"
                >
                  <li class="nav-item">
                    <button
                      class="nav-link active"
                      id="nav-general-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#general"
                      role="tab"
                    >
                      {{ $t("general_capitalize") }}
                    </button>
                  </li>
                  <li class="nav-item">
                    <button
                      id="nav-message-tab"
                      ref="tab-message"
                      aria-controls="message"
                      aria-selected="false"
                      class="nav-link"
                      data-bs-target="#message"
                      data-bs-toggle="tab"
                      role="tab"
                    >
                      {{ $t("message") }}
                    </button>
                  </li>
                </ul>
                <div class="tab-content">
                  <div
                    class="tab-pane fade show active"
                    id="general"
                    role="tabpanel"
                  >
                    <div class="col-lg-12">
                      <div class="d-flex justify-content-between">
                        <div class="form-group col-lg-5">
                          <label class="form-label required">{{ $t("name_data") }}</label>
                          <input
                            type="text"
                            class="form-control"
                            v-model="fields.name"
                          >
                        </div>
                        <div class="col-lg-6 b3">
                          <div class="form-group">
                            <label class="form-label required">{{ $t("initial_date") }}</label>
                            <flat-pickr
                              class="form-control"
                              :placeholder="flatPickrPlaceHolder"
                              type="text"
                              aria-label="Input group appended"
                              aria-describedby="inputGroup"
                              v-model="fields.date"
                              :config="config"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="form-group">
                        <label
                          for="name"
                          class="form-label required"
                        >{{ $t("mailing_list_capitalize") }}</label>
                        <span class="form-text">{{ $t("send_csv_file") }}
                        </span>
                        <div
                          :class="{
                            'card mb-0': true,
                            'is-invalid': errors.mailing
                          }"
                        >
                          <div class="card-body">
                            <file-upload
                              @select-file="selectFile"
                              filter="text/csv,text/plain"
                              icon="fe fe-database"
                            />
                          </div>
                        </div>
                        <div class="invalid-feedback">
                          {{ errors.mailing && errors.mailing[0] }}
                        </div>
                      </div>
                    </div>
                    <div class="col col-lg-12 d-flex justify-content-between">
                      <div class="col-lg-5">
                        <div class="form-group">
                          <label class="form-label required">{{ $t("split_by") }}</label>
                          <select
                            class="form-select"
                            v-model="fields.separator"
                          >
                            <option value=",">
                              ,
                            </option>
                            <option value=";">
                              ;
                            </option>
                            <option value="tab">
                              {{ $t("tab") }}
                            </option>
                          </select>
                        </div>
                      </div>

                      <div class="col-lg-6">
                        <div class="form-group">
                          <label class="form-label required">{{ $t("delimiter") }}</label>
                          <select
                            class="form-select"
                            v-model="fields.delimiter"
                          >
                            <option value="quotes">
                              "
                            </option>
                            <option value="single-quotes">
                              '
                            </option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div class="col col-lg-12 d-flex justify-content-between">
                      <div class="col-lg-6 b3">
                        <div class="form-group">
                          <label class="form-label required">{{ $t("fist_line_header") }}</label>
                          <span class="form-text">
                            {{
                              $t("activate_first_line_header")
                            }}
                          </span>
                          <div class="form-check form-switch">
                            <input
                              class="form-check-input"
                              v-model="fields.has_header"
                              type="checkbox"
                            >
                            <label class="form-check-label">{{
                              fields.has_header ? $t("yes") : $t("no")
                            }}</label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-12 b3">
                      <div
                        class="alert alert-info"
                        role="alert"
                      >
                        {{
                          $t("config_columns")
                        }}
                        <strong>{{ $t("area_code_and_phone") }}</strong> {{ $t("or_column") }}
                        <strong>{{ $t("area_code_phone") }}</strong>. <br>
                        {{ $t("header_config") }}
                        <br>
                        <strong>{{
                          $t("no_header_columns")
                        }}</strong>
                      </div>
                    </div>
                    <div class="col-lg-12 b3 mb-2">
                      <app-button
                        class="w-100"
                        :blocked="!file"
                        style="min-height: 0px"
                        @click="validate()"
                        :loading="loading && loadingContext == 'create'"
                      >
                        {{ $t("validate_list") }}
                      </app-button>
                    </div>
                    <div
                      class="col-lg-12 b1 mt-5"
                      v-if="listDataPreview.length > 0"
                    >
                      <div class="form-group">
                        <label class="form-label required">{{ $t("list_setting") }}</label>

                        <div
                          id="preview"
                          ref="preview"
                          :class="{
                            'card mb-0': true,
                            'is-invalid ': errors.header
                          }"
                        >
                          <div class="card-header 0">
                            <div class="row my-2">
                              <div clascreates="col-lg-1" />
                              <div class="col-lg-2">
                                {{ $t("column") }}
                              </div>
                              <div class="col-lg-1" />
                              <div class="col-lg-3">
                                {{ $t("column_type") }}
                              </div>
                              <div class="col-lg-3">
                                {{ $t("column_name") }}
                              </div>
                              <div class="col-lg-2">
                                {{ $t("line") }} 1
                              </div>
                              <div
                                v-if="listDataPreview.length > 1"
                                class="col-lg-2"
                              >
                                {{ $t("line") }} 2
                              </div>
                            </div>
                          </div>
                          <div class="card-body">
                            <div
                              v-for="(name, i) in listHeaderPreview"
                              :key="i"
                              class="row my-2"
                            >
                              <div class="col-lg-1 b2">
                                <div
                                  class="form-check form-switch"
                                  style="margin: 8.25px 0"
                                >
                                  <input
                                    v-model="actives[i]"
                                    class="form-check-input"
                                    type="checkbox"
                                  >
                                </div>
                              </div>
                              <div class="col-lg-1">
                                <strong
                                  :class="{ 'text-muted': !actives[i] }"
                                  style="line-height: 40.5px"
                                >
                                  {{ i }}
                                </strong>
                              </div>
                              <div class="col-lg-3 b3">
                                <strong style="line-height: 40.5px">
                                  <select
                                    v-model="fields.header[i]"
                                    :disabled="!actives[i]"
                                    class="form-select"
                                  >
                                    <option
                                      v-for="(nameColumns, type) in columnsTypes"
                                      :key="type"
                                      :value="type"
                                    >
                                      {{ nameColumns }}
                                    </option>
                                  </select>
                                </strong>
                              </div>
                              <div class="col-lg-3 b3">
                                <strong style="line-height: 40.5px">
                                  <input
                                    v-show="!hasType(fields.header[i])"
                                    v-model="fields.header[i]"
                                    :disabled="!actives[i]"
                                    class="form-control"
                                  >
                                </strong>
                              </div>
                              <div
                                class="col-lg-2 b3 text-muted overflow-ellipsis"
                              >
                                <span
                                  v-if="listDataPreview.length"
                                  style="line-height: 40.5px"
                                >
                                  {{ listDataPreview[0][i] }}
                                </span>
                              </div>
                              <div
                                v-if="listDataPreview.length > 1"
                                class="col-lg-2 b3 text-muted overflow-ellipsis"
                              >
                                <span
                                  v-if="listDataPreview.length > 1"
                                  style="line-height: 40.5px"
                                >
                                  {{ listDataPreview[1][i] }}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="invalid-feedback">
                          {{ errors.header && errors.header[0] }}
                        </div>
                      </div>
                    </div>
                    <div
                      v-if="listDataPreview.length > 0"
                      class="col-lg-12 b3"
                    >
                      <app-button
                        :blocked="!file && false"
                        :loading="loading && loadingContext == 'create'"
                        class="w-100"
                        style="min-height: 0px"
                        @click="submit()"
                      >
                        {{ $t("next_prox") }}
                      </app-button>
                    </div>
                  </div>
                  <div
                    id="message"
                    class="tab-pane fade"
                    role="tabpanel"
                  >
                    <div class="col-12 col-lg-12 row">
                      <label class="form-label px-0">{{ $t("variables") }}</label>
                      <div
                        class="card"
                      >
                        <div class="card-body p-3">
                          <span
                            v-for="item in validatedVariables"
                            :key="item.id"
                            class="badge bg-primary-soft me-2 cursor-pointer"
                          >
                            <template v-if="unvalidVariables.includes(item) == false">
                              <span
                                :class="{'text-muted': isVariableUsed(item) }"
                                @click="addOnTextArea(item)"
                              >
                                {{ item }}
                              </span>
                              <button
                                v-if="isVariableUsed(item)"
                                aria-label="Close"
                                class="btn-close p-0 ms-1 mt-1"
                                type="button"
                                @click="removeFromMessage(item)"
                              />
                            </template>
                          </span>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-lg-12 row border-bottom">
                      <label class="form-label mt-3 px-0">
                        {{ $t("message") }}
                        <span
                          ref="tooltip"
                          class="fe fe-alert-circle"
                          style="color: #2C7BE5"
                        />
                      </label>

                      <span class="form-text fs-6 px-0">
                        {{ $t("how_to_add_variable") }} {{ "{" }}{{ $t("variable_name") }}{{ "}" }}
                      </span>
                      <div class="card">
                        <textarea
                          rows="3"
                          data-autosize
                          class="form-control form-control-flush mb-5"
                          :class="{
                            'text-danger':
                              mailingMessageCounter(this.fields.message) > 160
                          }"
                          :placeholder="$t('variable_placeholder')"
                          v-model="this.fields.message"
                          @keyup="stractVariable"
                        />
                        <span
                          class=" mx-2 mb-3 align-self-end"
                          :class="
                            mailingMessageCounter(this.fields.message) > 160
                              ? 'text-danger'
                              : 'text-muted'
                          "
                        >
                          {{
                            mailingMessageCounter(this.fields.message)
                          }}/160</span>
                      </div>
                      <div
                        class="form-text fs-6 px-0"
                        style="margin-bottom: 20px"
                      >
                        <i class="fe fe-alert-triangle me-2" />
                        <span>{{
                          $t("accent_and_especial_char")
                        }}</span>
                        <strong
                          class="fw-medium fs-6 cursor-pointer"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          {{ $t("know_which_one") }}
                        </strong>
                        <ul class="dropdown-menu">
                          <li
                            class="dropdown-item d-flex align-items-center gap-2 tx-text-black"
                          >
                            <span
                              class="fa fa-circle"
                              style="font-size: 6px"
                            />
                            <strong class="fw-medium">{{ $t("accent_char") }}:</strong> ç, à, á, â, é, ê, í, ó, ô, ú,
                            etc.
                          </li>
                          <li
                            class="dropdown-item d-flex align-items-center gap-2 tx-text-black"
                          >
                            <span
                              class="fa fa-circle"
                              style="font-size: 6px"
                            />
                            <strong class="fw-medium">{{ $t("especial_char") }}:</strong> @, #, $, %, &, *, etc.
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div class="col-12 col-lg-12 row">
                      <label class="form-label mt-5 px-0">{{ $t("example") }}</label>
                      <div class="card mb-0">
                        <div class=" m-2 text-muted">
                          <div class="row">
                            <div class="col-lg-2">
                              {{ $t("phone") }}
                            </div>
                            <div class="col-lg-9">
                              {{ $t("message") }}
                            </div>
                            <div class="col-lg-1">
                              {{ $t("char") }}
                            </div>
                          </div>
                        </div>
                        <div class="card-body p-0 mx-2">
                          <ul class="p-0">
                            <li
                              v-for="(item, i) in this.listDataPreview"
                              :key="i"
                            >
                              <div
                                v-if="i <= 4"
                                class="row py-2"
                              >
                                <div
                                  v-if="item [listHeaderPreview.indexOf('areacodephone')]"
                                  class="col-lg-2 text-muted"
                                >
                                  {{ item [listHeaderPreview.indexOf("areacodephone")] }}
                                </div>
                                <div
                                  v-else
                                  class="col-lg-2 text-muted"
                                >
                                  ({{
                                    item[listHeaderPreview.indexOf("areacode")]
                                  }})
                                  {{ item[listHeaderPreview.indexOf("phone")] }}
                                </div>
                                <div class="col-lg-9 text-muted">
                                  {{ cutMailingPreviewMessage(previewMessage[i]) }}
                                </div>
                                <div class="col-lg-1 text-muted">
                                  {{ mailingMessageCounter(previewMessage[i]) > 160 ? 160 : mailingMessageCounter(previewMessage[i])
                                  }}
                                </div>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <app-button
                        :blocked="!file && false"
                        :loading="loading && loadingContext == 'create'"
                        class="w-100 col-lg-12 mt-3"
                        style="min-height: 0px"
                        @click="createMailing()"
                      >
                        {{ $t("conclude") }}
                      </app-button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <a
      class="btn btn-sm btn-primary px-5"
      href="#"
      @click="handleModalAndIntercom"
    >
      {{ $t("add_list") }}
    </a>
  </div>
</template>
<script>
import { Modal, Tab } from "bootstrap";
import { generateTooltip, mailingMessageCounter, cutMailingPreviewMessage } from "@/utils.js";
import _ from "lodash";
import flatPickr from "vue-flatpickr-component";
import FileUpload from "@components/file-uploader.vue";
import AppButton from "@components/app-button.vue";
import { mapActions, mapGetters, mapMutations } from "vuex";
import moment from "moment";
import HeaderComponent from "@/components/header-component.vue";
import localeFlatPicker from "@/mixins/localeFlatPicker";

export default {
  emits: ["success"],
  mixins: [localeFlatPicker],
  data() {
    return {
      columnsTypes: {
        "": "",
        identifier: this.$t("identifier"),
        areacode: this.$t("area_code"),
        phone: this.$t("phone"),
        areacodephone: this.$t("area_code_phone"),
      },
      modal: null,
      file: null,
      csv: [],
      header: [],
      actives: [],
      fields: {
        separator: ";",
        delimiter: "quotes",
        data: [],
        header: [],
        has_header: true,
        file_name: "",
        date: moment().format("YYYY-MM-DD"),
        name: "",
        message: "",
      },
      flatPickrPlaceHolder: moment().format("YYYY-MM-DD"),
      tab_message: null,
      validatedVariables: [],
      unvalidVariables: ["areacode", "phone", "areacodephone", "identifier"],
      options: [""],
      previewMessage: ["", "", "", "", ""],
      isDisabled: true,
      tooltip: null,
      exceededTextArea: null,
    };
  },

  components: {
    FileUpload,
    AppButton,
    flatPickr,
    HeaderComponent,
  },
  watch: {
    fileName(val) {
      this.fields.file_name = val;
    },

    listHeaderPreview(val) {
      this.fields.header = val;
    },

    listDataPreview(val) {
      this.actives = new Array(val[0].length).fill(true);
      this.fields.data = val;
    },
  },

  computed: {
    ...mapGetters("manager/sms_dashboard", {
      listDataPreview: "getListDataPreview",
      listHeaderPreview: "getListHeaderPreview",
      fileName: "getFileName",
      list: "getList",
      data: "getChartData",
      pagination: "getPagination",
      loading: "isLoading",
      errors: "getErrors",
      loadingContext: "getloadingContext",
      validatedMailingListData: "getValidatedMailingListData",
    }),
  },

  mounted() {
    this.tab_message = new Tab(this.$refs["tab-message"]);
    this.modal = new Modal(this.$refs.modal);
    this.generateTooltips();
  },

  methods: {
    mailingMessageCounter,
    cutMailingPreviewMessage,
    generateTooltip,
    ...mapActions("manager/sms_dashboard", [
      "uploadMailingList",
      "validateMailingList",
      "createMailingList",
      "getMailingList",
    ]),
    ...mapActions("manager/list", ["validateListFile"]),
    ...mapMutations("auth", ["trackEvents"]),

    handleModalAndIntercom() {
      this.trackEvents("[Manager] Send SMS List")
      this.modal.toggle()
    },

    validate() {
      let formData = new FormData();
      formData.append("mailing", this.file);
      formData.append("separator", this.fields.separator);
      formData.append("delimiter", this.fields.delimiter);
      formData.append("has_header", this.fields.has_header ? 1 : 0);
      this.uploadMailingList({ formData }).then(() => {
        this.scroll();
      });
    },

    generateTooltips() {
      this.tooltip = this.generateTooltip(
          `${this.$t("max_160_char")} <br> ${this.$t("text_impact")}`,
          this.$refs.tooltip,
      );
    },

    addOnTextArea(data) {
      this.fields.message = this.fields.message + `{{${data}}}`;
    },

    removeFromMessage(item) {
      let index = this.fields.message.lastIndexOf(item);
      let arr = [...this.fields.message];
      arr.splice(index - 2, item.length + 4);
      this.fields.message = arr.join("").toString();
    },

    isVariableUsed(item) {
      return this.fields.message.includes(`{{${item}}`);
    },

    submit() {
      this.validateMailingList({
        name: this.fields.name,
        schedule: this.fields.date + " 00:00:00",
        mailing: this.file,
        separator: this.fields.separator,
        delimiter: this.fields.delimiter,
        has_header: this.fields.has_header,
      }).then(() => {
        this.validatedVariables = this.listHeaderPreview;
        this.tab_message.show();
        this.$emit("success");
      });
    },

    createMailing() {
      this.createMailingList({
        data: this.listDataPreview,
        delimiter: this.fields.delimiter,
        file_name: this.fields.file_name,
        has_header: this.fields.has_header,
        header: this.listHeaderPreview,
        mailing: {},
        message: this.fields.message,
        name: this.fields.name,
        schedule: this.fields.date + " 00:00:00",
        separator: this.fields.separator,
      }).then(() => {
        window.location.reload();
      });
    },

    selectFile(file) {
      this.file = file;
    },

    hasType(val) {
      return (
          val == "identifier" ||
          val == "areacode" ||
          val == "phone" ||
          val == "areacodephone"
      );
    },

    scroll() {
      if (this.$refs.preview) {
        var top = this.$refs.preview.offsetTop;
        this.$refs.modal.scrollTo(0, top);
      }
    },

    stractVariable: _.debounce(function () {
      this.previewMessage = new Array(Math.min(this.listDataPreview.length, 5)).fill("");

      let variables = [...this.fields.message.matchAll(/{{[a-zA-Z\s]*}}/g)];
      let temp = [{}, {}, {}, {}, {}];
      for (let item of variables) {
        let variable = item[0].replace("{{", "").replace("}}", "");
        let variableIndex = this.listHeaderPreview.indexOf(variable);
        if (variableIndex > -1)
          for (let i in this.previewMessage)
            temp[i][variable] = this.listDataPreview[i][variableIndex];
      }
      for (let i in this.previewMessage) {
        this.previewMessage[i] = this.fields.message;
        for (let variable of Object.keys(temp[i])) {
          this.previewMessage[i] = this.previewMessage[i].replaceAll(
              "{{" + variable + "}}",
              temp[i][variable],
          );
        }
      }
    }, 300),
  },
};
</script>

<style lang="scss" scoped>
.tooltip {
  --tooltip-color: #2C7BE5;
}

.modal {
  z-index: 1059 !important;
  background: rgba(68, 68, 68, 0.4) !important;
}

.mailing-form {
  .form-background {
    background-color: #f9fbfd;
  }
}

ul {
  list-style-type: none;
}

.multiselect {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.multiselect__select {
  visibility: hidden;
}

.multiselect__tag {
  padding: 4px 10px 4px 10px !important;
}

.multiselect__tag-icon {
  display: none;
}
</style>
