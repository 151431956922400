<template>
  <tr
    @mouseover="hoverEvent = true"
    @mouseout="hoverEvent = false"
  >
    <td>
      <div class="form-check form-switch ps-0 d-flex align-items-center">
        <input
          class="form-check-input ms-0"
          type="checkbox"
          role="switch"
          :checked="product.is_active"
          @click.prevent="handleIsActive"
        >
        <label
          style="margin: 0.688rem 0"
          class="fw-medium"
        >
          <span
            ref="tooltipName"
            class="line-clamp fw-medium"
          >
            {{ product.name }}
          </span>
        </label>
      </div>
    </td>
    <td>
      <div class="d-inline-block">
        <span
          ref="tooltipCode"
          class="line-clamp"
        >
          {{ product.code }}
        </span>
      </div>
    </td>
    <td>
      <div
        ref="tooltipPrice"
        class="d-inline-flex"
      >
        <span
          class="fs-6 fw-medium money-badge mb-0 text-center lh-1 d-inline-block "
        >
          {{ getCurrencySymbol }}
        </span>
        <span class="line-clamp">{{ formatMoney(product.price) }}</span>
      </div>
    </td>
    <td>
      <div class="d-flex align-items-center">
        <div
          style="margin: 0.688rem 0"
          class="col line-clamp fs-6 align-content-center description"
          ref="tooltipDescription"
        >
          {{ product.description }}
        </div>
        <button
          v-show="hoverEvent"
          @click="handleEdit"
          class="btn btn-edit col-auto"
        >
          <i class="fa-regular fa-pen" />
        </button>
      </div>
    </td>
  </tr>
</template>

<script>
import {generateTooltip, Utils} from "@/utils";
import {mapActions} from "vuex";

export default {
  name: 'ProductComponent',

  props: {
    product: {
      type: Object,
      required: true
    },
    show: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {
      hoverEvent: false,
      currencies: {
        BRL: {
          value: "BRL",
          locale: "pt-BR",
        },
        EUR: {
          value: "EUR",
          locale: "en-EN",
        },
        USD: {
          value: "USD",
          locale: 'en-US',
        }
      }
    }
  },

  methods: {
    ...mapActions("manager/products_and_services", [
      "handleProductData",
      "toggleEdit",
      "toggleCategoryModal",
      "toggleModal",
      "updateProduct",
      "updateExclude"
    ]),
    formatCurrency: Utils.formatCurrency,
    generateTooltip,

    formatMoney(value) {
      const locale = this.currencies[this.product.currency].locale || "BRL";
      const currency = this.currencies[this.product.currency].value || "pt-BR";

      return this.formatCurrency(parseFloat(value), locale, currency).replace(this.getCurrencySymbol, "")
    },

    handleEdit() {
      this.handleProductData({
        ...this.product,
        price: parseFloat(this.product.price)
      })

      this.toggleCategoryModal({
        title: this.$t('edit_product'),
        button: this.$t('capitalizes.save'),
        type: "product",
        actionName: "updateProduct",
        handleName: "handleProductData",
        data: {
          name: "",
          code: "",
          description: null,
          currency: "",
          is_recurrent: false,
          price: 0,
          maximum_discount: 0,
          is_active: true,
          category_id: ""
        },
        dataState: "productDataState",
        exclude: {
          title: this.$t('deactivate_product'),
          name: this.product.name,
          actionName: "deleteProduct"
        }
      })
      this.toggleEdit(true)
      this.toggleModal(true)
    },

    handleIsActive(event) {
      this.toggleCategoryModal({
        data: {
          name: "",
          code: "",
          description: null,
          currency: "",
          is_recurrent: false,
          price: 0,
          maximum_discount: 0,
          is_active: true,
          category_id: ""
        },
        dataState: "productData",
        exclude: {
          title: this.$t('deactivate_product'),
          name: this.product.name,
          actionName: "updateProduct",
        }
      })


      const data = {
        ...this.product,
        is_active: event.target.checked,
      }

      this.handleProductData(data)

      if (!event.target.checked) {
        this.updateExclude(true)
        return
      }

      this.updateProduct(data).then(() => {
        event.target.checked = !event.target.checked
      })
    },
  },


  computed: {
    getCurrencySymbol() {
      const locale = this.currencies[this.product.currency].locale || "BRL";
      const currency = this.currencies[this.product.currency].value || "pt-BR";

      return (0).toLocaleString(locale, {
        style: 'currency',
        currency,
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
      }).replace(/\d/g, '').trim()
    }
  },

  mounted() {
    if (this.product.name) {
      this.generateTooltip(
        this.product.name,
        this.$refs.tooltipName,
        'bottom'
      );
    }

    if (this.product.code) {
      this.generateTooltip(
        this.product.code,
        this.$refs.tooltipCode,
        'bottom'
      );
    }

    if (this.product.price) {
      const locale = this.currencies[this.product.currency].locale || "BRL";
      const currency = this.currencies[this.product.currency].value || "pt-BR";
      this.generateTooltip(
        this.formatCurrency(parseFloat(this.product.price), locale, currency),
        this.$refs.tooltipPrice,
        'bottom'
      );
    }

    if (this.product.description) {
      this.generateTooltip(
        this.product.description,
        this.$refs.tooltipDescription,
        'bottom'
      );
    }
  }
}
</script>

<style lang="scss" scoped>
.money-badge {
  border-radius: 4px;
  background-color: #E1E9F4;
  padding: 4px;
  color: #373753;
  margin-right: 8px;
}

.form-check-input {
  width: 1.75rem;
  min-width: 1.75rem;
  height: 1rem;
  margin-right: 1rem;
}

.btn-edit {
  background-color: transparent;
  color: #677C92;

  &:hover,
  &:focus,
  &:focus-visible {
    background-color: #E1E9F4;
  }
}

.line-clamp {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  word-break: break-word;
}

.table {
  align-content: center;
}

tbody {
  tr {
    td {
      &:first-child {
        padding-left: 0;
      }

      &:last-child {
        padding-right: 0;
      }
    }

    &:first-child {
      td {
        border-top: 0 !important;
      }
    }
  }

  td {
    padding: 0.375rem 1rem;
  }
}
</style>