<template>
  <div
    class="d-flex align-items-center"
    style="height: 160px"
  >
    <div
      class="card mb-0"
      style="min-width: 296px;"
    >
      <div
        class="card-body d-flex align-items-center"
        style="padding: 1rem"
      >
        <action-component
          icon-bg="#E1E9F4"
          icon-class="fa-regular fa-microchip-ai"
          icon-color="#3057F2"
          :name="$t('answer_with_ai')"
        />
        <app-button
          class="btn-md col-auto"
          color="btn-gray-blue"
          @click="editAction"
        >
          <span class="fa-regular fa-pen-to-square" />
        </app-button>
      </div>
    </div>
    <question-arrow />
  </div>
</template>

<script>
import {mapMutations} from "vuex";
import ActionComponent from "../action-component.vue";
import AppButton from "@components/app-button.vue";
import QuestionArrow from "@chatbot/pages/workflow/components/arrows/question-arrow.vue";

export default {
  name: "CardSdr",

  components: {QuestionArrow, AppButton, ActionComponent},

  props: {
    action: {
      type: Object,
      required: true
    },
  },


  methods: {
    ...mapMutations("chatbot/actions", [
      "setOffcanvasEditActionIsOpen",
      "setActionToEdit"
    ]),

    editAction() {
      this.setActionToEdit(this.action)
      this.setOffcanvasEditActionIsOpen(true)
    }
  }
};
</script>
