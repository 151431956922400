import axiosOmni from "@/plugins/axios-omni.js"

export default {

 /**
 * Find contacts
 *
 * @param {*} param0
 * @returns
 */
  findContacts({ commit, getters }) {
    commit('startLoading', 'findContacts');
    return new Promise((resolve, reject) => {
      axiosOmni.get('/whatsapp/contacts', { params: getters.getParams })
        .then(response => {
          commit('addContacts', response.data.data);
          commit('setPagination', response.data.meta.pagination);
          resolve();
        }).catch((response) => {
          commit('system/setErrorMessage', response.response.data, { root: true })
          reject();
        }).finally(() => {
          commit('stopLoading', 'findContacts');
        });
    })
  },


 /**
 * Find contacts
 *
 * @param {*} param0
 * @returns
 */
 findContactByID({ commit }, payload) {
  commit('setContact', null);
  commit('startLoading', 'findContactByID');
  return new Promise((resolve, reject) => {
    axiosOmni.get('/whatsapp/contacts/' + payload.id )
      .then(response => {
        commit('setContact', response.data.data);
        resolve();
      }).catch((response) => {
        commit('system/setErrorMessage', response.response.data, { root: true })
        reject();
      }).finally(() => {
        commit('stopLoading', 'findContactByID');
      });
  })
},


  /**
   * Update contact
   *
   * @param {*} param0
   * @param {*} payload
   * @returns
   */
  //TODO: remove "Info"
  updateContactInfo({ commit }, payload) {
    commit('clearErrors')
    commit('startLoading', 'editContact');
    return new Promise((resolve, reject) => {
      axiosOmni.put(`/whatsapp/contacts/${payload.id}`, payload.fields)
        .then((response) => {
          commit('system/setSuccessMessage', response.data.detail, { root: true })
          commit('setContact', response.data.data)
          resolve(response.data.data);
        }).catch((response) => {
          commit('system/setErrorMessage', response.response.data, { root: true })
          response.response.status == 422 && commit('setErrors', response.response.data);
          reject();
        })
        .finally(() => {
          commit('stopLoading', 'editContact');
        });
    });
  },

  findContactInstances({ commit }, number) {
    commit("startLoading", "findContactInstances");
    commit("setContactInstances", null);
    return new Promise((resolve, reject) => {
      axiosOmni
        .post("/whatsapp/instance/check_contact_number", {phone: number})
        .then(response => {
          commit("setContactInstances",response.data.data.instances);
          resolve();
        })
        .catch(response => {
          commit("system/setErrorMessage", response.response.data, {
            root: true
          });
          reject();
        })
        .finally(() => {
          commit("stopLoading", "findContactInstances");
        });
    });
  }
}
