<template>
  <div
    class="d-flex align-items-center"
    style="height: 160px"
  >
    <div
      class="card mb-0"
      style="min-width: 350px"
    >
      <div class="card-body p-0">
        <div
          class="d-flex align-items-center"
          style="padding: 1rem 1rem 0 1rem; width: 350px"
        >
          <action-component
            icon-bg="#E1E9F4"
            icon-class="fa-regular fa-play"
            icon-color="#3057F2"
            :name="$t('start')"
          />
          <app-button
            v-if="action.need_channel"
            class="btn-md col-auto"
            color="btn-gray-blue"
            @click="$emit('openModal')"
          >
            <span class="fa-regular fa-plus" />
          </app-button>
        </div>
        <hr class="w-100">
        <div
          class="d-flex flex-column justify-content-center"
          style="padding: 0 1rem 1rem 1rem; width: 350px"
        >
          <app-button
            v-if="action.need_channel"
            class="border-0 w-100"
            color="btn-outline-primary"
            @click="$emit('openModal')"
          >
            + {{ $t('channel_select') }}
          </app-button>
          <div v-else>
            <p class="m-0 tx-text-gray">
              Canal
            </p>
            <p class="m-0">
              {{ groupChannel }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <default-arrow />
  </div>
</template>

<script>
import ActionComponent from "@chatbot/pages/workflow/components/action-component.vue";
import AppButton from "@components/app-button.vue";
import DefaultArrow from "@chatbot/pages/workflow/components/arrows/default-arrow.vue";
import {mapGetters} from "vuex";

export default {
  name: "CardStarter",

  emits: ["openModal"],

  components: {
    DefaultArrow,
    AppButton,
    ActionComponent
  },

  props: {
    action: {
      type: Object,
      required: true
    }
  },

  computed: {
    ...mapGetters("chatbot/chatbots", {
      chatbot: "getChatbot",
    }),

    groupChannel() {
      return this.chatbot.group_channel_name
    }
  }
}
</script>
