<template>
  <div class="container-fluid container-page">
    <header-component
      class="container-header"
      redirect-link="/manager/chatbot"
      :title="$t('back_capitalize')"
    >
      <template #buttons>
        <app-button
          v-if="isManager"
          color="btn-light me-4"
          @click="modalSettingsIsOpen = true"
        >
          <span class="fa-regular fa-gear" />
        </app-button>
        <template v-if="isManager">
          <app-button
            style="width: 200px"
            @click="$router.push('/manager/chatbot')"
          >
            {{ chatbotChanged ? $t('save_and_exit') : $t('exit') }}
          </app-button>
        </template>
      </template>
    </header-component>

    <workflow-container>
      <template v-if="!loadingFlags.createMultiChoiceAction">
        <card-starter
          v-if="actionStarter"
          :action="actionStarter"
          @open-modal="offcanvasSelectChannelIsOpen = true"
        />
        <card-actions
          v-if="actionStarter?.next_action_id"
          :key="actionStarter.next_action_id"
          :action="getAction(actionStarter.next_action_id)"
        />
        <button-new-action
          v-else-if="!actionStarter?.need_channel"
          :action-id="actionStarter?.id"
        />
      </template>
    </workflow-container>
  </div>

  <offcanvas-select-channel
    :show="offcanvasSelectChannelIsOpen"
    @close="offcanvasSelectChannelIsOpen = false"
  />
  <offcanvas-new-action :show="offcanvasNewActionIsOpen" />
  <offcanvas-edit-action :show="offcanvasEditActionIsOpen" />
  <modal-settings
    :is-open="modalSettingsIsOpen"
    @close="modalSettingsIsOpen = false"
  />
</template>

<script>
import {mapActions, mapGetters, mapMutations} from "vuex";

import HeaderComponent from "@/components/header-component.vue";
import AppButton from "@components/app-button.vue";
import ModalSettings from "@chatbot/pages/workflow/components/modal-settings.vue";
import OffcanvasSelectChannel from "@chatbot/pages/workflow/components/offcanvas/offcanvas-select-channel.vue";
import OffcanvasNewAction
  from "@chatbot/pages/workflow/components/offcanvas/offcanvas-new-action/offcanvas-new-action.vue";
import CardStarter from "@chatbot/pages/workflow/components/action-cards/card-starter.vue";
import OffcanvasEditAction
  from "@chatbot/pages/workflow/components/offcanvas/offcanvas-edit-action/offcanvas-edit-action.vue";
import CardActions from "@chatbot/pages/workflow/components/action-cards/card-actions.vue";
import ButtonNewAction from "@chatbot/pages/workflow/components/button-new-action.vue";
import WorkflowContainer from "@chatbot/pages/workflow/components/workflow-container.vue";


export default {
  name: "ChatbotWorkflow",

  components: {
    WorkflowContainer,
    ButtonNewAction,
    CardActions,
    OffcanvasEditAction,
    CardStarter,
    OffcanvasNewAction,
    OffcanvasSelectChannel,
    ModalSettings,
    AppButton,
    HeaderComponent
  },

  data() {
    return {
      modalSettingsIsOpen: false,
      offcanvasSelectChannelIsOpen: false,
      chatbotChanged: null,
    }
  },

  created() {
    this.fetchChatbot(this.$route.params.id)
    this.fetchGroupChannels()
    window.Intercom('update', {
      "hide_default_launcher": true
    });
  },

  unmounted() {
    this.setZoom(1.0)
    window.Intercom('update', {
      "hide_default_launcher": false
    });
  },

  watch: {
    actions: {
      handler() {
        if (this.chatbotChanged === null) {
          this.chatbotChanged = false
          return
        }
        this.chatbotChanged = true
      }, deep: true
    }
  },

  computed: {
    ...mapGetters("chatbot/chatbots", {
      chatbot: "getChatbot",
    }),

    ...mapGetters("chatbot/actions", {
      actions: "getActions",
      loadingFlags: "getLoadingFlags",
      offcanvasEditActionIsOpen: "getOffcanvasEditActionIsOpen",
      offcanvasNewActionIsOpen: "getOffcanvasNewActionIsOpen"
    }),

    ...mapGetters('auth', {
      isManager: "isManager"
    }),

    actionStarter() {
      return this.actions.find(action => action.type == "starter")
    }
  },

  methods: {
    ...mapMutations("chatbot/actions", [
      "setOffcanvasNewActionIsOpen",
      "setPrevActionId",
      "setZoom"
    ]),
    ...mapActions("system", ["showConfirmationMessage"]),
    ...mapActions("chatbot/chatbots", ["fetchChatbot",]),
    ...mapActions("chatbot/actions", ["fetchGroupChannels"]),

    getAction(actionId) {
      return this.actions.find(action => action.id == actionId)
    }
  }
}
</script>

<style scoped>
.container-page {
  height: 100vh;
  position: relative;
  background-image: url("~@/assets/img/background-chatbot.svg");
}

.container-header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding: 0 36px !important;
  z-index: 1;
  background-color: white;
}
</style>
