<template>
  <div
    ref="newActivityTooltip"
    :class="getActivityClass"
    class="activities-preview d-flex align-items-center justify-content-center"
    style="font-size: 12px"
    @click="newActivity"
  >
    <i
      class="fa-regular fa-calendar"
    />
    <span
      v-if="this.activities.total > 0"
      class="fw-medium"
    >
      {{ this.activities.total }}
    </span>
    <i
      v-else
      class="fa-regular fa-plus"
    />
  </div>
</template>

<script>
import {generateTooltip} from "@/utils";

export default {
  name: "CardActivityBadge",

  props: {
    activities: {type: Object, required: true},
    hasTooltip: {type: Boolean, default: false},
  },

  emits: ['newActivity'],

  data() {
    return {
      newActivityTooltip: null
    }
  },

  computed: {
    getActivityClass() {
      if (this.activities.has_late && this.activities.total > 0) {
        return 'late-activity';
      }

      if (!this.activities.has_late && this.activities.total > 0) {
        return 'current-activity';
      }

      return 'new-activity'
    }
  },

  methods: {
    generateTooltip,

    newActivity(event) {
      event.stopPropagation();
      this.$emit('newActivity');
    }
  },

  mounted() {
    if (!this.hasTooltip) return;
    this.newActivityTooltip = this.generateTooltip("Adicionar atividade", this.$refs.newActivityTooltip, 'bottom');
  }
}

</script>


<style lang="scss" scoped>
.activities-preview {
  border-radius: 50px;
  width: 36px;
  height: 22px;
  gap: 5px
}

.current-activity {
  background-color: $color-gray-blue-300;
  color: $color-blue-300;
}

.late-activity {
  background-color: $color-hover-danger;
  color: $color-red-300;
}

.new-activity {
  background-color: $color-gray-blue-300;
  color: $color-text-gray;

  &:hover {
    background-color: $color-gray-200;
  }
}
</style>