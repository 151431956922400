<template>
  <div class="socket-disconnection d-flex align-items-center justify-content-between gap-4 mx-auto">
    <div class="no-internet d-flex align-items-center justify-content-center rounded-circle bg-yellow-300">
      <i class="fa-regular fa-wifi-slash" />
    </div>
    <p class="mb-0">
      <strong class="me-1">{{ $t("no_connection") }}</strong>
      <span class="text-medium">
        {{ $t("no_connection_description") }}
      </span>
    </p>
  </div>
</template>

<script>

export default {
  name: 'NoInternetConnection',
}

</script>

<style lang="scss" scoped>
  .socket-disconnection {
    border-radius: 0;

    .no-internet {
      width: 40px;
      height: 40px;

      i {
        font-size: 14px;
      }
    }
  }
</style>
