<template>
  <div class="col-12">
    <header-component
      :title="$t('intervals')"
      :pretitle="$t('settings_uppercase')"
      redirect-link="/manager/voice-settings"
    >
      <template #buttons>
        <button
          class="btn btn-primary px-6"
          @click="$router.push('/manager/intervals/new')"
        >
          {{ $t('add_new_pattern') }}
        </button>
      </template>
    </header-component>

    <div class="card mb-0">
      <div class="card-header">
        <search-input
          @search="search"
          placeholder="search_breaks"
        />
      </div>
      <div class="card-body pb-0">
        <div class="row">
          <div
            class="col-12 col-md-3 mb-4"
            style="height: 72px;"
          >
            <div
              class="card card-new bg-gray-blue-300"
              @click="$router.push('/manager/intervals/new')"
              style="height: 72px;"
            >
              <div class="card-body">
                <div class="row align-items-center gx-0 h-100">
                  <div class="col overflow-ellipsis">
                    <p class="mb-0 tx-text-200">
                      {{ $t('add_new_pattern') }}
                    </p>
                  </div>
                  <div class="col-auto">
                    <i class="far fa-plus text-primary m-0" />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            class="col-12 col-md-3"
            v-if="loadingFlags.fetchWorkBreakGroups"
          >
            <div class="card-body text-center">
              <div class="spinner-border text-muted">
                <span class="visually-hidden" />
              </div>
            </div>
          </div>

          <template v-else>
            <div
              class="col-12 col-md-3 mb-4"
              v-for="(workBreakGroup, i) in workBreakGroups"
              :key="i"
              style="height: 72px;"
            >
              <div
                class="card card-list-item cursor-pointer"
                @click="$router.push({ path: `/manager/intervals/${workBreakGroup.id}/edit` })"
                style="height: 72px;"
              >
                <div
                  class="card-body"
                  style="padding: 16px;"
                >
                  <div class="row align-items-center">
                    <div class="col overflow-ellipsis">
                      <div class="text-large fw-bold overflow-ellipsis">
                        {{ workBreakGroup.name }}
                      </div>
                      <div class="tx-text-gray">
                        {{ workBreakGroup.intervals.length }} {{ $t("breaks") }}
                      </div>
                    </div>
                    <div class="col-auto">
                      <span class="h2 fe fe-chevron-right tx-text-gray mb-0" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </div>
      </div>
      <div
        v-if="pagination && pagination.total_pages > 1 && !loadingFlags.fetchWorkBreakGroups"
        class="card-footer"
      >
        <div class="col-12">
          <paginator
            :pagination="pagination"
            @change-page="changePage"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from "lodash";

import { mapActions, mapGetters } from "vuex";

import Paginator from "@/components/app-paginator.vue";
import HeaderComponent from "@/components/header-component.vue";
import SearchInput from "@/components/search-input.vue";

export default {
  components: {
    Paginator,
    HeaderComponent,
    SearchInput
  },
  mounted() {
    this.fetchWorkBreakGroups({
      include: ["intervals"]
    });
  },
  computed: {
    ...mapGetters("manager/intervals", {
      workBreakGroups: "getWorkBreakGroups",
      loadingFlags: "getLoadingFlags",
      pagination: "getPagination"
    })
  },
  methods: {
    ...mapActions("manager/intervals", ["fetchWorkBreakGroups"]),
    search: _.debounce(function(search) {
      this.fetchWorkBreakGroups({
        search: search,
        include: ["intervals"]
      });
    }, 500),
    changePage(page) {
      this.fetchWorkBreakGroups({
        page,
        include: ["intervals"]
      });
    }
  }
};
</script>
