<template>
  <div>
    <select
      :class="{ 'form-select': true, 'is-invalid': errors }"
      :disabled="loading"
      @focus="handleAmplitude"
      @input="$emit('update:modelValue', $event.target.value)"
      :value="modelValue"
      selectedIndex="0"
    >
      <template
        v-for="qualification in qualificationList"
        :key="qualification.id"
      >
        <option
          v-if="
            !showOnlyWhatsappQualifications ||
              (showOnlyWhatsappQualifications && qualification.type >= 2)
          "
          :value="qualification.id"
        >
          {{ qualification.name }}
        </option>
      </template>
    </select>
    <div class="invalid-feedback">
      {{ errors && errors[0] }}
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from "vuex";

export default {
  /**
   *
   */
  emits: ["update:modelValue"],

  /**
   *
   */
  mounted() {
    this.loadQualificationListDataSelect();
  },

  /**
   *
   */
  computed: {
    ...mapGetters("manager/qualification", {
      // dataSelect: "getDataSelect",
      qualificationListDataSelect: "getQualificationListDataSelect",
      qualificationList: "getEachQualificationListDataSelect",
      loading: "isLoading",
    }),
  },

  props: {
    /**
     *
     */
    modelValue: {
      default: "",
      type: [String, Number],
    },

    /**
     *
     */
    errors: {
      type: Array,
      default: null,
    },

    /**
     *
     */
    defaultSelectFirst: {
      type: Boolean,
      default: false,
    },

    /**
     *
     */
    showOnlyWhatsappQualifications: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  /**
   *
   */
  watch: {
    /**
     *
     */
    qualificationListDataSelect() {
      if (
        this.qualificationListDataSelect.length > 0 &&
        this.defaultSelectFirst
      )
        this.$emit("update:modelValue", this.qualificationListDataSelect[0].id);
    },
  },

  /**
   *
   */
  methods: {
    ...mapActions("manager/qualification", ["loadQualificationListDataSelect"]),
    ...mapMutations("auth", ["trackEvents"]),
    handleAmplitude(){
      this.trackEvents("[Manager] Edit qualification")
    }
  },
};
</script>
