<template>
  <div
    class="list-group list-group-flush talks"
    id="in-chatbot-tab"
    ref="in-chatbot-tab"
  >
    <contact-talk
      v-for="item in inChatbot"
      :key="item.id"
      :contact-item="item"
    />
    <infinity-pagination-observer
      v-if="validationObserver"
      :loading="loadingFlags.findInChatbot"
      parent-container="in-chatbot-tab"
      :threshold="0.5"
      margin="10px"
      @reload="nextPage"
    />
    <contact-skeleton-loading v-if="loadingFlags.findInChatbot" />
  </div>
  <div
    v-if="Object.values(inChatbot).length === 0 && !loadingFlags.findInChatbot"
    class="empty d-flex flex-column align-items-center"
  >
    <div
      v-if="isSearch"
      class="d-flex flex-column align-items-center"
      style="width: 180px"
    >
      <img
        src="@assets/img/talks-empty-state/search.svg"
      >
      <p class="text-center tx-text-gray tx-text-300">
        {{ $t('empty_search') }}
      </p>
    </div>
    <div
      v-else-if="isFilter"
      class="d-flex flex-column align-items-center"
      style="width: 185px"
    >
      <img
        src="@assets/img/talks-empty-state/filter.svg"
      >
      <p class="text-center tx-text-gray mb-2 fw-bolder fs-5">
        {{ $t('empty_filter') }}
      </p>
      <p class="text-center tx-text-gray fs-6">
        {{ $t('empty_filter_2') }}
      </p>
    </div>
    <div
      v-else
      class="d-flex flex-column align-items-center"
      style="width: 160px"
    >
      <img
        src="@assets/img/talks-empty-state/finished.svg"
      >
      <p class="text-center tx-text-gray tx-text-300">
        {{ $t('empty_finished') }}
      </p>
    </div>
  </div>
</template>

<script>
import ContactTalk from "../contacts-types/contact-talk.vue";
import ContactSkeletonLoading from "../contacts-types/contact-skeleton-loading.vue";
import { mapActions, mapGetters } from "vuex";
import InfinityPaginationObserver from "@components/infinity-pagination-observer.vue";

export default {
  components: { InfinityPaginationObserver, ContactSkeletonLoading, ContactTalk },

  props: {
    modelValue: {
      type: Number,
      default: () => 0
    }
  },

  emits: ['update:modelValue'],

  mounted() {
    this.$nextTick(() => {
      document.getElementById("in-chatbot-tab").scrollTo(0, this.modelValue);
    });
  },

  computed: {
    ...mapGetters("whatsapp/chat", {
      inChatbot: "getInChatbot",
      pagination: "getInChatbotPagination",
      loadingFlags: "getLoadingFlags",
      filters: "getFilters"
    }),

    validationObserver() {
      return this.pagination.current_page <= this.pagination.total_pages && this.pagination.total_pages !== 1;
    },

    isSearch() {
      return this.filters.search !== "";
    },

    isFilter() {
      return Object.values(this.filters).some(value => value);
    }
  },

  methods: {
    ...mapActions("whatsapp/chat", ["findInChatbot", "incrementInChatbotCurrentPage"]),
    async fetchInChatbotChat() {
      this.incrementInChatbotCurrentPage();
      await this.findInChatbot();
    },
    nextPage() {
      if (this.pagination.current_page < this.pagination.total_pages) {
        this.fetchInChatbotChat();
      } else if (this.$refs["in-chatbot-tab"].scrollHeight < this.$refs["in-chatbot-tab"].clientHeight) {
        this.fetchInChatbotChat();
      }
    }
  },

  beforeUnmount() {
    this.$emit("update:modelValue", this.$refs["in-chatbot-tab"].scrollTop);
  }
};
</script>

<style scoped lang="scss">
.talks {
  font-family: "Inter", "Noto Emoji" !important;
  max-height: 85vh;
  overflow-y: auto;
}

::-webkit-scrollbar {
  width: 4px !important;
}

::-webkit-scrollbar-track {
  background: transparent !important;
}

::-webkit-scrollbar-thumb {
  background: #b0c5c2 !important;
  border-radius: 3px !important;
}

::-webkit-scrollbar-thumb:hover {
  background: #6e84a3 !important;
}

.empty {
  padding-top: 80px;

  img{
    margin-bottom: 20px;
  }
}
</style>
