<template>
  <div
    class="card"
  >
    <div class="card-header">
      <h4 class="card-header-title fw-normal lh-base">
        {{ $t('my_channel_groups') }}
      </h4>
      <ul
        class="nav nav-tabs card-header-tabs"
        role="tablist"
      >
        <li class="nav-item">
          <button
            class="nav-link text-medium active"
            id="active-tab"
            data-bs-toggle="tab"
            data-bs-target="#active"
            @click="tab = 'active'"
            type="button"
            role="tab"
            aria-controls="active"
            aria-selected="true"
            ref="tab-active"
            data-test="active-tab"
          >
            {{ $t('list_connections_active') }}
          </button>
        </li>
        <li class="nav-item">
          <a
            class="nav-link text-medium"
            id="deleted-tab"
            data-bs-toggle="tab"
            data-bs-target="#deleted"
            @click="tab = 'deleted'"
            type="button"
            role="tab"
            aria-controls="deleted"
            aria-selected="false"
            ref="tab-deleted"
            data-test="deleted-tab"
          >
            {{ $t('list_connections_deleted') }}
          </a>
        </li>
      </ul>
    </div>

    <div
      class="card-body pb-0"
      style="min-height: 264px"
    >
      <div class="tab-content">
        <div
          class="tab-pane fade show active"
          id="active"
          role="tabpanel"
        >
          <div class="row">
            <template v-if="groupChannels.loading">
              <div
                v-for="i in 16"
                :key="i"
                class="col-lg-6 col-xl-4 col-xxl-3"
              >
                <card-loading :height="56" />
              </div>
            </template>

            <template v-else>
              <connection-card
                v-for="item in groupChannels.data"
                :item="item"
                :key="item.id"
              />
              <div
                v-if="isManager &&
                  user.company.whatsapp_licenses > totalActive"
                @click="this.$router.push('/manager/whatsapp/new')"
                class="col-lg-6 col-xl-4 col-xxl-3"
                data-test="addNewConnection-card"
              >
                <div
                  class="card cursor-pointer card-add-new"
                >
                  <div
                    class="card-body d-flex align-items-center"
                  >
                    <div class="d-flex justify-content-between w-100">
                      <div
                        class="overflow-ellipsis"
                      >
                        <p class="m-0">
                          {{ $t('new_group_channels') }}
                        </p>
                      </div>
                      <span class="fe fe-plus tx-text-gray" />
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </div>
        </div>
        <div
          class="tab-pane fade"
          id="deleted"
          role="tabpanel"
        >
          <div class="row">
            <template v-if="groupChannels.loading">
              <div
                v-for="i in 12"
                :key="i"
                class="col-lg-6 col-xl-4 col-xxl-3"
              >
                <card-loading :height="56" />
              </div>
            </template>

            <template v-else>
              <connection-card
                v-for="item in groupChannels.data"
                :item="item"
                :key="item.id"
              />
            </template>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-12">
      <paginator
        :pagination="groupChannels.pagination"
        @change-page="changePage"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

import ConnectionCard from "./connection-card.vue";
import Paginator from "@/components/app-paginator.vue";
import CardLoading from "@/components/card-loading.vue";

export default {
  components: {
    ConnectionCard,
    Paginator,
    CardLoading
  },

  data() {
    return {
      tab: "active", // "deleted"
      loading: true,
    };
  },

  created() {
    this.findActiveGroupChannels(1)
    this.findDeletedGroupChannels(1)
  },

  computed: {
    ...mapGetters("manager/whatsapp_group_channel", {
      groupChannelsData: "getGroupChannels",
      groupChannelsPagination: "getGroupChannelsPagination",
      loadingFlags: "getLoadingFlags"
    }),

    ...mapGetters("manager/whatsapp", {
      totalActive: "getTotalActiveInstances"
    }),

    ...mapGetters("auth", {
      user: "getUser",
      isManager: "isManager",
    }),

    groupChannels() {
      if (this.tab == "active") {
        return {
          data: this.groupChannelsData.active,
          pagination: this.groupChannelsPagination.active,
          loading: this.loadingFlags.findActiveGroupChannels
        };
      }
      return {
        data: this.groupChannelsData.deleted,
        pagination: this.groupChannelsPagination.deleted,
        loading: this.loadingFlags.findDeletedGroupChannels
      };
    },
  },

  methods: {
    ...mapActions("manager/whatsapp_group_channel", ["findActiveGroupChannels", "findDeletedGroupChannels"]),

    changePage(page) {
      if (this.tab == "active") this.findActiveGroupChannels(page);
      else this.findDeletedGroupChannels(page);
    },
  },
};
</script>

<style scoped lang="scss">
.card-add-new {
  background-color: #F0F4FA;
  height: 56px;

  &:hover{
    background-color: $color-gray-blue-300;
  }

  .card-body {
    padding: $whatsapp-spacing-2;
  }
}
</style>
