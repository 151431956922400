<template>
  <div
    class="d-flex align-items-center"
    style="width: 210px;"
  >
    <svg
      width="40px"
      viewBox="0 0 40 16"
    >
      <line
        x1="0"
        y1="8"
        x2="40"
        y2="8"
        stroke="#5517E6"
        stroke-width="2"
      />
    </svg>
    <div
      class="d-flex align-items-center fw-medium fs-6 badge-blue"
    >
      <p class="m-0">
        {{ $t('after_answer') }}
      </p>
    </div>
    <svg
      width="40px"
      viewBox="0 0 40 16"
    >
      <line
        x1="0"
        y1="8"
        x2="39"
        y2="8"
        stroke="#5517E6"
        stroke-width="2"
      />
      <polygon
        points="32,0 40,8 32,16"
        fill="#5517E6"
        stroke-width="2"
      />
    </svg>
  </div>
</template>

<script>
export default {
  name: "QuestionArrow"
};
</script>

<style scoped lang="scss">
.badge-blue{
  gap: 8px;
  background-color: #FFFFFF;
  border-radius: 10px;
  padding: 0.5rem 1rem;
  border: 1px solid #E1E9F4
}
</style>

