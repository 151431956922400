<template>
  <div class="container-fluid pb-4">
    <main-loading v-if="loadingFlags.findById" />
    <div v-else-if="register">
      <header-component
        :title="register.name"
        :pretitle="$t('active_ivr')"
        redirect-link="/manager/ivrs"
      />
      <div class="card mb-2-25r">
        <div class="card-body">
          <div class="d-flex align-items-center gap-3 border-bottom mb-1-5r pb-1r">
            <badge-icon
              background-color="#E1E9F4"
              color="tx-blue-300"
              icon="fal fa-gear"
            />
            <h5 class="fw-normal mb-0">
              {{ $t('general_config') }}
            </h5>
          </div>
          <div class="row">
            <div class="col-12 col-md-6">
              <div class="form-group">
                <label class="form-label required">{{ $t('capitalizes.name') }}</label>
                <input
                  v-model="fields.name"
                  :class="{ 'is-invalid': errors.name }"
                  class="form-control"
                  :placeholder="$t('capitalizes.name')"
                  type="email"
                >
                <div class="invalid-feedback">
                  {{ errors.name && errors.name[0] }}
                </div>
              </div>
            </div>
            <div class="col-12 col-md-6">
              <div class="form-group">
                <label class="form-label required">{{ $t('attendance_schedule') }}</label>
                <single-select
                  id="uraSelect"
                  :list-array="officeHours"
                  label="name"
                  :current-option="initialFormData.office_hour?.name"
                  @selected-option="event => fields.office_hour_id = event.id"
                  :z-index="1001"
                />
              </div>
            </div>
          </div>
          <div class="d-flex align-items-center gap-3 border-bottom mb-1-5r pb-1r">
            <badge-icon
              background-color="#E1E9F4"
              color="tx-blue-300"
              icon="fal fa-volume"
            />
            <h5 class="fw-normal mb-0">
              {{ $t('audio_capitalize') }}
            </h5>
          </div>
          <div class="form-group">
            <label
              class="form-label required"
              for="name"
            >{{ $t("audio_capitalize") }}</label>
            <div class="card mb-0 border-0">
              <div class="text-center my-1 text-muted">
                <small> {{ register.original_audio_name }}</small>
                <audio
                  class="w-100 mb-4"
                  controls
                >
                  <source
                    :src="getFullAudioPath()"
                    type="audio/mpeg"
                  >
                </audio>
              </div>
              <file-upload
                filter="audio/mp3,audio/mpeg"
                icon="far fa-headphones m-0"
                @select-file="file => fields.audio = file"
              />
            </div>
            <div class="invalid-feedback">
              {{ errors.audio && errors.audio[0] }}
            </div>
          </div>
          <div class="d-flex align-items-center gap-3 border-bottom mb-1-5r pb-1r">
            <badge-icon
              background-color="#E1E9F4"
              color="tx-blue-300"
              icon="fa-regular fa-star"
            />
            <h5 class="fw-normal mb-0">
              {{ $t('key_configuration') }}
            </h5>
          </div>
          <div class="form-group mb-3r">
            <div class="d-flex gap-1 align-items-center mb-1r">
              <label class="form-label required mb-0">{{ $t('action_keys') }}</label>
            </div>
            <div
              aria-label="First group"
              class="btn-group mr-2 w-100"
              role="group"
            >
              <button
                v-if="fields.redirect_type !== OMNI_REDIRECT_ID"
                :class="{
                  'btn-outline-secondary': !keyAny[0],
                  'btn-primary': keyAny[0]
                }"
                class="btn py-1 px-3 rounded fw-medium fs-6 btn-key"
                @click="toggleNone(0)"
              >
                {{ $t('capitalizes.none') }}
              </button>
              <button
                v-for="(i, index) in 10"
                :key="index"
                :class="{
                  'btn-outline-secondary': !fields.keys[index],
                  'btn-primary': fields.keys[index],
                  'me-0': i === 10,
                  'opacity-25': keyNone
                }"
                :disabled="keyNone"
                class="btn p-1 mx-2 rounded btn-key"
                type="button"
                @click="fields.keys[index] = !fields.keys[index]"
              >
                {{ index }}
              </button>
            </div>
            <div class="invalid-feedback">
              {{ errors.keys && errors.keys[0] }}
            </div>
          </div>
          <div class="mb-5">
            <label class="form-label required">{{ $t('waiting_time_to_press_key') }}</label>
            <div class="form-group">
              <input-text-range
                v-model="fields.wait_time"
                :disabled="keyNone"
                :min="5"
                :max="10"
                :ura="true"
                color="#2C7BE5"
              />
              <div
                v-if="errors.wait_time"
                class="text-danger text-medium mt-3"
              >
                {{ errors.wait_time && errors.wait_time[0] }}
              </div>
            </div>
          </div>
          <div class="d-flex align-items-center gap-3 border-bottom mb-1-5r pb-1r">
            <badge-icon
              background-color="#E1E9F4"
              color="tx-blue-300"
              icon="fa-regular fa-sliders-simple"
            />
            <h5 class="fw-normal mb-0">
              {{ $t('advanced_configuration') }}
            </h5>
          </div>
          <div class="form-group ">
            <label class="form-label required">{{ $t('targeting') }}</label>
            <single-select
              :error="errors.redirect_type && errors.redirect_type[0]"
              :current-option="redirectTypes.find(type => type.id === fields.redirect_type).name || ''"
              id="redirectType"
              :list-array="redirectTypes"
              label="name"
              @selected-option="setRedirect"
              :z-index="1047"
            />
          </div>
          <div
            v-if="fields.redirect_type === OMNI_REDIRECT_ID"
            class="form-group"
          >
            <label class="form-label required">{{ $t('group_channels') }}</label>
            <select-channel
              style="z-index: 1045"
              :initial-channel="fields.instances[0]"
              :model-value="selectedChannel"
              @update:model-value="setChannel"
              :error="errors.instances && errors.instances[0]"
            />
          </div>
          <div
            v-if="fields.redirect_type === OMNI_REDIRECT_ID && selectedChannel"
            class="form-group "
          >
            <label class="form-label required">{{ $t('select_the_message') }}</label>
            <select-quick-message
              v-if="selectedChannel.type === 'ultramsg'"
              style="z-index: 1044"
              :initial-message="fields.instances[0].quick_message_id"
              :group-channel-id="selectedChannel.group_channel_id"
              :model-value="selectedMessage"
              :error="errors['instances.0.quick_message_id'] && errors['instances.0.quick_message_id'][0]"
              @update:model-value="setMessage"
            />
            <select-waba-template
              v-if="selectedChannel.type === 'waba'"
              style="z-index: 1044"
              :initial-message="fields.instances[0].template_id"
              :instance-id="selectedChannel.id"
              :model-value="selectedMessage"
              :error="errors['instances.0.template_id'] && errors['instances.0.template_id'][0]"
              @update:model-value="setMessage"
            />
          </div>
        </div>
      </div>
      <div class="text-center">
        <app-button
          :disabled="fields.keys.every(key => key === false) && fields.redirect_type === OMNI_REDIRECT_ID"
          :loading="loadingFlags.update"
          class="w-100 mb-1r"
          color="btn-primary"
          @click="submit"
        >
          {{ $t('capitalizes.save') }}
        </app-button>
        <app-button
          :loading="loadingFlags.remove"
          class="w-100"
          color="text-danger"
          @click="showModal = true;"
        >
          <span class="fal fa-trash" /> {{ $t('delete') }}
        </app-button>
      </div>
    </div>
  </div>
  <modal-delete-ivr
    :show-modal="showModal"
    :redirect-link="redirectLink"
    :modal-details="modalDetails"
    @update-show-modal="boolean => showModal = boolean"
    @update-navigation="boolean => LiberationNavigation = boolean"
  />
</template>

<script>
import {mapActions, mapGetters, mapMutations} from "vuex";

import MainLoading from "@/components/main-loading.vue";
import FileUpload from "@components/file-uploader";
import InputTextRange from "@components/input-text-range";
import AppButton from "@components/app-button";
import HeaderComponent from "@/components/header-component.vue";
import SingleSelect from "@components/single-select.vue";
import ModalDeleteIvr from "@dialer/manager/pages/settings/ivrs/components/modal-delete-ivr.vue";
import BadgeIcon from "@dialer/manager/pages/settings/ivrs/badge-icon.vue";
import SelectChannel from "@dialer/manager/pages/settings/ivrs/components/select-channel/select-channel.vue";
import SelectQuickMessage from "@dialer/manager/pages/settings/ivrs/components/select-quick-message.vue";
import SelectWabaTemplate from "@dialer/manager/pages/settings/ivrs/components/select-waba-template.vue";

export default {
  data() {
    return {
      fields: {
        name: "",
        wait_time: 5,
        audio: null,
        redirect_id: "",
        office_hour: {},
        keys: Array(10).fill(false),
        redirect_type: 1,
        instances: []
      },
      keyAny: Array(1).fill(false),
      keyNone: false,
      selectedChannel: null,
      selectedMessage: null,
      redirectTypes: [
        {
          name: this.$t('dialer_menu_capitalize'),
          id: 1
        },
        {
          name: this.$t('Omnichannel'),
          id: 2
        }
      ],
      WABA_INSTANCE_TYPE_ID: 2,
      ULTRAMSG_INSTANCE_TYPE_ID: 1,
      OMNI_REDIRECT_ID: 2,
      showModal: false,
      modalDetails: {
        header: this.$t('capitalizes.exclude'),
        title: this.$t('delete_ivr_title'),
        content: this.$t('delete_ivr_confirmation'),
        buttons: {
          firstText: this.$t('yes'),
          secondText: this.$t('cancel_general'),
          backgroundColor: "bg-danger",
          action: "delete"
        }
      },
      initialFormData: {},
      LiberationNavigation: false,
      redirectLink: "",
    };
  },

  created() {
    this.setRegister(null);
    this.findById({id: this.$route.params.id}).then(() => {
      this.fields.name = this.register.name;
      this.fields.audio = this.register.audio;
      this.fields.wait_time = this.register.wait_time ? this.register.wait_time : 5;
      this.fields.office_hour = this.register.office_hour;
      this.fields.redirect_type = this.register.redirect_type;
      this.fields.instances = this.register.instances;
      for (let item of this.register.keys) this.fields.keys[item.key] = true;
      this.initialFormData = JSON.parse(JSON.stringify(this.fields));
      this.noneKeys();
    });
    this.findOfficeHours();
  },

  computed: {
    ...mapGetters("manager/ivrs", {
      register: "getRegister",
      errors: "getErrors",
      loadingFlags: "getLoadingFlags",
    }),
    ...mapGetters("auth", {
      user: "getUser"
    }),
    ...mapGetters("manager/office_hours", {
      officeHours: "getOfficeHours",
    }),
  },

  components: {
    SelectWabaTemplate, SelectQuickMessage,
    SelectChannel,
    BadgeIcon,
    ModalDeleteIvr,
    SingleSelect,
    MainLoading,
    FileUpload,
    InputTextRange,
    AppButton,
    HeaderComponent
  },

  methods: {
    ...mapActions("manager/ivrs", ["update", "remove", "findById"]),
    ...mapActions("manager/office_hours", ["findOfficeHours"]),
    ...mapMutations("manager/ivrs", ["setRegister"]),
    ...mapMutations("system", ["setFlashMessage"]),

    getFullAudioPath() {
      return (
        process.env.VUE_APP_BA12AS.replace("/api/v1", "") + this.register.audio + "&api_token=" + this.user.api_token
      );
    },

    setRedirect(option){
      this.fields.redirect_type = option.id
      if (option.id === this.OMNI_REDIRECT_ID) {
        this.keyNone = false
        this.keyAny[0] = false
      }
    },

    setChannel(channel) {
      this.selectedMessage = null

      const instance = {
        instance_id: channel.id,
        group_channel_id: channel.group_channel_id,
        type: channel.type === 'ultramsg' ? this.ULTRAMSG_INSTANCE_TYPE_ID : this.WABA_INSTANCE_TYPE_ID
      }

      if (this.selectedChannel) {
        this.fields.instances[0] = instance;
      }
      else {
        this.fields.instances[0] = { ...this.fields.instances[0], ...instance }
      }

      this.selectedChannel = channel
    },

    setMessage(message) {
      this.selectedMessage = message
      let fields = {}

      if (this.selectedChannel.type === 'ultramsg') {
        fields = {
          quick_message_id: message.id,
          message: message.message,
        }
      }
      if (this.selectedChannel.type === 'waba') {
        fields = {
          template_id: message.id,
        }
      }

      this.fields.instances[0] = {...this.fields.instances[0], ...fields}
    },

    createFormData() {
      let formData = new FormData();
      if (this.fields.audio && typeof this.fields.audio === 'object') {
        formData.append("audio", this.fields.audio);
      }
      formData.append("_method", "PUT");
      formData.append("name", this.fields.name);
      formData.append("wait_time", this.fields.wait_time);
      formData.append("office_hour_id", this.fields.office_hour.id ? this.fields.office_hour.id : this.fields.office_hour);
      formData.append("redirect_type", this.fields.redirect_type);
      for (let item of this.fields.keys) {
        if (item === false || item === undefined) {
          formData.append("keys[]", item = false);
          continue;
        }
        formData.append("keys[]", item);
      }

      if (this.fields.redirect_type === this.OMNI_REDIRECT_ID) {
        this.fields.instances.forEach((instance, index) => {
          formData.append(`instances[${index}][instance_id]`, instance.instance_id);
          formData.append(`instances[${index}][group_channel_id]`, instance.group_channel_id);
          formData.append(`instances[${index}][type]`, instance.type);
          if (instance.type === this.ULTRAMSG_INSTANCE_TYPE_ID){
            formData.append(`instances[${index}][quick_message_id]`, instance.quick_message_id);
            formData.append(`instances[${index}][message]`, instance.message);
          }
          if (instance.type === this.WABA_INSTANCE_TYPE_ID) {
            formData.append(`instances[${index}][template_id]`, instance.template_id);
            formData.append(`instances[${index}][template][]`, []);
          }
        });
      }

      return formData;
    },

    submit() {
      let formData = this.createFormData();
      this.update({formData, id: this.register.id}).then(data => {
        this.LiberationNavigation = true;
        this.$router.push({ path: `/manager/ivrs` }).then(() => {
          this.setFlashMessage({
            message: data.message,
            type: "success"
          });
        });
      }).catch(data => {
        this.setFlashMessage({
          message: data.message,
          type: "danger"
        });
      });
    },

    toggleNone(key) {
      this.keyAny[key] = !this.keyAny[key];
      this.keyNone = !this.keyNone;
      if (this.keyNone) {
        this.fields.keys = this.fields.keys.map(() => false);
      } else {
        this.fields.keys = JSON.parse(JSON.stringify(this.initialFormData.keys));
      }
    },

    noneKeys() {
      let validateKeysActives = this.fields.keys.some(item => item === true);
      if (!validateKeysActives) {
        this.keyAny[0] = true;
        this.keyNone = true;
        return
      }
      this.keyNone = false;
      this.keyAny[0] = false;
    }
  },

  beforeRouteLeave(to, from, next) {
    if (from.fullPath !== to.fullPath) {
      if (JSON.stringify(this.initialFormData) !== JSON.stringify(this.fields) && !this.LiberationNavigation) {
        this.showModal = true;
        this.modalDetails = {
          header: this.$t('attention'),
          title: this.$t('pending_changes'),
          content: this.$t('pending_changes_confirmation'),
          buttons: {
            firstText: this.$t('exit_without_saving'),
            secondText: this.$t('continue_editing'),
            backgroundColor: "bg-primary",
            action: "previous page"
          }
        };
        next(false);
      } else {
        next();
      }
      this.redirectLink = to.fullPath;
    }
  }
};
</script>

<style scoped lang="scss">
.mb-3r {
  margin-bottom: 3rem;
}
.mb-2-25r {
  margin-bottom: 2.25rem;
}
.mb-1-5r {
  margin-bottom: 1.5rem;
}
.mb-1r {
  margin-bottom: 1rem;
}
.pb-1r {
  padding-bottom: 1rem;
}
.btn-key {
  width: 90px;
}
</style>