import store from "@/store";
import { playMediaStreamAudio, CallStatus, CallMode, AgentStatus } from "@/utils";
import audioPlayer from "@/plugins/audio-player";

/**
 * The function below handles all the PABX actions,
 * this was made to isolate the functionality and not
 * mix anything related to the current dialer.
 * @param { Object } session
 */
export function pabx(session) {
  const debug = localStorage.getItem("debug-front-end");

  if (session.connection) {
    session.connection.addEventListener("track", async data => {
      if (debug) console.log("[PABX] - track: ", data);
      const mediaStream = data.streams[0];
      playMediaStreamAudio(mediaStream);
      session.remoteMediaStream = mediaStream;
    });
  }

  session.on("peerconnection", data => {
    if (debug) console.log("[PABX] - peerconnection: ", data);

    data.peerconnection.addEventListener("addstream", data => {
      if (debug) console.log("[PABX] - addstream: ", data);
      playMediaStreamAudio(data.stream);
      store.commit("system/setJsSipStream", data.stream);
      session.remoteMediaStream = data.stream;
    });
  });

  session.on("connecting", data => {
    if (debug) console.log("[PABX] - connecting: ", data);
    if (session.direction === "outgoing") {
      store.dispatch("agent/ring_group/updateCallToDialingStatus", session);
    }
  });

  session.on("sending", data => {
    if (debug) console.log("[PABX] - sending: ", data);
  });

  session.on("progress", data => {
    if (debug) console.log("[PABX] - progress", data);
    const phone = session.remote_identity.uri.user;

    if (session.direction === "incoming") {
      audioPlayer.play("ringing", 1, true);
    }

    const findUserByTelephonyId = substrIndex => {
      const ringGroupUsers = store.getters["agent/ring_group/getRingGroupUsers"];
      for (let group of ringGroupUsers) {
        const user = group.users.find(user => user.telephony_id === phone.substr(substrIndex));
        if (user) {
          return user.extension_number;
        }
      }
    };

    if (phone.includes("consultant")) {
      let extensionNumber = findUserByTelephonyId(11);

      const call = {
        phone,
        extension_number: extensionNumber,
        status: CallStatus.RECEIVING_TRANSFERENCE
      };
      store.commit("system/setStatus", AgentStatus.ON_CALL);
      store.commit("system/setMode", CallMode.RECEPTIVE);
      store.commit("agent/ring_group/setCallPABX", call);
      store.dispatch("agent/ring_group/answerCallPABX");
      return;
    }

    if (phone.includes("internalcall")) {
      let extensionNumber = findUserByTelephonyId(13);

      const call = {
        phone,
        extension_number: extensionNumber,
        status: CallStatus.RECEIVING_INTERNAL_CALL
      };
      store.commit("system/setStatus", AgentStatus.ON_CALL);
      store.commit("system/setMode", CallMode.RECEPTIVE);
      store.commit("agent/ring_group/setCallPABX", call);
      return;
    }

    store.dispatch("agent/ring_group/handlePABXCallInProgress", session);
  });

  session.on("accepted", data => {
    if (debug) console.log("[PABX] - accepted: ", data);
    audioPlayer.stop("ringing");
  });

  session.on("confirmed", data => {
    if (debug) console.log("[PABX] - confirmed: ", data);
    audioPlayer.stop("ringing");
    if (data.ack) {
      /**
       * This action is called also in the websocket file because
       * at this point I don't have the full call object so I create
       * a smaller version to show the minimum information to the user
       */
      let callStatus;
      if (data.ack.from.uri.user.includes("consultant")) {
        callStatus = CallStatus.CONSULT_ANSWERED;
      } else if (data.ack.from.uri.user.includes("internalcall")) {
        callStatus = CallStatus.INTERNAL_CALL_ANSWERED;
      } else {
        callStatus = CallStatus.CONNECTED;
      }

      const callPABX = store.getters["agent/ring_group/getCallPABX"];
      let call = {};
      if ([CallStatus.CONSULT_ANSWERED, CallStatus.INTERNAL_CALL_ANSWERED].includes(callStatus)) {
        call = {
          phone: callPABX.phone,
          extension_number: callPABX.extension_number,
          status: callStatus,
          call_mode: "receptive"
        };
      } else {
        call = {
          phone: data.ack.from.uri.user,
          status: callStatus,
          call_mode: "receptive"
        };
      }

      store.dispatch("agent/ring_group/updateCallStatusAfterCallPABXWasAnswered", { call });
    }

    let mediaRecorder = null;
    let chunksLocal = [];

    try {
      const audioCtx = new AudioContext();
      const source1 = audioCtx.createMediaStreamSource(session._localMediaStream);
      const source2 = audioCtx.createMediaStreamSource(session.remoteMediaStream);
      const destination = audioCtx.createMediaStreamDestination();

      source1.connect(destination);
      source2.connect(destination);

      mediaRecorder = new MediaRecorder(destination.stream, {
        mimeType: "audio/webm;codecs=opus"
      });

      store.commit("system/setMediaRecorder", mediaRecorder);

      // Download the audio after the user has stopped
      mediaRecorder.onstop = () => {
        const audio = document.createElement("audio");
        audio.setAttribute("controls", "");

        const { phone, sid, status, extension_number: extensionNumberCall } = store.getters["agent/ring_group/getCallPABX"];
        const { extension_number } = store.getters["auth/getUser"].extension;

        let fileName = `${sid} - ${phone}`;

        if ([CallStatus.INTERNAL_CALL_ANSWERED, CallStatus.CONSULT_ANSWERED].includes(status)) {
          fileName = `${extension_number} - ${ extensionNumberCall ? extensionNumberCall : phone }`;
        }

        const blob = new Blob(chunksLocal, {
          type: "audio/wav"
        });

        chunksLocal = [];

        const url = URL.createObjectURL(blob);

        const a = document.createElement("a");
        a.style.display = "none";
        a.href = url;
        a.download = `${fileName}`;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);

        document.body.removeChild(a);
      };

      mediaRecorder.ondataavailable = e => {
        chunksLocal.push(e.data);
      };
    } catch (error) {
      console.error("[PABX] - ERROR WHEN HANDLING MEDIA RECORDER:", error);
    }
  });

  session.on("ended", data => {
    if (debug) console.log("[PABX] - ended: ", data);

    audioPlayer.stop("ringing");

    const { cause } = data;
    if (cause === "Terminated") {
      store.dispatch("agent/ring_group/handlePABXCallEnded", session);
    }
  });

  session.on("failed", data => {
    if (debug) console.log("[PABX] - failed: ", data);
    audioPlayer.stop("ringing");
    store.dispatch("agent/ring_group/handlePABXCallEnded", session);
  });

  session.on("newDTMF", data => {
    if (debug) console.log("[PABX] - newDTMF: ", data);
  });

  session.on("newInfo", data => {
    if (debug) console.log("[PABX] - newInfo: ", data);
  });

  session.on("hold", data => {
    if (debug) console.log("[PABX] - hold: ", data);
  });

  session.on("unhold", data => {
    if (debug) console.log("[PABX] - unhold: ", data);
  });

  session.on("muted", data => {
    if (debug) console.log("[PABX] - muted: ", data);
  });

  session.on("unmuted", data => {
    if (debug) console.log("[PABX] - unmuted: ", data);
  });

  session.on("reinvite", data => {
    if (debug) console.log("[PABX] - reinvite: ", data);
  });

  session.on("update", data => {
    if (debug) console.log("[PABX] - update: ", data);
  });

  session.on("refer", data => {
    if (debug) console.log("[PABX] - refer: ", data);
  });

  session.on("replaces", data => {
    if (debug) console.log("[PABX] - replaces: ", data);
  });

  session.on("sdp", data => {
    if (debug) console.log("[PABX] - sdp: ", data);
  });

  session.on("icecandidate", data => {
    if (debug) console.log("[PABX] - icecandidate: ", data);
  });

  session.on("getusermediafailed", data => {
    if (debug) console.log("[PABX] - getusermediafailed: ", data);
  });
}
