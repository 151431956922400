<template>
  <li
    class="list-group-item d-flex justify-content-between align-items-center border-0 py-2 cursor-pointer rounded"
    :class="{ 'disabled': loading }"
    @click="getMessagePerPage"
  >
    <div class="col d-flex flex-column px-2">
      <div class="fs-5 overflow-ellipsis list">
        <span class="ml-3">{{ formatedTime }}</span>
        ·
        <span
          v-if="message.owner"
          class="mr-3"
        >{{ message.owner.name }}
        </span>
      </div>
      <div class="d-flex align-items-center px-1">
        <i
          v-if="message.ack === 'read' || message.ack === 'played'"
          class="fa-kit text-small-2 fa-doble-check text-primary my-0"
        />
        <i
          v-else-if="message.ack === 'device'"
          class="fa-kit text-small-2 fa-doble-check my-0"
        />
        <i
          v-else-if="message.ack === 'server'"
          class="fa-regular text-small-2 fa-check my-0"
        />
        <i
          v-else-if="!message.ack"
          class="fa-regular text-small-2 fa-clock my-0"
        />
        <h4
          v-if="message.body"
          class="fs-5 fw-medium my-0 mx-3 overflow-hidden text-truncate"
          v-html="highlightedSentence"
        />
      </div>
    </div>
    <div
      v-if="loading"
      class="pe-5"
    >
      <div
        class="spinner-border text-primary spinner-border-sm"
        role="status"
      >
        <span class="visually-hidden">{{ $t('loading_capitalize') }}...</span>
      </div>
    </div>
  </li>
</template>
<script>
import {mapActions, mapGetters, mapMutations} from "vuex";
import {Utils} from "@/utils";

export default {
  emits: ["close-canvas", 'loading'],
  props: {
    search: {type: String, default: ""},
    message: {type: Object, required: true},
    currentChat: {type: Object, required: true},
    index: {type: Number, required: true},
  },
  data (){
    return {
      loading: false
    }
  },
  computed: {
    formatedTime (){
      return this.formatTimestamp(this.message.time)
    },
    highlightedSentence (){
      const regex = new RegExp(this.search, "gi");
      const highlightedText = this.highlightSearchResults();
      return highlightedText.replace(
        regex,
        '<span class="text-primary">$&</span>'
      );
    },

    ...mapGetters("whatsapp/chat", {
      loadMessagesContext: "getLoadMessagesContext",
    }),
  },
  methods: {
    formatTimestamp: Utils.formatTimestamp,
    ...mapActions("whatsapp/chat", [
      "addMessagesToChat",
      "findMessagesHistoricByNumber",
      "searchOldRecords",
    ]),
    ...mapMutations("whatsapp/message", ["setDisableAutoScroll"]),
    ...mapMutations("whatsapp/chat", [
      "setLoadMessagesContext",
      "setMessageFound",
      "startLoading",
      "stopLoading"
    ]),

    highlightSearchResults () {
      const message = this.message.body;
      if (message.length <= 45)
        return message
      else {
        const position = message.indexOf(this.search);
        const sentences = message.split(this.search, position);

        if (sentences && sentences.length > 1){
          const start = sentences[0];
          const end = sentences[1];
          return (
            start.slice(start.length - this.search.length, position) +
            this.search +
            end.slice(0, this.search.length)
          );
        } else {
          return message
        }
      }
    },
    async getMessagePerPage () {
      this.loading = true
      this.startLoading('findAnchor')
      this.setMessageFound(this.message)
      const initialPage = this.currentChat.sumTotalPages - this.currentChat.pagination.total_pages + 1
      if(this.currentChat.is_group){
        if(this.currentChat.pagination.current_page < this.message.page){
          await this.callGroupPages(this.currentChat.pagination.current_page);
        } else{
          if(this.currentChat.stashedMessages){
            this.setDisableAutoScroll(true);
            await this.addMessagesToChat({
              messages: this.currentChat.stashedMessages,
              chat_id: this.$route.params.id
            })
          }
          this.scrollToProtocolAnchor()
        }
      } else if(this.message.context && this.message.context === "historic"){
        if(this.loadMessagesContext === "current"){
          this.setLoadMessagesContext("historic")
          await this.callCurrentPages(initialPage)
          await this.callHistoricPages(1);
        } else if(this.loadMessagesContext === "historic"){
          await this.callHistoricPages(initialPage);
        }
      } else if(this.message.context === "current")
        await this.callCurrentPages(this.currentChat.pagination.current_page)
    },

    async callGroupPages (inicialPage){
      if(this.currentChat.pagination.current_page < this.currentChat.pagination.total_pages){
        for (let i = inicialPage; i <= this.message.page; i++){
          await this.searchOldRecords({
            page: i,
            instance_id: this.message.instance_id,
            chat_id: this.$route.params.id
          }).then(() => {
            if(this.currentChat.stashedMessages){
              this.setDisableAutoScroll(true);
              this.addMessagesToChat({
                messages: this.currentChat.stashedMessages,
                chat_id: this.$route.params.id
              })
            }
          });
        }
      }
      this.scrollToProtocolAnchor()
    },

     async callCurrentPages (inicialPage){
       if(this.currentChat.pagination.current_page < this.currentChat.pagination.total_pages){
         for (let i = inicialPage; i < this.message.page; i++){
           await this.searchOldRecords({
             page: i,
             instance_id: this.message.instance_id,
             chat_id: this.$route.params.id
           }).then(() => {
             if(this.currentChat.stashedMessages){
               this.setDisableAutoScroll(true);
               this.addMessagesToChat({
                 messages: this.currentChat.stashedMessages,
                 chat_id: this.$route.params.id
               })
             }
           });
         }
       }
       this.scrollToProtocolAnchor()
     },

    async callHistoricPages (inicialPage){
      if(this.currentChat.pagination.current_page <= this.currentChat.sumTotalPages){
        for (let i = inicialPage; i <= this.message.page + 1; i++){
          await this.findMessagesHistoricByNumber({
            number: this.currentChat.number,
            page: i,
            instance_id: this.message.instance_id,
            chat_id: this.$route.params.id
          })
          if(this.currentChat.stashedMessages){
            this.setDisableAutoScroll(false);
            this.addMessagesToChat({
              messages: this.currentChat.stashedMessages,
              chat_id: this.$route.params.id
            })
          }
        }
      }
      this.scrollToProtocolAnchor()
    },
    scrollToProtocolAnchor () {
      const messageId = this.message.id ? this.message.id : this.message._id;
      const findMessage = setTimeout(() => {
        if(this.message && document.getElementById(messageId)){
          document.getElementById(messageId).scrollIntoView({
            behavior: "smooth",
            block: "center",
            inline: "nearest"
          })
          if(document.getElementById(messageId)){
            clearInterval(findMessage)
          }
          this.$emit("close-canvas");
        }
        this.loading = false
        this.stopLoading('findAnchor')

      }, 500)
    }
  },
};
</script>
<style scoped>
.list-group-item:hover {
  background-color: #e1e9f4;
}
</style>
