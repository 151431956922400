<template>
  <div class="main-content pb-6">
    <div class="container-fluid">
      <div class="row justify-content-center">
        <div
          v-if="register"
          class="col-12 col-lg-10 col-xl-8 "
        >
          <header-component
            :title="register.name "
            :pretitle="$t('office_hours_capitalize')"
            redirect-link="/manager/office-hours"
          />

          <div
            ref="form"
            class="row"
          >
            <div class="col-lg-12">
              <div class="form-group 0">
                <label class="form-label required">{{ $t('name_data') }}</label>
                <input
                  v-model="fields.name"
                  :class="{
                    'form-control': true,
                    'is-invalid': errors.name
                  }"
                  :placeholder="$t('name_data')"
                  type="email"
                >

                <div class="invalid-feedback">
                  {{ errors.name && errors.name[0] }}
                </div>
              </div>
            </div>

            <div class="col-lg-12">
              <div class="my-2">
                <label
                  class="form-label required"
                  for="name"
                >{{ $t('operating_hours') }}</label>

                <div class="form-group">
                  <div
                    :class="{
                      'card mb-0': true,
                      'is-invalid': errors.day_week
                    }"
                    class="card"
                  >
                    <div class="card-body">
                      <div
                        v-for="(item, i) of this.fields.week_days"
                        :key="i"
                        class="row"
                      >
                        <div
                          :class="{
                            'col-md-2 my-2': true,
                            'text-muted': !item.active
                          }"
                          style="padding-top: 8px"
                        >
                          {{ item.title }}
                        </div>

                        <div class="col-md-2 my-2">
                          <div class="form-check form-switch">
                            <input
                              id="flexSwitchCheckDefault"
                              v-model="item.active"
                              class="form-check-input"
                              style="margin-top: 8px"
                              type="checkbox"
                            >
                          </div>
                        </div>
                        <div
                          v-if="item.active"
                          class="col-lg-8 my-2 b1"
                        >
                          <div class="d-flex">
                            <input
                              :value="minutesToHoursFormat(item.start_time)"
                              class="form-control text-center px-0 mr-2"
                              style="display: inline-block"
                              type="text"
                              @change="updateMin($event, item)"
                            >
                            <div class="col-md-8 col-sm-8 text-center b2 px-4">
                              <double-range-select
                                v-model:endInterval="item.end_time"
                                v-model:startInterval="item.start_time"
                                :interval="10"
                                :max="1439"
                                :min="0"
                                class="w-100"
                                style="margin-top: 17px !important"
                              />
                            </div>
                            <input
                              :value="minutesToHoursFormat(item.end_time)"
                              class="form-control text-center px-0"
                              type="text"
                              @change="updateMax($event, item)"
                            >
                          </div>
                        </div>
                        <div
                          v-else
                          class="col-md-3 my-2"
                          style="line-height: 40.5px"
                        >
                          {{ $t('no_service') }}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="invalid-feedback">
                    {{ errors.day_week && errors.day_week[0] }}
                  </div>
                </div>
              </div>
            </div>

            <div class="col-lg-12">
              <app-button
                :loading="loading"
                class="btn w-100 btn-primary my-2"
                type="button"
                @click="submit()"
              >
                {{ $t('edit') }}
              </app-button>

              <app-button
                :blocked="loading"
                class="btn w-100 my-2 mb-3"
                color="text-danger"
                type="button"
                @click="remove_()"
              >
                <span class="fe fe-trash" />
                {{ $t('exclude') }}
              </app-button>
            </div>
          </div>
        </div>
        <main-loading v-else-if="loading" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import appButton from "@/components/app-button";

import mainLoading from "@/components/main-loading.vue";
import doubleRangeSelect from "@/components/double-range-select";
import HeaderComponent from "@/components/header-component.vue";

import moment from "moment";

export default {
  /**
   *
   */
  data() {
    return {
      fields: {
        name: "",
        week_days: [
          {
            title: this.$t('sunday'),
            active: false,
            start_time: 480,
            end_time: 1080
          },
          {
            title: this.$t('monday'),
            active: false,
            start_time: 480,
            end_time: 1080
          },
          {
            title: this.$t('tuesday'),
            active: false,
            start_time: 480,
            end_time: 1080
          },
          {
            title: this.$t('wednesday'),
            active: false,
            start_time: 480,
            end_time: 1080
          },
          {
            title: this.$t('thursday'),
            active: false,
            start_time: 480,
            end_time: 1080
          },
          {
            title: this.$t('friday'),
            active: false,
            start_time: 480,
            end_time: 1080
          },
          {
            title: this.$t('saturday'),
            active: false,
            start_time: 480,
            end_time: 1080
          }
        ]
      },

      player: null
    };
  },

  /**
   *
   */
  components: {
    appButton,
    mainLoading,
    doubleRangeSelect,
    HeaderComponent
  },

  /**
   *
   */
  mounted() {
    this.setRegister(null);
    this.findById({ id: this.$route.params.id });
  },

  /**
   *
   */
  computed: {
    /**
     * Getters
     */
    ...mapGetters("manager/office_hours", {
      register: "getRegister",
      errors: "getErrors",
      loading: "isLoading"
    }),
    /**
     *
     */
    ...mapGetters("auth", {
      user: "getUser"
    })
  },

  /**
   *
   */
  watch: {
    /**
     *
     */
    register(register) {
      if (register) {
        this.fields.name = register.name;
        for (let item of register.hours_items) {
          this.fields.week_days[item.day_week].active = true;
          this.fields.week_days[item.day_week].start_time = this.hoursFormatToMinutes(item.start_time);
          this.fields.week_days[item.day_week].end_time = this.hoursFormatToMinutes(item.end_time);
        }
      }
    }
  },

  /**
   *
   */
  methods: {
    /**
     * vuex
     */
    ...mapActions("manager/office_hours", ["findById", "remove", "update"]),
    ...mapMutations("manager/office_hours", ["setRegister"]),
    ...mapMutations("system", ["setFlashMessage"]),

    /**
     *  submit
     */
    submit() {
      const formData = new FormData();

      formData.append("name", this.fields.name);
      formData.append("_method", "PUT");

      for (let i = 0; i < this.fields.week_days.length; i++) {
        if (this.fields.week_days[i].active) {
          formData.append("day_week[]", i);
          formData.append("start_time[]", this.minutesToHoursFormat(this.fields.week_days[i].start_time));
          formData.append("end_time[]", this.minutesToHoursFormat(this.fields.week_days[i].end_time));
        }
      }
      this.update({ formData, id: this.register.id })
        .then(data => {
          this.$router
            .push({
              path: `/manager/office-hours`
            })
            .then(() => {
              this.setFlashMessage({
                message: data.message,
                type: "success"
              });
            });
        })
        .catch(data => {
          this.errorMessage = data.message;
          this.setFlashMessage({
            message: data.message,
            type: "danger"
          });
        });
    },

    /**
     * delete
     */
    remove_() {
      if (
        window.confirm(
          this.$t('delete_office_hour_confirmation')
        )
      ) {
        this.remove({ id: this.register.id });
      }
    },

    /**
     * convert total minutes to hours as HH:mm
     */
    minutesToHoursFormat(minutes) {
      return moment.utc(minutes * 60 * 1000).format("HH:mm");
    },

    /**
     *  convert hours as HH:mm in total minutes
     */
    hoursFormatToMinutes(text) {
      return moment.duration(moment(text, "HH:mm").diff(moment("00:00", "HH:mm"))).asMinutes();
    },

    /**
     *
     */
    updateMin(v, u) {
      let t = this.hoursFormatToMinutes(v.target.value);
      u.start_time = t >= u.end_time ? u.end_time : t;
    },

    /**
     *
     */
    updateMax(v, u) {
      let t = this.hoursFormatToMinutes(v.target.value);
      u.end_time = t <= u.start_time ? u.start_time : t;
    },

    /**
     *
     */
    baseURL() {
      return process.env.VUE_APP_BA12AS.replace("/api/v1", "");
    }
  }
};
</script>
