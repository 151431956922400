<template>
  <div class="card card-insight dropdown">
    <div
      class="card-header card-insight-header justify-content-between"
      type="button"
      data-bs-toggle="dropdown"
      aria-expanded="false"
      ref="dropdownInsight"
    >
      <div class="d-flex gap-2r">
        <div class="d-flex align-items-center gap-05r">
          <span class="far fa-sparkles tx-blue-300 text-small" />
          <h5 class="m-0">
            {{ $t('ai_evaluation') }}
          </h5>
        </div>
        <p class="m-0 text-medium tx-text-gray">
          {{ mood }}
        </p>
      </div>
      <div class="d-flex align-items-center gap-2r">
        <p class="m-0 text-medium tx-text-gray">
          <strong :class="avaliationClass">{{ insight.overall_score.toFixed(1) }}</strong>/10
        </p>
        <span
          class="far fa-chevron-down text-small"
          :class="open? 'chevron-open' : 'chevron-closed'"
        />
      </div>
    </div>
    <div class="dropdown-menu dropdown-menu- dropdown-insight py-0 w-100">
      <div class="card-header card-insight-header justify-content-between cursor-pointer">
        <div class="d-flex gap-2r">
          <div class="d-flex align-items-center gap-05r">
            <span class="far fa-sparkles tx-blue-300 text-small" />
            <h5 class="m-0">
              {{ $t('ai_evaluation') }}
            </h5>
          </div>
          <p class="m-0 text-medium tx-text-gray">
            {{ mood }}
          </p>
        </div>
        <div class="d-flex align-items-center gap-2r">
          <p class="m-0 text-medium tx-text-gray">
            <strong :class="avaliationClass">{{ insight.overall_score.toFixed(1) }}</strong>/10
          </p>
          <span
            class="far fa-chevron-down text-small"
            :class="open? 'chevron-open' : 'chevron-closed'"
          />
        </div>
      </div>
      <div class="card-body card-insight-body d-flex">
        <div class="w-50">
          <h6>
            {{ $t('suggestion') }}
          </h6>
          <p class="text-medium">
            {{ insight.general_evaluation }}
          </p>
        </div>
        <div class="w-50">
          <h6>
            {{ $t('attention_points') }}
          </h6>
          <ul class="text-medium ps-1-25r">
            <li
              v-for="(point, index) in improvementPoints"
              :key="index"
            >
              {{ point }}
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MessageInsight",

  props: {
    insight: {
      type: Object,
      required: true
    }
  },

  data() {
    return{
      open: false
    }
  },

  computed: {
    mood() {
      const moods= {
        "2": "🤩 " + this.$t('very_happy'),
        "1": "😊 " + this.$t('happy'),
        "0": "😐 " + this.$t('neutral'),
        "-1": "😞 " + this.$t('sad'),
        "-2": "😡 " + this.$t('angry'),
      }
      return moods[this.insight.mood]
    },

    avaliationClass() {
      const avaliation = this.insight.overall_score
      if(avaliation <= 3){
        return "tx-danger"
      }
      if(avaliation > 3 && avaliation <= 6){
        return "tx-yellow-400"
      }
      if(avaliation > 6){
        return "tx-green-400"
      }
      return ""
    },

    improvementPoints() {
      return this.insight.improvement_points.split('.').map(sentence => sentence.trim()).filter(Boolean);
    }
  },

  mounted() {
    this.$refs.dropdownInsight.addEventListener('hide.bs.dropdown', this.hideHandler)
    this.$refs.dropdownInsight.addEventListener('show.bs.dropdown', this.showHandler)
  },

  beforeUnmount() {
    this.$refs.dropdownInsight.removeEventListener('hide.bs.dropdown', this.hideHandler)
    this.$refs.dropdownInsight.removeEventListener('show.bs.dropdown', this.showHandler)
  },

  methods: {
    showHandler() {
      this.open = true
    },

    hideHandler() {
      this.open = false
    }
  }
}
</script>

<style scoped lang="scss">
.dropdown-insight {
  transform: translate(0px, 0px) !important;
  transform-origin: top !important;
  border-radius: 0.5rem;
  box-shadow: none;
  border: none
}

.card-insight {
  max-width: 658px;

  &:hover {
    background: linear-gradient(270.25deg, #FFF2D3 -5.42%, #F6F8FC 14.39%, #F6F8FC 87.41%, #9BCDFA 110.59%);
  }
}

.card-insight-header {
  height: 40px;
  padding: 0.5rem 1rem;

  &:hover .chevron-closed {
    color: #3057F2 !important;
  }
}

.gap-2r {
  gap: 2rem;
}

.gap-05r {
  gap: 0.5rem;
}

.card-insight-body {
  padding: 1rem;
  gap: 50px
}

.ps-1-25r {
  padding-left: 1.25rem;
}

.chevron-open {
  color: #3057F2;
  transform: rotate(180deg);
  transition: transform 0.3s ease;
}

.chevron-closed {
  color: #D2DDEC;
  transform: rotate(0deg);
  transition: transform 0.3s ease;
}

.tx-danger {
  color: #F23F2C;
}
</style>