<template>
  <div class="short-player">
    <div
      class="text-primary"
      @click.stop="pause()"
      v-if="status == 'playing'"
    >
      <i class="fe fe-pause" />
    </div>
    <div
      v-if="status == 'ready' || status == 'paused'"
      class="text-primary"
      @click.stop="play()"
    >
      <i class="fe fe-play" />
    </div>
    <div
      v-if="status == 'ended'"
      class="text-primary"
      @click.stop="play()"
    >
      <i class="fe fe-repeat" />
    </div>
    <div
      v-if="status == 'error'"
      class="text-danger"
      @click.stop="play()"
    >
      <i class="fe fe-alert-circle" />
    </div>
    <div
      v-if="status == 'loading'"
      class="text-secondary"
    >
      <i class="fe fe-clock" />
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
export default {
  data() {
    return {
      plalyer: null,
      status: "ready",
    };
  },

  props: {
    url: {
      type: String,
      required: true,
    },
  },

  computed: {
    ...mapGetters({
      currentAudioUrl: "system/getCurrentAudioUrl"
    })
  },

  mounted() {
    this.player = new Audio();
    this.player.type = "audio/mpeg";
    this.player.addEventListener("playing", () => {
      this.status = "playing";
    });
    this.player.addEventListener("pause", () => {
      if(this.url === this.currentAudioUrl) this.setCurrentAudioUrl("")
      this.status = "paused";
    });
    this.player.addEventListener("ended", () => {
      this.status = "ended";
    });
    this.player.addEventListener("error", () => {
      this.status = "error";
    });
    this.player.addEventListener("play", () => {
      this.status = "loading";
    });
  },

  unmounted() {
    this.pause();
    this.player = null;
  },

  methods: {
    ...mapActions("system", ["setCurrentAudioUrl"]),

    play() {      
      if (this.status != "paused") {
        this.player.src = this.url;
      }
      this.setCurrentAudioUrl(this.url)
    },

    pause() {
      if (this.status == "playing") {
        this.player.pause();
      }
    },
  },

  watch: {
    currentAudioUrl(currentAudioUrl) {
      if(currentAudioUrl === this.url) {
        this.player.play();
      } else this.pause();
    }
  }
};
</script>

<style lang="scss">
.short-player {
  display: inline;
  div {
    display: inline;
    cursor: pointer;
  }
}
</style>
