<template>
  <div
    class="tab-pane fade show mt-3"
    id="strategy"
    aria-labelledby="home-tab"
    role="tabpanel"
  >
    <distribution-strategy-form v-model="fields.settings.chat_distribution_strategy" />
    <div class="d-flex flex-row gap-4 mb-4">
      <div
        class="col px-0"
      >
        <div
          class="card h-100 m-0"
        >
          <h4 class="mb-2 card-header">
            <span class="form-check form-switch">
              <input
                v-model="fields.settings.office_hour_enabled"
                class="form-check-input"
                data-test="maxTimeAutomaticFinalization-switch"
                type="checkbox"
              >
              <label class="col form-label text-large mt-1 mb-0 ms-3">
                {{ $t('attendance_schedule') }}
              </label>
            </span>
          </h4>
          <div
            :class="fields.settings.office_hour_enabled ? 'switchOn' : 'switchOff'"
            class="card-body d-flex flex-column justify-content-between"
          >
            <div class="switch">
              <h5
                class="fw-normal mb-3"
              >
                <i
                  class="fe fe-clock p-3 rounded-circle text-large mr-1 text-gray"
                  style="background-color: #E1E9F4; margin-right: 16px;"
                />
                {{ $t('set_schedule_strategy') }}
              </h5>
              <small
                class="text-medium"
                style="padding-top: 8px;"
              >
                {{ $t('set_schedule_strategy_description') }}
              </small>
            </div>
            <div class="mt-4">
              <h5 class="form-label fw-normal">
                {{ $t('attendance_schedule') }}
              </h5>
              <select
                v-model="fields.settings.office_hour_id"
                :class="{ 'is-invalid': hasError?.office_hour_id}"
                :disabled="!fields.settings.office_hour_enabled"
                class="form-select text-gray"
                style="font-size: 16px;"
              >
                <option value="">
                  {{ $t('select') }}
                </option>
                <option
                  v-for="item in filters.hours"
                  :key="item.id"
                  :value="item.id"
                >
                  {{ item.name }}
                </option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <div
        class="col px-0"
      >
        <div
          class="card h-100 m-0"
        >
          <h4 class="mb-2 card-header">
            <span class="form-check form-switch">
              <input
                v-model="fields.settings.max_time_automatic_finalization_enable"
                class="form-check-input"
                data-test="maxTimeAutomaticFinalization-switch"
                type="checkbox"
                @click="delete hasError['max_time_automatic_finalization']"
              >
              <label class="col form-label text-large mt-1 mb-0 ml-6">
                {{ $t('auto_end_chats') }}
              </label>
            </span>
          </h4>
          <div
            :class="fields.settings.max_time_automatic_finalization_enable ? 'switchOn' : 'switchOff'"
            class="card-body d-flex flex-column justify-content-between"
          >
            <div class="switch">
              <h5 class="fw-normal mb-3">
                <i
                  class="fe fe-check-circle p-3 rounded-circle text-large mr-1 text-gray"
                  style="background-color: #E1E9F4; margin-right: 16px;"
                />
                {{ $t('auto_end_chats_description_p1') }}
              </h5>
              <small
                class="text-medium"
                style="padding-top: 8px;"
              >
                {{ $t('auto_end_chats_description_p2') }}
              </small>
            </div>
            <div class="mt-4">
              <div class="d-flex justify-content-between align-items-center">
                <h5 class="form-label fw-normal">
                  {{ $t('time') }}
                </h5>
                <small class="text-gray">
                  ({{ $t('time_days') }})
                </small>
              </div>
              <input
                v-model="fields.settings.max_time_automatic_finalization"
                :class="{ 'is-invalid': hasError?.max_time_automatic_finalization }"
                :readonly="!fields.settings.max_time_automatic_finalization_enable"
                class="form-control text-gray"
                min="1"
                style="font-size: 16px;"
                type="number"
                @keydown="preventNegativeValue($event)"
                @paste="$event.preventDefault()"
              >
              <small
                v-if="hasError.max_time_automatic_finalization"
                class="text-danger"
              >
                {{ $t('enter_value_messaged') }}
              </small>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 mb-4">
      <div class="card h-100 m-0">
        <h4 class="mb-2 card-header">
          <span class="form-check form-switch">
            <input
              v-model="fields.settings.max_time_waiting_answer_enable"
              class="col-auto form-check-input"
              data-test="maxTimeWaitingAnswer-switch"
              type="checkbox"
              @click="delete hasError['max_time_waiting_answer']; delete hasError['snooze_time'];"
            >
            <label class="col form-label text-large mt-1 mb-0 ml-6">{{ $t('client_on_hold_mode') }}
            </label>
          </span>
        </h4>
        <div
          :class=" fields.settings.max_time_waiting_answer_enable ? 'switchOn' : 'switchOff' "
          class="card-body"
        >
          <div class="mb-4 switch">
            <h5 class="fw-normal mb-2">
              <i
                class="fal fa-snooze rounded-circle text-large  me-3 text-gray"
                style="background-color: #E1E9F4; padding: 0.75rem 0.8rem"
              />
              {{ $t('client_on_hold_mode_description_p1') }}
            </h5>
            <small
              class="text-medium"
              style="padding-top: 8px;"
            >
              {{ $t('client_on_hold_mode_description_p2') }}
            </small>
          </div>
          <div class="row">
            <div class="col-6">
              <div class="d-flex justify-content-between align-items-center">
                <h5 class="form-label fw-normal">
                  {{ $t('time') }}
                </h5>
                <small class="text-gray">
                  ({{ $t('time_minutes') }})
                </small>
              </div>
              <input
                v-model="fields.settings.max_time_waiting_answer"
                :class="{ 'is-invalid': hasError.max_time_waiting_answer }"
                :readonly="!fields.settings.max_time_waiting_answer_enable"
                class="form-control text-large text-gray"
                min="1"
                type="number"
                @keydown="preventNegativeValue($event)"
                @paste="$event.preventDefault()"
              >
              <small
                v-if="hasError.max_time_waiting_answer"
                class="text-danger"
              >
                {{ $t('enter_value_message') }}
              </small>
            </div>
            <div class="col-6">
              <h5 class="form-label fw-normal">
                {{ $t('time_on_hold') }}
              </h5>
              <select
                v-model="fields.settings.snooze_time"
                :class="{'is-invalid': hasError.snooze_time}"
                :disabled="!fields.settings.max_time_waiting_answer_enable"
                class="form-select text-large text-gray"
                style="color: #575778"
              >
                <option
                  disabled
                  value=""
                >
                  {{ $t('select') }}
                </option>
                <option value="THREE_HOURS">
                  {{ $t('time_on_hold_later') }}
                </option>
                <option value="TOMORROW">
                  {{ $t('time_on_hold_tomorrow') }} (Ex: {{ generateTomorrowAt9AMDate().format('DD/MM - HH') }}h)
                </option>
                <option value="MONDAY">
                  {{ $t('time_on_hold_next_monday') }} (Ex: {{ generateNextMondayAt9AMDate().format('DD/MM - HH') }}h)
                </option>
                <option value="ONE_MONTH">
                  {{ $t('time_on_hold_month') }} (Ex: {{ generateNextMonthAt9AMDate().format('DD/MM - HH') }}h)
                </option>
              </select>
              <small
                v-if="hasError.snooze_time"
                class="text-danger"
              >
                {{ $t('enter_value_message') }}
              </small>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div>
      <div class="d-flex flex-row gap-4 gx-5 mb-0">
        <div class="col">
          <div class="card h-100 m-0">
            <h4 class="mb-2 card-header">
              <span class="form-check form-switch">
                <input
                  v-model="fields.settings.max_time_waiting_agent_answer_enable"
                  class="form-check-input"
                  data-test="maxTimeToBeAnswer-switch"
                  type="checkbox"
                  @click="delete hasError['max_time_waiting_agent_answer']"
                >
                <label class="col form-label text-large mt-1 mb-0 ml-6">{{ $t('counter_be_served_queue') }}
                </label>
              </span>
            </h4>
            <div
              :class="fields.settings.max_time_waiting_agent_answer_enable ? 'switchOn' : 'switchOff'"
              class="card-body"
            >
              <div class="mb-4 switch">
                <h5
                  class="fw-normal mb-3"
                >
                  <i
                    class="fe fe-watch rounded-circle text-large me-3 p-3 text-gray"
                    style="background-color: #E1E9F4;"
                  />
                  {{ $t('counter_be_served_queue_description_p1') }}
                </h5>
                <small
                  class="text-medium"
                >
                  {{
                    $t('counter_be_served_queue_description_p2')
                  }}
                </small>
              </div>
              <div>
                <div class="d-flex justify-content-between align-items-center">
                  <h5 class="form-label fw-normal">
                    {{ $t('max_time') }}
                  </h5>
                  <small class="text-gray">
                    ({{ $t('time_minutes') }})
                  </small>
                </div>
                <input
                  v-model="fields.settings.max_time_waiting_agent_answer"
                  :class="{ 'is-invalid': hasError.max_time_waiting_agent_answer }"
                  :readonly="!fields.settings.max_time_waiting_agent_answer_enable"
                  class="form-control text-large text-gray"
                  min="1"
                  type="number"
                  @keydown="preventNegativeValue($event)"
                  @paste="$event.preventDefault()"
                >
                <small
                  v-if="hasError.max_time_waiting_agent_answer"
                  class="text-danger"
                >
                  {{ $t('enter_value_message') }}
                </small>
              </div>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="card h-100 m-0">
            <h4 class="mb-2 card-header">
              <span class="form-check form-switch">
                <input
                  v-model="fields.settings.max_time_to_be_answer_enable"
                  class="form-check-input"
                  data-test="maxTimeWaitingAgentAnswer-switch"
                  type="checkbox"
                  @click="delete hasError['max_time_to_be_answer']"
                >
                <label class="col form-label text-large mt-1 mb-0 ml-6">
                  {{ $t('counter_chats') }}
                </label>
              </span>
            </h4>
            <div
              :class="fields.settings.max_time_to_be_answer_enable ? 'switchOn' : 'switchOff'"
              class="card-body pt-3"
            >
              <div class="mb-4 switch">
                <h5
                  class="fw-normal mb-2"
                >
                  <i
                    class="fa-regular fa-message rounded-circle text-large me-3 p-3 text-gray"
                    style="background-color: #E1E9F4;"
                  />
                  {{ $t('counter_chats_description_p1') }}
                </h5>
                <small
                  class="text-medium"
                  style="padding-top: 8px;"
                >
                  {{
                    $t('counter_chats_description_p2')
                  }}
                </small>
              </div>
              <div>
                <div class="d-flex justify-content-between align-items-center">
                  <h5 class="form-label fw-normal">
                    {{ $t('max_time') }}
                  </h5>
                  <small class="text-gray">
                    ({{ $t('time_minutes') }})
                  </small>
                </div>
                <input
                  v-model="fields.settings.max_time_to_be_answer"
                  :class="{ 'is-invalid': hasError.max_time_to_be_answer }"
                  :readonly="!fields.settings.max_time_to_be_answer_enable"
                  class="form-control text-large text-gray"
                  min="1"
                  type="number"
                  @keydown="preventNegativeValue($event)"
                  @paste="$event.preventDefault()"
                >
                <small
                  v-if="hasError.max_time_to_be_answer"
                  class="text-danger"
                >
                  {{ $t('enter_value_message') }}
                </small>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="pt-4">
      <div class="col-lg-12">
        <app-button
          :loading="loadingFlags['updateStrategySettings']"
          class="w-100 mb-5"
          color="btn-primary"
          @click="submit()"
        >
          {{ $t('save_general') }}
        </app-button>
      </div>
    </div>
  </div>
</template>
<script>
import moment from "moment";
import {mapActions, mapGetters, mapMutations} from "vuex";

import AppButton from "@components/app-button";
import DistributionStrategyForm
  from "@whatsapp/manager/pages/dashboards/connection/components/distribution-strategy-form.vue";

export default {
  data () {
    return {
      fields: {
        id: 0,
        name: "",
        team_id: 0,
        settings: {
          max_time_automatic_finalization: null,
          max_time_automatic_finalization_enable: false,
          max_time_to_be_answer: null,
          max_time_to_be_answer_enable: false,
          snooze_time: null,
          max_time_waiting_agent_answer: null,
          max_time_waiting_agent_answer_enable: false,
          max_time_waiting_answer: null,
          max_time_waiting_answer_enable: false,
          office_hour_enabled: false,
          office_hour_id: null,
          chat_distribution_strategy: "DEFAULT"
        },
      },
      hasError: {}
    };
  },

  mounted () {
    this.setInstanceIncludes(["settings"]);

    this.setIncludes(["hours"]);
    this.findFilters();
  },

  components: {
    DistributionStrategyForm,
    AppButton
  },
  computed: {
    ...mapGetters("manager/whatsapp", {
      loadingFlags: "getLoadingFlags",
      instance: "getInstance",
      errors: "getErrors"
    }),
    ...mapGetters("manager/filters", {
      filters: "getFilters"
    }),
    ...mapGetters("manager/whatsapp_group_channel", {
      groupChannel: "getGroupChannel"
    }),
  },

  watch: {
    groupChannel(data) {
      if(data){
        this.fields.id = this.$route.params.id;
        this.fields.name = this.groupChannel.name;
        this.fields.team_id = this.groupChannel.team_id;
        this.instanceCreated = true;

        if (this.groupChannel.settings) {
          this.fields.settings = {
            max_time_automatic_finalization: this.groupChannel.settings.data.max_time_automatic_finalization,
            max_time_automatic_finalization_enable: this.groupChannel.settings.data.max_time_automatic_finalization_enable,
            max_time_to_be_answer: this.groupChannel.settings.data.max_time_to_be_answer,
            max_time_to_be_answer_enable: this.groupChannel.settings.data.max_time_to_be_answer_enable,
            snooze_time: this.groupChannel.settings.data.snooze_time,
            max_time_waiting_agent_answer: this.groupChannel.settings.data.max_time_waiting_agent_answer,
            max_time_waiting_agent_answer_enable: this.groupChannel.settings.data.max_time_waiting_agent_answer_enable,
            max_time_waiting_answer: this.groupChannel.settings.data.max_time_waiting_answer,
            max_time_waiting_answer_enable: this.groupChannel.settings.data.max_time_waiting_answer_enable,
            office_hour_enabled: this.groupChannel.settings.data.office_hour_enabled,
            office_hour_id: this.groupChannel.settings.data.office_hour_id,
            chat_distribution_strategy: this.groupChannel.settings.data.chat_distribution_strategy || 'DEFAULT'
          }
        }
      }
    },

    errors (value) {
      this.hasError = value;
    }
  },

  methods: {
    ...mapActions("manager/whatsapp", [
      "findInstanceById",
      "updateStrategySettings",
    ]),
    ...mapActions("manager/filters", ["findFilters"]),
    ...mapMutations("manager/filters", ["setIncludes"]),
    ...mapMutations("manager/whatsapp", [
      "setInstanceIncludes"
    ]),

    submit () {
      if (!this.fields.settings.max_time_automatic_finalization_enable) {
        delete this.fields.settings['max_time_automatic_finalization']
      }
      if (!this.fields.settings.max_time_to_be_answer_enable) {
        delete this.fields.settings["max_time_to_be_answer"]
      }
      if (!this.fields.settings.max_time_waiting_agent_answer_enable) {
        delete this.fields.settings["max_time_waiting_agent_answer"]
      }
      if (!this.fields.settings.max_time_waiting_answer_enable) {
        delete this.fields.settings["max_time_waiting_answer"]
        delete this.fields.settings["snooze_time"]
      }

      this.updateStrategySettings({
        id: this.$route.params.id,
        fields: this.fields.settings
      }).then(() => {
        this.hasError = {}
      })
    },

    preventNegativeValue (el) {
      el.key === '-' ? el.preventDefault() : null
    },

    generateTomorrowAt9AMDate () {
      return moment().add("1", "day").set({hours: 9, minute: 0, second: 0});
    },

    generateNextMondayAt9AMDate () {
      return moment().day(8).set({hours: 9, minute: 0, second: 0});
    },

    generateNextMonthAt9AMDate () {
      return moment().add("1", "month").set({hours: 9, minute: 0, second: 0});
    }
  },
};
</script>
<style lang="scss" scoped>
.form-check-input {
  background-color: #C5C5C5 !important;
}

.form-check-input:checked {
  background-color: $color-blue-300 !important;
}

.fe-alert-circle:hover {
  color: #2c7be5;
}

.fa-clock {
  background-color: $color-gray-blue-300;
}

.switchOn {
  .switch {
    h5 {
      color: $color-text-200;

      i {
        color: #2336C7;
        background-color: $color-gray-blue-300;
      }

      img {
        background-color: $color-gray-blue-300;
        width: 2.8rem;
        height: 2.8rem;
        padding: 0.5rem;
      }
    }

    small {
      color: #677C92;
    }
  }

  small {
    color: #888888;
  }
}

.switchOff {
  .switch {
    h5 {
      color: #C5C5C5;

      i {
        color: #C5C5C5;
        background-color: #F4F4F4;
      }

      img {
        background-color: #F4F4F4;
        width: 2.8rem;
        height: 2.8rem;
        padding: 0.5rem;
      }
    }

    small {
      color: #C5C5C5;
    }
  }

  small, p {
    color: #C5C5C5;
  }

  input, select {
    border: 1px solid #C5C5C5;
    text-decoration-color: #2c7be5 !important;
  }
}

.form-select:disabled {
  background-color: #F4F4F4;
  color: #C5C5C5 !important;
}

select[disabled] {
  background-color: #FFF !important;
  color: transparent !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>
