import moment from "moment";
import {Utils} from "@/utils";

export default {
  clearErrors: state => (state.errors = {}),

  setPagination: (state, pagination) => (state.pagination = pagination),
  setCurrentPage: (state, page) => (state.pagination.current_page = page),
  setFilters: (state, filters) => (state.filters = Object.assign({}, state.filters, filters)),
  setInclude: (state, include) => (state.include = include),

  startLoading: (state, flag) => (state.loadingFlags[flag] = true),
  stopLoading: (state, flag) => (state.loadingFlags[flag] = false),

  incrementPendingPreLoadMessages: state => state.pendingPreLoadMessages++,
  decrementPendingPreLoadMessages: state => state.pendingPreLoadMessages--,

  startChatLoading: (state, flag) => (state.loadingChats[flag] = true),
  stopChatLoading: (state, flag) => (state.loadingChats[flag] = false),

  setErrors: (state, errors) => (state.errors = errors),
  removeChatById: (state, payload) => {
    if (state.inProgress[payload.id]) delete state.inProgress[payload.id];
    if (state.inSnooze[payload.id]) delete state.inSnooze[payload.id];
    if (state.inChatbot[payload.id]) delete state.inChatbot[payload.id];
    state.openChat = null;
  },

  transferChatToQueue: (state, payload) => {
    if(state.inProgress[payload.id]) {
      let chat = state.inProgress[payload.id]
      delete state.inProgress[payload.id];
      chat.agent_id = null
      state.queue[payload.id] = chat
    }
    else if (state.inChatbot[payload.id]) {
      let chat = state.inChatbot[payload.id]
      delete state.inChatbot[payload.id];
      state.queue[payload.id] = chat
      state.currentChat[payload.id]['chatbot_active'] = false
    }
  },

  transferChatToInProgress: (state, payload) => {
    if (state.inChatbot[payload.id]) {
      state.inProgressPagination.total++
      let chat = state.inChatbot[payload.id]
      delete state.inChatbot[payload.id];
      state.inProgress[payload.id] = chat
      state.currentChat[payload.id]['chatbot_active'] = false
    }
  },

  setNewChatInfo: (state, newChatInfo) => (state.newChatInfo = newChatInfo),

  setOpenChat: (state, openChat) => {
    state.openChat = openChat
    if (openChat.instance?.data?.type){
      state.chatChannelTypeSelected = {
        id: openChat.instance.data.id,
        type: openChat.instance.data.type
      }
    } else if (openChat?.states?.channel) {
      state.chatChannelTypeSelected = {
        id: openChat.instance_id,
        type: openChat.states.channel
      }
    }
  },

  setGroupParticipants: (state, groupParticipants) => (state.groupParticipants = groupParticipants),

  setGroupAgents: (state, groupAgents) => (state.groupAgents = groupAgents),

  /**
   * map chats by id
   *
   * @param {*} state
   * @param {*} inProgress
   */
  setInProgress: (state, inProgress) => {
    inProgress = inProgress.sort((a, b) => {
      return Math.sign(moment(a.updated_at).diff(moment(b.updated_at)));
    });
    let temp = {};
    for (let item of inProgress) {
      temp[item.id] = item;
      state.inProgress = temp;
    }
  },

  updateInProgressTalkOrder(state, chat) {
    if (state.openChat?.id == chat.id) {
      chat.unread = 0;
    }

    const prevChat = state.inProgress[chat.id]

    if (prevChat) {
      chat.mood = prevChat.mood
      delete state.inProgress[chat.id];
    }


    let chats = [];
    chats = Object.values(JSON.parse(JSON.stringify(state.inProgress)));
    chats.unshift(chat);

    state.inProgress = {};

    for (let item of chats) {
      state.inProgress[item.id] = item;

      if (!state.currentChat[item.id]) {
        item["messagesData"] = {};
        item["messagesRefs"] = {};
        item["pagination"] = {};
        item["stashedMessages"] = null;
        item["allowNewMessages"] = false;
        item["incompleteMessagesPage"] = [];
        item["states"] = {
          scrollY: null,
          text: "",
          tab: "whatsapp"
        };
        item["totalPages"] = {
          current: 0,
          historic: 0,
          channel: 'waba'
        }
        item['sumTotalPages'] = chat.totalPages['current'] + chat.totalPages['historic']

        state.currentChat[item.id] = item
      }
      else {
        state.currentChat[item.id].agent_id = item.agent_id
        state.currentChat[item.id].last_message_data = item.last_message_data
      }
    }

    if (state.currentChat[chat?.id]) {
      state.currentChat[chat.id].unread = chat.unread;
    }
  },

  updateGroupTalkOrder(state, chat) {
    if (state.openChat?.id == chat.id) {
      chat.unread = 0;
    }
    delete state.groups[chat.id];

    if (chat.id.endsWith("@g.us")) {
      let groupChats = Object.values(JSON.parse(JSON.stringify(state.groups)));
      groupChats.unshift(chat);

      state.groups = {};

      for (let item of groupChats) {
        state.groups[item.id] = item;
        if (!state.currentChat[item.id]) {
          item["messagesData"] = {};
          item["messagesRefs"] = {};
          item["pagination"] = {};
          item["stashedMessages"] = null;
          item["allowNewMessages"] = false;
          item["incompleteMessagesPage"] = [];
          item["states"] = {
            scrollY: null,
            text: "",
            tab: "whatsapp",
            channel: 'waba'
          }
          item['totalPages'] = {
            current: 0,
            historic: 0
          };
          item["sumTotalPages"] = chat.totalPages["current"] + chat.totalPages["historic"];
          state.currentChat[item.id] = item;
        } else {
          state.currentChat[item.id].last_message_data = item.last_message_data;
        }
      }
    }

    if (state.currentChat[chat?.id]) {
      state.currentChat[chat.id].unread = chat.unread;
    }
  },

  updateGroupTalkOrderByEvent(state, chat) {
    delete state.groups[chat.id];
    let groupChats = Object.values(JSON.parse(JSON.stringify(state.groups)));
    groupChats.unshift(chat);
    state.groups = {};
    for (let chat of groupChats) {
      state.groups[chat.id] = chat;
      if (!state.currentChat[chat.id]) {
        chat["messagesData"] = {};
        chat["messagesRefs"] = {};
        chat["pagination"] = {};
        chat["stashedMessages"] = null;
        chat["allowNewMessages"] = false;
        chat["incompleteMessagesPage"] = [];
        chat["states"] = {
          scrollY: null,
          text: "",
          tab: "whatsapp",
          channel: 'waba'
        }
        chat['totalPages'] = {
          current: 0,
          historic: 0
        };
        chat["sumTotalPages"] = chat.totalPages["current"] + chat.totalPages["historic"];
        state.currentChat[chat.id] = chat;
      }
    }
  },

  updateQueueTalkOrder(state, chat) {
    delete state.queue[chat.id];
    if (chat.id.endsWith("@c.us")) {
      let queueChats = Object.values(JSON.parse(JSON.stringify(state.queue)));
      queueChats.unshift(chat);

      state.queue = {};

      for (let chat of queueChats) {
        state.queue[chat.id] = chat;
        if (!state.currentChat[chat.id]) {
          chat["messagesData"] = {};
          chat["messagesRefs"] = {};
          chat["pagination"] = {};
          chat["stashedMessages"] = null;
          chat["allowNewMessages"] = false;
          chat["incompleteMessagesPage"] = [];
          chat["states"] = {
            scrollY: null,
            text: "",
            tab: "whatsapp",
            channel: 'waba'
          }
          chat['totalPages'] = {
            current: 0,
            historic: 0
          };
          chat["sumTotalPages"] = chat.totalPages["current"] + chat.totalPages["historic"];
          state.currentChat[chat.id] = chat;
        }
      }
    }
  },

  updateInChatbotTalkOrder(state, chat) {
    delete state.inChatbot[chat.id];
    let chats = Object.values(JSON.parse(JSON.stringify(state.inChatbot)));
    chats.unshift(chat)

    state.inChatbot = {};

    for (let chat of chats) {
      state.inChatbot[chat.id] = chat;
    }
  },

  /**
   *
   * @param {*} state
   * @param {*} payload
   */
  incrementUnreadMessagesCount(state, payload) {
    if (state.inProgress[payload.chat_id]) {
      state.inProgress[payload.chat_id].unread += 1;
    }
  },
  incrementGroupsUnreadMessagesCount(state, payload) {
    if (state.groups[payload.chat_id]) state.groups[payload.chat_id].unread += 1;
  },

  updateTalksInfo(state, payload) {
    if (state.inProgress[payload.chat_id]) {
      state.inProgress[payload.chat_id].unread = payload.unread;
    }
  },

  updateGroupsTalksInfo(state, payload) {
    if (state.groups[payload.chat_id]) state.groups[payload.chat_id].unread = payload.unread;
  },

  setQueue: (state, queue) => (state.queue = queue),

  setCurrentChat: (state, currentChat) => {
    currentChat["messagesData"] = {};
    currentChat["messagesRefs"] = {};
    currentChat["pagination"] = {};
    currentChat["stashedMessages"] = null;
    currentChat["allowNewMessages"] = false;
    currentChat["incompleteMessagesPage"] = [];
    currentChat["states"] = {
      scrollY: null,
      text: "",
      tab: "whatsapp",
      channel: 'ultramsg'
    }
    currentChat['totalPages'] = {
      current: 0,
      historic: 0
    };
    currentChat["sumTotalPages"] = currentChat.totalPages["current"] + currentChat.totalPages["historic"];

    if (state?.currentChat?.[currentChat.id] &&
      state?.currentChat?.[currentChat.id]?.["messagesData"] &&
      state?.currentChat?.[currentChat.id]?.["messagesRefs"] &&
      !Object.keys(currentChat?.["messagesData"])?.length &&
      !Object.keys(currentChat?.["messagesRefs"])?.length) {
      currentChat["messagesData"] = state?.currentChat?.[currentChat.id]["messagesData"];
      currentChat["messagesRefs"] = state?.currentChat?.[currentChat.id]["messagesRefs"];
    }

    state.currentChat[currentChat.id] = currentChat;
    state.removedFromTheGroup = currentChat?.isReadOnly;
  },

  toggleInGroup: (state, payload) => {
    state.currentChat[payload.chat.id].isReadOnly = false;
  },

  removeFromCurrentChat: (state, currentChat) => {
    delete state.currentChat[currentChat.id];
  },
  setIncompleteMessagesPage: (state, { payload, incompleteMessagesPage }) => {
    state.currentChat[payload.chat_id || payload.id].incompleteMessagesPage = incompleteMessagesPage;
  },
  setStashedMessages: (state, { payload, messages }) => {
    const index = payload.chat_id || payload.id;
    state.currentChat[index].stashedMessages = messages;
  },
  setAllowNewMessages: (state, { payload, allowNewMessages }) => {
    if (payload) {
      state.currentChat[payload.chat_id || payload.id].allowNewMessages = allowNewMessages;
    }
  },
  setSnoozeNote: (state, snoozeNote) => (state.snoozeNote = snoozeNote),

  // ==== QUEUE ====
  /**
   * Adds the chat in the queue's array making
   * the chat id as the key to access the value.
   *
   * @param {Object} state
   * @param {Array} queue
   * @returns
   */
  addQueue: (state, queue) => {
    for (let chat of queue) state.queue[chat.id] = chat;
  },

  setQueuePagination: (state, pagination) => (state.queuePagination = pagination),
  setQueueCurrentPage: (state, page) => (state.queuePagination.current_page = page),

  /**
   * Adds a new chat at the end of the waiting queue
   * based on the user's current page.
   *
   * @param {Object} state
   * @param {Object} chat
   */
  addChatToQueue: (state, chat) => {
    if (state.queue[chat.id]) return;

    state.queuePagination.total++;

    let chats = Object.values(JSON.parse(JSON.stringify(state.queue)));
    chats.unshift(chat);
    state.queue = {};
    for (let chat of chats) {
      state.queue[chat.id] = chat;
    }
  },

  addChatToInProgress: (state, { chat, agent_id }) => {
    const agent = {
      id: agent_id,
      name: chat?.agent_name || chat?.agent?.name || chat?.internal_message?.agent_name
    }

    if (chat.id && state.queue[chat.id]) {
      delete state.queue[chat.id];
    }
    if (chat.id && state.inProgress[chat.id]) return;

    state.inProgressPagination.total++;

    let chats = Object.values(JSON.parse(JSON.stringify(state.inProgress)));
    chats.unshift(chat);
    state.inProgress = {};
    for (let item of chats) {
      if (!state.currentChat[chat.id]) {

        item["messagesData"] = {};
        item["messagesRefs"] = {};
        item["pagination"] = {};
        item["stashedMessages"] = null;
        item["allowNewMessages"] = false;
        item["incompleteMessagesPage"] = [];
        item["totalPages"] = {
          current: 0,
          historic: 0,
        }
        item['sumTotalPages'] = item.totalPages['current'] + item.totalPages['historic']
        item['states'] = {
          channel: 'waba'
        }
      }
      state.inProgress[item.id] = item;
    }
    state.inProgress[chat.id].agent_id = agent.id;
    state.inProgress[chat.id].agent = agent;
    state.inProgress[chat.id].agent_name = agent.name;

    state.currentChat[chat.id] = {...state.currentChat[chat.id], ...state.inProgress[chat.id] };
  },

  addChatToInChatbot: (state, chat) => {
    if (state.inChatbot[chat.id]) return;

    let chats = Object.values(JSON.parse(JSON.stringify(state.inChatbot)));
    chats.unshift(chat);
    state.inChatbot = {};
    for (let item of chats) {
      state.inChatbot[item.id] = item;

      if (!state.currentChat[chat.id]) {
        chat['chatbot_active'] = true
        chat['messagesData'] = {}
        chat['messagesRefs'] = {}
        chat['pagination'] = {}
        chat['stashedMessages'] = null
        chat['allowNewMessages'] = false
        chat['incompleteMessagesPage'] = []
        chat['states'] = {
          scrollY: null,
          text: "",
          tab: "whatsapp",
        }
        chat['totalPages'] = {
          current: 0,
          historic: 0,
        }
        chat['sumTotalPages'] = chat.totalPages['current'] + chat.totalPages['historic']
        state.currentChat[chat.id] = chat
      }
    }
  },

  /**
   * Verifies if the chat really exists and remove it from
   * the queue when the event chat-accepted-whatsapp
   * is triggered by another agent.
   *
   * @param {Object} state
   * @param {Object} chat
   */
  removeChatFromQueue: (state, chat) => {
    if (state.queue[chat.id]) {
      delete state.queue[chat.id];
      let queue = Object.values(state.queue);
      let temp = {};
      for (let chat of queue) {
        temp[chat.id] = chat;
        state.queue = temp;
      }
    }

    if(state.queuePagination.total > 0) {
      state.queuePagination.total--;
    }
  },

  /**
   * Handle the new-message-whatsapp event updating the
   * last unread message in the user's screen.
   *
   * @param {Object} state
   * @param {Object} chat
   */
  updateInQueueTalk(state, chat) {
    let queueChats = Object.values(JSON.parse(JSON.stringify(state.queue)));
    queueChats.unshift(chat);

    state.queue = {};

    for (let chat of queueChats) {
      state.queue[chat.id] = chat;
      if (!state.currentChat[chat.id]) {
        chat["messagesData"] = {};
        chat["messagesRefs"] = {};
        chat["pagination"] = {};
        chat["stashedMessages"] = null;
        chat["allowNewMessages"] = false;
        chat["incompleteMessagesPage"] = [];
        chat["states"] = {
          scrollY: null,
          text: "",
          tab: "whatsapp",
          channel: 'waba'
        }
        chat['totalPages'] = {
          current: 0,
          historic: 0
        };
        chat["sumTotalPages"] = chat.totalPages["current"] + chat.totalPages["historic"];
        state.currentChat[chat.id] = chat;
      }
    }
  },

  // ==== IN PROGRESS ====
  setInProgressPagination: (state, pagination) => (state.inProgressPagination = pagination),
  setInProgressCurrentPage: (state, page) => (state.inProgressPagination.current_page = page),
  /**
   * Adds the chat in the queue's array making
   * the chat id as the key to access the value.
   *
   * @param {Object} state
   * @param {Array} inProgress
   */
  addInProgress: (state, inProgress) => {
    for (let chat of inProgress) state.inProgress[chat.id] = chat;
  },

  removeInProgress: (state, payload) => {
    delete state.inProgress[payload.id];
  },

  setUnreadConversations: (state, unreadConversations) => (state.unreadConversations = unreadConversations),

  incrementUnreadConversation: state => (state.unreadConversations += 1),

  decrementUnreadConversation: state => {
    if (state.unreadConversations > 0) state.unreadConversations -= 1;
  },

  setUnreadGroupsConversations: (state, unreadGroupsConversations) =>
    (state.unreadGroupsConversations = unreadGroupsConversations),

  incrementUnreadGroupsConversation: state => (state.unreadGroupsConversations += 1),

  decrementUnreadGroupsConversation: state => {
    if (state.unreadGroupsConversations > 0) state.unreadGroupsConversations -= 1;
  },

  setHasNotifiedInProgress: (state, hasNotifiedInProgress) => (state.hasNotifiedInProgress = hasNotifiedInProgress),
  setHasNotifiedQueue: (state, hasNotifiedQueue) => (state.hasNotifiedQueue = hasNotifiedQueue),

  clearInProgress: state => (state.inProgress = {}),
  clearGroups: state => (state.groups = {}),
  clearQueue: state => (state.queue = {}),
  clearInChatbot: state => (state.inChatbot = {}),
  clearInSnooze: state => (state.inSnooze = {}),
  clearFinished: state => (state.finished = {}),

  // ==== SNOOZE ====
  addInSnooze: (state, inSnooze) => {
    for (let chat of inSnooze) state.inSnooze[chat.id] = chat;
  },
  setInSnoozePagination: (state, pagination) => (state.inSnoozePagination = pagination),
  setInSnoozeCurrentPage: (state, page) => (state.inSnoozePagination.current_page = page),
  setChatAsInSnooze: (state, payload) => {
    if (state.inProgress[payload.chat_id]) {
      let snoozeChat = state.inProgress[payload.chat_id];
      delete state.inProgress[payload.chat_id];

      let chats = Object.values(JSON.parse(JSON.stringify(state.inSnooze)));
      snoozeChat.in_snooze = true;
      snoozeChat.end_snooze = payload.end_snooze;
      chats.unshift(snoozeChat);
      state.inSnooze = {};
      for (let chat of chats) state.inSnooze[chat.id] = chat;
    }
  },

  removeChatFromSnooze: (state, chat) => {
    state.snoozedByManager = false;
    if (state.inSnooze[chat.id]) {
      let savedChat = state.inSnooze[chat.id];
      delete state.inSnooze[chat.id];
      state.inProgress[chat.id] = savedChat;
      state.currentChat[chat.id].in_snooze = false
    }
  },

  setRemovedFromTheGroup: (state, payload) => {
    state.removedFromTheGroup = payload.chat.isReadOnly;
  },

  // ==== FINISHED ====
  addFinished: (state, finished) => {
    for (let chat of finished) state.finished[chat.id] = chat;
  },
  updateFinished: (state, chat) => {
    let chats = Object.values(JSON.parse(JSON.stringify(state.finished)));
    chats.unshift(chat);
    state.finished = {};
    for (let chat of chats) state.finished[chat.id] = chat;
  },
  setFinishedPagination: (state, pagination) => (state.finishedPagination = pagination),
  setFinishedCurrentPage: (state, page) => (state.finishedPagination.current_page = page),

  addInChatbot: (state, inChatbot) => {
    for (let chat of inChatbot) state.inChatbot[chat.id] = chat;
  },
  setInChatbotPagination: (state, pagination) => (state.inChatbotPagination = pagination),
  setInChatbotCurrentPage: (state, page) => (state.inChatbotPagination.current_page = page),

  addGroups: (state, groups) => {
    for (let chat of groups) state.groups[chat.id] = chat;
  },
  addGroupsByNewGroupEvent: (state, group) => {
    state.groups[group.id] = group;
  },
  setGroupsPagination: (state, pagination) => (state.groupsPagination = pagination),
  setGroupsCurrentPage: (state, page) => (state.groupsPagination.current_page = page),
  setCheckedContacts: (state, checkedContacts) => (state.checkedContacts = checkedContacts),

  // ==== SPY ====
  setTransferedByManager: (state, boolean) => (state.transferedByManager = boolean),
  setSnoozedByManager: (state, snoozedByManager) => (state.snoozedByManager = snoozedByManager),
  setFinalizedByManager: (state, finalizedByManager) => (state.finalizedByManager = finalizedByManager),

  /**
   * ----------MESSAGES SCOPE------------
   *
   * @param {*} state
   * @param {*} chat
   */
  setMessagesPagination: (state, { pagination, payload, context }) => {
    state.currentChat[payload.chat_id || payload.id].pagination = pagination;
    state.currentChat[payload.chat_id || payload.id].totalPages[context] = pagination.total_pages;
    state.currentChat[payload.chat_id || payload.id].sumTotalPages =
      state.currentChat[payload.chat_id || payload.id].totalPages["current"] +
      state.currentChat[payload.chat_id || payload.id].totalPages["historic"];
  },

  setMessages: (state, messages) => {
    state.prev = null;

    for (let item of messages) {
      state.currentChat[item.chat_id].messagesData[item.id] = item;
      let day = Utils.passedTime(moment.unix(item.time).format("Y-M-D 00:00:00"), false);
      if (!state.currentChat[item.chat_id].messagesRefs[day]) state.currentChat[item.chat_id].messagesRefs[day] = [];

      if (item.chat_id.endsWith("@g.us") && state.prev) {
        if (
          state.prev.fromMe != item.fromMe ||
          (!state.prev.fromMe && state.prev.message_from != item.message_from) ||
          (state.prev.fromMe && state.prev.agent && state.prev.agent.name != item.agent?.name)
        )
          state.currentChat[item.chat_id].messagesRefs[day].unshift(null);
      }

      if (!state.currentChat[item.chat_id].messagesRefs[day].includes(item.id)) {
        state.currentChat[item.chat_id].messagesRefs[day].unshift(item.id);
      }
      state.currentChat[item.chat_id]["sumTotalPages"] = 0;

      state.prev = item;
    }
  },

  addOlderMessages: (state, { messages, chat_id }) => {
    if (messages?.length) {
      if (messages[0].number.endsWith("@g.us")) chat_id = messages[0].number;

      for (let item of messages) {
        let day = Utils.passedTime(moment.unix(item.time).format("Y-M-D 00:00:00"), false);
        if (!state.currentChat[chat_id]?.messagesRefs[day]) state.currentChat[chat_id].messagesRefs[day] = [];

        if (state.prev) {
          if (
            state.prev.fromMe != item.fromMe ||
            (!state.prev.fromMe && state.prev.message_from != item.message_from) ||
            (state.prev.fromMe && state.prev.agent && state.prev.agent.name != item.agent?.name)
          )
            state.currentChat[chat_id].messagesRefs[day].unshift(null);
        }

        state.currentChat[chat_id].messagesRefs[day].unshift(item.id);
        state.currentChat[chat_id].messagesData[item.id] = item;

        state.prev = item;
      }
      state.currentChat[chat_id].stashedMessages = null;
    }
  },

  addRecentMessage: (state, { message }) => {
    let day = Utils.passedTime(moment.unix(message.time).format("Y-M-D 00:00:00"), false);
    let chatId = null;

    if (!message?.chat_id) {
      chatId = message.number + message.instance_id;
      if (message.number.endsWith("@g.us")) chatId = message.number;
    } else {
      chatId = message.chat_id;
    }

    state.currentChat[chatId].messagesData[message.id || message._id] = message;

    if (!state.currentChat[chatId].messagesRefs[day]) {
      state.currentChat[chatId].messagesRefs = Object.assign(
        { [day]: [message.id || message._id] },
        state.currentChat[chatId].messagesRefs
      );
    } else {
      if (message.chat_id.endsWith("@g.us")) {
        let lastMessage =
          state.currentChat[chatId].messagesData[
            state.currentChat[chatId].messagesRefs[day][state.currentChat[chatId].messagesRefs[day].length - 1]
          ];
        if (lastMessage) {
          if (
            lastMessage.fromMe != message.fromMe ||
            (!lastMessage.fromMe && lastMessage.message_from != message.message_from) ||
            (lastMessage.fromMe && message.agent && message.agent.name != lastMessage.agent?.name)
          )
            state.currentChat[chatId].messagesRefs[day].push(null);
        }
      }

      state.currentChat[chatId].messagesRefs[day].push(message.id || message._id);
    }
  },

  replaceTemporaryMessage(state, payload) {
    state.currentChat[payload.message.chat_id].messagesData[payload.message.id] = payload.message
    let day = Utils.passedTime(moment.unix(payload.message.time).format("Y-M-D 00:00:00"),false);
    let index = state.currentChat[payload.message.chat_id].messagesRefs[day].indexOf(payload.temp_id);
    if (index != -1)
      state.currentChat[payload.message.chat_id].messagesRefs[day][index] = payload.message.id;
    delete state.currentChat[payload.message.chat_id].messagesData[payload.temp_id]
  },

  updateMessageACK(state, message) {
    //has watsapp id
    if (state.currentChat[message.chat_id].messagesData[message.id]){
      state.currentChat[message.chat_id].messagesData[message.id].ack = message.ack
    }
    //mongo id
    else if(state.currentChat[message.chat_id].messagesData[message._id]){
      state.currentChat[message.chat_id].messagesData[message._id].ack = message.ack
      state.currentChat[message.chat_id].messagesData[message._id].id = message.id
    }
    // waba
    else if(message.waba_data?.id && state.currentChat[message.chat_id].messagesData[message.waba_data.id]){
      state.currentChat[message.chat_id].messagesData[message.waba_data.id].ack = message.ack
      state.currentChat[message.chat_id].messagesData[message.waba_data.id].id = message.id
    }
    // messenger
    else if(message.messenger_data?.id && state.currentChat[message.chat_id].messagesData[message.messenger_data.id]){
      state.currentChat[message.chat_id].messagesData[message.messenger_data.id].ack = message.ack
      state.currentChat[message.chat_id].messagesData[message.messenger_data.id].id = message.id
    }
  },

  updateNewMessageTemplateWhatsapp (state, {chat}){
    state.currentChat[chat.id].waba_message_received = chat.waba_message_received
  },

  removeMessageById(state, payload) {
    let message = state.currentChat[payload.chat_id].messagesData[payload.id];
    if (message) {
      let d = Utils.passedTime(moment.unix(message.time).format("Y-M-D 00:00:00"), false);
      let index = state.currentChat[payload.chat_id].messagesRefs[d].indexOf(payload.id);
      if (index != -1) delete state.currentChat[payload.chat_id].messagesRefs[d].splice(index, 1);
      delete state.currentChat[payload.chat_id].messagesData[payload.id];
    }
  },

  setMessageFailed(state, payload) {
    if (payload.template_id) {
      if(state.currentChat[payload.chat_id].messagesData[payload.template_id]){
        state.currentChat[payload.chat_id].messagesData[payload.template_id].failed = true
      }
    } else if (payload.formData) {
      if(state.currentChat[payload.formData.get('chat_id')].messagesData[payload.tempId]){
        state.currentChat[payload.formData.get('chat_id')].messagesData[payload.tempId].failed = true
      }
    } else {
      const targetChatId = payload.fields.chat_id ? payload.fields.chat_id : payload.fields.get('chat_id')
      if(state.currentChat[targetChatId].messagesData[payload.tempId]){
        state.currentChat[targetChatId].messagesData[payload.tempId].failed = true
      }
    }
  },

  markMessageAsDeleted(state, { data, chat_id }) {
    const key = state.currentChat[chat_id].messagesData[data.id] ? data.id : data.internal_id || data._id;
    if (state.currentChat[chat_id].messagesData[key]) {
      state.currentChat[chat_id].messagesData[key].is_deleted = true;
    }
  },

  /**
   *
   * -----------END MESSAGES SCOPE-------------
   *
   */

  incrementInProgressCurrentPage: state => {
    state.inProgressPagination.current_page++;
  },
  incrementGroupsCurrentPage: state => {
    state.groupsPagination.current_page++;
  },
  incrementQueueCurrentPage: state => {
    state.queuePagination.current_page++;
  },
  incrementInChatbotCurrentPage: state => {
    state.inChatbotPagination.current_page++;
  },
  incrementInSnoozeCurrentPage: state => {
    state.inSnoozePagination.current_page++;
  },
  incrementFinishedCurrentPage: state => {
    state.finishedPagination.current_page++;
  },

  setChatLastMessage: (state, { chatType, message }) => {
    const last_message_data = {
      body: message.body,
      type: message.type,
      date: message.time,
      send_by_me: true
    };

    state[chatType][message.chat_id].last_message_data = last_message_data;
  },

  cleanMessagesDataById: (state, chatId) => {
    state.currentChat[chatId].messagesData = {};
    state.currentChat[chatId].messagesRefs = {};
  },

  setSearchedMessages(state, { messages }) {
    state.searchedMessages = messages;
  },
  setSearchedMessagesNextPage(state, { messages }) {
    if (messages.length)
      messages.forEach(message => {
        state.searchedMessages.push(message);
      });
    else state.searchedMessages = state.searchedMessages.concat(Object.values(messages));
  },
  clearSetSearchedMessages: state => (state.searchedMessages = []),
  setLastTermSearchedMessage: (state, payload) => (state.lastTermSearchedMessage = payload),
  setSearchedMessagesPagination(state, payload) {
    state.searchedMessagesPagination = payload;
  },
  setMessageFound: (state, messageFound) => (state.messageFound = messageFound),

  setEmptyChatContact: (state, emptyChatContact) => {
    state.emptyChatContact = emptyChatContact
    state.currentChat['empty'] = emptyChatContact
  },

  setLastVisitedWhatsAppRoute: (state, route) => (state.lastVisitedWhatsAppRoute = route),
  setTalksTab: (state, tab) => (state.talksTab = tab),
  setAllTalksLoaded: (state, allTalksLoaded) => (state.allTalksLoaded = allTalksLoaded),

  setClientResponseLate(state, payload) {
    state.currentChat[payload.chat.id].lag_to_response = payload.chat.lag_to_response;
  },
  setClientResponseLateQueue(state, payload) {
    state.currentChat[payload.chat.id].queue_response_is_late = payload.chat.queue_response_is_late;
  },
  setRemoveUnanswerMark(state, chatId) {
    state.currentChat[chatId].lag_to_response = {
      response_is_late: false,
      late_since: null,
      max_time_to_be_answer: null
    };
    state.currentChat[chatId].queue_response_is_late = {
      response_is_late: false,
      late_since: null,
      max_time_to_be_answer: null
    };
  },

  setAllProtocols: (state, payload) => (state.allProtocols = payload),
  setProtocol: (state, payload) => (state.protocol = payload),
  setProtocolFound: (state, protocolFound) => (state.protocolFound = protocolFound),
  setLoadMessagesContext: (state, context) => (state.loadMessagesContext = context),

  updateChatName(state, { chatId, contact }) {
    state.currentChat[chatId].contact = contact;

    const types = ["inProgress", "queue", "inChatbot", "inSnooze", "finished"];
    types.forEach(type => {
      if (state[type][chatId]) {
        state[type][chatId].contact = contact;
      }
    });
  },

  updateChatMood(state, { chatId, mood }) {
    state.currentChat[chatId].mood = mood;

    const types = ["inProgress", "queue", "inChatbot", "inSnooze", "finished"];
    types.forEach(type => {
      if (state[type][chatId]) {
        state[type][chatId].mood = mood;
      }
    });
  },

  toggleOpenTransferForm: (state) => state.openTransferForm = !state.openTransferForm,

  updateWabaMessageReceived (state, { chat_id, value }) {
    state.currentChat[chat_id].waba_message_received = value
  },

  setChatState(state, {chatId, chatState, value}){
    state.currentChat[chatId].states[chatState] = value
  },


  updateCurrentChatOwner(state, payload) {
    if(state?.currentChat[payload.currentChatId]) {
      if(state?.currentChat[payload.currentChatId]?.agent_id) {
        state.currentChat[payload.currentChatId].agent_id = payload.userId;
      }
      if(state?.currentChat[payload.currentChatId]?.agent?.id) {
        state.currentChat[payload.currentChatId].agent.id = payload.userId;
      }

      if(state?.currentChat[payload.currentChatId]?.agent_name) {
        state.currentChat[payload.currentChatId].agent_name = payload.userName;
      }
      if(state?.currentChat[payload.currentChatId]?.agent?.name) {
        state.currentChat[payload.currentChatId].agent.name = payload.userName;
      }
    }
  },
}

