import axios from "axios";

export default {


  /**
 *
 */
  getList({ commit, getters }) {

    commit('startLoadingFlags')
    commit('setList', []);

    return new Promise((resolve, reject) => {
      axios.get('/receptive_ivr', { params: getters.getParams }).
        then((response) => {
          commit('setPagination', response.data.meta.pagination);
          resolve();
          commit('setList', response.data.data);
        }).catch((response) => {
          reject({ message: response.response.data.detail });
        }).finally(() => {
          commit('stopLoadingFlags')
        });
    });
  },

  /**
    *
    * @param {*} param0
    * @param {*} payload
    */
  create({ commit }, payload) {
    const headers = { 'Content-Type': 'multipart/form-data' };
    commit('startLoadingFlags')
    commit('clearErrors')

    return new Promise((resolve, reject) => {
      axios.post('/receptive_ivr', payload.formData, { headers })
        .then(() => {
          resolve({ message: "URA Receptiva cadastrado com sucesso!" });
        })
        .catch((response) => {
          reject({ message: response.response.data.detail });
          response.response.status == 422 && response.response.data.errors && commit('setErrors', response.response.data.errors);
        }).finally(() => {
          commit('stopLoadingFlags')
        });
    });
  },


  /**
   *
   * @param {*} param0
   * @param {*} payload
   */
  findById({ commit }, payload) {
    commit('startLoadingFlags')
    commit('setRegister', null)
    return new Promise((resolve, reject) => {
      axios.get('/receptive_ivr/' + payload.id + '?include=keys').
        then((response) => {
          commit('setRegister', response.data.data);
          resolve();
        }).catch((response) => {
          reject({ message: response.response.data.detail });
        }).finally(() => {
          commit('stopLoadingFlags')
        });
    });

  },

  /**
 *
 * @param {*} param0
 * @param {*} payload
 */
  update({ commit }, payload) {
    const headers = { 'Content-Type': 'multipart/form-data' };
    commit('startLoadingFlags')
    commit('clearErrors')
    return new Promise((resolve, reject) => {
      axios.post('/receptive_ivr/' + payload.id, payload.formData, { headers })
        .then(() => {
          resolve({ message: "URA Receptiva modificado com sucesso!" });
        }).catch((response) => {

          reject({ message: response.response.data.detail });
          response.response.status == 422 && response.response.data.errors && commit('setErrors', response.response.data.errors);

        }).finally(() => {
          commit('stopLoadingFlags')
        });
    });
  },

  /**
   *
   * @param {*} param0
   * @param {*} payload
   */
  remove({ commit }, payload) {
    commit('startLoadingFlags')
    // commit('setRegister', null)
    return new Promise((resolve, reject) => {
      axios.delete('/receptive_ivr/' + payload.id).
        then(() => {
          resolve({ message: "URA Receptiva removido com sucesso!" });
        }).catch((response) => {
          reject({ message: response.response.data.detail });
        }).finally(() => {
          commit('stopLoadingFlags')
        });
    });
  }
}
