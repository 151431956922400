<template>
  <div class="container-fluid">
    <header-component
      redirect-link="/manager/voice-panel"
      title="Configurações"
      :pretitle="$t('uppercases.voice')"
    />
    <div class="row">
      <wrapper-card-settings
        :title="$t('dialer_menu_capitalize')"
        :settings="dialerSettings"
      />
      <div class="px-3">
        <hr class="mt-0 mb-4">
      </div>
      <wrapper-card-settings
        :title="$t('receptive_capitalize')"
        :settings="receptiveSettings"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import HeaderComponent from "@/components/header-component.vue";
import WrapperCardSettings from "@dialer/manager/pages/settings/voice-settings/wrapper-card-settings.vue";

export default {
  name: "VoiceSettings",
  components: {
    HeaderComponent,
    WrapperCardSettings,
  },

  data() {
    return {
      dialerSettings: [
        {
          title: this.$t("capitalizes.qualifications"),
          description: this.$t("call_tabulation"),
          redirectLink: "/manager/qualifications",
          icon: "far fa-thumbs-up",
          show: true,
        },
        {
          title: this.$t("capitalizes.intervals"),
          description: this.$t("agents_breaks"),
          redirectLink: "/manager/intervals",
          icon: "far fa-clock",
          show: true,
        },
        {
          title: this.$t("capitalizes.feedbacks"),
          description: this.$t("evaluation_criteria"),
          redirectLink: "/manager/feedbacks",
          icon: "far fa-comments",
          show: true,
        },
        {
          title: this.$t("telephony_capitalize"),
          description: this.$t("route_settings"),
          redirectLink: "/manager/telephony",
          icon: "far fa-phone-intercom",
          show: true,
        },
        {
          title: this.$t("active_IVR"),
          description: this.$t("call_triggering_via_ivr"),
          redirectLink: "/manager/ivrs",
          icon: "far fa-tty",
          show: true,
        },
        {
          title: this.$t("blocklist_capitalize"),
          description: this.$t("blocked_numbers_for_calling"),
          redirectLink: "/manager/blocklist",
          icon: "far fa-lock",
          show: true,
        },
      ],

      receptiveSettings: [
        {
          title: this.$t("receptive_numbers_capitalize"),
          description: this.$t("numbers_that_receive_calls"),
          redirectLink: "/manager/receptive-numbers",
          icon: "far fa-phone-arrow-down-left",
          show: true,
        },
        {
          title: this.$t("receptive_queues_capitalize"),
          description: this.$t("agents_who_receive_calls"),
          redirectLink: "/manager/receptive-queue",
          icon: "far fa-list",
          show: true,
        },
        {
          title: this.$t("receptive_IVR_capitalize"),
          description: this.$t("IVR_that_distributes_calls"),
          redirectLink: "/manager/receptive-ivrs",
          icon: "far fa-tty",
          show: true,
        },
        {
          title: this.$t("IVR_after_call_capitalize"),
          description: this.$t("customer_feedback_IVR"),
          redirectLink: "/manager/ivr-after-call",
          icon: "far fa-tty",
          show: true,
        },
      ],
    };
  },

  computed: {
    ...mapGetters("auth", {
      user: "getUser",
    }),
  },

  beforeMount() {
    if (this.user) {
      if (!this.user.company.ura_licenses) {
        let ivrIndex = this.dialerSettings.findIndex(
            setting => setting.title === this.$t("active_IVR"),
        );

        this.dialerSettings.splice(ivrIndex, 1);
      }

      if (this.user.company.plan === 1) { // Ilimitado
        let telephonyIndex = this.dialerSettings.findIndex(
            setting => setting.title === this.$t("telephony_capitalize"),
        );

        this.dialerSettings.splice(telephonyIndex, 1);
      }
    }
  },
};
</script>
