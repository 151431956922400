<template>
  <div
    v-if="!isLoading"
    class="card"
  >
    <div class="card-header">
      <h4 class="m-0 text-capitalize">
        {{ $t(cardTitle) }}
      </h4>
      <div v-if="!compact">
        <span class="text-medium tx-gray-400 me-2"> Total </span>
        <span class="text-large"> {{ count }} </span>
      </div>
    </div>

    <div class="card-body">
      <div
        v-if="!compact"
        class="row"
      >
        <div
          class="col col-xxl d-flex flex-column align-items-center"
          v-for="(status, index) in dataStatus"
          :key="index"
        >
          <span class="text-large">{{ status.value }}</span>
          <div class="d-flex align-items-center">
            <dot-status
              size="7px"
              :color="status.color"
            />
            <small class="ms-1 status-label text-uppercase">{{ $t(status.label) }}</small>
          </div>
        </div>
      </div>

      <slot name="body" />

      <div
        :class="{ 'mt-4': !compact }"
        class="d-flex align-items-center justify-content-between"
      >
        <template
          v-for="(status, index) in dataStatus"
          :key="index"
        >
          <div
            v-if="status.value"
            :class="{
              progress: true,
              'status-progress-bar': true,
              'ms-2': index > 0
            }"
            :style="{
              width: (status.value / count * 100) + '%',
              height: '7px'
            }"
          >
            <div
              class="progress-bar"
              role="progressbar"
              :style="{
                width: '100%',
                backgroundColor: status.color
              }"
              aria-valuenow="100"
              aria-valuemin="0"
              aria-valuemax="100"
            />
          </div>
        </template>
      </div>
      <div
        v-if="compact"
        class="col-12 d-flex justify-content-between pt-4"
      >
        <div
          v-for="(status, index) in dataStatus"
          :key="index"
        >
          <div class="d-flex align-items-center">
            <dot-status
              size="7px"
              :color="status.color"
            />
            <small class="ms-1 status-label text-uppercase">{{ status.label }}</small>
          </div>
        </div>
      </div>
    </div>
    <slot />
  </div>
  <div
    v-else
    class="loading-card"
  />
</template>

<script>
import DotStatus from "@components/dot-status.vue";

export default {
  name: "StatusCard",
  components: { DotStatus },
  props: {
    cardTitle: {
      type: String,
      default: ""
    },

    count: {
      type: Number,
      default: 0
    },

    dataStatus: {
      type: Array,
      default: () => []
    },

    isLoading: {
      type: Boolean,
      default: false
    },

    compact: {
      type: Boolean,
      default: false
    }
  }
}

</script>

<style scoped lang="scss">
.status-label {
  color: $color-text-gray;
}

.status-progress-bar {
  transition: width 0.3s ease;
}

.loading-card {
  height: 176px;
  background: linear-gradient(110deg, #F0F4FA 8%, #F9FBFD 18%, #F0F4FA 33%);
  border-radius: 8px;
  background-size: 200% 100%;
  animation: 1.5s shine linear infinite;
  margin-bottom: 1.5rem;
}

@keyframes shine {
  to {
    background-position-x: -200%;
  }
}
</style>
