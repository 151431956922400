export default {
  loading: false,
  errorMessage: "",
  errors: {},
  user: null,

  //TODO: improve, use code list as agentstatus
  logoutCause: "", // 'force'

  //null = show now
  nextDataUpdate: null,
  isRecentlyLogged: false,

  impersonateUserBackup: null,
  impersonate: false,

  loadingFlags: {
    login: false,
    logout: false,
    notifyLogout: false,
    updateUser: false,
    resetPassword: false,
    requestPasswordChange: false,
    checkEmail: false,
    resendEmail: false,
    confirmationEmail: false,
    loginTwoFa: false
  },
  allStates: null,
  includes: ["company.partner","company.tags","permissions", "teams.instances", "ring_groups"],
  filters: {},
  userLogin: "",
  twoFa: null,
  currentCompany: null,
  showPaymentModal:false
};
