export default {
  loadingFlags: {
    fetchPendingActivities: false,
    fetchPendingActivitiesOfDay: false,
    fetchFutureActivities: false,
    completeActivity: false,
    createActivity: false,
    fetchPinNotes: false,
    deleteActivity: false,
    updateActivity: false,
    fetchStatisticsActivities: false
  },
  errors: {
    createActivity: {},
    updateActivity: {},
    createNote: {}
  },
  errorMessage: "",
  pendingActivities: [],
  pendingActivitiesPagination: {
    per_page: -1,
    current_page: 1
  },
  pendingActivitiesFilters: {
    search: null,
    owner_id: null,
    contact_funnel_id: null,
  },

  pendingActivitiesOfDay: [],
  pendingActivitiesOfDayPagination: {
    per_page: 7,
    current_page: 1
  },
  pendingActivitiesOfDayFilters: {
    search: null,
    owner_id: null,
    funnel_id: null,
    is_future: 0,
  },

  futureActivities: [],
  futureActivitiesPagination: {
    per_page: 7,
    current_page: 1
  },
  futureActivitiesFilters: {
    search: null,
    owner_id: null,
    funnel_id: null,
    is_future: 1,
  },

  statisticsActivitiesFilters: {
    funnel_id: null,
    owner_id: null
  },

  statisticsActivities: [],

  pinNotes: [],
}
