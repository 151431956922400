import store from "@store";

import WhatsAppIndex from "@whatsapp/pages/whatsapp-index"
import WhatsAppChat from "@whatsapp/components/whatsapp-chat.vue";
import EmptyChat from "@whatsapp/components/whatsapp-empty-chat.vue"


export default {
  path: "",
  name: "whatsapp-index",
  components: {
    main: WhatsAppIndex
  },
  children: [
    {
      path: ":id",
      components: {
        chat: WhatsAppChat
      },
      beforeEnter: (to, from, next)=> {
        if(to.params?.id) store.dispatch("nps/closeNpsWhenOpen")
        if(!Object.keys(store.getters["whatsapp/chat/getCurrentChat"]).length){
          next("/whatsapp")
        } 
        else next()
      }
    },
    {
      path: "empty-chat",
      components: {
        chat: EmptyChat
      },
      beforeEnter: (to, from, next) => {
        if (!Object.keys(store.getters["whatsapp/chat/getCurrentChat"]).length) {
          next("/whatsapp");
        } else next();
      }
    }

  ],
  
  beforeEnter: (to, from, next) => {
    if (store.getters["auth/isManager"] || store.getters["auth/isAgent"] || store.getters["auth/isSupervisor"]){
       if(store.getters["auth/getUser"]?.company?.company_financial_status.status === "Bloqueada"){
        store.commit('auth/setPaymentModal', true);
       }else {
         store.commit('auth/setPaymentModal', false);
       }
      next();
    }
    else next({
      name: "not-found",
      params: {
        pathMatch: to.path.split('/').slice(1)
      }
    });
  }
}
