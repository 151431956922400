<template>
  <li
    class="card-tag col-auto"
    :class="{'cursor-pointer': clickEnabled}"
    :style="{
      'background-color': tag.color,
      'color': tagColors[tag.color]
    }"
    @click="handleClick(tag)"
  >
    {{ tag.name }}
    <span
      v-if="loading"
      class="spinner-border spinner-border-sm"
    />
    <span
      v-else-if="closeEnabled"
      class="cursor-pointer"
      @click="$emit('close')"
    >x</span>
  </li>
</template>

<script>
import {tagColors} from "@/utils";

export default {
  name: "TagContact",

  emits: ['close', 'click'],

  props: {
    tag: {
      type: Object,
      required: true
    },

    closeEnabled: {
      type: Boolean,
      default: false
    },

    clickEnabled: {
      type: Boolean,
      default: false
    },

    loading: {
      type: Boolean,
      default: false
    }
  },

  data(){
    return{
      tagColors: tagColors,
    }
  },

  methods: {
    handleClick(tag) {
      if(this.clickEnabled && !this.loading) {
        this.$emit('click', tag)
      }
    }
  }
}
</script>

<style scoped lang="scss">
.card-tag {
  width: fit-content;
  height: 1.5rem;
  border-radius: 0.375rem;
  padding: 0 0.5rem;
  font-size: 0.875rem;
}

.card-tag-sm {
  width: fit-content;
  height: 1.25rem;
  border-radius: 0.375rem;
  padding: 0 0.5rem;
  font-size: 0.675rem;
}
</style>