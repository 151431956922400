export default {
  metrics: [],
  chatbots: [],
  chatbotsPagination: {},
  chatbotsParams: {
    page: 1,
    search: ""
  },
  chatbot: null,
  loadingFlags: {
    createChatbot: false,
    fetchMetrics: false,
    fetchChatbots: false,
    fetchChatbot: false,
    updateChatbotSettings: false,
    deleteChatbot: false
  }
}
