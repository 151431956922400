<template>
  <div
    style="width: 100%;"
    class="card d-flex justify-content-between align-items-end pt-3 pb-3 flex-row mb-0"
  >
    <textarea
      ref="textarea"
      class="form-control m-0 border-0"
      :rows="row"
      v-model="text"
      style="resize: none; width:92%"
      :placeholder="row !== 1 ? $t('ask_some_question') : '' "
      @keydown="enterSubmit"
    />
    <div class="d-flex gap-2">
      <button
        class="btn p-0 send-button"
        style="margin-right: 16px"
        @click="submitQuestion()"
        :disabled="loading.sendQuestion || !text"
      >
        <i class="fal fa-paper-plane-top fw-medium m-0" />
      </button>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  emits: ['question'],
  data() {
    return {
      text: ''
    }
  },
  props: {
    row: {
      type: Number,
      default: 1
    }
  },
  computed : {
    ...mapGetters("crm/funnels", {
      currentFunnel: "getCurrentFunnel"
    }),
    ...mapGetters("crm/ai_assistant", {
      loading: "getLoadingFlags"
    })
  },
  methods: {
    ...mapActions('crm/ai_assistant', [
      'sendQuestion'
    ]),

    submitQuestion(){
      const questionBody = {
        prompt: this.text,
        funnel_id: this.currentFunnel.id
      }
      this.sendQuestion(questionBody)
      this.$emit('question', questionBody.prompt)
      this.text = ''
    },

    enterSubmit(e) {
      if (e.keyCode === 13) {
        if (e.ctrlKey || e.shiftKey ) {
          this.$refs.textarea.scrollTop = this.$refs.textarea.scrollHeight;
          return
        }

        if (this.text.trim() === "") {
          e.preventDefault()
          this.text = "";
          return
        }

        e.preventDefault();
        this.submitQuestion();
      }
    },
  }
}
</script>

<style scoped>
.audio-button{
  height: 40px;
  width: 40px;
  border-radius:10px;
  color: #677C92;
  background-color: #E1E9F4;
}

.send-button {
  color: #fff;
  height: 40px;
  width: 40px;
  border-radius: 10px;
  position: relative;
  overflow: hidden;
  display: flex;
  gap: 2px;
  align-items: center;
  justify-content: center;
  border: 0;
  margin: 0;
  padding: 10px;
  z-index: 1;
  background:  linear-gradient(94deg, #E34AB8 -42.06%, #7745EB 64.09%, #6CBAFE 193.52%);
}

.send-button::before,
.send-button::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: opacity 0.6s ease-in-out;
  z-index: -1;
}

.send-button::before {
  background: linear-gradient(94deg, #E34AB8 -42.06%, #7745EB 64.09%, #6CBAFE 193.52%);
  opacity: 1;
}

.send-button::after {
  background: linear-gradient(90deg, #E34AB8 -42.27%, #7745EB 13.8%, #6CBAFE 95.42%);
  opacity: 0;
}

.send-button:hover::before {
  opacity: 0;
}

.send-button:hover::after {
  opacity: 1;
}
</style>