<template>
  <div>
    <div
      id="attachmentCollapse"
      ref="attachmentCollapse"
      class="collapse pt-0 p-4"
    >
      <attachment-field-list
        v-model="attachmentList"
        @close="closeAttachCollapse"
      />
    </div>
    <div
      id="internalNoteCollapse"
      ref="internalNoteCollapse"
      class="collapse pt-0 p-4"
    >
      <note-field-list
        v-model="noteList"
        @close="closeNotesCollapse"
      />
    </div>
    <div
      id="linkCollapse"
      ref="linkCollapse"
      class="collapse pt-0 p-4"
    >
      <link-field-list
        v-model="linkList"
        @close="closeLinkCollapse"
      />
    </div>
    <div class="card-footer bg-light">
      <div class="d-flex justify-content-between">
        <div class="d-flex gap-3">
          <button
            v-if="false"
            class="btn btn-light"
            @click="openAttachCollapse"
          >
            <i class="fa-regular fa-paperclip" />
          </button>
          <button
            class="btn btn-light"
            @click="openNotesCollapse"
          >
            <i class="fa-regular fa-note m-0" />
          </button>
          <button
            class="btn btn-light"
            @click="openLinkCollapse"
          >
            <i class="fa-regular fa-link" />
          </button>
        </div>
        <div class="d-flex align-items-center gap-3">
          <div
            v-if="shouldShowIsCompleted"
            class="form-check"
          >
            <input
              id="is_completed"
              v-model="is_completed"
              checked
              class="form-check-input"
              type="checkbox"
            >
            <label
              class="form-check-label"
              for="is_completed"
            >
              {{ $t('mark_as_concluded') }}
            </label>
          </div>
          <app-button
            class="px-5 gap-2 d-flex"
            @click="submit"
          >
            <i class="fa-regular fa-check align-self-center" />
            {{ $t('capitalizes.save') }}
          </app-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {defineComponent} from 'vue'
import {Collapse} from "bootstrap";
import LinkFieldList from "@crm/components/activity/activity-fields/link-field-list.vue";
import NoteFieldList from "@crm/components/activity/activity-fields/note-field-list.vue";
import AttachmentFieldList from "@crm/components/activity/activity-fields/attachment-field-list.vue";
import AppButton from "@components/app-button.vue";
import moment from 'moment';

export default defineComponent({
  name: "ActivityCardFooter",
  emits: ['submitActivity'],
  components: {AppButton, AttachmentFieldList, NoteFieldList, LinkFieldList},


  data() {
    return {
      attachesCollapse: null,
      notesCollapse: null,
      linksCollapse: null,
      is_completed: false,
      shouldShowIsCompleted: true,

      attachmentList: [],
      noteList: [],
      linkList: []
    }
  },

  props: {
    dueDate: {
      type: String,
      default: null
    },

  },

  mounted() {
    this.attachesCollapse = new Collapse(this.$refs.attachmentCollapse, {toggle: false})
    this.notesCollapse = new Collapse(this.$refs.internalNoteCollapse, {toggle: false})
    this.linksCollapse = new Collapse(this.$refs.linkCollapse, {toggle: false})
  },

  methods: {
    submit() {
      this.$emit('submitActivity', {
          is_completed: this.is_completed,
          attachments: this.filledAttachments
        }
      )
    },

    openAttachCollapse() {
      if (this.attachesCollapse) {
        this.attachesCollapse.show()
      }
    },
    openNotesCollapse() {
      if (this.notesCollapse) {
        this.notesCollapse.show()
      }
    },
    openLinkCollapse() {
      if (this.linksCollapse) {
        this.linksCollapse.show()
      }
    },

    closeAttachCollapse() {
      if (this.attachesCollapse) {
        this.attachesCollapse.hide()
      }
    },
    closeNotesCollapse() {
      if (this.notesCollapse) {
        this.notesCollapse.hide()
      }
    },
    closeLinkCollapse() {
      if (this.linksCollapse) {
        this.linksCollapse.hide()
      }
    },
  },

  computed: {
    filledAttachments() {
      return [
        ...this.attachmentList.filter((attachment) => attachment.content),
        ...this.noteList.filter((attachment) => attachment.content),
        ...this.linkList.filter((attachment) => attachment.content)
      ]
    }
  },

  watch: {
    dueDate(newVal) {
      const now = moment().startOf('day');
      const newDate = moment(newVal, "DD/MM/YYYY");
      this.shouldShowIsCompleted = newDate.isBefore(now) || newDate.isSame(now);
      this.is_completed = newDate.isBefore(now);
    }
  },
})
</script>
