<template>
  <div
    class="dropdown"
  >
    <button
      class="btn btn-secondary shadow-none background-loading btn-dropdown-top-bar d-flex justify-content-center align-items-center"
      type="button"
      data-test="moreChatOptions-button"
      data-bs-toggle="dropdown"
      data-bs-auto-close="outside"
      aria-expanded="false"
    >
      <span class="fe fe-more-horizontal" />
    </button>
    <div class="dropdown-menu">
      <h6 style="font-weight: 500; margin-bottom: 16px;">
        {{ $t('more_options') }}
      </h6>
      <ul class="list-group list-group-flush">
        <li
          class="list-group-item h5 fw-normal mb-0"
          data-test="showProfile-button"
          @click="$emit('showEdit')"
        >
          <span v-if="isGroup">{{ $t('group_details') }}</span>
          <span v-else>{{ $t('view_profile') }}</span>
        </li>
        <li
          v-if="!isGroup"
          class="list-group-item h5 fw-normal mb-0"
          style="cursor: pointer; padding: 12px;"
          data-test="showProtocols-button"
          @click="showProtocolMenu = !showProtocolMenu"
        >
          {{ $t('protocol') }}
        </li>
        <li
          class="list-group-item h5 fw-normal mb-0"
          style="cursor: pointer; padding: 12px;"
          data-test="searchChat-button"
          @click="searchInChat = true"
        >
          {{ $t('search_in_chat') }}
        </li>
        <li
          v-if="!isGroup"
          class="list-group-item h5 fw-normal mb-0"
          :class="{'disabled': !funnels.length}"
          data-test="showFunnels-button"
          @click="showFunnels = !showFunnels"
        >
          {{ $t('connect_to_funnel') }}
        </li>
      </ul>
      <funnels-list v-if="showFunnels" />
    </div>
    <protocol-menu
      v-if="!isGroup"
      v-model="showProtocolMenu"
    />
    <search-messages-canvas
      v-if="currentChat"
      :contact-id="contactId"
      :current-chat="currentChat"
      :model-value="searchInChat"
      @close="searchInChat = false"
    />
  </div>
</template>

<script>
import FunnelsList from "@whatsapp/components/funnels-list.vue";
import {mapGetters} from "vuex";
import ProtocolMenu from "../protocols/protocol-menu.vue";
import SearchMessagesCanvas from "../search-messages/search-messages-canvas.vue";

export default {
  emits: ["showEdit"],

  components:{
    SearchMessagesCanvas,
    ProtocolMenu,
    FunnelsList,
  },
  props: {
    isGroup: { type: Boolean, default: false }
  },

  data(){
    return {
      showFunnels: false,
      showProtocolMenu: false,
      searchInChat: false,

    }
  },

  computed: {
    ...mapGetters("whatsapp/crm", {
      funnels: "getFunnels",
    }),
    ...mapGetters("whatsapp/chat", {
      chats: "getCurrentChat",
    }),

    currentChat () {
      return this.chats[this.$route.params.id]
    },
    contactId () {
      return this.currentChat.contact.contact_id ? this.currentChat.contact.contact_id : this.currentChat.contact.id
    }

  }
};
</script>

<style lang="scss" scoped>
.btn-dropdown-top-bar {
  margin-left: $whatsapp-spacing-2;
  padding: $whatsapp-button-padding !important;
  color: $color-text-gray !important;
  height: $whatsapp-button-size !important;
  width: $whatsapp-button-size !important;
}
.dropdown {
  display: inline-block;
  .dropdown-menu {
    min-width: 240px;
    padding: 16px 21px;

    .list-group-item {
      cursor: pointer;
      padding: 12px;
      border-radius: 8px;
      border-color: #E1E9F4;

      &:hover{
        background-color: $color-gray-blue-300;
        color: $color-blue-300;
      }
    }
  }
}
button, .button-whatsapp {
  min-width: 1.244rem;
  min-height: 1.244rem;
  background-color: #e1e9f4;
  border: none;
  color: #95aac9;
  border-radius: $whatsapp-border-radius;
  &:hover:not(.no-hover), .whatsapp-calling {
    background-color: $color-blue-300 !important;
    color: #e3ebf6 !important;
  }
}
</style>
