<template>
  <div class="row p-0 m-0">
    <div
      style="padding-top: 0.5rem"
      class="align-items-center px-0"
    >
      <div
        class="col d-flex justify-content-between"
        style="padding: 0 16px;"
      >
        <filter-dropdown
          :enable-search="true"
          :initial-selected-id="filters.agent_id"
          :list="agentsList"
          :loading="loadingFlags.fetchAgents"
          :title="$t('agent_capitalize')"
          data-test="agentsFilter-button"
          @radio-selected="filterByAgent($event)"
        />
        <filter-dropdown
          :title="$t('team_omni')"
          :list="teamsList"
          :initial-selected-id="filters.team_id"
          :loading="loadingFlags.fetchTeams"
          data-test="teamsFilter-button"
          @radio-selected="filterByTeam($event)"
        />
        <filter-dropdown
          :title="$t('channel_group')"
          :list="groupChannelsList"
          :initial-selected-id="filters.group_channel_id"
          :loading="loadingFlags.fetchGroupChannels"
          data-test="connectionsFilter-button"
          @radio-selected="filterByGroupChannel($event)"
        />
        <select-contact-tags @select="filterByTags" />
      </div>
    </div>
  </div>
</template>

<script>
import FilterDropdown from "@components/filter-dropdown.vue";
import {mapActions, mapGetters} from "vuex";
import SelectContactTags from "@whatsapp/components/tags/select-contact-tags.vue";

export default {
  emits: ["filterAgent"],

  components: {SelectContactTags, FilterDropdown },

  data() {
    return {
      filters: {
        agent_id: null,
        team_id: null,
        group_channel_id: null,
      },
    };
  },

  computed: {
    ...mapGetters("whatsapp/chat", {
      chatFilters: "getFilters",
    }),

    ...mapGetters("manager/whatsapp_filters", {
      agentsList: "getAgents",
      teamsList: "getTeams",
      groupChannelsList: "getGroupChannels",
      loadingFlags: "getLoadingFlags"
    })
  },

  created() {
    const filterKeys = ["agent_id", "team_id", "group_channel_id", "tags_ids"];
    filterKeys.forEach(key => this.filters[key] = this.chatFilters[key]);

    this.fetchAgents()
    this.fetchTeams()
    this.fetchGroupChannels()
  },

  methods: {
    ...mapActions("manager/whatsapp_filters", [
      "fetchAgents",
      "fetchTeams",
      "fetchGroupChannels"
    ]),
    ...mapActions("whatsapp/chat", [
      "clearChats"
    ]),

    filterByAgent(id) {
      this.clearChats();
      this.filters.agent_id = id
      this.$emit("filterAgent", this.filters)
    },

    filterByTeam(id) {
      this.clearChats();
      this.filters.team_id = id
      this.$emit("filterAgent", this.filters)
    },

    filterByGroupChannel(id) {
      this.clearChats();
      this.filters.group_channel_id = id
      this.$emit("filterAgent", this.filters)
    },

    filterByTags(ids) {
      this.clearChats();
      this.filters.tags_ids = ids
      this.$emit("filterAgent", this.filters)
    }
  },
};
</script>
