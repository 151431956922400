<template>
  <div
    v-if="opportunity?.status !== 'lost'"
    :class="isModal ? 'm-0' : ''"
    class="card"
  >
    <div
      v-if="isLoading"
      class="card-body"
    >
      <card-loading
        :height="49"
        margin-bottom="0"
      />
    </div>
    <template v-else>
      <activity-card-header
        :disabled="disabled"
        :initial-tab="currentTab"
        :is-modal="isModal"
        :should-show-new-activity-text="shouldShowNewActivityText"
        @current-tab="currentTab = $event"
      />
      <form-activity
        v-if="currentTab"
        :show-modal="showModal"
        :type="currentTab"
      />
    </template>
  </div>
</template>
<script>
import ActivityCardHeader from '@crm/components/activity/form-activity/activity-card-header.vue';
import FormActivity from "@crm/components/activity/form-activity/form-activity.vue";
import CardLoading from "@components/card-loading.vue";
import {mapGetters} from "vuex";

export default {
  name: "NewActivityCard",
  components: {
    CardLoading,
    FormActivity,
    ActivityCardHeader
  },

  data() {
    return {
      currentTab: null,
      internalNoteAttachments: {type: 'note', content: ''},
      linkAttachments: {type: 'link', content: ''}
    }
  },

  computed: {
    ...mapGetters('crm/opportunities', {
      opportunity: 'getOpportunity',
    })
  },

  props: {
    isModal: {
      type: Boolean,
      default: false
    },

    initialActivityTab: {
      type: String,
      default: null
    },

    shouldShowNewActivityText: {
      type: Boolean,
      default: true
    },

    disabled: {
      type: Boolean,
      default: false
    },

    showModal: {
      type: Boolean,
      default: false
    },

    isLoading: {
      type: Boolean,
      default: false
    }
  },

  beforeMount() {
    if (this.initialActivityTab) {
      this.currentTab = this.initialActivityTab;
    }
  },

  watch: {
    initialActivityTab(data) {
      this.currentTab = data;
    }
  }
}
</script>
