<template>
  <div class="card bar-chart">
    <div class="card-header">
      <h4 class="card-header-title fw-normal">
        {{ title }}
      </h4>
    </div>
    <div class="card-body">
      <canvas
        class="chart-canvas"
        ref="chart"
      />
    </div>
  </div>
</template>

<script>
import { Chart } from "chart.js";

export default {
  /**
   *
   */

  mounted() {
    new Chart(this.$refs.chart, this.options);
    // this.update();
  },

  /**
   *
   */
  data() {
    /**
     *
     */
    return {
      /**
       *
       */
      options: {
        type: "bar",
        options: {
          scales: {
            x: {
              stacked: true,
            },
            y: {
              stacked: true,
              ticks: {
                callback: this.formatTicks,
                maxTicksLimit: 5,
                minTicksLimit: 5,
                stepSize: 1,
              },
            },
          },
        },
      },
    };
  },

  /**
   *
   */
  props: {
    /**
     *
     *
     */
    formatTicks: {
      type: Function,
      default: (val) => val,
    },
    /**
     *
     */
    title: {
      type: String,
      default: ""
    },

    /**
     *
     */
    datasets: {
      type: Array,
      default: () => {
        return [];
      },
    },

    /**
     *
     */
    labels: {
      type: Array,
      default: () => {
        return [];
      },
    },

    /**
     *
     */
    loading: {
      type: Boolean,
      default: false,
    },

    /**
     *
     */
    chartData: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },

  /**
   *
   */
  watch: {
    /**
     *
     */
    chartData() {
      this.update();
    },
  },

  /**
   *
   */
  methods: {
    /**
     *
     */
    getChartIntance() {
      return Chart.getChart(this.$refs.chart);
    },

    /**
     *
     */
    update() {
      let chart = this.getChartIntance();
      chart.data.labels = this.labels;

      //remove old datasets
      chart.data.datasets = [];

      for (let item of this.datasets) {
        chart.data.datasets.push({
          backgroundColor: item.color,
          label: item.label,
          borderWidth: 0,
          borderRadius: 0,
          borderSkipped: true,
          data: [],
        });
      }

      if (chart.data.datasets.length > 0) {
        chart.data.datasets[chart.data.datasets.length - 1]["borderRadius"] = {
          topLeft: 10,
          topRight: 10,
        };
        chart.data.datasets[0]["borderRadius"] = {
          bottomLeft: 10,
          bottomRight: 10,
        };
      }

      for (let i = 0; i < this.chartData.length; i++) {
        chart.data.datasets[i].data = this.chartData[i];
      }


      chart.update();
    },
  },
};
</script>

<style lang="scss">
.bar-chart {
  //fix card height
  canvas {
    min-height: 300px;
  }
}
</style>
