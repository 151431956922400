<template>
  <div
    class="tab-pane fade show active mt-3"
    id="general"
    role="tabpanel"
    aria-labelledby="home-tab"
  >
    <div class="card card-body col-md-12 py-0 px-0">
      <div class="card-header">
        <h2 class="mb-0">
          {{ $t('data') }}
        </h2>
      </div>
      <div class="card-body">
        <div class="d-flex flex-row gap-3">
          <form class="form-group mb-0  col">
            <label class="form-label required"> {{ $t('name_data') }}</label>
            <input
              v-model="fields.name"
              type="text"
              class="form-control"
              :class="{'is-invalid': missedFields && !fields.name}"
              required
            >
          </form>
          <div class="form-group mb-0  col-1">
            <label class="form-label required">{{ $t('color_general') }}</label>
            <color-picker-dropdown
              v-model="fields.color"
              :class="{'is-invalid': missedFields && !fields.color}"
            />
          </div>
          <div class="form-group mb-0 col">
            <label
              class="form-label required"
            >{{ $t('team_omni') }}</label>
            <select
              class="form-select"
              :class="{'is-invalid': missedFields && !fields.team_id}"
              v-model="fields.team_id"
            >
              <option
                v-for="item in list.teams"
                :key="item.id"
                :value="item.id"
              >
                {{ item.name }}
              </option>
            </select>
          </div>
        </div>
      </div>
    </div>
    <div
      class="card card-body col-md-12 py-0 px-0"
      :class="{'disabled-div': mode == 'new-connection'}"
    >
      <div class="card-header">
        <h2 class="mb-0">
          {{ $t('channels') }}
        </h2>
        <button
          v-if="groupChannel && groupChannel.channels.data.length < 2"
          @click="newChannel = true && this.findGroupChannelById({id: this.$route.params.id})"
          class="btn btn-outline-primary px-4"
        >
          <i class="fa-solid fa-plus pe-2" />
          {{ $t('new_channel') }}
        </button>
      </div>
      <div class="card-body">
        <div v-if="groupChannel && groupChannel.channels.data.length">
          <div
            v-for="item in groupChannel.channels.data"
            :key="item.id"
            class="card"
          >
            <div class="card-body">
              <div class="d-flex justify-content-between col-12 align-items-center">
                <div
                  class="d-flex justify-content-center align-items-center gap-3"
                >
                  <i
                    class="fa-kit fa-whatsapp tx-gray"
                    style="font-size: 24px"
                  />
                  <div>
                    <h4 class="mb-0">
                      {{ item.type == 'waba' ? 'Whatsapp API Oficial - WABA' : 'Whatsapp 3C Plus' }}
                    </h4>
                    <span
                      v-if="item.phone"
                      class="text-muted"
                    >
                      {{ formatInternationalPhoneMask(item.phone) }}
                    </span>
                  </div>
                </div>
                <div class="d-flex justify-content-center align-items-center text-center gap-2">
                  <div
                    class="ball"
                    :style="{'background-color': item.status === 'disconnected' && '#1B1B2B'}"
                  />
                  <span
                    class="fw-light"
                    :class="{'tx-green-300': item.status === 'connected'}"
                  >
                    {{ item.status === 'connected'? $t('connected_general') : $t('disconnected') }}
                  </span>
                  <div
                    v-if="item.status == 'connected' && item.type != 'waba'"
                    class="dropdown"
                  >
                    <button
                      data-bs-toggle="dropdown"
                      class="btn btn-outline-primary border-0 button-more text-muted p-0"
                    >
                      <i
                        class="fas fa-ellipsis-h p-3"
                      />
                    </button>
                    <div class="dropdown-menu m-2 p-3">
                      <h5 class="m-0">
                        {{ $t('more_options') }}
                      </h5>
                      <button
                        @click="selectInstance(item)"
                        class="dropdown-item mt-3 me-0"
                      >
                        {{ $t('disconnect') }}
                      </button>
                    </div>
                  </div>
                  <template v-if="item.status === 'disconnected'">
                    <button
                      v-if="item.type && item.type === 'waba'"
                      class="btn btn-primary ms-3"
                      @click="facebookValidation()"
                    >
                      <span class="fab fa-facebook" />
                      {{ $t('validate_with_facebook') }}
                    </button>
                    <button
                      v-if="item.type === 'ultramsg'"
                      @click="_reactivate(item)"
                      class="btn btn-primary ms-3"
                      style="width: 200px !important"
                      :disabled="isLoading"
                    >
                      {{ $t('validate') }}
                      <span
                        class="spinner-border spinner-border-sm"
                        v-if="isLoading"
                      >
                        <span class="visually-hidden">Carregando...</span>
                      </span>
                    </button>
                  </template>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          v-else
          class="d-flex flex-row justify-content-center align-items-center"
        >
          <img
            src="@/assets/img/sad-pluszinha.svg"
            class="img-fluid sad-pluszinha mx-5"
            alt=""
          >
          <div>
            <h4 class="mb-0">
              {{ $t('no_channel_configured') }}
            </h4>
            <small>{{ $t('no_channel_configured_description') }}</small>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="newChannel"
      class="card card-body col-md-12 py-0 px-0"
    >
      <div class="card-header">
        <h2 class="mb-0">
          {{ $t('new_channel') }}
        </h2>
      </div>
      <div class="card-body ">
        <div class="d-flex flex-row">
          <div class="form-group col-12 mb-0">
            <label
              for=""
              class="form-label"
            >{{ $t('type_general') }}</label>
            <div class="d-flex gap-3">
              <select
                v-model="selectedChannel"
                name=""
                id=""
                class="form-select"
              >
                <option
                  v-if="groupChannel.channels.data.every(channel => channel.type !== 'ultramsg')"
                  value="ultramsg"
                >
                  WhatsApp 3C+
                </option>
                <option value="waba">
                  WhatsApp API Oficial - WABA
                </option>
              </select>
              <app-button
                v-if="selectedChannel === 'ultramsg'"
                @click="openNewNumberModal()"
                class="col-4"
              >
                {{ $t('validate') }}
              </app-button>
              <app-button
                v-if="selectedChannel === 'waba'"
                @click="facebookValidation()"
                class="col-4"
              >
                <span class="fab fa-facebook" />
                {{ $t('validate_with_facebook') }}
              </app-button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="card card-body col-md-12 py-0 px-0"
      :class="{'disabled-div': mode == 'new-connection'}"
    >
      <div class="card-header">
        <h2 class="mb-0">
          {{ $t('audio_transcription') }}
        </h2>
      </div>
      <div class="card-body ">
        <span class="text-muted">
          {{ $t('audio_transcription_description') }}
        </span>
        <div class="form-group mt-3">
          <div class="form-check form-switch">
            <input
              class="form-check-input"
              type="checkbox"
              v-model="fields.audio_transcription_enabled"
              data-test="activeAudioTranscription-checkbox"
            >
            <label class="form-check-label">Ativada</label>
          </div>
        </div>
      </div>
    </div>
    <app-button
      v-if="groupChannel && groupChannel.status != 'deleted'"
      @click="edit"
      class="col-12 mb-4 "
      :disabled="disableSubmit"
    >
      {{ $t('save_general') }}
    </app-button>
    <app-button
      v-if="groupChannel && groupChannel.status == 'deleted'"
      @click="reactivate"
      class="col-12 mb-4 "
      :disabled="disableSubmit"
    >
      {{ $t('reactivate') }}
    </app-button>
    <app-button
      v-else-if="mode == 'new-connection'"
      @click="submit"
      class="col-12 mb-4"
      :disabled="disableSubmit"
    >
      {{ $t('save_general') }}
    </app-button>
    <button
      v-if="mode === 'edit-connection' && groupChannel && groupChannel.status !== 'deleted'"
      @click="deleteModal.show()"
      class="col-12 btn btn-outline-danger border-0"
    >
      <i class="fa-light fa-trash" />
      {{ $t('delete_omni') }}
    </button>
    <div
      ref="deleteModal"
      class="modal fade"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header border-0">
            <button
              type="button"
              class="btn-close text-reset"
              data-bs-dismiss="modal"
              aria-label="Close"
            />
          </div>
          <div class="d-flex gap-4 justify-content-center align-items-center text-center flex-column">
            <h2 class="mb-0">
              {{ $t('delete_confirmation_message_p1') }}<br>{{ $t('delete_confirmation_message_p2') }}
            </h2>

            <img
              src="@/assets/img/aloisio-exclamation.svg"
              alt=""
              class="img-fluid"
              style="height: 94px"
            >
            <span>{{ $t('delete_confirmation_message_description_p1') }} <br> {{ $t('delete_confirmation_message_description_p2') }}</span>
            <app-button
              @click="deleteConnection()"
              :loading="loadingFlags.deleteGroupChannel"
              class="btn btn-danger col-8"
            >
              {{ $t('delete_channel_group') }}
            </app-button>
            <button
              @click="deleteModal.hide()"
              class="btn border-0 col-8 mb-4 text-muted"
            >
              {{ $t('cancel_general') }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <div
      ref="disconnectModal"
      class="modal fade"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header border-0">
            <button
              type="button"
              class="btn-close text-reset"
              data-bs-dismiss="modal"
              aria-label="Close"
            />
          </div>
          <div class="d-flex gap-4 justify-content-center align-items-center text-center flex-column">
            <h2 class="mb-0">
              {{ $t('disconnect_channel') }}
            </h2>
            <img
              src="@/assets/img/plusinha-thinking.svg"
              alt=""
              class="img-fluid"
              style="height: 94px"
            >
            <app-button
              @click="deactivateInstance(selectedInstance.id)"
              :loading="loading.deactivateWhatsappInstance || loadingFlags.findGroupChannelById"
              class="btn btn-primary col-8"
            >
              {{ $t('disconnect') }}
            </app-button>
            <button
              @click="this.disconnectModal.hide()"
              class="btn border-0 col-8 mb-4 text-muted"
            >
              {{ $t('cancel_general') }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <whatsapp-new-number
    :data="fields"
    ref="whatsappNewNumber"
  />
  <div
    class="modal-backdrop fade show"
    :class="{'d-none': !inValidation}"
  />
</template>
<script>
import ColorPickerDropdown from "@/components/color-picker-dropdown.vue";
import AppButton from "@/components/app-button.vue"
import whatsappNewNumber from "../../connections-list/components/whatsapp-new-number.vue";
import {mapActions, mapGetters, mapMutations} from 'vuex';
import {formatInternationalPhoneMask} from "@/utils";
import {Modal} from "bootstrap";

export default {
  data() {
    return {
      fields: {
        name: "",
        color: "",
        team_id: null,
        company_id: null,
        audio_transcription_enabled: false,
      },
      waba_fileds:{
        waba_id: null,
        phone_number_id: null
      },
      newChannel: false,
      selectedChannel: null,
      selectedInstance: null,
      missedFields: false,
      deleteModal: null,
      disconnectModal: null,
      inValidation: false
    }
  },

  created(){
    const sessionInfoListener = (event) => {
      if (event.origin !== "https://www.facebook.com") return;
      try {
        const data = JSON.parse(event.data);
        if (data.type === 'WA_EMBEDDED_SIGNUP') {
          if (data.event === 'FINISH') {
            this.waba_fileds.phone_number_id = data.data.phone_number_id
            this.waba_fileds.waba_id = data.data.waba_id
          }
          else {
            const{current_step} = data.data;
            this.setErrorMessage(`Validação interrompida na etapa ${current_step}`)
          }
        }
      } catch {
        console.log('Non JSON Response');
      }
    };

    window.addEventListener('message', sessionInfoListener);
  },

  mounted() {
    this.handleModals()
    if (this.mode === "new-connection") {
      this.setGroupChannel(null)
    }
    this.fields.company_id = this.user.company.id
    this.loadTeamsSelect();
  },

  computed:{
    ...mapGetters("auth", {
      user: "getUser"
    }),
    ...mapGetters("manager/whatsapp", {
      list: "getTeamsList",
      loading: "getLoadingFlags",
      isLoading: "isLoading"
    }),
    ...mapGetters("manager/whatsapp_group_channel", {
      loadingFlags: "getLoadingFlags",
      groupChannel: "getGroupChannel"
    }),

    mode() {
      return this.$route.name
    },

    channelStatus() {
      if (!this.groupChannel.channels.data.length) {
        return "disconnected"
      }
      let status = ""
      this.groupChannel.channels.data.some(el => el.status == "connected" || el.status == "in_process" ?
        status = el.status : status = "disconnected")
      return status
    },

    disableSubmit() {
      return Object.values(this.fields).some((el) => el == null)
    }
  },
  watch: {
    groupChannel: {
      handler(newData) {
        if (newData) {
          this.fields.name = newData.name
          this.fields.color = newData.color
          if (newData.team) {
            this.fields.team_id = newData.team.data.id
          }
          this.fields.audio_transcription_enabled = newData.audio_transcription_enabled
        }
      }
    }
  },

  methods: {
    formatInternationalPhoneMask,
    ...mapMutations("system", [
      "setErrorMessage",
    ]),

    ...mapMutations("manager/whatsapp_group_channel", ["setGroupChannel"]),
    ...mapActions("manager/whatsapp", [
      "deactivateWhatsappInstance",
      "loadTeamsSelect",
      "createInstances"
    ]),

    ...mapActions("manager/whatsapp_group_channel", [
      "reactivateGroupChannel",
      "findGroupChannelById",
      "createGroupChannel",
      "editGroupChannel",
      "deleteGroupChannel"
    ]),

    ...mapMutations("manager/whatsapp", [
      "startLoading",
      "stopLoading"
    ]),

    loadGroupChannel() {
      this.findGroupChannelById({id: this.$route.params.id}).then(() => {
        this.fields.name = this.groupChannel.name
        this.fields.color = this.groupChannel.color
        this.fields.audio_transcription_enabled = this.groupChannel.audio_transcription_enabled
        if (this.groupChannel.team) {
          this.fields.team_id = this.groupChannel.team.data.id
        }
        this.fields.company_id = this.user.company.id
      })
    },

    handleModals() {
      this.deleteModal = new Modal(this.$refs.deleteModal)
      this.disconnectModal = new Modal(this.$refs.disconnectModal)
    },

    submit() {
      const payload = { ...this.fields, company_id: this.user.company.id }

      this.createGroupChannel({formData: payload}).then((groupChannelId) => {
        this.$router.push({path: `/manager/whatsapp/${groupChannelId}/edit`})
      })
    },

    edit() {
      this.editGroupChannel({id: this.$route.params.id, fields: this.fields}).then(() => {
        this.$router.push({path: `/manager/whatsapp/${this.$route.params.id}`})
      })
    },
    reactivate() {
      this.reactivateGroupChannel({id: this.$route.params.id, fields: this.fields}).then(() => {
        this.$router.push({path: '/manager/whatsapp'})
      })
    },

    _reactivate(item) {
      this.startLoading();
      this.$refs.whatsappNewNumber.reactivate(item);
    },

    selectInstance(item) {
      this.selectedInstance = item;
      this.disconnectModal.show();
    },

    deactivateInstance(id) {
      this.deactivateWhatsappInstance({id: id}).then(() => {
        this.loadGroupChannel()
        this.disconnectModal.hide()
      })
    },

    deleteConnection() {
      this.deleteGroupChannel({id: this.$route.params.id}).then(() => {
        this.deleteModal.hide()
        this.$router.push({path: "/manager/whatsapp"})
      })
    },
    openNewNumberModal() {
      let {color, name, team_id} = this.fields

      if (!name) {
        this.setErrorMessage(this.$t('choose_connection_name'))
      }
      if (!color) {
        this.setErrorMessage(this.$t('choose_connection_color'))
      }
      if (!team_id) {
        this.setErrorMessage(this.$t('choose_connection_team'))
      }

      if (color && name && team_id) return this.$refs.whatsappNewNumber.new();
      return this.missedFields = true
    },

    facebookValidation() {
      this.inValidation = true
      this.clearWabaFields()
      if (!document.getElementById('facebook-jssdk')) {
        this.setErrorMessage('Erro ao conectar com facebook!')
        return
      }

      window.FB.login((response) => {
        if (response.authResponse) {
          const code = response.authResponse.code;
          this.createWabaChannel(code)
        } else {
          console.log('User cancelled login or did not fully authorize.');
        }
        this.inValidation = false
      }, {
        config_id: process.env.VUE_APP_DIGFCAW,
        response_type: 'code',
        override_default_response_type: true,
        extras: {
          sessionInfoVersion: 2
        }
      });
    },

    createWabaChannel(code) {
      const formData = {
        name: this.fields.name,
        first_connection: true,
        type: "waba",
        group_channel_id: this.$route.params.id,
        phone_number_id: this.waba_fileds.phone_number_id,
        whatsapp_business_account_id: this.waba_fileds.waba_id,
        waba_code: code,
        team_id: this.fields.team_id,
      }

      this.createInstances({formData}).then(() => {
        this.findGroupChannelById({id: this.$route.params.id})
        this.clearWabaFields()
        this.newChannel = false
      })
    },

    clearWabaFields() {
      this.waba_fileds = {
        phone_number_id: null,
        waba_id: null
      }
    }
  },

  components: {
    ColorPickerDropdown,
    AppButton,
    whatsappNewNumber,
  }
}
</script>
<style lang="scss" scoped>
.sad-pluszinha {
  width: 95px;
  height: 70px;
}

.ball {
  background-color: rgb(94, 184, 99);
  width: 8px;
  display: inline-block;
  height: 8px;
  border-radius: 50%;
}

.tx-gray {
  color: $color-text-gray;
}

.button-more {
  :hover {
    color: white !important;
  }
}

.dropdown-item {
  color: $color-text-solid-black;
  width: auto;
  display: block;
  border-radius: 8px !important;
  padding-left: 0.75rem;
  cursor: pointer;

  &:hover {
    background-color: $color-gray-blue-300 !important;
    color: $color-blue-300 !important;
  }

  &.active {
    background-color: $color-blue-300 !important;
    color: $color-text-light !important;
  }
}

.disabled-div {
  pointer-events: none;
  opacity: 0.5;
}

.sdk-background {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1055;
  transition: opacity 0.15s linear;
  opacity: 0.5;
  background-color: rgba(68, 68, 68, 0.4) !important;
}
</style>
