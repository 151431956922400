<template>
  <div class="col-12 col-lg-10 col-xl-8">
    <main-loading v-if="loading?.findById" />
    <template v-if="register">
      <header-component
        :title="register.name"
        pretitle="FEEDBACK"
        redirect-link="/manager/feedbacks"
      />

      <div class="row">
        <div
          class="col-lg-12"
          v-if="!register.criteria.length"
        >
          <div
            class="alert alert-info fade show"
            role="alert"
          >
            <span class="fe fe-alert-octagon" />&nbsp; <strong>{{ $t('attention') }}</strong>
            {{ $t('no_feedback_added') }}
            <span
              style="text-decoration: underline; cursor: pointer"
              @click="openCriteriaModal()"
            >
              {{ $t('click_here_to_add') }}
            </span>
          </div>
        </div>

        <div class="col-lg-8">
          <div class="form-group mb-4">
            <label
              for="exampleInputEmail1"
              class="form-label required"
            >{{ $t('name_data') }}</label>
            <input
              :class="{
                'form-control': true,
                'is-invalid': errors.update.name,
              }"
              v-model="fields.name"
              placeholder="Nome"
            >
            <div class="invalid-feedback">
              {{ errors.update.name && errors.update.name[0] }}
            </div>
          </div>
        </div>

        <div class="col-lg-4">
          <div class="form-group mb-4">
            <label
              for="exampleInputEmail1"
              disabled
              class="form-label required"
            >{{ $t('max_rate') }}</label>
            <input
              type="number"
              v-model="fields.high_score"
              in="0"
              max="10"
              class="form-control"
              disabled
            >
          </div>
        </div>

        <div class="col-lg-12">
          <div class="card">
            <div class="card-header">
              <div class="row align-items-center">
                <div class="col">
                  <h4 class="card-header-title">
                    Feedbacks
                  </h4>
                </div>
                <div class="col-auto">
                  <button
                    class="btn-primary btn btn-sm px-5"
                    @click="openCriteriaModal()"
                  >
                    {{ $t('add_feedback') }}
                  </button>

                  <criteria-form ref="criteriaForm" />
                </div>
              </div>
            </div>

            <div class="card-body py-0 px-0">
              <list-criteria :criterials="register.criteria" />
            </div>
          </div>
        </div>

        <div class="col-lg-12">
          <app-button
            class="fw mb-3"
            @click="submit()"
            :loading="loading.update"
          >
            <span class="fe fe-save" /> {{ $t('save_general') }}
          </app-button>
        </div>

        <div
          class="col-lg-12"
          @click="remove_()"
        >
          <app-button
            class="fw mb-4"
            color="text-danger"
            :loading="loading.remove"
          >
            <span class="fe fe-trash" />
            {{ $t('exclude') }}
          </app-button>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

import AppButton from "@/components/app-button.vue";
import MainLoading from "@/components/main-loading.vue";
import ListCriteria from "./components/list-criteria.vue";
import CriteriaForm from "./components/form-criteria.vue";
import HeaderComponent from "@/components/header-component.vue";

export default {
  data() {
    return {
      fields: {
        name: "",
        high_score: 0,
      }
    };
  },

  mounted() {
    this.clearErrors("update");
    this.$store.dispatch("manager/criterion/findById", { id: this.$route.params.id });
  },

  computed: {
    ...mapGetters("manager/criterion", {
      register: "getRegister",
      loading: "getLoadingFlags",
      errors: "getErrorFlags",
    }),
  },

  watch: {
    register(val) {
      if (val) {
        this.fields.name = val.name;
        this.fields.high_score = val.high_score;
      }
    },
  },

  components: {
    AppButton,
    MainLoading,
    CriteriaForm,
    ListCriteria,
    HeaderComponent
  },

  methods: {
    ...mapActions("manager/criterion", ["update", "remove", "clearErrors"]),
    ...mapActions("system", ["showConfirmationMessage"]),

    updateCriteria(criteria) {
      alert(JSON.stringify(criteria));
    },

    openCriteriaModal() {
      this.$refs.criteriaForm.new();
    },

    submit() {
      this.update({
        id: this.register.id,
        fields: {
          name: this.fields.name,
        },
      })
        .then(() => {
          this.$router.push({
            path: `/manager/feedbacks`,
          });
        });
    },

    async remove_() {
      let response = await this.showConfirmationMessage({
        title: this.$t('delete_feedback_question'),
        text: this.$t('delete_feedback_confirmation'),
        type: "badWarning"
      });

      if (response.isConfirmed) {
        this.remove({
          id: this.register.id,
        })
          .then(() => {
            this.$router.push({
              path: `/manager/feedbacks`,
            });
          });
      }
    },
  },
};
</script>
