import axios from "axios";
import axiosOmni from "@/plugins/axios-omni";

export default {
  getList({ commit, getters }) {
    commit('startLoading', 'getList')
    commit('setList', []);

    return new Promise((resolve, reject) => {
      axios.get('/uras', { params: getters.getParams }).
        then((response) => {
          commit('setPagination', response.data.meta.pagination);
          resolve();
          commit('setList', response.data.data);
        }).catch((response) => {
          reject({ message: response.response.data.detail });
        }).finally(() => {
          commit('stopLoading', 'getList')
        });
    });
  },

  createIvr({ commit }, payload) {
    commit("startLoading", "createIvr")
    commit('clearErrors')
    const headers = { 'Content-Type': 'multipart/form-data' };

    return new Promise((resolve, reject) => {
      axios.post('/uras', payload.formData, { headers })
        .then(() => {
          resolve({ message: "URA Receptiva cadastrado com sucesso!" });
        })
        .catch((response) => {
          response.response.status == 422 && response.response.data.errors && commit('setErrors', response.response.data.errors);
          commit('system/setErrorMessage', response.response.data.detail, { root: true})
          reject();
        }).finally(() => {
        commit("stopLoading", "createIvr")
      });
    });
  },

  findById({ commit }, payload) {
    commit('startLoading', 'findById')
    commit('setRegister', null)
    return new Promise((resolve, reject) => {
      axios.get('/uras/' + payload.id).
        then((response) => {
          commit('setRegister', response.data.data);
          resolve();
        }).catch((response) => {
          reject({ message: response.response.data.detail });
        }).finally(() => {
          commit('stopLoading', 'findById')
        });
    });

  },

  update({ commit }, payload) {
    const headers = { 'Content-Type': 'multipart/form-data' };
    commit('startLoading', 'update')
    commit('clearErrors')
    return new Promise((resolve, reject) => {
      axios.post('/uras/' + payload.id, payload.formData, { headers })
        .then(() => {
          resolve({ message: "URA Receptiva modificado com sucesso!" });
        }).catch((response) => {
          commit('system/setErrorMessage', response.response.data.detail, { root: true})
          reject();
          response.response.status == 422 && response.response.data.errors && commit('setErrors', response.response.data.errors);

        }).finally(() => {
          commit('stopLoading', 'update')
        });
    });
  },

  remove({ commit }, payload) {
    commit('startLoading', 'remove')
    // commit('setRegister', null)
    return new Promise((resolve, reject) => {
      axios.delete('/uras/' + payload.id).
        then(() => {
          resolve({ message: "URA Receptiva removido com sucesso!" });
        }).catch((response) => {
          reject({ message: response.response.data.detail });
        }).finally(() => {
          commit('stopLoading', 'remove')
        });
    });
  },

  getDataSelect({ commit }) {
    commit('startLoading', 'getDataSelect')
    // commit('setRegister', null)
    return new Promise((resolve, reject) => {
      axios.get('/uras?per_page=1000').
        then((response) => {
          // alert(JSON.stringify(response));
          commit('setDataSelect', response.data.data);
          resolve();
          // resolve({ message: "URA Receptiva removido com sucesso!" });
        }).catch(() => {
          // alert(JSON.stringify(response));
          // reject({ message: response.response.data.detail });
          reject();
        }).finally(() => {
          commit('stopLoading', 'getDataSelect')
        });
    });
  },

  fetchGroupChannels({ commit }){
    return new Promise((resolve, reject) => {
      axiosOmni.get("/whatsapp/filters?include=group_channels")
        .then((response) => {
          commit("setGroupChannels", response.data.data.group_channels.data.active)
          resolve();
        })
        .catch((errors) => {
          commit("system/setErrorMessage", errors.response.data, { root: true})
          reject()
        })
    })
  },

  fetchQuickMessages({ commit }, groupChannelId){
    const params = {
      group_channel_id: groupChannelId,
      include: 'quick_messages'
    }

    return new Promise((resolve, reject) => {
      axiosOmni.get("/whatsapp/filters", {params})
        .then((response) => {
          commit("setQuickMessages", response.data.data.quick_messages.data)
          resolve();
        })
        .catch((errors) => {
          commit("system/setErrorMessage", errors.response.data, { root: true})
          reject()
        })
    })
  },

  fetchTemplates({ commit }, instanceId){
    const params = {
      instance_id: instanceId,
      include: 'waba_templates'
    }

    return new Promise((resolve, reject) => {
      axiosOmni.get("/whatsapp/filters", { params })
        .then((response) => {
          commit("setTemplates", response.data.data.waba_templates.data)
          resolve();
        })
        .catch((errors) => {
          commit("system/setErrorMessage", errors.response.data, { root: true})
          reject()
        })
    })
  }
}
