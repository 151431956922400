
<template>
  <nav
    style="overflow: visible !important;"
    class="
      navbar navbar-vertical navbar-vertical-sm
      fixed-start
      navbar-expand-md navbar-light
    "
    :style="sidebarStyle"
  >
    <div class="container-fluid">
      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#sidebarSmallCollapse"
        aria-controls="sidebarSmallCollapse"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon" />
      </button>

      <router-link
        class="navbar-brand"
        to="/manager"
      >
        <img
          src="@/assets/img/logo.svg"
          class="navbar-brand-img mx-auto"
        >
      </router-link>

      <div
        class="collapse navbar-collapse"
        id="sidebarSmallCollapse"
      >
        <hr class="navbar-divider d-none d-md-block mt-0 mb-3">
        <ul class="navbar-nav">
          <li
            class="nav-item dropend"
            v-for="(item, i) of menu.top"
            :key="i"
          >
            <template v-if="item.type === 'group' && user && item?.roles.includes(user.role.name)">
              <a
                :class="{
                  'nav-link': true,
                  active: isActive(item.children),
                }"
                :id="item.id"
                href="#"
                data-bs-toggle="dropdown"
                role="button"
                aria-haspopup="true"
                aria-expanded="true"
                title="Dashboards"
              >
                <i :class="item.icon" />
                <span class="d-md-none">{{ item.title }}</span>
              </a>
              <ul
                class="dropdown-menu"
                :aria-labelledby="item.id"
              >
                <li
                  v-for="(subitem, j) of item.children"
                  :key="j"
                >
                  <router-link
                    :to="subitem.path"
                    class="dropdown-item"
                  >
                    {{ subitem.title }}
                  </router-link>
                </li>
              </ul>
            </template>
            <template v-else-if="item.type === 'receptive-group' && user && item?.roles.includes(user.role.name)">
              <a
                :class="{
                  'nav-link': true,
                  active: isActive(item.children),
                }"
                :id="item.id"
                href="#"
                data-bs-toggle="dropdown"
                role="button"
                aria-haspopup="true"
                aria-expanded="true"
                title="Dashboards"
              >
                <i :class="item.icon" />
                <span class="d-md-none">{{ item.title }} </span>
              </a>
              <ul
                class="dropdown-menu"
                :aria-labelledby="item.id"
              >
                <li
                  v-for="(subitem, j) of item.children"
                  :key="j"
                >
                  <router-link
                    :to="subitem.path"
                    class="dropdown-item"
                  >
                    {{ subitem.title }}
                  </router-link>
                </li>
              </ul>
            </template>
            <template v-else-if="item.type === 'link' && user && item?.roles.includes(user.role.name)">
              <router-link
                class="nav-link"
                :to="item.path"
              >
                <i :class="item.icon" />
                <span class="d-md-none">Widgets</span>
              </router-link>
            </template>
            <template v-else-if="item.type === 'separator'">
              <hr
                class="navbar-divider mx-3"
                v-if="isManager"
              >
            </template>
          </li>
        </ul>
      </div>
      <div
        class="navbar-user d-none d-md-flex flex-column"
        id="sidebarSmallUser"
      >
        <div class="dropend">
          <button
            href="#"
            id="sidebarIconCopy"
            class="dropdown-toggle btn btn-logout p-0"
            role="button"
            data-bs-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <img
              src="@assets/img/log-out.svg"
              alt=""
              class="img-fluid img-logout m-2"
              style="width: 32px;"
            >
          </button>
          <div
            class="dropdown-menu ms-1 mt-2"
            aria-labelledby="sidebarIconCopy"
            @click="_logout"
          >
            <a
              href="#"
              class="dropdown-item"
            >{{ $t('logout') }}</a>
          </div>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import menu from "@dialer/manager/menu-config.js";
import {mapActions, mapGetters} from "vuex";

export default {
  /**
   */
  data() {
    return {
      menu: [],
    };
  },

  /**
   *
   */
  mounted() {
    this.menu = menu;
  },
  computed:{
    ...mapGetters("auth", {
      showPaymentModal:"showPaymentModal",
      user: "getUser",
      isManager: "isManager",
    }),
    sidebarStyle(){
      return {
        zIndex: this.showPaymentModal ? 1068 : 1000
      };
    }
  },

  /**
   *
   */
  methods: {
    /**
     *
     */
    ...mapActions("auth", ["logout"]),

    _logout() {
      this.logout().then(() => {
        this.$router.push({
          name: "login"
        });
      });
    },
    getActiveRouteName() {
      return this.$route.matched && this.$route.matched[1]
        ? this.$route.matched[1].name
        : "";
    },

    /**
     *
     */
    prepareFilters() {
      let filters = {};

      filters.start_date = this.requiredFilters.start_date + " 00:00:00";
      filters.end_date = this.requiredFilters.end_date + " 23:59:59";

      let temp = this.minimum_duration.split(":");
      temp = parseInt(temp[0]) * 60 + parseInt(temp[1]);
      if (temp > 0) filters.minimum_duration = temp;
      temp = this.maximum_duration.split(":");
      temp = parseInt(temp[0]) * 60 + parseInt(temp[1]);
      if (temp > 0) filters.maximum_duration_formated = temp;

      if (this.showAllFilters) {
        for (let key of Object.keys(this.advancedFilters)) {
          if (this.advancedFilters[key]) {
            filters[key] = this.advancedFilters[key];
          }
        }
      }

      if (this.transfer == "is_transfer") filters.is_transfer = true;
      else if (this.transfer == "is_transferred") filters.is_transferred = true;

      filters.include = "campaign_rel";
      filters.simple_paginate = true;
      this.setFilters(filters);
    },

    /**
     *
     */
    isActive(children) {
      for (let i in children)
        if (this.getActiveRouteName() == children[i].active_name) {
          let menu = document.querySelector('[class="dropdown-menu show"]');
          if (menu) menu.classList.remove("show");

          return true;
        }
      return false;
    },
  },
};
</script>
<style lang="scss" scoped>
.btn-logout{
  background-color: $color-gray-blue-300;
}
</style>
