export default {
  getLoadingFlags: state => state.loadingFlags,
  getActions: state => state.actions,
  getActionToEdit: state => state.actionToEdit,
  getPrevActionId: state => state.prevActionId,
  getOffcanvasEditActionIsOpen: state => state.offcanvasEditActionIsOpen,
  getOffcanvasNewActionIsOpen: state => state.offcanvasNewActionIsOpen,
  getGroupChannels : state => state.groupChannels,
  getUpdateCoordinates : state => state.updateCoordinates,
  getZoom: state => state.zoom
}
