import axios from "@crm/plugins/axios-config";
import axiosApplication from "axios";

export default {
  fetchUsersByTeam ({commit}, {teamId, search}) {
    commit('startLoading', 'fetchUsersByTeam');
    return new Promise((resolve, reject) => {
      axiosApplication.get(`/team/${teamId}/users?search=${search}`)
        .then((response) => {
          commit('setTeamUsers', response.data.data)
          resolve()
        })
        .catch(() => {
          reject()
        })
        .finally(() => {
          commit('stopLoading', 'fetchUsersByTeam');
        })
    })
  },

  fetchAgentsByTeam ({commit}, payload) {
    commit('startLoading', 'fetchAgents');
    if (payload.params && (!payload.params.page || payload.params.page === 1)) commit("clearTeamAgents")
    return new Promise((resolve, reject) => {
      axios.get(`/team/${ payload.team_id }/agents`, {
        params: payload.params
      })
        .then((response) => {
          commit('setTeamAgents', response.data.data)
          commit('setAgentsPagination', response.data.meta);
          resolve()
        })
        .catch(() => {
          reject()
        })
        .finally(() => {
          commit('stopLoading', 'fetchAgents');
        })
    })
  },

  //TODO: merge this action with action above
  fetchAgentsByTeamSearch ({commit}, payload) {
    commit('startLoading', 'fetchAgents');
    commit("clearTeamAgents")
    return new Promise((resolve, reject) => {
      axios.get(`/team/${ payload.team_id }/agents`, {params: payload})
        .then((response) => {
          commit('setTeamAgents', response.data.data)
          resolve()
        })
        .catch(() => {
          reject()
        })
        .finally(() => {
          commit('stopLoading', 'fetchAgents');
        })
    })
  },

  addPhoneChanel({dispatch, rootGetters, commit}, {contact_id, number}) {
    number = number.replace(/[^0-9\n]/g, '');
    return new Promise((resolve, reject) => {
      axios.post(`/phone_number_channel`, {contact_id, number})
        .then(() => {
          const opportunity = rootGetters["crm/opportunities/getOpportunity"];
            dispatch("crm/opportunities/fetchOpportunity", opportunity.id, {root: true})
            commit("crm/opportunities/setOpportunity", opportunity, {root: true});
            commit('system/setSuccessMessage', "success_message.add_phone", {root: true})
            resolve()
        })
        .catch(() => {
          commit('system/setErrorMessage', 'Erro ao adicionar telefone', {root: true})
          reject()
        })
    })
  },

  updatePhoneChanel({dispatch, rootGetters, commit}, {channel_id, contact_id, number}) {
    number = number.replace(/[^0-9\n]/g, '');
    try {
      axios.put(`/phone_number_channel/${ channel_id }`, {contact_id, number})
        .then(() => {
          const opportunity = rootGetters["crm/opportunities/getOpportunity"];
          dispatch("crm/opportunities/fetchOpportunityLogs", {opportunityId: opportunity.id, payload: {contacts_ids: [contact_id]}}, {root: true})
          commit('system/setSuccessMessage', "success_message.update_phone", {root: true})
        })
    } catch (e) {
      commit('system/setErrorMessage', 'Erro ao atualizar telefone', {root: true})
    }
  },

  destroyPhoneChanel({dispatch, rootGetters, commit}, {channel_id, contact_id}) {
    try {
      axios.delete(`/phone_number_channel/${channel_id}`, {params: {contact_id}})
        .then(() => {
          const opportunity = rootGetters["crm/opportunities/getOpportunity"];
          dispatch('updateContactChannels', {
            contact_id,
            channel_id,
            flag: 'phones',
            opportunity
          });
          dispatch("crm/opportunities/fetchOpportunityLogs", {opportunityId: opportunity.id, payload: {contacts_ids: [contact_id]}}, {root: true})
          commit('system/setSuccessMessage', "success_message.delete_phone", {root: true})
        })
    } catch (e) {
      commit('system/setErrorMessage', 'Erro ao excluir telefone', {root: true})
    }
  },

  addEmailChannel({dispatch, rootGetters, commit}, {contact_id, email}) {
    return new Promise((resolve, reject) => {
      axios.post(`/email_channel/`, {contact_id, email})
        .then(() => {
            const opportunity = rootGetters["crm/opportunities/getOpportunity"];
            dispatch("crm/opportunities/fetchOpportunity", opportunity.id, {root: true})
            commit("crm/opportunities/setOpportunity", opportunity, {root: true});
            commit('system/setSuccessMessage', "success_message.add_email", {root: true})
            resolve()
        })
        .catch(() => {
          commit('system/setErrorMessage', 'Erro ao adicionar email', {root: true})
          reject()
        })
    })
  },

  updateEmailChannel({commit, dispatch, rootGetters}, {channel_id, contact_id, email}) {
    try {
      axios.put(`/email_channel/${channel_id}`, {contact_id, email})
        .then(() => {
          const opportunity = rootGetters["crm/opportunities/getOpportunity"];
          dispatch("crm/opportunities/fetchOpportunityLogs", {opportunityId: opportunity.id, payload: {contacts_ids: [contact_id]}}, {root: true})
          commit('system/setSuccessMessage', "success_message.update_email", {root: true})
        })
    } catch (e) {
      commit('system/setErrorMessage', 'Erro atualizar email', {root: true})
    }
  },

  destroyEmailChannel({dispatch, rootGetters, commit}, {channel_id, contact_id}) {
    try {
      axios.delete(`/email_channel/${channel_id}`, {params: {contact_id}})
        .then(() => {
          const opportunity = rootGetters["crm/opportunities/getOpportunity"];
            dispatch('updateContactChannels', {
              contact_id,
              channel_id,
              flag: 'emails',
              opportunity
            });
            dispatch("crm/opportunities/fetchOpportunityLogs", {opportunityId: opportunity.id, payload: {contacts_ids: [contact_id]}}, {root: true})
            commit('system/setSuccessMessage', "success_message.delete_email", {root: true})
        }).catch((e) => {
        commit('system/setErrorMessage', 'Erro ao excluir email', {root: true})
        console.log(e);
      })
    } catch (e) {
      console.log(e);
    }
  },

  editFormInputs ({commit}, payload) {
    return new Promise((resolve, reject) => {
      axios.put(`/form_input/${ payload.id }`, payload, {params: {funnel_id: payload.funnel_id}})
        .then((response) => {
          commit('updateFormInputs', response.data.data)
          resolve()
        })
        .catch(() => {
          commit('system/setErrorMessage', 'Erro ao editar campos de formulário', {root: true})
          reject()
        })
    })
  },

  updateContact({commit}, {contactId, payload}){
      commit('startLoading', 'updateContact');
      return new Promise((resolve, reject) => {
          axios.put(`/contacts/${contactId}`, payload).then(() => {
              commit('system/setSuccessMessage', "success_message.update_name", {root: true})
              resolve()
          }).catch((response) => {
              commit('system/setErrorMessage', response.response.data, {root: true})
              reject();
          }).finally(()=>{
              commit('stopLoading', 'updateContact');
          })
      })
  },

  updateContactChannels ({rootGetters}, {contact_id, channel_id, flag}) {
    const opportunity = rootGetters["crm/opportunities/getOpportunity"];

    opportunity.contacts.forEach((contact) =>  {
      if(contact.id === contact_id) {
        const index = contact[flag].findIndex((item) => item.id === channel_id);
        contact[flag].splice(index, 1);
      }
    });
  }
}
