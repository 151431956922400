<template>
  <li
    class="list-group-item"
    style="padding: 0.75rem"
  >
    <div
      class="d-flex align-items-center justify-content-between cursor-pointer"
      data-bs-toggle="collapse"
      :data-bs-target="'#channel' + groupChannel.id"
      aria-expanded="false"
      aria-controls="channel"
    >
      <div class="d-flex align-items-center gap-2">
        <dot-status
          size="8px"
          :color="groupChannel.color"
        />
        <p class="m-0 tx-text-gray">
          {{ groupChannel.name }}
        </p>
      </div>
      <span
        class="far fa-chevron-down tx-blue-300"
        :class="open? 'chevron-open' : 'chevron-closed'"
      />
    </div>
    <ul
      class="collapse list-unstyled"
      :id="'channel' + groupChannel.id"
      ref="channel"
    >
      <item-channel
        v-for="channel in groupChannel.instances"
        :key="channel.id"
        :channel="channel"
        @click="$emit('selectChannel', channel)"
      />
    </ul>
  </li>
</template>

<script>
import DotStatus from "@components/dot-status.vue";
import ItemChannel from "@dialer/manager/pages/settings/ivrs/components/select-channel/item-channel.vue";

export default {
  name: "ItemGroupChannel",

  emits: ['selectChannel'],

  components: {ItemChannel, DotStatus},

  props: {
    groupChannel: {
      type: Object,
      required: true,
    }
  },

  data() {
    return {
      open: false,
    }
  },

  mounted() {
    this.$refs.channel.addEventListener('hide.bs.collapse', this.hideHandler)
    this.$refs.channel.addEventListener('show.bs.collapse', this.showHandler)
  },

  beforeUnmount() {
    this.$refs.channel.removeEventListener('hide.bs.collapse', this.hideHandler)
    this.$refs.channel.removeEventListener('show.bs.collapse', this.showHandler)
  },

  methods: {
    showHandler(e) {
      if (e.target.id === "channel" + this.groupChannel.id) {
        this.open = true
      }
    },

    hideHandler(e) {
      if (e.target.id === "channel" + this.groupChannel.id) {
        this.open = false
      }
    }
  },
}
</script>

<style scoped lang="scss">
.chevron-open {
  transform: rotate(180deg);
  transition: transform 0.3s ease;
}

.chevron-closed {
  transform: rotate(0deg);
  transition: transform 0.3s ease;
}
</style>