<template>
  <div
    class="d-flex align-items-center"
    style="height: 160px; min-width: 100px"
  >
    <app-button
      style="width: 166px"
      @click="openNewAction"
    >
      {{ $t('new_step') }}
    </app-button>
  </div>
</template>

<script>
import {mapMutations} from "vuex";
import AppButton from "@components/app-button.vue";

export default {
  name: "ButtonNewAction",

  components: {
    AppButton
  },

  props: {
    actionId: {
      type: Number,
      required: true,
    },
  },

  methods: {
    ...mapMutations("chatbot/actions", [
      "setOffcanvasNewActionIsOpen",
      "setPrevActionId"
    ]),
    ...mapMutations("auth", ["trackEvents"]),

    openNewAction() {
      this.setPrevActionId(this.actionId)
      this.setOffcanvasNewActionIsOpen(true)
      this.trackEvents("[Manager] Create New Chatbot Action")
    },
  }
};
</script>
