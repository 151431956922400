<template>
  <div class="card m-0">
    <div
      class="card-body d-flex align-items-center"
      style="padding: 1rem 0.5rem"
    >
      <div
        class="d-flex align-items-center justify-content-center"
        style="height: 24px; width: 24px; padding: 4px 5px; margin-right: 0.5rem"
      >
        <span class="fa-regular fa-text" />
      </div>
      <p class="m-0">
        {{ text }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "CardText",

  props: {
    text: {
      type: String,
      required: true,
    }
  }
}
</script>

