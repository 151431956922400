<template>
  <div
    class="offcanvas-body d-flex flex-column"
    style="gap: 1rem"
  >
    <action-component
      icon-bg="#E1E9F4"
      icon-class="fa-regular fa-microchip-ai"
      icon-color="#3057F2"
      :name="$t('sdr_ai_qualification')"
    />
    <div>
      <p class="m-0 fw-medium">
        {{ $t('generate_answers_until_client_write') }}
      </p>
      <p class="m-0 text-medium lh-1 tx-text-gray">
        {{ $t('ai_decides_the_question_moment') }}
      </p>
    </div>
    <div
      v-for="(question, index) in questions"
      :key="index"
      class="col-auto"
    >
      <div class="input-group">
        <input
          id="optionTitle"
          v-model="questions[index]"
          :class="{'is-invalid': hasErrors && !questions[index]}"
          :placeholder="$t('what_is_your_question')"
          class="form-control"
        >
        <span
          v-if="index > 0"
          class="input-group-text fe fe-x cursor-pointer"
          @click="questions.splice(index, 1)"
        />
      </div>
    </div>
    <button
      v-if="questions.length < 10"
      class="btn btn-sm btn-light align-self-center"
      style="width: 141px"
      @click="addNewQuestion"
    >
      + {{ $t('new_option') }}
    </button>
    <hr class="w-100">
    <div>
      <p class="fw-medium">
        {{ $t('after_conclude_questions') }}
      </p>
      <p class="m-0 fw-medium">
        {{ $t('answer') }}
      </p>
      <p class="m-0 text-medium lh-1 tx-text-gray">
        {{ $t('ai_will_write_text') }}
      </p>
    </div>
    <div>
      <input
        id="message"
        v-model="endTrigger"
        :class="{'is-invalid': hasErrors && !endTrigger}"
        class="form-control"
        :placeholder="$t('answer')"
        type="text"
      >
    </div>
    <div class="mt-auto" />
    <app-button
      :disabled="hasErrors && (Object.values(questions).some(el => !el) || !endTrigger)"
      class="col-auto"
      @click="editAction"
    >
      {{ $t('capitalizes.save') }}
    </app-button>
    <app-button
      class="col-auto"
      color="text-danger"
      @click="_deleteAction"
    >
      <span class="fa-regular fa-trash" />
      {{ $t('capitalizes.exclude') }}
    </app-button>
  </div>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from "vuex";

import ActionComponent from "@chatbot/pages/workflow/components/action-component.vue";
import AppButton from "@components/app-button.vue";

export default {
  name: "EditSdr",

  components: {
    AppButton,
    ActionComponent
  },

  data() {
    return {
      endTrigger: "",
      questions: [],
      hasErrors: false,
    }
  },

  mounted() {
    this.endTrigger = this.actionToEdit.sdr_end_trigger
    this.questions = this.actionToEdit.sdr_questions
  },

  watch: {
    actionToEdit(action) {
      if (action.type === "sdr") {
        this.endTrigger = action.sdr_end_trigger
        this.questions = action.sdr_questions
      }
    }
  },

  computed: {
    ...mapGetters("chatbot/actions", {
      actionToEdit: "getActionToEdit"
    })
  },

  methods: {
    ...mapActions("chatbot/chatbots", ["fetchChatbot"]),
    ...mapActions("chatbot/actions", [
      "updateAction",
      "deleteAction"
    ]),
    ...mapMutations("chatbot/actions", [
      "setOffcanvasEditActionIsOpen",
    ]),

    addNewQuestion() {
      if (this.questions.length == 10) {
        return
      }

      this.questions.push("")
    },

    editAction() {
      if (!this.endTrigger || Object.values(this.questions).some(el => !el)) {
        this.hasErrors = true
        return
      } else {
        this.hasErrors = false
      }

      this.updateAction({
        actionId: this.actionToEdit.id,
        payload: {
          end_trigger: this.endTrigger,
          questions: this.questions,
        }
      }).then(() => {
        this.setOffcanvasEditActionIsOpen(false)
      })
    },

    _deleteAction() {
      this.deleteAction(this.actionToEdit.id).then(() => {
        this.setOffcanvasEditActionIsOpen(false)
        this.fetchChatbot(this.$route.params.id).then(() => {
          this.changeUpdateCoordinates()
        })
      })
    }
  }
}
</script>
