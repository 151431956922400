<template>
  <div
    ref="dragContainer"
    class="container-drag"
    @mousedown.prevent="startDrag"
    @mousemove.prevent="drag"
    @mouseup.prevent="stopDrag"
    @mouseleave.prevent="stopDrag"
    @wheel.prevent="handleWheel"
  >
    <div class="d-flex container-assistant">
      <div
        :style="{ transform: `scale(${zoom})`, 'transform-origin': 'top left' }"
        class="d-flex align-items-center align-self-center container-actions"
      >
        <slot />
      </div>
    </div>
  </div>

  <div class="d-flex align-items-center justify-content-between container-footer">
    <div />
    <div
      class="d-flex align-items-center"
      style="gap: 16px"
    >
      <button
        class="btn btn-sm btn-white rounded-circle tx-text-gray"
        style="width: 30px; height: 30px"
        @click="resetZoom"
      >
        <span class="fa-regular fa-arrow-down-left-and-arrow-up-right-to-center" />
      </button>
      <div
        aria-label="Zoom"
        class="btn-group"
        role="group"
      >
        <button
          class="btn btn-sm btn-white tx-text-gray"
          @click="zoomIn"
        >
          <span class="fa-regular fa-plus" />
        </button>
        <button
          class="btn btn-sm btn-white tx-text-gray"
          @click="zoomOut"
        >
          <span class="fa-regular fa-minus" />
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import {mapMutations} from "vuex";

export default {
  name: "WorkflowContainer",

  data() {
    return {
      zoom: 1.0,

      isDragging: false,
      startX: 0,
      startY: 0,
      scrollLeft: 0,
      scrollTop: 0,
    }
  },

  methods: {
    ...mapMutations("chatbot/actions", ["setZoom"]),

    handleWheel(event) {
      if (event.deltaY > 0) {
        this.zoomOut();
      } else {
        this.zoomIn();
      }
    },

    resetZoom() {
      this.startX = 0;
      this.startY = 0;
      this.scrollTop = 0;
      this.scrollLeft = 0;
      this.$refs.dragContainer.style.transition = 'transform 0.3s ease-out';
      this.$refs.dragContainer.scrollLeft = 0;
      this.$refs.dragContainer.scrollTop = 0;

      const innerDiv = this.$refs.dragContainer.querySelector('.container-actions');
      const container = this.$refs.dragContainer;

      const requiredZoomX = container.clientWidth / innerDiv.offsetWidth;
      const requiredZoomY = container.clientHeight / innerDiv.offsetHeight;

      let requiredZoom = Number(Math.min(requiredZoomX, requiredZoomY).toFixed(1))

      requiredZoom = requiredZoom >= 0.5 ? requiredZoom : 0.5
      requiredZoom = requiredZoom <= 2.0 ? requiredZoom : 2.0

      this.zoom = requiredZoom;
      this.setZoom(this.zoom)

      setTimeout(() => {
        this.$refs.dragContainer.style.transition = '';
      }, 300);
    },
    zoomIn() {
      if (this.zoom >= 2.0) return
      this.zoom += 0.1;
      this.setZoom(this.zoom)
    },
    zoomOut() {
      if (this.zoom <= 0.5) return
      this.zoom -= 0.1;
      this.setZoom(this.zoom)
    },

    startDrag(event) {
      this.isDragging = true;
      this.startX = event.pageX - this.$refs.dragContainer.offsetLeft;
      this.startY = event.pageY - this.$refs.dragContainer.offsetTop;
    },
    drag(event) {
      if (!this.isDragging) return;

      const x = event.pageX - this.$refs.dragContainer.offsetLeft;
      const y = event.pageY - this.$refs.dragContainer.offsetTop;

      const deltaX = x - this.startX;
      const deltaY = y - this.startY;

      this.$refs.dragContainer.scrollLeft = this.scrollLeft - deltaX;
      this.$refs.dragContainer.scrollTop = this.scrollTop - deltaY;
    },
    stopDrag() {
      this.isDragging = false;
      this.scrollLeft = this.$refs.dragContainer.scrollLeft;
      this.scrollTop = this.$refs.dragContainer.scrollTop;
    },
  }
}
</script>

<style scoped>
.container-footer {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 24px 36px !important;
}

.container-drag {
  height: 100%;
  overflow: hidden;
  cursor: grab;
}

.container-assistant {
  min-height: 100%;
  padding-top: 124px
}

.container-actions {
  min-height: 100%;
  transition: transform 0.3s ease-out;
  padding-bottom: 80px;
  gap: 16px;
}
</style>
