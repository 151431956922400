import axiosOmni from "@/plugins/axios-omni.js";

export default {
  findChat({ commit, getters }, chatId) {
    commit("startLoading", "findChat");
    return new Promise((resolve, reject) => {
      axiosOmni
        .get("/whatsapp/chats/" + chatId)
        .then(response => {
          const currentChat = getters.getCurrentChat;
          let chat = response.data.data;
          
          if (!currentChat[chat.id]) commit("setCurrentChat", chat);
          commit("setOpenChat", chat);

          resolve(chat);
        })
        .catch(error => {
          if (error.response.status != 404)
            commit("system/setErrorMessage", error.response.data, {
              root: true
            });
          reject(error.response.status);
        })
        .finally(() => {
          commit("stopLoading", "findChat");
        });
    });
  },

  preLoadMessages({ commit, getters }, payload) {
    commit("incrementPendingPreLoadMessages");
    commit("startChatLoading", "preLoadMessages");
    commit("startLoading", "findMessages");
    commit("cleanMessagesDataById", payload.id);
    return new Promise((resolve, reject) => {
      axiosOmni.get(`/whatsapp/chats/${ payload.id }/messages`, {
        params: {
          per_page: 150,
          page: 1,
          chat_id: payload.id,
            instance_id: payload.instance_id
        }
      })
        .then(response => {
          let pagination = response.data.meta.pagination;
          commit("setMessages", response.data.data);
          commit("setMessagesPagination", { pagination, payload, context: "current" });
          resolve();
        }).catch(response => {
          commit("system/setErrorMessage", response.data, { root: true });
          reject();
        }).finally(() => {
          commit("decrementPendingPreLoadMessages");
          if (getters.getPendingPreLoadMessages <= 0) {
            commit("stopChatLoading", "preLoadMessages");
            commit("stopLoading", "findMessages");
          }
        });
    });
  },

  sendForward({ commit }, payload) {
    commit("startLoading", "sendForward");
    return new Promise((resolve, reject) => {
      axiosOmni
        .post("/whatsapp/message/forward_message", payload)
        .then(response => {
          commit("system/setSuccessMessage", response.data.detail, { root: true });
          resolve();
        })
        .catch(response => {
          commit("stopLoading", "sendForward");
          commit("system/setErrorMessage", response.response.data, { root: true });
          reject();
        })
        .finally(() => {
          commit("stopLoading", "sendForward");
        });
    });
  },

  findOlderMessages({ commit }, payload) {
    commit("startLoading", "findOlderMessages");
    return new Promise((resolve, reject) => {
      axiosOmni.get(`/whatsapp/message/${payload.id}`, {
        params: {
          per_page: 150,
          page: payload.page,
        }
      })
        .then(response => {
          commit("setStashedMessages", { payload: payload, messages: response.data.data });
          let pagination = response.data.meta.pagination;
          commit("setMessagesPagination", { pagination, payload, context: "current" });
          commit("setAllowNewMessages", { payload: payload, allowNewMessages: true });
          resolve();
        })
        .catch(response => {
          commit("system/setErrorMessage", response.response.data, { root: true });
          reject();
        })
        .finally(() => {
          commit("stopLoading", "findOlderMessages");
        });
    });
  },

  findMessagesHistoricByNumber({ commit, getters }, payload) {
    commit("startLoading", "findMessagesHistoricByNumber");
    return new Promise((resolve, reject) => {
      axiosOmni
        .get("/whatsapp/message/" + payload.number + `/history`, {
          params: { per_page: 150, page: payload.page }
        })
        .then(response => {
          let pagination = response.data.meta.pagination;
          commit("setMessagesPagination", { pagination, payload, context: "historic" });
          let incompleteMessagesPage = getters.getCurrentChat[payload.chat_id];
          let historyMessagesPage = response.data.data;
          if (incompleteMessagesPage.length) {
            commit("setIncompleteMessagesPage", { payload: payload, incompleteMessagesPage: [] });
          }
          commit("setStashedMessages", { payload: payload, messages: historyMessagesPage });
          commit("setAllowNewMessages", { payload: payload, allowNewMessages: true });
          resolve();
        })
        .catch(response => {
          if (response.status !== 404) commit("system/setErrorMessage", response.data, { root: true });
          reject();
        })
        .finally(() => {
          commit("stopLoading", "findMessagesHistoricByNumber");
        });
    });
  },

  addMessagesToChat({ commit }, payload) {
    commit("addOlderMessages", payload);
  },

  /**
   * Find list of chat that are in progres
   *
   * @param {*} param0
   * @returns
   */
    findInProgress({ commit, dispatch, getters }) {
      commit('startLoading', 'findInProgress');
      commit('startChatLoading', 'findInProgress');
      return new Promise((resolve, reject) => {
        axiosOmni.get('/whatsapp/chats/in_progress', { params: getters.getInProgressParams })
          .then(response => {
            if (response.data.data.chats) {
              for (const item of response.data.data.chats.data) {
                commit('setCurrentChat', item)
              }
            }
            for (const item of Object.values(response.data.data.chats.data)) {
              let payload = {id: item.id, instance_id: item.instance_id}
              dispatch('preLoadMessages', payload)
            }
            commit("addInProgress", response.data.data.chats.data);
            commit("setInProgressPagination", response.data.data.chats.meta.pagination);
            commit("setUnreadConversations", response.data.data.unread_conversations);
            commit("setHasNotifiedInProgress", response.data.data.has_notified_chats);
            resolve();
          }).catch((response) => {
            commit('system/setErrorMessage', response.response.data, { root: true })
            reject();
          }).finally(() => {
            commit('stopLoading', 'findInProgress');
            commit('stopChatLoading', 'findInProgress');
          });
      })
    },

  /**
   * find list of chat in queue
   *
   * @param {*} param0
   * @returns
   */
  findQueue({ commit, dispatch, getters }) {
    commit("startLoading", "findQueues");
    commit("startChatLoading", "findQueues");
    return new Promise((resolve, reject) => {
      axiosOmni
        .get("/whatsapp/chats/queue", { params: getters.getQueueParams })
        .then(response => {
          if (response.data.data.chats.data.length) {
            for (const item of response.data.data.chats.data) {
              commit("setCurrentChat", item);
            }
          }
          for (const item of Object.values(response.data.data.chats.data)) {
              let payload = {id: item.id, instance_id: item.instance_id}
            dispatch('preLoadMessages', payload)
          }
          commit("addQueue", response.data.data.chats.data);
          commit("setQueuePagination", response.data.data.chats.meta.pagination);
          commit("setHasNotifiedQueue", response.data.data.has_notified_chats);
          resolve();
        })
        .catch(response => {
          commit("system/setErrorMessage", response.response.data, { root: true });
          reject();
        })
        .finally(() => {
          commit("stopLoading", "findQueues");
          commit("stopChatLoading", "findQueues");
        });
    });
  },

  // Snooze
  /**
   * @param {*} param0
   * @returns
   */
  findInSnooze({ commit, dispatch, getters }) {
    commit("startLoading", "findInSnooze");
    commit("startChatLoading", "findInSnooze");
    return new Promise((resolve, reject) => {
      axiosOmni
        .get("/whatsapp/chats/in_snooze", { params: getters.getInSnoozeParams })
        .then(response => {
          if (response.data.data.length)
            for (const item of response.data.data) {
              commit("setCurrentChat", item);
            }
          for (const item of Object.values(response.data.data)) {
            let payload = { id: item.id, instance_id: item.instance_id };
            dispatch("preLoadMessages", payload);
          }
          commit("addInSnooze", response.data.data);
          commit("setInSnoozePagination", response.data.meta.pagination);
          resolve();
        })
        .catch(response => {
          commit("system/setErrorMessage", response.response.data, { root: true });
          reject();
        })
        .finally(() => {
          commit("stopLoading", "findInSnooze");
          commit("stopChatLoading", "findInSnooze");
        });
    });
  },

  // Groups
  /**
   * @param {*} param0
   * @returns
   */
  findGroups({ commit, dispatch, getters }) {
    commit("startLoading", "findGroups");
    commit("startChatLoading", "findGroups");
    return new Promise((resolve, reject) => {
      axiosOmni
        .get(`/whatsapp/chats/groups`, { params: getters.getGroupsParams })
        .then(response => {
          if (response.data.data.groups) {
            for (const item of response.data.data.groups.data) {
              commit("setCurrentChat", item);
            }
          }
          for (const item of Object.values(response.data.data.groups.data)) {
            let payload = { id: item.id, instance_id: item.instance_id };
            dispatch("preLoadMessages", payload);
          }
          commit("addGroups", response.data.data.groups.data);
          commit("setGroupsPagination", response.data.data.groups.meta.pagination);
          commit("setUnreadGroupsConversations", response.data.data.unread_groups_conversations);
          resolve();
        })
        .catch(response => {
          commit("system/setErrorMessage", response.response.data, { root: true });
          reject();
        })
        .finally(() => {
          commit("stopLoading", "findGroups");
          commit("stopChatLoading", "findGroups");
        });
    });
  },

  // Finished
  /**
   * @param {*} param0
   * @returns
   */
  findFinished({ commit, dispatch, getters }) {
    commit("startChatLoading", "findFinished");
    commit("startLoading", "findFinished");
    return new Promise((resolve, reject) => {
      axiosOmni
        .get("/whatsapp/chats/finished/all", { params: getters.getFinishedParams })
        .then(response => {
          if (response.data.data.length) {
            for (const item of response.data.data) {
              commit("setCurrentChat", item);
            }
          }
          for (const item of Object.values(response.data.data)) {
            let payload = { id: item.id, instance_id: item.instance_id };
            dispatch("preLoadMessages", payload);
          }
          commit("addFinished", response.data.data);
          commit("setFinishedPagination", response.data.meta.pagination);
          resolve();
        })
        .catch(response => {
          commit("system/setErrorMessage", response.data, { root: true });
          reject();
        })
        .finally(() => {
          commit("stopChatLoading", "findFinished");
          commit("stopLoading", "findFinished");
        });
    });
  },

  findInChatbot({ commit, dispatch, getters }) {
    commit("startChatLoading", "findInChatbot");
    commit("startLoading", "findInChatbot");
    return new Promise((resolve, reject) => {
      axiosOmni
        .get("/whatsapp/chats/in_chatbot", { params: getters.getInChatbotParams })
        .then(response => {
          if (response.data.data.length) {
            for (const item of response.data.data) {
              commit("setCurrentChat", item);
            }
          }
          for (const item of Object.values(response.data.data)) {
            let payload = { id: item.id, instance_id: item.instance_id };
            dispatch("preLoadMessages", payload);
          }
          commit("addInChatbot", response.data.data);
          commit("setInChatbotPagination", response.data.meta.pagination);
          resolve();
        })
        .catch(response => {
          commit("system/setErrorMessage", response.data, { root: true });
          reject();
        })
        .finally(() => {
          commit("stopChatLoading", "findInChatbot");
          commit("stopLoading", "findInChatbot");
        });
    });
  },

  findGroupParticipants({ commit }, payload) {
    commit("startLoading", "findGroupParticipants");
    commit("setGroupParticipants", {});
    return new Promise((resolve, reject) => {
      axiosOmni
        .get(`/whatsapp/chats/group/participants`, { params: payload.fields })
        .then(response => {
          commit("setGroupParticipants", response.data.data);
          resolve();
        })
        .catch(response => {
          commit("system/setErrorMessage", response.response.data, { root: true });
          reject();
        })
        .finally(() => {
          commit("stopLoading", "findGroupParticipants");
        });
    });
  },

  findGroupAgents({ commit, getters }, payload) {
    commit("startLoading", "findGroupAgents");
    commit("setGroupAgents", {});
    return new Promise((resolve, reject) => {
      axiosOmni.get(`/whatsapp/chats/group/${payload.id}/agents`, { params: getters.getGroupAgentsParams })
        .then(response => {
          commit('setGroupAgents', response.data.data.agents);
          resolve();
        })
        .catch(response => {
          commit("system/setErrorMessage", response.response.data, { root: true });
          reject();
        })
        .finally(() => {
          commit("stopLoading", "findGroupAgents");
        });
    });
  },

  deleteGroup({ commit, dispatch}, payload){
    commit("startLoading", "deleteGroup");
    return new Promise((resolve, reject) => {
      axiosOmni
        .delete(`/whatsapp/chats/group/${payload.chatId}?team_id=${payload.teamId}`)
        .then((response) => {
          commit("system/setSuccessMessage", 'success_message.delete_group_omni', { root: true });
          commit("clearGroups")
          commit("setGroupsPagination", {
            per_page: 25,
            current_page: 1
          })
          dispatch("findGroups");
          resolve(response.data);
        })
        .catch(response => {
          commit("system/setErrorMessage", response.response.data, { root: true });
          reject();
        })
        .finally(() => {
          commit("stopLoading", "deleteGroup");
        })
    });
  },

  addResponsibleGroup({ commit }, payload) {
    commit("setErrors", {});
    commit("startLoading", "addResponsibleGroup");
    return new Promise((resolve, reject) => {
      axiosOmni
        .post(`/whatsapp/chats/group/${payload.chat.id}/responsible`, payload.agents)
        .then(() => {
          resolve();
        }).catch((response) => {
          commit('system/setErrorMessage', response.response.data, { root: true })
          reject();
        })
        .finally(() => {
          commit("stopLoading", "addResponsibleGroup");
        });
    });
  },

  /**
   *
   * @param {*} param0
   * @param {*} payload
   * @returns
   */

  createNewChat({ commit, dispatch }, payload) {
    commit("setErrors", {});
    commit("startLoading", "createNewChat");
    return new Promise((resolve, reject) => {
      axiosOmni.post('/whatsapp/chats/open_new_chat', payload.fields)
        .then((response) => {
          let payload = {id: response.data.data.id, instance_id: response.data.data.instance_id}
          commit('setCurrentChat', response.data.data)
          dispatch('preLoadMessages', payload)
          commit('setOpenChat', response.data.data)
          commit("setNewChatInfo", response.data.data);
          commit("setFinalizedByManager", false);
          commit("updateInProgressTalkOrder", response.data.data);
          resolve(response.data.data.id);
        })
        .catch(error => {
          commit("system/setErrorMessage", error.response.data, { root: true });
          reject();
        })
        .finally(() => {
          commit("stopLoading", "createNewChat");
        });
    });
  },

  /**
   * Clear unreaded messages from chat
   *
   * @param {*} param0
   * @param {*} payload
   * @returns
   */
  clearUnreadMessageCount({ commit }, payload) {
    commit("startLoading", "clearUnreadMessageCount");
    return new Promise((resolve, reject) => {
      axiosOmni
        .put("/whatsapp/chats/" + payload.id + "/unread")
        .then(() => {
          commit("updateTalksInfo", {
            chat_id: payload.id,
            unread: 0
          });
          commit("updateGroupsTalksInfo", {
            chat_id: payload.id,
            unread: 0
          });
          resolve();
        })
        .catch(() => {
          reject();
        })
        .finally(() => {
          commit("stopLoading", "clearUnreadMessageCount");
        });
    });
  },

  /**
   *  Put the chat in snooze mode based on a date provided
   *  in the request. The date format must be YYYY-MM-DD HH:MM:SS
   *
   * @param {Object} param0
   * @returns
   */
  putOnSnooze({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axiosOmni
        .post(`/whatsapp/chats/${payload.chat_id}/snooze`, payload.fields)
        .then(response => {
          commit("system/setSuccessMessage", response.data.detail, { root: true });
          commit("setChatAsInSnooze", {
            chat_id: payload.chat_id,
            end_snooze: payload.fields.end_snooze
          });
          resolve();
        })
        .catch(() => {
          commit("system/setErrorMessage", "errors_message.put_on_snooze", { root: true });
          reject();
        });
    });
  },

  /**
   * accept chat from queue
   *
   * @param {*} param0
   * @param {*} payload
   * @returns
   */
  acceptQueue({ commit }, payload) {
    commit("startLoading", "acceptQueue");
    return new Promise((resolve, reject) => {
      axiosOmni
        .post("/whatsapp/chats/accept_queue/" + payload.id)
        .then(() => {
          resolve();
        })
        .catch(response => {
          commit("system/setErrorMessage", response.response.data, { root: true });
          reject();
        })
        .finally(() => {
          commit("stopLoading", "acceptQueue");
        });
    });
  },

  restartChatFinalized({ commit }, payload) {
    commit("startLoading", "acceptQueue");
    return new Promise((resolve, reject) => {
      axiosOmni
        .post("/whatsapp/chats/open_new_chat", payload)
        .then(() => {
          resolve();
        })
        .catch(() => {
          reject();
        })
        .finally(() => {
          commit("stopLoading", "acceptQueue");
        });
    });
  },

  /**
   *  transfer chat
   *
   * @param {*} param0
   * @param {*} payload
   * @returns
   */
  transferChat({ commit }, { id, fields }) {
    commit("startLoading", "transferChat");
    commit("setErrors", {});
    return new Promise((resolve, reject) => {
      axiosOmni
        .post(`/whatsapp/chats/${id}/transfer`, fields)
        .then(() => {
           commit("transferChatToQueue", { id: id });

          resolve();
        })
        .catch(response => {
          commit("system/setErrorMessage", response.response.data, { root: true });
          response.response.status == 422 &&
            response.response.data.errors &&
            commit("setErrors", response.response.data.errors);
          reject();
        })
        .finally(() => {
          commit("stopLoading", "transferChat");
        });
    });
  },

  /**
   * finish chat
   *
   * @param {*} param0
   * @param {*} payload
   * @returns
   */
  finishChat({ commit }, payload) {
    commit("clearErrors");
    commit("startLoading", "finishChat");
    return new Promise((resolve, reject) => {
      axiosOmni
        .post("/whatsapp/chats/" + payload.id + "/finish", payload.fields)
        .then(() => {
          commit("clearFinished");
          resolve();
        })
        .catch(response => {
          commit("system/setErrorMessage", response.response.data, { root: true });
          response.response.status == 422 &&
            response.response.data.errors &&
            commit("setErrors", response.response.data.errors);
          reject();
        })
        .finally(() => {
          commit("stopLoading", "finishChat");
        });
    });
  },

  checkContacts({ commit }, payload) {
    commit("clearErrors");
    commit("startLoading", "checkContacts");
    return new Promise((resolve, reject) => {
      axiosOmni
        .post("/whatsapp/contacts/check_contacts", payload.fields)
        .then(response => {
          commit("setCheckedContacts", response.data.data);
          resolve(response.data.data);
        })
        .catch(error => {
          commit("system/setErrorMessage", error.response.data, { root: true });
          error.response.status == 422 && error.response.data.errors && commit("setErrors", error.response.data.errors);
          reject();
        })
        .finally(() => {
          commit("stopLoading", "checkContacts");
        });
    });
  },

  fetchVerifyNotifiedChats({ commit }, list) {
    commit("startLoading", "fetchVerifyNotifiedChats");
    return new Promise((resolve, reject) => {
      axiosOmni.get("/whatsapp/chats/verify-notified-chats", { params: { list: list } })
        .then(response => {
          if (list == "in_progress") {
            commit("setHasNotifiedInProgress", response.data.has_notified_chats);
          } else if (list == "queue") {
            commit("setHasNotifiedQueue", response.data.has_notified_chats);
          }
          resolve();
        })
        .catch(error => {
          commit('system/setErrorMessage', error.response.data, { root: true });
          error.response.status == 422 && error.response.data.errors && commit('setErrors', error.response.data.errors);
          reject();
        })
        .finally(() => {
          commit("stopLoading", "fetchVerifyNotifiedChats");
        });
    });
  },

  incrementInProgressCurrentPage({ commit }) {
    commit("incrementInProgressCurrentPage");
  },
  incrementGroupsCurrentPage({ commit }) {
    commit("incrementGroupsCurrentPage");
  },
  incrementQueueCurrentPage({ commit }) {
    commit("incrementQueueCurrentPage");
  },
  incrementInChatbotCurrentPage({ commit }) {
    commit("incrementInChatbotCurrentPage");
  },
  incrementInSnoozeCurrentPage({ commit }) {
    commit("incrementInSnoozeCurrentPage");
  },
  incrementFinishedCurrentPage({ commit }) {
    commit("incrementFinishedCurrentPage");
  },

  resetInProgressCurrentPage({ commit }) {
    commit("setInProgressCurrentPage", 1);
  },
  resetFinishedCurrentPage({ commit }) {
    commit("setFinishedCurrentPage", 1);
  },

  resetChatsPagination({ commit }) {
    commit("setInProgressCurrentPage", 1);
    commit("setGroupsCurrentPage", 1);
    commit("setQueueCurrentPage", 1);
    commit("setInChatbotCurrentPage", 1);
    commit("setInSnoozeCurrentPage", 1);
    commit("setFinishedCurrentPage", 1);
  },

  clearChats({ commit }) {
    commit("clearInProgress");
    commit("clearGroups");
    commit("clearQueue");
    commit("clearInChatbot");
    commit("clearInSnooze");
    commit("clearFinished");
  },

  addFilters({ commit }, payload) {
    commit("setFilters", payload);
  },

  updateTalksTab({ commit }, tab) {
    commit("setTalksTab", tab);
  },

  searchMessages({ state, getters, commit }, payload) {
    commit("startLoading", "searchMessages");
    if (payload.page > getters.getSearchedMessagesPagination.last_page) return;
    axiosOmni
      .post("/whatsapp/message/search", payload)
      .then(response => {
        const messagesData = response.data;
        const pagination = response.data.meta.pagination;
        if (state.lastTermSearchedMessage && state.lastTermSearchedMessage !== payload.search)
          commit("clearSetSearchedMessages");
        if (messagesData.meta.pagination.current_page === 1)
          commit("setSearchedMessages", { messages: messagesData.data });
        else commit("setSearchedMessagesNextPage", { messages: messagesData.data });
        commit("setLastTermSearchedMessage", payload.search);
        commit("setSearchedMessagesPagination", pagination);
      })
      .catch(error => {
        commit("system/setErrorMessage", error.response?.data.detail || "errors_message.search_messages", { root: true });
      })
      .finally(() => {
        commit("stopLoading", "searchMessages");
      });
  },

  findAllProtocols({ commit }, payload) {
    commit("startLoading", "findAllProtocols");
    commit("setAllProtocols", {});
    return new Promise((resolve, reject) => {
      axiosOmni
        .get(`/whatsapp/chats/${payload}/protocols`)
        .then(response => {
          commit("setAllProtocols", response.data.data);
          resolve();
        })
        .catch(response => {
          commit("system/setErrorMessage", response.response.data, { root: true });
          reject();
        })
        .finally(() => {
          commit("stopLoading", "findAllProtocols");
        });
    });
  },

  findProtocol({ commit }, payload) {
    commit("startLoading", "findProtocol");
    commit("setProtocol", {});
    return new Promise((resolve, reject) => {
      axiosOmni
        .post(`/whatsapp/message/search`, {
          search: payload.protocol_number,
          chat_id: payload.chat_id,
          per_page: 150
        })
        .then(response => {
          commit("setProtocolFound", null);
          if (response.data.data.length === 2) {
            commit("setProtocolFound", response.data.data[1]);
          } else if (response.data.data.length === 0) {
            commit("system/setErrorMessage", "errors_message.find_protocol", { root: true });
          } else {
            commit("setProtocolFound", response.data.data[0]);
          }
          resolve();
        })
        .catch(response => {
          commit("system/setErrorMessage", response.response.data, { root: true });
          reject();
        })
        .finally(() => {
          commit("stopLoading", "findProtocol");
        });
    });
  },

  searchOldRecords({ commit }, payload) {
    commit("startLoading", "searchOldRecords");
    return new Promise((resolve, reject) => {
      axiosOmni.get(`/whatsapp/message/${payload.chat_id}`, { params:
          {
            per_page: 150,
            page: payload.page
          }
        })
        .then(response => {
          commit("setStashedMessages", { payload: payload, messages: response.data.data });
          let pagination = response.data.meta.pagination;
          commit("setMessagesPagination", { pagination, payload, context: "current" });
          commit("setAllowNewMessages", { payload: payload, allowNewMessages: true });
          resolve();
        })
        .catch(response => {
          commit("system/setErrorMessage", response.response.data, { root: true });
          reject();
        })
        .finally(() => {
          commit("stopLoading", "searchOldRecords");
        });
    });
  },

  findProtocolHistoric({ commit, getters }, payload) {
    commit("startLoading", "findProtocolHistoric");
    return new Promise((resolve, reject) => {
      axiosOmni
        .get("/whatsapp/message/" + payload.number + `/history?instance_id=${payload.instance_id}`, {
          params: { per_page: 150, page: payload.page }
        })
        .then(response => {
          let pagination = response.data.meta.pagination;
          commit("setMessagesPagination", {
            pagination,
            payload,
            context: "historic"
          });
          let incompleteMessagesPage = getters.getCurrentChat[payload.chat_id];
          let historyMessagesPage = response.data.data;
          if (incompleteMessagesPage.length) {
            commit("setIncompleteMessagesPage", { payload: payload, incompleteMessagesPage: [] });
          }
          commit("setStashedMessages", { payload: payload, messages: historyMessagesPage });
          commit("setAllowNewMessages", { payload: payload, allowNewMessages: true });
          resolve();
        })
        .catch(response => {
          if (response.status != 404) commit("system/setErrorMessage", response.data, { root: true });
          reject();
        })
        .finally(() => {
          commit("stopLoading", "findProtocolHistoric");
        });
    });
  }
};
