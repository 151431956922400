export default {
  errors: {},

  //params
  filters: {
    search: "",
    group_channel_id: null,
    team_id: null,
    agent_id: null,
    tags_ids: [],
  },

  include: [],
  pagination: {
    per_page: 25,
    current_page: 1
  },

  currentChat: {},
  //loading
  loadingFlags: {
    findInProgress: false,
    findQueues: false,
    findChat: false,
    createNewChat: false,
    putOnSnooze: false,
    acceptQueue: false,
    findGroups: false,
    findInSnooze: false,
    findFinished: false,
    findInChatbot: false,
    sendForward: false,
    findMessages: false,
    findOlderMessages: false,
    findMessagesHistoricByNumber: false,
    findGroupAgents: false,
    deleteGroup: false,
    addResponsibleGroup: false,
    fetchVerifyNotifiedChats: false,
    findProtocol: false,
    findAnchor: false,
  },

  loadingChats: {
    preLoadMessages: false,
    findInProgress: null,
    findQueues: null,
    findGroups: null,
    findInSnooze: null,
    findFinished: null,
    findInChatbot: null,
  },

  snoozeNote: "",

  chatChannelTypeSelected: {
    type: 'ultramsg'
  },

  // Queue
  queue: {},
  queuePagination: {
    per_page: 25,
    current_page: 1
  },

  // In Progress
  inProgress: {},
  inProgressPagination: {
    per_page: 25,
    current_page: 1
  },

  unreadConversations: 0,
  unreadGroupsConversations: 0,
  hasNotifiedInProgress: false,
  hasNotifiedQueue: false,

  // In Snooze
  inSnooze: {},
  inSnoozePagination: {
    per_page: 25,
    current_page: 1
  },
  // Groups
  groups: {},
  groupsPagination: {
    per_page: 25,
    current_page: 1
  },

  finished: {},
  finishedPagination: {
    per_page: 25,
    current_page: 1
  },

  inChatbot: {},
  inChatbotPagination: {
    per_page: 25,
    current_page: 1
  },

  pendingPreLoadMessages: 0,
  groupParticipants: {},
  groupAgents: {},
  transferedByManager: false,
  snoozedByManager: false,
  finalizedByManager: false,
  openChat: null,
  removedFromTheGroup: false,
  emptyChatContact: null,
  lastVisitedWhatsAppRoute: null,
  talksTab: "my", // groups // queue // inChatbot // snooze // finished
  allTalksLoaded: false,
  openTransferForm: false,

  clientResponseLate: {},
  clientResponseLateQueue: {},

  removeUnanswerMark: {},

  searchedMessages: [],
  searchedMessagesPagination: {
    total: 0,
    total_pages: 1,
    current_page: 1,
  },
  lastTermSearchedMessage: null,
  messageFound: null,
  protocolFound: null,
  loadMessagesContext: 'historic',
}
