<template>
  <div
    class="whatsapp-index"
    v-if="user && (isSupervisorOrManager || hasAccessToWhatsapp)"
  >
    <manager-side-bar v-if="isSupervisorOrManager" />
    <div
      :class="{
        'main-content': true,
        'has-sidebar': isAgent
      }"
    >
      <div
        class="fade-in"
        :class="{ 'fade-out': enableFadeOut }"
        v-show="!allTalksLoaded"
      >
        <loading-page-whatsapp />
      </div>
      <div
        v-show="allTalksLoaded"
        class="row m-0 p-0"
        :class="{ 'sidebar-margin': isAgent }"
      >
        <div
          class="col-md-4 col-lg-3 p-0 m-0 d-flex flex-column whatsapp-talks"
          data-test="whatsappChats-div"
        >
          <offcanvas-new-chat ref="newChatModal" />
          <whatsapp-talks @open-new-chat="openNewChatModal()" />
        </div>
        <div class="background-chat col-md-8 col-lg-9 p-0 m-0">
          <div
            :class="{
              'agent-top-bar-active': isAgent
            }"
            class="px-0 w-100 chat"
          >
            <router-view
              name="chat"
              :key="$route.fullPath"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
    v-else-if="!hasAccessToWhatsapp"
    class="whatsapp-index"
  >
    <div
      class="background-chat m-0"
      style="padding-left: 66px"
    >
      <div
        v-if="user.teams.length === 0"
        class="px-0 w-100 no-team"
      />
      <div
        v-else-if="instances.length === 0"
        class="px-0 w-100 no-connections"
      />
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from "vuex";
import WhatsappTalks from "@whatsapp/components/whatsapp-talks";
import ManagerSideBar from "@/scopes/dialer/manager/components/short-sidebar.vue";
import LoadingPageWhatsapp from "@whatsapp/components/loading-page-whatsapp.vue";
import OffcanvasNewChat from "../components/offcanvas-new-chat.vue";

export default {
  data() {
    return {
      timer: null,
      enableFadeOut: false
    };
  },

  beforeMount() {
    if (this.hasAccessToWhatsapp || this.isWhatsappAllowed) {
      this.fetchGroupChannels()
      if (!this.instances.length > 0) {
        this.findInstances().then(() => {
          if (this.instances.length > 0) {
            this.findGroupChannel({team_id: this.instances[0].team_id}).then(() => {
              this.fetchQualificationsById(this.groupChannel[0].id);
            });
          }
        });
      }
      this.ping();
      this.timer = setInterval(() => {
        this.ping();
      }, 50000);
    }
  },

  beforeUnmount() {
    clearInterval(this.timer);
  },

  computed: {
    ...mapGetters("whatsapp/instance", {
      instances: "getInstances",
      connectedInstances: "getConnectedInstances",
      groupChannel: "getGroupChannel"
    }),

    ...mapGetters("auth", {
      user: "getUser",
      hasAccessToWhatsapp: "hasAccessToWhatsapp",
      isWhatsappAllowed: "isWhatsappAllowed",
      isManager: "isManager",
      isSupervisorOrManager: "isSupervisorOrManager",
      isAgent: "isAgent"
    }),

    ...mapGetters("manager/chats_report", {
      spiedChat: "getSpiedChat"
    }),

    ...mapGetters("whatsapp/chat", {
      loadingChats: "getloadingChats",
      allTalksLoaded: "getAllTalksLoaded"
    })
  },

  methods: {
    ...mapActions("whatsapp/instance", ["findInstances", "findGroupChannel", "ping", "fetchGroupChannels", "fetchQualificationsById"]),
    ...mapActions("whatsapp/quick-message", ["findQuickMessages"]),
    ...mapMutations("whatsapp/chat", ["setAllTalksLoaded"]),

    openNewChatModal() {
      this.$refs.newChatModal.open();
    }
  },

  watch: {
    loadingChats: {
      immediate: true,
      deep: true,
      handler(el) {
        if (Object.values(el).every(item => item === false)) {
          this.enableFadeOut = true;
          setTimeout(() => {
            this.setAllTalksLoaded(true);
            if (this.spiedChat) {
              this.$router.push(`/whatsapp/${this.spiedChat}`);
            }
          }, 1000);
        }
      }
    }
  },

  components: {
    OffcanvasNewChat,
    WhatsappTalks,
    LoadingPageWhatsapp,
    ManagerSideBar
  }
};
</script>

<style lang="scss">
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.fade-in {
  animation: fadeIn 1s;
}

.fade-out {
  animation: fadeOut 1s;
}

.t-tabs {
  border-bottom: 1px solid #95aac9;
  background-color: white !important;

  .t-tab {
    border-bottom: none;
    color: #95aac9;
    font-weight: 300;
    position: relative;

    small {
      font-size: 14px !important;
    }

    top: 1px;
    cursor: pointer;

    &.active {
      color: #2c7be5;
      font-weight: 500;

      border-bottom: 2px solid #2c7be5 !important;
    }
  }
}

.whatsapp-index {
  background-color: white;

  .background-chat {
    background-color: #eaf1fb;
    background-image: url("~@/assets/img/whatsapp-background.svg") !important;
    background-size: 10%;
    background-repeat: repeat;
    background-blend-mode: color-burn;
  }

  .chat {
    background-image: url("~@/assets/img/chat.svg") !important;
    height: 100vh;
    width: 100%;
    background-size: 60% 60%;
    background-repeat: no-repeat;
    background-position: center center;

    &.agent-top-bar-active {
      height: calc(100vh - 56px);
    }
  }

  .no-connections {
    background-image: url("~@/assets/img/no-connections.svg") !important;
    height: calc(100vh - 56px);
    width: 100%;
    background-size: 50% 50%;
    background-repeat: no-repeat;
    background-position: center center;
  }

  .no-team {
    background-image: url("~@/assets/img/no-team.svg") !important;
    height: calc(100vh - 56px);
    width: 100%;
    background-size: 50% 50%;
    background-repeat: no-repeat;
    background-position: center center;
  }

  .whatsapp-talks {
    // height: 100vh;
    height: calc(100vh - 56px);
    overflow-x: hidden;
    overflow-y: hidden;
    position: relative;

    .offcanvas-backdrop {
      position: absolute !important;
      z-index: 1040 !important;
      width: 100vw !important;
      height: 100vh !important;
    }
  }

  .sidebar-margin {
    margin-left: 66px !important;
  }
}

::-webkit-scrollbar {
  width: 4px !important;
}

::-webkit-scrollbar-track {
  background: transparent !important;
}

::-webkit-scrollbar-thumb {
  background: #b0c5c2 !important;
  border-radius: 3px !important;
}

::-webkit-scrollbar-thumb:hover {
  background: #6e84a3 !important;
}
</style>
