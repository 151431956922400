<template>
  <div class="main-content pb-1">
    <header-component
      pretitle="PARTNERS"
      title="Criar novo partner"
      redirect-link="/admin/partners"
    />
    <create-update-partner-form />
  </div>
</template>

<script>
import HeaderComponent from "./components/header-component.vue";
import CreateUpdatePartnerForm from "@admin/pages/3c-partners/partners/components/create-update-partner-form.vue";

export default {
  components: {
    CreateUpdatePartnerForm,
    HeaderComponent
  }
};
</script>
