export default {
  startLoading: (state, flag) => state.loadingFlags[flag] = true,
  stopLoading: (state, flag) => state.loadingFlags[flag] = false,
  setActions: (state, actions) => state.actions = actions,
  setActionToEdit: (state, action) => state.actionToEdit = action,
  setPrevActionId: (state, actionId) => state.prevActionId = actionId,
  setGroupChannels: (state, groupChannels) => state.groupChannels = groupChannels,
  setOffcanvasEditActionIsOpen: (state, isOpen) => state.offcanvasEditActionIsOpen = isOpen,
  setOffcanvasNewActionIsOpen: (state, isOpen) => state.offcanvasNewActionIsOpen = isOpen,
  changeUpdateCoordinates: (state) => state.updateCoordinates = !state.updateCoordinates,
  setZoom: (state, zoom) => state.zoom = zoom
}
