<template>
  <div
    class="d-flex align-items-center"
    style="gap: 16px"
  >
    <card-sdr
      v-if="action.type == 'sdr'"
      :action="action"
    />
    <card-message
      v-else-if="action.type == 'question' && !action.need_chat_response"
      :action="action"
    />
    <card-question
      v-else-if="action.type == 'question'"
      :action="action"
    />
    <card-choice
      v-else-if="action.type == 'multiple_choice'"
      :action="action"
      :choices="action.choices.data"
      :id="action.id"
    >
      <template
        v-for="item in action.choices.data"
        #[item.expected_message_response]
        :key="item.expected_message_response"
      >
        <div
          class="d-flex align-items-center"
          style="gap: 16px"
        >
          <multiple-choice-item-arrow :expected-response="item.expected_message_response" />
          <card-actions
            :action="item"
            :key="item.id"
            :id="item.id"
          />
        </div>
      </template>
    </card-choice>
    <card-transfer
      v-if="action.transfer_to_group_channel_id"
      :action="action"
    />
    <card-default-next-action
      v-else-if="action.default_next_action == 'back'"
      text="Voltar"
      :action="action"
    />
    <card-default-next-action
      v-else-if="action.default_next_action == 'restart'"
      text="Reiniciar chatbot"
      :action="action"
    />
    <card-default-next-action
      v-else-if="action.default_next_action == 'finish'"
      text="Fim"
      :action="action"
    />
    <card-actions
      v-if="action.next_action_id"
      :action="getAction(action.next_action_id)"
      :key="action.next_action_id"
    />
    <button-new-action
      v-else-if="!action.default_next_action && !action.transfer_to_group_channel_id && action.type != 'multiple_choice'"
      :action-id="action.id"
    />
  </div>
</template>

<script>
import {mapGetters} from "vuex";

import CardTransfer from "@chatbot/pages/workflow/components/action-cards/card-transfer.vue";
import CardChoice from "@chatbot/pages/workflow/components/action-cards/card-choice.vue";
import CardMessage from "@chatbot/pages/workflow/components/action-cards/card-message.vue";
import CardQuestion from "@chatbot/pages/workflow/components/action-cards/card-question.vue";
import ButtonNewAction from "@chatbot/pages/workflow/components/button-new-action.vue";
import CardDefaultNextAction
  from "@chatbot/pages/workflow/components/action-cards/card-default-next-action.vue";
import MultipleChoiceItemArrow from "@chatbot/pages/workflow/components/arrows/multiple-choice-item-arrow.vue";
import CardSdr from "@chatbot/pages/workflow/components/action-cards/card-sdr.vue";

export default {
  name: "CardActions",

  components: {
    CardSdr,
    MultipleChoiceItemArrow,
    CardDefaultNextAction,
    ButtonNewAction,
    CardQuestion,
    CardMessage,
    CardChoice,
    CardTransfer
  },

  props: {
    action: {
      type: Object,
      required: true
    }
  },

  computed:{
    ...mapGetters("chatbot/actions", {
      actions: "getActions"
    }),
  },

  methods:{
    getAction(actionId) {
      return this.actions.find(action => action.id == actionId)
    }
  }
};
</script>


