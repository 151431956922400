<template>
  <div class="col-12 col-xl-6">
    <div
      class="card"
      v-if="!loading"
    >
      <div class="card-header">
        <h4 class="card-header-title">
          {{ $t("capitalizes.metrics") }}
        </h4>
        <ul
          class="nav nav-tabs card-header-tabs"
          id="nav-tab"
          role="tablist"
        >
          <li class="nav-item">
            <button
              class="nav-link active two-lines"
              id="nav-today-tab"
              data-bs-toggle="tab"
              data-bs-target="#today"
              role="tab"
              aria-controls="today"
              aria-selected="true"
            >
              <small class="text-muted">{{ $t("uppercases.today") }}</small>
              <br>
              <span class="subtitle">
                {{ metrics.connected }}
              </span>
            </button>
          </li>
          <li class="nav-item">
            <button
              class="nav-link two-lines"
              id="nav-consult-tab"
              data-bs-toggle="tab"
              data-bs-target="#consult"
              role="tab"
              aria-controls="consult"
              aria-selected="false"
            >
              <small class="text-muted">{{ $t("uppercases.consultations") }}</small>
              <br>
              <span class="subtitle">
                {{ consultMetrics.connected }}
              </span>
            </button>
          </li>
        </ul>
      </div>
      <div class="card-body tab-content">
        <div
          class="tab-pane fade show active"
          id="today"
          role="tabpanel"
        >
          <div class="row d-flex text-center align-items-center">
            <h1> {{ metrics.abandonedPercentage }}% </h1>
            <h2>{{ $t("capitalizes.abandonment") }}</h2>
          </div>
          <div class="d-flex justify-content-between border-bottom border-top py-3">
            <span>{{ $t("capitalizes.average_waiting_time") }}</span>
            <span class="text-muted">
              {{ convertSecondsToTime(metrics.awt) }}
            </span>
          </div>
          <div class="d-flex justify-content-between border-bottom py-3">
            <span>{{ $t("capitalizes.average_service_time") }}</span>
            <span class="text-muted">
              {{ convertSecondsToTime(metrics.ast) }}
            </span>
          </div>
          <div class="d-flex justify-content-between border-bottom py-3">
            <span>{{ $t("capitalizes.received_calls") }}</span>
            <div class="d-flex align-items-center">
              <span class="item-industry pe-3">
                {{ metrics.connected + metrics.abandoned + metrics.abandoned_out_service }}
              </span>
              <progressbar-multiple
                v-if="metrics.connected + metrics.abandoned + metrics.abandonedOutServicePercentage"
                style="height: 5px; width: 120px;"
                :use-icon="false"
                :data="[
                  {
                    color: '#5cb85c',
                    percentage: metrics.connectedPercentage,
                    count: metrics.connected,
                    title: this.$t('capitalizes.connected')
                  },
                  {
                    color: '#eb5545',
                    percentage: metrics.abandonedPercentage,
                    count: metrics.abandoned,
                    title: this.$t('capitalizes.abandoned')
                  },
                  {
                    color: '#1B1B2B',
                    percentage: metrics.abandonedOutServicePercentage,
                    count: metrics.abandoned_out_service,
                    title: this.$t('capitalizes.out_of_hours')
                  }
                ]"
              />
            </div>
          </div>
          <div class="d-flex justify-content-between border-bottom py-3">
            <span> {{ $t("capitalizes.connected_calls") }} </span>
            <div class="d-flex align-items-center">
              <span class="item-industry pe-3">{{ qualifications.calls }}</span>

              <progressbar-multiple
                v-if="qualifications.qualifications.length"
                style="height: 5px; width: 120px;"
                :use-icon="false"
                :data="qualifications.qualifications.map((qualification) => {
                  return {
                    color: qualification.color,
                    percentage: qualification.count / qualifications.calls * 100,
                    count: qualification.count,
                    title: qualification.name
                  }
                })"
              />
            </div>
          </div>
        </div>
        <div
          class="tab-pane fade"
          id="consult"
          role="tabpanel"
        >
          <div class="row d-flex text-center align-items-center border-bottom">
            <h1> {{ consultMetrics.abandonedPercentage }}% </h1>
            <h2>{{ $t("capitalizes.abandonment") }}</h2>
          </div>
          <div class="d-flex justify-content-between border-bottom py-3">
            <span> {{ $t("capitalizes.average_waiting_time") }} </span>
            <span class="text-muted">
              {{ convertSecondsToTime(consultMetrics.awt) }}
            </span>
          </div>
          <div class="d-flex justify-content-between border-bottom py-3">
            <span> {{ $t("capitalizes.average_service_time") }} </span>
            <span class="text-muted">
              {{ convertSecondsToTime(consultMetrics.ast) }}
            </span>
          </div>
          <div class="d-flex justify-content-between border-bottom py-3">
            <span> {{ $t("capitalizes.received_calls") }} </span>
            <div class="d-flex align-items-center">
              <span class="item-industry pe-3">
                {{ consultMetrics.connected + consultMetrics.abandoned }}
              </span>

              <progressbar-multiple
                style="height: 5px; width: 120px;"
                :use-icon="false"
                :data="[
                  {
                    color: '#5cb85c',
                    percentage: consultMetrics.connectedPercentage,
                    count: consultMetrics.connected,
                    title: this.$t('capitalizes.connected')
                  },
                  {
                    color: '#eb5545',
                    percentage: consultMetrics.abandonedPercentage,
                    count: consultMetrics.abandoned,
                    title: this.$t('capitalizes.abandoned')
                  },
                ]
                "
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { convertSecondsToTime } from "@/utils.js";
import ProgressbarMultiple from "@components/progressbar-multiple";

export default {
  computed: {
    ...mapGetters("manager/receptive_metrics", {
      loading: "isLoading",
      qualifications: "getQualifications",
      metrics: "getMetrics",
    }),
    ...mapGetters("manager/receptive_consult", {
      consultMetrics: "getConsultMetrics",
    }),

  },
  components: {
    ProgressbarMultiple,
  },
  methods: {
    ...mapActions("manager/receptive_metrics", [
      "loadMetrics",
      "clearMetrics",
      "loadQualifications",
      "clearQualifications",
    ]),
    ...mapActions("manager/receptive_consult", [
      "loadConsultMetrics",
      "clearConsultMetrics",
    ]),
    convertSecondsToTime,
  },
  async mounted() {
    const id = this.$router.currentRoute._rawValue.params.id;
    await this.loadMetrics({ id: id });
    await this.loadQualifications({ id: id });
    await this.loadConsultMetrics({ id: id });
  },

  beforeUnmount() {
    this.clearMetrics()
    this.clearQualifications()
    this.clearConsultMetrics()
  },
};
</script>
