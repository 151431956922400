import axios from "axios";
import axiosOmni from "@/plugins/axios-omni.js";

export default {
  fetchAgents({ commit }) {
    commit("startLoadingFlag", "fetchAgents")
    commit("setAgents", []);

    return new Promise((resolve, reject) => {
      axiosOmni.get('/whatsapp/team/agents')
        .then((response) => {
          commit('setAgents', response.data.data);
          resolve();
        })
        .catch((response) => {
          commit('system/setErrorMessage', response.response.data.errors, {root: true})
          reject();
        })
        .finally(() => {
          commit("stopLoadingFlag", "fetchAgents")
        });
    });
  },

  fetchTeams({ commit }) {
    commit("startLoadingFlag", "fetchTeams")

    return new Promise((resolve, reject) => {
      axios.get('/teams', {params: {per_page: 500}})
        .then((response) => {
          const teams = response.data.data.filter(team => team.whatsapp === true)
          commit('setTeams', teams);
          resolve();
        })
        .catch((response) => {
          commit('system/setErrorMessage', response.response.data.errors, {root: true})
          reject();
        })
        .finally(() => {
          commit("stopLoadingFlag", "fetchTeams")
        });
    });
  },

  fetchInstances({ commit }) {
    commit("startLoadingFlag", "fetchInstances")

    return new Promise((resolve, reject) => {
      axiosOmni.get('/whatsapp/instances', {params: {per_page: 500, status: "active"}})
        .then((response) => {
          commit('setInstances', response.data.data);
          resolve();
        })
        .catch((response) => {
          commit('system/setErrorMessage', response.response.data.errors, {root: true})
          reject();
        })
        .finally(() => {
          commit("stopLoadingFlag", "fetchInstances")
        });
    });
  },

  fetchGroupChannels({ commit }) {
    commit("startLoadingFlag", "fetchGroupChannels")
    return new Promise((resolve, reject) => {
      axiosOmni.get('/group-channel/simplified-list', {params: { per_page: -1 }})
        .then((response) => {
          commit('setGroupChannels', response.data.data.data);
          resolve();
        })
        .catch((response) => {
          commit('system/setErrorMessage', response.response.data.errors, {root: true})
          reject();
        })
        .finally(() => {
          commit("stopLoadingFlag", "fetchGroupChannels")
        });
    });
  },

  fetchGroupChannelsWithQuali({ commit }) {
    commit("startLoadingFlag", "fetchGroupChannelsWithQuali")
    return new Promise((resolve, reject) => {
      axiosOmni.get('/group-channel', {params: { per_page: 50 }})
        .then((response) => {
          commit('setGroupChannelsWithQuali', response.data.data);
          resolve();
        })
        .catch((response) => {
          commit('system/setErrorMessage', response.response.data.errors, {root: true})
          reject();
        })
        .finally(() => {
          commit("stopLoadingFlag", "fetchGroupChannelsWithQuali")
        });
    });
  },

  fetchQualifications({ commit }) {
    commit("startLoadingFlag", "fetchQualifications")
    return new Promise((resolve, reject) => {
      axios.get('/filters?include[]=qualifications')
        .then((response) => {
          commit('setQualifications', response.data.data.qualifications);
          resolve();
        })
        .catch((response) => {
          commit('system/setErrorMessage', response.response.data.errors, {root: true})
          reject();
        })
        .finally(() => {
          commit("stopLoadingFlag", "fetchQualifications")
        });
    });
  },

}


