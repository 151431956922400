<template>
  <div
    class="container-bg w-100"
    style="padding: 1rem 1.5rem"
  >
    <div
      v-if="enableCancel"
      class="modal-header border-bottom-0 pb-0 pt-1 pe-0 d-flex justify-content-end"
      style="height: 0"
    >
      <i
        class="fal fa-xmark p-0"
        @click="close"
      />
    </div>
    <div class="col-12 d-flex flex-row flex-wrap align-items-center gap-3">
      <div class="col d-flex flex-column p-0 m-0 overflow-ellipsis">
        <div class="d-flex mb-2 gap-2 align-items-center">
          <h5 class="my-0">
            {{ template.name }}
          </h5>
          <span class="badge bg-primary-soft d-flex align-items-center">
            {{ $t(`templateMessageStatus.${template.status}`) }}
          </span>
        </div>
        <div
          v-if="template?.components?.HEADER"
          class="m-0 overflow-ellipsis"
        >
          <span class="fw-bold me-2">{{ $t('header') }}:</span>
          <span
            v-if="template.components.HEADER.text?.length"
            class="text-break"
          >
            {{ template.components.HEADER?.text[0] }}
          </span>
        </div>
        <div
          v-if="template.components?.BODY"
          class="my-0 overflow-ellipsis"
        >
          <span class="fw-bold me-2">{{ $t('model') }}:</span>
          <span
            v-if="template.components.BODY.text?.length"
            class="text-break"
          >
            {{ template.components.BODY.text[0] }}
          </span>
        </div>
        <div
          v-if="template.components?.FOOTER"
          class="my-0 overflow-ellipsis"
        >
          <span class="fw-bold me-2">{{ $t('footer') }}:</span>
          <span
            v-if="template.components.FOOTER.text?.length"
            class="text-break"
          >
            {{ template.components.FOOTER.text[0] }}
          </span>
        </div>
      </div>
      <div
        v-if="!enableCancel"
        class="col-4 d-flex align-items-center"
      >
        <ul class="text-gray fs-6">
          <li class="list-inline-item d-flex gap-1">
            <span>{{ $t('date') }}:</span>
            <span>{{
              template?.quality_score?.date ?
                formatTimestamp(template?.quality_score.date, 'DD [de] MMM [de] YYYY') :
                $t('invalid_date')
            }}
            </span>
          </li>
          <li class="list-inline-item d-flex gap-1">
            <span>{{ $t('type') }}:</span>
            <span v-if="template.components?.HEADER">
              {{ $t(`templateMessageMediaType.${template.components?.HEADER.format}`) }}
            </span>
            <span v-else>
              {{ $t('text') }}
            </span>
          </li>
          <li class="list-inline-item d-flex gap-1">
            <span>{{ $t('language') }}:</span>
            {{ $t(`templateMessageLanguageType.${template.language}`) }}
          </li>
          <li class="list-inline-item d-flex gap-1">
            <span>ID:</span>
            <span>{{ template.id }}</span>
          </li>
        </ul>
      </div>
      <div
        v-if="!enableCancel"
        class="col-3 d-flex flex-column justify-content-center align-content-center gap-4"
      >
        <div
          class="btn btn-outline-secondary"
          @click="previewTemplate"
        >
          {{ $t('to_view') }}
        </div>
        <div
          class="btn btn-primary"
          @click="selectTemplate"
        >
          {{ $t('to_select') }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {formatTimestamp} from "@/utils";
import {mapMutations} from "vuex";

export default {
  name: "MessageTemplateItem",
  emits: ['preview', 'close'],
  props: {
    template: {type: Object, default: null},
    enableCancel: {type: Boolean, default: null},
  },
  methods: {
    formatTimestamp,
    ...mapMutations("whatsapp/waba", ["setSelectedMessageTemplate"]),
    previewTemplate (){
      this.$emit('preview', this.template)
    },
    selectTemplate (){
      this.setSelectedMessageTemplate(this.template)
      this.close()
    },
    close (){
      this.$emit('close')
    }
  }
}
</script>
<style scoped lang="scss">
.container-bg {
  max-width: 96%;
  background-color: #F9FBFD !important;
  border: 1px solid #E1E9F4 !important;
  border-radius: $border-radius;
}
.fa-xmark {
  margin-right: -1% !important;
}
</style>
