<template>
  <div class="dropdown">
    <button
      class="btn btn-sm btn-select dropdown-toggle d-flex align-items-center gap-2"
      data-bs-toggle="dropdown"
      data-bs-auto-close="outside"
      aria-expanded="false"
    >
      <div
        class="count rounded-circle bg-blue-300 tx-text-light"
        v-if="selectedTags.length"
      /> {{ $t('tags') }}
    </button>
    <div class="dropdown-menu dropdown-tags">
      <div class="card m-0">
        <div class="card-header py-3 h-auto d-flex flex-column">
          <ul class="list-unstyled w-100 p-0 m-0 row list-tags">
            <tag-contact
              class="mb-2 me-2"
              v-for="tag in selectedTags"
              :key="tag.id"
              :tag="tag"
              close-enabled
              @close="unselect(tag)"
            />
          </ul>
          <app-button
            v-if="selectedTags.length > 2"
            :color="'btn-outline-primary'"
            class="btn-sm border-0"
            @click="clear"
          >
            <span class="far fa-trash" /> {{ $t('remove_all') }}
          </app-button>
          <div class="d-flex align-items-center w-100 gap-1">
            <span class="far fa-search tx-text-gray" />
            <input
              class="input-tags w-100"
              :placeholder="$t('search_capitalize')"
              type="text"
              maxlength="20"
              v-model="search"
            >
          </div>
        </div>
        <div
          v-if="tags.length"
          class="card-body py-3"
        >
          <ul class="list-unstyled p-0 m-0 d-flex flex-column gap-2 list-tags">
            <tag-contact
              class="mb-2 me-2"
              v-for="tag in filteredTags"
              :key="tag.id"
              :tag="tag"
              click-enabled
              @click="select(tag)"
            />
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {tagBackgrounds} from "@/utils";
import {mapActions, mapGetters} from "vuex";
import TagContact from "@whatsapp/components/tags/tag-contact.vue";
import AppButton from "@components/app-button.vue";

export default {
  name: "SelectContactTags",

  emits: ['select'],

  components: {
    AppButton,
    TagContact,
  },

  data() {
    return {
      tagBackgrounds: tagBackgrounds,
      search: "",
      selectedTags: [],
      selectedIds: []
    }
  },

  created() {
    this.fetchTags()
  },

  watch: {
    selectedIds: {
      handler(ids) {
        this.$emit('select', ids)
      },
      deep: true
    }
  },

  computed: {
    ...mapGetters('crm/tags', {
      tags: "getTags",
      loadingFlags: 'getLoadingFlags'
    }),

    availableTags() {
      return this.tags.filter(tag =>
        !this.selectedTags.some(selectedTag => selectedTag.id === tag.id)
      );
    },

    filteredTags() {
      return this.availableTags.filter(tag =>{
        return tag.name.toLowerCase().includes(this.search.toLowerCase());
      })
    }
  },

  methods: {
    ...mapActions('crm/tags', ['fetchTags']),

    select(tag) {
      this.selectedTags.push(tag)
      this.selectedIds.push(tag.id)
    },

    unselect(tag) {
      this.selectedTags = this.selectedTags.filter(selectedTag => selectedTag.id !== tag.id)
      this.selectedIds = this.selectedIds.filter(id => id !== tag.id)
    },

    clear() {
      this.selectedTags = []
      this.selectedIds = []
    }
  }
}
</script>

<style scoped lang="scss">
.dropdown-tags {
  padding: 0 !important;
  width: 350px;
}

.btn-select {
  background-color: #F0F4FA
}

.count{
  width: 10px;
  height: 10px;
}

.btn-select.show {
  background-color: $color-blue-300 !important;
  color: $color-text-light !important;

  .count{
    background-color: #F0F4FA !important;
    color: $color-text-dark !important;
  }
}

.card-tag {
  width: fit-content;
  height: 1.5rem;
  border-radius: 0.375rem;
  padding: 0 0.5rem;
  font-size: 0.875rem;
}

.input-tags {
  border: none;
  outline: none;
  color: #677C92;
}

.list-tags {
  max-height: 334px;
  overflow-y: scroll;
}
</style>