<template>
  <modal-component
    ref="new-product-modal"
    :show="showModal"
    size="modal-dialog-centered modal-xl"
    @close="$emit('close')"
  >
    <template #content>
      <div class="modal-content">
        <div class="modal-header">
          <h4
            id="new-product-modal"
            class="modal-title mb-0 tx-text-200"
          >
            {{ $t('add_products_and_services') }}
          </h4>
          <button
            aria-label="Close"
            class="btn"
            type="button"
            @click="$emit('close')"
          >
            <i class="fa-regular fa-close" />
          </button>
        </div>
        <div
          v-if="showModal"
          class="modal-body"
        >
          <card-loading
            v-if="loadingFlags.fetchSynchronizedProducts"
            height="40"
            class="mb-0"
          />
          <div
            v-else
            class="col-12 px-0"
          >
            <sync-products-table
              :key="showModal+synchronizedProducts"
              :currency="search"
              @update-sync-list="calcFooterMetrics"
            />
          </div>
        </div>
        <div class="modal-footer">
          <div class="d-flex align-items-center justify-content-between w-100">
            <div class="d-flex gap-3">
              <div>
                <label>
                  {{ $t('total_products') }}:
                  <span class="fw-bold">{{ rows.length || 0 }}</span>
                </label>
              </div>
              <div>
                <label>
                  {{ $t('quantity') }}:
                  <span class="fw-bold">{{ totalQuantity || 0 }}</span>
                </label>
              </div>
            </div>
            <div class="d-flex align-items-center ms-auto">
              <div class="d-flex gap-3">
                <label>
                  {{ $t('recurrent_value') }}
                  <span class="fw-bold">{{ formatCurrency(totalRecurrence, 'pt-BR', productCurrency) }}</span>
                </label>
              </div>
              <div class="vr m-3 border border-light" />
              <div class="d-flex">
                <label>
                  {{ $t('unique_value') }}
                  <span class="fw-bold">{{ formatCurrency(totalSingle, 'pt-BR', productCurrency) }}</span>
                </label>
              </div>
              <div class="vr m-3 border border-light" />
              <div class="d-flex align-items-center gap-3">
                <label class="fw-bold">
                  Total
                </label>
                <currency-input
                  ref="totalGeneralCurrencyInput"
                  v-model="totalGeneral"
                  :currency="search"
                  custom-class="bg-light"
                  readonly
                />
              </div>
              <button
                :disabled="disableSaveButton"
                class="btn btn-primary ms-2 px-5"
                type="button"
                @click="handleSubmit"
              >
                {{ $t('capitalizes.save') }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </template>
  </modal-component>
</template>
<script>
import {defineComponent} from 'vue'
import SyncProductsTable from "@crm/components/contact/products/table/sync-products-table.vue";
import {mapActions, mapGetters} from "vuex";
import CurrencyInput from "@components/currency-input.vue";
import {Utils} from "@/utils";
import {parseInt} from "lodash/string";
import ModalComponent from "@components/modal-component.vue";
import CardLoading from "@components/card-loading.vue";

export default defineComponent({
  name: "NewProductModal",
  components: {CardLoading, ModalComponent, CurrencyInput, SyncProductsTable},
  props: {
    showModal: {type: Boolean, default: false},
    opportunityId: {type: Number, default: null},
    fetchProducts: {type: Boolean, default: false},
  },
  emits: ['close'],
  data() {
    return {
      rows: [],
      totalQuantity: 0,
      totalRecurrence: 0,
      totalSingle: 0,
      totalGeneral: 0,
      productCurrency: "BRL",
      search: "BRL",
      products: [],
      availableCurrencyFormats: [
        {name: this.$t('currency_format_brl'), locale: 'pt-BR', abbr: 'BRL', symbol: "R$"},
        {name: this.$t('currency_format_usd'), abbr: 'USD', locale: 'en-US', symbol: "$"},
        {name: this.$t('currency_format_eur'), abbr: 'EUR', locale: 'en-EN', symbol: "€"}
      ]
    }
  },

  computed: {
    ...mapGetters("manager/products_and_services", {
      synchronizedProducts: "getSynchronizedProducts",
      loadingFlags: "getLoadingFlag"
    }),

    disableSaveButton() {
      return this.rows.some((row) => row.maximum_discount < parseInt(row.discount_amount))
    },

    getOpportunityId() {
      return this.opportunityId || this.$route.params.opportunityId;
    }
  },

  watch: {
    search(value) {
      if (value)
        this.loadProducts()
    },

    showModal(show) {
      if (show) {
        this.updateSynchronizedProducts();
      }
    },
  },

  mounted() {
    this.updateSynchronizedProducts()
  },

  methods: {
    ...mapActions("manager/products_and_services", [
      "fetchPnS",
      "syncProductOpportunity",
      "fetchSynchronizedProducts"
    ]),
    ...mapActions("crm/funnels", ['updateCurrentFunnel']),

    formatCurrency: Utils.formatCurrency,

    updateSynchronizedProducts() {
      if (this.fetchProducts && this.opportunityId !== null) {
        this.fetchSynchronizedProducts({opportunity_id: this.opportunityId})
      }
    },

    loadProducts() {
      this.fetchPnS({
        currency: this.search,
        page: 1,
        limit: 10,
        is_active: 1
      })
    },

    calcFooterMetrics(rows) {
      this.rows = rows

      this.totalSingle = 0;
      this.totalRecurrence = 0;
      this.totalQuantity = 0;
      this.totalGeneral = 0;
      let totalSingleDiscount = 0;
      let totalRecurrenceDiscount = 0;
      this.rows.map((row) => {
        if (row.is_active && row.value) {
          if (row.is_recurrent) {
            totalRecurrenceDiscount = row.discount_amount * row.quantity
            this.totalRecurrence += parseFloat(row.price) * row.quantity - totalRecurrenceDiscount
          } else {
            totalSingleDiscount = row.discount_amount * row.quantity
            this.totalSingle += parseFloat(row.price) * row.quantity - totalSingleDiscount
          }
          this.totalQuantity += row.quantity
          this.productCurrency = row.currency
        }
      })
      this.totalGeneral = this.totalRecurrence + this.totalSingle
    },

    handleSubmit() {
      let listToSync = this.products = this.rows.filter((r) => r.value && r.id)
      if (this.synchronizedProducts.length === 0)
        listToSync = listToSync.filter((row) => row.is_active)
      listToSync.map((row) => {
        return {
          id: row.id,
          quantity: row.quantity,
          discount_percentage: true,
          discount_amount: parseFloat(row.discount_amount),
          is_active: row.is_active
        }
      });
      this.syncProductOpportunity({
        products: listToSync,
        opportunity_id: this.getOpportunityId
      }).then(() => {
        this.$emit('close', true);
        if (this.$route.name === 'crm-funnel') {
          this.updateCurrentFunnel(this.$route.params.id);
        }
      })
      this.rows = []
    }
  },
})
</script>

<style lang="scss" scoped>
.btn-light {
  &:hover {
    background-color: $primary;
    color: $color-gray-100;
  }
}
</style>