<template>
  <modal-component
    @close="closeModal"
    :show="showModal"
    :center="true"
    size="modal-md"
  >
    <template
      #content
    >
      <div class="modal-header p-4">
        <div class="w-100">
          <h2 class="m-0 modal-title text-center">
            {{ title }}
          </h2>
        </div>
        <button
          type="button"
          class="btn-close mb-3"
          data-bs-dismiss="modal"
          aria-label="Close"
        />
      </div>
      <div
        class="modal-body text-center"
        style="padding: 24px 48px;"
      >
        <div
          class="d-flex align-items-center justify-content-center mb-2"
          v-if="quantityLicenses"
        >
          <h5
            class="fw-normal me-2 tx-text-gray"
            style="line-height: 24px;"
          >
            Licenças ativas
          </h5>
          <h5
            class="fw-normal"
            style="line-height: 24px;"
            v-if="licenseType == 'ring_group_licenses'"
          >
            {{ register?.used_ring_group_licenses }}/{{ register?.ring_group_licenses }}
          </h5>
          <h5
            class="fw-normal"
            style="line-height: 24px;"
            v-if="licenseType == 'ura_licenses'"
          >
            {{ register?.ura_licenses - register?.ura_limit }}/{{ register?.ura_licenses }}
          </h5>
        </div>
        <div
          class="row justify-content-center"
          style="margin-bottom: 30px;"
        >
          <div class="col-4">
            <div
              class="input-group py-2 px-3"
            >
              <span
                class="border-0 input-group-text mb-0 py-0 ps-0 pe-2 mt-1"
                style="font-size: 24px;"
                v-if="(licenseType == 'ura_licenses' && modelValue <= (register?.ura_licenses - register?.ura_limit)) || (licenseType == 'ring_group_licenses' && modelValue <= used_ring_group_licenses)"
              >
                <tooltip-component
                  title="É necessário que o gestor desabilite as licenças utilizadas para realizar o downsell"
                  icon="minus"
                  color="tx-text-gray m-0"
                />
              </span>
              <span
                class="border-0 input-group-text fe fe-minus my-0 cursor-pointer tx-blue-300 py-0 ps-0 pe-2 ms-1"
                style="font-size: 24px;"
                @click="decrease"
                v-if="(licenseType != 'ura_licenses' || modelValue > (register?.ura_licenses - register?.ura_limit)) || (licenseType != 'ring_group_licenses' && modelValue > used_ring_group_licenses)"
              />
              <input
                class="border-0 form-control p-0 h5 text-center tx-text-gray"
                type="number"
                :value="modelValue"
                @input="updateValue"
                @focusout="preventInvalidValue"
              >
              <span
                class="border-0 input-group-text fe fe-plus my-0 cursor-pointer tx-blue-300 py-0 pe-0 ps-2"
                style="font-size: 24px;"
                @click="increase"
              />
            </div>
          </div>
        </div>
        <div
          class="cursor-pointer"
          style="margin-bottom: 32px;"
          @click="$refs.removeLicenses.openModal(), closeModal()"
        >
          <h5
            class="fw-normal m-0"
            style="color: #F23F2C;"
          >
            <i class="me-2 fa-light fa-user-xmark" />
            Zerar licenças
          </h5>
        </div>
        <div
          class="mb-4"
          v-if="showReasonInput"
        >
          <label class="form-label text-start w-100 h5 fw-normal">Motivo da alteração</label>
          <textarea
            placeholder="Descreva o motivo da alteração"
            v-model="reason"
            class="form-control rounded-4 p-3 h5 fw-normal"
            id="textarea"
            rows="5"
            required
          />
        </div>
        <div class="row justify-content-center">
          <div class="col-12">
            <button
              class="btn btn-primary w-100"
              :disabled="enableButton"
              @click="submit"
            >
              Confirmar
            </button>
            <app-button
              class="w-100"
              color="tx-gray-400"
              @click="closeModal"
            >
              Cancelar
            </app-button>
          </div>
        </div>
      </div>
    </template>
  </modal-component>

  <remove-all-licenses
    ref="removeLicenses"
    title="Tem certeza que deseja zerar as licenças?"
    :license-type="licenseType"
  />
</template>

<script>
import ModalComponent from "@/components/modal-component.vue";
import AppButton from "@/components/app-button.vue";
import RemoveAllLicenses from "./remove-all-licenses.vue";
import { mapGetters, mapActions, mapMutations } from "vuex";
import TooltipComponent from "@/components/tooltip-component.vue";

export default {
  emits: ['update:modelValue'],

  components: {
    ModalComponent,
    AppButton,
    RemoveAllLicenses,
    TooltipComponent
  },

  props: {
    title: {
      type: String,
      default: ""
    },

    modelValue: {
      type: Number,
      default: 0,
    },

    quantityLicenses: {
      type: Boolean,
      default: false
    },

    licenseType: {
      type: String,
      required: true
    },
  },

  data() {
    return {
      showModal: false,
      showReasonInput: false,
      reason: '',
      internalValue: this.modelValue,
      submitParam: {},
      decreased: false,
    };
  },

  watch: {
    modelValue(newValue) {
      this.internalValue = newValue;
    },
  },

  computed: {
    ...mapGetters("admin/companies", {
      register: "getRegister",
    }),

    enableButton() {
      if (this.reason) {
        return false;
      }
      return true;
    },
  },

  methods: {
    ...mapActions("admin/companies", ["findById", "updateLicense"]),
    ...mapActions("auth", ["getCurrentCompany"]),
    ...mapMutations("auth", ["trackEvents"]),

    openModal() {
      this.showModal = true;
    },

    closeModal() {
      this.showReasonInput = false;
      this.showModal = false;
    },

    decrease() {
      if (this.internalValue <= 1) {
        return;
      }
      if (this.licenseType == 'ura_licenses' && this.internalValue <= (this.register?.ura_licenses - this.register?.ura_limit)) {
        return;
      }
      if (this.licenseType == 'ring_group_licenses' && this.internalValue <= this.register?.used_ring_group_licenses) {
        return;
      }
      this.internalValue -= 1;
      this.updateParent();
      this.showReasonInput = true;
      this.decreased = true;
    },

    increase() {
      this.internalValue += 1;
      this.updateParent();
      this.showReasonInput = true;
    },

    updateValue(event) {
      this.internalValue = Number(event.target.value);

      if (isNaN(this.internalValue)) {
        this.internalValue = 0;
      }
      this.updateParent();
      this.showReasonInput = true;
    },

    updateParent() {
      this.$emit('update:modelValue', this.internalValue);
    },

    preventInvalidValue(event) {
      if (event.target.value < 1) {
        this.internalValue = 1;
      }
      this.updateParent();
    },

    submit() {
      this.submitParam[this.licenseType] = this.modelValue
      this.submitParam["change_reason"] = this.reason
      this.updateLicense({
        id: this.$route.params.id,
        params: this.submitParam
      }).then(() => {
        this.findById({ id: this.$route.params.id }).then(() => {
            this.getCurrentCompany().then(() => {
              if (this.decreased) {
                this.trackEvents(`[Admin] Decrease Licenses`)
              }
          })
        })
        this.showModal = false;
        this.showReasonInput = false;
        this.reason = '';
      })
    }
  }
};
</script>

<style lang="scss" scoped>

.modal-header .btn-close {
  margin-right: -0.9rem;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

textarea {
  border: 1px solid $color-gray-blue-300;
}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: $color-text-gray !important;
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: $color-text-gray !important;
}

::-ms-input-placeholder { /* Microsoft Edge */
  color: $color-text-gray !important;
}

</style>
