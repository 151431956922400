<template>
  <prevent-multiples-connections />
  <div class="top-bar-wrapper">
    <div
      class="top-bar h-100 py-3 px-4 position-relative d-flex align-items-center justify-content-between"
      :class="getBackgroundColors"
    >
      <progress-bar v-if="shouldShowProgressBar" />
      <no-internet-connection v-if="showSocketDisconnection" />
      <template v-else>
        <div
          v-for="(section, index) in sections"
          :key="section.name"
          :class="['d-flex align-items-center gap-3', { 'middle-section': index === 1 && shouldShowCallHandler }]"
        >
          <template v-for="component in section.components">
            <component
              :key="component.name"
              :is="component.component"
              v-if="component.condition"
            />
          </template>
        </div>
      </template>
      <call-handler v-if="shouldShowCallHandler" />
      <span
        v-if="shouldShowInactiveMessage && !showSocketDisconnection"
        class="tx-text-gray text-large position-absolute top-50 inactive-message"
        style="transform: translate(-80%, -50%) !important;"
      >
        {{ inactiveMessage }}
      </span>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { AgentStatus, CallStatus } from "@/utils";
import MicButton from "@dialer/agent/components/top-bar/mic-button/mic-button.vue";
import ReadableCallStatus from "@dialer/agent/components/top-bar/readable-call-status/readable-call-status.vue";
import KeyPad from "@dialer/agent/components/top-bar/key-pad/key-pad.vue";
import WorkBreakIntervals from "@dialer/agent/components/top-bar/work-break-intervals/work-break-intervals.vue";
import CallHistory from "@dialer/agent/components/top-bar/call-history/call-history.vue";
import ScheduleComponent from "@dialer/agent/components/top-bar/schedule/schedule-component.vue";
import ManualButton from "./manual/manual-button.vue";
import ExitManualButton from "@dialer/agent/components/top-bar/manual/exit-manual-button.vue";
import HangupButton from "@dialer/agent/components/top-bar/hangup/hangup-button.vue";
import CallDetails from "@dialer/agent/components/top-bar/call-details/call-details.vue";
import WhatsappRedirect from "@dialer/agent/components/top-bar/whatsapp-redirect/whatsapp-redirect.vue";
import QualificationComponent from "@dialer/agent/components/top-bar/qualification/qualification-component.vue";
import ExitWorkBreak from "@dialer/agent/components/top-bar/work-break-intervals/exit-work-break.vue";
import ManualAcwButton from "@dialer/agent/components/top-bar/manual-acw/manual-acw-button.vue";
import TransferenceComponent from "@dialer/agent/components/top-bar/transference/transference-component.vue";
import IvrAfterCallButton from "@dialer/agent/components/top-bar/ivr/ivr-after-call-button.vue";
import CancelConsult from "@dialer/agent/components/top-bar/consult/cancel-consult.vue";
import CallHandler from "@dialer/agent/components/top-bar/call-handler/call-handler.vue";
import RecordAudioButton from "@dialer/agent/components/top-bar/record/record-audio-button.vue";
import ProgressBar from "@dialer/agent/components/top-bar/progress-bar/progress-bar.vue";
import ToggleRingGroupButton from "@dialer/agent/pages/pabx/toggle-ring-group-button.vue";
import LoginCampaign from "@dialer/agent/pages/campaign/login-campaign.vue";
import DialingComponent from "@dialer/agent/pages/pabx/dialing-component.vue";
import InternalCall from "@dialer/agent/pages/pabx/internal-call.vue";
import InternalTransference from "@dialer/agent/pages/pabx/internal-transference.vue";
import NoInternetConnection from "@dialer/agent/components/top-bar/offline/no-internet-connection.vue";
import PreventMultiplesConnections from "@dialer/agent/pages/extension/components/prevent-multiples-connections.vue";

export default {
  name: "AgentTopBar",
  data() {
    return {
      AgentStatus,
      CallStatus,
      showSocketDisconnection: false
    };
  },

  components: {
    MicButton,
    ReadableCallStatus,
    KeyPad,
    WorkBreakIntervals,
    ToggleRingGroupButton,
    LoginCampaign,
    InternalCall,
    CallHistory,
    DialingComponent,
    ScheduleComponent,
    CallDetails,
    WhatsappRedirect,
    HangupButton,
    InternalTransference,
    CallHandler,
    CancelConsult,
    RecordAudioButton,
    ExitManualButton,
    ManualButton,
    ProgressBar,
    QualificationComponent,
    ExitWorkBreak,
    ManualAcwButton,
    TransferenceComponent,
    IvrAfterCallButton,
    NoInternetConnection,
    PreventMultiplesConnections
  },

  mounted() {
    this.fetchRingGroupUsers();
  },

  methods: {
    ...mapActions("agent/ring_group", ["fetchRingGroupUsers"])
  },

  computed: {
    ...mapGetters("agent/ring_group", {
      isRingGroupPermissionEnabled: "isRingGroupPermissionEnabled",
      isRingGroupActive: "isRingGroupActive",
      callStatus: "getCallStatus",
      callPABX: "getCallPABX",
      loadingFlags: "getLoadingFlags",
      internalCallAgent: "getInternalCallAgent"
    }),

    ...mapGetters("system", {
      status: "getStatus",
      mode: "getMode",
      isConsultHold: "isConsultHold",
      inConsulting: "inConsulting",
      socketIOStatus: "getSocketIOStatus"
    }),

    ...mapGetters("auth", {
      loading: "getLoadingFlags",
      user: "getUser"
    }),

    ...mapGetters("agent/agent", {
      loggedCampaign: "getCampaign"
    }),

    ...mapGetters("agent/call", {
      call: "getCall"
    }),

    ...mapGetters("agent/work-break", {
      intervalActive: "getIntervalActive",
    }),

    sections() {
      return [
        {
          name: "section1",
          components: [
            {
              name: "callDetails",
              component: "CallDetails",
              condition: this.shouldShowComponent
            },
            {
              name: "workBreakIntervals",
              component: "WorkBreakIntervals",
              condition: this.shouldShowWorkBreakIntervals
            },
            {
              name: "whatsappRedirect",
              component: "WhatsappRedirect",
              condition: this.shouldShowComponent
            },
            {
              name: "toggleRingGroupButton",
              component: "ToggleRingGroupButton",
              condition: this.shouldShowToggleRingGroupButton
            },
            {
              name: "loginCampaign",
              component: "LoginCampaign",
              condition: this.shouldShowLoginCampaign
            }
          ]
        },
        {
          name: "section2",
          components: [
            {
              name: "hangupButton",
              component: "HangupButton",
              condition: this.shouldShowHangupButton
            },
            {
              name: "exitManualButton",
              component: "ExitManualButton",
              condition: this.shouldShowExitManualButton
            },
            {
              name: "readableCallStatus",
              component: "ReadableCallStatus",
              condition: this.shouldShowReadableCallStatus
            },
            {
              name: "dialingComponent",
              component: "DialingComponent",
              condition: this.shouldShowDialingComponent
            },
            {
              name: "micButton",
              component: "MicButton",
              condition: this.shouldShowMicButton
            },
            {
              name: "keyPad",
              component: "KeyPad",
              condition: this.shouldShowKeyPad
            },
            {
              name: "manualButton",
              component: "ManualButton",
              condition: this.shouldShowManualButton
            },
            {
              name: "cancelConsult",
              component: "CancelConsult",
              condition: this.shouldShowCancelConsult
            },
            {
              name: "internalTransference",
              component: "InternalTransference",
              condition: this.shouldShowInternalTransference
            },
            {
              name: "internalCall",
              component: "InternalCall",
              condition: this.shouldShowInternalCall
            },
            {
              name: "recordAudioButton",
              component: "RecordAudioButton",
              condition: this.shouldShowRecordAudioButton
            },
            {
              name: "manualAcwButton",
              component: "ManualAcwButton",
              condition: this.showManualAcwButton
            },
            {
              name: "transferenceComponent",
              component: "TransferenceComponent",
              condition: this.shouldShowTransferenceComponent
            },
            {
              name: "ivrAfterCallButton",
              component: "IvrAfterCallButton",
              condition: this.shouldShowIvrAfterCallButton
            }
          ]
        },
        {
          name: "section3",
          components: [
            {
              name: "scheduleComponent",
              component: "ScheduleComponent",
              condition: this.shouldShowScheduleComponent
            },
            {
              name: "callHistory",
              component: "CallHistory",
              condition: this.shouldShowCallHistory
            },
            {
              name: "qualificationComponent",
              component: "QualificationComponent",
              condition: this.shouldShowQualificationComponent
            },
            {
              name: "exitWorkBreak",
              component: "ExitWorkBreak",
              condition: this.shouldShowExitWorkBreak
            }
          ]
        }
      ];
    },

    getBackgroundColors() {
      return {
        "bg-offline": !this.showSocketDisconnection && this.status === AgentStatus.OFFLINE,
        "bg-idle": this.showSocketDisconnection || (this.status === AgentStatus.IDLE && this.mode == "dialer"),
        "bg-manual": this.status == AgentStatus.IDLE && this.mode == "manual",
        "bg-interval": this.status === AgentStatus.ON_WORK_BREAK,
        "bg-calling":
          this.status === AgentStatus.ON_CALL &&
          ((this.call && this.call.call_mode === "receptive" && this.isConsultHold) ||
            [CallStatus.DIALING, CallStatus.TRYING_INTERNAL_CALL, CallStatus.CONSULT_TRYING].includes(this.callStatus)),
        "bg-call":
          this.status === AgentStatus.ON_CALL &&
          (this.mode === "dialer" ||
            this.mode === "manual" ||
            (this.callStatus === CallStatus.CONNECTED && this.callPABX.call_mode === "manual")),
        "bg-tpa": this.status === AgentStatus.ACW,
        "bg-mtpa": this.status === AgentStatus.ON_MANUAL_CALL_ACW,
        "bg-green-300":
          this.status === AgentStatus.ON_CALL &&
          this.callStatus === CallStatus.CONNECTED &&
          this.callPABX.call_mode === "receptive",
        "bg-receptive":
          (this.status === AgentStatus.ON_CALL &&
            ((this.call && this.call.call_mode === "receptive" && !this.isConsultHold) ||
              [CallStatus.INTERNAL_CALL_ANSWERED, CallStatus.CONSULT_ANSWERED].includes(this.callStatus))) ||
          (!this.call && this.status === AgentStatus.CONSULT_CONNECTED),
        "bg-danger": this.status === AgentStatus.ON_CALL && [CallStatus.INTERNAL_CALL_FAILED].includes(this.callStatus)
      };
    },

    shouldShowComponent() {
      const CALL_STATUSES = [
        CallStatus.DIALING,
        CallStatus.CONNECTED,
        CallStatus.CONSULT_ANSWERED,
        CallStatus.TRANSFERENCE_CONNECTED,
        CallStatus.TRYING_INTERNAL_CALL,
        CallStatus.INTERNAL_CALL_FAILED,
        CallStatus.CONSULT_TRYING,
        CallStatus.CONSULT_CONNECTED
      ];

      if (this.isRingGroupActive) {
        return this.status === AgentStatus.ON_CALL && CALL_STATUSES.includes(this.callStatus);
      }

      if (this.loggedCampaign) {
        return [
          AgentStatus.ON_CALL,
          AgentStatus.ACW,
          AgentStatus.ON_MANUAL_CALL_ACW,
          AgentStatus.CONSULT_CONNECTED
        ].includes(this.status);
      }

      return false;
    },

    shouldShowToggleRingGroupButton() {
      return this.isRingGroupPermissionEnabled && this.status === AgentStatus.OFFLINE;
    },

    shouldShowLoginCampaign() {
      return this.status === AgentStatus.OFFLINE;
    },

    shouldShowHangupButton() {
      if (this.isRingGroupActive) {
        return (
          this.status === AgentStatus.ON_CALL &&
          [
            CallStatus.DIALING,
            CallStatus.CONNECTED,
            CallStatus.RECEIVING_EXTERNAL_CALL,
            CallStatus.RECEIVING_INTERNAL_CALL,
            CallStatus.INTERNAL_CALL_ANSWERED,
            CallStatus.TRANSFERENCE_CONNECTED,
            CallStatus.TRYING_INTERNAL_CALL,
            CallStatus.INTERNAL_CALL_FAILED
          ].includes(this.callStatus)
        );
      }

      if (this.loggedCampaign) {
        return [AgentStatus.ON_CALL, AgentStatus.CONSULT_CONNECTED].includes(this.status) && this.call;
      }

      return false;
    },

    shouldShowReadableCallStatus() {
      const CALL_STATUSES = [
        CallStatus.DIALING,
        CallStatus.CONNECTED,
        CallStatus.RECEIVING_EXTERNAL_CALL,
        CallStatus.RECEIVING_INTERNAL_CALL,
        CallStatus.INTERNAL_CALL_ANSWERED,
        CallStatus.RECEIVING_TRANSFERENCE,
        CallStatus.CONSULT_ANSWERED,
        CallStatus.TRANSFERENCE_CONNECTED,
        CallStatus.TRYING_INTERNAL_CALL,
        CallStatus.INTERNAL_CALL_FAILED,
        CallStatus.CONSULT_TRYING,
        CallStatus.CONSULT_CONNECTED
      ];

      if (this.isRingGroupActive) {
        return this.status === AgentStatus.ON_CALL && CALL_STATUSES.includes(this.callStatus);
      }

      if (this.loggedCampaign) {
        return this.status !== AgentStatus.OFFLINE;
      }

      return false;
    },

    shouldShowDialingComponent() {
      return this.isRingGroupPermissionEnabled && this.isRingGroupActive && this.status === AgentStatus.OFFLINE;
    },

    shouldShowMicButton() {
      if (this.isRingGroupPermissionEnabled && this.isRingGroupActive) {
        return true;
      }

      if (this.loggedCampaign) {
        return this.status !== AgentStatus.OFFLINE;
      }

      return false;
    },

    shouldShowKeyPad() {
      const CALL_STATUSES = [
        CallStatus.RECEIVING_EXTERNAL_CALL,
        CallStatus.RECEIVING_INTERNAL_CALL,
        CallStatus.INTERNAL_CALL_ANSWERED,
        CallStatus.RECEIVING_TRANSFERENCE
      ];

      const AGENT_STATUSES = [AgentStatus.ON_CALL, AgentStatus.ON_MANUAL_CALL_ACW, AgentStatus.CONSULT_CONNECTED];

      if (this.isRingGroupActive) {
        return this.isRingGroupPermissionEnabled && !CALL_STATUSES.includes(this.callStatus);
      }

      if (this.loggedCampaign) {
        return AGENT_STATUSES.includes(this.status) || (this.status === AgentStatus.IDLE && this.mode === "manual");
      }

      return false;
    },

    shouldShowCancelConsult() {
      if (this.isRingGroupActive) {
        return this.status === AgentStatus.ON_CALL && [CallStatus.CONSULT_CONNECTED].includes(this.callStatus);
      }

      if (this.loggedCampaign) {
        return (
          (this.status === AgentStatus.ON_CALL && this.isConsultHold) ||
          (this.call && this.status === AgentStatus.CONSULT_CONNECTED)
        );
      }

      return false;
    },

    shouldShowInternalTransference() {
      return (
        this.status === AgentStatus.ON_CALL &&
        [
          CallStatus.DIALING,
          CallStatus.CONNECTED,
          CallStatus.CONSULT_ANSWERED,
          CallStatus.TRANSFERENCE_CONNECTED,
          CallStatus.TRYING_INTERNAL_CALL,
          CallStatus.INTERNAL_CALL_FAILED,
          CallStatus.CONSULT_TRYING
        ].includes(this.callStatus)
      );
    },

    shouldShowInternalCall() {
      return this.isRingGroupPermissionEnabled && this.isRingGroupActive && this.status === AgentStatus.OFFLINE;
    },

    shouldShowScheduleComponent() {
      if (this.isRingGroupActive) {
        return (
          this.isRingGroupPermissionEnabled && this.isRingGroupActive && this.status === AgentStatus.OFFLINE && false // O component de agendamento será ativado novamente quando as qualificações forem implementadas
        );
      }

      if (this.loggedCampaign) {
        return this.status === AgentStatus.IDLE;
      }

      return false;
    },

    shouldShowCallHistory() {
      if (this.isRingGroupActive) {
        return this.isRingGroupPermissionEnabled && this.isRingGroupActive && this.status === AgentStatus.OFFLINE;
      }


      if (this.loggedCampaign && !this.loggedCampaign.agent_dashboard) {
        return this.mode === "dialer" && this.status === AgentStatus.IDLE;
      }

      return false;
    },

    shouldShowCallHandler() {
      if (this.isRingGroupActive) {
        return (
          this.status === AgentStatus.ON_CALL &&
          [
            CallStatus.RECEIVING_EXTERNAL_CALL,
            CallStatus.RECEIVING_INTERNAL_CALL,
            CallStatus.CONSULT_CONNECTED
          ].includes(this.callStatus)
        );
      }

      if (this.loggedCampaign) {
        return this.call && this.status === AgentStatus.CONSULT_CONNECTED;
      }

      return false;
    },

    shouldShowRecordAudioButton() {
      const isAgentDialingToAnInternalCall = !!this.internalCallAgent && this.callStatus === CallStatus.DIALING;
      const isAgentReceivingInternalCall = [CallStatus.RECEIVING_INTERNAL_CALL, CallStatus.RECEIVING_EXTERNAL_CALL].includes(this.callStatus);

      return [AgentStatus.ON_CALL].includes(this.status) && this.user.company.record_audio_agent && !isAgentDialingToAnInternalCall && !isAgentReceivingInternalCall;
    },

    shouldShowInactiveMessage() {
      return this.isRingGroupPermissionEnabled && !this.isRingGroupActive && this.status === AgentStatus.OFFLINE;
    },

    inactiveMessage() {
      return this.loading.updateUser || this.loadingFlags.updateRingGroupStatus
        ? this.$t("activating_extension")
        : this.$t("deactivated_extension");
    },

    shouldShowWorkBreakIntervals() {
      const AGENT_STATUSES = [AgentStatus.IDLE, AgentStatus.ON_CALL, AgentStatus.ACW, AgentStatus.ON_MANUAL_CALL_ACW];
      if (this.loggedCampaign) {
        return AGENT_STATUSES.includes(this.status) || (this.call && this.status === AgentStatus.CONSULT_CONNECTED);
      }

      return false;
    },

    shouldShowManualButton() {
      if (this.loggedCampaign) {
        return this.status === AgentStatus.IDLE && this.mode === "dialer";
      }

      return false;
    },

    shouldShowProgressBar() {
      if (this.loggedCampaign) {
        return this.status === AgentStatus.ON_WORK_BREAK || this.status === AgentStatus.ACW;
      }

      return false;
    },

    shouldShowQualificationComponent() {
      const isLoggedAgentBeingConsulted = !this.call && this.status === AgentStatus.CONSULT_CONNECTED;
      if (this.loggedCampaign) {
        return (
          [AgentStatus.ON_CALL, AgentStatus.ACW, AgentStatus.ON_MANUAL_CALL_ACW].includes(this.status) ||
          isLoggedAgentBeingConsulted
        );
      }

      return false;
    },

    shouldShowExitWorkBreak() {
      if (this.loggedCampaign) {
        const auto_start = this.intervalActive?.auto_start;
        return this.status === AgentStatus.ON_WORK_BREAK && !auto_start;
      }

      return false;
    },

    showManualAcwButton() {
      if (this.loggedCampaign) {
        return this.status === AgentStatus.ACW && this.mode === "dialer";
      }

      return false;
    },

    shouldShowExitManualButton() {
      if (this.loggedCampaign) {
        if (this.mode === "manual") {
          return this.status === AgentStatus.IDLE;
        }

        if (this.mode === "dialer") {
          return this.status === AgentStatus.ON_MANUAL_CALL_ACW;
        }
      }

      return false;
    },

    shouldShowTransferenceComponent() {
      const isReceptiveCall = this.status === AgentStatus.ON_CALL && this.call && this.call.call_mode === "receptive";
      const isLoggedAgentBeingConsulted = !this.call && this.status === AgentStatus.CONSULT_CONNECTED;

      return (isReceptiveCall && !this.isConsultHold) || isLoggedAgentBeingConsulted;
    },

    shouldShowIvrAfterCallButton() {
      const isReceptiveCalWithIvrAfterCall = !!(
        this.call &&
        this.call.call_mode === "receptive" &&
        this.call.ivr_after_call_id
      );

      const isCampaignWithIvrAfterCall = !!(
        this.loggedCampaign?.ivr_after_call_id &&
        this.loggedCampaign?.behavior == "when_agent_sends" &&
        this.loggedCampaign?.ivr_after_call_status
      );

      return (
        this.status === AgentStatus.ON_CALL &&
        ((isReceptiveCalWithIvrAfterCall && !this.isConsultHold) || isCampaignWithIvrAfterCall)
      );
    }
  },

  watch: {
    socketIOStatus(value) {
      this.showSocketDisconnection = value === "disconnected";
    }
  }
};
</script>

<style lang="scss" scoped>
.top-bar-wrapper {
  height: 56px;
  padding-left: 64px;
  position: sticky;
  top: 0;
  z-index: 1;

  .top-bar {
    box-shadow: 0px 12px 24px 0px rgba(18, 38, 63, 0.03);

    .inactive-message {
      left: 53%;
    }

    .middle-section {
      transform: translateX(-46px);

      @media (max-width: 1300px) {
        transform: translateX(-70px);
      }

      @media (max-width: 1200px) {
        transform: translateX(-106px);
      }
    }
  }
}
</style>
