<template>
  <div>
    <div class="row">
      <div class="col">
        <div class="form-group">
          <label class="form-label required">
            {{ $t('period') }}
          </label>
          <input-date-range
            :max-interval="31"
            v-model:start="filters.start_date"
            v-model:end="filters.end_date"
            :time-enabled="true"
            :seconds-enabled="true"
            format-date="d/m/Y H:i:S"
            min-date="2024-08-03"
          />
        </div>
      </div>

      <div
        v-if="avaliableFilters.includes('group-channels')"
        class="col"
      >
        <div class="form-group">
          <label class="form-label">
            {{ $t('group_channel') }}
          </label>
          <select-whatsapp-group-channels
            @select-group-channels="value => (filters.group_channel_ids = value)"
          />
        </div>
      </div>

      <div
        v-if="avaliableFilters.includes('agents')"
        class="col"
      >
        <div class="form-group">
          <label class="form-label">
            {{ $t('agents_capitalize') }}
          </label>
          <select-whatsapp-agents @select-agent="value => (filters.agents_ids = value)" />
        </div>
      </div>

      <div
        v-if="avaliableFilters.includes('teams')"
        class="col"
      >
        <div class="form-group">
          <label class="form-label">
            {{ $t('teams_capitalize') }}
          </label>
          <multi-select
            :list-data="teamsList"
            label="name"
            return-all-object
            v-model="teams"
          />
        </div>
      </div>

      <div
        class="col"
        style="margin-top: 2rem"
      >
        <app-button
          class="w-100"
          @click="submit"
          :disabled="!filters.start_date || !filters.start_date || loading"
        >
          {{ $t('search_report') }}
        </app-button>
      </div>
    </div>

    <div
      v-if="enableHideDays"
      class="col-12 mb-4"
    >
      <div class="form-check form-switch">
        <input
          class="form-check-input"
          type="checkbox"
          id="flexSwitchCheckDefault"
        >
        <label
          class="form-check-label"
          for="flexSwitchCheckDefault"
        >Ocultar dias </label>
      </div>
    </div>

    <div
      v-if="enableAdvancedFilters"
      class="col-12"
      style="margin: 1rem 0"
    >
      <div class="form-group">
        <a
          class="text-primary cursor-pointer"
          @click="showAdvancedFilters = !showAdvancedFilters"
        >
          {{ showAdvancedFilters ? "- Ocultar" : "+ Exibir" }} filtros avançados.
        </a>
      </div>
    </div>

    <div
      v-if="enableAdvancedFilters"
      v-show="showAdvancedFilters"
      class="row"
    >
      <div class="col-md-6 col-lg-4 col-xl-3">
        <div class="form-group">
          <label
            for="number"
            class="form-label"
          >
            Número
          </label>
          <input
            v-model="filters.number"
            id="number"
            type="text"
            class="form-control"
            v-maska
            data-maska="[
            '+## (##) ####-####',
            '+## (##) #####-####'
          ]"
            placeholder="+55 (00) 00000-0000"
          >
        </div>
      </div>

      <div class="col-md-6 col-lg-4 col-xl-3">
        <div class="form-group">
          <label class="form-label">
            Canal
          </label>
          <vue-multiselect
            v-model="filters.instance_id"
            id="channel"
            label="name"
            track-by="id"
            placeholder="Selecione um canal"
            select-label="Clique ou pressione enter para selecionar"
            deselect-label="Clique ou pressione enter para remover"
            selected-label="Selecionado"
            :multiple="true"
            :close-on-select="false"
            :preserve-search="true"
            :options="channels"
          />
        </div>
      </div>

      <div class="col-md-6 col-lg-4 col-xl-3">
        <div class="form-group">
          <label class="form-label">
            Status
          </label>
          <select
            class="form-select"
            v-model="filters.status"
          >
            <option
              v-for="(item, index) in status"
              :key="index"
              :value="item.value"
            >
              {{ item.name }}
            </option>
          </select>
        </div>
      </div>

      <div class="col-md-6 col-lg-4 col-xl-3">
        <label class="form-label">
          Qualificação
        </label>
        <select
          class="form-select"
          v-model="filters.qualification"
          :disabled="filters.group_channel_ids === ''"
        >
          <option value="">
            Selecione uma qualificação
          </option>
          <option
            v-for="qualification in qualifications"
            :key="qualification.id"
            :value="qualification.id"
          >
            {{ qualification.name }}
          </option>
        </select>
      </div>

      <div class="col-md-6 col-lg-4 col-xl-3">
        <div class="form-group">
          <label class="form-label">
            Protocolo
          </label>
          <input
            type="text"
            class="form-control"
            v-model="filters.protocol_number"
            placeholder="12345678901234567"
          >
        </div>
      </div>

      <div class="col-md-6 col-lg-4 col-xl-3 d-flex align-items-center">
        <div class="form-check form-switch">
          <input
            type="checkbox"
            class="form-check-input"
            v-model="filters.transferred"
          >
          <label class="form-check-label">
            Apenas conversas transferidas
          </label>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import InputDateRange from "@components/input-date-range.vue";
import SelectWhatsappAgents from "@dialer/manager/components/select-whatsapp-agents.vue";
import AppButton from "@components/app-button.vue";
import VueMultiselect from "vue-multiselect";
import SelectWhatsappGroupChannels from "@dialer/manager/components/select-whatsapp-group-channels.vue";
import MultiSelect from '@components/select/multi-select.vue'
import {mapActions, mapGetters} from 'vuex'

export default {
  name: "MenuSearch",

  emits: ["searched", "updateFilters"],

  props: {
    avaliableFilters: { type: Array, default: () => ["group-channels", "agents"] },
    enableHideDays: { type: Boolean, default: false },
    enableAdvancedFilters: { type: Boolean, default: false },
    loading: { type: Boolean, default: false }
  },

  components: {
    MultiSelect,
    SelectWhatsappGroupChannels,
    SelectWhatsappAgents,
    AppButton,
    InputDateRange,
    VueMultiselect
  },

  data() {
    return {
      teams: [],
      filters: {
        teams_ids: [],
        start_date: null,
        end_date: null,
        group_channel_ids: "",
        agents_ids: "",
        number: "",
        instance_id: [],
        status: "",
        qualification: "",
        protocol_number: null,
        transferred: false
      },
      showAdvancedFilters: false
    };
  },

  mounted(){
    this.fillTeamsList()
  },
  computed: {
    ...mapGetters("manager/teams", {
      teamsList: "getTeams"
    }),

    qualifications() {
      let idsArray = this.filters.group_channel_ids;
      let addedQualificationListsId = [];
      let qualifications = [];

      this.groupChannels.forEach(groupChannel => {
        if (idsArray.includes(groupChannel.id.toString())) {
          const listId = groupChannel.team.data.qualification_list_id;

          if (!addedQualificationListsId.includes(listId)) {
            addedQualificationListsId.push(listId);
            this.qualificationsData[listId].forEach(qualification => {
              qualifications.push(qualification);
            });
          }
        }
      });

      return qualifications;
    }
  },

  methods: {
    ...mapActions("manager/teams", ["fetchTeams"]),

    submit() {
      this.$emit("searched", true);
      this.$emit("updateFilters", this.parseFilters({...this.filters}));
    },

    async fillTeamsList() {
      await this.fetchTeams();
    },

    parseFilters(filters) {
      if (this.enableAdvancedFilters) {
        filters.number = filters.number.replace(/\D/g, "");
      }

      if (filters.instance_id) {
        filters.instance_id = filters.instance_id.map(channel => channel.id).join(",");
      }
      if (this.teams) {
        filters.teams_ids = this.teams.map(team => team.id);
      }

      for (const filter in filters) {
        if (!filters[filter]) {
          delete filters[filter];
        }
      }
      return filters;
    }
  }
};
</script>