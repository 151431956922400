<template>
  <div class="col-12">
    <header-component
      title="Feedbacks"
      :pretitle="$t('settings_uppercase')"
      redirect-link="/manager/voice-settings"
    >
      <template #buttons>
        <button
          class="btn btn-primary px-6"
          @click="$router.push('/manager/feedbacks/new')"
        >
          {{ $t('add_new_standard') }}
        </button>
      </template>
    </header-component>

    <div class="input-group input-group-merge input-group-reverse mb-4">
      <input
        class="form-control"
        v-model="term"
        :placeholder="$t('search_feedback_groups')"
        @keyup="search"
        type="text"
      >
      <div class="input-group-text">
        <span class="fe fe-search" />
      </div>
    </div>

    <div class="row">
      <div class="col-xl-12 col-lg-12 col-md-12 col-sm-6">
        <div
          class="card card-new"
          @click="$router.push('/manager/feedbacks/new')"
        >
          <div class="card-body">
            <div class="row align-items-center gx-0">
              <div
                class="col overflow-ellipsis"
                style="line-height: 36.19px"
              >
                <span class="h5 mb-0"> {{ $t('new_group') }}</span>
              </div>
              <div class="col-auto">
                <span class="h2 fe fe-plus text-primary mb-0" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-if="loading.loadFeedbacks">
        <div class="card-body text-center">
          <div class="spinner-border text-muted">
            <span class="visually-hidden" />
          </div>
        </div>
      </div>

      <div
        class="col-xl-4 col-lg-4 col-md-4 col-sm-6"
        v-for="(item, i) in dataSelect"
        :key="i"
      >
        <div
          class="card card-list-item"
          style="cursor: pointer"
          @click="$router.push({ path: `/manager/feedbacks/${item.id}/edit` })"
        >
          <div class="card-body">
            <div class="row align-items-center gx-0">
              <div class="col overflow-ellipsis">
                <div
                  class="col overflow-ellipsis one-line"
                  v-if="item.criteria.length < 1"
                >
                  <span class="h5 mb-0"> {{ item.name }}</span>
                </div>

                <template v-else>
                  <span class="h5 mb-0"> {{ item.name }}</span>
                  <br>
                  <small class="text-muted">{{ item.criteria.length }} Feedback{{
                    item.criteria.length > 1 ? "s" : ""
                  }}
                  </small>
                </template>
              </div>
              <div class="col-auto">
                <span class="h2 fe fe-chevron-down text-primary mb-0" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-12">
        <paginator
          :pagination="pagination"
          @change-page="changePage"
          v-if="!loading.loadFeedbacks"
        />
      </div>
    </div>
  </div>
</template>


<script>
import { mapActions, mapGetters } from "vuex";
import _ from "lodash";
import Paginator from "@/components/app-paginator.vue";
import HeaderComponent from "@/components/header-component.vue";

export default {
  mounted() {
    this.loadFeedbacks();
  },
  components: {
    Paginator,
    HeaderComponent
  },
  computed: {
    ...mapGetters("manager/criterion", {
      dataSelect: "getDataSelect",
      loading: "getLoadingFlags",
      pagination: "getPagination",
    }),
  },
  methods: {
    ...mapActions("manager/criterion", ["setFilters", "loadFeedbacks", "setCurrentPage"]),
    search: _.debounce(function (term) {
      this.setFilters({
        search: term.target.value,
      });
      this.loadFeedbacks();
    }, 500),
    changePage(page) {
      this.setCurrentPage(page);
      this.loadFeedbacks();
    },
  },
};
</script>
