<template>
  <div class="card card-fill">
    <div
      class="card-header cursor-pointer"
      @mousedown="redirectUserTo($event)"
    >
      <div class="d-flex align-items-center">
        <div class="custom-icon d-flex align-items-center justify-content-center rounded-1 me-3">
          <i :class="['fa-regular', icon, 'text-primary']" />
        </div>
        <h2 class="card-title m-0">
          {{ title }}
        </h2>
        <i class="fa-regular fa-chevron-right ms-auto tx-idle" />
      </div>
    </div>

    <div
      class="card-body px-0 pt-0 pb-0 mt-0"
    >
      <div class="table-responsive">
        <table class="table m-0">
          <thead>
            <tr>
              <th
                scope="col"
                colspan="2"
              >
                {{ $t('campaign_capitalize') }}
              </th>
              <th
                scope="col"
                class="text-truncate"
              >
                {{ $t('today_cal') }}
              </th>
              <th />
            </tr>
          </thead>
          <tbody>
            <tr
              class="cursor-pointer"
              @mousedown="redirectUserTo($event, item.id)"
              v-for="item in data"
              :key="item.name"
            >
              <td class="py-2 pe-0">
                <div
                  class="status mx-auto"
                  :class="{
                    active: item.agents_with_online_status > 0
                  }"
                />
              </td>
              <td class="py-2 ps-1 text-truncate overflow-ellipsis">
                {{ item.name }}
              </td>
              <td class="fw-bold text-large py-2">
                {{ item.calls_today }}
              </td>
              <td class="pe-4 py-2">
                <div class="d-flex align-items-center justify-content-end">
                  <i class="fa-regular fa-chevron-right tx-idle" />
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DefaultCard',
  props: {
    title: {
      type: String,
      required: true
    },
    icon: {
      type: String,
      required: true
    },
    redirectLink: {
      type: String,
      required: true
    },
    data: {
      type: Array,
      required: true
    }
  },

  methods: {
    redirectUserTo(event, id) {
      console.log(event, id)
      if (id) {
        if (event.button === 0) {
          this.$router.push(`/manager/${this.redirectLink}/${id}`);
        }
        if (event.button === 1) {
          window.open(`/manager/${this.redirectLink}/${id}`, '_blank');
        }
      } else {
        if (event.button === 0) {
          this.$router.push(`/manager/${this.redirectLink}`);
        }
        if (event.button === 1) {
          window.open(`/manager/${this.redirectLink}`, '_blank');
        }
      }
    }
  }
};
</script>

<style scoped lang="scss">
.card-header {
  transition: 0.2s;
  &:hover {
    background-color: $color-gray-blue-300;
    .fa-chevron-right {
      color: var(--color-blue-300);
    }
  }

  .custom-icon {
    width: 40px;
    height: 40px;
    background-color: $color-gray-blue-300;
  }
}

.card-body {
  tr {
    transition: 0.2s;
    &:hover {
      background-color: $color-gray-blue-300;
      .fa-chevron-right {
        color: var(--color-blue-300);
      }
    }

    td {
      &:first-child {
        width: 50px;

        // Get the next element after the first child
        & + td {
          max-width: 9rem;
        }
      }
    }

    .status {
      width: 0.75rem;
      height: 0.75rem;
      border-radius: 50%;
      background-color: var(--color-gray-200);

      &.active {
        background-color: var(--color-blue-300);
        animation-name: active-campaign;
        animation-duration: 0.8s;
        animation-iteration-count: infinite;
        animation-direction: normal;
      }

      @keyframes active-campaign {
        from {
          box-shadow: none;
        }

        to {
          box-shadow: 0 0 0 3px #7e9cf7;
        }
      }
    }
  }
}
</style>
