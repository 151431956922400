import store from "@store/index"
import logo from "@assets/img/logo.svg";
import audioNotification from "@assets/audio/web_whatsapp.mp3";

const WhatsappNotificationPlayer = new Audio(audioNotification);

export default class UserSocket {
  #user = {};
  #socket = null;

  constructor(user, socket) {
    this.setUser(user);
    this.setSocket(socket);
  }

  setUser(user) {
    this.#user = user;
  }

  setSocket(socket) {
    this.#socket = socket;
  }

  getUser() {
    return this.#user;
  }

  hasAgentId(chat) {
    return Boolean((chat?.agent_id || chat?.agent?.id))
  }

  init() {
    if (this.getUser()) {
      if (this.isManager() || this.isAgent() || this.isSupervisor()) {
        this.newAgentChatWhatsApp();
        this.messageAckWhatsApp();
        this.transferChatWhatsApp();
        this.newWhatsAppInternalMessage();
        this.startSnoozeChatWhatsApp();
        this.newAgentMessageWhatsApp();
        this.finishChatWhatsApp();
        this.newGroupChatWhatsApp();
        this.newMessageWhatsApp();
        this.newQueueWhatsAppChat();
        this.queueChatResponseIsLate();
        this.clientResponseIsLate();
        this.chatAcceptedWhatsApp();
        this.queueChatResponseIsLate();
        this.deleteAgentMessageWhatsApp();
      }

      if (this.isManager()) {
        this.newChatWhatsApp();
        this.whatsAppInstanceStatusChanged();
      }

      if (this.isAgent()) {
        this.endSnoozeChatWhatsApp();
        this.newMessageTemplateWhatsApp();
        this.chatEndedConversationCycle();
        this.whatsAppGroupMessageSent();
      }
    }
  }

  isManager() {
    return this.getUser()?.role?.name === 'manager';
  }

  isSupervisor() {
    return this.getUser()?.role?.name === 'supervisor';
  }

  isAgent() {
    return this.getUser()?.role?.name === 'agent';
  }

  verifySearch({name, name_alias, number, filterSearch}) {
    const matchName = name.includes(filterSearch)
    const matchNameAlias = name_alias && name_alias.includes(filterSearch)
    const matchNumber = number.toString().includes(filterSearch) || filterSearch === ""

    return matchName || matchNameAlias || matchNumber
  }

  verifyFilter({name, name_alias, number}, team_id, group_channel_id, agent_id, contact_tags) {
    const filters = store.getters['whatsapp/chat/getFilters']
    const matchSearch = this.verifySearch({name, name_alias, number, filterSearch: filters.search});
    const matchTeam = (filters.team_id === null || filters.team_id === '') || filters.team_id === team_id
    const matchAgent = agent_id ? filters.agent_id === null || agent_id === filters.agent_id : true
    const matchGroupChannel = filters.group_channel_id === null || filters.group_channel_id === group_channel_id
    const matchTags = !filters.tags_ids.length || contact_tags.some(contactTag => filters.tags_ids.includes(contactTag.tag_id))

    return matchSearch && matchTeam && matchGroupChannel && matchAgent && matchTags
  }

  verifySearchFilter({name, name_alias, number}, contact_tags) {
    const {search, tags_ids} = store.getters['whatsapp/chat/getFilters'];

    const matchSearch =
      !search ||
      name.includes(search) ||
      name_alias.includes(search) ||
      number.toString().includes(search);

    const matchTags =
      !tags_ids.length ||
      contact_tags.some(contactTag => tags_ids.includes(contactTag.tag_id));

    return matchSearch && matchTags
  }

  newAgentMessageWhatsApp() {
    this.#socket.on("new-agent-message-whatsapp", event => {
      const message = event.message;
      const chat = event.chat;
      const currentChat = store.getters['whatsapp/chat/getCurrentChat'][chat.id];

      store.commit("whatsapp/chat/removeChatFromSnooze", {
        id: message.chat_id
      });

      if (message.chat_id === currentChat?.id && (message.agent.id !== this.getUser().id || message?.agent_id !== this.getUser().id)) {
        store.commit("whatsapp/chat/addRecentMessage", {message});
      }

      if (chat.chatbot.is_active && this.verifyFilter(chat.contact, chat.team_id, chat.instance.group_channel_id, null, chat.contact_tags)) {
        store.commit("whatsapp/chat/updateInChatbotTalkOrder", chat)
        return
      }
      if (chat.is_group && this.verifyFilter(chat.contact, chat.team_id, chat.instance.group_channel_id, null, chat.contact_tags)) {
        if (currentChat.queue_response_is_late && currentChat.queue_response_is_late.response_is_late) {
          store.dispatch("whatsapp/chat/fetchVerifyNotifiedChats", "queue")
        }

        store.commit("whatsapp/chat/updateGroupTalkOrder", chat);
        return
      }
      if (!chat.is_group && this.verifyFilter(chat.contact, chat.team_id, chat.instance.group_channel_id, chat.agent_id, chat.contact_tags)) {
        if (currentChat.lag_to_response && currentChat.lag_to_response.response_is_late) {
          store.commit("whatsapp/chat/setRemoveUnanswerMark", chat.id, {root: true});
          store.dispatch("whatsapp/chat/fetchVerifyNotifiedChats", "in_progress")
        }
        if (event.chat.agent_id) {
          store.commit("whatsapp/chat/updateInProgressTalkOrder", chat);
        }
      }
    });
  }

  newMessageWhatsApp() {
    this.#socket.on("new-message-whatsapp", event => {
      let currentChat = store.getters['whatsapp/chat/getCurrentChat'][event.chat.id]
      let openChat = store.getters['whatsapp/chat/getOpenChat']
      let message = event.message
      let currentUser = store.getters['auth/getUser']

      store.commit("whatsapp/chat/removeChatFromSnooze", {
        id: message.chat_id
      });

      if (this.isManager()) {
        const chat = event.chat;
        if (message.type === "transfer" && !this.hasAgentId(chat)) {
          store.commit("whatsapp/chat/removeChatById", {id: chat.id});
          store.commit("whatsapp/chat/addChatToQueue", event.chat)
        } else if (chat && !chat?.chatbot?.is_active && this.hasAgentId(chat)) {
          if (chat.is_group && this.verifyFilter(chat.contact, chat.team_id, chat.instance.group_channel_id, null, chat.contact_tags)) {
            store.commit("whatsapp/chat/updateGroupTalkOrder", chat)
            if (chat.unread === 1 && (openChat === null || message.chat_id !== openChat.id)) {
              store.commit("whatsapp/chat/incrementUnreadGroupsConversation")
            }
          } else if (!chat.is_group && this.verifyFilter(chat.contact, chat.team_id, chat.instance.group_channel_id, chat.agent_id, chat.contact_tags)) {
            store.commit("whatsapp/chat/updateInProgressTalkOrder", chat);
            if (chat.unread === 1 && (openChat === null || message.chat_id !== openChat?.id)) {
              store.commit("whatsapp/chat/incrementUnreadConversation")
            }
          }
        }

        if (currentChat && message.chat_id === currentChat.id) {
          store.commit("whatsapp/chat/setRemovedFromTheGroup", event);
          store.commit('whatsapp/chat/updateWabaMessageReceived', {
            chat_id: currentChat.id, value: event.chat.waba_message_received
          })
        }

        if (message.type !== "transfer") {
          if (currentChat && message.chat_id === currentChat.id) {
            store.commit("whatsapp/chat/addRecentMessage", {message})
          }

          if (!openChat && message.chat_id !== openChat?.id) {
            store.commit("whatsapp/chat/incrementUnreadMessagesCount", {message})
            store.commit("whatsapp/chat/incrementGroupsUnreadMessagesCount", {message})
          }
        }

        // Notifies the agent if the chat isn't in the queue
        if (Notification.permission === "granted" && (message.chat_id !== openChat?.id || document.hidden) && chat.agent_id && chat.agent_id === currentUser.id) {
          //TODO dados do contato???
          new Notification("3C Plus - Omnichannel", {
            body: message.body, icon: logo, silent: true,
          });

          WhatsappNotificationPlayer.volume = 0.1;
          WhatsappNotificationPlayer.play();
        }
      }

      if (this.isAgent()) {
        store.commit('whatsapp/chat/updateWabaMessageReceived', {
          chat_id: event?.chat?.id, value: event?.chat?.waba_message_received
        })

        if (this.verifySearchFilter(event.chat.contact, event.chat.contact_tags)) {
          if (event.chat.is_group === true) {
            store.commit("whatsapp/chat/updateGroupTalkOrder", event.chat);
            if (event.chat.unread === 1 && (!openChat || message.chat_id !== openChat.id)) {
              store.commit("whatsapp/chat/incrementUnreadGroupsConversation")
            }
          }
          if (event.chat.is_group === false && event.chat.agent_id) {
            store.commit("whatsapp/chat/updateInProgressTalkOrder", event.chat);
            if (event.chat.unread === 1 && (!openChat || event.chat.id !== openChat?.id)) {
              store.commit("whatsapp/chat/incrementUnreadConversation")
            }
          }
        }

        if (currentChat?.isReadOnly) {
          store.commit("whatsapp/chat/toggleInGroup", event)
        }

        if (message.type !== "transfer") {
          if (currentChat && message.chat_id === currentChat.id) {
            store.commit("whatsapp/chat/addRecentMessage", {message})
          }

          if (!openChat || message.chat_id !== openChat.id) {
            store.commit("whatsapp/chat/incrementUnreadMessagesCount", {message});
            store.commit("whatsapp/chat/incrementGroupsUnreadMessagesCount", {message});
          }
        }

        if (message.chat_id === openChat?.id && message.body !== 'Tempo de espera excedido') {
          store.dispatch("whatsapp/chat/clearUnreadMessageCount", {id: message.chat_id})
        }
        if (message.isExternal) {
          store.commit("whatsapp/chat/updateMessageACK", event.message);
        }

        // Notifies the agent if the chat isn't in the queue
        if (Notification.permission === "granted" && (message.chat_id !== openChat?.id || document.hidden) && event.chat.agent_id && event.chat.agent_id === currentUser.id) {
          //TODO dados do contato???
          new Notification("3C Plus - Whatsapp", {
            body: message.body, icon: logo, silent: true,
          });

          WhatsappNotificationPlayer.volume = 0.1;
          WhatsappNotificationPlayer.play();
        }
      }
    });
  }

  newAgentChatWhatsApp() {
    this.#socket.on("new-agent-chat-whatsapp", event => {
      if (this.isManager()) {
        const chat = event.chat;
        const agentId = !chat.is_group ? chat.agent_id : null;
        const contactTags = chat.contact_tags || [];

        if (this.verifyFilter(chat.contact, chat.team_id, chat.instance.group_channel_id, agentId, contactTags)) {
          store.commit("whatsapp/chat/updateInProgressTalkOrder", event.chat)
        }
      }

      if (this.isAgent()) {
        if (this.verifySearchFilter(event.chat.contact, event.chat.contact_tags || [])) {
          store.commit("whatsapp/chat/updateInProgressTalkOrder", event.chat)
        }
      }
    });
  }

  messageAckWhatsApp() {
    this.#socket.on("message-ack-whatsapp", (event) => {
      const currentChat = store.getters['whatsapp/chat/getCurrentChat'][event.message.chat_id];
      store.commit("whatsapp/chat/updateMessageACK", event.message);
      store.commit('whatsapp/chat/updateNewMessageTemplateWhatsapp', {
        chat: currentChat,
      })
    });
  }

  transferChatWhatsApp() {
    this.#socket.on("transfer-chat-whatsapp", ({agentId, chat, message}) => {
      const updateCurrentChatOwner = {
        currentChatId: chat?.id,
        userId: null,
        userName: null
      }
      if (!agentId) {
        store.commit("whatsapp/chat/removeChatFromQueue", chat)
      }

      if (this.hasAgentId(chat)) {
        updateCurrentChatOwner.userId = chat?.agent_id || chat?.agent?.id;
        updateCurrentChatOwner.userName = chat?.agent_name || chat?.agent?.name;
      }

      if (this.isManager()) {
        store.commit("whatsapp/chat/updateCurrentChatOwner", updateCurrentChatOwner);
        return true;
      }

      if (this.isAgent()) {
        const currentChatOwner = message.inter_message_data.current_agent;
        const previousChatOwner = message.inter_message_data.previous_agent;

        const currentUser = store.getters["auth/getUser"];
        const instances = store.getters["whatsapp/instance/getInstances"]
        const openChat = store.getters['whatsapp/chat/getOpenChat']

        if (!previousChatOwner && !chat.agent_id && agentId) {
          store.commit("whatsapp/chat/addChatToQueue", chat);
          store.commit("whatsapp/chat/setCurrentChat", chat);
          return
        }
        if (currentUser.id === previousChatOwner.id) {
          store.commit("whatsapp/chat/removeChatById", {id: chat.id});

          if (openChat?.id === chat.id && message.agent.role.data.name !== "agent") {
            store.commit("whatsapp/chat/setTransferedByManager", chat);
          } else if (chat.unread >= 1) {
            store.commit("whatsapp/chat/decrementUnreadConversation")
          }
        }

        if (chat.agent_id && currentUser.id === currentChatOwner.id) {
          store.commit("whatsapp/chat/updateInProgressTalkOrder", chat);
        } else if (!chat.agent_id && instances.some(instance => instance.id === currentChatOwner.id)) {
          store.commit("whatsapp/chat/addChatToQueue", chat);
          store.commit("whatsapp/chat/setCurrentChat", chat);
        }

        return true
      }
    });
  }

  newWhatsAppInternalMessage() {
    this.#socket.on("new-whatsapp-internal-message", event => {
      const currentChat = store.getters['whatsapp/chat/getCurrentChat'][event.message.chat_id];
      const message = event.message;
      if(message.type==='internal-message' && currentChat && message.chat_id === currentChat.id && message?.agent_id !== this.getUser().id){
        store.commit("whatsapp/chat/addRecentMessage", {message})
      }
      if (this.isManager()) {
        if ((event.message.type === "transfer" || event.message.type === "protocol-message") && this.hasAgentId(event.chat)) {
          store.commit("whatsapp/chat/addChatToInProgress", {
            chat: event.chat,
            agent_id: event.chat.agent_id || event.chat?.agent?.id
          });
        }
      }
    });
  }

  startSnoozeChatWhatsApp() {
    this.#socket.on("start-snooze-chat-whatsapp", (event) => {
      const openChat = store.getters['whatsapp/chat/getOpenChat']
      if (this.isAgent() && openChat?.id === event.chat.id) {
        store.commit("whatsapp/chat/setSnoozedByManager", event.chat);
        store.commit('whatsapp/chat/setChatAsInSnooze', {
          chat_id: event.chat.id, end_snooze: event.chat.end_snooze,
        });
      }

      if (this.isManager()) {
        store.commit('whatsapp/chat/setChatAsInSnooze', {
          chat_id: event.chat.id, end_snooze: event.chat.end_snooze,
        });
      }
    });
  }

  #isOpenedChat(openChat, eventChat) {
    const isOpened = openChat && (openChat.id === eventChat.id);
    const isFinished = openChat && (openChat.finished && openChat.number + openChat.instance_id === eventChat.id);
    return {
      isOpened, isFinished, both: isOpened || isFinished,
    };
  }

  finishChatWhatsApp() {
    this.#socket.on("finish-chat-whatsapp", (event) => {
      const openChat = store.getters['whatsapp/chat/getOpenChat']

      store.commit("whatsapp/chat/removeInProgress", event.chat)
      store.commit("whatsapp/chat/decrementUnreadConversation")
      store.commit("whatsapp/chat/removeChatById", {id: event.chat.id});


      if (this.isManager()) {
        store.commit("whatsapp/chat/updateFinished", event.chat)

        if (this.#isOpenedChat(openChat, event.chat).both) {
          event.chat.id = event.chat._id
          store.commit("whatsapp/chat/setOpenChat", event.chat)
        }
      }

      if (this.isAgent()) {
        event.chat.finished = true;

        store.commit('whatsapp/chat/clearFinished')
        store.dispatch("whatsapp/chat/findFinished")

        if (this.#isOpenedChat(openChat, event.chat).isOpened) {
          store.commit("whatsapp/chat/setFinalizedByManager", event.chat)
        }

        if (this.#isOpenedChat(openChat, event.chat).isFinished) {
          event.chat.id = event.chat._id
          store.commit("whatsapp/chat/setOpenChat", event.chat)
          store.commit("whatsapp/chat/setCurrentChat", event.chat)
        }
      }
    });
  }

  newGroupChatWhatsApp() {
    this.#socket.on("new-group-chat-whatsapp", (event) => {
      store.commit("whatsapp/chat/addGroupsByNewGroupEvent", event.chat)
      store.commit("whatsapp/chat/updateGroupTalkOrderByEvent", event.chat)
    });
  }

  newQueueWhatsAppChat() {
    this.#socket.on("new-queue-chat-whatsapp", event => {
      if (event.chat.chatbot.is_active) {
        if (this.isAgent()) {
          return true
        }
        store.commit("whatsapp/chat/addChatToInChatbot", event.chat)
      } else {
        store.commit("whatsapp/chat/removeFromCurrentChat", event.chat)
        store.commit("whatsapp/chat/addChatToQueue", event.chat)
        store.commit("whatsapp/chat/setCurrentChat", event.chat)
      }
    });
  }

  clientResponseIsLate() {
    this.#socket.on("client-response-is-late", (event) => {
      store.commit("whatsapp/chat/setClientResponseLate", event)
      if (this.isManager()) {
        store.dispatch("whatsapp/chat/findInProgress")
      }
    });
  }

  chatAcceptedWhatsApp() {
    this.#socket.on("chat-accepted-whatsapp", event => {
      store.commit("whatsapp/chat/removeChatFromQueue", event.chat);

      if (event.chat?.unread) {
        store.commit("whatsapp/chat/incrementUnreadConversation");
      }

      const currentChat  = store.getters["whatsapp/chat/getCurrentChat"][event.chat.id]
      const chat = currentChat ? Object.assign(currentChat, event.chat) : event.chat;
      if (this.isManager()) {
        store.commit("whatsapp/chat/addChatToInProgress", {
          chat: chat, agent_id: (event.agent.id || event?.agent_id)
        });
      }
    });
  }

  newChatWhatsApp() {
    this.#socket.on("new-chat-whatsapp", event => {
      if (this.isManager()) {
        const chat = event.chat;
        const agentId = !chat.is_group ? chat.agent_id : null;
        const contactTags = chat.contact_tags || [];

        if (this.verifyFilter(chat.contact, chat.team_id, chat.instance.group_channel_id, agentId, contactTags)) {
          store.commit("whatsapp/chat/removeFromCurrentChat", event.chat);
          store.commit("whatsapp/chat/addChatToQueue", event.chat);
          store.commit("whatsapp/chat/setCurrentChat", event.chat);
        }
      }
    });
  }

  whatsAppInstanceStatusChanged() {
    this.#socket.on("whatsapp-instance-status-changed", (event) => {
      if (this.isManager()) {
        const instance = event.instance
        store.commit("manager/whatsapp_group_channel/setInstanceStatusInGroupChannel", {
          groupChannelId: instance.group_channel_id, instanceId: instance.id, status: instance.status,
        })
      }
    });
  }

  queueChatResponseIsLate() {
    this.#socket.on("queue-chat-response-is-late", (event) => {
      store.commit("whatsapp/chat/setClientResponseLateQueue", event)
      if (this.isManager()) {
        store.dispatch("whatsapp/chat/findQueue")
      }
    });
  }

  endSnoozeChatWhatsApp() {
    this.#socket.on("end-snooze-chat-whatsapp", (event) => {
      if (this.isAgent()) {
        store.commit("whatsapp/chat/removeChatFromSnooze", event.chat);
        store.commit("whatsapp/chat/updateInProgressTalkOrder", event.chat);
      }
    });
  }

  deleteAgentMessageWhatsApp() {
    this.#socket.on("delete-agent-message-whatsapp", (event) => {
      const data = event.message;
      const chat = event.chat;
      const currentChat = store.getters['whatsapp/chat/getCurrentChat'][chat.id];
      if (currentChat?.id) {
        store.commit("whatsapp/chat/markMessageAsDeleted", {data, chat_id: data.chat_id});
      }
    })
  }

  newMessageTemplateWhatsApp() {
    this.#socket.on('new-message-template-whatsapp', event => {
      if (this.isAgent()) {
        store.commit('whatsapp/chat/updateNewMessageTemplateWhatsapp', {
          chat: event.chat,
        })
      }
    })
  }

  chatEndedConversationCycle() {
    this.#socket.on('chat-ended-conversation-cycle', event => {
      if (this.isAgent()) {
        store.commit('whatsapp/chat/updateNewMessageTemplateWhatsapp', {
          chat: event.chat,
        })
      }
    })
  }

  whatsAppGroupMessageSent() {
    this.#socket.on("whatsapp-group-message-sent", event => {
      if (this.isAgent()) {
        const message = event.message;
        const user = store.getters["auth/getUser"];
        const currentChat = store.getters["whatsapp/chat/getCurrentChat"][message.chat_id];

        if ((message.agent.id !== user.id || message?.agent_id !== user.id) && message.chat_id === currentChat?.id) {
          store.commit("whatsapp/chat/addRecentMessage", {message: message});
        }

        const groups = store.getters["whatsapp/chat/getGroups"]
        if (groups[event.message.chat_id]) {
          const chat = groups[event.message.chat_id]
          store.commit("whatsapp/chat/updateGroupTalkOrder", chat);
          store.commit("whatsapp/chat/setChatLastMessage", {chatType: "groups", message: event.message})
        }
      }
    });
  }

  groupMessageSent() {
    this.#socket.on("group-message-sent", () => {
    });
  }
}


