<template>
  <div
    ref="canvas"
    class="offcanvas offcanvas-end"
  >
    <div class="offcanvas-body">
      <h2 class="text-center mb-2">
        {{ this.mode === "new" ? $t('new_message') : $t('edit_message') }}
      </h2>
      <hr class="mb-4">
      <form @submit.prevent="sendFormData">
        <div class="form-group">
          <div class="form-label">
            {{ $t('shortcut') }}
          </div>

          <!--
            Allow only letters,numbers and spaces
            with an '/' at the begining of the input value
          -->
          <input
            v-maska
            data-maska="/A"
            data-maska-tokens="A:[a-zA-Z0-9\s]:multiple"
            type="text"
            maxlength="26"
            :class="{
              'form-control': true,
              'is-invalid': errors.shortcut,
            }"
            name="shortcut"
            id="shortcut"
            v-model="fields.shortcut"
          >
          <div class="invalid-feedback">
            {{ errors.shortcut && errors.shortcut[0] }}
          </div>
        </div>

        <div class="form-group">
          <div class="form-label">
            {{ $t('message') }}
          </div>
          <div class="textbox-wrapper border rounded p-3">
            <textarea
              :class="{
                'form-control message border-0 p-0': true,
                'is-invalid': errors.message,
              }"
              rows="8"
              :placeholder="$t('type_here_placeholder')"
              maxlength="1000"
              v-model="fields.message"
            />
            <div class="invalid-feedback">
              {{ errors.message && errors.message[0] }}
            </div>
            <span class="text-muted">
              {{ fields.message.length }}/{{ MAXIMUM_MESSAGE_LENGTH }}
            </span>
          </div>
        </div>

        <app-button
          class="btn btn-primary w-100"
          :loading="loading_context['create_quick_message'] ||
            loading_context['update_quick_message']"
        >
          {{ mode === "new" ? $t('save_general') : $t('edit') }}
        </app-button>
      </form>
    </div>
  </div>
</template>

<script>
import { Offcanvas } from 'bootstrap';

import AppButton from "@components/app-button.vue";
import { mapActions, mapGetters, mapMutations } from 'vuex';

export default {
  data() {
    return {
      MAXIMUM_MESSAGE_LENGTH: 1000,
      instance_id: 0,
      message: null,
      fields: {
        group_channel_id: null,
        shortcut: "/",
        message: "",
      },
      offcanvas: null,
      mode: "new",
    }
  },
  mounted() {
    this.instance_id = this.$route.params.id;
    this.offcanvas = new Offcanvas(this.$refs.canvas);
  },
  components: {
    AppButton
  },
  computed: {
    ...mapGetters("manager/quick_messages", {
      loading_context: "getLoadingContext",
      errors: "getErrors"
    }),
  },
  methods: {
    ...mapActions("manager/quick_messages", [
      "createWhatsappQuickMessage",
      "updateWhatsappQuickMessage",
      "loadWhatsappQuickMessageList"
    ]),
    ...mapMutations("manager/quick_messages", ["clearErrors"]),
    new() {
      this.clearErrors();
      this.mode = "new";
      this.fields = {
        group_channel_id: this.$route.params.id,
        shortcut: "/",
        message: "",
      };
      this.offcanvas.show();
    },
    edit(message) {
      this.clearErrors();
      this.mode = "edit";
      this.message = message;
      this.fields = {
        group_channel_id: this.$route.params.id,
        shortcut: message.shortcut,
        message: message.message,
      };
      this.offcanvas.show();
    },
    sendFormData() {
      if(this.mode === "new") {
        this.createWhatsappQuickMessage({
          fields: this.fields
        }).then(() => {
          this.offcanvas.hide();
          this.loadWhatsappQuickMessageList({
            id: this.$route.params.id,
          })
        });
      } else if (this.mode === "edit") {
        this.updateWhatsappQuickMessage({
          id: this.message.id,
          fields: this.fields,
        }).then(() => {
          this.offcanvas.hide();
          this.loadWhatsappQuickMessageList({
            id: this.$route.params.id,
          })
        });
      }
    },
  }
}
</script>

<style lang="scss">
.message {
  resize: none;
}
</style>
