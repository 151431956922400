export default {
  loadingFlags: {
    sendForward: false,
    openIaSuggest: false,
    openIaImprove: false,
  },

  disableAutoScroll: false,

  improvedMessage: "",
  SuggestedMessage: "",
}
