<template>
  <action-input
    :is-creation="isCreation"
    :readonly="readonly"
    :errors="errors"
    placeholder="-"
    :input-type="inputAnswer.mask === 'date' ? 'date' : 'textarea'"
    v-model="answerField"
    @update:model-value="updateField"
  />
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import ActionInput from "@crm/pages/opportunity/components/action-input.vue";

export default {
  name: "InputAnswer",
  components: {
    ActionInput,
  },

  props: {
    inputAnswer: {
      type: Object,
      required: true,
    },
    readonly: {
      type: Boolean,
      default: false
    },
    errors: {
      type: Array,
      default: () => []
    },
    isCreation: {
      type: Boolean,
      default: false
    }
  },

  emits: ['updateInput'],

  data() {
    return {
      answerField: "",
      hasAnswer: false,
      findAnswer: null,
    }
  },

  mounted() {
    this.loadAnswer()

    if (this.findAnswer) {
      this.id = this.findAnswer.id;
      this.hasAnswer = true
      this.answerField = this.findAnswer.answer
    }
  },

  computed: {
    ...mapGetters("crm/opportunities", {
      opportunity: "getOpportunity"
    })
  },

  methods: {
    ...mapActions('crm/opportunities', [
      "createAnswer",
      "updateAnswer",
      "fetchOpportunityLogs"
    ]),

    loadAnswer() {
      this.findAnswer = this.opportunity?.answers.find(answer => answer.form_input_id === this.inputAnswer.id)
    },

    updateField(event) {
      if (!this.opportunity) {
        this.$emit("updateInput", this.answerField);

        return
      }

      this.answerField = event

      this.loadAnswer()

      if (!this.answerField) {
        this.answerField = this.inputAnswer.answer
      }

      let data = {
        answer: this.answerField,
        opportunity_id: this.opportunity.id,
        form_input_id: this.inputAnswer.id,
      }

      if (this.hasAnswer) {
        data.id = this.findAnswer.id;
        this.updateAnswer(data)
        return
      }
      this.createAnswer(data).then(() => {
        this.hasAnswer = true
      })
    },
  }
}
</script>