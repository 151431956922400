import axios from "axios";

export default {
  // Active Companies
  loadActiveCompanies({ commit, getters }) {
    commit("startLoading", "loadActiveCompanies");
    commit("setActiveCompanies", []);

    axios
      .get("/companies", { params: getters.getActiveCompaniesParams })
      .then(response => {
        commit("setActiveCompanies", response.data.data);
        commit("setActiveCompaniesPagination", response.data.meta.pagination);
      })
      .catch(response => {
        commit("system/setErrorMessage", response.response.data, { root: true });
      })
      .finally(() => {
        commit("stopLoading", "loadActiveCompanies");
      });
  },

  setActiveCompaniesFilters({ commit }, filters) {
    commit("setActiveCompaniesFilters", filters);
  },

  setActiveCompaniesCurrentPage({ commit }, page) {
    commit("setActiveCompaniesCurrentPage", page);
  },

  // Blocked Companies
  loadBlockedCompanies({ commit, getters }) {
    commit("startLoading", "loadBlockedCompanies");
    commit("setBlockedCompanies", []);
    return new Promise((resolve, reject) => {
      axios
        .get(`/companies`, { params: getters.getBlockedCompaniesParams })
        .then(response => {
          commit("setBlockedCompanies", response.data.data);
          commit("setBlockedCompaniesPagination", response.data.meta.pagination);
          resolve();
        })
        .catch(response => {
          commit("system/setErrorMessage", response.response.data, { root: true });
          reject();
        })
        .finally(() => {
          commit("stopLoading", "loadBlockedCompanies");
        });
    });
  },

  setBlockedCompaniesFilters({ commit }, filters) {
    commit("setBlockedCompaniesFilters", filters);
  },

  setBlockedCompaniesCurrentPage({ commit }, page) {
    commit("setBlockedCompaniesCurrentPage", page);
  },

  setBlockCompaniesParams({ commit }, params) {
    commit("setBlockCompaniesParams", params);
  },

  // Removed Companies
  loadTrashedCompanies({ commit, getters }) {
    commit("startLoading", "loadTrashedCompanies");
    commit("setTrashedCompanies", []);
    return new Promise(resolve => {
      axios
        .get(`/companies`, { params: getters.getTrashedCompaniesParams })
        .then(response => {
          commit("setTrashedCompanies", response.data.data);
          commit("setTrashedCompaniesPagination", response.data.meta.pagination);
          resolve();
        })
        .catch(response => {
          commit("system/setErrorMessage", response.response.data, { root: true });
        })
        .finally(() => {
          commit("stopLoading", "loadTrashedCompanies");
        });
    });
  },

  setTrashedCompaniesFilters({ commit }, filters) {
    commit("setTrashedCompaniesFilters", filters);
  },

  setTrashedCompaniesCurrentPage({ commit }, page) {
    commit("setTrashedCompaniesCurrentPage", page);
  },

  editTags({ commit }, payload) {
    commit("startLoading", "editTags");
    return new Promise((resolve, reject) => {
      axios
        .put(`/companies/${payload.id}`, {
          tags: payload.tags,
          domain: payload.domain,
          company_name: payload.company_name,
        })
        .then(() => {
          commit("system/setSuccessMessage", "Alteração realizada.", { root: true });
          resolve();
        })
        .catch(error => {
          commit("system/setErrorMessage", error.response.data, { root: true });
          reject();
        })
        .finally(() => {
          commit("stopLoading", "editTags");
        });
    });
  },

  findById({ commit }, payload) {
    commit("startLoading", "findById");
    commit("setRegister", null);
    return new Promise((resolve, reject) => {
      axios
        .get(`/companies/${payload.id}?include=tags,agent-status,calls,instances`)
        .then(response => {
          commit("setRegister", response.data.data);
          resolve();
        })
        .catch(response => {
          reject({ message: response.response.data.detail });
        })
        .finally(() => {
          commit("stopLoading", "findById");
        });
    });
  },

  updateAudiosSettings({ commit }, payload) {
    commit("startLoading", "updateAudioSettings");
    return new Promise(resolve => {
      axios
        .post(`/companies/${payload.id}/update_audios_settings`, {
          download_audios_in_batch: payload.enabled_download_audios_in_batch,
          audio_recording_stereo: payload.enabled_recordings_in_stereo,
        })
        .then(() => {
          resolve();
        })
        .catch(response => {
          commit("system/setErrorMessage", response.response.data, { root: true });
        })
        .finally(() => {
          commit("stopLoading", "updateAudioSettings");
        });
    });
  },

  change({ commit }, payload) {
    commit("startLoading", payload.dataName);
    return new Promise((resolve, reject) => {
      axios
        .put(`/companies/${payload.id}/${payload.dataName}/change`, {
          [Object.keys(payload).pop()]: payload[Object.keys(payload).pop()],
        })
        .then(() => {
          commit("system/setSuccessMessage", "Alteração realizada.", { root: true });
          resolve();
        })
        .catch(response => {
          commit("system/setErrorMessage", response.response.data, { root: true });
          reject();
        })
        .finally(() => {
          commit("stopLoading", payload.dataName);
        });
    });
  },
  changeStatusInsightsAI({ commit }, payload) {
    commit("startLoading", "changeStatusInsightsAI");
    return new Promise((resolve, reject) => {
      axios
        .put(`/companies/${payload.id}/ai-evaluation/status`, { active: payload.status })
        .then(() => {
          commit("system/setSuccessMessage", "Alteração realizada.", { root: true });
          resolve();
        })
        .catch(response => {
          commit("system/setErrorMessage", response.response.data, { root: true });
          reject();
        })
        .finally(() => {
          commit("stopLoading", "changeStatusInsightsAI");
        });
    });
  },

  updateLicense({ commit }, params) {
    commit("startLoading", "updateLicense");
    return new Promise((resolve, reject) => {
      axios
        .patch(`/companies/${params.id}`, params.params)
        .then(() => {
          commit("system/setSuccessMessage", "Alteração realizada.", { root: true });
          resolve();
        })
        .catch(error => {
          commit("system/setErrorMessage", error.response.data, { root: true });
          reject();
        })
        .finally(() => {
          commit("stopLoading", "updateLicense");
        });
    });
  },

  changeProMode({ commit }, payload) {
    commit("startLoading", "changeProMode");
    return new Promise((resolve, reject) => {
      axios
        .patch(`/companies/${payload.companyId}`, { pro_mode: payload.proMode })
        .then(() => {
          commit("system/setSuccessMessage", "Alteração realizada.", { root: true });
          commit("auth/trackEvents", `[Admin] Change pro mode status.`, { root: true });
          resolve();
        })
        .catch(response => {
          commit("system/setErrorMessage", response.response.data, { root: true });
          reject();
        })
        .finally(() => {
          commit("stopLoading", "changeProMode");
        });
    });
  },

  changeCredits({ commit }, payload) {
    commit("startLoading", "changeCredits");
    return new Promise((resolve, reject) => {
      axios
        .put(`/companies/${payload.id}/credit/${payload.method}`, { reason: payload.reason, value: payload.value })
        .then(() => {
          commit("system/setSuccessMessage", "Alteração realizada.", { root: true });
          resolve();
        })
        .catch(error => {
          commit("system/setErrorMessage", error.response.data, { root: true });
          reject();
        })
        .finally(() => {
          commit("stopLoading", "changeCredits");
        });
    });
  },

  findTotalLogged({ commit }) {
    commit("startLoading", "findTotalLogged");
    commit("setTotalLogged", 0);
    return new Promise((resolve, reject) => {
      axios
        .get("/companies/agents/totalLogged")
        .then(response => {
          commit("setTotalLogged", parseInt(response.data.total));
          resolve();
        })
        .catch(response => {
          reject();
          commit("system/setErrorMessage", response.response.data, { root: true });
        })
        .finally(() => {
          commit("stopLoading", "findTotalLogged");
        });
    });
  },

  findTotalMaxLogin({ commit }) {
    commit("startLoading", "findTotalMaxLogin");
    commit("setMaxLogin", 0);
    return new Promise((resolve, reject) => {
      axios
        .get("/companies/agents/totalMaxLogin")
        .then(response => {
          commit("setMaxLogin", parseInt(response.data.total));
          resolve();
        })
        .catch(response => {
          reject();
          commit("system/setErrorMessage", response.response.data, { root: true });
        })
        .finally(() => {
          commit("stopLoading", "findTotalMaxLogin");
        });
    });
  },

  findCallStats({ commit }) {
    commit("startLoading", "findCallStats");
    commit("setCallStats", null);

    return new Promise((resolve, reject) => {
      axios
        .get("/companies/calls/stats")
        .then(response => {
          // alert(JSON.stringify(response.data));
          commit("setCallStats", {
            total: response.data.total,
            desired: response.data.desired,
            max_calls_per_agent: response.data.max_calls_per_agent,
          });
          // commit('setCallStats', parseInt(response.data.total));
          resolve();
        })
        .catch(response => {
          reject();
          commit("system/setErrorMessage", response.response.data, { root: true });
        })
        .finally(() => {
          commit("stopLoading", "findCallStats");
        });
    });
  },

  editCompanySettings({ commit }, payload) {
    commit("startLoading", "editCompanySettings");
    commit("clearReceptiveNumbersErrors");
    commit("clearErrors");

    return new Promise((resolve, reject) => {
      axios
        .put(`/companies/${payload.id}`, payload)
        .then(response => {
          commit("system/setSuccessMessage", response.data.detail, { root: true });
          resolve();
        })
        .catch(response => {
          commit("system/setInfoMessage", response.response.data.message, { root: true });
          response.response.status == 422 &&
          response.response.data.errors &&
          commit("setErrors", response.response.data.errors);
          reject();
        })
        .finally(() => {
          commit("stopLoading", "editCompanySettings");
        });
    });
  },

  createCompany({ commit }, payload) {
    commit("startLoading", "createCompany");
    commit("auth/trackEvents", `[Admin] Create Company.`, { root: true });
    commit("clearErrors");

    return new Promise((resolve, reject) => {
      axios
        .post("/register", payload)
        .then(response => {
          let payloadWithId = {
            ...payload,
            company_id: response.data.company_id,
          };
          commit("setNewCompanyDataFeedback", payloadWithId);
          resolve();
        })
        .catch(response => {
          response.response.status != 422 && commit("system/setErrorMessage", response.response.data, { root: true });
          !!(response.response.status == 422 || response.response.status == 401) &&
          commit("setErrors", response.response.data.errors);
          reject({ message: response.response.data.detail });
        })
        .finally(() => {
          commit("stopLoading", "createCompany");
        });
    });
  },

  createNewCompany({ commit }, payload) {
    commit("startLoading", "createNewCompany");
    commit("clearErrors");

    return new Promise((resolve, reject) => {
      axios
        .post("/register-company", payload)
        .then(response => {
          let payloadWithId = {
            ...payload,
            company_id: response.data.data.id,
          };
          commit("setNewCompanyDataFeedback", payloadWithId);
          commit("system/setSuccessMessage", "Empresa criada com sucesso.", { root: true });
          resolve();
        })
        .catch(response => {
          response.response.status != 422 && commit("system/setErrorMessage", response.response.data, { root: true });
          !!(response.response.status == 422 || response.response.status == 401) &&
          commit("setErrors", response.response.data.errors);
          commit("system/setErrorMessage", response.response.data, { root: true });
          reject();
        })
        .finally(() => {
          commit("stopLoading", "createNewCompany");
        });
    });
  },

  removeCompany({ commit, getters }, payload) {
    commit("startLoading", "removeCompany");

    return new Promise((resolve, reject) => {
      axios
        .delete(`/companies/${payload.id}`, { params: getters.getRemoveCompanyParams })
        .then(() => {
          commit("system/setSuccessMessage", "Empresa excluída com sucesso", { root: true });
          resolve();
        })
        .catch(response => {
          response.response.status == 422 &&
          response.response.data.errors &&
          commit("setRemoveCompanyErrors", response.response.data.errors);
          commit("system/setErrorMessage", response.response.data, { root: true });
          reject();
        })
        .finally(() => {
          commit("stopLoading", "removeCompany");
        });
    });
  },

  managerPermissionToUnlockCompany({ commit }, payload) {
    commit("startLoading", "managerPermissionToUnlockCompany");

    return new Promise((resolve, reject) => {
      axios
        .post("/unlock-company", payload)
        .then(() => {
          commit("system/setSuccessMessage", "Acesso liberado com sucesso!", { root: true });
          resolve();
        })
        .catch(error => {
          reject(error.response.data.message);
        })
        .finally(() => {
          commit("stopLoading", "managerPermissionToUnlockCompany");
        });
    });
  },

  releaseAccessToBlockedCompany({ commit, getters }, payload) {
    commit("startLoading", "releaseAccessToBlockedCompany");
    return new Promise((resolve, reject) => {
      axios
        .put(`/companies/${payload.id}/blocked-at/change`, getters.getBlockCompaniesParams)
        .then(() => {
          commit("system/setSuccessMessage", "Acesso liberado com sucesso!", { root: true });
          resolve();
        })
        .catch(response => {
          commit("system/setErrorMessage", response.response.data, { root: true });
          reject();
        })
        .finally(() => {
          commit("stopLoading", "releaseAccessToBlockedCompany");
        });
    });
  },

  releaseAccessToTrashedCompany({ commit }, payload) {
    commit("startLoading", "findTrashedCompanies");

    return new Promise((resolve, reject) => {
      axios
        .post(`/companies/restore/${payload.id}`)
        .then(() => {
          commit("system/setSuccessMessage", "Empresa reativada com sucesso!", { root: true });
          resolve();
        })
        .catch(response => {
          commit("system/setErrorMessage", response.response.data, { root: true });
          reject();
        })
        .finally(() => {
          commit("stopLoading", "findTrashedCompanies");
        });
    });
  },

  findCompanyRoutes({ commit, getters }, payload) {
    commit("startLoading", "findCompanyRoutes");
    commit("setCompanyRoutesList", []);
    return new Promise((resolve, reject) => {
      axios
        .get(`/companies/${payload.id}/routes`, { params: getters.getCompanyRoutesParams })
        .then(response => {
          commit("setCompanyRoutesList", response.data.data);
          commit("setCompanyRoutesPagination", response.data.meta.pagination);
          resolve();
        })
        .catch(response => {
          reject();
          commit("system/setErrorMessage", response.response.data, { root: true });
        })
        .finally(() => {
          commit("stopLoading", "findCompanyRoutes");
        });
    });
  },

  editCompanyRoute({ commit }, payload) {
    commit("startLoading", "editCompanyRoute");
    commit("setCompanyRoutesErrors", {});
    return new Promise((resolve, reject) => {
      axios
        .put(`/companies/${payload.id}/routes/${payload.fields.id}`, payload.fields)
        .then(() => {
          commit("system/setSuccessMessage", "Rota atualizada com sucesso!", { root: true });
          resolve();
        })
        .catch(error => {
          if (error.response.data.status === 422) commit("setCompanyRoutesErrors", error.response.data.errors);

          commit("system/setErrorMessage", error.response.data, { root: true });
          reject();
        })
        .finally(() => {
          commit("stopLoading", "editCompanyRoute");
        });
    });
  },

  editTelephonyRate({ commit }, payload) {
    commit("startLoading");
    commit("setTelephonyRatesErrors", {});

    return new Promise((resolve, reject) => {
      axios
        .put(
          `/companies/${payload.company_id}/routes/${payload.route_id}/telephonyRates/${payload.telephony_rate_id}`,
          payload.fields,
        )
        .then(() => {
          commit("system/setSuccessMessage", "Rota e tarifas atualizadas com sucesso!", { root: true });
          resolve();
        })
        .catch(error => {
          commit("setTelephonyRatesErrors", error.response.data.errors);
          commit("system/setErrorMessage", error.response.data, { root: true });
          reject();
        })
        .finally(() => {
          commit("stopLoading");
        });
    });
  },

  createRoute({ commit }, payload) {
    commit("startLoading", "createRoute");
    return new Promise((resolve, reject) => {
      axios
        .post(`/companies/${payload.id}/routes`, payload.fields)
        .then(() => {
          commit("system/setSuccessMessage", "Rota criada com sucesso!", { root: true });
          resolve();
        })
        .catch(error => {
          if (error.response.data.status === 422) commit("setCompanyRoutesErrors", error.response.data.errors);

          commit("system/setErrorMessage", error.response.data, { root: true });
          reject();
        })
        .finally(() => {
          commit("stopLoading", "createRoute");
        });
    });
  },

  deleteRoute({ commit }, payload) {
    commit("startLoading");
    return new Promise((resolve, reject) => {
      axios
        .delete(`/companies/${payload.id}/routes/${payload.route_id}`)
        .then(() => {
          commit("system/setSuccessMessage", "Rota excluída com sucesso!", { root: true });
          resolve();
        })
        .catch(error => {
          commit("system/setErrorMessage", error.response.data, { root: true });
          reject();
        })
        .finally(() => {
          commit("stopLoading");
        });
    });
  },

  deleteTelephonyRate({ commit }, payload) {
    commit("startLoading");
    return new Promise((resolve, reject) => {
      axios
        .delete(`/companies/${payload.id}/routes/${payload.routeId}/telephonyRates/${payload.rateId}`)
        .then(() => {
          resolve();
        })
        .catch(response => {
          reject({ message: response.response.data.detail });
        })
        .finally(() => {
          commit("stopLoading");
        });
    });
  },

  createTelephonyRate({ commit }, payload) {
    commit("startLoading");
    return new Promise((resolve, reject) => {
      axios
        .post(`/companies/${payload.id}/routes/${payload.routeId}/telephonyRates`, payload)
        .then(() => {
          resolve();
        })
        .catch(response => {
          reject();
          commit("system/setErrorMessage", response.response.data, { root: true });
        })
        .finally(() => {
          commit("stopLoading");
        });
    });
  },

  listCompanyBilling({ commit }, payload) {
    commit("setCompanyBilling", []);
    commit("startLoading", "listCompanyBilling");
    return new Promise((resolve, reject) => {
      axios
        .get(`/companies/billing?start_date=${payload.start_date}&end_date=${payload.end_date}&company=${payload.id}`)
        .then(response => {
          commit("setCompanyBilling", response.data.data);
          resolve();
        })
        .catch(response => {
          reject();
          commit("system/setErrorMessage", response.response.data, { root: true });
        })
        .finally(() => {
          commit("stopLoading", "listCompanyBilling");
        });
    });
  },

  listCompanyCredit({ commit, getters }) {
    commit("setCompanyCredit", []);
    commit("startLoading", "listCompanyCredit");
    return new Promise((resolve, reject) => {
      axios
        .get(`/companies/credit-extract?`, { params: getters.getCompanyCreditParams })
        .then(response => {
          commit("setCompanyCredit", response.data.data);
          commit("setCompanyCreditPagination", response.data.meta.pagination);
          resolve();
        })
        .catch(response => {
          reject();
          commit("system/setErrorMessage", response.response.data, { root: true });
        })
        .finally(() => {
          commit("stopLoading", "listCompanyCredit");
        });
    });
  },

  findCompanyHistory({ commit, getters }, payload) {
    commit("setCompanyHistory", []);
    commit("startLoading", "findCompanyHistory");
    return new Promise((resolve, reject) => {
      axios
        .get(`/companies/${payload.id}/changes/history`, { params: getters.getCompanyHistoryParams })
        .then(response => {
          commit("setCompanyHistory", response.data.data);
          commit("setCompanyHistoryPagination", response.data.meta.pagination);
          resolve();
        })
        .catch(response => {
          //rever tratamento de erro
          commit("system/setErrorMessage", response.response.data, { root: true });
          reject();
        })
        .finally(() => {
          commit("stopLoading", "findCompanyHistory");
        });
    });
  },

  setCompanyHistoryFilters({ commit }, filters) {
    commit("setCompanyHistoryFilters", filters);
  },

  setCompanyHistoryCurrentPage({ commit }, page) {
    commit("setCompanyHistoryCurrentPage", page);
  },

  /**
   * Brings only the receptive numbers of an
   * specific company based on an id.
   *
   * @param {Object} param0
   * @returns
   */
  findReceptiveNumbers({ commit, getters }, payload) {
    commit("setReceptiveNumbersList", []);
    commit("startLoading", "findReceptiveNumbers");
    return new Promise((resolve, reject) => {
      axios
        .get(`/receptiveNumbers/company/${payload.id}`, { params: getters.getReceptiveNumbersParams })
        .then(response => {
          commit("setReceptiveNumbersList", response.data.data);
          commit("setReceptiveNumbersPagination", response.data.meta.pagination);
          resolve();
        })
        .catch(response => {
          reject();
          commit("system/setErrorMessage", response.response.data, { root: true });
        })
        .finally(() => {
          commit("stopLoading", "findReceptiveNumbers");
        });
    });
  },

  createReceptiveNumbers({ commit }, payload) {
    commit("startLoading", "createReceptiveNumbers");
    commit("setReceptiveNumbersErrors", {});
    return new Promise((resolve, reject) => {
      axios
        .post(`/receptiveNumbers`, payload.fields)
        .then(response => {
          commit("system/setSuccessMessage", response.data.detail, { root: true });
          resolve();
        })
        .catch(error => {
          if (error.response.data.status === 422) commit("setReceptiveNumbersErrors", error.response.data.errors);
          commit("system/setErrorMessage", error.response.data, { root: true });
          reject();
        })
        .finally(() => {
          commit("stopLoading", "createReceptiveNumbers");
        });
    });
  },

  deleteReceptiveNumbers({ commit }, payload) {
    commit("startLoading", "deleteReceptiveNumbers");
    return new Promise((resolve, reject) => {
      axios
        .delete(`/receptiveNumbers/${payload.id}`)
        .then(() => {
          commit("system/setSuccessMessage", "Número receptivo deletado com sucesso!", { root: true });
          resolve();
        })
        .catch(response => {
          commit("system/setErrorMessage", response.response.data, { root: true });
          reject();
        })
        .finally(() => {
          commit("stopLoading", "deleteReceptiveNumbers");
        });
    });
  },

  downloadCSV({ commit }) {
    commit("startLoading", "downloadCSV");
    return new Promise((resolve, reject) => {
      axios
        .get(`/companies/data`)
        .then(response => {
          commit("system/setSuccessMessage", response.data.detail, { root: true });
          resolve();
        })
        .catch(response => {
          commit("system/setErrorMessage", response.response.data, { root: true });
          reject();
        })
        .finally(() => {
          commit("stopLoading", "downloadCSV");
        });
    });
  },
};
