<template>
  <div
    role="tabpanel"
    :id="id"
    class="tab-pane"
    :class="{active: isActive}"
  >
    <template v-if="!listCategories.length && !loading">
      <div class="empty-category d-flex align-items-center justify-content-center flex-column">
        <img
          src="@assets/img/empty.svg"
          width="99"
          height="88"
          loading="lazy"
          alt="Empty"
          class="img-fluid"
          style="margin-top: 1rem"
        >

        <p
          style="margin-top: 1rem; margin-bottom: 5px"
          class="fw-medium tx-text-200"
        >
          {{ $t('no_registered_products') }}
        </p>

        <p
          style="color: #575778;"
          class="fs-6 mb-4 tx-text-100"
        >
          {{ $t('before_add_products') }}
        </p>
        <app-button
          style="width: 240px"
          @click="handleNewCategory"
          class="create-category mb-4"
        >
          {{ $t('create_products_category') }}
        </app-button>
      </div>
    </template>

    <template v-if="loading">
      <card-loading
        v-for="item in 4"
        :key="item"
        class="card"
        :height="60"
      />
    </template>
    <template v-else>
      <category-component
        v-for="(item, index) in listCategories"
        :key="item.id"
        :list-categories="listCategories"
        :index="index"
        :item="item"
      />
    </template>
  </div>
</template>

<script>
import CategoryComponent from "@dialer/manager/pages/settings/products-and-services/components/category-component.vue";
import AppButton from "@components/app-button.vue";
import CardLoading from "@components/card-loading.vue";
import {mapActions, mapGetters} from "vuex";

export default {
  name: "CategoriesTablePane",
  components: {CardLoading, AppButton, CategoryComponent},

  props: {
    id: {
      type: String,
      required: true
    },
    listCategories: {
      type: Array,
      required: true
    },
    loading: {
      type: Boolean,
      required: true
    }
  },

  computed: {
    ...mapGetters('manager/products_and_services', {
      activeList: "getActiveList",
    }),

    isActive() {
      return this.activeList === this.id
    }
  },

  methods: {
    ...mapActions("manager/products_and_services", [
      "toggleModal",
      "toggleCategoryModal",
    ]),

    handleNewCategory() {
      this.toggleCategoryModal({
        title: this.$t('new_category'),
        button: this.$t('create_new_category'),
        type: "category",
        actionName: "createCategory",
        handleName: "handleCategoryData",
        data: {
          name: "",
          team_ids: [],
          is_active: true
        },
        dataState: "categoryDataState"
      })
      this.toggleModal(true)
    }
  },
}
</script>