<template>
  <div
    class="position-relative"
    style="z-index: 1040"
    ref="selectGroupChannel"
  >
    <div
      class="select-group-channel d-flex align-items-center justify-content-between cursor-pointer"
      @click="open = !open"
    >
      <p class="m-0">
        {{ preview }}
      </p>
      <span
        class="far fa-chevron-down tx-text-gray fs-4"
        style="margin-left: 0.75rem"
      />
    </div>

    <div
      class="select-group-channel w-100 position-absolute top-0 bg-white"
      :class="{'d-none': !open}"
    >
      <div class="d-flex justify-content-between align-items-center">
        <div class="d-flex justify-content-between align-items-center w-100">
          <span
            class="far fa-search tx-text-gray"
            style="margin-right: 0.75rem"
          />
          <input
            class="form-control p-0 border-0 text-medium"
            style="border-radius: 0"
            :placeholder="$t('search_capitalize')"
            v-model="search"
            @keyup="handleSearch"
          >
        </div>
        <span
          class="far fa-chevron-up tx-text-gray fs-4 cursor-pointer"
          style="margin-left: 0.75rem"
          @click="open = !open"
        />
      </div>

      <div
        class="bg-white"
        style="margin-top: 1rem;"
        :class="{'d-none': !open}"
      >
        <li
          class="dropdown-item d-flex align-items-center p-0"
          style="gap: 1rem"
        >
          <input
            class="form-check-input m-0"
            type="checkbox"
            aria-label="Checkbox for following text input"
            id="allGroupChannels"
            v-model="selectedAll"
            @click="selectAll"
          >
          <label
            class="form-label m-0"
            for="allGroupChannels"
          >
            {{ $t('select_all') }}
          </label>
        </li>
        <hr class="card-divider">
        <div
          class="overflow-scroll"
          style="max-height: 320px"
        >
          <li
            class="dropdown-item p-0"
            v-if="activeList.length"
          >
            <div
              class="d-flex align-items-center"
              style="gap: 1rem"
            >
              <input
                class="form-check-input m-0"
                type="checkbox"
                aria-label="Checkbox for following text input"
                id="activeGroupChannelsSelected"
                v-model="activeAll"
                @click="selectActive"
              >
              <label
                class="form-label m-0"
                for="activeGroupChannelsSelected"
              >
                {{ $t('actives') }}
              </label>
            </div>
            <ul
              class="mb-0"
              style="padding: 0.5rem 0 0 1rem;"
            >
              <li
                v-for="groupChannel in activeList"
                :key="groupChannel.id"
                class="dropdown-item d-flex align-items-center"
                style="gap: 1rem; padding: 0.5rem 0"
              >
                <input
                  class="form-check-input m-0"
                  :class="{'checkbox-gray': activeAll}"
                  type="checkbox"
                  :value="groupChannel.id"
                  :id="groupChannel.id"
                  v-model="activeSelected"
                >
                <label
                  class="form-label m-0"
                  :for="groupChannel.id"
                >
                  {{ groupChannel.name }}
                </label>
              </li>
            </ul>
          </li>
          <li
            class="dropdown-item p-0"
            v-if="inactiveList.length"
          >
            <div
              class="d-flex align-items-center"
              style="gap: 1rem"
            >
              <input
                class="form-check-input m-0"
                type="checkbox"
                aria-label="Checkbox for following text input"
                id="inactiveGroupChannelsSelected"
                v-model="inactiveAll"
                @click="selectInactive"
              >
              <label
                class="form-label m-0"
                for="inactiveGroupChannelsSelected"
              >
                {{ $t('excluded') }}
              </label>
            </div>
            <ul
              class="mb-0"
              style="padding: 0.5rem 0 0 1rem;"
            >
              <li
                v-for="groupChannel in inactiveList"
                :key="groupChannel.id"
                class="dropdown-item d-flex align-items-center"
                style="gap: 1rem; padding: 0.5rem 0"
              >
                <input
                  class="form-check-input m-0"
                  :class="{'checkbox-gray': inactiveAll}"
                  type="checkbox"
                  :value="groupChannel.id"
                  :id="groupChannel.id"
                  v-model="inactiveSelected"
                >
                <label
                  class="form-label m-0"
                  for="groupChannel"
                >
                  {{ groupChannel.name }}
                </label>
              </li>
            </ul>
          </li>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import {mapActions, mapGetters} from "vuex";

export default {
  name: "SelectWhatsappGroupChannels",

  emits:['selectGroupChannels'],

  data() {
    return {
      open: false,
      activeList: [],
      activeSelected: [],
      inactiveList: [],
      inactiveSelected: [],
      search: ''
    }
  },

  created() {
    this.fetchGroupChannelsWithQuali().then(()=>{
      this.activeList = this.groupChannels.filter(groupChannel => groupChannel.status === 'active')
      this.inactiveList = this.groupChannels.filter(groupChannel => groupChannel.status === 'deleted')
      this.selectActive()
    })
  },

  mounted() {
    document.addEventListener("click", this.closeDropdown);
  },

  beforeUnmount() {
    document.removeEventListener("click", this.closeDropdown);
  },

  computed: {
    ...mapGetters("manager/whatsapp_filters", {
      groupChannels: "getGroupChannelsWithQuali",
    }),

    activeIds() {
      return this.activeList.map(groupChannel=>groupChannel.id)
    },

    activeAll() {
      return this.activeSelected.length === this.activeIds.length
    },

    inactiveIds() {
      return this.inactiveList.map(groupChannel=>groupChannel.id)
    },

    inactiveAll() {
      return this.inactiveSelected.length === this.inactiveIds.length
    },

    selectedAll() {
      return this.activeAll && this.inactiveAll
    },

    preview() {
      const count = this.inactiveSelected.length + this.activeSelected.length
      if (this.selectedAll || count === 0) {
        return this.$t('all_dropdown')
      }
      if (this.activeAll && !this.inactiveSelected.length){
        return this.$t('actives')
      }
      if (this.inactiveAll && !this.activeSelected.length){
        return this.$t('excluded')
      }
      return count > 1 ? `${count} Grupo de canais selecionados` : `${count} Grupo de canal selecionado`
    }
  },

  methods: {
    ...mapActions("manager/whatsapp_filters", ["fetchGroupChannelsWithQuali"]),

    handleSearch: _.debounce(function() {
      const term = this.search
      this.fetchGroupChannelsWithQuali(term);
    }, 500),

    closeDropdown(event) {
      if (!this.$refs.selectGroupChannel.contains(event.target)) {
        this.open = false;
      }
    },

    selectActive() {
      this.activeAll? this.activeSelected = [] : this.activeSelected = this.activeIds
    },

    selectInactive() {
      this.inactiveAll? this.inactiveSelected = [] : this.inactiveSelected = this.inactiveIds
    },

    selectAll() {
      if (this.selectedAll) {
        this.activeSelected = []
        this.inactiveSelected = []
      }
      else {
        this.activeSelected = this.activeIds
        this.inactiveSelected = this.inactiveIds
      }
    }
  },

  watch:{
    activeSelected() {
      const selectedGroupChannels = [...this.activeSelected, ...this.inactiveSelected]
      this.$emit("selectGroupChannels", selectedGroupChannels)
    },
    inactiveSelected() {
      const selectedGroupChannels = [...this.activeSelected, ...this.inactiveSelected]
      this.$emit("selectGroupChannels", selectedGroupChannels)
    },
  }
}
</script>


<style scoped lang="scss">
.select-group-channel{
  padding: 0.5rem 1rem;
  border: 1px solid #E1E9F4;
  border-radius: 10px;
  background-color: #FFFFFF;
}

.checkbox-gray[type="checkbox"]:checked {
  background-color: $color-text-gray;
}
</style>