<template>
  <div class="file-uploader">
    <form
      ref="file"
      action="#"
      class="dropzone"
    />
  </div>
</template>

<script>
import Dropzone from "dropzone";

export default {
  emits: ["select-file", "removed-file"],
  props: {
    icon: {
      type: String,
      default: "fe fe-file"
    },
    filter: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      dropzone: null,
      audioFileUrl: null
    };
  },
  mounted() {
    const previewTemplate = `
      <div class="list-group list-group-flush rounded px-4 type-border d-flex flex-column justify-content-center align-items-center" style="min-height: 188px;">
        <div class="list-group-item w-100">
          <div class="row align-items-center" :class="{ 'pt-4': filter && !filter.includes('audio') }">
            <div class="col-auto">
              <div class="avatar">
                <div class="avatar-title rounded bg-white text-secondary" style="border: 1px solid #d2ddec">
                  <span class="${this.icon}" />
                </div>
              </div>
            </div>
            <div class="col ms-n2">
              <h4 class="fw-normal mb-1">
                <div data-dz-name></div>
              </h4>
              <small class="text-muted" data-dz-size></small>
            </div>
            <div class="col-auto">
              <div class="dropdown">
                <a aria-expanded="false" aria-haspopup="true" class="dropdown-ellipses dropdown-toggle" data-bs-toggle="dropdown" href="#" role="button">
                  <i class="fe fe-trash"></i>
                </a>
                <div class="dropdown-menu dropdown-menu-end">
                  <a
                    href="#"
                    class="dropdown-item"
                    data-dz-remove
                  > ${ this.$t('remove_capitalize') } </a>
                </div>
              </div>
            </div>
            <div ref="audioPreviewContainer" class="mt-4"></div>
          </div>
        </div>
      </div>
    `;

    this.dropzone = new Dropzone(this.$refs.file, {
      acceptedFiles: this.filter,
      autoProcessQueue: false,
      maxFiles: 1,
      previewTemplate: previewTemplate,
      maxfilesexceeded: function(files) {
        this.removeAllFiles();
        this.addFile(files);
      },
      dictDefaultMessage:  this.$t('drag_and_drop'),
      addedfiles: files => {
        if (this.filter.includes("audio")) {
          const audioPreviewContainer = this.$refs.file.querySelector("[ref=audioPreviewContainer]");
          const audio = document.createElement("audio");
          audio.src = URL.createObjectURL(files[0]);
          audio.controls = true;
          audio.style.width = "100%";
          audio.style.height = "40px";
          audioPreviewContainer.appendChild(audio);
        }
        this.styleDisplayDzMessage();
        this.$emit("select-file", files[0]);
      }
    });

    this.dropzone.on("removedfile", () => {
      this.audioFileUrl = null;
      this.styleDisplayDzMessage();
      this.$emit("removed-file", null);
    });
  },
  methods: {
    removeFiles(file) {
      this.dropzone.removeFile(file);
    },
    styleDisplayDzMessage() {
      const primaryDzMessage = this.$refs.file.querySelector(".dz-default.dz-message");
      if (this.dropzone.files.length >= 1) {
        primaryDzMessage.style.display = "none";
      } else {
        primaryDzMessage.style.display = "block";
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.type-border {
  border: 1px dashed #d2ddec;
  a:nth-last-child(2):hover {
    color: red;
  }
}

.dz-max-files-reached > .dz-default {
  display: none;
}
</style>
