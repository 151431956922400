<template>
  <div class="mailing-form">
    <div
      class="modal"
      ref="modal"
    >
      <div class="modal-dialog modal-xl">
        <div class="modal-content form-background">
          <div class="modal-body pt-0">
            <div class="container-fluid">
              <div class="row b1">
                <header-component
                  :title="$t('include_mailing_list')"
                  :pretitle="campaign.name"
                />

                <div
                  class="col-lg-12"
                  v-if="withUra"
                >
                  <div class="form-group">
                    <label class="form-label required">{{ $t("select_your_ivr") }}</label>
                    <select-ivrs v-model="ura" />
                  </div>
                </div>

                <div class="col-lg-12">
                  <div class="form-group">
                    <label
                      for="name"
                      class="form-label required"
                    >{{ $t("mailing_list_capitalize") }}</label>
                    <span class="form-text">{{ $t("send_csv_file") }}
                    </span>
                    <div
                      :class="{
                        'card mb-0': true,
                        'is-invalid': errors.mailing
                      }"
                    >
                      <div class="card-body">
                        <file-upload
                          @select-file="selectFile"
                          filter="text/csv,text/plain"
                          icon="fe fe-database"
                        />
                      </div>
                    </div>
                    <div class="invalid-feedback">
                      {{ errors.mailing && errors.mailing[0] }}
                    </div>
                  </div>
                </div>

                <div class="col-lg-6 b3">
                  <div class="form-group">
                    <label class="form-label required">{{ $t("split_by") }}</label>
                    <select
                      class="form-select"
                      v-model="fields.separator"
                    >
                      <option value=",">
                        ,
                      </option>
                      <option value=";">
                        ;
                      </option>
                      <option value="tab">
                        {{ $t("tab") }}
                      </option>
                    </select>
                  </div>
                </div>

                <div class="col-lg-6 b3">
                  <div class="form-group">
                    <label class="form-label required">{{ $t("delimiter") }}</label>
                    <select
                      class="form-select"
                      v-model="fields.delimiter"
                    >
                      <option value="quotes">
                        "
                      </option>
                      <option value="single-quotes">
                        '
                      </option>
                    </select>
                  </div>
                </div>

                <div :class="[isBrazil ? 'col-12 ' : 'col-lg-6']">
                  <div class="form-group">
                    <div class="form-check form-switch">
                      <input
                        class="form-check-input"
                        v-model="fields.has_header"
                        type="checkbox"
                      >
                      <label class="form-label required">{{ $t("fist_line_header") }}</label>
                    </div>
                    <span class="form-text">
                      {{ $t("activate_first_line_header") }}.
                    </span>
                  </div>
                </div>
                <div
                  v-if="!isBrazil"
                  class="col-lg-6 b3"
                >
                  <div class="form-group">
                    <div class="form-check form-switch">
                      <input
                        class="form-check-input"
                        v-model="fields.is_international_mailing"
                        type="checkbox"
                      >
                      <label class="form-label">{{ $t("international") }}</label>
                    </div>
                    <span class="form-text">
                      {{ $t("function_international_format") }}
                    </span>
                  </div>
                </div>

                <div class="col-lg-12 b3">
                  <div
                    class="alert alert-info bg-blue-300"
                    style="border-color: var(--color-blue-300);"
                    role="alert"
                  >
                    {{
                      $t("config_columns")
                    }} <strong>{{ $t("area_code_and_phone") }}</strong> {{ $t("or_column") }}
                    <strong>{{ $t("area_code_phone") }}</strong>. <br>
                    {{ $t("header_config") }}
                    <br>
                    <strong>{{
                      $t("no_header_columns")
                    }}</strong>
                  </div>
                </div>

                <div class="col-lg-12 b3 mb-2">
                  <app-button
                    class="w-100"
                    :blocked="!file"
                    style="min-height: 0px"
                    @click="validate()"
                    :loading="loading && loadingContext == 'validate'"
                  >
                    {{ $t("validate_list") }}
                  </app-button>
                </div>

                <div
                  class="col-lg-12 b1 mt-3"
                  v-if="listDataPreview.length > 0"
                >
                  <div class="form-group">
                    <label class="form-label required">{{ $t("list_setting") }}</label>

                    <div
                      :class="{
                        'card mb-0': true,
                        'is-invalid ': errors.header
                      }"
                      id="preview"
                      ref="preview"
                    >
                      <div class="card-header 0">
                        <div class="row my-2">
                          <div class="col-lg-1" />
                          <div class="col-lg-1">
                            {{ $t("column") }}
                          </div>
                          <div class="col-lg-3">
                            {{ $t("column_type") }}
                          </div>
                          <div class="col-lg-3">
                            {{ $t("column_name") }}
                          </div>
                          <div class="col-lg-2">
                            {{ $t("line") }} 1
                          </div>
                          <div class="col-lg-2">
                            {{ $t("line") }} 2
                          </div>
                        </div>
                      </div>

                      <div class="card-body">
                        <div
                          class="row my-2"
                          v-for="(name, i) in listHeaderPreview"
                          :key="i"
                        >
                          <div
                            v-if="verifyIdentifier(i)"
                            class="bg-danger-soft py-2 d-flex align-items-center rounded"
                          >
                            <i class="fe fe-alert-triangle text-danger fs-2 me-3 mb-1" />
                            <span>
                              {{ $t("cannot_add_column") }} <strong>{{ $t("identifier_lowercase")
                              }}</strong> {{ $t("exists_to_many_lines") }} <strong>{{ $t("repeated")
                              }}</strong> {{ $t("in_sent_list") }}
                            </span>
                          </div>

                          <div class="col-lg-1 b2">
                            <div
                              class="form-check form-switch"
                              style="margin: 8.25px 0"
                            >
                              <input
                                class="form-check-input"
                                v-model="actives[i]"
                                type="checkbox"
                              >
                            </div>
                          </div>
                          <div class="col-lg-1 b2">
                            <strong
                              style="line-height: 40.5px"
                              :class="{ 'text-muted': !actives[i] }"
                            >
                              {{ i }}
                            </strong>
                          </div>
                          <div class="col-lg-3 b3">
                            <strong style="line-height: 40.5px">
                              <select
                                class="form-select"
                                v-model="fields.header[i]"
                                :disabled="!actives[i]"
                              >
                                <template
                                  v-for="(n, type) in columnsTypes()"
                                  :key="type"
                                >
                                  <option
                                    v-if="true"
                                    :value="type"
                                  > {{ n }}
                                  </option>
                                </template>
                              </select>
                            </strong>
                          </div>
                          <div class="col-lg-3 b3">
                            <strong style="line-height: 40.5px">
                              <input
                                class="form-control"
                                v-model="fields.header[i]"
                                :disabled="!actives[i]"
                                v-show="!hasType(fields.header[i])"
                              >
                            </strong>
                          </div>

                          <div class="col-lg-2 b3 text-muted overflow-ellipsis">
                            <span style="line-height: 40.5px">
                              {{ listDataPreview[0][i] }}
                            </span>
                          </div>

                          <div
                            v-if="listDataPreview[1]"
                            class="col-lg-2 b3 text-muted overflow-ellipsis"
                          >
                            <span style="line-height: 40.5px">
                              {{ listDataPreview[1][i] }}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="invalid-feedback">
                      {{ errors.header && errors.header[0] }}
                    </div>
                  </div>
                </div>
                <div
                  class="col-lg-12 b3"
                  v-if="listDataPreview.length > 0"
                >
                  <app-button
                    class="w-100"
                    :blocked="!file && false"
                    style="min-height: 0px"
                    @click="submit()"
                    :loading="loading && loadingContext == 'create'"
                  >
                    {{ $t("capitalizes.save") }}
                  </app-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <app-button
      @click="open"
      icon="plus"
      color="btn-outline-primary"
      size="btn-sm"
      class="px-4 py-0"
      style="height: 32px;"
    >
      {{ $t("new_list") }}
    </app-button>
  </div>
</template>

<script>
import { Modal } from "bootstrap";

import { mapActions, mapGetters, mapMutations } from "vuex";

import FileUpload from "@components/file-uploader";
import AppButton from "@components/app-button";
import SelectIvrs from "@components/select-ivrs";
import HeaderComponent from "@/components/header-component.vue";

export default {
  emits: ["success"],

  props: {
    campaign: {
      type: Object,
      default: null,
    },

    withUra: {
      type: Boolean,
      default: false,
    },

    openModalForm: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      ura: "",
      modal: null,
      file: null,
      actives: [],
      fields: {
        separator: ";",
        delimiter: "quotes",
        data: [],
        header: [],
        has_header: true,
        is_international_mailing: false,
        file_name: "",
        ura_id: "",
        name: "",
      },

      MAXIMUM_DUPLICATE_IDENTIFIERS: 30,
    };
  },

  components: {
    FileUpload,
    AppButton,
    SelectIvrs,
    HeaderComponent,
  },

  watch: {
    openModalForm() {
      this.open();
    },

    fileName(val) {
      this.fields.file_name = val;
    },

    listHeaderPreview(val) {
      this.fields.header = val;
    },

    listDataPreview(val) {
      if (val.length) {
        this.actives = new Array(val[0].length).fill(true);
        this.fields.data = val;
      }
    },
  },

  computed: {
    /**
     * Getters
     */
    ...mapGetters("manager/list", {
      listDataPreview: "getListDataPreview",
      listHeaderPreview: "getListHeaderPreview",
      fileName: "getFileName",
      errors: "getErrors",
      loading: "isLoading",
      loadingContext: "getloadingContext",
    }),
    ...mapGetters("auth", {
      user: "getUser",
      isBrazil: "isBrazil",
    }),
  },

  mounted() {
    this.modal = new Modal(this.$refs.modal);
  },

  methods: {
    ...mapMutations("auth", ["trackEvents"]),
    open() {
      this.trackEvents("[Manager] Insert Mailing");
      this.modal.toggle();
      this.reset();
    },

    reset() {
      this.actives = [];

      this.fields.separator = ";";
      this.fields.delimiter = "quotes";
      this.fields.data = [];
      this.fields.header = [];
      this.fields.has_header = true;
      this.fields.is_international_mailing = false;
      this.fields.file_name = "";
      // this.fields.ura_id = "";

      this.setListDataPreview([]);
    },
    ...mapActions("manager/list", ["validateListFile", "create"]),
    ...mapMutations("manager/list", ["setListDataPreview"]),

    validate() {
      let formData = new FormData();
      formData.append("mailing", this.file);
      formData.append("separator", this.fields.separator);
      formData.append("delimiter", this.fields.delimiter);
      formData.append("has_header", this.fields.has_header ? 1 : 0);
      formData.append("is_international_mailing ", this.fields.is_international_mailing ? 1 : 0);
      if (this.withUra) formData.append("ura", this.fields.delimiter);
      this.validateListFile({ formData, id: this.campaign.id })
          .then(() => {
            this.scroll();
          });
    },

    selectFile(file) {
      this.file = file;
      this.fields.name = file.name;
    },

    hasType(val) {
      return (
          val == "identifier" ||
          val == "areacode" ||
          val == "phone" ||
          val == "areacodephone" ||
          val == "countrycodeareacodephone" ||
          val == "countrycode"
      );
    },

    scroll() {
      if (this.$refs.preview) {
        var top = this.$refs.preview.offsetTop;
        this.$refs.modal.scrollTo(0, top);
      }
    },

    submit() {
      if (this.withUra) this.fields.ura_id = this.ura;
      else delete this.fields.ura_id;
      this.fields.header = this.fields.header.map((el, i) => (this.actives[i] ? el : ""));
      this.create({
        fields: this.fields,
        id: this.campaign.id,
      })
          .then(() => {
            this.modal.toggle();
            this.$emit("success");
          });
    },

    /**
     * Check if there is more than 30 equals identifiers in a column
     *
     * @param {Number} index
     * @returns {Boolean}
     */
    verifyIdentifier(index) {
      let isIdentifierInvalid = false;
      if (this.fields.header[index] == "identifier") {
        let identifiers = this.listDataPreview.map(el => el[index]);
        let occurrences = {};
        identifiers.forEach(el => (occurrences[el] ? occurrences[el]++ : (occurrences[el] = 1)));

        for (const key in occurrences) {
          const element = occurrences[key];

          if (element >= this.MAXIMUM_DUPLICATE_IDENTIFIERS) {
            isIdentifierInvalid = true;
            break;
          }
        }
      }

      return isIdentifierInvalid;
    },
    columnsTypes() {
      if (this.fields.is_international_mailing) {
        return {
          "": "",
          identifier: this.$t("identifier"),
          countrycode: "DDI",
          areacode: this.$t("area_code"),
          phone: this.$t("phone"),
          countrycodeareacodephone: this.$t("area_code_phone_international"),
        };
      } else {
        return {
          "": "",
          identifier: this.$t("identifier"),
          areacode: this.$t("area_code"),
          phone: this.$t("phone"),
          areacodephone: this.$t("area_code_phone"),
        };
      }
    },
  },
};
</script>

<style lang="scss">
.mailing-form {
  .form-background {
    background-color: #f9fbfd;
  }
}
</style>
