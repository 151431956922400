<template>
  <div class="main-content">
    <div
      v-if="campaign"
      class="container-fluid"
    >
      <div class="row justify-content-center">
        <div class="col-12 col-lg-10 col-xl-8">
          <header-component
            :redirect-link="`/manager/campaign/${$route.params.id}`"
            :title="campaign.name"
            :pretitle="$t('CAMPAIGN')"
          >
            <template #buttons>
              <button
                v-if="loading && (loadingContext == 'pause' || loadingContext == 'resume')"
                class="btn btn-primary me-4"
                data-test="pauseResumeCampaignLoading-button"
                style="max-width: 41px"
                @click="resumeCampaign()"
              >
                <div
                  class="spinner-border spinner-border-sm"
                  role="status"
                >
                  <span class="visually-hidden">Loading...</span>
                </div>
              </button>
              <button
                v-else-if="campaign.paused"
                class="fe fe-play btn btn-primary me-4"
                data-test="resumeCampaign-button"
                @click="resumeCampaign()"
              />
              <button
                v-else
                class="fe fe-pause btn btn-primary me-4"
                data-test="pauseCampaign-button"
                @click="pauseCampaign()"
              />
              <button
                v-if="loadingSchedules || (loading && loadingContext == 'remove')"
                class="btn btn-danger"
                data-test="trashLoading-button"
                style="max-width: 41px"
              >
                <div
                  class="spinner-border spinner-border-sm"
                  role="status"
                >
                  <span class="visually-hidden">Loading...</span>
                </div>
              </button>
              <button
                v-else
                class="fe fe-trash-2 btn btn-danger"
                data-test="trash-button"
                @click="openDeleteCampaignConfirmationModal()"
              />
            </template>
          </header-component>

          <flash-message />
          <div class="col-lg-12">
            <ul
              id="myTab"
              class="nav nav-tabs"
              role="tablist"
            >
              <li
                class="nav-item"
                role="presentation"
              >
                <button
                  id="tab-geral"
                  ref="tab-general"
                  aria-controls="geral"
                  aria-selected="true"
                  class="nav-link active"
                  data-bs-target="#geral"
                  data-bs-toggle="tab"
                  data-test="generalTab-button"
                  role="tab"
                  type="button"
                >
                  {{ $t("general_capitalize") }}
                </button>
              </li>
              <li
                class="nav-item"
                role="presentation"
              >
                <button
                  id="aa-tab"
                  ref="tab-agents"
                  aria-controls="aa"
                  aria-selected="false"
                  class="nav-link"
                  data-bs-target="#agents"
                  data-bs-toggle="tab"
                  data-test="agentsTab-button"
                  role="tab"
                  type="button"
                >
                  {{ $t("agents_capitalize") }}
                </button>
              </li>
              <li
                class="nav-item"
                role="presentation"
              >
                <button
                  id="strategy-tab"
                  ref="tab-strategy"
                  aria-controls="strategy"
                  aria-selected="false"
                  class="nav-link"
                  data-bs-target="#strategy"
                  data-bs-toggle="tab"
                  data-test="strategyTab-button"
                  role="tab"
                  type="button"
                >
                  {{ $t("strategy_omni") }}
                </button>
              </li>
              <li
                class="nav-item"
                role="presentation"
              >
                <button
                  v-if="pro_mode"
                  id="advanced-tab"
                  ref="tab-advanced"
                  aria-controls="advanced"
                  aria-selected="false"
                  class="nav-link"
                  data-bs-target="#advanced"
                  data-bs-toggle="tab"
                  data-test="advancedTab-button"
                  role="tab"
                  type="button"
                >
                  {{ $t("advanced_capitalize") }}
                </button>
              </li>
              <li
                v-if="user.company.pro_mode"
                class="nav-item"
                role="presentation"
              >
                <button
                  id="reluctance-tab"
                  ref="tab-reluctance"
                  aria-controls="reluctance"
                  aria-selected="false"
                  class="nav-link"
                  data-bs-target="#reluctance"
                  data-bs-toggle="tab"
                  data-test="reluctanceTab-button"
                  role="tab"
                  type="button"
                >
                  {{ $t("reluctance_capitalize") }}
                </button>
              </li>
            </ul>
          </div>
          <div class="col-lg-12">
            <div class="tab-content">
              <!-- GENERAL -->
              <div
                id="geral"
                aria-labelledby="home-tab"
                class="tab-pane fade show active"
                role="tabpanel"
              >
                <div class="row mt-5">
                  <div class="col-lg-12">
                    <app-input
                      v-model="fields.name"
                      :errors="errors.name"
                      :label="$t('capitalizes.name')"
                    />
                  </div>

                  <div class="col-lg-6">
                    <div class="form-group">
                      <label class="form-label required">{{
                        $t("initial_hour")
                          .charAt(0)
                          .toUpperCase() + $t("initial_hour")
                            .slice(1)
                      }}</label>
                      <div class="input-group input-group-merge">
                        <input
                          ref="start_time"
                          v-model="fields.start_time"
                          :class="{
                            'form-control': true,
                            'is-invalid': errors.start_time
                          }"
                          aria-label="Input group appended"
                          data-test="startTime-field"
                          type="text"
                        >
                        <div class="input-group-text">
                          <span class="fe fe-clock" />
                        </div>
                      </div>
                      <div class="text-danger fs-5">
                        {{ errors.start_time && errors.start_time[0] }}
                      </div>
                    </div>
                  </div>

                  <div class="col-lg-6">
                    <div class="form-group">
                      <label class="form-label required">
                        {{
                          $t("final_hour")
                            .charAt(0)
                            .toUpperCase() + $t("final_hour")
                              .slice(1)
                        }}</label>
                      <div class="input-group input-group-merge">
                        <input
                          ref="end_time"
                          v-model="fields.end_time"
                          :class="{
                            'form-control': true,
                            'is-invalid': errors.end_time
                          }"
                          aria-label="Input group appended"
                          data-test="endTime-field"
                          type="text"
                        >
                        <div class="input-group-text">
                          <span class="fe fe-clock" />
                        </div>
                      </div>
                      <div class="text-danger fs-5">
                        {{ errors.end_time && errors.end_time[0] }}
                      </div>
                    </div>
                  </div>

                  <div class="col-lg-6">
                    <div class="form-group">
                      <label class="form-label required text-capitalize">{{ $t("qualifications") }}</label>
                      <select-qualification-list
                        v-model="fields.qualification_list"
                        :errors="errors.qualification_list"
                      />
                    </div>
                  </div>

                  <div class="col-lg-6">
                    <div class="form-group">
                      <label class="form-label text-capitalize">{{ $t("breaks") }}</label>
                      <select-interval-list
                        v-model="fields.work_break_group_id"
                        :errors="errors.work_break_group_id"
                      />
                    </div>
                  </div>

                  <div class="col-lg-6">
                    <div class="form-group">
                      <label class="form-label">{{
                        $t("list_of_criteria")
                          .charAt(0)
                          .toUpperCase() + $t("list_of_criteria")
                            .slice(1)
                      }}</label>
                      <select-criterion-list
                        v-model="fields.criterion_list"
                        :errors="errors.criterion_list"
                      />
                    </div>
                  </div>

                  <div class="col-lg-6">
                    <app-input
                      v-model="fields.acw_timeout"
                      :label="$t('qualification_time_CTA')"
                      :min="0"
                      :errors="errors.acw_timeout"
                      :required="false"
                      type="number"
                      :tooltip="$t('tooltips.info_acw_option')"
                    />
                  </div>

                  <div :class="[isBrazil ? 'col-lg-6' : 'col-12']">
                    <div class="form-group">
                      <label class="form-label">{{ isBrazil ? $t("routes_to_phone") : $t("routes_capitalize") }}</label>
                      <select-route
                        v-model="landline"
                        :allow-mobile="false"
                        :errors="[
                          errors.landline_id,
                          errors.landline_type,
                          errors.international_type,
                          errors.international_type
                        ]"
                      />
                    </div>
                  </div>

                  <div
                    class="col-lg-6"
                    v-if="isBrazil"
                  >
                    <div class="form-group">
                      <label class="form-label">{{
                        $t("routes_to_cellphone")
                          .charAt(0)
                          .toUpperCase() + $t("routes_to_cellphone")
                            .slice(1)
                      }}</label>
                      <select-route
                        v-model="mobile"
                        :allow-landline="false"
                        :errors="[errors.mobile_id, errors.mobile_type]"
                      />
                    </div>
                  </div>

                  <div class="col-lg-12">
                    <div class="form-group">
                      <label class="form-label d-flex align-items-center">
                        {{ $t("integration_url") }}

                        <button
                          ref="moreInfoURL"
                          class="btn btn-link p-0"
                          data-test="moreInfoURL-button"
                          type="button"
                        >
                          <span
                            ref="moreInfoURL"
                            class="fe fe-info text-primary ms-2 cursor-pointer"
                          />
                        </button>
                      </label>
                      <input
                        v-model="fields.url"
                        :class="{
                          'form-control': true,
                          'is-invalid': errors.url || errorsFront.url
                        }"
                        class="form-control"
                        data-test="url-field"
                        placeholder="https://"
                      >
                      <div class="invalid-feedback">
                        {{ errors.url ? errors.url[0] : errorsFront.url && errorsFront.url[0] }}
                      </div>
                    </div>
                  </div>

                  <div class="col-lg-12">
                    <app-button
                      class="w-100"
                      color="btn-primary"
                      @click="redirectToTheNextPage"
                    >
                      {{ $t("next") }}
                    </app-button>
                  </div>
                </div>
              </div>

              <!-- AGENTS -->
              <div
                id="agents"
                aria-labelledby="home-tab"
                class="tab-pane fade show"
                role="tabpanel"
              >
                <div class="row mt-5">
                  <div class="col-lg-12 b1 mb-5">
                    <form-teams
                      :selected-teams="campaign.teams"
                      @update-teams="updateTeams"
                    />
                  </div>
                  <div class="col-lg-12 b1 mb-5">
                    <form-agents
                      v-if="campaign"
                      :campaign="campaign"
                    />
                  </div>

                  <div class="col-lg-12">
                    <app-button
                      :loading="loading"
                      class="w-100"
                      color="btn-primary"
                      @click="tab_strategy.show()"
                    >
                      {{ $t("next") }}
                    </app-button>
                  </div>

                  <div class="col-lg-12 mt-3">
                    <app-button
                      class="w-100"
                      color="btn-secondary"
                      @click="tab_general.show()"
                    >
                      {{ $t("back") }}
                    </app-button>
                  </div>
                </div>
              </div>

              <!-- STRATEGY -->
              <div
                id="strategy"
                aria-labelledby="home-tab"
                class="tab-pane fade show"
                role="tabpanel"
              >
                <div class="row mt-5">
                  <div
                    v-if="ura_licenses"
                    class="col-lg-6"
                  >
                    <app-input
                      v-model="fields.ura_limit"
                      :label="$t('ivr_channel_limit')"
                      :errors="errors.ura_limit"
                      type="number"
                      :small-text="
                        $t('you_have') +
                          ` ${companyUraLimit} ${
                            companyUraLimit === 1 ? $t('available_channel') : $t('available_channels')
                          }`
                      "
                    />
                  </div>
                  <div class="col-lg-6">
                    <app-input
                      v-model="fields.exit_manual_mode"
                      :label="$t('dialing_time_in_manual_mode')"
                      :min="0"
                      :errors="errors.exit_manual_mode"
                      :small-text="$t('seconds_unlimited')"
                    />
                  </div>

                  <div class="col-lg-6">
                    <app-input
                      v-model="fields.wait_time"
                      :label="$t('waiting_time')"
                      :min="0"
                      :small-text="$t('in_seconds')"
                      :errors="errors.wait_time"
                    />
                  </div>
                  <div class="col-lg-6">
                    <app-input
                      v-model="fields.limit_call_time"
                      :label="$t('limit_time_per_call')"
                      :min="0"
                      :errors="errors.limit_call_time"
                      :small-text="$t('in_minutes')"
                    />
                  </div>

                  <div
                    :class="{
                      'col-lg-6': !user.company.pro_mode,
                      'col-lg-12': user.company.pro_mode
                    }"
                  >
                    <app-input
                      v-model="fields.limit_call_per_agent"
                      :label="$t('limit_calls_per_agent')"
                      :min="0"
                      :errors="errors.limit_call_per_agent"
                      :small-text="$t('limit_system')"
                    />
                  </div>

                  <div
                    v-if="!user.company.pro_mode"
                    class="col-lg-6"
                  >
                    <app-input
                      v-model="fields.recalls"
                      :label="$t('dialing_attempt')"
                      :min="0"
                      :errors="errors.recalls"
                    />
                  </div>

                  <div class="col-lg-12 mt-5">
                    <div class="col-auto form-check form-switch">
                      <label class="form-label">{{ $t("IVR_after_call") }}</label>
                      <input
                        v-model="fields.ivr_after_call_status"
                        class="form-check-input"
                        data-test="checkIvrAfterCall-checkbox"
                        type="checkbox"
                        @change="clearIvrRelatedFields"
                      >
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="form-group">
                      <span class="form-text" />
                      <select
                        v-model="fields.behavior"
                        :class="{ 'is-invalid': errors.behavior }"
                        :disabled="!fields.ivr_after_call_status"
                        class="form-select"
                        data-test="ivrBehavior-select"
                      >
                        <option
                          disabled
                          selected
                          value=""
                        >
                          {{ $t("behavior") }}
                        </option>
                        <option value="all_calls">
                          {{ $t("all_calls") }}
                        </option>
                        <option value="when_agent_sends">
                          {{ $t("when_operator_sends") }}
                        </option>
                      </select>
                      <div class="invalid-feedback">
                        {{ errors.behavior && errors.behavior[0] }}
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="form-group">
                      <span class="form-text" />
                      <select-after-call-ivr-list
                        v-model="fields.ivr_after_call_id"
                        :disabled="!fields.ivr_after_call_status"
                        :errors="errors.ivr_after_call_id"
                        :per-page="-1"
                      />
                    </div>
                  </div>

                  <div
                    class="col-lg-12 mb-4"
                    style="margin-top: 1rem;"
                  >
                    <div class="d-flex align-items-center gap-3">
                      <hr class="w-100">
                      <button
                        :class="!showAdvancedOptions ? 'btn-light' : 'btn-primary'"
                        class="btn text-nowrap text-small show-more-button"
                        data-test="showAdvancedOptions-button"
                        @click="handleShowAdvancedOptions"
                      >
                        <i
                          :class="!showAdvancedOptions ? 'fe fe-chevron-down' : 'fe fe-chevron-up'"
                          class="me-2"
                        />
                        {{
                          $t("advanced_options")
                            .charAt(0)
                            .toUpperCase() + $t("advanced_options")
                              .slice(1)
                        }}
                      </button>
                      <hr class="w-100">
                    </div>
                  </div>

                  <div v-show="showAdvancedOptions">
                    <div
                      class="card p-4"
                      style="margin-top: 33px;"
                    >
                      <div class="row">
                        <div
                          v-for="checkboxCardInfoStrategy in checkboxCardInfosStrategy"
                          :key="checkboxCardInfoStrategy.value"
                          class="col-lg-6"
                        >
                          <div class="form-group">
                            <div class="form-check form-switch mb-2">
                              <input
                                v-model="fields[checkboxCardInfoStrategy.value]"
                                :data-test="checkboxCardInfoStrategy.dataTest"
                                class="form-check-input"
                                type="checkbox"
                                @click="event => checkboxCardInfoStrategy.onClick(event)"
                              >
                              <label class="form-check-label fs-5">
                                {{ $t(checkboxCardInfoStrategy.title) }}
                                <tooltip-component
                                  v-if="!!checkboxCardInfoStrategy.tooltip"
                                  :class="'ms-1'"
                                  :title="checkboxCardInfoStrategy.tooltip"
                                />
                              </label>
                            </div>
                            <span class="form-text text-small">
                              {{ $t(checkboxCardInfoStrategy.description) }}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  v-if="pro_mode"
                  class="col-lg-12"
                >
                  <app-button
                    :loading="loading"
                    class="w-100"
                    color="btn-primary"
                    @click="tab_advanced.show()"
                  >
                    {{ $t("next") }}
                  </app-button>
                </div>

                <div
                  v-else
                  class="col-lg-12"
                >
                  <app-button
                    color="btn-primary text-capitalize"
                    class="w-100"
                    @click="submit()"
                  >
                    {{ $t("save") }}
                  </app-button>
                </div>

                <div class="col-lg-12 mt-3">
                  <app-button
                    color="btn-secondary text-capitalize"
                    class="w-100"
                    @click="tab_agents.show()"
                  >
                    {{ $t("back") }}
                  </app-button>
                </div>
              </div>

              <!-- ADVANCED -->
              <div
                id="advanced"
                aria-labelledby="home-tab"
                class="tab-pane fade show"
                role="tabpanel"
              >
                <div class="row mt-5">
                  <div class="col-lg-6">
                    <app-input
                      v-model="fields.call_time"
                      :label="$t('call_duration')"
                      :min="0"
                      :small-text="$t('in_seconds')"
                      type="number"
                    />
                  </div>
                  <div class="col-lg-6">
                    <app-input
                      v-model="fields.min_idle_time"
                      :label="$t('minimum_idle_time')"
                      :min="0"
                      :errors="errors.min_idle_time"
                      :small-text="$t('in_seconds')"
                      type="number"
                    />
                  </div>

                  <div
                    class="col-lg-12 mb-4"
                    style="margin-top: 1rem;"
                  >
                    <div class="d-flex align-items-center gap-3">
                      <hr class="w-100">
                      <button
                        :class="!showAdvancedOptions ? 'btn-light' : 'btn-primary'"
                        class="btn text-nowrap text-small show-more-button"
                        data-test="showAdvancedOptions-button"
                        @click="showAdvancedOptions = !showAdvancedOptions"
                      >
                        <i
                          :class="!showAdvancedOptions ? 'fe fe-chevron-down' : 'fe fe-chevron-up'"
                          class="me-2"
                        />
                        {{
                          $t("advanced_options")
                            .charAt(0)
                            .toUpperCase() + $t("advanced_options")
                              .slice(1)
                        }}
                      </button>
                      <hr class="w-100">
                    </div>
                  </div>

                  <div v-show="showAdvancedOptions">
                    <div
                      class="card p-4"
                      style="margin-top: 33px;"
                    >
                      <div class="row">
                        <div
                          v-for="(checkboxCardInfo, index) in checkboxCardInfos"
                          :key="checkboxCardInfo.value"
                          class="col-lg-6"
                        >
                          <div
                            v-if="(!isBrazil && index < 1) || isBrazil"
                            class="form-group"
                          >
                            <div class="form-check form-switch mb-2">
                              <input
                                :id="checkboxCardInfo.value"
                                v-model="fields[checkboxCardInfo.value]"
                                :data-test="checkboxCardInfo.dataTest"
                                class="form-check-input"
                                type="checkbox"
                                @click="checkboxCardInfo.onClick"
                              >
                              <label
                                :for="checkboxCardInfo.value"
                                class="form-check-label fs-5"
                              >
                                {{ $t(checkboxCardInfo.title) }}
                                <tooltip-component
                                  v-if="!!checkboxCardInfo.tooltip"
                                  :class="'ms-1'"
                                  :title="checkboxCardInfo.tooltip"
                                />
                              </label>
                            </div>
                            <span class="form-text text-medium">
                              {{ $t(checkboxCardInfo.description) }}
                              <span
                                v-if="checkboxCardInfo.value === 'check_ddd'"
                                class="text-primary cursor-pointer"
                                data-test="verifyDialRules-button"
                                @click="$refs.dialingRule.openModal()"
                              >
                                {{
                                  $t("verify_rules")
                                    .charAt(0)
                                    .toUpperCase() + $t("verify_rules")
                                      .slice(1)
                                }}
                              </span>
                            </span>
                          </div>
                        </div>
                        <dialing-rule ref="dialingRule" />
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-lg-12">
                  <app-button
                    v-if="user.company.pro_mode"
                    class="w-100"
                    :loading="loading"
                    color="btn-primary"
                    @click="tab_reluctance.show()"
                  >
                    {{ $t("next") }}
                  </app-button>
                  <app-button
                    v-else
                    color="btn-primary text-capitalize"
                    class="w-100"
                    @click="submit()"
                  >
                    {{ $t("save") }}
                  </app-button>
                </div>

                <div class="col-lg-12 mt-3">
                  <app-button
                    class="w-100"
                    color="btn-secondary"
                    @click="tab_strategy.show()"
                  >
                    {{ $t("back") }}
                  </app-button>
                </div>
              </div>
              <!-- TODO: Verificar o id:agents  -->
              <div
                id="agents"
                aria-labelledby="home-tab"
                class="tab-pane fade show"
                role="tabpanel"
              >
                <div class="row mt-5">
                  <div class="col-lg-12 b1 mb-5">
                    <form-teams
                      :selected-teams="campaign.teams"
                      @update-teams="updateTeams"
                    />
                  </div>
                  <div class="col-lg-12 b1 mb-5">
                    <form-agents
                      v-if="campaign"
                      :campaign="campaign"
                    />
                  </div>

                  <div class="col-lg-12">
                    <app-button
                      :loading="loading"
                      class="w-100"
                      color="btn-primary"
                      @click="tab_advanced.show()"
                    >
                      {{ $t("next") }}
                    </app-button>
                  </div>

                  <div class="col-lg-12 mt-3">
                    <app-button
                      class="w-100"
                      color="btn-secondary"
                      @click="tab_general.show()"
                    >
                      {{ $t("back") }}
                    </app-button>
                  </div>
                </div>
              </div>

              <reluctance-tab
                v-if="user.company.pro_mode"
                :tab-advanced="tab_advanced"
                @submit="submit"
              />
            </div>
          </div>
        </div>
        <div
          ref="toastAlert"
          class="toast align-items-center bg-success border-0 me-0 my-2"
          role="alert"
          style="
            position: fixed;
            color: white;
            bottom: 2.5rem !important;
            right: 1rem !important;
            width: 50%;
            "
        >
          <div class="d-flex me-0">
            <div class="toast-body me-0">
              {{ $t("campaign_capitalize") }}
              {{ this.campaign.paused == false ? $t("function_active") : $t("paused_singular") }}
              {{ $t("successfully") }}
            </div>
            <button
              aria-label="Close"
              class="btn-close btn-close-white me-2 m-auto me-0"
              data-bs-dismiss="toast"
              data-test="manager_edit_campaign_button_close_toast"
              type="button"
            />
          </div>
        </div>
      </div>
    </div>
    <main-loading v-else-if="loading" />
  </div>

  <!-- Modal confirmation -->
  <modal-component
    :show="showConfirmExcludeModal"
    @close="showConfirmExcludeModal = false"
  >
    <template #content>
      <div class="modal-header">
        <h1>{{ $t("are_you_sure") }}</h1>
        <button
          class="btn-close"
          type="button"
          @click="showConfirmExcludeModal = false"
        />
      </div>
      <div class="modal-body">
        <p v-if="paginationCampaignSchedules.total">
          &#128680; {{ $t("attention_there_is") }}
          <strong> {{ paginationCampaignSchedules.total }} </strong>
          {{
            paginationCampaignSchedules.total > 1
              ? $t("appointments_will_be_removed")
              : $t("appointment_will_be_removed")
          }}
          {{ $t("along_campaign") }}
        </p>
        <p v-if="campaign">
          {{ $t("delete_campaign_confirmation") }}
          <strong>{{ campaign.name }}</strong>? {{ $t("cannot_undo_this_operation") }}
          <strong>{{ $t("campaign_name_to_exclude") }}</strong>
        </p>
        <form
          id="campaignName"
          @submit.prevent="removeCampaign()"
        >
          <input
            v-model="campaignNameToDelete"
            :class="{
              'is-invalid': isCampaignNameCorrect === false
            }"
            class="form-control"
            data-test="campaignNameToDelete-field"
            type="text"
          >
          <div class="invalid-feedback">
            {{ $t("campaign_right_name") }}
          </div>
        </form>
      </div>
      <div class="modal-footer">
        <button
          class="btn btn-secondary"
          @click="showConfirmExcludeModal = false"
        >
          {{ $t("cancel_general") }}
        </button>
        <button
          class="btn btn-primary"
          form="campaignName"
        >
          {{ $t("capitalizes.exclude") }}
        </button>
      </div>
    </template>
  </modal-component>
</template>

<script>
import flatpickr from "flatpickr";
import { Portuguese } from "flatpickr/dist/l10n/pt.js";

import { Tab, Toast, Tooltip } from "bootstrap";
import { mapActions, mapGetters, mapMutations } from "vuex";

import selectQualificationList from "@dialer/manager/components/select-qualification-list";
import SelectIntervalList from "@dialer/manager/components/select-interval-list";
import SelectCriterionList from "@dialer/manager/components/select-criterion-list";
import SelectRoute from "@dialer/manager/components/select-route";
import SelectAfterCallIvrList from "@dialer/manager/components/select-after-call-ivr-list";
import AppButton from "@components/app-button";
import AppInput from "@components/app-input";
import mainLoading from "@/components/main-loading";
import FormAgents from "./components/form-agents.vue";
import FlashMessage from "@components/flash-message";
import FormTeams from "./components/form-teams.vue";
import ModalComponent from "@/components/modal-component.vue";
import HeaderComponent from "@/components/header-component.vue";
import ReluctanceTab from "./components/tablist/reluctance-tab.vue";
import TooltipComponent from "@/components/tooltip-component.vue";
import DialingRule from "./components/dialing-rule.vue";

export default {
  data() {
    return {
      fields: {
        active_list_notify: false,
        acw_timeout: 0,
        agent_dashboard: false,
        allows_manual: true,
        call_time: 32,
        caller_id: "",
        check_amd: true,
        check_blacklist: true,
        check_ddd: false,
        check_dnd: false,
        copy_identifier: false,
        criterion_list: "",
        end_time: "18:30",
        exit_manual_mode: 0,
        extension_number: "",
        check_smart_filter: false,
        is_predictive: false,
        ivr_after_call_status: false,
        ivr_after_call_id: "",
        behavior: "",
        landline_id: "",
        landline_type: "",
        limit_call_per_agent: 0,
        limit_call_time: 0,
        min_idle_time: 0,
        mobile_id: "",
        mobile_type: "",
        name: "",
        qualification_list: "",
        recalls: 3,
        should_complete_failed_call: true,
        start_time: "08:00",
        ura_limit: 0,
        url: "",
        update_mailing_data: false,
        wait_time: 3,
        wait_time_without_min_idle_time: 0,
        work_break_group_id: "",
        horizontal_dial: false,
        advancedOptions: false,
      },

      pro_mode: false,

      mobile: {
        id: null,
        type: null,
      },
      landline: {
        id: null,
        type: null,
      },
      tab_advanced: null,
      tab_general: null,
      tab_agents: null,
      tab_reluctance: null,
      tab_strategy: null,
      showAdvancedOptions: false,
      minTimeFlatpicker: null,
      maxTimeFlatpicker: null,
      moreInfoURLTooltip: null,
      teamsInfoTooltip: null,
      toastAlert: null,
      ura_licenses: 0,
      showModal: false,

      // delete campaign
      showConfirmExcludeModal: false,
      campaignNameToDelete: "",
      isCampaignNameCorrect: null,
      // validations from front-end
      errorsFront: {
        url: null,
      },
      limit_unproductive_calls: 0,

      hangup_groups: {
        not_answered: {
          name: this.$t("not_answered"),
          tooltipInfo: `${this.$t("tooltips.hangup_groups")}`,
          retry_strategy_id: 148,
          attempts: 10,
          interval: 1000,
        },
        abandoned: {
          name: this.$t("capitalizes.abandonment"),
          tooltipInfo: `${this.$t("tooltips.abandoned_info")}`,
          retry_strategy_id: 149,
          attempts: 9,
          interval: 900,
        },
        amd: {
          name: this.$t("amd_reluctance"),
          tooltipInfo: `${this.$t("tooltips.amd_info")}`,
          retry_strategy_id: 150,
          attempts: 8,
          interval: 800,
        },
        busy: {
          name: this.$t("busy"),
          tooltipInfo: `${this.$t("tooltips.busy_info")}`,
          retry_strategy_id: 151,
          attempts: 7,
          interval: 700,
        },
        invalid_number: {
          name: this.$t("invalid_number"),
          tooltipInfo: `${this.$t("tooltips.invalid_number_info")}`,
          retry_strategy_id: 152,
          attempts: 6,
          interval: 600,
        },
        call_denied: {
          name: this.$t("call_denied"),
          tooltipInfo: `${this.$t("tooltips.call_denied_info")}`,
          retry_strategy_id: 153,
          attempts: 5,
          interval: 500,
        },
        external_congestion: {
          name: this.$t("external_congestion"),
          tooltipInfo: `${this.$t("tooltips.external_congestion_info")}`,
          retry_strategy_id: 154,
          attempts: 4,
          interval: 400,
        },
        impossible_to_complete: {
          name: this.$t("impossible_to_complete"),
          tooltipInfo: `${this.$t("tooltips.impossible_to_complete_info")}`,
          retry_strategy_id: 155,
          attempts: 3,
          interval: 300,
        },
      },
    };
  },

  mounted() {
    this.fetchCampaignById(this.$route.params.id)
        .then(() => {
          this.tab_advanced = new Tab(this.$refs["tab-advanced"]);
          this.tab_general = new Tab(this.$refs["tab-general"]);
          this.tab_agents = new Tab(this.$refs["tab-agents"]);
          this.tab_reluctance = new Tab(this.$refs["tab-reluctance"]);
          this.tab_strategy = new Tab(this.$refs["tab-strategy"]);
          this.limit_unproductive_calls = this.user.company.limit_unproductive_calls;
          this.enable_improductive_calls_limit = this.limit_unproductive_calls > 0;

          this.toastAlert = new Toast(this.$refs["toastAlert"]);

          this.minTimeFlatpicker = flatpickr(this.$refs.start_time, {
            locale: Portuguese,
            enableTime: true,
            noCalendar: true,
            dateFormat: "H:i",
          });
          this.maxTimeFlatpicker = flatpickr(this.$refs.end_time, {
            locale: Portuguese,
            enableTime: true,
            noCalendar: true,
            dateFormat: "H:i",
          });

          this.generateTooltips();
        });

    this.pro_mode = this.user.company.pro_mode;

    this.ura_licenses = this.user.company.ura_licenses;
    this.loadCompanyUraLimit();
  },

  components: {
    selectQualificationList,
    SelectIntervalList,
    SelectCriterionList,
    SelectRoute,
    SelectAfterCallIvrList,
    AppButton,
    FlashMessage,
    AppInput,
    mainLoading,
    FormAgents,
    FormTeams,
    ModalComponent,
    HeaderComponent,
    ReluctanceTab,
    TooltipComponent,
    DialingRule,
  },
  emits: ["limit-calls-updated"],

  /**
   * Getters
   */
  computed: {
    ...mapGetters("manager/campaign", {
      campaign: "getCampaign",
      errors: "getErrors",
      loading: "isLoading",
      loadingContext: "getLoadingContext",
      companyUraLimit: "getCompanyUraLimit",
    }),

    ...mapGetters("auth", {
      user: "getUser",
      isBrazil: "isBrazil",
    }),

    ...mapGetters("manager/schedules", {
      loadingSchedules: "isLoadingSchedules",
      paginationCampaignSchedules: "getPaginationCampaignSchedules",
    }),
    is_predictive() {
      return this.fields.is_predictive;
    },
    ivr_after_call_id() {
      return this.fields.ivr_after_call_id;
    },

    checkboxCardInfos() {
      const data = [
        {
          title: "area_code_rules",
          description: "area_code_rules_description",
          value: "check_ddd",
          onClick: () => null,
          show: true,
          dataTest: "activeDialingRule-checkbox",
          tooltip: "",
        },
      ];

      return data.filter(checkboxCardInfo => checkboxCardInfo.show);
    },
    checkboxCardInfosStrategy() {
      const allowedPlans = [2, 3];

      const data = [
        {
          title: `email_notification`,
          description: "email_notification_description",
          value: "active_list_notify",
          onClick: () => null,
          show: true,
          dataTest: "activeListNotify-checkbox",
          tooltip: "",
        },
        {
          title: "automatic_ctrl_c",
          description: "automatic_ctrl_c_description",
          value: "copy_identifier",
          onClick: () => null,
          show: true,
          dataTest: "copyIdentifier-checkbox",
          tooltip: "",
        },
        {
          title: "discard_unproductive_calls",
          description: "discard_unproductive_calls_description",
          value: "check_amd",
          onClick: () => null,
          show: true,
          dataTest: "checkAmd-checkbox",
          tooltip: "",
        },
        {
          title: "manul_call",
          description: "manul_call_description",
          value: "allows_manual",
          onClick: () => null,
          show: true,
          dataTest: "allowsManual-checkbox",
          tooltip: "",
        },
        {
          title: "productive",
          description: "productive_description",
          value: "is_predictive",
          onClick: () => null,
          show: true,
          dataTest: "isPredictive-checkbox",
          tooltip: `
            <div style="text-align: left;">
              <strong style="font-size: 1rem">Productive</strong> <br>
              <span style="font-size: 1rem;">${this.$t("productive_mode")}</span>
              <br></br>
              <strong style="font-size: 1rem">${this.$t("important_tips")}</strong>
              <ul style="font-size: 1rem; padding-left: 1rem;">
                <li class="d-flex align-items-start"> <div class="pe-2 pt-2" style="font-size: 6px">&#x2B24;</div> <div> ${this.$t(
              "productive_recommendations",
          )}</div></li>
                <li class="d-flex align-items-start"> <div class="pe-2 pt-2" style="font-size: 6px">&#x2B24;</div> <div> ${this.$t(
              "productive_recommendations_2",
          )}</div></li>
                <li class="d-flex align-items-start"> <div class="pe-2 pt-2" style="font-size: 6px">&#x2B24;</div> <div> ${this.$t(
              "productive_recommendations_3",
          )}</div></li>
              </ul>
            </div>`,
        },
        {
          title: "enable_mailing_edition",
          description: "enable_mailing_edition_description",
          value: "update_mailing_data",
          onClick: () => null,
          show: true,
          dataTest: "updateMailingData-checkbox",
          tooltip: "",
        },
        {
          title: "conclude_failed_numbers",
          description: "conclude_failed_numbers_description",
          value: "should_complete_failed_call",
          onClick: () => null,
          show: allowedPlans.includes(this.user.company.plan) && !this.user.company.pro_mode,
          dataTest: "shouldCompleteFailedCall-checkbox",
          tooltip: "",
        },
        {
          title: "smart_filter",
          description: "smart_filter_description",
          value: "check_smart_filter",
          onClick: () => null,
          show: allowedPlans.includes(this.user.company.plan),
          dataTest: "filterCalls-checkbox",
          tooltip: "",
        },
        {
          title: "do_not_disturb",
          description: "do_not_disturb_description",
          value: "check_dnd",
          onClick: () => null,
          show: allowedPlans.includes(this.user.company.plan),
          dataTest: "checkDnd-checkbox",
          tooltip: "",
        },
        {
          title: "blocklist_capitalize",
          description: "blocklist_description",
          value: "check_blacklist",
          onClick: () => null,
          show: allowedPlans.includes(this.user.company.plan),
          dataTest: "checkBlacklist-checkbox",
          tooltip: "",
        },
        {
          title: "agent_dashboard",
          description: "agent_dashboard_description",
          value: "agent_dashboard",
          onClick: () => null,
          show: true,
          dataTest: "activeDialingRule-checkbox",
          tooltip: "",
        },
        {
          title: "horizontal_dialing",
          description: "horizontal_dialing_description",
          value: "horizontal_dial",
          onClick: async event => this.showConfirmationModal(event),
          show: true,
          dataTest: "horizontalDial-checkbox",
        },
      ];
      return data.filter(checkboxCardInfo => checkboxCardInfo.show);
    },
  },

  watch: {
    campaign(val) {
      if (val) {
        this.fields.active_list_notify = val.active_list_notify;
        this.fields.acw_timeout = val.acw_timeout;
        this.fields.allows_manual = val.allows_manual;
        this.fields.call_time = val.dialer_settings.call_time;
        this.fields.caller_id = val.caller_id;
        this.fields.check_amd = val.amd_enabled;
        this.fields.check_blacklist = val.check_blacklist;
        this.fields.check_ddd = val.check_ddd;
        this.fields.copy_identifier = val.copy_identifier;
        this.fields.criterion_list =
            val.dialer_settings.criterion_list_id == null ? "" : val.dialer_settings.criterion_list_id;
        this.fields.end_time = val.end_time;
        this.fields.exit_manual_mode = val.exit_manual_mode;
        this.fields.extension_number = val.extension ? val.extension.extension_number : "";
        this.fields.check_smart_filter = val.check_smart_filter;
        this.fields.check_dnd = val.check_dnd;
        this.fields.agent_dashboard = val.agent_dashboard;
        this.fields.check_blacklist = val.check_blacklist;
        this.fields.is_predictive = val.is_predictive;
        this.fields.ivr_after_call_id = val.ivr_after_call_id ? val.ivr_after_call_id : "";
        this.fields.ivr_after_call_status = val.ivr_after_call_status;
        this.fields.behavior = val.behavior;
        this.fields.limit_call_per_agent = val.limit_call_per_agent;
        this.fields.limit_call_time = val.limit_call_time;
        this.fields.min_idle_time = val.min_idle_time;
        this.fields.name = val.name;
        this.fields.qualification_list = val.dialer_settings.qualification_list_id;
        this.fields.recalls = val.dialer_settings.recalls;
        this.fields.should_complete_failed_call = val.should_complete_failed_call;
        this.fields.start_time = val.start_time;
        this.fields.teams = val.teams;
        this.fields.ura_limit = val.ura_limit;
        this.fields.url = val.dialer_settings.url;
        this.fields.update_mailing_data = val.update_mailing_data;
        this.fields.wait_time = val.dialer_settings.wait_time - val.min_idle_time;
        this.fields.work_break_group_id = val.work_break_group ? val.work_break_group.id : "";

        this.landline.id = val.route_landline_id ? val.route_landline_id : val.route_group_landline_id;
        this.landline.type = val.route_landline_id ? "route" : "group";
        this.mobile.id = val.route_mobile_id ? val.route_mobile_id : val.route_group_mobile_id;
        this.mobile.type = val.route_mobile_id ? "route" : "group";
        if (!this.isBrazil) {
          this.landline.id = val.international_route_id ? val.international_route_id : val.international_route_group_id;
          this.landline.type = val.international_route_id ? "route" : "group";
        }
        this.fields.horizontal_dial = val.horizontal_dial;
      }
    },
    enable_improductive_calls_limit(isEnabled) {
      if (!isEnabled) {
        this.limit_unproductive_calls = 0;
        this.changeUnproductiveCallsLimit();
      }
    },
    limit_unproductive_calls(limit) {
      this.$emit("limit-calls-updated", limit);
    },
  },

  methods: {
    ...mapActions("manager/campaign", [
      "update",
      "fetchCampaignById",
      "pause",
      "resume",
      "remove",
      "loadCompanyUraLimit",
    ]),
    ...mapActions("system", ["showConfirmationMessage"]),
    ...mapActions("manager/schedules", ["searchSchedulesByCampaignId"]),

    ...mapMutations("system", ["setFlashMessage"]),
    ...mapMutations("auth", ["trackEvents"]),

    handleShowAdvancedOptions() {
      this.showAdvancedOptions = !this.showAdvancedOptions;
      this.trackEvents("[Manager] Edit advanced campaign");
    },
    changeUnproductiveCallsLimit() {
      this.updateUnproductiveCallsLimit({
        fields: {
          limit_unproductive_calls: this.limit_unproductive_calls,
        },
      });
    },

    updateWaitTime() {
      const waitTime = this.fields.wait_time;
      const minIdleTime = this.fields.min_idle_time;
      const newWaitTime = waitTime - minIdleTime;
      this.fields.wait_time = newWaitTime;
    },

    redirectToTheNextPage() {
      const validations = this.handleUrlValidation();
      if (validations) {
        this.tab_agents.show();
      }
    },

    async showConfirmationModal(event) {
      event.preventDefault();
      let response = await this.showConfirmationMessage({
        title: this.$t("horizontal_dialing"),
        text: this.$t("only_new_lists"),
        type: "goodWarning",
      });

      if (response.isConfirmed) {
        this.fields.horizontal_dial = !this.fields.horizontal_dial;
      }
    },

    handleUrlValidation() {
      this.errorsFront.url = [];

      const domains = [".3c.plus", ".3c.fluxoti", ".3cplus"];
      let is3cDomain = domains.find(domain => this.fields.url.includes(domain));

      // regex to url validation
      const pattern = /^(https:\/\/|http:\/\/)/;
      let isUrl = pattern.test(this.fields.url);

      if (!isUrl) {
        this.errorsFront.url.push(this.$t("errors.invalid_url"));
      } else if (is3cDomain) {
        this.errorsFront.url.push(this.$t("errors.invalid_3c_url"));
      } else this.errorsFront.url = "";

      if (!this.fields.url || !this.errorsFront.url) {
        this.errorsFront.url = "";
        return true;
      }
      return false;
    },

    submit() {
      const validations = this.handleUrlValidation();
      if (!validations) {
        return this.tab_general.show();
      }

      let fields = JSON.parse(JSON.stringify(this.fields));

      if (this.isBrazil) {
        if (this.landline.id) {
          fields.landline_id = this.landline.id;
          fields.landline_type = this.landline.type;
        }
        if (this.mobile.id) {
          fields.mobile_id = this.mobile.id;
          fields.mobile_type = this.mobile.type;
        }
      } else {
        fields.international_id = this.landline.id ? this.landline.id : "";
        fields.international_type = this.landline.id ? this.landline.type : "";
      }

      fields.teams = fields.teams.map(team => team.id);
      // fields.agent_dashboard = false // Temporário;

      this.update({
        fields: fields,
        id: this.$route.params.id,
      })
          .then(() => {
            this.$router.push({
              path: `/manager/campaign/${this.$route.params.id}`,
            });
            this.trackEvents("[Manager] Update campaign parameter");
          })
          .catch(() => {
            this.tab_general.show();
          });
    },

    updateTeams(teams) {
      this.fields.teams = teams;
    },

    generateTooltips() {
      this.generateMoreInfoURLTooltip();
    },

    generateMoreInfoURLTooltip() {
      let tooltip = document.createElement("div");
      tooltip.innerHTML = `
        <div style="text-align: left; width: 550px;">
          <span style="font-size: 1rem">
              ${this.$t("tooltips.generate_more_info_url")}
              https://minhaurldeintegracao.com.br?variavel1=[variavel1]&amp;telefone=[telefone]&amp;ramal=[ramal]
              &amp;protocolo=[protocolo]&amp;identificador=[identificador]&amp;id_campanha=[id_campanha]
          </span>
        </div>`;

      this.moreInfoURLTooltip = new Tooltip(this.$refs.moreInfoURL, {
        html: true,
        placement: "top",
        title: tooltip,
      });
    },

    resumeCampaign() {
      this.resume({ id: this.campaign.id })
          .then(() => {
            this.toastAlert.show();
            this.campaign.paused = false;
          });
    },

    pauseCampaign() {
      this.pause({ id: this.campaign.id })
          .then(() => {
            this.toastAlert.show();
            this.campaign.paused = true;
          });
    },

    /**
     * Search for schedules in campaign and trigger
     * the confirmation modal.
     */
    openDeleteCampaignConfirmationModal() {
      this.searchSchedulesByCampaignId({ id: this.campaign.id })
          .then(() => {
            this.showConfirmExcludeModal = true;
          });
    },

    /**
     * Verify if the value of the text field
     * is the same as the campaign's name and
     * if so, deletes the campaign when submitting
     * the form.
     */
    removeCampaign() {
      if (this.campaignNameToDelete == this.campaign.name) {
        this.isCampaignNameCorrect = true;
        this.showConfirmExcludeModal = false;
        this.remove({
          id: this.campaign.id,
        })
            .then(data => {
              this.$router
                  .push({
                    path: `/manager/campaign`,
                  })
                  .then(() => {
                    this.setFlashMessage({
                      message: data.message,
                      type: "success",
                    });
                  });
            })
            .catch(error => {
              this.setFlashMessage({
                message: error.message,
                type: "danger",
              });
            });
      } else {
        this.isCampaignNameCorrect = false;
      }
    },

    clearIvrRelatedFields(event) {
      if (!event.target.checked) {
        this.fields.behavior = "";
        this.fields.ivr_after_call_id = "";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.show-more-button {
  box-shadow: unset;
  padding: 0.375rem 1rem;

  &.btn:active {
    box-shadow: unset;
  }

  &.btn-light {
    background-color: white;
  }

  &.btn-light:hover {
    color: $color-blue-300;
    border-color: rgba(48, 87, 242, 0.5);
  }
}

.tooltip-inner {
  background: blue !important;
}
</style>
