<template>
  <div
    class="d-flex align-items-center"
    style="height: 160px"
  >
    <div
      class="card mb-0"
      style="min-width: 400px"
    >
      <div
        class="card-body d-flex align-items-center"
        style="padding: 1rem"
      >
        <action-component
          icon-bg="#F7EAC4"
          icon-class="fa-regular fa-arrow-right-arrow-left"
          icon-color="#754000"
          :name="$t('transfer_to_wait_queue')"
        />
        <app-button
          class="btn-md col-auto"
          color="btn-gray-blue"
          @click="editAction"
        >
          <span class="fa-regular fa-pen-to-square" />
        </app-button>
      </div>
    </div>
  </div>
</template>

<script>
import {mapMutations} from "vuex";

import ActionComponent from "@chatbot/pages/workflow/components/action-component.vue";
import AppButton from "@components/app-button.vue";

export default {
  name: "CardTransfer",

  props: {
    action: {
      type: Object,
      required: true
    }
  },

  components: {
    AppButton,
    ActionComponent
  },

  methods: {
    ...mapMutations("chatbot/actions", [
      "setOffcanvasEditActionIsOpen",
      "setActionToEdit"
    ]),

    editAction() {
      this.setActionToEdit({...this.action, type: "transfer"})
      this.setOffcanvasEditActionIsOpen(true)
    }
  }
}
</script>
