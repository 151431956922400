<template>
  <div
    ref="canvas"
    class="offcanvas offcanvas-end p-0"
  >
    <div class="offcanvas-body p-0">
      <div
        class="col-12"
        style="padding: 24px 16px;"
      >
        <button
          type="button"
          class="btn-close p-0"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
        />
        <h3
          class="text-center m-0 title-canvas"
          v-if="this.mode == 'edit'"
        >
          Editar rota
        </h3>
        <h3
          v-else
          class="text-center m-0 title-canvas"
        >
          Criação de rota
        </h3>
      </div>
      <hr class="mt-0 mb-4">
      <div
        v-if="this.mode !== 'edit' && !companyCountry"
        class="d-flex justify-content-center align-items-center px-4 mb-4"
      >
        <div class="col-6 text-center">
          <input
            id="routeInternal"
            name="routeType"
            type="radio"
            value="internal"
            checked
            class="form-check-input me-4"
            v-model="fields.route_option"
          >
          <label
            for="routeInternal"
            class="form-check-label"
          >Rota Interna</label>
        </div>
        <div class="col-6 text-center">
          <input
            id="routeExternal"
            name="routeType"
            type="radio"
            value="external"
            class="form-check-input me-4"
            v-model="fields.route_option"
          >
          <label
            for="routeExternal"
            class="form-check-label"
          >Rota Externa</label>
        </div>
      </div>
      <p
        class="mb-4 px-4 text-center fw-medium"
        v-if="this.mode === 'edit' && !companyCountry"
      >
        {{ fields.route_option === "internal" ? "Rotas internas" : "Rotas externas" }}
      </p>
      <div class="col-12 px-4">
        <div
          class="form-group m-0"
          style="padding-bottom: 14px;"
        >
          <label class="form-label">Nome</label>
          <input
            v-model="fields.name"
            :class="{
              'is-invalid': companyRoutesErrors.name
            }"
            class="form-control"
            placeholder="Nome"
          >
          <div
            class="invalid-feedback"
            v-if="companyRoutesErrors.name"
          >
            {{ companyRoutesErrors.name[0] }}
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div class="form-group m-0">
              <label class="form-label">Tech Prefix</label>
              <input
                v-model="fields.route"
                :class="{
                  'is-invalid': companyRoutesErrors.route
                }"
                class="form-control"
                placeholder="Tech Prefix"
              >
              <div
                class="invalid-feedback"
                v-if="companyRoutesErrors.route"
              >
                {{ companyRoutesErrors.route[0] }}
              </div>
            </div>
          </div>
          <div class="col">
            <div class="form-group m-0">
              <label class="form-label">Caller ID</label>
              <input
                v-model="fields.caller_id"
                class="form-control"
                placeholder="Caller ID"
              >
            </div>
          </div>
        </div>
        <div
          class="col-12"
          style="padding-top: 25px; padding-bottom: 31px;"
        >
          <label class="form-label">Tronco SIP</label>
          <single-select
            v-if="routeSettings"
            id="sip"
            label="endpoint"
            :current-option="fields.endpoint"
            :list-array="routeSettings"
            @selected-option="fields.endpoint = $event.endpoint"
            initial-text="Selecionar Tronco SIP"
            z-index="20"
          />
        </div>
        <div class="row">
          <div class="col">
            <div class="form-check">
              <label class="form-check-label">A rota não deve ser debitada</label>
              <input
                v-model="fields.should_not_debit"
                :class="{
                  'is-invalid':
                    fields.route_option === 'internal' ||
                    (fields.route_option === 'external' && !fields.should_not_debit)
                }"
                class="form-check-input"
                type="checkbox"
              >
              <div class="invalid-feedback">
                {{ typeRouteChange() }}
              </div>
            </div>
          </div>
          <div
            class="col"
            v-if="companyCountry"
          >
            <div class="form-check">
              <label class="form-check-label">Incluir código +55</label>
              <input
                v-model="fields.uses_country_code"
                class="form-check-input"
                type="checkbox"
              >
            </div>
          </div>
        </div>
        <div
          class="mt-4"
          v-if="!companyCountry && fields.route_option === 'internal'"
        >
          <p class="mb-2 tx-text-200">
            Planos
          </p>
          <single-select
            label="name"
            :current-option="conversionIdPlan"
            :list-array="plans"
            id="plans"
            @selected-option="fields.plan = $event.id"
          />
        </div>
      </div>
      <div
        class="col-12"
        style="padding: 21px 16px 0 16px;"
        v-if="mode == 'edit' && companyCountry"
      >
        <h5
          class="fw-normal m-0"
          style="padding-bottom: 15px;"
        >
          TARIFAS
        </h5>
        <div class="table-container">
          <table class="table m-0">
            <thead>
              <tr>
                <th
                  scope="col"
                  class="border-top-0 border-end"
                  style="width: 20%;"
                >
                  Dispositivo
                </th>
                <th
                  scope="col"
                  class="border-top-0 border-end"
                  style="width: 40%;"
                >
                  Valor do minuto
                </th>
                <th
                  scope="col"
                  class="border-top-0"
                  style="width: 40%;"
                >
                  Cadência
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(telephonyRate, i) in fields['telephony-rates']"
                :key="i"
              >
                <th
                  scope="row"
                  class="border-end h5 fw-normal text-end"
                >
                  {{ telephonyRate.type == 1 ? "Fixo" : "Móvel" }}
                </th>
                <td class="border-end">
                  <div class="input-group input-group-merge input-group-reverse">
                    <input
                      v-model="telephonyRate.value"
                      v-maska
                      :class="{
                        'is-invalid': telephonyRatesErrors.value && telephonyRate.value == ''
                      }"
                      class="form-control h6 tx-text-gray"
                      data-maska="#.###"
                      type="text"
                      placeholder="0.000"
                    >
                    <div class="input-group-text h6 tx-text-gray">
                      R$
                    </div>
                  </div>
                  <small
                    class="text-danger"
                    v-if="telephonyRatesErrors.value && telephonyRate.value == ''"
                  >
                    {{ telephonyRatesErrors.value[0] }}
                  </small>
                </td>
                <td>
                  <select
                    class="form-select h6 tx-text-gray"
                    v-model="telephonyRate.minimum_duration"
                    name=""
                    id=""
                  >
                    <option :value="0">
                      0/30/6
                    </option>
                    <option :value="3">
                      3/30/6
                    </option>
                  </select>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="col-12 d-flex justify-content-center">
        <app-button
          :loading="loadingFlags.editCompanyRoute || loadingFlags.createRoute"
          class="btn btn-primary"
          style="margin-top: 32px; padding: 8px 115px;"
          @click="submit()"
        >
          <h5
            v-if="mode == 'edit'"
            class="m-0 fw-normal"
          >
            {{ $t("capitalizes.save") }}
          </h5>
          <h5
            v-else
            class="m-0 fw-normal"
          >
            Criar rota
          </h5>
        </app-button>
      </div>

      <div
        v-if="this.mode == 'edit'"
        class="d-flex justify-content-center my-3"
      >
        <button
          @click="removeRoute()"
          class="btn btn-outline-danger border-0 text-center py-2"
        >
          <span class="fe fe-trash" />
          <label class="form-label my-0 cursor-pointer">Excluir</label>
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import { Offcanvas } from "bootstrap";
import appButton from "@components/app-button";
import SingleSelect from "@components/single-select.vue";

export default {
  data() {
    return {
      mode: "",
      fields: {
        allow_landline: true,
        allow_mobile: true,
        caller_id: "",
        host: "",
        endpoint: "",
        formatted_endpoint: "",
        id: null,
        name: "",
        route: "",
        plan: "",
        should_not_debit: false,
        route_option: "",
        "telephony-rates": [],
        uses_country_code: false,
      },
      rateFields: {
        cadence: null,
        id: null,
        minimum_duration: null,
        minimum_duration_charged: null,
        type: null,
        value: "",
        typeOfCadence: null,
      },
      offcanvas: null,
      editRoute: false,
      is3CEndpoint: false,
      plans: [
        { id: 1, name: "Gold" },
        { id: 2, name: "Platinum" },
        { id: 3, name: "Instant" },
      ],
    };
  },

  props: {
    routeSettings: {
      type: Array,
      default: () => [],
    },
  },

  components: {
    SingleSelect,
    appButton,
  },

  mounted() {
    this.offcanvas = new Offcanvas(this.$refs.canvas);
  },

  computed: {
    ...mapGetters("admin/companies", {
      register: "getRegister",
      loadingFlags: "getLoadingFlags",
      telephonyRatesErrors: "getTelephonyRatesErrors",
      companyRoutesErrors: "getCompanyRoutesErrors",
    }),
    companyCountry() {
      return this.register["company-data"].data.country.country_code === 55;
    },
    conversionIdPlan() {
      return this.plans.find(plan => plan.id === this.fields.plan).name;
    },
  },

  methods: {
    ...mapActions("admin/companies", [
      "editCompanyRoute",
      "editTelephonyRate",
      "createRoute",
      "deleteTelephonyRate",
      "createTelephonyRate",
      "deleteRoute",
      "findCompanyRoutes",
    ]),
    ...mapActions("system", ["showConfirmationMessage"]),
    ...mapMutations("system", ["setFlashMessage", "setSuccessMessage"]),

    edit(item) {
      this.mode = "edit";
      for (const key in this.fields) {
        this.fields[key] = item[key];
      }
      const newEndpoint = this.routeSettings.find(route => route.endpoint_name === this.fields.endpoint);
      this.fields.endpoint = newEndpoint.endpoint;
      this.fields.plan = item["international-telephony-rate"]?.plan;
      this.is3CEndpoint = this.fields.endpoint.toLowerCase().includes("3cplus");
      this.offcanvas.show();
    },
    new() {
      this.mode = "new";
      let route_option;
      if (!this.companyCountry) {
        route_option = "internal";
      } else {
        route_option = null;
      }

      this.fields = {
        allow_landline: true,
        allow_mobile: true,
        caller_id: "",
        host: "",
        endpoint: "",
        id: null,
        name: "",
        route: "",
        plan: "",
        route_option: route_option,
        should_not_debit: false,
        "telephony-rates": [],
        uses_country_code: false,
      };
      this.offcanvas.show();
    },
    submit() {
      let company_id = this.$route.params.id;

      let host = "";
      this.routeSettings.find(route => {
        if (route.endpoint == this.fields.endpoint) {
          host = route.ip + ":" + route.port;
        }
      });
      delete this.fields["host"];
      this.fields["host"] = host;

      if (this.mode == "edit") {
        this.editCompanyRoute({
          id: company_id,
          fields: this.fields,
        }).then(() => {
          this.offcanvas.hide();
          this.updateTelephoneRates();
        });
      }

      if (this.mode == "new") {
        let fields;

        if (this.fields.route_option === "external") {
          const filterFields = { ...this.fields };
          delete filterFields.plan;
          fields = filterFields;
        } else {
          fields = this.fields;
        }

        this.createRoute({
          id: company_id,
          fields: fields,
        }).then(() => {
          this.offcanvas.hide();
          this.findCompanyRoutes({ id: company_id });
        });
      }
    },

    updateTelephoneRates() {
      let company_id = this.$route.params.id;
      let route_id = this.fields.id;

      let promises = [];

      this.fields["telephony-rates"].forEach(telephony_rate => {
        let telephony_rate_id = telephony_rate.id;
        let fields = {
          cadence: 6,
          minimum_duration: telephony_rate.minimum_duration,
          minimum_duration_charged: 30,
          type: telephony_rate.type,
          value: telephony_rate.value,
        };

        promises.push(
            this.editTelephonyRate({
              company_id: company_id,
              route_id: route_id,
              telephony_rate_id: telephony_rate_id,
              fields: fields,
            }),
        );
      });

      Promise.all(promises).then(() => this.findCompanyRoutes({ id: company_id }));
    },

    async removeRoute() {
      let company_id = this.$route.params.id;
      let route_id = this.fields.id;

      let response = await this.showConfirmationMessage({
        title: "Tem certeza que deseja excluir essa rota?",
        text: "A exclusão desse rota não poderá ser desfeita.",
        type: "badWarning",
      });

      if (response.isConfirmed) {
        await this.deleteRoute({
          id: company_id,
          route_id: route_id,
        });
        this.offcanvas.hide();
        this.findCompanyRoutes({ id: company_id });
      }
    },
    typeRouteChange() {
      if (this.fields.should_not_debit && this.fields.route_option === "internal") {
        return "Esta ação não é recomendada para rotas internas";
      } else if (!this.fields.should_not_debit && this.fields.route_option === "external") {
        return "Esta ação é recomendada para rotas externas";
      }
    },
  },
  watch: {
    "fields.route_option": {
      handler(route_option) {
        if (!this.companyCountry) {
          this.fields.should_not_debit = route_option === "external";
          this.fields.plan = this.fields.plan ?? null
        }
      },
      deep: true,
    },
  },
};
</script>
<style lang="scss" scoped>
.offcanvas {
  min-width: 50em !important;

  .btn-close {
    float: left;
    width: 15px;
    height: 15px;
  }

  .title-canvas {
    padding-top: 8px;
  }

  .table-container {
    border: 1px solid $color-gray-blue-300;
    border-radius: 6px;
    padding: 10px;
    background-color: #f9fafc !important;
  }

  .form-check-input[type="checkbox"] {
    border-radius: 6px !important;
  }

  .table {
    margin: 10px;
    border: none;
    width: 100%;

    thead tr th {
      font-size: 18px !important;
      text-transform: capitalize;
      background-color: #f9fafc !important;
      color: $color-text-200 !important;
      letter-spacing: -0.36px;
    }

    tbody {
      background-color: #f9fafc !important;

      tr th {
        color: $color-text-200 !important;
      }
    }
  }
}
</style>