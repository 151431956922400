<template>
  <div
    v-if="!(log.event_type === 'CREATED' && log.new_data?.opportunity) && log.origin !== 'WHATSAPP'"
    class="list-group-item w-100"
  >
    <div
      class="d-flex w-100 align-items-center"
      style="gap: 16px;"
    >
      <div>
        <div class="avatar avatar-xs ms-2">
          <div
            :class="getCustomAvatarColor"
            class="avatar-title rounded-circle"
          >
            <i
              :class="getIcon"
              class="main-icon fs-6"
            />
          </div>
        </div>
      </div>
      <div
        :class="{'cursor-pointer': isNotActivityNote}"
        class="w-100 tx-text-200 "
        @click="openEditActivityModal"
      >
        <div
          :class="{
            'bg-note': log.event_type === 'ACTIVITY_NOTE',
            'task-hover': isNotActivityNote,
          }"
          class="card-body rounded-4 m-0 bg-gray-blue-300 py-3 d-flex flex-column"
          style="padding: 16px;"
        >
          <div class="d-flex align-items-center justify-content-between w-100">
            <div>
              <div class="d-flex align-items-center fs-6 gap-2">
                <span :class="log.event_type !== 'ACTIVITY_NOTE' ? 'fw-medium' : 'tx-text-black'">
                  {{ setEventTitle(log) }}
                </span>
                <span v-if="log.new_data.status === 'lost'">{{ log.new_data.qualification_name }}</span>
                <span
                  v-if="isUpdated || isRd"
                  class="p-0 m-0"
                >
                  {{ getUpdatedLabel(log.old_data) }}
                  <i class="fa-regular fa-arrow-right mx-1" />
                  {{ getUpdatedLabel(log.new_data) }}
                </span>
              </div>
              <small class="mb-0 d-flex align-items-center gap-3 text-small">
                <small>{{ getDate(log.created_at) }}</small>
                <dot-status
                  color="#373753"
                  size="0.3rem"
                />
                <small>{{ getDate(log.created_at, 'HH:mm') }}</small>
                <dot-status
                  color="#373753"
                  size="0.3rem"
                />
                <small>
                  <i class="fa-regular fa-user" />
                  {{ log.user_name }}
                </small>
                <dot-status
                  color="#373753"
                  size="0.3rem"
                />
                <small>
                  <i class="fa-regular fa-building-user" />
                  {{ opportunity.name }}
                </small>
              </small>
            </div>
            <div
              v-if="log.event_type === 'ACTIVITY_NOTE'"
              ref="internalNoteBtn"
              :class="{'bg-white': !log.new_data.is_pinned}"
              :data-bs-title="log.new_data.is_pinned ? $t('unpin') : $t('pin_on_top')"
              class="rounded-circle pin-icon text-center cursor-pointer"
              data-bs-placement="bottom"
              data-bs-toggle="tooltip"
              @click="pin(log.new_data)"
            >
              <span class="fa-regular fa-thumbtack rotate-45 text-small" />
            </div>
            <div v-else-if="log.event_type.includes('ACTIVITY_')">
              <i class="fa-regular fa-check text-primary" />
            </div>
          </div>
        </div>
        <div
          v-if="log.new_data.description || log.new_data.qualification_note"
        >
          <div class="col-12">
            <div class="card mb-0 card-body collapse-card p-3 pb-0">
              <div
                class="py-3 pb-0 d-flex flex-row justify-content-between align-items-start"
                @click.stop="() => expandDescription = !expandDescription"
              >
                <div class="text-break flex-wrap text-start w-100">
                  <p
                    ref="description"
                    :class="expandDescription ? '' : 'text-truncate'"
                    class="w-100 fs-6 mb-3"
                  >
                    {{ log.new_data.description || log.new_data.qualification_note }}
                  </p>
                </div>
                <button
                  v-if="isTextTruncated"
                  class="btn p-0"
                >
                  <i :class="expandDescription ? 'fa-regular fa-chevron-up' : 'fa-regular fa-chevron-down'" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {defineComponent, nextTick} from 'vue'
import DotStatus from "@components/dot-status.vue";
import {mapActions, mapGetters} from "vuex";
import {getActivityIcon} from "@crm/utills";
import {formatPhoneNumber} from "@/utils";
import {Tooltip} from "bootstrap";
import moment from "moment";

export default defineComponent({
  name: "ListGroupActivityItem",
  emits: ["selectedActivity"],

  components: {
    DotStatus,
  },

  data() {
    return {
      expandDescription: true,
      isTextTruncated: false,
    }
  },

  mounted() {
    const description = this.$refs.description;
    const width = description?.offsetWidth;
    if (width) {
      this.expandDescription = false;
      description.style.maxWidth = width - 18 + 'px';

      nextTick(() => {
        this.checkIfTextIsTruncated(description);
      });
    }
  },

  beforeUnmount() {
    const tooltip = Tooltip.getInstance(this.$refs.internalNoteBtn);
    if (tooltip) {
      tooltip.dispose();
    }
  },

  props: {
    log: {type: Object, default: null}
  },

  computed: {
    ...mapGetters('crm/opportunities', {
      logs: 'getOpportunityLogs',
      opportunity: 'getOpportunity',
    }),

    isRd() {
      return (this.log?.new_data?.type === 'rd_station') || (this.log?.old_data?.type === 'rd_station');
    },

    getIcon() {
      const type = this.log.event_type.replace('ACTIVITY_', '').toLowerCase();
      const icon = this.getActivityIcon(type === 'note' ? 'internal-note' : type);

      if (icon.length) return `fa-regular ${icon}`;

      const newDataIcons = {
        number: 'fa-phone',
        email: 'fa-phone-arrow-up-right',
        tag_id: 'fa-tag',
        name: 'fa-user',
        step_id: 'fa-filter',
        owner_id: 'fa-wrench',
      };

      for (const key in newDataIcons) {
        if (this.log.new_data[key]) return `fa-regular ${newDataIcons[key]}`;
      }

      const statusIcons = {
        gain: 'fa-star',
        active: 'fa-filter',
        lost: 'fa-xmark',
        deleted: 'fa-trash',
      }

      return `fa-regular ${statusIcons[this.log.new_data.status] || 'fa-list'}`;
    },

    getCustomAvatarColor() {
      if (this.log.event_type === 'ACTIVITY_NOTE') {
        return 'bg-note'
      }

      if (this.log.new_data.status === 'gain') {
        return 'gain-opportunity'
      }

      if (this.log.new_data.status === 'lost') {
        return 'lost-opportunity'
      }

      return 'bg-gray-blue-300'
    },

    isNotActivityNote() {
      return this.log.event_type.includes("ACTIVITY_") && !this.log.event_type.includes("ACTIVITY_NOTE")
    },

    isUpdated() {
      if (this.log.event_type === 'UPDATED' && this.log.new_data && !this.log.new_data.status) {
        return true
      }

      if (this.log.event_type === 'CREATED' && this.log.new_data && !this.log.new_data.funnel_id) {
        return true
      }

      if (this.log.event_type === 'TRANSFER' && this.log.new_data && !this.log.new_data.funnel_id) {
        return true
      }

      return false
    },
  },

  methods: {
    getActivityIcon,
    formatPhoneNumber,

    ...mapActions('crm/activities', [
      'unpinNote',
      'pinNote'
    ]),
    ...mapActions('crm/opportunities', ["fetchOpportunityLogs"]),

    openEditActivityModal() {
      if (this.isNotActivityNote) {
        this.$emit("selectedActivity", this.log.new_data);
      }
    },

    setEventTitle(log) {
      if (log.event_type === 'CREATED' && log.new_data?.funnel_id) {
        return  this.$t('created_opportunity');
      }

      if (log.event_type === 'UPDATED' && log.old_data?.form_input_name) {
        return log.old_data.form_input_name + ':'
      }

      if(log.event_type === 'UPDATED' && log?.new_data?.opportunity?.type === 'rd_station') {
        return 'RD Station'
      }

      if (log.event_type === 'ACTIVITY_NOTE') {
        return log.new_data.note;
      }

      if (log.event_type.includes('ACTIVITY_')) {
        return log.new_data.title;
      }

      if (log.new_data?.number) {
        return `${this.$t('phone')}:`
      }
      if (log.new_data?.email) {
        return 'E-mail:'
      }
      if (log.new_data?.tag_id) {
        return 'Tags:'
      }
      if (log.new_data?.name) {
        return `${this.$t('name_capitalize')}:`
      }
      if (log?.new_data?.step_id) {
        return `${this.$t('step')}:`
      }
      if (log?.new_data?.owner_id) {
        return `${this.$t('responsible')}:`
      }
      if (log.new_data?.status === 'gain') {
        return this.$t('gain_opportunity')
      }
      if (log.new_data?.status === 'lost') {
        return this.$t('lost_opportunity')
      }
      if (log.new_data?.status === 'active' && log.old_data?.status === 'lost') {
        return this.$t('reactivated_lost_opportunity')
      }
      if (log.new_data?.status === 'active' && log.old_data?.status === 'gain') {
        return this.$t('reactivated_gain_opportunity')
      }
      if (log.new_data?.status === 'active') {
        return this.$t('reactivated_opportunity')
      }
      if (log.new_data?.status === 'deleted') {
        return this.$t('excluded_opportunity')
      }
      if (log.new_data.form_input_id) {
        return log.new_data.form_input_name + ': '
      }
      if (log.new_data.step_order) {
        return this.$t('new_position')
      } else {
        return log.new_data
      }
    },

    getUpdatedLabel(data) {
      if (!data) return this.$t('empty');

      if (data.number) {
        return this.formatPhoneNumber(data.number)
      }

      if(data?.type === 'rd_station') {
        return `RD Station - ${data?.owner_name}`;
      }

      const fields = [
        'email',
        'tag_id',
        'name',
        'step_name',
        'owner_name',
        'answer',
        'step_order'
      ];

      for (let field of fields) {
        if (data[field]) {
          return data[field]
        }
      }
    },

    getDate(date, format = 'DD/MM/YY') {
      return moment(date).format(format)
    },

    pin(note) {
      if (note.is_pinned) {
        this.unpinNote({
          noteId: note.id,
          opportunityId: this.$route.params.opportunityId,
          funnelId: this.$route.params.id
        }).then(() => {
          this.fetchOpportunityLogs({opportunityId: this.$route.params.opportunityId})
        })
      } else {
        this.pinNote({
          noteId: note.id,
          opportunityId: this.$route.params.opportunityId,
          funnelId: this.$route.params.id
        }).then(() => {
          this.fetchOpportunityLogs({opportunityId: this.$route.params.opportunityId})
        })
      }
    },

    checkIfTextIsTruncated(description) {
      this.isTextTruncated = description.scrollWidth > description.offsetWidth;
    },
  }
})
</script>
<style lang="scss" scoped>
.bg-gray-blue-300 {
  background-color: $color-gray-blue-200;

  .main-icon {
    color: $color-text-gray;
  }
}

.bg-note {
  background-color: rgba(255, 233, 169, 0.5) !important;

  .main-icon {
    color: rgba(117, 64, 0, 0.96)
  }
}

.gain-opportunity {
  background-color: #C9F2CD;
  color: #015901;
}

.lost-opportunity {
  background-color: #FFD7D9;
  color: #8F0000;
}

.task-hover:hover {
  border: 1px solid $color-gray-300;
  border-radius: .625rem;
}

.card {
  z-index: 1;
}

.collapse-card {
  margin-top: -1rem;
  z-index: 0;
  border-bottom-right-radius: $whatsapp-border-radius !important;
  border-bottom-left-radius: $whatsapp-border-radius !important;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
  border-top: 0;
}

.pin-icon {
  width: 26px;
  height: 26px;
}

.rotate-45 {
  color: #754000;
  transform: rotate(45deg);
}

.main-text {
  flex: 1 1 auto;
}
</style>
