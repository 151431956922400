export default {
  alert: null,
  errorFlags: {
    list: {},
    findById: {},
    create: {},
    update: {},
    updateCriteria: {},
    addCriteria: {},
    loadDataSelect: {}
  },
  loadingFlags: {
    list: false,
    findById: false,
    create: false,
    update: false,
    remove: false,
    updateCriteria: false,
    addCriteria: false,
    removeCriteria: false,
    loadDataSelect: false,
    loadFeedbacks: false
  },
  list: [],
  filters: {},
  register: null,
  pagination: {
    current_page: 1
  },
  dataSelect: null,
}
