<template>
  <div
    class="kanban-item cursor-pointer"
    tabindex="0"
  >
    <div
      :id="`card-${card.id}`"
      :aria-controls="`canvas-${card.id}`"
      :data-bs-target="`#canvas-${card.id}`"
      class="card card-sm rounded-4 mb-3"
      style="box-shadow: none"
    >
      <div
        class="card-body p-3 d-flex flex-column justify-content-between align-items-start overflow-visible"
        style="height: 114px"
        @click="openOpportunity"
      >
        <div class="d-flex align-items-center justify-content-between w-100">
          <h6
            class="mb-0"
            style="width: 144px"
          >
            {{ card.name.length > 37 ? card.name.substring(0, 37) + '...' : card.name }}
          </h6>
          <card-activity-badge
            v-if="card.status !== 'lost'"
            :activities="card.activities_count"
            :has-tooltip="card.activities_count.total === 0"
            @new-activity="$emit('openActivityModal')"
          />
        </div>
        <div class="w-100">
          <div class="d-flex justify-content-between align-items-center">
            <div class="d-flex align-items-center gap-2">
              <action-dropdown
                :fixed="true"
                :title="$t('select_channel_group')"
                icon="fa-kit fa-whatsapp"
                id="omni-chat"
                :blocked="!contactNumber"
              >
                <template #content>
                  <div
                    class="accordion"
                    id="instancesAccordion"
                  >
                    <channels-accordion-item
                      v-for="(channel , cIndex) in allowedInstances"
                      :key="`channel-${cIndex}`"
                      accordion-id="instancesAccordion"
                      :dividers="cIndex < allowedInstances?.length - 1"
                      :instance="channel"
                      @select-instance="startOmniChat"
                    />
                  </div>
                </template>
              </action-dropdown>
              <action-button
                :blocked="true"
                icon="fa-regular fa-phone"
              />
            </div>
            <div class="d-flex align-items-center gap-2">
              <p
                v-if="card.status !== 'lost'"
                :class="getAddProductInfo.class"
                class="mb-0"
                style="font-size: 12px"
                @click="openNewProductModal"
              >
                {{ getAddProductInfo.text }}
              </p>
              <span
                ref="agentTooltip"
                class="tx-text-200 d-flex justify-content-center align-items-center opportunity-agent"
              >
                {{ card.agent_name_initials }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {formatLocaleMoney, generateTooltip} from "@/utils";
import CardActivityBadge from "@crm/components/kanban/card-activity-badge.vue";
import ActionButton from "@crm/components/action-button.vue";
import ActionDropdown from "@crm/components/action-dropdown.vue";
import ChannelsAccordionItem from "@whatsapp/components/transfer-form/channels-accordion-item.vue";
import {mapActions, mapGetters, mapMutations} from "vuex";

export default {
  name: 'KanbanCard',
  components: {ChannelsAccordionItem, ActionDropdown, ActionButton, CardActivityBadge},

  props: {
    card: {type: Object, required: true},
    stepOrder: {type: Number, default: 0},
  },

  emits: ['openActivityModal', 'openNewProductModal'],

  data() {
    return {
      isOpenDetailsCanvas: false,
      opportunityAmounts: [],
      hasRecurrence: false,
      productsListTooltip: null,
      dropdown: null,

      totalQuantity: 0,
      uniqueAmount: 0,
      totalAmount: 0,
      loading: false,
      totalRecurrence: 0,
      totalSingle: 0,
      totalGeneral: 0,
      productCurrency: 'BRL',
      newActivityTooltip: null,
      agentTooltip: null,
    }
  },

  computed: {
    ...mapGetters("whatsapp/instance", {
      allowedInstances: "getAllowedInstances"
    }),
    ...mapGetters("auth", {
      isAgent: "isAgent"
    }),

    getAddProductInfo() {
      const hasProducts = this.card.opportunity_total_value > 0 && this.card.products.length > 0;

      return {
        text: hasProducts ? this.formatLocaleMoney(this.card.opportunity_total_value, 'BRL') : `+ ${this.$t('add_product')}`,
        class: hasProducts ? 'fw-medium' : 'add-product',
      }
    },

    contactNumber() {
      return this.card?.contacts[0]?.phones[0]?.number
    },
  },

  mounted() {
    this.hasRecurrence = this.card.products.some((product) => product.is_recurrent);
    this.calcOpportunityAmounts()
    this.agentTooltip = this.generateTooltip("Nome do agente", this.$refs.agentTooltip, 'bottom');
  },

  watch: {
    'card.opportunity_total_value': {
      handler() {
        this.calcOpportunityAmounts()
      },
      deep: true
    }
  },

  methods: {
    formatLocaleMoney,
    generateTooltip,

    ...mapActions("whatsapp/chat", [
      "findChat",
    ]),
    ...mapMutations("whatsapp/chat", ["setEmptyChatContact"]),

    openOpportunity() {
      this.agentTooltip.hide();
      this.$router.push(`${this.$route.params.id}/opportunity/${this.card.id}`)
    },

    calcOpportunityAmounts() {
      let uniqueAmount = 0;
      let recurringAmount = 0;
      let totalQuantity = 0;
      let totalCurrentAmount = 0;
      let maxValue = -Infinity;
      let minValue = Infinity;

      this.card.products.forEach((product) => {
        if (product.pivot && product.pivot.is_active) {
          const pricePerUnit = parseFloat(product.pivot.price_per_unit);
          const quantity = product.pivot.quantity;
          if (product.is_recurrent)
            recurringAmount += pricePerUnit * quantity;
          else
            uniqueAmount += pricePerUnit * quantity;
          totalQuantity += quantity;
          maxValue = Math.max(maxValue, pricePerUnit * quantity);
          minValue = Math.min(minValue, pricePerUnit * quantity);
        }
      })
      totalCurrentAmount = uniqueAmount + recurringAmount;

      this.totalAmount = totalCurrentAmount;
      this.opportunityAmounts = [
        {label: this.$t('unique'), value: uniqueAmount > 0 ? uniqueAmount : 0},
        {label: this.$t('recurrent'), value: recurringAmount > 0 ? recurringAmount : 0},
        {
          label: this.$t('average_ticket'),
          value: totalCurrentAmount / totalQuantity > 0 ? totalCurrentAmount / totalQuantity : 0
        },
        {label: this.$t('max_value'), value: isFinite(maxValue) ? maxValue : 0},
        {label: this.$t('min_value'), value: isFinite(minValue) ? minValue : 0},
      ]
    },

    openNewProductModal(event) {
      event.stopPropagation()
      this.$emit('openNewProductModal');
    },

    redirectToChat(contact, instance) {
      this.loading = true
      this.findChat(`${this.contact(contact?.number || contact).number}${instance.id}`)
        .then(chat => {
          this.$router.push("/whatsapp/" + chat.id);
        })
        .catch(status => {
          if (status === 404) {
            let emptyChatContact = {number: this.contact(contact?.number || contact).number};
            emptyChatContact.instance = instance;

            this.setEmptyChatContact(emptyChatContact);

            this.$router.push("/whatsapp/empty-chat");
          }
        }).finally(() => {
        this.loading = false
      })
    },

    startOmniChat(instance) {
        this.redirectToChat(this.contactNumber, instance)
    },

    contact(number){
      number =
        number.length === 12
          ? number.slice(0, 4) + "9" + this.number.slice(4)
          : number;

      return { number: number }
    },
  }
}
</script>

<style lang="scss" scoped>
.icon {
  color: #575778;
}

.add-product {
  &:hover {
    color: $color-blue-300;
  }
}

.card {
  border-color: $color-gray-blue-300;

  &:hover {
    border-color: $color-gray-300;
  }
}

.opportunity-agent {
  width: 20px;
  height: 20px;
  background-color: $color-gray-blue-300;
  font-size: 10px;
  border-radius: 50%;

  &:hover {
    background-color: $color-gray-200;
  }
}
</style>