import axiosCrm from "@crm/plugins/axios-config";

export default {
  createOpportunity({commit, rootGetters}, opportunityData) {
    commit("startLoading", "createOpportunity")
    commit("setErrors", [])

    opportunityData.contacts.forEach(contact => {
      if (contact.type === "phone") {
        contact.value = contact.value.replace(/[^0-9\n]/g, '');
      }
    });

    opportunityData.company_id = rootGetters["auth/getUser"].company.id

    return new Promise((resolve, reject) => {
      axiosCrm.post(`/opportunity`, opportunityData).then((response) => {
        commit('system/setSuccessMessage', "success_message.create_opportunity", {root: true})
        resolve(response.data.data.id)
      }).catch((response) => {
        commit('system/setErrorMessage', response.response.data, {root: true})
        if (response.response.status == 422 && response.response.data.errors) {
          commit('setErrors', response.response.data.errors)
          if (response.response.data.errors['existing-contact']) {
            commit('system/setErrorMessage', response.response.data.errors['existing-contact'][0], {root: true})
          }
          if (response.response.data.errors['invalid-contact']) {
            commit('system/setErrorMessage', response.response.data.errors['invalid-contact'][0], {root: true})
          }
        }
        reject()
      }).finally(() => {
        commit("stopLoading", "createOpportunity")
      })
    })
  },

  fetchStepOpportunities({commit, getters, rootGetters}, {step, page}) {
    commit("setOpportunitiesLoading", {
      stepId: step.id,
      loading: true
    })
    const company_id = rootGetters["auth/getUser"].company.id
    const filters = getters.getOpportunityFilters
    return new Promise((resolve, reject) => {
      axiosCrm.get(`/opportunity`, {
        params: {
          funnel_id: step.funnel_id,
          step_id: step.id,
          company_id,
          page: page,
          ...filters
        }
      }).then((response) => {
        let opportunities = response.data.data
        let stepOpportunities = getters.getOpportunities[step.id]

        if (page === 1) {
          stepOpportunities = []
        }

        opportunities.forEach(opportunity => {
          if (!stepOpportunities.some(el => el.id === opportunity.id)) {
            stepOpportunities.push(opportunity)
          }
        })

        commit("setOpportunities", {
          stepId: step.id,
          opportunities: stepOpportunities
        })
        commit("setOpportunitiesPagination", {
          stepId: step.id,
          pagination: response.data.meta
        })
        resolve()

      }).catch((response) => {
        commit('system/setErrorMessage', response.response.data, {root: true})
        reject()
      }).finally(() => {
        commit("setOpportunitiesLoading", {
          stepId: step.id,
          loading: false
        })
      })
    })
  },

  fetchFunnelOpportunities({commit, dispatch}, steps) {
    commit("startLoading", "fetchFunnelOpportunities");
    const dispatchPromises = steps.map(step => dispatch("fetchStepOpportunities", {step: step, page: 1}));

    Promise.all(dispatchPromises).finally(() => {
      commit("stopLoading", "fetchFunnelOpportunities");
    })
  },

  fetchOpportunity({commit, rootGetters}, opportunityId) {
    const company_id = rootGetters["auth/getUser"].company.id
    commit("startLoading", "fetchOpportunity");
    commit("setOpportunity", null)
    return new Promise((resolve, reject) => {
      axiosCrm.get(`/opportunity/${opportunityId}`, {params: {company_id}}).then((response) => {
        commit("setOpportunity", response.data.data)
        resolve()
      }).catch((response) => {
        commit('system/setErrorMessage', response.response.data, {root: true})
        reject()
      }).finally(() => {
        commit("stopLoading", "fetchOpportunity");
      })
    })
  },

  fetchOpportunityWithoutLoading({commit, rootGetters}, opportunityId) {
    const company_id = rootGetters["auth/getUser"].company.id
    return new Promise((resolve, reject) => {
      axiosCrm.get(`/opportunity/${opportunityId}`, {params: {company_id}}).then((response) => {
        commit("setOpportunity", response.data.data)
        resolve()
      }).catch((response) => {
        commit('system/setErrorMessage', response.response.data, {root: true})
        reject()
      })
    })
  },

  dropCards({commit, dispatch}, {addedIndex, removedIndex, targetStep, currentCard}) {
    if (addedIndex !== null || removedIndex !== null) {
      commit("reorderOpportunitiesInStep", {
        targetStep,
        currentCard,
        addedIndex,
        removedIndex
      })

      if (addedIndex !== null) {
        dispatch('sendDropCards', {
          opportunityFunnelId: currentCard.opportunity_funnel_id,
          funnelId: currentCard.funnel_id,
          stepId: targetStep.id,
          order: addedIndex + 1,
        })
      }
    }
  },

  sendDropCards({commit, dispatch, rootGetters}, {opportunityFunnelId, stepId, order, funnelId}) {
    const company_id = rootGetters["auth/getUser"].company.id
    try {
      axiosCrm.post(`/opportunity/${opportunityFunnelId}/step/${stepId}/order/${order}/move/?company_id=${company_id}`)
    } catch (e) {
      commit('system/setErrorMessage', 'errors_message.move_contact', {root: true})
    } finally {
      dispatch('crm/funnels/updateCurrentFunnel', funnelId, {root: true})
    }
  },

  createAnswer({dispatch, getters, commit}, answerData) {
    return new Promise((resolve, reject) => {
      axiosCrm.post("/opportunity/answers", answerData).then(() => {
        const opportunityId = getters.getOpportunity.id
        dispatch("fetchOpportunity", opportunityId)
        commit('system/setSuccessMessage', "success_message.update_field", {root: true})
        resolve()
      }).catch((response) => {
        commit('system/setErrorMessage', response.response.data, {root: true})
        reject()
      })
    })
  },

  updateAnswer({getters, commit}, answerData) {
    return new Promise((resolve, reject) => {
      axiosCrm.put(`/opportunity/answers/${answerData.id}`, answerData).then(() => {
        const opportunity= getters.getOpportunity;
        opportunity?.answers?.push(answerData);
        commit("crm/opportunities/setOpportunity", opportunity, {root: true});
        commit('system/setSuccessMessage', "success_message.update_field", {root: true})
        resolve()
      }).catch(() => {
        commit('system/setErrorMessage', 'errors_message.update_field', {root: true})
        reject()
      })
    })
  },

  fetchFormInputs({commit}, funnelId) {
    commit('startLoading', 'fetchFormInputs');
    commit("setErrors", [])
    commit('setFormInputs', {
      rd_station: [],
      CRM: []
    })
    return new Promise((resolve, reject) => {
      axiosCrm.get(`/form_input?funnel_id=${funnelId}`).then((response) => {
        commit('setFormInputs', (response.data))
        resolve(response.data);
      }).catch(() => {
        commit('system/setErrorMessage', 'errors_message.loading_form_fields', {root: true})
        reject();
      }).finally(() => {
        commit('startLoading', 'fetchFormInputs');
      })
    })
  },

  fetchOpportunityLogs({commit, rootGetters}, {opportunityId, payload}) {
    payload.company_id = rootGetters["auth/getUser"].company.id
    commit('startLoading', 'fetchOpportunityLogs');
    return new Promise((resolve, reject) => {
      axiosCrm.get(`/opportunity/${opportunityId}/opportunities-logs`, {
        params: {
          ...payload,
          is_not_grouped_by_date: 1
        }
      }).then((response) => {
        commit('setOpportunityLogs', response.data.data)
        resolve();
      }).catch(() => {
        commit('system/setErrorMessage', 'errors_message.loading_timeline', {root: true})
        reject();
      }).finally(() => {
        commit('stopLoading', 'fetchOpportunityLogs');
      })
    })
  },

  updateCurrentTabTimeline({commit}, currentTab) {
    commit("setCurrentTabTimeline", currentTab);
  },

  createChangeOwner({commit, rootGetters}, {opportunityFunnelId, ownerId}) {
    const company_id = rootGetters["auth/getUser"].company.id
    commit('startLoading', 'createChangeOwner');
    return new Promise((resolve, reject) => {
      axiosCrm.post(`/opportunity/${opportunityFunnelId}/owner/${ownerId}/transfer/?company_id=${company_id}`).then(() => {
        commit('system/setSuccessMessage', "success_message.update_responsible", {root: true})
        resolve()
      }).catch(() => {
        commit('system/setErrorMessage', 'errors_message.changing_responsible', {root: true})
        reject();
      }).finally(() => {
        commit('stopLoading', 'createChangeOwner');
      })
    })
  },

  updateOpportunity({commit}, {opportunityId, payload}) {
    commit('startLoading', 'updateOpportunity');
    return new Promise((resolve, reject) => {
      axiosCrm.put(`/opportunity/${opportunityId}`, payload).then(() => {
        commit('system/setSuccessMessage', "success_message.update_name", {root: true})
        resolve()
      }).catch(() => {
        commit('system/setErrorMessage', 'errors_message.edit_name', {root: true})
        reject();
      }).finally(() => {
        commit('stopLoading', 'updateOpportunity');
      })
    })
  },

  deleteFormInput({dispatch, commit}, payload) {
    return new Promise((resolve, reject) => {
      axiosCrm.delete(`/form_input/${payload.id}`, {params: {funnel_id: payload.funnel_id}}).then(() => {
        commit('system/setSuccessMessage', 'success_message.delete_field', {root: true})
        dispatch('fetchFormInputs', payload.funnel_id)
        resolve()
      }).catch(() => {
        commit('system/setErrorMessage', 'errors_message.delete_form_fields', {root: true})
        reject()
      })
    })
  },

  addFormInputs({dispatch, commit}, payload) {
    return new Promise((resolve, reject) => {
      axiosCrm.post("/form_input", payload).then(() => {
        dispatch('fetchFormInputs', payload.funnel_id)
        resolve();
      }).catch(() => {
        commit('system/setErrorMessage', 'errors_message.add_form_fields', {root: true})
        reject();
      })
    })
  },

  createContactInOpportunity({commit, dispatch}, {opportunityId, payload}) {
    commit('startLoading', 'createContactInOpportunity');

    payload.contacts.forEach(contact => {
      if (contact.type === "phone") {
        contact.value = contact.value.replace(/[^0-9\n]/g, '');
      }
    });
    return new Promise((resolve, reject) => {
      axiosCrm.post(`/opportunity/${opportunityId}/store-contact`, payload).then(() => {
        commit('system/setSuccessMessage', "success_message.add_contact", {root: true})
        dispatch("fetchOpportunity", opportunityId)
        resolve()
      }).catch((response) => {
        commit('system/setErrorMessage', response.response.data, {root: true})
        reject();
      }).finally(() => {
        commit('stopLoading', 'createContactInOpportunity');
      })
    })
  },

  moveOpportunity({commit}, {opportunity_funnel_id, new_step_id, new_order}) {
    return new Promise((resolve, reject) => {
      axiosCrm.post(`/opportunity/${opportunity_funnel_id}/step/${new_step_id}/order/${new_order}/move`).then(() => {
        resolve()
      }).catch(() => {
        commit('system/setErrorMessage', 'Erro ao passar de etapa', {root: true})
        reject()
      })
    })
  },

  qualifyOpportunity({commit}, {opportunityFunnelId, data, message}) {
    commit('startLoading', 'qualifyOpportunity');
    return new Promise((resolve, reject) => {
      axiosCrm.post(`/opportunity-funnel/${opportunityFunnelId}/qualify`, data).then(() => {
        commit('system/setSuccessMessage', message, {root: true})
        resolve()
      }).catch((response) => {
        commit('system/setErrorMessage', response.response.data, {root: true})
        reject();
      }).finally(() => {
        commit('stopLoading', 'qualifyOpportunity');
      })
    })
  }
}
