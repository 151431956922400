export default {
  startLoading: state => state.loading = true,
  stopLoading: state => state.loading = false,
  startLoadingFlag: (state, flag) => state.loadingFlags[flag] = true,
  stopLoadingFlag: (state, flag) => state.loadingFlags[flag] = false,
  setErrors: (state, errors) => state.errors = errors,
  clearErrors: (state) => state.errors = {},
  setloadingContext: (state, loadingContext) => state.loadingContext = loadingContext,
  setList: (state, list) => state.list = list,
  setPagination: (state, pagination) => state.pagination = pagination,
  setCurrentPage: (state, page) => state.pagination.current_page = page,
  setRegister: (state, register) => state.register = register,
  setFilters: (state, filters) => state.filters = filters,
  setRecycleFilters: (state, recycleFilters) => state.recycleFilters = recycleFilters,
  setInclude: (state, include) => state.include = include,
  setDataSelect: (state, dataSelect) => state.dataSelect = dataSelect,
  setListDataPreview: (state, listDataPreview) => state.listDataPreview = listDataPreview,
  setListHeaderPreview: (state, listHeaderPreview) => state.listHeaderPreview = listHeaderPreview,
  setFileName: (state, fileName) => state.fileName = fileName
}
