export default {
  loadingFlags: {
    getCallHistory: false,
    startManualCallAfterAgentEnteredManual: false,
    loadCampaignMetricsCharts: false
  },
  loading: false,
  loadingContext: "",
  /* loadingFlags: {
        'whatsappCall': false
    }, */
  errorMessage: "",
  errors: {},
  call: null, //{ id: 5, phone: '888855', ivr_after_call_id: 5 , call_mode: 'receptive'},
  manualCallACW: null,
  mailing: null,
  agentCallStatus: "",
  callHistory: [],
  callHistoryNumberCalling: "",
  whatsappCallCurrentNumber: "",
  campaignMetricsCharts: []
};
