import axios from "axios";

export default {
  listOnlineUsers({ commit }, payload) {
    commit("clearErrors");
    commit("startLoadingFlag", "listOnlineUsers");
    commit("setListOnlineUsers", []);

    let paramsInUrl;
    if (payload && payload.allUser) {
      paramsInUrl = `/users?include=permissions&active=1&all=${payload.allUser}`;
    } else {
      paramsInUrl =
        "/users?include=permissions&active=1&" +
        (payload && payload.page ? "page=" + payload.page + "&" : "") +
        (payload && payload.search ? "search=" + payload.search + "&" : "") +
        (payload && payload.role ? "role=" + payload.role : "");
    }
    axios
      .get(paramsInUrl)
      .then(response => {
        commit("setListOnlineUsers", response.data.data);
        commit("setPaginationOnlineUsers", response.data.meta.pagination);
      })
      .catch(response => {
        if (response.response) {
          commit("setAlert", { message: response.response.data.detail, type: "danger" });
          response.response.status == 422 &&
            response.response.data.errors &&
            commit("setErrors", response.response.data.errors);
        }
      })
      .finally(() => {
        commit("stopLoadingFlag", "listOnlineUsers");
      });
  },

  listOfflineUsers({ commit }, payload) {
    commit("clearErrors");
    commit("startLoadingFlag", "listOfflineUsers");
    commit("setListOfflineUsers", []);

    axios
      .get(
        "/users?include=permissions&active=0&" +
          (payload && payload.page ? "page=" + payload.page + "&" : "") +
          (payload && payload.search ? "search=" + payload.search + "&" : "") +
          (payload && payload.role ? "role=" + payload.role : "")
      )
      .then(response => {
        commit("setListOfflineUsers", response.data.data);
        commit("setPaginationOfflineUsers", response.data.meta.pagination);
      })
      .catch(response => {
        commit("setAlert", { message: response.response.data.detail, type: "danger" });
        response.response.status == 422 &&
          response.response.data.errors &&
          commit("setErrors", response.response.data.errors);
      })
      .finally(() => {
        commit("stopLoadingFlag", "listOfflineUsers");
      });
  },

  switchWebPhone({ commit }, payload) {
    commit("clearErrors");
    commit("startLoadingFlag", "switchWebPhone");

    return new Promise((resolve, reject) => {
      axios
        .put("/users/" + payload.id + "/enable/webphone")
        .then(() => {
          resolve();
        })
        .catch(response => {
          commit("system/setErrorMessage", response.response.data, { root: true });
          reject();
        })
        .finally(() => {
          commit("stopLoadingFlag", "switchWebPhone");
        });
    });
  },

  switchExtension({ commit }, payload) {
    commit("clearErrors");
    commit("startLoadingFlag", "switchExtension");

    return new Promise((resolve, reject) => {
      axios
        .put("/users/" + payload.id + "/enable/web_extension", payload)
        .then(() => {
          resolve();
        })
        .catch(response => {
          commit("system/setErrorMessage", response.response.data, { root: true });
          reject({ message: response.response.data.errors });
          response.response.status == 422 &&
            response.response.data.errors &&
            commit("setErrors", response.response.data.errors);
        })
        .finally(() => {
          commit("stopLoadingFlag", "switchExtension");
        });
    });
  },

  listFilters({ commit }) {
    commit("clearErrors");
    commit("startLoading");
    commit("setList", []);
    commit("startLoadingFlag", "listFilters");

    return new Promise((resolve, reject) => {
      axios
        .get("/filters?include[]=teams&include[]=queues")
        .then(response => {
          commit("setList", response.data.data);
          resolve();
        })
        .catch(response => {
          commit("setAlert", {
            message: response.response.data.detail,
            type: "danger"
          });
          response.response.status == 422 &&
            response.response.data.errors &&
            commit("setErrors", response.response.data.errors);
          reject({ message: response.response.data.detail });
        })
        .finally(() => {
          commit("stopLoading");
          commit("stopLoadingFlag", "listFilters");
        });
    });
  },

  findById({ commit }, payload) {
    commit("clearErrors");
    commit("startLoading");
    commit("setRegister", null);
    commit("startLoadingFlag", "findById");

    return new Promise((resolve, reject) => {
      axios
        .get("/users/" + payload.id, { params: { include: "receptive_queues,campaigns,teams,permissions" } })
        .then(response => {
          commit("setRegister", response.data.data);
          resolve();
        })
        .catch(response => {
          commit("setRegister", null);
          commit("setAlert", { message: response.response.data.detail, type: "danger" });
          response.response.status == 422 &&
            response.response.data.errors &&
            commit("setErrors", response.response.data.errors);
          reject({ message: response.response.data.detail });
        })
        .finally(() => {
          commit("stopLoading");
          commit("stopLoadingFlag", "findById");
        });
    });
  },

  create({ commit }, payload) {
    commit("clearErrors");
    commit("startLoading");
    commit("setRegister", null);
    commit("startLoadingFlag", "create");

    return new Promise((resolve, reject) => {
      axios
        .post("/users", payload.fields)
        .then(response => {
          commit("system/setSuccessMessage", "success_message.create_user", { root: true });
          commit("setRegister", response.data.data);

          resolve({ message: "Registro criado com sucesso!" });
        })
        .catch(response => {
          response.response.status == 422 &&
            response.response.data.errors &&
            commit("setErrors", response.response.data.errors);
          reject({ message: response.response.data.detail });
        })
        .finally(() => {
          commit("stopLoading");
          commit("stopLoadingFlag", "create");
        });
    });
  },

  update({ commit }, payload) {
    commit("clearErrors");
    commit("startLoading");
    commit("startLoadingFlag", "update");

    return new Promise((resolve, reject) => {
      axios
        .put("/users/" + payload.id, payload.fields)
        .then(() => {
          commit("system/setSuccessMessage", "success_message.update_user", { root: true });
          resolve();
        })
        .catch(response => {
          commit("setErrors", response.response.data.errors || response.response.data);
          commit("system/setErrorMessage", response.response.data, { root: true });
          reject();
        })
        .finally(() => {
          commit("stopLoading");
          commit("stopLoadingFlag", "update");
        });
    });
  },

  updatePassword({ commit }, payload) {
    commit("clearErrors");
    commit("startLoadingFlag", "updatePassword");

    return new Promise((resolve, reject) => {
      axios
        .put(`/users/${payload.id}`, payload.fields)
        .then(response => {
          let userProperty = {
            property: "password_updated_at",
            value: response.data.data.password_updated_at
          };

          commit("auth/setUserProperty", userProperty, { root: true });
          commit("system/setSuccessMessage", "success_message.update_password", { root: true });
          resolve({ message: "Registro atualizado com sucesso!" });
        })
        .catch(response => {
          if (response.response.status == 422)
            response.response.data.errors && commit("setErrors", response.response.data.errors);
          else commit("system/setErrorMessage", response.response.data, { root: true });
          reject({ message: response.response.data.detail });
        })
        .finally(() => {
          commit("stopLoadingFlag", "updatePassword");
        });
    });
  },

  remove({ commit }, payload) {
    commit("clearErrors");
    commit("startLoading");
    commit("setRegister", null);
    commit("startLoadingFlag", "remove");

    return new Promise((resolve, reject) => {
      axios
        .delete("/users/" + payload.id)
        .then(() => {
          commit("setRegister", null);
          resolve({ message: "Registro removido com sucesso!" });
        })
        .catch(response => {
          commit("setErrors", response.response.data);
          reject({ message: response.response.data.detail });
        })
        .finally(() => {
          commit("stopLoading");
          commit("stopLoadingFlag", "remove");
        });
    });
  },

  deactivate({ commit }, payload) {
    commit("clearErrors");
    commit("startLoading");
    commit("startLoadingFlag", "deactivate");

    return new Promise((resolve, reject) => {
      axios
        .put("/users/" + payload.id + "/deactivate")
        .then(() => {
          resolve();
        })
        .catch(response => {
          commit("setErrors", response.response.data);
          reject({ message: response.response.data });
        })
        .finally(() => {
          commit("stopLoading");
          commit("stopLoadingFlag", "deactivate");
        });
    });
  },

  disable({ commit }, payload) {
    commit("clearErrors");
    commit("startLoading");
    commit("startLoadingFlag", "disable");

    return new Promise((resolve, reject) => {
      axios
        .put("/users/" + payload.id + "/disable", {
          remove_schedules: payload.remove_schedules,
          user_id: payload.user_id
        })
        .then(() => {
          resolve();
        })
        .catch(response => {
          commit("setErrors", response.response.data);
          reject({ message: response.response.data });
        })
        .finally(() => {
          commit("stopLoading");
          commit("stopLoadingFlag", "disable");
        });
    });
  },

  findManagerDataSelect({ commit }) {
    commit("startLoading");
    commit("startLoadingFlag", "findManagerDataSelect");

    return new Promise((resolve, reject) => {
      axios
        .get("/filters?include=managers")
        .then(response => {
          commit("setManagerDataSelect", response.data.data.managers);
          resolve();
        })
        .catch(() => {
          reject();
        })
        .finally(() => {
          commit("stopLoading");
          commit("stopLoadingFlag", "findManagerDataSelect");
        });
    });
  },

  downloadCSV({ commit }, payload) {
    commit("startLoadingFlag", "downloadCSV");
    return new Promise((resolve, reject) => {
      axios
        .get(`/users/csv?is_active_users=${payload.is_active_users}&email=${payload.email}`)
        .then(response => {
          commit("system/setSuccessMessage", response.data.detail, { root: true });
          resolve();
        })
        .catch(response => {
          commit("system/setErrorMessage", response.response.data, { root: true });
          reject();
        })
        .finally(() => {
          commit("stopLoadingFlag", "downloadCSV");
        });
    });
  },

  findUserData({ commit }, payload) {
    commit("startLoading");
    commit("setUserData", []);
    commit("startLoadingFlag", "findUserData");
    return new Promise((resolve, reject) => {
      axios
        .get(`/user_data/${payload.id}`)
        .then(response => {
          commit("setUserData", response.data.data);
          resolve();
        })
        .catch(response => {
          commit("system/setErrorMessage", response.response.data, { root: true });
          reject();
        })
        .finally(() => {
          commit("stopLoading");
          commit("stopLoadingFlag", "findUserData");
        });
    });
  },

  updateUserData({ commit }, payload) {
    commit("clearErrors");
    commit("startLoading");
    commit("startLoadingFlag", "updateUserData");
    return new Promise((resolve, reject) => {
      axios
        .put(`/user_data/${payload.id}`, payload.fields)
        .then(response => {
          commit("system/setSuccessMessage", response.data.detail, { root: true });
          resolve();
        })
        .catch(response => {
          commit("system/setErrorMessage", response.response.data, { root: true });
          response.response.status == 422 &&
            response.response.data.errors &&
            commit("setErrors", response.response.data.errors);
          reject();
        })
        .finally(() => {
          commit("stopLoading");
          commit("stopLoadingFlag", "updateUserData");
        });
    });
  },

  findLineOfWork({ commit }) {
    commit("startLoading");
    commit("setLineOfWork", []);
    commit("startLoadingFlag", "findLineOfWork");
    return new Promise((resolve, reject) => {
      axios
        .get(`/line_of_work`)
        .then(response => {
          commit("setLineOfWork", response.data.data);
          resolve();
        })
        .catch(response => {
          commit("system/setErrorMessage", response.response.data, { root: true });
          reject();
        })
        .finally(() => {
          commit("stopLoading");
          commit("stopLoadingFlag", "findLineOfWork");
        });
    });
  },

  findPromoter({ commit }) {
    commit("startLoading");
    commit("setPromoter", []);
    commit("startLoadingFlag", "findPromoter");
    return new Promise((resolve, reject) => {
      axios
        .get(`/promoter`)
        .then(response => {
          commit("setPromoter", response.data.data);
          resolve();
        })
        .catch(response => {
          commit("system/setErrorMessage", response.response.data, { root: true });
          reject();
        })
        .finally(() => {
          commit("stopLoading");
          commit("stopLoadingFlag", "findPromoter");
        });
    });
  },

  findCompanyRole({ commit }) {
    commit("startLoading");
    commit("setCompanyRole", []);
    commit("startLoadingFlag", "findCompanyRole");
    return new Promise((resolve, reject) => {
      axios
        .get(`/company_role`)
        .then(response => {
          commit("setCompanyRole", response.data.data);
          resolve();
        })
        .catch(response => {
          commit("system/setErrorMessage", response.response.data, { root: true });
          reject();
        })
        .finally(() => {
          commit("stopLoading");
          commit("stopLoadingFlag", "findCompanyRole");
        });
    });
  },

  updateUserIpAllowedVerification({ commit }, payload) {
    commit("startLoadingFlag", "updateUserIpAllowedVerification");
    return new Promise((resolve, reject) => {
      axios
        .put(`/update_users_ip_verification`, payload)
        .then(response => {
          commit("system/setSuccessMessage", response.data.detail, { root: true });
          resolve();
        })
        .catch(response => {
          commit("system/setErrorMessage", response.response.data, { root: true });
          reject();
        })
        .finally(() => {
          commit("stopLoadingFlag", "updateUserIpAllowedVerification");
        });
    });
  },

  listIpsAllowed({ commit }) {
    commit("clearErrors");
    commit("startLoadingFlag", "listIpsAllowed");
    commit("setListIpAllowed", []);

    axios
      .get("/company/ip_allow_list")
      .then(response => {
        commit("setListIpAllowed", response.data.data);
      })
      .finally(() => {
        commit("stopLoadingFlag", "listIpsAllowed");
      });
  },

  createIp({ commit }, payload) {
    commit("clearErrorFlags", "createIp");
    commit("startLoadingFlag", "createIp");

    return new Promise((resolve, reject) => {
      axios
        .post("/company/ip_allow_list", payload)
        .then(response => {
          commit("system/setSuccessMessage", response.data.detail, { root: true });
          resolve();
        })
        .catch(response => {
          commit("setErrorFlags", { flag: "createIp", errors: response.response.data.errors });
          reject();
        })
        .finally(() => {
          commit("stopLoadingFlag", "createIp");
        });
    });
  },

  deleteIp({ commit }, ids) {
    commit("clearErrors");
    commit("startLoadingFlag", "deleteIp");

    return new Promise((resolve, reject) => {
      axios
        .delete("/company/delete_ip_allow_list", { params: ids })
        .then(() => {
          commit("system/setSuccessMessage", "success_message.remove_ip", { root: true });
          resolve();
        })
        .catch(response => {
          commit("system/setErrorMessage", response.response.data, { root: true });
          reject();
        })
        .finally(() => {
          commit("stopLoadingFlag", "deleteIp");
        });
    });
  },

  updateIp({ commit }, payload) {
    commit("clearErrorFlags", "updateIp");
    commit("startLoadingFlag", "updateIp");

    return new Promise((resolve, reject) => {
      axios
        .put("/company/update_ip_allow_list", payload)
        .then(response => {
          commit("system/setSuccessMessage", response.data.detail, { root: true });
          resolve();
        })
        .catch(response => {
          commit("setErrorFlags", { flag: "updateIp", errors: response.response.data.errors });
          reject();
        })
        .finally(() => {
          commit("stopLoadingFlag", "updateIp");
        });
    });
  }
};
