<template>
  <div class="campaign-container container-fluid">
    <header-component :title="$t('Campaigns')">
      <template #buttons>
        <improductive-calls v-if="user && user.role.name == 'manager'" />
        <router-link
          v-if="user && user.role.name == 'manager'"
          to="/manager/campaign/new"
          class="btn btn-primary"
        >
          <span>
            {{ $t("add_new_campaign") }}
          </span>
        </router-link>
      </template>
    </header-component>

    <campaign-metrics />
    <flash-message />

    <div class="col-lg-12 mb-4">
      <div class="d-flex align-items-center gap-3">
        <hr class="w-100">
        <button
          class="btn text-nowrap d-flex align-items-center show-cards"
          @click="showAgentsAndCallsRealTimeInfo"
          :class="!showCards ? 'btn-white btn-sm' : 'btn-primary btn-sm'"
          data-test="showCards-button"
        >
          <i
            :class="!showCards ? 'fe fe-chevron-down' : 'fe fe-chevron-up'"
            class="me-2 fs-5"
          />
          {{ $t("see_more").charAt(0).toUpperCase() + $t("see_more").slice(1) + " " }}
        </button>
        <hr class="w-100">
      </div>
    </div>
    <div
      class="row"
      v-if="showCards"
    >
      <div class="col-12 col-xxl-6">
        <status-card
          card-title="agents"
          :is-loading="agentsLoadingFlags.loadAllAgentsOnline"
          :count="agentsCardData.reduce((acc, curr) => acc + curr.value, 0)"
          :data-status="agentsCardData"
        />
      </div>
      <div class="col-12 col-xxl-6">
        <status-card
          :is-loading="callsLoadingFlags.loadAllActiveCalls"
          card-title="calls_cham"
          :count="callsCardData.reduce((acc, curr) => acc + curr.value, 0)"
          :data-status="callsCardData"
        />
      </div>
    </div>

    <div class="row">
      <campaigns-table />
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { mapActions, mapGetters } from "vuex";
import { AgentStatus, CallStatus, countAgentsByStatus } from "@/utils";

import CampaignsTable from "./components/campaigns-table.vue"
import FlashMessage from "@components/flash-message";
import ImproductiveCalls from "@dialer/manager/components/improductive-calls.vue";
import CampaignMetrics from "./components/campaign-metrics.vue";
import StatusCard from "@dialer/manager/pages/dashboards/campaign/components/status-card.vue";
import HeaderComponent from "@/components/header-component.vue";

export default {
  data() {
    return {
      start_date: moment().startOf("day").format("YYYY-MM-DD HH:mm:ss"),
      end_date: moment().endOf("day").format("YYYY-MM-DD HH:mm:ss"),
      showCards: false,
      wasAgentsAndCallsCardInfoLoaded: false
    };
  },

  computed: {
    ...mapGetters("auth", {
      user: "getUser"
    }),

    ...mapGetters("manager/campaign/agents", {
      allAgentsOnline: "getAllAgentsOnline",
      agentsLoadingFlags: "getLoadingFlags"
    }),

    ...mapGetters("manager/campaign/calls", {
      allActiveCalls: "getAllActiveCalls",
      callsLoadingFlags: "getLoadingFlags"
    }),

    agentsCardData() {
      return [
        {
          label: "talking",
          value: countAgentsByStatus(this.allAgentsOnline, AgentStatus.ON_CALL) +
              countAgentsByStatus(this.allAgentsOnline, AgentStatus.ON_MANUAL_CALL_CONNECTED) +
              countAgentsByStatus(this.allAgentsOnline, AgentStatus.MANUAL_CALL_CONNECTED),
          color: "#008A35"
        },
        {
          label: "manual",
          value: countAgentsByStatus(this.allAgentsOnline, AgentStatus.ON_MANUAL_CALL),
          color: "#E67C0B"
        },
        {
          label: "idle",
          value: countAgentsByStatus(this.allAgentsOnline, AgentStatus.IDLE),
          color: "#D2DDEC"
        },
        {
          label: "break",
          value: countAgentsByStatus(this.allAgentsOnline, AgentStatus.ON_WORK_BREAK),
          color: "#FFBD00"
        },
        {
          label: "mact",
          value: countAgentsByStatus(this.allAgentsOnline, AgentStatus.ON_MANUAL_CALL_ACW),
          color: "#5517E6"
        },
        {
          label: "act",
          value: countAgentsByStatus(this.allAgentsOnline, AgentStatus.ACW),
          color: "#3057F2"
        },
      ];
    },

    callsCardData() {
      return [
        {
          label: "calling",
          value: this.countActiveCallsByStatus(CallStatus.DIALING),
          color: "#B1C2D9"
        },
        {
          label: "talking",
          value: this.countActiveCallsByStatus(CallStatus.CONNECTED),
          color: "#008A35"
        },
        {
          label: "waiting",
          value: this.countActiveCallsByStatus(CallStatus.ANSWERED),
          color: "#E67C0B"
        },
        {
          label: "amd",
          value: this.countActiveCallsByStatus(CallStatus.ANSWERED, true),
          color: "#E60B42"
        }
      ];
    }
  },

  components: {
    FlashMessage,
    ImproductiveCalls,
    CampaignMetrics,
    CampaignsTable,
    StatusCard,
    HeaderComponent
  },

  methods: {
    ...mapActions("manager/campaign/agents", [
        "loadAllAgentsOnline",
        "clearAgentsData"
    ]),

    ...mapActions("manager/campaign/calls", [
        "loadAllActiveCalls"
    ]),

    ...mapActions("manager/campaign", ["clearCampaignsData"]),

    countActiveCallsByStatus(status, amdStatus = false) {
      if(status === CallStatus.ANSWERED) {
        if(amdStatus) {
          return this.allActiveCalls[status] ?
              Object.values(this.allActiveCalls[status]).filter((call) => call.amd_status > -1).length : 0;
        } else {
          return this.allActiveCalls[status] ?
              Object.values(this.allActiveCalls[status]).filter((call) => {
                return !call.amd_status || call.amd_status === "-1";
              }).length : 0;
        }
      }

      return this.allActiveCalls[status] ? Object.values(this.allActiveCalls[status]).length : 0;
    },

    showAgentsAndCallsRealTimeInfo() {
      this.showCards = !this.showCards;

      if(!this.wasAgentsAndCallsCardInfoLoaded) {
        this.loadAllAgentsOnline();
        this.loadAllActiveCalls();
      }
      this.wasAgentsAndCallsCardInfoLoaded = true;
    }

  },

  beforeRouteLeave() {
    this.clearCampaignsData();
    this.clearAgentsData();
  },
};

</script>

<style lang="scss" scoped>
.header-body {
  border-bottom: none !important;
}

.show-cards {
  height: 30px;
}
</style>
