import axios from "@crm/plugins/axios-config";

export default {
  fetchQuestions ({commit}, {funnel_id, page}) {
    commit('startLoading', 'fetchQuestions');
    return new Promise((resolve, reject) => {
      axios.get(`/fuck-the-dash/${funnel_id}/history`, {params: {
        page
        }}).then((response) => {
        resolve(response.data)
      }).catch((error) => {
        commit('system/setErrorMessage', error.response.data, {root: true})
        reject()
      }).finally(() => {
        commit('stopLoading', 'fetchQuestions');
      })
    })
  },

  sendQuestion ({commit}, payload ) {
    commit('startLoading', 'sendQuestion');
    return new Promise((resolve, reject) => {
      axios.post(`/fuck-the-dash`, payload).then(()=> {
        resolve()
      }).catch((e) => {
        commit('setErrorsMessage', e.response.data.errors)
        commit('stopLoading', 'sendQuestion');
        reject()
      })
    })
  },

  fetchQuestionById ({commit}, {message_id, page}) {
    commit('startLoading', 'fetchQuestionById');
    return new Promise((resolve, reject) => {
      axios.get(`/fuck-the-dash/${message_id}/message/history`, {params: {
          page
        }}).then((response) => {
        resolve(response.data)
      }).catch( error => {
        commit('system/setErrorMessage', error.response.data, {root: true})
        reject()
      }).finally(() => {
        commit('stopLoading', 'fetchQuestionById');
      })
    })
  },
}
