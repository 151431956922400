<template>
  <div
    style="border-color: #E1E9F4;"
    class="card rounded-2 shadow-none"
  >
    <div
      style=" padding: 1rem 1.5rem;"
      class="card-header"
    >
      <div class="col">
        <form
          novalidate
          @submit.prevent="search"
        >
          <search-input
            :placeholder="this.$t('search_product_or_service')"
            @search="query"
          />
        </form>
      </div>
      <ul class="nav nav-tabs nav-tabs-sm card-header-tabs">
        <categories-table-tab
          v-for="category in categoriesTab"
          :key="category.id"
          :data-bs-target="category.target"
          :active-state="category.activeState"
          :categories-count="category.categoriesCount"
        />
      </ul>
    </div>

    <div
      style="padding: 1rem"
      class="card-body"
    >
      <div class="tab-content">
        <categories-table-pane
          v-for="categoryPane in categoriesPane"
          :key="categoryPane.id"
          :id="categoryPane.id"
          :loading="categoryPane.loading"
          :list-categories="categoryPane.listCategories"
          :is-active="categoryPane.isActive"
        />
      </div>
    </div>
    <category-modal-component />
    <exclude-modal />
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import CategoriesTableTab
  from "@dialer/manager/pages/settings/products-and-services/components/categories-table-tab.vue";
import CategoriesTablePane
  from "@dialer/manager/pages/settings/products-and-services/components/categories-table-pane.vue";
import CategoryModalComponent
  from "@dialer/manager/pages/settings/products-and-services/components/category-modal-component.vue";
import ExcludeModal from "@dialer/manager/pages/settings/products-and-services/components/exclude-modal.vue";
import SearchInput from "@components/search-input.vue";
import _ from "lodash";

export default {
  name: "CategoriesTable",
  components: {
    SearchInput,
    ExcludeModal,
    CategoryModalComponent,
    CategoriesTablePane,
    CategoriesTableTab
  },

  data() {
    return {
      exclude: false
    }
  },

  computed: {
    ...mapGetters("manager/products_and_services", {
      listActiveCategories: "getActiveCategories",
      listInactiveCategories: "getInactiveCategories",
      loadingFlag: "getLoadingFlag",
      showModal: "getShowModal"
    }),

    categoriesTab() {
      return [
        {
          id: 1,
          target: 'actives',
          activeState: this.$t('uppercases.actives'),
          categoriesCount: this.listActiveCategories.length
        },
        {
          id: 2,
          target: 'inactives',
          activeState: this.$t('uppercases.inactives'),
          categoriesCount: this.listInactiveCategories.length
        },
      ]
    },

    categoriesPane() {
      return [
        {
          id: "actives",
          loading: this.loadingFlag.activeCategories,
          listCategories: this.listActiveCategories,
          isActive: this.listActiveCategories.length >= this.listInactiveCategories.length
        },
        {
          id: "inactives",
          loading: this.loadingFlag.inactiveCategories,
          listCategories: this.listInactiveCategories,
          isActive: this.listInactiveCategories.length > this.listActiveCategories.length
        },
      ]
    }
  },

  mounted() {
    this.fetchTeams();
    this.fetchCategories(0);
    this.fetchCategories(1);
  },

  methods: {
    ...mapActions("manager/teams", [
      "fetchTeams"
    ]),

    ...mapActions("manager/products_and_services", [
      "fetchCategories",
      "fetchPnS"
    ]),

    query: _.debounce(function (term) {
      if (term !== "") {
        this.fetchPnS(
          {
            query: term,
            is_active: 1
          }
        );
        this.fetchPnS(
          {
            query: term,
            is_active: 0
          }
        );

        return
      }

      this.fetchCategories(0);
      this.fetchCategories(1);
    }, 500),
  },
}
</script>

<style lang="scss" scoped>
.create-category {
  font-size: 12px;
  width: 240px;
  margin-bottom: 1rem;
}
</style>