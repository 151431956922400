export default {
  loading: false,
  loadingContext: '',
  errors: {},

  socketIOStatus: 'disconnected',//'disconnected', //'disconnected',
  userMediaPermission: 'peending',//'peending', //'peending', //, allowed, not-allowed
  jsSipStatus: 'disconnected', //'disconnected', //connected, registered, in-call
  jsSipStream: null,

  registrationAttemp: 0,

  showTransferCard: false,
  consulting: false,
  consultHold: false,

  //consulted
  agentInConsult: {},
  consultingAgentName: "",

  mode: '', //'dialer', 'manual', 'receptive'
  status: 0,

  acwTimeout: 0, //0 - infinite

  timeAsString: '00:00:00',
  totalSeconds: 0,
  timerTargetAchieved: false,

  showDialer: true,
  dialerText: '',

  DTMFkeys: '',

  muted: false,
  flashMessage: null,

  integrationURL: '',

  errorMessage: null,
  infoMessage: null,
  warningMessage: null,
  timeMessage: null,
  customizedTitleMessage: null,
  customMessageType: null,
  isMessage: false,

  /**
   * @typedef   {Object} confirmationMessage
   * @property {string} title
   * @property {"goodWarning" | "badWarning"} type
   * @property {string} text
  */
  confirmationMessage: null,
  confirmationMessageResponse: "",

  remaningACWTime: 0, // when enter on manual acw

  spiedAgent: null,
  selectedAgentToSpy: null,
  showSpy: false,
  spyError: "",

  manualTimeLimited: false,
  manualTimeout: 0,

  jsSIPActive: false,
  socketIOActive: false,

  // Request limit
  requestLimitExceeded: false,



  loadingFlags: {
    JsSIPConnecting: false,
    JsSIPRegistering: false,
    JsSipDisconnecting: false,
    JsSipUnregistering: false,
    startAgentSpy: false,
    stopAgentSpy: false,
    stopInterval: false,
    enterManual: false,
  },

  spyParams: {
    spy: 'both',
    whisper: 'none'
  },

  currentAudioUrl: "",

  mediaRecorder: null,
  isConnectedExecuted: false,
  userIp: null,
}
