<template>
  <div
    class="quick-messages"
    v-show="show"
  >
    <div class="box px-3 text-truncate">
      <div class="title-t b2 fs-5 m-0 py-1 mb-1">
        {{ $t('quick_responses') }}
      </div>
      <div class="b1 fs-5">
        <div>
          <table class="w-100">
            <tbody>
              <tr
                v-for="message in filtered"
                :key="message.id"
                @click="$emit('sendQuickMessage', message.message)"
              >
                <td
                  class="pe-3"
                  v-html="message.highlight"
                />
                <td>
                  {{ message.message }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";

export default {
  /**
   *
   */
  emits: ["sendQuickMessage", "close"],

  /**
   *
   */
  mounted() {
    window.addEventListener("keydown", this.keydown);
    window.addEventListener("click", this.outsideClick);
  },

  /**
   *  unregister events
   */
  beforeUnmount() {
    window.removeEventListener("keydown", this.keydown);
    window.removeEventListener("click", this.outsideClick);
  },
  /**
   *
   * data
   */
  data() {
    return {
      filtered: [],
    };
  },

  /**
   *
   */
  computed: {
    /**
     * quick messages getters
     */
    ...mapGetters("whatsapp/quick-message", {
      quickMessages: "getQuickMessages",
    }),
  },

  /**
   * props
   */
  props: {
    /**
     * modal show prop
     */
    show: {
      type: Boolean,
      default: true,
    },

    /**
     * sarch quick message
     */
    searchTerm: {
      type: String,
      default: "",
    },
  },

  /**
   *
   */
  watch: {

    /**
     * close whem mouse cick in anywhere
     */

    outsideClick() {
      this.$emit("close");
    },

    /**
     * show
     */
    show() {
      if (this.show) this.filter();
    },

    /**
     * search
     */
    searchTerm() {
      this.filter();
    },

    /**
     * quick message
     */
    quickMessages(data) {
      if (data) this.filter();
    },
  },

  methods: {
    ...mapMutations("auth", ["trackEvents"]),

    /**
     *
     */
    outsideClick() {
      this.$emit("close");
    },

    /**
     * enter pressed
     *
     * @param {*} e
     */
     keydown(e) {
      if (this.show && e.keyCode == 13) {
        e.preventDefault()

        let match = this.quickMessages.find((message) => {
          return message.shortcut.toLowerCase().includes(this.searchTerm.toLowerCase());
        });

        if (match){
          this.trackEvents('[Agent] Quick Message Use')
          this.$emit("sendQuickMessage", match.message);
        }
      }
    },

    /**
     * filter quick messages
     */
    filter() {
      if (this.quickMessages.length) {
        let temp = [];
        for (let message of this.quickMessages) {
          if (message.shortcut.toLowerCase().includes(this.searchTerm.toLowerCase())) {
            let shortcut = message.shortcut.replace("/", ""); //.substring(1);
            message.highlight = shortcut.replace(
              this.searchTerm.toLowerCase(),
              "<strong class='highlight'>" + this.searchTerm.toLowerCase() + "</strong>"
            );
            temp.push(message);
          }
        }
        if (!temp.length) this.$emit("close");
        else this.filtered = temp;
      }
    },
  },
};
</script>

<style lang="scss">
.quick-messages {
  position: relative;
  tr {
    cursor: pointer;
    user-select: none;
    border-bottom: 1px solid #e0e6ed !important;
  }
  tr:last-child {
    border: none !important;
  }
  .box {
    top: -240px;
    left: 32px;
    width: calc(100% - 64px);
    height: 160px;
    border-radius: $whatsapp-border-radius;
    border: 1px solid #e0e6ed;
    background-color: white;
    position: absolute;
    font-weight: 300;

    .title-t {
      color: $color-blue-300;
    }
  }
  .box {
    overflow-y: auto;
    height: 228px;
    &::-webkit-scrollbar {
      width: $scrollbar-width !important;
      height: $scrollbar-width !important;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #b0c5c2 !important;
      border-radius: $scrollbar-radius !important;
    }
  }
}
</style>
