
<template>
  <div
    class="blur position-fixed bottom-0 start-0 h-100 w-100"
    v-show="isCollapsed"
  />
  <div class="assistant text-center position-fixed bottom-0 start-0 w-100 d-flex flex-column align-items-center">
    <button
      class="btn button-ai d-flex gap-3 align-items-center justify-content-center m-0"
      @click="toggleAssistant"
      type="button"
      data-bs-toggle="collapse"
      data-bs-target="#collapseAiAssistant"
      aria-expanded="false"
      aria-controls="collapseAiAssistant"
    >
      <i class="fa-regular fa-sparkles" />
      <p class="m-0">
        F#ck the Dashboard
      </p>
      <i
        class="fa-regular "
        :class="isCollapsed ? 'fa-chevrons-down' : 'fa-chevrons-up'"
      />
    </button>
    <div
      id="collapseAiAssistant"
      class="collapse card overflow-hidden align-items-center m-0"
      style="width: 90%;background-color: #F9FAFC; border-radius: 16px 16px 0 0;"
    >
      <div
        class="card-body d-flex align-items-center flex-column overflow-auto w-100"
        style="height: 90vh; padding: 40px"
        v-if="!chatOpen"
      >
        <div>
          <div class="d-flex flex-row justify-content-center align-items-baseline mt-4">
            <h1>{{ $t('ai_assistant_title') }}</h1>
            <div class="sparkles " />
          </div>
          <p>
            {{ $t('ai_assistant_subtitle') }}
          </p>
        </div>
        <div class="effect-bg d-flex justify-content-center align-items-center">
          <input-ai-assistant
            :row="3"
            @question="toggleChat"
          />
        </div>
        <suggestions-ai-assistant
          :is-chat="false"
          class="ps-4 pe-4"
          @suggestion="toggleChat"
        />
        <history-ai-assistant @selected-question="handleSelectedQuestion" />
      </div>
     
      <chat-ai-assistant
        v-else-if="chatOpen"
        @new-question="toggleChat"
        :current-question="selectedQuestion"
      />

      <div
        v-if="!chatOpen"
        class="bottom-effect"
      />
    </div>
  </div>
</template>

<script>
import InputAiAssistant from "@crm/components/ai-assistant/input-ai-assistant.vue";
import SuggestionsAiAssistant from "@crm/components/ai-assistant/suggestions-ai-assistant.vue";
import ChatAiAssistant from "@crm/components/ai-assistant/chat-ai-assistant.vue";
import HistoryAiAssistant from "@crm/components/ai-assistant/history-ai-assistant.vue";

export default {
  components: {HistoryAiAssistant, ChatAiAssistant, SuggestionsAiAssistant, InputAiAssistant},
  data() {
    return {
      isCollapsed: false,
      chatOpen: false,
      selectedQuestion: null,
    };
  },
  methods: {
    toggleAssistant() {
      this.isCollapsed = !this.isCollapsed;
    },
    toggleChat(body) {
      this.selectedQuestion = body;
      this.chatOpen = !this.chatOpen;
    },
    handleSelectedQuestion(question) {
      this.selectedQuestion = question;
      this.chatOpen = true;
    }
  },
};
</script>

<style scoped>
.blur {
  backdrop-filter: blur(5px);
  background: rgba(163, 163, 163, 0.20);
}
.assistant {
  text-align: center;
  padding-left: 65px;
}

button {
  margin-bottom: 10px;
}
.collapse-content {
  overflow: hidden;
}
.effect-bg{
  background-image: url("~@assets/img/purple-blur-effect.png");
  width: 100%;
  min-height: 418px;
  background-size: 100% 100%;
}
.bottom-effect{
  background-image: url("~@/assets/img/purple-bottom-effect.svg");
  width: 1284px;
  height: 2px;
}
.sparkles{
  background-image: url("~@/assets/img/sparkles.svg");
  width: 38px;
  height: 51px;
}
.button-ai {
  color: #fff;
  width: 290px;
  border-radius: 16px 16px 0 0;
  position: relative;
  overflow: hidden;
  display: flex;
  border: 0;
  margin: 0;
  padding: 10px;
  z-index: 1;
  background:  linear-gradient(94deg, #E34AB8 -42.06%, #7745EB 64.09%, #6CBAFE 193.52%);
}

.button-ai::before,
.button-ai::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: opacity 0.6s ease-in-out;
  z-index: -1;
}

.button-ai::before {
  background: linear-gradient(94deg, #E34AB8 -42.06%, #7745EB 64.09%, #6CBAFE 193.52%);
  opacity: 1;
}

.button-ai::after {
  background: linear-gradient(90deg, #E34AB8 -42.27%, #7745EB 13.8%, #6CBAFE 95.42%);
  opacity: 0;
}

.button-ai:hover::before {
  opacity: 0;
}

.button-ai:hover::after {
  opacity: 1;
}

</style>