export default {
  // TODO: Remove loading and use only loadingFlags

  loading: false,
  loadingFlags: {
    findListOfLists: false,
    recycle: false,
    updateWeight: false,
    updateGoalList: false,
  },
  errors: {},
  list: [],
  loadingContext: '',
  listDataPreview: [],
  listHeaderPreview: [],
  register: null,
  filters: {},
  recycleFilters: {},
  include: [''],
  pagination: {
    current_page: 1
  },
  dataSelect: [],
  fileName: ""
}
