<template>
  <transition
    name="fade"
    mode="in-out"
  >
    <div
      v-if="!isOnline"
      class="row m-0"
      style="padding: 16px;"
    >
      <div
        class="col-lg-12 px-0 d-flex justify-content-start align-items-center"
        style="gap: 16px;"
      >
        <div class="rounded-circle no-connection d-flex align-items-center justify-content-center">
          <span
            class="fal fa-wifi-slash"
          />
        </div>
        <div>
          <h6
            class="text text-medium fw-500 m-0"
            style="padding-bottom: 4px;"
          >
            {{ $t('no_connection') }}
          </h6>
          <p
            class="text fw-normal text-small m-0 text-justify me-3"
          >
            {{
              $t('no_connection_description')
            }}
          </p>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: "NoConnection",

  mounted() {
    window.addEventListener('offline', this.setOffline);
    window.addEventListener('online', this.setOnline);
  },

  beforeUnmount() {
    window.removeEventListener('offline', this.setOffline);
    window.removeEventListener('online', this.setOnline);
  },

  data() {
    return {
      isOnline: true,
    };
  },

  methods:{
    setOffline() {
      this.isOnline = false;
    },
    setOnline() {
      this.isOnline = true;
    },
  }
}
</script>

<style lang="scss" scoped>

.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter, .fade-leave-to {
  opacity: 0;
}

.no-connection {
  font-size: 19px;
  width: 56px;
  aspect-ratio: 1/1;
  background-color: #ffbb3a;
}
</style>
