export default {
  loadingFlags: {
    loadActiveCompanies: false,
    loadBlockedCompanies: false,
    loadTrashedCompanies: false,
    findById: false,
    findTotalLogged: false,
    findTotalMaxLogin: false,
    findCallStats: false,
    findCompanyRoutes: false,
    listCompanyBilling: false,
    editCompanyRoute: false,
    listCompanyCredit: false,
    findReceptiveNumbers: false,
    createReceptiveNumbers: false,
    deleteReceptiveNumbers: false,
    editCompanySettings: false,
    removeCompany: false,
    findCompanyHistory: false,
    changeStatusInsightsAI: false
  },

  // Active Companies
  activeCompanies: [],
  activeCompaniesFilters: {
    actives: true,
    orderByDesc: "max_agents_login"
  },
  activeCompaniesPagination: {
    current_page: 1
  },
  activeCompaniesIncludes: [
    'agent-status',
    'calls',
  ],

  // Blocked Companies
  blockedCompanies: [],
  blockedCompaniesFilters: {
    blocked: true,
    orderByDesc: "blocked_at",
  },
  blockedCompaniesPagination: {
    current_page: 1
  },
  blockedCompaniesIncludes: [],

  // Trashed Companies
  trashedCompanies: [],
  trashedCompaniesFilters: {
    trashed: true,
  },
  trashedCompaniesPagination: {
    current_page: 1
  },
  trashedCompaniesIncludes: [],

  errors: {},
  errorMessage: "",
  removeCompanyErrors: {},
  removeCompanyErrorMessage: "",
  companyRoutesList: [],
  receptiveNumbersList: [],
  companyBilling: [],
  companyCredit: [],
  listOfCompaniesWithoutPagination: [],


  blockedFilters: {},
  trashedFilters: {},
  companyCreditFilters: {},

  companyCreditPagination: {
    per_page: 15,
    current_page: 1
  },
  register: null,
  companyHistory: null,

  companyHistoryPagination: {
    per_page: 15,
    current_page: 1
  },
  blockCompaniesParams: {},

  companyHistoryFilters: {},

  // dataSelect: []
  totalLogged: 0,
  totalMaxLogin: 0,
  callStats: null,
  receptiveNumbersFilters: {},
  receptiveNumbersPagination: {
    current_page: 1,
  },
  companyRoutesPagination: {
    current_page: 1,
  },
  receptiveNumbersErrors: {},
  companyRoutesErrors: {},
  telephonyRatesErrors: {},
  // callsStats: 0

  newCompanyDataFeedback : null,
}
