<template>
  <div class="d-flex align-items-center">
    <div
      class="card m-0"
      style="min-width: 350px; height: min-content"
    >
      <div class="card-body p-0">
        <div
          class="d-flex align-items-center"
          style="padding: 1rem 1rem 0 1rem; width: 350px"
        >
          <action-component
            icon-bg="#D6E9D9"
            icon-class="fa-regular fa-messages-question"
            icon-color="#015D0D"
            :name="$t('multiple_choice_question')"
          />
          <app-button
            class="btn-md col-auto"
            color="btn-gray-blue"
            @click="editAction"
          >
            <span class="fa-regular fa-pen-to-square" />
          </app-button>
        </div>
        <hr class="w-100">
        <div
          class="d-flex flex-column justify-content-center"
          style="padding: 0 1rem 1rem 1rem; width: 350px"
        >
          <p class="m-0 tx-text-gray">
            {{ $t('text') }}
          </p>
          <card-text :text="action.text_content" />
          <hr class="w-100">
          <div
            class="d-flex flex-column"
            style="gap: 16px"
          >
            <div
              v-for="(item, index) in choices"
              :key="index"
            >
              <p
                class="m-0 tx-text-gray"
                style="margin-bottom: 8px"
              >
                {{ $t('option') }} {{ item.expected_message_response }}
              </p>
              <card-text
                :text="item.text_content"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <multi-arrow
      :action-id="action.id"
      :choices-id="getChoicesId(action.choices.data)"
    />
    <div
      class="d-flex flex-column justify-content-center"
      style="gap: 16px"
    >
      <slot
        v-for="item in choices"
        :name="`${item.expected_message_response}`"
      />
    </div>
  </div>
</template>

<script>
import {mapMutations} from "vuex";

import ActionComponent from "@chatbot/pages/workflow/components/action-component.vue";
import CardText from "@chatbot/pages/workflow/components/card-text.vue";
import MultiArrow from "../arrows/multi-arrow.vue";
import AppButton from "@components/app-button.vue";

export default {
  name: "CardChoice",

  props: {
    action: {
      type: Object,
      required: true
    },
    choices: {
      type: Object,
      required: true
    }
  },

  components: {
    AppButton,
    MultiArrow,
    CardText,
    ActionComponent
  },

  methods: {
    ...mapMutations("chatbot/actions", [
      "setOffcanvasEditActionIsOpen",
      "setActionToEdit"
    ]),

    getChoicesId(choices) {
      return choices.map(choice => choice.id)
    },

    editAction() {
      this.setActionToEdit(this.action);
      this.setOffcanvasEditActionIsOpen(true);
    }
  }
};
</script>
