<template>
  <hover-button
    ref="mailingDataPabxButton"
    @click="toggleDropdown"
    icon="fas fa-list-ul"
    :keep-button-hovered="showMailingData"
    :background-color="getBackgroundColor"
    :disabled="isCallDetailsDisabled"
    :active="
      status === AgentStatus.ON_CALL &&
        [
          CallStatus.CONNECTED,
          CallStatus.EXTERNAL_CALL_ANSWERED,
          CallStatus.TRANSFERENCE_CONNECTED
        ].includes(callStatus)
    "
  />

  <dropdown-component
    :is-open="showMailingData"
    :button-rect="buttonRect"
    :title="$t('data')"
  >
    <template #header>
      <div v-if="integrationURL">
        <a
          :href="integrationURL"
          class="btn btn-primary text-medium py-1 px-3 me-3 d-flex align-items-center justify-content-between gap-6"
          target="_blank"
        >
          {{ $t("integration_capitalize") }}
          <span class="fa-regular fa-external-link" />
        </a>
      </div>
    </template>

    <div class="overflow-auto card-call-details-body">
      <div>
        <div
          class="call-info pb-2"
          v-if="currentCall.sid"
        >
          <div>{{ $t("protocol") }}</div>
          <div class="tx-text-gray text-medium">
            {{ currentCall.sid }}
          </div>
        </div>

        <div
          class="call-info py-2"
          v-if="currentCall.call_mode"
        >
          <div>{{ $t("call_type") }}</div>
          <div class="tx-text-gray text-medium">
            {{
              getReadableMailingType(currentCall)
            }}
          </div>
        </div>

        <div
          class="call-info py-2"
          v-if="!manualCallAcw && mailing?.identifier"
        >
          <div>{{ $t("identifier") }}</div>
          <div class="tx-text-gray text-medium">
            {{ mailing.identifier }}
          </div>
        </div>

        <template v-if="!manualCallAcw || manualCallAcw && manualCallAcw.phone === mailing.phone">
          <div
            class="call-info py-2"
            v-for="(item, key) in mailing?.data"
            :key="key"
          >
            <div class="fw-bold">
              {{ key }}
            </div>
            <div
              :contenteditable="loggedCampaign !== null && loggedCampaign.update_mailing_data"
              class="tx-text-gray editable-mailing-field text-medium"
              @focusout="updateMailingData($event, key)"
              v-html="makeLinkTag(item)"
            />
          </div>
        </template>

        <div
          class="call-info py-2"
          v-if="false"
        >
          <div>{{ $t("call_started_by") }}</div>
          <div class="tx-text-gray text-medium">
            {{
              $t("some_agent_started")
            }}
          </div>
        </div>

        <div
          class="call-info py-2"
          v-if="false"
        >
          <div>{{ $t("call_forward_by") }}</div>
          <div class="tx-text-gray text-medium">
            {{
              $t("some_agent_forwarded")
            }}
          </div>
        </div>

        <div
          class="call-info py-2"
          v-if="currentCall.connected_time"
        >
          <div>{{ $t("call_start") }}</div>
          <div class="tx-text-gray text-medium">
            {{
              timestampToDate(currentCall.connected_time, "DD/MM/YYYY [às] HH:mm:ss")
            }}
          </div>
        </div>

        <div
          class="call-info py-2"
          v-if="currentCall.phone"
        >
          <div>{{ $t("phone") }}</div>
          <div class="tx-text-gray text-medium">
            {{ currentCall.phone }}
          </div>
        </div>
      </div>
    </div>
  </dropdown-component>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
import { AgentStatus, CallStatus, Utils } from "@/utils";
import HoverButton from "@dialer/agent/components/hover-button.vue";
import DropdownComponent from "@dialer/agent/components/dropdown-component.vue";

export default {
  name: "CallDetails",
  data() {
    return {
      CallStatus,
      AgentStatus,
      showMailingData: false,
      buttonRect: null,
    };
  },

  components: {
    HoverButton,
    DropdownComponent,
  },

  computed: {
    ...mapGetters("system", {
      status: "getStatus",
      mode: "getMode",
      isConsultHold: "isConsultHold",
      integrationURL: "getIntegrationURL",
    }),

    ...mapGetters("agent/ring_group", {
      callStatus: "getCallStatus",
      callPABX: "getCallPABX",
    }),

    ...mapGetters("agent/agent", {
      loggedCampaign: "getCampaign",
      activeDropdownLeft: "getActiveDropdownLeft",
    }),

    ...mapGetters("agent/call", {
      call: "getCall",
      manualCallAcw: "getManualCallACW",
      mailing: "getMailing",
    }),

    currentCall() {
      return this.call || this.manualCallAcw || this.callPABX || {};
    },

    getBackgroundColor() {
      if (this.loggedCampaign && [AgentStatus.ON_CALL, AgentStatus.ACW, AgentStatus.ON_MANUAL_CALL_ACW].includes(this.status)) {
        return "var(--bs-white)";
      }

      return "#E1E9F4";
    },

    isCallDetailsDisabled() {
      const isLoggedAgentBeingConsulted = !this.call && this.status === AgentStatus.CONSULT_CONNECTED;
      return this.isConsultHold ||
          isLoggedAgentBeingConsulted ||
          [
            CallStatus.DIALING,
            CallStatus.CONSULT_ANSWERED,
            CallStatus.TRYING_INTERNAL_CALL,
            CallStatus.INTERNAL_CALL_FAILED,
            CallStatus.CONSULT_TRYING,
          ].includes(this.callStatus);
    },
  },

  mounted() {
    if ([CallStatus.CONNECTED].includes(this.callStatus) || this.call?.status == CallStatus.CONNECTED) {
      this.toggleDropdown();
    }
  },

  methods: {
    timestampToDate: Utils.timestampToDate,
    ...mapActions("agent/call", ["updateMailing"]),
    ...mapActions("agent/agent", ["setActiveDropdownLeft"]),
    ...mapMutations("agent/call", ["setMailing"]),
    toggleDropdown() {
      this.showMailingData = !this.showMailingData;
      if (this.showMailingData) {
        this.setActiveDropdownLeft("CallDetails");
        this.calculateButtonRect();
        window.addEventListener("resize", this.calculateButtonRect);
      } else {
        window.removeEventListener("resize", this.calculateButtonRect);
      }
    },

    calculateButtonRect() {
      const rect = this.$refs.mailingDataPabxButton.$el.getBoundingClientRect();
      this.buttonRect = rect;
    },

    getReadableMailingType({ call_mode, ura }) {
      if (ura) {
        return this.$t("active_IVR");
      }
      switch (call_mode) {
        case "manual":
          return this.$t("manual_capitalize");
        case "acw_manual":
          return this.$t("manual_act");
        case "dialer":
          return this.$t("dialer_capitalize");
        case "receptive":
          return this.$t("receptive_call");
        case "consult":
          return this.$t("consult");
        default:
          return this.$t("dialer_capitalize");
      }

    },

    updateMailingData(event, key) {
      let payload = {
        id: this.mailing._id,
        fields: {
          campaign_id: this.loggedCampaign.id,
          mode: this.mode,
          [key]: event.target.innerHTML,
        },
      };

      this.updateMailing(payload).then(() => {
        let newMailing = this.mailing;
        newMailing.data[key] = event.target.innerHTML;

        this.setMailing(newMailing);
      });
    },

    makeLinkTag: function (value, target = "_blank") {
      // Simplest Pattern that matches with http URLs
      let patternStr = /^(https?:\/\/[^\s]+)/;
      let pattern = new RegExp(patternStr, "i");
      if (pattern.test(value)) {
        return `<a href="${value}" target="${target}">${value}</a>`;
      }
      return value;
    },
  },

  watch: {
    callStatus(newCallStatus) {
      if (!this.showMailingData && [CallStatus.CONNECTED, CallStatus.TRANSFERENCE_CONNECTED].includes(newCallStatus)) {
        this.toggleDropdown();

        return;
      }

      if (this.showMailingData && [CallStatus.CONSULT_TRYING].includes(newCallStatus)) {
        this.toggleDropdown();
      }
    },

    activeDropdownLeft(newVal) {
      if (newVal !== "CallDetails" && this.showMailingData) {
        this.toggleDropdown();
      }
    },
  },

  beforeUnmount() {
    window.removeEventListener("resize", this.calculateButtonRect);
  },
};
</script>

<style lang="scss" scoped>
.card-call-details-body {
  max-height: calc(100vh - 150px);

  padding: 1rem;

  > div:first-child {
    overflow-y: scroll;
  }

  .editable-mailing-field[contenteditable="true"]:focus {
    background: #fff;
    outline-color: #95aac9;
  }
}
</style>
