<template>
  <div class="col-12 col-xl-6">
    <status-card
      :card-title="$t('agents_capitalize')"
      :is-loading="loadingFlags.fetchAgentsByCampaignId"
      :count="agentsCardData.reduce((acc, curr) => acc + curr.value, 0)"
      :data-status="agentsCardData"
    >
      <div
        class="mx-4"
        style="margin-bottom: 1rem"
      >
        <search-input @search="term => (search = term)" />
      </div>

      <div
        class="table-responsive"
        style="max-height: 640px;"
      >
        <table
          class="table table-hover table-nowrap card-table"
          :class="{ 'mb-4': !showAgentsOffline }"
        >
          <thead
            class="position-sticky top-0"
            v-if="agentsCardData.reduce((acc, curr) => acc + curr.value, 0) || showAgentsOffline"
          >
            <tr>
              <th class="text-capitalize">
                {{ $t("agents") }}
              </th>
              <th class="text-capitalize">
                {{ $t("status") }}
              </th>
              <th class="text-center">
                {{ $t("today_cal") }}
              </th>
              <th class="px-0 text-center">
                {{ $t("average") }}
              </th>
              <th />
            </tr>
          </thead>
          <tbody style="border-top: none">
            <tr v-if="agentsCardData.reduce((acc, curr) => acc + curr.value, 0)">
              <td
                colspan="5"
                class="border-0"
              >
                <div
                  colspan="5"
                  style="height: 16px;"
                />
              </td>
            </tr>

            <template
              v-for="(agent, index) in Object.values(agents).filter(data => data.status !== 0)"
              :key="agent.id"
            >
              <tr
                v-if="
                  agent.status != AgentStatus.OFFLINE &&
                    (search === '' ||
                      agent.name.toLowerCase().includes(search) ||
                      agent.extension.toString().includes(search))
                "
                :style="spiedAgent?.id == agent?.id && 'background-color: #DFDFDF'"
                @mouseover="selectedAgent = agent"
                @mouseleave="deselectAgent"
              >
                <td
                  class="py-1 text-truncate"
                  :class="{ 'border-0': index === 0 }"
                  style="max-width: 11rem;"
                  :title="`${agent.extension} - ${agent.name}`"
                >
                  {{ agent.extension }} - {{ agent.name }}
                </td>
                <td
                  class="py-1 "
                  :class="{ 'border-0': index === 0 }"
                >
                  <div class="d-flex align-items-center position-relative">
                    <i
                      v-show="agent.work_break_exit_pending"
                      class="fa-regular fa-clock tx-text-gray position-absolute"
                    />
                    <agent-tooltip-status
                      :color="statuses[agent.status].color"
                      :title="agent.work_break_exit_pending ? $t('return_requested') : statuses[agent.status].title"
                      :agent="agent"
                      :is-spy-mode="spiedAgent?.id == agent?.id"
                      :limit-call-time="campaign.limit_call_time"
                    />
                  </div>
                </td>
                <td
                  class="py-1 tx-text-gray text-center"
                  :class="{ 'border-0': index === 0 }"
                  style="width: 68px"
                >
                  {{ agent.count }}
                </td>
                <td
                  class="py-1 px-0  tx-text-gray text-center"
                  :class="{ 'border-0': index === 0 }"
                >
                  {{ agent.average ? agent.average : "00:00" }}
                </td>
                <td
                  class="py-1 position-relative "
                  :class="{ 'border-0': index === 0 }"
                >
                  <tooltip-agent-info
                    v-if="selectedAgent == agent || spiedAgent?.id == agent?.id"
                    :agent="agent"
                    :campaign="campaign"
                    :intervals="intervals"
                    @show-info-modal="handleInfoModal"
                  />
                </td>
              </tr>
            </template>

            <tr v-if="showAgentsOffline">
              <td
                colspan="5"
                class="border-0"
              >
                <div class="my-3 d-flex align-items-center gap-3">
                  <hr class="w-100">
                  <button
                    class="btn btn-primary btn-sm text-nowrap d-flex align-items-center py-0 px-3"
                    @click="showAgentsOffline = false"
                    data-test="hideAgentsOffline-button"
                    style="height: 32px;"
                  >
                    <i class="me-2 fs-5 fe fe-chevron-up" />
                    {{ $t("hide") }} {{ countAgentsByStatus(agents, AgentStatus.OFFLINE) }} {{ $t("offline_agents") }}
                  </button>
                  <hr class="w-100">
                </div>
              </td>
            </tr>

            <template v-if="showAgentsOffline">
              <template
                v-for="(agent, index) in Object.values(agents).filter(data => data.status === 0)"
                :key="agent.id"
              >
                <tr
                  v-if="
                    agent.status == AgentStatus.OFFLINE &&
                      (search === '' ||
                        agent.name.toLowerCase().includes(search) ||
                        agent.extension.toString().includes(search))
                  "
                >
                  <td
                    class="py-1 text-truncate"
                    :class="{ 'border-0': index === 0 }"
                    style="max-width: 11rem;"
                    :title="`${agent.extension} - ${agent.name}`"
                  >
                    {{ agent.extension }} - {{ agent.name }}
                  </td>
                  <td
                    class="py-1"
                    :class="{ 'border-0': index === 0 }"
                  >
                    <agent-tooltip-status
                      :color="statuses[agent.status].color"
                      :title="statuses[agent.status].title"
                      :agent="agent"
                      :limit-call-time="campaign.limit_call_time"
                    />
                  </td>
                  <td
                    class="py-1 tx-text-gray text-center"
                    :class="{ 'border-0': index === 0 }"
                    style="width: 68px"
                  >
                    {{ agent.count }}
                  </td>
                  <td
                    class="py-1 px-0  tx-text-gray text-center"
                    :class="{ 'border-0': index === 0 }"
                  >
                    {{ agent.average ? agent.average : "00:00" }}
                  </td>
                  <td />
                </tr>
              </template>
            </template>
          </tbody>
        </table>

        <button
          v-if="!showAgentsOffline && countAgentsByStatus(agents, AgentStatus.OFFLINE)"
          class="btn btn-white btn-sm text-nowrap d-flex align-items-center py-0 offline-agents-button"
          @click="showAgentsOffline = true"
          data-test="showAgentsOffline-button"
        >
          <i class="me-2 fs-5 fe fe-chevron-down" />
          {{ $t("show") }} {{ countAgentsByStatus(agents, AgentStatus.OFFLINE) }} {{ $t("offline_agents") }}
        </button>
      </div>
    </status-card>

    <agent-spy
      ref="agentSpy"
      :campaign="campaign"
    />
    <modal-component
      :show="showInfoModal"
      :all-customized="true"
      :center="true"
      @close="showInfoModal = false"
    >
      <template #content>
        <button
          class="btn btn-close position-absolute end-0 mt-2 fs-2"
          @click="showInfoModal = false"
          style="margin-right: -40px;"
        />
        <info-client-and-agent
          v-if="showInfoModal"
          :agent="agentInfo"
          :campaign="campaign"
          :spy="false"
        />
      </template>
    </modal-component>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import { AgentStatus, countAgentsByStatus, Utils } from "@/utils";

import AgentTooltipStatus from "./agent-tooltip-status.vue";
import TooltipAgentInfo from "./agent-tooltip-info.vue";
import AgentSpy from "./agent-spy.vue";
import InfoClientAndAgent from "./info-client-and-agent.vue";
import ModalComponent from "@/components/modal-component.vue";
import StatusCard from "./status-card.vue";
import SearchInput from "@/components/search-input.vue";

export default {
  name: "AgentsStatus",
  emits: ["boleanHasUsersOnline"],
  data() {
    return {
      statuses: {
        [AgentStatus.IDLE]: { color: "#D2DDEC", title: this.$t("capitalizes.idle") },
        [AgentStatus.ON_CALL]: { color: "#008A35", title: this.$t("capitalizes.talking") },
        [AgentStatus.ACW]: { color: "#3057F2", title: this.$t("acronym.act") },
        [AgentStatus.ON_MANUAL_CALL_ACW]: { color: "#5517E6", title: this.$t("acronym.mact") },
        [AgentStatus.MANUAL_CALL_CONNECTED]: { color: "#008A35", title: this.$t("acronym.mact") },
        [AgentStatus.ON_MANUAL_CALL]: { color: "#E67C0B", title: this.$t("capitalizes.manual") },
        [AgentStatus.ON_MANUAL_CALL_CONNECTED]: { color: "#008A35", title: this.$t("capitalizes.manual") },
        [AgentStatus.ON_WORK_BREAK]: { color: "#FFBD00", title: this.$t("capitalizes.break") },
        [AgentStatus.OFFLINE]: { color: "#DFDFDF", title: "Offline" }
      },
      countStatuses: [0, 0, 0, 0, 0, 0],
      AgentStatus,
      showAgentsOffline: false,
      selectedAgent: null,
      showInfoModal: false,
      agentInfo: {},
      search: "",
      intervals: []
    };
  },

  components: {
    AgentTooltipStatus,
    TooltipAgentInfo,
    AgentSpy,
    InfoClientAndAgent,
    StatusCard,
    SearchInput,
    ModalComponent
  },

  beforeMount() {
    if (this.campaign.work_break_group) {
      this.findWorkBreakGroupById({ id: this.campaign.work_break_group.id, include: ["intervals"] });
    }
  },

  beforeUnmount() {
    this.clearAgentsData();
    if (this.jsSipStatus == "registered" || this.jsSipStatus == "in-call") this.setJsSIPActive(false);
  },

  props: {
    campaign: {
      required: true,
      type: Object,
      default: () => {}
    }
  },

  mounted() {
    window.addEventListener("click", this.mustHideTooltipButton);
  },

  computed: {
    ...mapGetters("manager/campaign/agents", {
      agents: "getAgents",
      loadingFlags: "getLoadingFlags"
    }),

    ...mapGetters("system", {
      spiedAgent: "getSpiedAgent",
      jsSipStatus: "getJsSipStatus",
      showSpy: "getShowSpy"
    }),

    ...mapGetters("manager/intervals", {
      workBreakGroup: "getWorkBreakGroup"
    }),

    agentsCardData() {
      return [
        {
          label: "talking",
          value:
            countAgentsByStatus(this.agents, AgentStatus.ON_CALL) +
            countAgentsByStatus(this.agents, AgentStatus.ON_MANUAL_CALL_CONNECTED) +
            countAgentsByStatus(this.agents, AgentStatus.MANUAL_CALL_CONNECTED),
          color: "#008A35"
        },
        {
          label: "manual",
          value: countAgentsByStatus(this.agents, AgentStatus.ON_MANUAL_CALL),
          color: "#E67C0B"
        },
        {
          label: "idle",
          value: countAgentsByStatus(this.agents, AgentStatus.IDLE),
          color: "#D2DDEC"
        },
        {
          label: "break",
          value: countAgentsByStatus(this.agents, AgentStatus.ON_WORK_BREAK),
          color: "#FFBD00"
        },
        {
          label: "mact",
          value: countAgentsByStatus(this.agents, AgentStatus.ON_MANUAL_CALL_ACW),
          color: "#5517E6"
        },
        {
          label: "act",
          value: countAgentsByStatus(this.agents, AgentStatus.ACW),
          color: "#3057F2"
        }
      ];
    }
  },

  watch: {
    agentsStatuses: {
      handler(data) {
        this.countStatuses = [
          data[AgentStatus.IDLE],
          data[AgentStatus.ON_CALL],
          data[AgentStatus.ACW],
          data[AgentStatus.ON_MANUAL_CALL],
          data[AgentStatus.ON_WORK_BREAK],
          data[AgentStatus.ON_MANUAL_CALL_ACW]
        ];
      },
      deep: true
    },

    showSpy() {
      // TODO: improve
      // delay to get current selectedAgentToSpy
      setTimeout(() => this.$refs.agentSpy.startSpy(), 1);
    },

    workBreakGroup: {
      handler(data) {
        if (data && data.intervals) {
          this.intervals = data.intervals;
        }
      },
      deep: true
    }
  },

  methods: {
    timeFormat: Utils.timeFormat,
    countAgentsByStatus: countAgentsByStatus,
    ...mapActions("manager/campaign/agents", ["clearAgentsData"]),
    ...mapMutations("system", ["setJsSIPActive"]),
    ...mapActions("manager/intervals", ["findWorkBreakGroupById"]),

    hasUsersOnline() {
      this.$emit(
        "boleanHasUsersOnline",
        Object.values(this.agents).some(el => el.status !== AgentStatus.OFFLINE)
      );
    },

    handleInfoModal(agent) {
      this.showInfoModal = true;
      this.agentInfo = agent;
    },

    mustHideTooltipButton(event) {
      const clickedElement = event.target;
      // verify if clicked element is outside tooltip and agents table
      const parentElement = clickedElement.closest("tbody") || clickedElement.closest(".v-popper__popper--shown");
      if (!parentElement) {
        this.selectedAgent = null;
      }
    },

    deselectAgent() {
      const tooltip = document.querySelector(".v-popper__popper--shown");
      if (!tooltip) this.selectedAgent = null;
    }
  }
};
</script>

<style lang="scss" scoped>
.offline-agents-button {
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translate(-50%, 50%);
  height: 2rem;
  padding: 0 1rem;
}

.card-table tbody td:last-child {
  padding: 0 !important;
}

.fa-clock {
  left: -32px;
}
</style>
