<template>
  <div>
    <table class="table table-borderless ">
      <tr>
        <th class="visually-hidden w-min">
          *
        </th>
        <th
          colspan="2"
          class="w-min"
        >
          {{ $t('product') }}
        </th>
        <th class="w-min">
          {{ $t('quantity_abbreviation') }}
        </th>
        <th class="w-min">
          {{ $t('price') }}
        </th>
        <th class="w-min">
          {{ $t('recurrence') }}
        </th>
        <th class="w-min">
          {{ $t('discount') }}
        </th>
        <th class="w-min">
          Valor
        </th>
        <th class="visually-hidden w-min">
          *
        </th>
      </tr>
      <tbody v-if="!loading.fetchSynchronizedProducts || !loading.syncProductOpportunity">
        <product-form-row
          v-for="(row, index) in rows"
          :key="row.id"
          :row="row"
          :index="index"
          :currency="currency"
          :product-by-category="productByCategory"
          @update="updateRow"
          @remove-row="removeRow"
        />
      </tbody>
    </table>
    <div class="col-12 d-flex justify-content-center">
      <button
        type="button"
        class="btn btn-sm btn-light col-4"
        @click="addRow"
      >
        <i class="fa fa-plus" />
        <span class="ms-2">{{ $t('add_capitalize') }}</span>
      </button>
    </div>
  </div>
</template>
<script>
import {defineComponent} from 'vue'
import ProductFormRow from "@crm/components/contact/products/table/product-form-row.vue";
import {mapGetters} from "vuex";

export default defineComponent({
  name: "SyncProductsTable",
  components: {ProductFormRow},
  emits: ['update-sync-list'],

  props: {
    currency: { type: String, default: "BRL" },
  },

  data (){
    return {
      rows: [],
      product: {
        quantity: 1,
        maximum_discount: 0,
        discount: 0,
        discount_percentage: true,
        discount_amount: 0,
        is_active: true,
        is_recurrent: false,
        recurrence: this.$t('unique_f'),
        value: 0,
      }
    }
  },

  mounted (){
    this.loadRowData()
  },

  computed: {
    ...mapGetters("manager/products_and_services", {
      synchronizedProducts: "getSynchronizedProducts",
      loading: "getLoadingFlag",
      products: "getProducts",
    }),
    productByCategory (){
      const groupedProducts = {};
      this.products.forEach((product) => {
        const categoryId = product.category.id;
        const categoryName = product.category.name;

        if (!groupedProducts[categoryId]) {
          groupedProducts[categoryId] = {
            id: categoryId,
            name: categoryName,
            products: []
          };
        }

        groupedProducts[categoryId].products.push({
          id: product.id,
          name: product.name,
          ...product
        });
      });
      return Object.values(groupedProducts);
    }
  },

  methods: {
    addRow (){
      this.rows.push({ ...this.product})
    },

    removeRow(index){
      if (this.rows[index] && this.rows[index]){
          this.rows.splice(index, 1)
      }
      this.$emit('update-sync-list', this.rows)
    },

    loadRowData (){
      if(this.synchronizedProducts && this.synchronizedProducts.length > 0){
        this.rows = []
        let rows = [];
          this.synchronizedProducts.forEach((product) => {
             rows.push({
               id: product.id,
               quantity: product.quantity,
               maximum_discount: product.maximum_discount_in_percentage || product.maximum_discount,
               discount: product.discount,
               discount_percentage: product.discount_percentage,
               discount_amount: parseFloat(product.discount_amount),
               is_active: product.is_active,
               is_recurrent: product.is_recurrent,
               recurrence: product.is_recurrent ? this.$t('recurrent') : this.$t('unique_f'),
               value: product.value
             });
          })
        this.rows = rows;
      }
      if(!this.rows.length) {
        this.addRow()
      }
    },

    updateRow({data, index}){
      this.rows[index] = {
        id: data.product?.id || data.id,
        quantity: parseInt(data.quantity),
        maximum_discount: data.maximum_discount,
        discount: data.discount || 0,
        discount_percentage: data.discount_percentage,
        discount_amount: parseFloat(data.discount_amount) || 0,
        is_active: data.is_active,
        is_recurrent: data.is_recurrent,
        recurrence: data.recurrence,
        value: data.value,
        price: data.price,
      }
      this.$emit('update-sync-list', this.rows)
    }
  }
})
</script>
<style
    scoped
    lang="scss"
>
.table td {
  border: none !important;
}
th {
  border: none !important;
}
.w-min {
  width: min-content;
}
</style>