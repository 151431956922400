<template>
  <modal-component
    :backdrop="'static'"
    :show="showModal"
    :center="true"
    class="category-modal"
  >
    <template #content>
      <div class="modal-header">
        <h5 class="modal-title fs-4">
          {{ categoryModal.title }}
        </h5>
        <button
          type="button"
          class="float-btn bg-transparent border-0 d-flex justify-content-center align-items-center lh-1"
          aria-label="Close"
          @click="handleClose"
        >
          <i class="fa-regular fa-xmark" />
        </button>
      </div>
      <div class="modal-body">
        <category-form
          v-if="categoryModal.type === 'category'"
          @submit-form="submitForm"
        />
        <product-form
          v-else
          @submit-form="submitForm"
        />
      </div>
      <div
        style="gap: 1rem"
        class="modal-footer flex-column p-4 pt-0"
      >
        <app-button
          style="width: 328px"
          type="submit"
          @click.prevent="submitForm"
        >
          {{ categoryModal.button }}
        </app-button>
        <button
          type="button"
          class="btn btn-transparent bg-transparent"
          @click="handleClose"
        >
          {{ $t('cancel_general') }}
        </button>
      </div>
    </template>
  </modal-component>
</template>

<script>
import ModalComponent from "@components/modal-component.vue";
import {mapActions, mapGetters} from "vuex";
import AppButton from "@components/app-button.vue";
import CategoryForm from "@dialer/manager/pages/settings/products-and-services/components/category-form.vue";
import ProductForm from "@dialer/manager/pages/settings/products-and-services/components/product-form.vue";

export default {
  name: 'CategoryModalComponent',
  components: {ProductForm, CategoryForm, AppButton, ModalComponent},

  data() {
    return {
      categoryData: {
        name: "",
        team_ids: [],
        is_active: true
      }
    }
  },

  methods: {
    ...mapActions("manager/products_and_services", [
      "toggleModal",
      "createCategory",
      "createProduct",
      "updateCategory",
      "updateProduct",
      "fetchCategories",
      "handleCategoryData",
      "handleProductData",
      "updateExclude",
      "toggleEdit"
    ]),
    ...mapActions("crm/tour", [
      "updateTourSteps"
    ]),


    handleExclude() {
      this.toggleModal(false)
      this.updateExclude(true);
    },

    handleClose() {
      this.toggleEdit(false)
      this.toggleModal(false)
      this.handleCategoryData({
        name: "",
        team_ids: [],
        is_active: true
      })
    },

    submitForm() {
      const action = this.categoryModal?.actionName
      const payload = this[this.categoryModal.dataState]
      this[action](payload).then(() => {
        if(this.categoryModal?.actionName === "createProduct" && !this.tourSteps?.add_products) {
          this.updateTourSteps({
            add_products: true,
          })
        }
        const action = this.categoryModal?.handleName
        const payload = this.categoryModal?.data

        this[action](payload)
        this.toggleEdit(false)
        this.fetchCategories(1)
        this.fetchCategories(0)
        this.toggleModal(false)
      })
    },
  },

  computed: {
    ...mapGetters("manager/products_and_services", {
      showModal: "getShowModal",
      categoryDataState: "getCategoryData",
      productDataState: "getProductData",
      edit: "getEdit",
      categoryModal: "getCategoryModal"
    }),
    ...mapGetters("crm/tour", {
      tourSteps: "getTourSteps"
    }),
  },
}
</script>

<style>
.category-modal {
  .modal-content {
    box-shadow: 0 4px 18px 0 rgba(34, 54, 77, 0.12);
  }

  .modal-header {
    border-bottom-color: #E1E9F4;
  }

  .modal-body {
    padding: 2.5rem;
  }

  .float-btn {
    width: 24px;
    height: 24px;
    color: #677C92;
    padding: 4px 6px;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

    &:hover,
    &:focus-visible,
    &:focus {
      color: #373753;
    }
  }

  .modal-footer {
    border-top-width: 0;

    > * {
      margin: 0;
    }
  }
}

.btn-transparent {
  width: 237px;
  color: #677C92;

  &:hover,
  &:focus,
  &:focus-visible {
    color: #373753;
  }
}

.dropdown-menu {
  border-radius: 0.75rem;
  box-shadow: 0 4px 18px 0 rgba(34, 54, 77, 0.12);
  border-color: #E1E9F4;
}


.exclude-btn {
  color: #F23F2C;
  padding: 1rem;
  gap: 0.5rem;

  &:hover,
  &:focus,
  &:focus-visible {
    outline: none;
    color: #D1220F;
  }
}

@media only screen and (min-width: 576px) {
  .category-modal {
    .modal-dialog {
      max-width: 647px;
    }
  }
}
</style>