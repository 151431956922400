<template>
  <div class="main-content">
    <div class="container-fluid">
      <header-component
        pretitle="OMNICHANNEL 3C+"
        :title="$t('chats_reports')"
        redirect-link="/manager/reports"
      />
      <menu-search @searched="searched = true" />
      <table-before-search v-if="!searched && !chatsReportList.length" />
      <table-chats-report
        v-else
        @open-offcanvas="openOffCanvas"
      />
    </div>
    <offcanvas-chats-report
      :data="offCanvasData"
      ref="chatsOffCanvas"
    />
  </div>
</template>

<script>
import HeaderComponent from "@/components/header-component.vue";
import TableBeforeSearch from "../components/table-before-search.vue";
import MenuSearch from "./components/menu-search.vue";
import TableChatsReport from "./components/table-chats-report.vue";
import OffcanvasChatsReport from "./components/offcanvas-chats-report.vue";

import { mapGetters } from 'vuex';

export default {
  name: "ChatsReport",

  components: {
    OffcanvasChatsReport,
    TableChatsReport,
    MenuSearch,
    TableBeforeSearch,
    HeaderComponent,
  },

  data() {
    return {
      searched: false,
      offCanvasData: null,
    }
  },

  computed: {
    ...mapGetters("manager/chats_report", {
      loadingFlags: "getLoadingFlags",
      chatsReportList: "getChatsReportList",
    }),
  },

  methods: {
    openOffCanvas(data) {
      this.offCanvasData = data;
      this.$refs.chatsOffCanvas.open()
    },
  },
}
</script>
