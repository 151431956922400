<template>
  <div class="col-12 col-xl-6">
    <doughnut-chart
      :title="$tc('capitalizes.qualifications', 0)"
      :chart-data="doughnutChartData"
      :label-on-side="true"
      :labels="chartLabels"
      :colors="chartColors"
    />
  </div>
</template>

<script>
import doughnutChart from "@dialer/manager/components/doughnut-chart";
import { mapActions, mapGetters } from "vuex";

export default {
  data() {
    return {
      doughnutChartData: [],
      chartLabels: [],
      chartColors: [],
    }
  },
  components: {
    doughnutChart,
  },
  computed: {
    ...mapGetters("manager/receptive_queues", {
      qualificationsMetrics: "getQualificationsMetrics",
    }),
  },
  methods: {
    ...mapActions("manager/receptive_queues", ["findQualificationsMetrics", "clearQualificationsMetrics"]),
    fillChartData() {
      this.doughnutChartData = [];
      this.chartLabels = [];
      this.chartColors = [];

      if (this.qualificationsMetrics[0].qualifications.length) {
        this.qualificationsMetrics[0].qualifications.forEach(element => {
          this.doughnutChartData.push(element.count);
          this.chartLabels.push(element.name);
          this.chartColors.push(element.color);
        });
      }
    },
  },
  mounted() {
    this.findQualificationsMetrics({ id: this.$router.currentRoute._rawValue.params.id });
  },
  watch: {
    qualificationsMetrics(data) {
      if (data)
        this.fillChartData();
    },
  },
  beforeUnmount() {
    this.clearQualificationsMetrics();
  },
}
</script>
