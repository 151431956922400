<template>
  <div class="dropdown">
    <button
      class="btn d-flex align-items-center btn-sm dropdown-toggle gap-2"
      style="background-color: #F0F4FA"
      type="button"
      data-bs-toggle="dropdown"
      data-bs-auto-close="outside"
      aria-expanded="false"
    >
      <div
        class="count rounded-circle bg-blue-300 tx-text-light"
        v-if="selectedItem"
      /> {{ $t(title) }}
    </button>
    <ul
      class="dropdown-menu"
      @scroll="checkScroll"
    >
      <li
        v-if="enableSearch"
        class="dropdown-item"
      >
        <div class="input-group input-group-flush input-group-merge">
          <div class="input-group-text p-0 me-1">
            <span class="fe fe-search" />
          </div>
          <input
            type="search"
            class="form-control list-search p-0"
            placeholder="Pesquisar"
            v-model="search"
          >
        </div>
      </li>
      <li
        v-if="enableAll"
        class="dropdown-item d-flex align-items-center gap-2"
      >
        <input
          class="form-check-input"
          type="radio"
          id="clean"
          v-model="selectedItem"
          value=""
          @input="$emit('radioSelected', null)"
        >
        <label
          class="form-check-label"
          for="clean"
        >{{ $t('all_dropdown') }}</label>
      </li>
      <li
        v-for="item in filteredList"
        :key="item.id"
        class="dropdown-item d-flex align-items-center gap-2"
      >
        <input
          class="form-check-input"
          type="radio"
          :id="`${title}-${item.id}`"
          v-model="selectedItem"
          :value="item.id"
          @input="$emit('radioSelected', item.id)"
        >
        <label
          class="form-check-label"
          :for="`${title}-${item.id}`"
        >{{ item.name }}</label>
      </li>
      <li
        v-if="loading"
        class="dropdown-item d-flex justify-content-center"
      >
        <div class="spinner-border spinner-border-sm" />
      </li>
    </ul>
  </div>
</template>

<script>

export default {
  emits: ["radioSelected", "filterScroll"],

  props: {
    title:{ type: String, default: "" },
    list:{ type: Array, required: true },
    enableSearch:{ type: Boolean, default: false },
    initialSelectedId:{ type: String, default: "" },
    loading:{ type: Boolean, default: false },
    enableAll: { type: Boolean, default: true }
  },

  data(){
    return{
      selectedItem: "",
      search: "",
    }
  },

  created(){
    this.selectedItem = this.initialSelectedId
  },

  computed:{
    filteredList(){
      const string = this.search.toLowerCase();
      return this.list.filter(
        item => item.name.toLowerCase().includes(string)
      )
    }
  },

  methods:{
    checkScroll(event) {
      const scrollContainer = event.target;
      const scrollHeight = scrollContainer.scrollHeight;
      const scrollTop = scrollContainer.scrollTop;

      if (scrollHeight - scrollTop - scrollContainer.clientHeight < 1) {
        this.$emit("filterScroll")
      }
    },
  }
}
</script>

<style scoped lang="scss">
.dropdown-menu{
  max-height: 620px;
  overflow-y: scroll;
}

button.show {
  background-color: $color-blue-300 !important;
  color: $color-text-light !important;

  .count{
    background-color: #F0F4FA !important;
    color: $color-text-dark !important;
  }
}

.count{
  width: 10px;
  height: 10px;
}
</style>
