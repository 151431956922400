<template>
  <div
    class="offcanvas-body d-flex flex-column"
    style="gap: 1rem"
  >
    <action-component
      icon-bg="#D6E9D9"
      icon-class="fa-regular fa-text"
      icon-color="#015D0D"
      :name="$t('send_a_message')"
    />

    <div class="col-auto">
      <label
        class="form-label"
        for="message"
      >{{ $t('message_text') }}</label>
      <input
        id="message"
        v-model="textContent"
        class="form-control"
        :placeholder="$t('what_is_your_message')"
        type="text"
      >
    </div>
    <div class="mt-auto" />
    <app-button
      :disabled="!textContent"
      @click="createMessage"
    >
      {{ $t('create') }}
    </app-button>
    <app-button
      color="btn-outline-secondary border-0"
      @click="$emit('return')"
    >
      {{ $t('back_capitalize') }}
    </app-button>
  </div>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from "vuex";

import ActionComponent from "@chatbot/pages/workflow/components/action-component.vue";
import AppButton from "@components/app-button.vue";

export default {
  name: "CreateMessage",

  emits: ["return", "close"],

  components: {
    AppButton,
    ActionComponent
  },

  data() {
    return {
      textContent: ""
    }
  },

  computed: {
    ...mapGetters("chatbot/actions", {
      prevActionId: "getPrevActionId"
    })
  },

  methods: {
    ...mapActions("chatbot/actions", ["createAction"]),
    ...mapMutations("auth", ["trackEvents"]),

    createMessage() {
      let payload = {
        type: "question",
        need_chat_response: false,
        text_content: this.textContent,
        previous_action_id: this.prevActionId,
        chatbot_id: this.$route.params.id
      }
      this.createAction(payload).then(() => {
        this.$emit("close")
        this.trackEvents("[Manager] Create Chatbot Message")
      })
    }
  }
}
</script>
