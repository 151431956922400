<template>
  <div class="dropdown">
    <button
      ref="dropdown"
      aria-expanded="false"
      :disabled="loading"
      data-test="doneChat-btn"
      class="btn shadow-none button-top-bar ms-0 d-flex justify-content-center align-items-center"
      @click="handleDropdownClick"
    >
      <span
        v-if="opened"
        class="finalize-button"
      >
        {{ $t('cancel_general') }}
      </span>
      <span
        v-else
        class="d-flex justify-content-center align-items-center"
        style="width: 84px;"
      >
        <span class="fe fe-check-circle pe-2" />
        <span class="h6 m-0 pt-1">{{ $t('finalize') }}</span>
      </span>
    </button>
    <div class="dropdown-menu">
      <form
        class="row"
        @submit.prevent="submit"
      >
        <div class="col-xs-12">
          <div
            class="form-group"
            style="margin-bottom: 16px;"
          >
            <label class="form-label h5">{{ $t('qualifications_omni') }}</label>
            <select
              class="form-select"
              data-test="qualifications-select"
              :disabled="loadingFlags.findQualifications"
              v-model="fields.qualification"
            >
              <option
                :value="undefined"
              >
                {{ $t('select') }}
              </option>
              <option
                :value="qualification.id"
                v-for="(qualification, index) of qualifications"
                :key="index"
              >
                {{ qualification.name }}
              </option>
            </select>
          </div>
        </div>
        <div class="col-xs-12">
          <div
            class="form-group"
            style="margin-bottom: 16px;"
          >
            <label class="form-label h5">{{ $t('internal_note') }}</label>
            <textarea
              v-model="fields.qualification_note"
              class="form-control fw-light h6 m-0 px-3"
              data-test="internalNote-textarea"
              :placeholder="$t('write_here')"
              rows="6"
              style="resize: none;"
            />
          </div>
        </div>
        <div class="col-xs-12">
          <div class="form-group mb-0">
            <button
              style="height: 34px;"
              type="submit"
              :disabled="loadingFlagsChat.finishChat || loadingFlags.findQualifications"
              class="btn w-100 btn-primary shadown-none tx-text-light bg-blue-300 d-flex justify-content-center align-items-center p-0"
              data-test="submitFinishChat-button"
            >
              <span
                class="fe fe-check-circle pe-2 h3 m-0"
              />
              <span>{{ $t('finalize') }}</span>
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from "vuex";
import {Dropdown} from "bootstrap";

export default {
  data() {
    return {
      dropdown: null,
      opened: false,
      fields: {
        qualification: undefined,
        qualification_note: "",
      },
    };
  },

  props: {
    loading: {
      type: Boolean,
      default: false
    }
  },

  mounted() {
    this.dropdown = new Dropdown(this.$refs.dropdown);
  },

  computed: {
    ...mapGetters("whatsapp/instance", {
      loadingFlags: "getLoadingFlags",
      qualifications: "getQualifications"
    }),

    ...mapGetters("whatsapp/chat", {
      loadingFlagsChat: "getLoadingFlags",
    }),

  },

  methods: {
    ...mapActions("whatsapp/chat", ["finishChat", "findFinished", "resetFinishedCurrentPage"]),
    ...mapMutations("whatsapp/chat", ["removeChatById"]),
    ...mapMutations("system", ["setSuccessMessage", "setCustomizedTitleMessage"]),
    ...mapMutations("auth", ["trackEvents"]),

    handleDropdownClick() {
      this.dropdown.toggle();
      this.opened = !this.opened;
    },

    submit() {
      this.finishChat({
        id: this.$route.params.id,
        fields: this.fields,
      }).then(() => {
        this.trackEvents("[Agent] Finish Chat");
        this.removeChatById({ id: this.$route.params.id });
        this.resetFinishedCurrentPage();
        this.findFinished();
        if (this.fields.qualification === undefined) {
          this.setSuccessMessage(this.$t('no_qualification'));
          this.setCustomizedTitleMessage(" ");
        } else {
          this.setSuccessMessage(this.$t('success_finished_chat'));
          this.setCustomizedTitleMessage(this.$t('success'));
        }
        this.$router.push("/whatsapp")
      });
    },
  }
};
</script>

<style scoped lang="scss">
.button-top-bar {
  padding: $whatsapp-button-padding 0.807rem;
  height: $whatsapp-button-size !important;
  color: $color-text-gray !important;
  background-color: #e1e9f4;
  &:hover:not(.no-hover), .whatsapp-calling {
    background-color: $color-blue-300 !important;
    color: #e3ebf6 !important;
  }
}

.finalize-button {
  font-size: 14px;
  width: 84px;
  margin-top: 2px;
}
.dropdown-menu {
  width: 274px;
  max-height: 403px;
  border-radius: $whatsapp-border-radius;
  box-shadow: 0px 12px 24px 0px rgba(18, 38, 63, 0.03);
  padding: $whatsapp-spacing-3;

  .disabled {
    opacity: 0.6 !important;
  }

  select , textarea::placeholder {
    font-size: 14px;
    font-weight: 400;
    color: $color-text-200;
  }
}
</style>
