<template>
  <div
    id="accordionInsights"
    class="accordion border-0 pb-5"
  >
    <div class="accordion-item rounded">
      <div class="accordion-header">
        <div
          :class="{ 'border-bottom ': invertIcon }"
          class="btn w-100  bg-white rounded d-flex align-items-center justify-content-between"
          style="height: 56px !important;"
        >
          <div
            class="w-75"
            aria-controls="collapseOne"
            aria-expanded="true"
            data-bs-target="#collapseOne"
            data-bs-toggle="collapse"
            @click="invertIcon = !invertIcon"
          >
            <badge-icon
              icon="far fa-comment-alt-edit tx-blue-300"
              title="Avaliação"
            />
          </div>
          <div class="d-flex align-items-center">
            <ul
              class="nav nav-tabs p-0 m-0 list-unstyled"
              style="height: 56px;"
              id="myTab"
              role="tablist"
            >
              <li
                class="nav-item h-100 p-0"
                role="presentation"
              >
                <button
                  class="btn rounded-0 p-0 text-small"
                  :class="[activeTab === 'criterion' ? 'fw-medium nav-link active tx-text-200' : 'tx-text-gray']"
                  style="height: 56px;"
                  id="criterion-pane"
                  data-bs-toggle="pane"
                  data-bs-target="#criterion-pane"
                  role="pane"
                  aria-controls="criterion-pane"
                  aria-selected="criterion"
                  @click="activeTab = 'criterion'"
                >
                  {{ $t('uppercases.criteria') }}
                </button>
              </li>
              <li
                class="nav-item  h-100 p-0"
                role="presentation"
              >
                <button
                  class="btn rounded-0 p-0 text-small"
                  :class="[activeTab === 'general' ? 'fw-medium nav-link active tx-text-200' : 'tx-text-gray']"
                  style="height: 56px;"
                  id="general-pane"
                  data-bs-toggle="pane"
                  data-bs-target="#general-pane"
                  role="pane"
                  aria-controls="general-pane"
                  aria-selected="general"
                  @click="activeTab = 'general'"
                >
                  {{ $t('uppercases.general') }}
                </button>
              </li>
            </ul>
            <span
              class="ms-4"
              aria-controls="collapseOne"
              aria-expanded="true"
              data-bs-target="#collapseOne"
              data-bs-toggle="collapse"
              @click="invertIcon = !invertIcon"
            >
              <i :class="[invertIcon ? 'fal fa-chevron-up' : 'fal fa-chevron-down']" />
            </span>
          </div>
        </div>
      </div>
      <div
        id="collapseOne"
        class="accordion-collapse collapse show"
        data-bs-parent="#accordionInsights"
      >
        <div class="accordion-body bg-gray-blue-100 rounded-bottom">
          <div class="tab-content">
            <div
              class="tab-pane mt-0 fade overflow-auto"
              :class="{ 'show active': activeTab === 'criterion' }"
              id="criterion-pane"
              role="tabpanel"
              aria-labelledby="criterion-tab"
              tabindex="0"
              style="height: 207px;margin-top: 50px;"
            >
              <div
                v-for="criteria in avaliation[0]"
                :key="criteria.id"
                style="margin-bottom: 16px;"
              >
                <div
                  class="d-flex align-items-center"
                  style="margin-bottom: 8px;"
                >
                  <p class="mb-0 tx-text-200 fw-medium me-2">
                    {{ criteria.name }}:
                  </p>
                  <badge-icon
                    :bg-color="metricsNoteColor(criteria.score)"
                    font-size="text-small"
                    height="20px"
                    radius="12px"
                    :text="scoreFixed(criteria.score)"
                    width="34px"
                  />
                  <i
                    v-if="criteria.score < 3"
                    class="fa-regular fa-triangle-exclamation tx-yellow-400 ms-3"
                  />
                </div>
                <p class="mb-0 tx-text-gray">
                  {{ criteria.comment }}
                </p>
              </div>
            </div>
            <div
              class="tab-pane mt-0 fade overflow-auto"
              id="general-pane"
              role="tabpanel"
              :class="{ 'show active': activeTab === 'general' }"
              aria-labelledby="general-tab"
              tabindex="0"
              style="height: 207px;margin-top: 50px;"
            >
              <p class="mb-0 tx-text-gray">
                {{ avaliation[1] }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      @click="invertIcon = false"
      class="accordion-item rounded border mt-4"
    >
      <h2 class="accordion-header">
        <button
          aria-controls="collapseTwo"
          aria-expanded="false"
          class="accordion-button collapsed bg-white rounded"
          data-bs-target="#collapseTwo"
          data-bs-toggle="collapse"
          style="height: 56px !important;"
          type="button"
        >
          <badge-icon
            icon="far fa-file-audio tx-blue-300"
            title="Transcrição do áudio"
          />
        </button>
      </h2>
      <div
        id="collapseTwo"
        class="accordion-collapse collapse"
        data-bs-parent="#accordionInsights"
      >
        <div
          class="accordion-body bg-gray-blue-100 rounded-bottom overflow-auto"
          style="max-height: 800px"
        >
          <div
            v-for="call in callHistory.transcription"
            :key="call.time"
          >
            <p class="mb-0 tx-text-gray">
              <span class="fw-medium">{{ fomatRole(call.role) }}</span>: {{ call.content }}
            </p>
          </div>
          <div class="d-flex justify-content-center mt-5">
            <app-button
              class="justify-content-center"
              icon="copy me-2"
              @click="copyTranscription"
              style="min-width: 331px; height: 40px;"
            >
              {{ $t('copy_transcription') }}
            </app-button>
          </div>
        </div>
      </div>
    </div>
    <div
      @click="invertIcon = false"
      class="accordion-item rounded border mt-4"
    >
      <h2 class="accordion-header">
        <button
          aria-controls="collapseThree"
          aria-expanded="false"
          class="accordion-button collapsed bg-white rounded"
          data-bs-target="#collapseThree"
          data-bs-toggle="collapse"
          style="height: 56px !important;"
          type="button"
        >
          <badge-icon
            icon="far fa-list-ul tx-blue-300"
            :title="$t('list_data')"
          />
        </button>
      </h2>
      <div
        id="collapseThree"
        class="accordion-collapse collapse"
        data-bs-parent="#accordionInsights"
      >
        <div class="accordion-body bg-gray-blue-200 rounded-bottom">
          <ul class="p-0 m-0 row">
            <li class="col-12 col-lg-3">
              <div>
                <p
                  class="mb-0 tx-text-gray fs-6"
                  style="margin-bottom: 8px !important;"
                >
                  {{ $t('identifier') }}
                </p>
                <p class="mb-0 tx-text-200 fw-medium">
                  {{ callHistory.mailing_data.identifier }}
                </p>
              </div>
            </li>
            <li
              v-for="(item, key, index) in callHistory.mailing_data.data"
              :key="key"
              class="col-12 col-lg-3"
            >
              <div :class="{ 'mt-4': index > 2 }">
                <p
                  class="mb-0 tx-text-gray fs-6"
                  style="margin-bottom: 8px !important;"
                >
                  {{ key }}
                </p>
                <p class="mb-0 tx-text-200 fw-medium">
                  {{ item }}
                </p>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div
      @click="invertIcon = false"
      class="accordion-item rounded border mt-4"
    >
      <h2 class="accordion-header">
        <button
          aria-controls="collapseFour"
          aria-expanded="false"
          class="accordion-button collapsed bg-white rounded"
          data-bs-target="#collapseFour"
          data-bs-toggle="collapse"
          style="height: 56px !important;"
          type="button"
        >
          <badge-icon
            icon="far fa-phone tx-blue-300"
            title="Dados da ligação"
          />
        </button>
      </h2>
      <div
        id="collapseFour"
        class="accordion-collapse collapse"
        data-bs-parent="#accordionInsights"
      >
        <div class="accordion-body bg-gray-blue-200 rounded-bottom">
          <ul class="p-0 m-0 row">
            <li
              v-for="data in dataAgent"
              :key="data.title"
              class="col-12"
              :class="{ 'col-lg-3 m-0': data.title !== $t('amd_record') }"
            >
              <div class="mt-4">
                <p
                  class="fs-6 tx-text-gray"
                  style="margin-bottom: 8px !important;"
                >
                  {{ data.title }}
                </p>
                <p
                  class="mb-0 fw-medium tx-text-200"
                  :class="{ 'd-none': data.title === $t('amd_record') }"
                >
                  {{ data.content || "-" }}
                </p>
              </div>
              <div
                v-if="data.title === $t('amd_record')"
                class="w-100"
                style="margin-top: 16px;"
              >
                <audio
                  class="w-100"
                  style="height:40px;"
                  controls
                  :src="callHistory.recording"
                  type="audio/mpeg"
                />
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BadgeIcon from "@components/badge-icon.vue";
import { Utils } from "@/utils";
import AppButton from "@components/app-button.vue";

export default {
  name: "AccordionIA",
  components: { AppButton, BadgeIcon },
  props: {
    callHistory: {
      type: Object,
      required: true
    },
    avaliation: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      invertIcon: true,
      activeTab: "criterion"
    };
  },
  computed: {
    dataAgent() {
      return [
        {
          title: this.$t('campaign_capitalize'),
          content: this.callHistory.campaign
        },
        {
          title: this.$t('post_call_detection'),
          content: this.callHistory.amd_time
        },
        {
          title: this.$t('waiting_time'),
          content: this.callHistory.waiting_time
        },
        {
          title: this.$t('conversation_time'),
          content: this.callHistory.speeking_time
        },
        {
          title: this.$t('post_service_time'),
          content: this.callHistory.acw_time
        },
        {
          title: this.$t('type_general'),
          content: this.callHistory.phone_type === "mobile" ? this.$t('mobile') : this.$t('landline')
        },
        {
          title: "Status",
          content: this.callHistory.readable_status_text
        },
        {
          title: this.$t('billed_time'),
          content: this.callHistory.billed_time
        },
        {
          title: this.$t('value'),
          content: this.formatCurrency(this.callHistory.billed_value === "R$ 0,000" ? 0 : this.callHistory.billed_value)
        },
        {
          title: this.$t('qualification'),
          content: this.callHistory.qualification
        },
        {
          title: this.$t('post_call_detection'),
          content: this.callHistory.readable_amd_status_text
        },
        {
          title: this.$t('ending_call_cause'),
          content: this.callHistory.readable_hangup_cause_text
        },
        {
          title: this.$t('call_mode_capitalize'),
          content: this.callMode(this.callHistory.mode)
        },
        {
          title: this.$t('date_capitalize'),
          content: this.callHistory.call_date
        },
        {
          title: this.$t('amd_record')
        },
        {
          title: this.$t('call_id'),
          content: this.callHistory.id
        },
        {
          title: this.$t('mailing_list'),
          content: this.callHistory.list
        },
        {
          title: this.$t('receptive_queue_capitalize'),
          content: this.callHistory.queue_name
        },
        {
          title: this.$t('call_date_rfc_format'),
          content: this.callHistory.call_date_rfc3339
        },
        {
          title: this.$t('ivr_name'),
          content: this.callHistory.ivr_name
        },
        {
          title: this.$t('receptive_name'),
          content: this.callHistory.receptive_name
        },
        {
          title: this.$t('receptive_number'),
          content: this.callHistory.receptive_phone
        },
        {
          title: this.$t('ivr_time'),
          content: this.callHistory.ivr_time
        },
        {
          title: this.$t('amd_time'),
          content: this.callHistory.amd_time
        },
        {
          title: this.$t('talking_to_agent_time'),
          content: this.callHistory.speeking_with_agent_time
        }
      ];
    }
  },
  methods: {
    fomatRole(role) {
      if (role === "assistant") {
        return this.$t('agent_capitalize');
      }
      return this.$t('client');
    },
    scoreFixed(value) {
      return value.toFixed(1);
    },
    metricsNoteColor(value) {
      if (value >= 6) {
        return "bg-green-400";
      } else if (value >= 3 && value < 6) {
        return "bg-yellow-400";
      }
      return "bg-danger";
    },
    callMode(call) {
      switch (call) {
        case "dialer":
          return this.$t('dialer_capitalize');
        case "receptive":
          return this.$t('receptive_capitalize');
        case "manual":
          return this.$t('capitalizes.manual');
        default:
          return this.$t('consult');
      }
    },
    copyTranscription() {
      let transcription = "";
      this.callHistory.transcription.forEach(call => {
        transcription += `${this.fomatRole(call.role)}: ${call.content}\n`;
      });
      navigator.clipboard.writeText(transcription);
    },
    formatCurrency: Utils.formatCurrency
  }
};
</script>

<style lang="scss" scoped>
.accordion-button:not(.collapsed) {
  box-shadow: none !important;
  border-bottom: 1px solid $color-gray-blue-300;
}
.accordion-button {
  border-color: $color-gray-blue-300;
}
.bg-gray-blue-100 {
  background-color: $color-gray-blue-100;
}
.bg-gray-blue-200 {
  background-color: $color-gray-blue-200;
}

.bg-green-400 {
  background-color: $color-green-400;
}
.bg-yellow-400 {
  background-color: $color-yellow-400;
}
.bg-red-400 {
  background-color: #f23f2c;
}
ul {
  list-style-type: none;
}
audio::-webkit-media-controls-panel {
  background-color: $color-text-light;
  border-radius: 16px;
}
</style>
