<template>
  <div class="col-12 mb-4">
    <div
      class="card h-100 m-0"
    >
      <h4 class="mb-2 mt-1 card-header">
        {{ $t('distribution_mode') }}
      </h4>
      <div class="card-body d-flex flex-row gap-2">
        <div class="col-4 d-flex">
          <div class="d-flex align-items-start me-5 pt-2">
            <input
              id="default"
              v-model="value"
              :checked="modelValue === 'DEFAULT'"
              class="form-check-input col-auto me-3 mt-3"
              type="radio"
              value="DEFAULT"
              @input="emitValue"
            >
            <label
              class="form-check-label col ms-2"
              for="default"
            >
              <h5 style="font-weight: 500;">{{ $t('standard') }}</h5>
              <p
                class="text-muted h6 fw-normal"
              >{{ $t('standard_description') }}</p>
            </label>
          </div>
          <div
            class="vr me-4"
            style="background-color: #E1E9F4;"
          />
        </div>
        <div class="col-4 d-flex">
          <div class="d-flex align-items-start me-5 pt-2">
            <input
              id="balanced"
              v-model="value"
              :checked="modelValue === 'BALANCED'"
              class="form-check-input col-auto me-3 mt-3"
              type="radio"
              value="BALANCED"
              @input="emitValue"
            >
            <label
              class="form-check-label col ms-2"
              for="balanced"
            >
              <h5 style="font-weight: 500;">{{ $t('balanced') }}</h5>
              <p
                class="text-muted h6 fw-normal"
              >{{ $t('balanced_description') }}</p>
            </label>
          </div>
          <div
            class="vr me-4"
            style="background-color: #E1E9F4;"
          />
        </div>
        <div class="col-4">
          <div
            class="d-flex align-items-start me-5 pt-2"
          >
            <input
              id="sequential"
              v-model="value"
              :checked="modelValue === 'SEQUENTIAL'"
              class="form-check-input col-auto me-3 mt-3"
              type="radio"
              value="SEQUENTIAL"
              @input="emitValue"
            >
            <label
              class="form-check-label col ms-2"
              for="sequential"
            >
              <h5 style="font-weight: 500;">{{ $t('sequential') }}</h5>
              <p
                class="text-muted h6 fw-normal"
              >{{ $t('sequential_description') }}</p>
            </label>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  emits: ['update:modelValue'],
  name: "DistributionStrategyForm",
  props: {
    modelValue: {type: String, default: 'DEFAULT'},
  },
  data () {
    return {
      value: 'DEFAULT'
    }
  },
  methods: {
    emitValue (e) {
      let value = e.target.value
      this.$emit('update:modelValue', value)
    }
  }
}
</script>
<style lang="scss" scoped>
</style>
