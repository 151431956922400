import axios from "axios";

export default {
  fetchTeams ({commit}) {
    commit("setTeams", []);

    return new Promise((resolve, reject) => {
      axios
        .get("/teams")
        .then(response => {
          commit("setTeams", response.data.data);
          resolve();
        })
        .catch(error => {
          commit("setErrorMessage", error.response.data, {root: true});
          reject();
        })
    });
  },

  list ({commit}, payload) {
    commit("clearErrors");
    commit("startLoading");
    commit("setList", []);

    return new Promise((resolve, reject) => {
      axios
        .get("/teams", {params: payload})
        .then(response => {
          commit("setPagination", response.data.meta.pagination);
          commit("setList", response.data.data);
          resolve();
        })
        .catch(response => {
          response.response.status === 422 &&
          response.response.data.errors &&
          commit("setErrors", response.response.data.errors);
          reject({message: response.response.data.detail});
        })
        .finally(() => {
          commit("stopLoading");
        });
    });
  },

  fetchTeamPaginate ({commit}, payload) {
    return new Promise((resolve, reject) => {
      commit("clearErrors");
      commit("startLoading");
      axios
        .get("/teams", {params: payload})
        .then(response => {
          commit("setPagination", response.data.meta.pagination);
          commit("setTeamPaginateList", response.data.data);
          resolve();
        })
        .catch(response => {
          response.response.status === 422 &&
          response.response.data.errors &&
          commit("setErrors", response.response.data.errors);
          reject({message: response.response.data.detail});
        })
      // .finally(() => {
      // });
      commit("stopLoading");
    });
  },

  createTeam({ commit }, payload) {
    commit("clearErrors");
    commit("setTeam", null);
    commit("startLoadingFlag", "createTeam");

    return new Promise((resolve, reject) => {
      axios
        .post("/teams", payload.fields)
        .then(response => {
          commit('system/setSuccessMessage', "success_message.create_team", {root: true});
          commit("setTeam", response.data.data);
          commit("auth/trackEvents", `[Manager] Create team`, {root: true});
          resolve();
        })
        .catch(error => {
          commit("system/setErrorMessage", error.response.data, { root: true });
          error.response.status == 422 && error.response.data.errors && commit("setErrors", error.response.data.errors);
          reject();
        })
        .finally(() => {
          commit("stopLoadingFlag", "createTeam");
        });
    });
  },

  updateTeam ({commit}, payload) {
    commit("clearErrors");
    commit("startLoadingFlag", "updateTeam");

    return new Promise((resolve, reject) => {
      axios
        .put("/teams/" + payload.id, payload.fields)
        .then(response => {
          commit("setTeam", response.data.data);
          commit("auth/trackEvents", `[Manager] Edit team`, {root: true})
          commit("system/setSuccessMessage", "success_message.update_team", {root: true})
          resolve();
        })
        .catch(error => {
          commit("system/setErrorMessage", error.response.data, { root: true });
          commit("setErrors", error.response.data.errors || error.response.data);
          reject();
        })
        .finally(() => {
          commit("stopLoadingFlag", "updateTeam");
        });
    });
  },

  deleteTeam({ commit }, payload) {
    commit("clearErrors");
    commit("setTeam", null);
    commit("startLoadingFlag", "deleteTeam");

    return new Promise((resolve, reject) => {
      axios
        .delete("/teams/" + payload.id)
        .then(() => {
          commit("system/setSuccessMessage", "success_message.remove_team", {root: true})
          commit("auth/trackEvents", `[Manager] Delete team`, { root: true });
          resolve();
        })
        .catch(response => {
          commit("system/setErrorMessage", response.response.data, { root: true });
          reject();
        })
        .finally(() => {
          commit("stopLoadingFlag", "deleteTeam");
        });
    });
  },

  fetchTeamById({ commit }, payload) {
    commit("clearErrors");
    commit("setTeam", null);
    commit("startLoadingFlag", "fetchTeamById");

    return new Promise((resolve, reject) => {
      axios
        .get("/teams/" + payload.id + "?include=campaigns")
        .then(response => {
          commit("setTeam", response.data.data);
          resolve();
        })
        .catch(error => {
          commit("setTeam", null);
          commit("system/setErrorMessage", error.response.data, { root: true });
          error.response.status == 422 &&
          error.response.data.errors &&
            commit("setErrors", error.response.data.errors);
          reject();
        })
        .finally(() => {
          commit("stopLoadingFlag", "fetchTeamById");
        });
    });
  }
};
