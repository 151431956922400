<template>
  <modal-component
    v-show="showModal"
    :center="true"
    :show="isOpen"
    @close="resetAndClose"
  >
    <template #content>
      <div class="modal-header">
        <div class="d-flex align-items-center gap-2">
          <span class="fa-regular fa-robot tx-blue-300 fs-6" />
          <h2 class="modal-title fs-4">
            {{ $t('chatbot_config') }}
          </h2>
        </div>
        <button
          aria-label="Close"
          class="btn-close m-0 px-0"
          type="button"
          @click="$emit('close')"
        />
      </div>
      <div class="modal-body">
        <form>
          <div style="margin-bottom: 1rem">
            <label
              class="form-label"
              for="name"
            >{{ $t('bot_name') }}</label>
            <input
              id="name"
              v-model="settings.name"
              :class="{'is-invalid': settings.name == ''}"
              class="form-control"
              type="text"
            >
            <div class="invalid-feedback">
              {{ $t('fill_chatbot_name') }}
            </div>
          </div>
          <div style="margin-bottom: 1rem">
            <label
              class="form-label"
              for="initialTrigger"
            >{{ $t('start_trigger') }}</label>
            <select
              id="initialTrigger"
              v-model="settings.initial_trigger"
              class="form-select"
            >
              <option value="received_message">
                {{ $t('whenever_a_message_is_sent_to_number') }}
              </option>
            </select>
          </div>
          <div style="margin-bottom: 1rem">
            <label
              class="form-label"
              for="defaultErrorMessage"
            >{{ $t('error_message') }}</label>
            <input
              id="defaultErrorMessage"
              v-model="settings.default_error_message"
              :class="{'is-invalid': settings.default_error_message == ''}"
              class="form-control"
              type="text"
            >
            <div class="invalid-feedback">
              {{ $t('fill_error_message') }}
            </div>
          </div>
          <hr class="card-divider my-4">
          <div
            class="d-flex justify-content-between align-items-center"
            style="margin-bottom: 1rem"
          >
            <p class="fw-medium m-0">
              {{ $t('chatbot_automatic_finalization') }}
            </p>
            <img
              src="@/assets/img/3czinhos.svg"
              style="height: 18px"
            >
          </div>
          <div class="row">
            <div class="col-6">
              <div class="position-relative">
                <label
                  class="form-label"
                  for="timeToEndChat"
                >{{ $t('time_to_finalize') }}</label>
                <input
                  id="timeToEndChat"
                  v-model="settings.time_to_end_chat"
                  :class="{'is-invalid': settings.time_to_end_chat == '0' || settings.time_to_end_chat == ''}"
                  class="form-control"
                >
                <span
                  class="position-absolute tx-text-gray text-small"
                  style="top: 44px; right: 12px"
                >
                  {{ $t('minutes_uppercase') }}
                </span>
              </div>
              <div class="invalid-feedback">
                {{ $t('value_bigger_than_0') }}
              </div>
            </div>
            <div class="col-6">
              <label
                class="form-label"
                for="endingTrigger"
              >{{ $t('action_after_finalize') }}</label>
              <select
                id="endingTrigger"
                v-model="settings.ending_trigger"
                class="form-select"
              >
                <option value="end_chat">
                  {{ $t('finalize_chat') }}
                </option>
              </select>
            </div>
          </div>

          <div
            class="d-flex flex-column align-items-center"
            style="margin-top: 40px; gap: 1rem"
          >
            <app-button
              :disabled="Object.values(settings).some(el => !el || el == '0')"
              class="w-100"
              @click.prevent="saveSettings"
            >
              {{ $t('capitalizes.save') }}
            </app-button>

            <app-button
              class="w-100"
              color="text-danger"
              @click.prevent="_deleteChatbot"
            >
              <span class="fa-regular fa-trash" />
              {{ $t('capitalizes.exclude') }}
            </app-button>
          </div>
        </form>
      </div>
    </template>
  </modal-component>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from "vuex";

import AppButton from "@components/app-button.vue";
import ModalComponent from "@components/modal-component.vue";

export default {
  name: "ModalSettings",

  emits: ['close'],

  components: {
    ModalComponent,
    AppButton
  },

  props: {
    isOpen: {
      type: Boolean,
      required: true,
    }
  },

  data() {
    return {
      settings: {
        name: "",
        initial_trigger: "",
        ending_trigger: "",
        time_to_end_chat: 0,
        default_error_message: "",
      },
      showModal: true
    }
  },

  watch: {
    chatbot(data) {
      this.settings = {
        name: data.name,
        initial_trigger: data.initial_trigger,
        ending_trigger: data.ending_trigger,
        time_to_end_chat: data.time_to_end_chat,
        default_error_message: data.default_error_message,
      }
    }
  },

  computed: {
    ...mapGetters("chatbot/chatbots", {
      chatbot: "getChatbot"
    })
  },

  methods: {
    ...mapActions("chatbot/chatbots", [
      "updateChatbotSettings",
      "deleteChatbot"
    ]),
    ...mapActions("system", ["showConfirmationMessage"]),
    ...mapMutations("auth", ["trackEvents"]),

    saveSettings() {
      this.updateChatbotSettings({
        chatbotId: this.$route.params.id,
        settings: this.settings
      }).then(() => {
        this.$emit('close')
        this.trackEvents("[Manager] Chatbot Settings Update")
      })
    },

    async _deleteChatbot() {
      this.toggleShowModal()

      let response = await this.showConfirmationMessage({
        title: this.$t('delete_confirmation'),
        text: this.$t('delete_chatbot_confirmation'),
        type: "badWarning"
      });


      if (response.isConfirmed) {
        this.deleteChatbot(this.$route.params.id).then(()=>{
          this.$router.push("/manager/chatbot")
          this.trackEvent("[Manager] Delete Chatbot")
        })
      } else {
        this.resetAndClose()
      }

    },

    toggleShowModal() {
      this.showModal = !this.showModal
    },

    resetAndClose() {
      this.settings = {
        name: this.chatbot.name,
        initial_trigger: this.chatbot.initial_trigger,
        ending_trigger: this.chatbot.ending_trigger,
        time_to_end_chat: this.chatbot.time_to_end_chat,
        default_error_message: this.chatbot.default_error_message,
      }

      this.$emit('close')
    }
  }
}
</script>
