<template>
  <div :style="{ marginBottom: diff > 0 ? diff + 'px' : '0', marginTop: diff < 0 ? -diff + 'px' : '0' }">
    <svg
      width="32"
      :height="viewBoxHeight"
      :viewBox="`0 0 32 ${viewBoxHeight}`"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        v-for="(height, i) in upHeights"
        :key="i"
        :d="`M0 ${center}V${center}C8.83656 ${center} 16 ${center - 7.163} 16 ${center - 16}V${center - (height/2)}V${center - height + 16}C16 ${center - height + 7.163} 23.1634 ${center - height} 32 ${center - height}V${center - height}`"
        stroke="#5517E6"
        stroke-width="2"
      />
      <path
        v-if="showCenterLine"
        :d="`M0 ${center}H32`"
        stroke="#5517E6"
        stroke-width="2"
      />
      <path
        v-for="(height, i) in downHeights"
        :key="i"
        :d="`M0 ${center}V${center}C8.83656 ${center} 16 ${center + 7.163} 16 ${center + 16}V${center + height - 16}C16 ${center + height - 7.163} 23.1634 ${center + height} 32 ${center + height}V${center + height}`"
        stroke="#5517E6"
        stroke-width="2"
      />
    </svg>
  </div>
</template>

<script>

import {mapGetters} from "vuex";

export default {
  name: "MultiArrow",

  emits: ['diff'],

  data(){
    return {
      upHeights: [],
      downHeights: [],
      showCenterLine: false,
      viewBoxHeight: 0,
      center: 0,
      diff: 0
    }
  },

  props: {
    actionId: {
      type: Number,
      required: true
    },
    choicesId:{
      type: Array,
      required: true
    }
  },

  mounted() {
    this.generateCoordinates()
  },

  computed:{
    ...mapGetters("chatbot/actions", {
      updateCoordinates: "getUpdateCoordinates",
      zoom: "getZoom"
    }),
  },

  watch:{
    updateCoordinates(){
      this.upHeights = []
      this.downHeights = []
      this.showCenterLine = false
      this.generateCoordinates()
    }
  },

  methods: {
    getY(id){
      const div = document.getElementById(id);
      const divRect = div.getBoundingClientRect();
      const centerY = divRect.top + divRect.height / 2;
      return centerY / this.zoom
    },

    generateCoordinates(){
      const multiChoiceY = this.getY(this.actionId)

      this.choicesId.forEach((id) => {
        const y = this.getY(id);
        if(Math.round(y) === Math.round(multiChoiceY)) {
          this.showCenterLine = true
        }
        else if (y > multiChoiceY) {
          this.downHeights.push(y - multiChoiceY);
        } else if (y < multiChoiceY) {
          this.upHeights.push(multiChoiceY - y);
        }
      });

      const maxUp = Math.max(...this.upHeights);
      const maxDown = Math.max(...this.downHeights);
      this.viewBoxHeight = maxDown + maxUp + 2
      this.center =  maxUp + 1
      this.diff = maxUp - maxDown
    }
  },
}
</script>
