import axios from "axios";

export default {
  /**
   *
   */
  create({ commit }, payload) {
    commit("startLoading");
    commit("clearErrors");
    commit("setloadingContext", "create");
    return new Promise((resolve, reject) => {
      axios
        .post("/campaigns/" + payload.id + "/lists", payload.fields /*, { headers }*/)
        .then(() => {
          commit("auth/trackEvents", "[Manager] Mailing List Created", { root: true });
          resolve({ message: "Mailing cadastrado com sucesso!" });
          resolve();
        })
        .catch(response => {
          reject({ message: response.response.data.detail });
          response.response.status == 422 &&
            response.response.data.errors &&
            commit("setErrors", response.response.data.errors);
        })
        .finally(() => {
          commit("setloadingContext", "");
          commit("stopLoading");
        });
    });
  },

  /**
   *
   * @param {*} param0
   * @param {*} payload
   * @returns
   */
  validateListFile({ commit /*, getters*/ }, payload) {
    commit("startLoading");
    commit("setListHeaderPreview", []);
    commit("setFileName", "");
    commit("clearErrors");
    commit("setloadingContext", "validate");

    const headers = { "Content-Type": "multipart/form-data" };
    return new Promise((resolve, reject) => {
      axios
        .post("/campaigns/" + payload.id + "/mailing", payload.formData, { headers })
        .then(response => {
          resolve();
          commit("setListDataPreview", response.data.data);
          commit("setFileName", response.data.file_name);
          if (response.data.header.length > 0) commit("setListHeaderPreview", response.data.header);
          else commit("setListHeaderPreview", new Array(response.data.data[0].length).fill(""));
        })
        .catch(response => {
          reject({ message: response.response.data.detail });
          response.response.status == 422 &&
            response.response.data.errors &&
            commit("setErrors", response.response.data.errors);
        })
        .finally(() => {
          commit("stopLoading");
          commit("setloadingContext", "validate");
        });
    });
  },

  /**
   * Brings the mailing lists previously uploaded by the user
   * in the campaign
   *
   * @returns A list of mailing lists
   */
  findListOfLists({ commit, getters }, payload) {
    commit("startLoadingFlag", "findListOfLists");

    return new Promise((resolve, reject) => {
      axios
        .get("/campaigns/" + payload.id + "/lists", { params: getters.getParams })
        .then(response => {
          commit("setList", response.data.data);
          resolve();
        })
        .catch(() => {
          reject();
        })
        .finally(() => {
          commit("stopLoadingFlag", "findListOfLists");
        });
    });
  },

  /**
   *
   */
  removeList({ commit }, payload) {
    commit("startLoading");
    return new Promise((resolve, reject) => {
      axios
        .delete("/campaigns/" + payload.campaign_id + "/lists/" + payload.id)
        .then(() => {
          resolve();
        })
        .catch(response => {
          reject({ message: response.response.data.detail });
        })
        .finally(() => {
          commit("stopLoading");
        });
    });
  },

  /**
   *
   */
  updateWeight({ commit }, payload) {
    commit("startLoadingFlag", "updateWeight");
    return new Promise((resolve, reject) => {
      axios
        .put("/campaigns/" + payload.campaign_id + "/lists/" + payload.id + "/updateWeight", { weight: payload.weight })
        .then(() => {
          resolve();
        })
        .catch(response => {
          reject({ message: response.response.data.detail });
        })
        .finally(() => {
          commit("stopLoadingFlag", "updateWeight");
        });
    });
  },
  downloadFilteredNumbers({ commit }, payload) {
    commit("startLoading");
    return new Promise((resolve, reject) => {
      axios
        .get(`/campaigns/${payload.campaign_id}/lists/${payload.list_id}/filtered/csv`)
        .then(response => {
          var fileURL = window.URL.createObjectURL(new Blob([response.data], { type: "text/csv" }));
          var fileLink = document.createElement("a");

          fileLink.href = fileURL;
          fileLink.setAttribute("download", `${payload.list_name}`);
          document.body.appendChild(fileLink);

          fileLink.click();
        })
        .catch(response => {
          reject({ message: response.response.data.detail });
        })
        .finally(() => {
          commit("stopLoading");
        });
    });
  },

  recycle({ commit, getters }, payload) {
    commit("startLoadingFlag", "recycle");

    return new Promise((resolve, reject) => {
      axios
        .post(`/campaigns/${payload.campaign_id}/lists/${payload.list_id}/recycle`, getters.getRecycleParams)
        .then(() => {
          resolve();
        })
        .catch(response => {
          commit("system/setErrorMessage", response.response.data, { root: true });
          reject({ message: response.response.data.detail });
        })
        .finally(() => {
          commit("stopLoadingFlag", "recycle");
        });
    });
  },
  updateGoalList({ commit }, payload) {
    commit("startLoadingFlag", "updateGoalList");
    return new Promise((resolve, reject) => {
      axios
        .put(`/campaigns/${payload.campaign_id}/lists/${payload.list_id}/updateConversionGoal`, {
          conversion_goal: payload.conversion_goal
        })
        .then(() => {
          commit("system/setSuccessMessage", "Meta atualizada com sucesso!", { root: true });
          resolve();
        })
        .catch(response => {
          commit("system/setErroressage", "Algo deu errado!", { root: true });
          reject({ message: response.response.data.detail });
        })
        .finally(() => {
          commit("stopLoadingFlag", "updateGoalList");
        });
    });
  }
};
