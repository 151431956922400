<template>
  <collapse-card
    id="data"
    :title="$t('data')"
    :errors="errors['invalid-opportunity-form-input-answer']"
    :is-loading="isLoading"
    :expand="true"
  >
    <template #content>
      <table
        style="border-collapse: separate; border-spacing: 0.5rem;"
        class="w-100"
      >
        <tr
          v-for="(inputAnswer, inputAnswerIndex) in formInputs"
          :key="`inputAnswer-${inputAnswerIndex}`"
        >
          <td>
            <label
              class="fs-6 label form-label fw-medium"
              :class="{'required': inputAnswer.is_required}"
            >{{ inputAnswer.label }}:</label>
          </td>
          <td class="w-100 ps-3">
            <input-answer
              :is-creation="isCreation"
              :errors="inputAnswer.is_required? errors['invalid-opportunity-form-input-answer'] : []"
              :input-answer="inputAnswer"
              @update-input="(val) => handleInput(inputAnswerIndex, val)"
            />
          </td>
        </tr>
      </table>
      <template v-if="formInputsRD && opportunity?.type === 'RD_STATION'">
        <div
          v-if="formInputs.length"
          style="margin-bottom: 1rem"
          class="border-top w-100"
        />
        <div
          style="margin-bottom: 0.5rem"
          class="rounded external-badge d-inline-flex align-items-center w-auto"
        >
          <img
            width="20"
            height="20"
            src="@assets/img/rd-station.svg"
            alt="RD Station"
          >
          <span class="tx-blue-300 fs-6">RD Station</span>
        </div>
        <table>
          <tr
            v-for="(inputAnswerRD, inputAnswerRDIndex) in formInputsRD"
            :key="`inputAnswerRD-${inputAnswerRDIndex}`"
          >
            <td>
              <label class="fs-6 label fw-medium">{{ inputAnswerRD.label }}:</label>
            </td>
            <td class="w-100 ps-3">
              <input-answer
                readonly
                :input-answer="inputAnswerRD"
              />
            </td>
          </tr>
        </table>
      </template>
    </template>
  </collapse-card>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import InputAnswer from "@crm/components/contact/form-input-answers/input-answer.vue";
import _ from "lodash";
import CollapseCard from "@crm/pages/opportunity/components/collapse-card.vue";

export default {
  props: {
    isCreation: {
      type: Boolean,
      default: false
    },

    isLoading: {
      type: Boolean,
      default: false
    }
  },
  emits: ["updateInputs"],

  components: {
    CollapseCard,
    InputAnswer
  },

  data() {
    return {
      formInputAnswers: [],
      answerField: null
    }
  },

  created() {
    this.init();
  },

  computed: {
    ...mapGetters('crm/funnels', {
      currentFunnel: 'getCurrentFunnel'
    }),
    ...mapGetters('crm/opportunities', {
      errors: "getErrors"
    }),
    ...mapGetters('crm/opportunities', {
      opportunity: "getOpportunity",
      formInputs: 'getFormInputs',
      formInputsRD: 'getFormInputsRD',
    }),
  },

  methods: {
    ...mapActions('crm/opportunities', ['fetchFormInputs']),

    handleInput(index, value) {
      this.formInputAnswers[index].answer = value
      this.$emit("updateInputs", this.formInputAnswers)
    },


    async init() {
      await this.awaitLoadingFalse();
      const formInputs = await this.fetchFormInputs(this.currentFunnel.id);
      if (formInputs.CRM && formInputs.CRM.length > 0) {
        const formInputsCRM = _.orderBy(formInputs.CRM, 'order');
        formInputsCRM.forEach((item) => {
          this.formInputAnswers.push({
            input_id: item.id,
            answer: "",
            is_required: item.is_required
          });
        });
      }
    },

    async awaitLoadingFalse() {
      return new Promise((resolve) => {
        const checkLoading = () => {
          if (!this.isLoading) {
            resolve();
          } else {
            setTimeout(checkLoading, 50);
          }
        };
        checkLoading();
      });
    },
  }
}
</script>

<style lang="scss" scoped>
.label {
  color: #575778;
  white-space: nowrap;
}

.external-badge {
  background-color: $color-gray-blue-300;
  padding: 0.25rem 0.5rem;
  gap: 0.25rem;
}
</style>
