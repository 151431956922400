<template>
  <div class="container-fluid">
    <header-component
      :pretitle="$t('reports_capitalize')"
      :title="$t('calls_report')"
      redirect-link="/manager/reports"
    />

    <div class="row">
      <div class="col">
        <label class="form-label required">
          {{ $t("period") }}
        </label>
        <input-date-range
          :max-interval="31"
          v-model:start="requiredFilters.start_date"
          v-model:end="requiredFilters.end_date"
          :time-enabled="true"
          :seconds-enabled="true"
          format-date="d/m/Y H:i:S"
        />
      </div>

      <div class="col">
        <label class="form-label">
          {{ $t("number") }}
        </label>
        <div
          class="input-group"
          style="height: 42px"
        >
          <span
            class="input-group-text p-0 border-0"
            v-if="!isBrazil"
          >
            <input-countries
              @selected-country="countryAcronym = $event.code"
              :not-input="false"
            />
          </span>
          <input
            type="text"
            @input="regexBlockedString"
            :class="{ 'rounded-start ': isBrazil }"
            class="form-control h-100"
            v-model="filters.number"
          >
        </div>
      </div>

      <div class="col">
        <label class="form-label">
          {{ $t("agent_capitalize") }}
        </label>
        <select-agent
          v-model="filters.agent_ids"
          :multiple="true"
        />
      </div>

      <div class="col">
        <label class="form-label">
          {{ $t("mode") }}
        </label>
        <select
          class="form-select"
          v-model="filters.call_mode"
        >
          <option value="all">
            {{ $t("all_dropdown") }}
          </option>
          <option value="dialer">
            {{ $t("dialer_menu_capitalize") }}
          </option>
          <option value="ura">
            {{ $t("ivr_uppercase") }}
          </option>
          <option value="manual">
            {{ $t("manual_capitalize") }}
          </option>
          <option value="receptive">
            {{ $t("receptive_capitalize") }}
          </option>
          <option value="consult">
            {{ $t("consult") }}
          </option>
          <option value="ring-group">
            {{ $t("extensions_group") }}
          </option>
        </select>
      </div>

      <div class="col">
        <app-button
          class="w-100 btn-report"
          @click="search()"
          :loading="loading.findCallsReports || loading.findCallsStatusCountPerTime"
          :blocked="!requiredFilters.start_date || !requiredFilters.start_date"
        >
          {{ $t("search_report") }}
        </app-button>
      </div>
    </div>

    <div class="col-12">
      <div class="form-group">
        <a
          href="#"
          @click="showAdvancedFilters = !showAdvancedFilters"
        ><small>
          <span v-if="!showAdvancedFilters">+ {{ $t("show_capitalizes") }} </span>
          <span v-else>- {{ $t("capitalizes.hide") }} </span>
          {{ $t("advanced_filters") }}.</small></a>
      </div>
    </div>

    <agent-information
      :show="showInformationOffcanvas"
      :call="callItem"
      :map="blobList"
      :get-call-mode="getCallMode"
      @blob="data => (blobList[callItem.record_name] = data)"
      @close="showInformationOffcanvas = false"
    />

    <form-feedback
      :show="showFeedbackOffcanvas"
      :call-history-id="callItem?.id"
      :campaign-id="callItem?.campaign_rel?.id"
      :call="callItem"
      :edit="callItem?.feedback"
      :recording="callItem?.recording"
      @close="showFeedbackOffcanvas = false"
    />

    <div v-show="showAdvancedFilters">
      <div class="filter-report mb-4">
        <div v-if="filters.call_mode != 'ring-group'">
          <label class="form-label required">
            {{ $t("campaign_capitalize") }}
          </label>
          <select-campaign v-model="filters.campaign" />
        </div>
        <div v-if="filters.call_mode == 'ring-group'">
          <label class="form-label">{{ $t("group") }}</label>
          <select-ring-group
            v-model="filters.ring_group_ids"
            :multiple="true"
          />
        </div>
        <div>
          <label class="form-label">Status</label>
          <select-call-status
            v-model="filters.statuses"
            :multiple="true"
          />
        </div>
        <div v-if="filters.call_mode != 'ring-group'">
          <label class="form-label">{{ $tc("capitalizes.qualifications", 0) }}</label>
          <select-qualification
            v-model="filters.qualification"
            :multiple="true"
            :close-on-select="false"
          />
        </div>
        <div>
          <label class="form-label">{{ $t("type_general") }}</label>
          <select
            class="form-select"
            v-model="filters.type"
          >
            <option value="all">
              {{ $t("all_dropdown") }}
            </option>
            <option value="mobile">
              {{ $t("mobiles") }}
            </option>
            <option value="landline">
              {{ $t("landline") }}
            </option>
          </select>
        </div>
        <div v-if="filters.call_mode != 'ring-group'">
          <label class="form-label">
            {{ $t("lists_capitalizes") }}
          </label>
          <select-list
            :campaign-id="filters.campaign"
            v-model="filters.list"
          />
        </div>
        <div v-if="filters.call_mode != 'ring-group'">
          <label class="form-label">
            {{ $t("routes_capitalize") }}
          </label>
          <select-route
            v-model="filters.route"
            :default-value="{ name: 'Todos', value: '' }"
            :hide-groups="true"
          />
        </div>
        <div>
          <label class="form-label">
            {{ $t("max_duration") }}
          </label>
          <div class="input-group input-group-merge">
            <input
              class="form-control"
              type="text"
              ref="max"
              v-model="maximum_duration"
              aria-label="Input group appended"
              aria-describedby="inputGroup"
            >
            <div class="input-group-text">
              <span class="fe fe-clock" />
            </div>
          </div>
        </div>
        <div>
          <label class="form-label">{{ $t("min_duration") }}</label>
          <div class="input-group input-group-merge">
            <input
              class="form-control"
              type="text"
              ref="min"
              v-model="minimum_duration"
              aria-label="Input group appended"
            >
            <div class="input-group-text">
              <span class="fe fe-clock" />
            </div>
          </div>
        </div>
        <div>
          <label class="form-label">{{ $t("protocol") }}</label>
          <input
            type="text"
            class="form-control"
            v-model="filters.sid"
          >
        </div>
        <div v-if="filters.call_mode != 'ring-group'">
          <label class="form-label">{{ $t("identifier") }}</label>
          <input
            type="text"
            class="form-control"
            v-model="filters.identifier"
          >
        </div>
        <div>
          <label class="form-label">{{ $t("ending_call_reason") }}</label>
          <select-hangupcauses v-model="filters.hangup_cause" />
        </div>
        <div>
          <label class="form-label">{{ $t("transfer_status") }}</label>
          <select
            class="form-select"
            v-model="transfer"
          >
            <option value="">
              {{ $t("all_dropdown") }}
            </option>
            <option value="is_transfer">
              {{ $t("transfers") }}
            </option>
            <option value="is_transferred">
              {{ $t("transferred") }}
            </option>
          </select>
        </div>
        <div v-show="filters.call_mode == 'receptive'">
          <label class="form-label">{{ $t("receptive_queue_capitalize") }} </label>
          <select-receptive-queues
            v-model="filters.queue_ids"
            :multiple="true"
          />
        </div>
      </div>
      <div class="row">
        <div class="col form-group">
          <div class="form-check form-switch">
            <input
              class="form-check-input"
              type="checkbox"
              id="call_time"
              v-model="filters.call_time"
            >
            <label
              class="form-check-label"
              for="call_time"
            >{{ $t("only_answered_calls") }}</label>
          </div>
        </div>
        <div
          v-if="filters.call_mode != 'ring-group'"
          class="col form-group"
        >
          <div class="form-check form-switch">
            <input
              class="form-check-input"
              type="checkbox"
              id="with_converted_calls"
              v-model="filters.with_converted_calls"
            >
            <label
              class="form-check-label"
              for="with_converted_calls"
            >{{ $t("only_converted_calls") }}</label>
          </div>
        </div>
        <div
          v-if="filters.call_mode != 'ring-group'"
          class="col form-group"
        >
          <div class="form-check form-switch">
            <input
              class="form-check-input"
              type="checkbox"
              id="max_time_exceeded"
              v-model="filters.max_time_exceeded"
            >
            <label
              class="form-check-label"
              for="max_time_exceeded"
            >{{ $t("only_exceeded_time_calls") }}</label>
          </div>
        </div>
        <div
          v-if="filters.call_mode != 'ring-group'"
          class="col form-group"
        >
          <div class="form-check form-switch">
            <input
              class="form-check-input"
              type="checkbox"
              id="with_ivr_after_call"
              v-model="filters.with_ivr_after_call"
            >
            <label
              class="form-check-label"
              for="with_ivr_after_call"
            >{{ $t("with_ivr_post_call") }}</label>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-header">
            <div class="row align-items-center">
              <div class="col">
                <p class="card-header-title">
                  {{ $t("calls_report") }}
                </p>
              </div>
              <div class="col-auto d-flex">
                <app-button
                  :color="'btn-outline-secondary'"
                  @click="showModal = true"
                  data-test="generateCsvModalShow-button"
                >
                  {{ $t("download_CSV") }}
                </app-button>
              </div>
            </div>
          </div>
          <div class="card-body p-0">
            <div class="table-responsive">
              <table class="table app-datatable my-0">
                <thead>
                  <tr>
                    <th scope="col" />
                    <th
                      scope="col-5"
                      class="fw-normal"
                      v-if="filters.call_mode == 'ring-group'"
                    >
                      {{ $t("phone") }}
                    </th>
                    <th
                      scope="col"
                      class="fw-normal"
                      v-if="filters.call_mode != 'ring-group'"
                    >
                      {{ $t("number") }}
                    </th>
                    <th
                      scope="col"
                      class="fw-normal"
                    >
                      {{ $t("date_capitalize") }}
                    </th>
                    <th
                      scope="col"
                      class="fw-normal"
                      v-if="filters.call_mode == 'ring-group'"
                    >
                      {{ $t("group") }}
                    </th>
                    <th
                      scope="col"
                      class="fw-normal"
                      v-if="filters.call_mode != 'ring-group'"
                    >
                      {{ $t("campaign_capitalize") }}/{{ $t("group") }}
                    </th>
                    <th
                      scope="col"
                      class="fw-normal"
                    >
                      {{ $t("total_time_capitalize") }}
                    </th>
                    <th
                      scope="col"
                      class="fw-normal"
                    >
                      {{ $t("type_general") }}
                    </th>
                    <th
                      scope="col"
                      class="fw-normal"
                    >
                      {{ $t("status_omni") }}
                    </th>
                    <th
                      scope="col"
                      class="fw-normal"
                    >
                      {{ $t("agent_capitalize") }}
                    </th>
                    <th
                      scope="col"
                      class="fw-normal"
                      v-if="filters.call_mode != 'ring-group'"
                    >
                      Feedback
                    </th>
                    <th
                      scope="col"
                      class="text-center fw-normal"
                      v-if="filters.call_mode != 'ring-group'"
                    >
                      {{ $t("agent_comment") }}
                    </th>
                    <th
                      scope="col"
                      class="text-center fw-normal"
                    >
                      Mailing
                    </th>
                    <th scope="col" />
                  </tr>
                </thead>

                <tbody>
                  <template v-if="loading.findCallsReports">
                    <loading-table-component
                      :lines="12"
                      :columns="11"
                      :list-width="[30, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100]"
                    />
                  </template>

                  <tr v-else-if="!searched && !callsReport.length">
                    <td
                      class="text-center text-muted"
                      colspan="12"
                      scope="row"
                    >
                      <span class="fe fe-alert-octagon" /> {{ $t("search_calls") }}
                    </td>
                  </tr>
                  <tr v-else-if="searched && !callsReport.length">
                    <td
                      class="text-center text-muted"
                      colspan="12"
                      scope="row"
                    >
                      <span class="fe fe-alert-octagon" /> {{ $t("no_record_found") }}
                    </td>
                  </tr>

                  <template
                    v-else
                    v-for="(report, i) in callsReport"
                    :key="report.id"
                  >
                    <tr @click="indexOpnnedRow = indexOpnnedRow == i ? -1 : i">
                      <td scope="row">
                        <span
                          v-if="report.status_id == 7"
                          class="fe fe-phone-call fs-3"
                          style="color: #5cb85c"
                        />
                        <span
                          v-else
                          class="fe fe-phone-missed fs-3"
                          style="color: #ce4c35"
                        />
                      </td>
                      <td>
                        <strong v-if="isBrazil">
                          {{ formatPhoneNumber(report.number) }}
                        </strong>
                        <strong v-else>
                          {{ report.number }}
                        </strong>
                      </td>
                      <td>{{ format(report.call_date) }}</td>
                      <td>
                        {{
                          report.campaign_id === 0 || report.campaign_id === null
                            ? report.ring_group_name
                            : report.campaign
                        }}
                      </td>
                      <td>{{ report.speaking_time }}</td>
                      <td>
                        {{ getCallType(report.phone_type) }}
                      </td>
                      <td
                        :style="{
                          color: getStatusInfo(report.status_id).color
                        }"
                      >
                        {{ report.readable_status_text }}
                      </td>
                      <td>
                        {{ report.agent == "-" ? "" : report.agent }}
                        <ended-agent-tooltip v-if="report.ended_by_agent" />
                      </td>
                      <td v-if="filters.call_mode != 'ring-group'">
                        <button
                          class="btn btn-sm btn-light  overflow-ellipsis"
                          :disabled="true"
                          v-if="report.agent == '-'"
                        >
                          {{ $t("no_agent") }}
                        </button>

                        <div
                          v-else-if="
                            report.campaign_rel &&
                              report.campaign_rel.dialer_settings &&
                              report.campaign_rel.dialer_settings.criterion_list_id
                          "
                        >
                          <button
                            v-if="report.feedback"
                            class="btn btn-sm m-0 btn-outline-secondary"
                            @click.stop.prevent="
                              showOffcanvas({
                                offcanvas: 'showFeedbackOffcanvas',
                                item: report
                              })
                            "
                          >
                            Ver Feedback
                          </button>
                          <button
                            v-else
                            @mouseover="hoveredItem = report"
                            @mouseleave="hoveredItem = null"
                            :class="
                              hoveredItem == report
                                ? 'btn btn-outline-primary btn-sm m-0'
                                : 'btn btn-sm m-0 text-secondary'
                            "
                            @click="handleAmplitude()"
                            @click.stop.prevent="
                              showOffcanvas({
                                offcanvas: 'showFeedbackOffcanvas',
                                item: report
                              })
                            "
                          >
                            {{ hoveredItem == report ? $t("give_feedback") : $t("not_have_feedback") }}
                          </button>
                        </div>
                        <button
                          v-else
                          class="btn btn-sm btn-light overflow-ellipsis"
                          :disabled="true"
                        >
                          {{ $t("no_criteria") }}
                        </button>
                      </td>

                      <td
                        class="text-center"
                        v-if="filters.call_mode != 'ring-group'"
                      >
                        <template v-if="report.agent == '-'" />
                        <button
                          v-if="report.qualification_note"
                          @click.stop.prevent="
                            showOffcanvas({
                              offcanvas: 'showInformationOffcanvas',
                              item: report
                            })
                          "
                          class="fe fe-message-square btn p-0"
                          style="cursor: pointer"
                        />
                        <div v-else>
                          <span class="tx-text-gray">
                            -
                          </span>
                        </div>
                      </td>
                      <td class="text-center">
                        <app-button
                          class="fe fe-file-text"
                          :color="'btn-link'"
                          @click.stop.prevent="
                            showOffcanvas({
                              offcanvas: 'showInformationOffcanvas',
                              item: report
                            })
                          "
                          style="color: #12263F"
                        />
                      </td>

                      <td
                        class="text-right"
                        style="text-align: right"
                      >
                        <span
                          @click="indexOpnnedRow = -1"
                          class="fe fe-chevron-down text-primary"
                          style="cursor: pointer"
                          v-if="indexOpnnedRow != i"
                        />

                        <span
                          @click="indexOpnnedRow = i"
                          class="fe fe-chevron-up text-primary"
                          style="cursor: pointer"
                          v-else
                        />
                      </td>
                    </tr>
                    <tr v-if="indexOpnnedRow == i">
                      <td
                        class="text-center p-0"
                        colspan="12"
                      >
                        <div class="detail py-4 m-0 d-flex flex-row justify-content-between">
                          <div class="col-9 d-flex flex-row justify-content-between">
                            <div class="col-lg-1 item-detail d-flex flex-column">
                              <span class="text-muted text-truncate">{{ $t("billed_time") }}</span>
                              <span>{{ report.billed_time }}</span>
                            </div>
                            <div class="col-lg-1 item-detail d-flex flex-column">
                              <span class="text-muted text-truncate">{{ $t("value") }}</span>
                              <span> {{ report.billed_value }}</span>
                            </div>
                            <div class="col-lg-2 item-detail d-flex flex-column">
                              <span class="text-muted text-truncate">{{ $t("behavior") }}</span>
                              <span class="text-truncate"> {{ report.readable_behavior_text }}</span>
                            </div>
                            <div class="col-lg-1 item-detail d-flex flex-column">
                              <span class="text-muted text-truncate">{{ $t("protocol") }}</span>
                              <span>
                                <copy-to-clipboard
                                  :text-to-copy="report.sid"
                                  position="'center'"
                                >
                                  <template #main>
                                    <div class="cursor-pointer overflow-hidden text-truncate">
                                      {{ report.sid }}
                                    </div>
                                  </template>
                                </copy-to-clipboard>
                              </span>
                            </div>

                            <div class="col-lg-2 item-detail d-flex flex-column">
                              <span class="text-muted text-truncate">{{ $t("ending_call_cause") }}</span>
                              <span class="text-truncate">
                                {{ report.hangup_cause }} - {{ report.readable_hangup_cause_text }}</span>
                            </div>

                            <div class="col-lg-1 item-detail d-flex flex-column">
                              <span class="text-muted text-truncate">Detecção de Caixa Postal</span>
                              <span class="text-truncate"> {{ report.readable_amd_status_text }}</span>
                            </div>

                            <div
                              class="col-lg-1 item-detail d-flex flex-column"
                            >
                              <span class="text-muted text-truncate">{{ $t("call_mode_capitalize") }}</span>
                              <span>
                                {{ getCallMode(report.mode) }}
                              </span>
                            </div>
                            <div class="col-lg-3 item-detail flex-column">
                              <span class="text-muted text-truncate">{{ $t("qualification") }}</span><br>
                              <span class="text-truncated">{{ report.qualification }}</span>
                            </div>
                          </div>

                          <div
                            class="col-3"
                            v-if="
                              report.readable_status_text != 'Falha' &&
                                report.readable_status_text != 'Caixa postal pós atendimento' &&
                                report.readable_status_text != 'Caixa postal pré atendimento'
                            "
                          >
                            <call-record
                              :audio="report.recording"
                              :file-name="report.record_name"
                              :map="blobList"
                              @new-blob="data => (blobList[report.record_name] = data)"
                            />
                          </div>
                        </div>
                      </td>
                    </tr>
                  </template>
                </tbody>
              </table>
            </div>
          </div>

          <div
            class="card-footer"
            v-if="searched && !loading.findCallsReports && callsReport.length"
          >
            <div class="col-lg-12">
              <paginator
                :pagination="pagination"
                @change-page="changePage"
                v-if="!loading.findCallsReports"
              />
            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-12">
        <div class="card">
          <div class="card-header">
            <div class="row align-items-center">
              <div class="col px-0">
                <p class="card-header-title">
                  {{ $t("totals") }}
                </p>
              </div>
            </div>
          </div>
          <div class="card-body p-0">
            <div class="table-responsive">
              <table class="table app-datatable my-0">
                <thead>
                  <tr>
                    <th class="col fw-normal">
                      {{ $t("uppercases.type") }}
                    </th>
                    <th class="col fw-normal">
                      {{ $t("uppercases.percent") }}
                    </th>
                    <th class="col fw-normal">
                      {{ $t("uppercases.total_calls") }}
                    </th>
                    <th class="col fw-normal">
                      {{ $t("uppercases.billing_time") }}
                    </th>
                    <th class="col fw-normal">
                      {{ $t("uppercases.billing_value") }}
                    </th>
                  </tr>
                </thead>

                <tbody>
                  <tr v-if="!searched">
                    <td
                      class="text-center text-muted"
                      colspan="12"
                      scope="row"
                    >
                      <span class="fe fe-alert-octagon" /> {{ $t("search_totals") }}
                    </td>
                  </tr>
                  <template v-if="isBrazil && totalCallsDataFilled && callsData.data">
                    <tr>
                      <td class="fw-bold">
                        {{ $t("landline") }}
                      </td>
                      <td>
                        {{
                          Math.round((callsData.data.landline.calls_count / callsData.data.all.calls_count) * 100) + "%"
                        }}
                      </td>
                      <td>{{ callsData.data.landline.calls_count }}</td>
                      <td>{{ Math.round(callsData.data.landline.billed_time / 60) + " " + $t("min") }}</td>
                      <td>{{ callsData.data.landline.billed_value }}</td>
                    </tr>
                    <tr>
                      <td class="fw-bold">
                        {{ $t("mobile") }}
                      </td>
                      <td>
                        {{
                          Math.round((callsData.data.mobile.calls_count / callsData.data.all.calls_count) * 100) + "%"
                        }}
                      </td>
                      <td>{{ callsData.data.mobile.calls_count }}</td>
                      <td>{{ Math.round(callsData.data.mobile.billed_time / 60) + " " + $t("min") }}</td>
                      <td>{{ callsData.data.mobile.billed_value }}</td>
                    </tr>
                    <tr>
                      <td class="fw-bold">
                        {{ $t("total_uppercase") }}
                      </td>
                      <td>100%</td>
                      <td>{{ callsData.data.all.calls_count }}</td>
                      <td>{{ Math.round(callsData.data.all.billed_time / 60) + " " + $t("min") }}</td>
                      <td>{{ callsData.data.all.billed_value }}</td>
                    </tr>
                  </template>
                  <template v-if="!isBrazil && totalCallsDataFilled && callsData.data">
                    <tr>
                      <td class="fw-bold">
                        Internacional
                      </td>
                      <td>
                        {{
                          Math.round(
                            (callsData.data.outbound_call.calls_count / callsData.data.all.calls_count) * 100,
                          ) + "%"
                        }}
                      </td>
                      <td>{{ callsData.data.outbound_call.calls_count }}</td>
                      <td>{{ Math.round(callsData.data.outbound_call.billed_time / 60) + " " + $t("min") }}</td>
                      <td>{{ callsData.data.outbound_call.billed_value }}</td>
                    </tr>
                    <tr>
                      <td class="fw-bold">
                        TOTAL
                      </td>
                      <td>100%</td>
                      <td>{{ callsData.data.outbound_call.calls_count }}</td>
                      <td>{{ Math.round(callsData.data.outbound_call.billed_time / 60) + " " + $t("min") }}</td>
                      <td>{{ callsData.data.outbound_call.billed_value }}</td>
                    </tr>
                  </template>
                  <tr v-else-if="totalCallsDataFilled && !callsData.data">
                    <td
                      class="text-center text-muted"
                      colspan="12"
                      scope="row"
                    >
                      <span class="fe fe-alert-octagon" /> {{ $t("no_results_totals") }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div
            v-if="searched && !totalCallsDataFilled"
            class="col col-12 d-flex justify-content-center"
          >
            <app-button
              @click="totalCallsData()"
              :loading="loading.getCallsTotal"
              class="col col-11 btn btn-primary m-5"
            >
              {{ $t("show_totals") }}
            </app-button>
          </div>
        </div>
      </div>

      <div class="col-lg-12">
        <calls-chart
          class="mb-1"
          :campaign-id="filters.campaign"
          :start-date="requiredFilters.start_date"
          :end-date="requiredFilters.end_date"
          v-show="filters.campaign"
        />
        <small class="text-muted mx-1"> {{ $t("search_to_show_graph") + " " }} <u> {{ $t("using_campaign_filter") + " "
        }}</u> {{ $t("to_show_graph") }}
        </small>
      </div>
    </div>
  </div>
  <calls-report-modal
    :show="showModal"
    @close="() => (showModal = false)"
    @prepare-filters="prepareFilters"
    :enable-download-audio-button="enableDownloadAudioButton"
  />
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";

import moment from "moment";

import { formatPhoneNumber, Utils } from "@/utils";

import HeaderComponent from "@/components/header-component.vue";

import InputDateRange from "@components/input-date-range";
import SelectHangupcauses from "@dialer/manager/components/select-hangupcauses";
import SelectRoute from "@dialer/manager/components/select-route.vue";
import SelectList from "@dialer/manager/components/select-list";
import SelectCallStatus from "@dialer/manager/components/select-call-status";
import SelectCampaign from "@dialer/manager/components/select-campaign";
import SelectQualification from "@dialer/manager/components/select-qualification";
import SelectAgent from "@dialer/manager/components/select-agent";
import SelectReceptiveQueues from "@dialer/manager/components/select-receptive-queues";
import SelectRingGroup from "@dialer/manager/components/select-ring-group";

import FormFeedback from "./components/form-feedback";
import AgentInformation from "./components/agent-information";
import paginator from "@components/app-paginator";
import CallsChart from "./components/calls-chart.vue";
import EndedAgentTooltip from "./components/ended-agent-tooltip.vue";
import CallRecord from "./components/call-record.vue";

import AppButton from "@components/app-button";

import LoadingTableComponent from "@dialer/manager/components/loading-table-component.vue";

import CallsReportModal from "./calls-report-modal.vue";

import CopyToClipboard from "@dialer/manager/pages/dashboards/campaign/components/copy-to-clipboard.vue";
import InputCountries from "@components/countries-collapse.vue";
import parsePhoneNumber, { AsYouType } from "libphonenumber-js";

export default {
  data() {
    return {
      requiredFilters: {
        start_date: moment()
            .startOf("day")
            .format("DD/MM/YYYY HH:mm:ss"),
        end_date: moment()
            .endOf("day")
            .format("DD/MM/YYYY HH:mm:ss"),
      },
      filters: {
        agents: [],
        call_mode: "all",
        statuses: "",
        type: "all",
        campaign: 0,
        agent_ids: [],
        queue_ids: [],
        minimum_duration: "",
        maximum_duration: "",
        sid: "",
        route: "",
        ring_group_ids: "",
        teams: "",
        identifier: "",
        hangup_cause: "",
        list: "",
        qualification: "",
        number: "",
        call_time: false,
        max_time_exceeded: false,
        with_ivr_after_call: false,
      },
      hoveredItem: null,
      showModal: false,
      indexOpnnedRow: -1,
      totalCallsDataFilled: false,
      showAdvancedFilters: false,
      minimum_duration: "00:00",
      maximum_duration: "00:00",
      transfer: "",
      searched: false,
      showCommentOffcanvas: false,
      showInformationOffcanvas: false,
      showFeedbackOffcanvas: false,
      callItem: null,
      blobList: {},
      countryAcronym: "BR",
      showCountry: false,
      formattedPhoneNumber: "",
    };
  },
  components: {
    InputCountries,
    InputDateRange,
    SelectRoute,
    SelectHangupcauses,
    SelectList,
    SelectCampaign,
    SelectQualification,
    SelectCallStatus,
    SelectAgent,
    SelectReceptiveQueues,
    SelectRingGroup,

    FormFeedback,
    paginator,
    AgentInformation,
    CallsChart,
    CallRecord,

    HeaderComponent,
    AppButton,
    LoadingTableComponent,
    EndedAgentTooltip,
    CallsReportModal,

    CopyToClipboard,
  },
  computed: {
    ...mapGetters("manager/calls-report", {
      callsReport: "getCallsReport",
      pagination: "getPagination",
      loading: "getLoadingFlags",
      callsData: "getTotalCallsData",
      callsReportFilters: "getFilters",
    }),
    ...mapGetters("auth", {
      user: "getUser",
      isBrazil: "isBrazil",
    }),
  },
  methods: {
    getCallMode(mode) {
      switch (mode) {
        case "dialer":
          return this.$t("dialer_menu_capitalize");
        case "receptive":
          return this.$t("receptive_capitalize");
        case "manual":
          return this.$t("manual_capitalize");
        case "consult":
          return this.$t("consult")
        case "ura":
          return this.$t("ivr_uppercase");
        default:
          return this.$t("extensions_group");
      }
    },

    regexBlockedString() {
      const regex = /[^\d() -]/g;
      this.filters.number = this.filters.number.replace(regex, "");
    },
    formatPhoneNumber,

    format: Utils.formatDate,

    ...mapMutations("manager/calls-report", ["setCurrentPage", "setFilters", "setTotalFilters", "setInclude"]),

    ...mapActions("manager/calls-report", [
      "findCallsReports",
      "findCallsStatusCountPerTime",
      "getCallsTotal",
    ]),
    ...mapMutations("auth", ["trackEvents"]),
    handleAmplitude() {
      this.trackEvents("[Manager] Feedback Applied");
    },

    search() {
      this.blobList = {};
      this.indexOpnnedRow = -1;
      this.prepareFilters();
      this.setCurrentPage(0);
      this.findCallsReports();
      if (this.filters.campaign)
        this.findCallsStatusCountPerTime({
          startDate: this.requiredFilters.start_date,
          endDate: this.requiredFilters.end_date,
          interval: 60,
          campaign_id: this.filters.campaign,
        });

      this.searched = true;
      this.totalCallsDataFilled = false;
    },

    prepareFilters() {
      let filters = {};

      filters.start_date = this.requiredFilters.start_date;
      filters.end_date = this.requiredFilters.end_date;
      if (this.filters.agent_ids.length) filters.agent_ids = this.filters.agent_ids;
      if (this.filters.number) filters.number = this.formattedPhoneNumber.replace("+", "");
      if (this.filters.call_mode == "ring-group") filters.report_option = 4;

      let temp = this.minimum_duration.split(":");
      temp = parseInt(temp[0]) * 60 + parseInt(temp[1]);
      if (temp > 0) filters.minimum_duration = temp;
      temp = this.maximum_duration.split(":");
      temp = parseInt(temp[0]) * 60 + parseInt(temp[1]);

      if (this.showAdvancedFilters) {
        for (let key of Object.keys(this.filters)) {
          if (this.filters[key]) {
            if (key === "number") {
              filters.number = this.formattedPhoneNumber.replace("+", "");
            } else {
              filters[key] = this.filters[key];
            }
          }
        }
      }
      filters.call_mode = this.filters.call_mode;
      if (filters.call_mode === "ring-group") {
        delete filters.call_mode;
      }
      if (this.filters.hangup_cause !== "") filters.hangup_cause = this.filters.hangup_cause;

      if (this.transfer == "is_transfer") filters.is_transfer = 1;
      else if (this.transfer == "is_transferred") filters.is_transferred = 1;

      filters.simple_paginate = true;

      let include = ["campaign_rel"];
      if (this.filters.call_mode == "ring-group") include.push("ring_group_rel");

      this.setInclude(include);

      this.setFilters(filters);

      return filters;
    },

    prepareTotalFilters() {
      let filters = this.prepareFilters();

      if (filters.agent_ids && filters.agent_ids.length > 0) {
        filters.agent_ids = filters.agent_ids.split(",");
      }

      let filtersAsArray = Object.entries(filters);
      filtersAsArray = filtersAsArray.filter(([key]) => key != "simple_paginate" && key != "include");

      let filterAsObject = Object.fromEntries(filtersAsArray);

      this.setTotalFilters(filterAsObject);
    },

    changePage(page) {
      this.blobList = {};
      this.indexOpnnedRow = -1;
      this.setCurrentPage(page);
      this.findCallsReports();
    },

    totalCallsData() {
      this.prepareTotalFilters();
      this.getCallsTotal()
          .then(() => {
            this.totalCallsDataFilled = true;
          });
    },

    getStatusInfo(status) {
      switch (status) {
        case 5:
          return {
            icon: "mx-2 fe fe-minus",
            color: "#989898",
            text: this.$t("not_answered_capitalize"),
          };
        case 6:
          return {
            icon: "mx-2 fe fe-alert-triangle",
            color: "#DE7912",
            text: this.$t("abandoned_capitalize"),
          };
        case 7:
          return {
            icon: "mx-2 fe fe-users",
            color: "#5cb85c",
            text: this.$t("connected_general"),
          };
        case 8:
          return {
            icon: "mx-2 fe fe-clock",
            color: "#B2B3C1",
            text: this.$t("fail"),
          };
        case 9:
          return {
            icon: "mx-2 fe fe-mail",
            color: "#ce4c35",
            text: this.$t("post_call_ivr"),
          };
        case 15:
          return {
            icon: "mx-2 fe fe-mail",
            color: "#ce4c35",
            text: this.$t("pre_call_ivr"),
          };
        default:
          return {
            icon: "mx-2 fe fe-help-circle",
            color: "#B2B3C1",
            text: "",
          };
      }
    },

    enableDownloadAudioButton() {
      const startDate = moment(this.requiredFilters.start_date);
      const endDate = moment(this.requiredFilters.end_date);

      if (
          this.filters.statuses.length != 1 ||
          (this.filters.statuses.length == 1 && this.filters.statuses[0] != 7) ||
          endDate.diff(startDate, "hours") > 24
      ) {
        return false;
      }

      return true;
    },

    showOffcanvas({ offcanvas, item }) {
      this[offcanvas] = true;
      this.callItem = item;
    },

    getCallType(type) {
      switch (type) {
        case "landline":
          return this.$t("landline");
        case "mobile":
          return this.$t("mobile");
        case "outbound_call":
          return this.$t("international");
        default:
          return "";
      }
    },
  },

  watch: {
    minimum_duration(val) {
      let arr = val.split(":");
      this.filters.minimum_duration = parseInt(arr[0]) * 60 + parseInt(arr[1]);
    },

    maximum_duration(val) {
      let arr = val.split(":");
      this.filters.maximum_duration = parseInt(arr[0]) * 60 + parseInt(arr[1]);
    },
    "filters.number": {
      handler(val) {
        if (val.trim() === "") {
          this.formattedPhoneNumber = "";
        }
        if (val && this.filters.number.length > 3) {
          const parse = parsePhoneNumber(val, this.countryAcronym);
          const formattedPhone = new AsYouType(this.countryAcronym).input(val);
          if (formattedPhone && parse) {
            this.formattedPhoneNumber = parse.number;
            this.filters.number = formattedPhone;
          }
        }
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.detail {
  background-color: #f3f7fb;
  font-size: 0.85em;
  padding: 0 16px;
}

.item-detail {
  padding-right: 6px;
}

.oppened-row {
  background-color: #f3f7fb;
}

.filter-report {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 24px;
}
</style>
