import {mapGetters, mapMutations} from "vuex";

export default {
  computed: {
    ...mapGetters("auth", {
      user: "getUser",
      inImpersonate: "inImpersonate",
      isSupervisorOrManager: "isSupervisorOrManager",
      isManager: 'isManager'
    }),
  },

  mounted() {
    if (this.user) {
      if (!this.$route.path.includes("/whatsapp") && !["agent", "partner"].includes(this.user.role.name) ) {
        if (!process.env.VUE_APP_AB01CD) return;
        let config = {
          app_id: process.env.VUE_APP_AB01CD,
          vertical_padding: 73,
        };
        if (
          this.user &&
          (this.user.role.name === "manager" ||
            this.user.role.name === "supervisor") &&
          !this.inImpersonate
        ) {
          let tags = [];
          for (let item of this.user.company.tags) tags.push(item.name);
          config["name"] = this.user.name + " (Novo front-end)";
          config["email"] = this.user.email;
          config["extension_id"] = this.user.extension.extension_number;
          config["user_id"] = this.user.id;
          config["created_at"] = this.user.company.created_at;
          config["company"] = {
            id: this.user.company.id,
            name: this.user.company.name,
            domain: this.user.company.domain,
            suite_users: this.user.company.max_agents_login,
            "3c-tag": tags.join(","),
            "3c-plan": ["Ilimitado", "Minutagem", "Telefonia Externa"][
            this.user.company.plan - 1
              ],
          };
          this.load(config);
        } else {
          this.load({});
        }
      }
    }
  },

  watch: {
    '$route.path': {
      handler(path) {
        if( this.isManager && window?.Intercom) {
          if(path.startsWith("/whatsapp")) {
            const clientHeight = window.innerHeight;
            const vertical_padding = (clientHeight / 2);
            window.Intercom('update', {
              "vertical_padding": vertical_padding
            });

            return
          }
          window.Intercom('update', {
            "vertical_padding": 73
          });
        }
      },
    },
    user(user) {
      if (!window.Intercom) return;
      if (
        user &&
        (user.role.name == "manager" || user.role.name == "supervisor") &&
        !this.inImpersonate
      ) {
        let tags = [];
        for (let item of user.company.tags) tags.push(item.name);
        window.Intercom("boot", {
          app_id: process.env.VUE_APP_AB01CD,
          name: user.name + " (Novo front-end)",
          email: this.user.email,
          config: user.extension.extension_number,
          user_id: user.id,
          company: {
            id: user.company.id,
            name: user.company.name,
            domain: user.company.domain,
            created_at: user.company.created_at,
            suite_users: user.company.max_agents_login,
            "3c-tag": tags.join(","),
            "3c-plan": ["Ilimitado", "Minutagem", "Telefonia Externa"][
            user.company.plan - 1
              ],
            },
          });

          window.Intercom('onShow', () => {
            this.trackEvents(`[Manager] Intercom Click`)
          })
      } else {
        window.Intercom("shutdown");
      }
    },
  },

  methods: {
    ...mapMutations("auth", ["trackEvents"]),
    load(config) {
      window.intercomSettings = config;
      (function () {
        var w = window;
        var ic = w.Intercom;
        if (typeof ic === "function") {
          ic("reattach_activator");
          ic("update", config);
        } else {
          var d = document;
          var i = function () {
            i.c(arguments);
          };
          i.q = [];
          i.c = function (args) {
            i.q.push(args);
          };
          w.Intercom = i;
          let l = () => {
            var s = d.createElement("script");
            s.type = "text/javascript";
            s.async = true;
            s.src = "https://widget.intercom.io/widget/" + config.app_id;
            var x = d.getElementsByTagName("script")[0];
            x.parentNode.insertBefore(s, x);
          };
          if (w.attachEvent) {
            w.attachEvent("onload", l);
          } else {
            w.addEventListener("load", l, false);
          }
        }
      })();
    },
  },
}
