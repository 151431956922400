<template>
  <div
    id="myOffcanvas"
    ref="canvas"
    class="offcanvas offcanvas-end"
  >
    <div
      class="offcanvas-header"
      style="border: none"
    >
      <button
        aria-label="Close"
        class="btn-close text-reset"
        data-bs-dismiss="offcanvas"
        type="button"
      />
    </div>
    <div class="offcanvas-body">
      <div class="row">
        <div class="col-lg-12 text-center">
          <h2 style="font-weight: 100">
            {{ $t('add_sms') }}
          </h2>
          <p class="text-muted">
            {{ $t('credit_per_message') }}
          </p>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-12">
          <div class="form-group">
            <label for="form-label required">{{ $t('quantity') }}</label>
            <input
              class="form-control"
              inputmode="numeric"
              type="number"
              placeholder="300"
              data-test="smsAmount-input"
              :class="{ 'is-invalid': hasErrors }"
              v-model="amount_sms_value"
            >
            <small
              v-if="hasErrors"
              class="text-danger"
            >
              {{ $t('select_valid_value') }}
            </small>
          </div>
          <div class="form-group">
            <label for="form-label required">{{ $t('values') }}</label>
            <div class="d-flex flex-row">
              <div class="border col-lg-6 text-center rounded-start">
                <label
                  for="input1"
                  class="text-muted font-weight-light"
                >{{ $t('unit_value') }}</label>
                <h3 class="py-2 font-weight-light">
                  {{ `${currentCoin()} ` + this.users.company.value_sms }}
                </h3>
              </div>
              <div
                class="border border-start-0 col-lg-6 text-center rounded-end"
              >
                <label
                  for="input1"
                  class="text-muted font-weight-light"
                >{{ $t('total_value') }}</label>
                <h3 class="py-2 font-weight-light">
                  {{ `${currentCoin()} ` + smsValueFormat(this.users.company.value_sms * amount_sms_value) }}
                </h3>
              </div>
            </div>
          </div>
        </div>
      </div>
      <app-button
        :loading="loading"
        class="col-lg-12 btn btn-primary"
        @click="submitBuySms()"
      >
        {{ $t('add_capitalize') }}
      </app-button>
    </div>
  </div>
</template>
<script>
import { Offcanvas } from "bootstrap";
import { mapActions, mapGetters } from "vuex";

export default {
  emits: ["bought-store", "no-credits", "bought-sms"],

  data() {
    return {
      offcanvas: null,
      amount_sms_value: 0,
      hasErrors: false,
      loading: false
    };
  },
  mounted() {
    this.offcanvas = new Offcanvas(this.$refs.canvas);
    if (this.offcanvas) {
      this.$refs.canvas.addEventListener("hidden.bs.offcanvas", () => {
        this.hasErrors = false;
        this.amount_sms_value = 0;
      });
    }
  },
  computed: {
    ...mapGetters("auth", {
      users: "getUser"
    })
  },
  methods: {
    ...mapActions("manager/sms_dashboard", ["buySms"]),
    new() {
      this.mode = "new";
      this.fields = {
        allow_schedule: false,
        allow_schedule_to_another_number: false,
        behavior: "",
        color: "",
        conversion: false,
        days_limit: 7,
        dmc: false,
        emoji: "",
        name: ""
      };
      this.offcanvas.show();
    },
    submitBuySms() {
      this.loading = true;
      if (this.amount_sms_value == 0) {
        this.hasErrors = true;
        return;
      }
      if (this.users.company.credit_limit + this.users.company.balance <= 0) {
        this.$emit("no-credits");
        this.offcanvas.hide();
      } else {
        this.buySms({
          amount_sms: this.amount_sms_value
        })
          .then(() => {
            this.offcanvas.hide();
            this.$emit("bought-sms");
          })
          .catch(error => {
            if (error.errors.balance.length && error.errors.balance[0] === "Sem saldo para compra de créditos de SMS") {
              this.$emit("no-credits");
              this.offcanvas.hide();
            }
          });
      }
      this.loading = false;
    },
    smsValueFormat(amount) {
      return amount.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,");
    },
    currentCoin() {
      if (this.users.company.currency === "BRL") {
        return "R$";
      } else {
        return "$";
      }
    }
  }
};
</script>

<style lang="scss" scoped>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
}
</style>
