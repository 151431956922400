export default {
  isLoading: state => state.loading,
  getErrors: state => state.errors,

  getErrorMessage: state => state.errorMessage,
  getSuccessMessage: state => state.successMesage,
  getInfoMessage: state => state.infoMessage,
  getWarningMessage: state => state.warningMessage,
  getTimeMessage: state => state.timeMessage,
  getCustomizedTitleMessage: state => state.customizedTitleMessage,
  getCustomMessageType: state => state.customMessageType,
  isMessage: state => state.isMessage,

  getConfirmationMessage: state => state.confirmationMessage,

  getLoadingContext: state => state.loadingContext,

  getSpyParams: state =>
    Object.assign({
      whisper: state.spyParams.whisper,
      spy: state.spyParams.spy
    }),

  isRegistered: state => {
    // state.socketIOStatus == 'connected'
    return (
      (state.jsSipStatus == "registered" || state.jsSipStatus == "in-call") && state.userMediaPermission == "allowed"
    );
  },

  getMode: state => state.mode,
  getStatus: state => state.status,
  getConnectingInfo: state => {
    if (state.socketIOStatus == "disconnected") {
      return "Conectando..."; //Ws
    } else if (state.userMediaPermission == "peending") {
      return "Aguardando Permissão...";
    } else if (state.jsSipStatus == "disconnected") {
      return "Conectando..."; //JsSip
    } else if (state.jsSipStatus != "registered") {
      if (state.registrationAttemp == 0) return "Registrando...";
      else return "Tentando registrar...";
    }
  },

  getUserMediaPermission: state => state.userMediaPermission,
  getSocketIOStatus: state => state.socketIOStatus,
  getAcwTimeout: state => state.acwTimeout,

  getTotalSeconds: state => state.totalSeconds,
  getTimeAsString: state => state.timeAsString,

  isTimerTargetAchieved: state => state.timerTargetAchieved,

  getShowDialer: state => state.showDialer,
  getDialerText: state => state.dialerText,

  inConsulting: state => state.consulting,
  getAgentInConsult: state => state.agentInConsult,
  isConsultHold: state => state.consultHold,
  getConsultingAgentName: state => state.consultingAgentName,

  getDTMFkeys: state => state.DTMFkeys,
  isMuted: state => state.muted,

  getFlashMessage: state => state.flashMessage,
  getIntegrationURL: state => state.integrationURL,

  getRemaningACWTime: state => state.remaningACWTime,
  getSpyingTelephonyId: state => state.spyingTelephonyId,
  getSpiedAgent: state => state.spiedAgent,
  getSelectedAgentToSpy: state => state.selectedAgentToSpy,
  getShowSpy: state => state.showSpy,
  getSpyError: state => state.spyError,

  isManualTimeLimited: state => state.manualTimeLimited,
  getManualTimeout: state => state.manualTimeout,

  /**
   *
   *
   *
   */

  getJsSIPActive: state => state.jsSIPActive,
  getSocketIOActive: state => state.socketIOActive,
  getJsSipStream: state => state.jsSipStream,

  // Request limit
  isRequestLimitExceeded: state => state.requestLimitExceeded,

  /**
   *
   * @param {*} state
   * @returns
   */
  getLoadingFlags: state => state.loadingFlags,
  getJsSipStatus: state => state.jsSipStatus,
  getShowTransferCard: state => state.showTransferCard,
  getCurrentAudioUrl: state => state.currentAudioUrl,
  getMediaRecorder: state => state.mediaRecorder,
  isConnectedExecuted: state => state.isConnectedExecuted,
  getUserIp: state => state.userIp,
};
