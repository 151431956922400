<template>
  <div class="mb-3">
    <label
      class="form-label required"
      for="selectOpportunity"
    >
      {{ $t('opportunity') }}
    </label>
    <vue-multiselect
      v-model="selectedOpportunity"
      :class="{ 'border-red': showError}"
      :clear-on-select="false"
      :disabled="disabled"
      :hide-selected="true"
      :internal-search="false"
      :limit="3"
      :loading="isLoading"
      :max-height="600"
      :options="allOpportunities"
      :options-limit="300"
      :placeholder="$t('search_opportunity')"
      :searchable="true"
      :show-no-results="false"
      label="name"
      name="selectOpportunity"
      track-by="id"
      @select="updateValue"
      @search-change="search"
    />
    <small
      v-if="showError"
      class="text-danger"
    >
      {{ $t('opportunity_field_required') }}
    </small>
  </div>
</template>

<script>
import VueMultiselect from "vue-multiselect";
import _ from "lodash";
import {mapActions, mapGetters, mapMutations} from "vuex";

export default {
  name: "SelectOpportunity",
  emits: ["update:modelValue"],
  components: {VueMultiselect},

  props: {
    modelValue: {type: Object, default: null},
    disabled: {type: Boolean, default: false},
    showError: {type: Boolean, default: false}
  },

  data() {
    return {
      selectedOpportunity: this.modelValue,
      isLoading: false
    }
  },

  computed: {
    ...mapGetters("crm/opportunities", {
      opportunities: "getOpportunities",
      loading: "getLoadingFlags",
    }),
    ...mapGetters("crm/funnels", {
      currentFunnel: "getCurrentFunnel",
    }),
    allOpportunities() {
      const opportunities = Object.values(this.opportunities).reduce((acc, opportunityArray) => acc.concat(opportunityArray), []);
      return opportunities.filter(opp => opp.status !== 'lost')
    }
  },

  watch: {
    modelValue: {
      handler(newVal) {
        this.selectedOpportunity = newVal;
      },
      deep: true,
      immediate: true
    }
  },

  methods: {
    ...mapMutations('crm/opportunities', ['setOpportunityFilters']),
    ...mapActions('crm/opportunities', ['fetchFunnelOpportunities']),
    search: _.debounce(function (query) {
      this.setOpportunityFilters({
        filter: "search",
        value: query.length ? query : null
      })
      this.getContactsList()
    }, 300),

    getContactsList() {
      if (!this.loading.fetchFunnelOpportunities) {
        this.fetchFunnelOpportunities(this.currentFunnel.steps)
      }
    },

    updateValue(value) {
      this.$emit('update:modelValue', value);
    },
  },

}
</script>

<style lang="scss" scoped>
.border-red {
  border: 1px solid red;
  border-radius: 10px;
}
</style>
