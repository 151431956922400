<template>
  <div class="col-12">
    <header-component
      :title="mode == 'new' ? 'Novo' : fields.name"
      :pretitle="$t('breaks_uppercase')"
      redirect-link="/manager/intervals"
    />

    <div class="card">
      <div
        class="card-header border-bottom"
        style="padding: 16px 24px;height: 56px;"
      >
        <h4 class="form-label required mb-0">
          {{ $t("work_break_group_name") }}
        </h4>
      </div>
      <div
        class="card-body"
        style="height: 88px;"
      >
        <div class="form-group mb-4">
          <input
            type="text"
            :placeholder="$t('interval_group')"
            v-model="fields.name"
            :class="{
              'is-invalid': errors.name
            }"
            class="form-control"
          >
          <div class="invalid-feedback">
            {{ errors.name && errors.name[0] }}
          </div>
        </div>
      </div>
    </div>
    <intervals-list
      v-if="mode === 'edit' && !loadingFlags.findWorkBreakGroupById"
      :intervals="workBreakGroup.intervals"
    />

    <div class="d-flex justify-content-center flex-column align-items-center my-4">
      <app-button
        class="col-6"
        :loading="loadingFlags.findWorkBreakGroupById || loadingFlags.create || loadingFlags.update"
        @click="submit"
      >
        {{ mode === "new" ? $t("create_interval") : $t("save_interval") }}
      </app-button>

      <app-button
        v-if="mode === 'edit'"
        @click="removeInterval"
        class="col-2"
        color="text-danger"
      >
        <span class="fe fe-trash" />
        {{ $t("exclude_interval") }}
      </app-button>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import AppButton from "@/components/app-button.vue";
import IntervalsList from "@dialer/manager/pages/settings/intervals/intervals-list.vue";
import HeaderComponent from "@/components/header-component.vue";

export default {
  data() {
    return {
      mode: "",
      fields: {
        name: ""
      }
    };
  },
  mounted() {
    if (this.$route.params.id) {
      this.mode = "edit";
      this.findWorkBreakGroupById({ id: this.$route.params.id, include: ["intervals"] }).then(() => {
        this.fields.name = this.workBreakGroup.name;
      });
    } else {
      this.mode = "new";
    }
  },
  components: {
    HeaderComponent,
    IntervalsList,
    AppButton
  },
  computed: {
    ...mapGetters("manager/intervals", {
      loadingFlags: "getLoadingFlags",
      workBreakGroup: "getWorkBreakGroup",
      errors: "getErrors"
    })
  },

  methods: {
    ...mapActions("manager/intervals", ["create", "update", "remove", "findWorkBreakGroupById"]),
    ...mapActions("system", ["showConfirmationMessage"]),
    createInterval() {
      this.create({ fields: this.fields }).then(({ id }) => {
        this.$router.push({
          path: `/manager/intervals/${id}/edit`
        });
      });
    },
    updateInterval() {
      this.update({
        id: this.workBreakGroup.id,
        fields: {
          name: this.fields.name
        }
      })
      .then(() => this.findWorkBreakGroupById({ id: this.$route.params.id, include: ["intervals"] }));
    },

    submit() {
      if (this.mode === "new") {
        this.createInterval();
      } else {
        this.updateInterval();
      }
    },

    async removeInterval() {
      let response = await this.showConfirmationMessage({
        title: this.$t("delete_breaks_group_question"),
        text: this.$t("delete_breaks_group_confirmation"),
        type: "badWarning"
      });

      if (response.isConfirmed) {
        this.remove({
          id: this.workBreakGroup.id
        }).finally(() => {
          this.$router.push({
            path: `/manager/intervals`
          });
        });
      }
    }
  }
};
</script>
