<template>
  <div
    v-if="isVisible"
    class="rounded text-center bg-danger-soft"
  >
    <div class="position-relative mb-2">
      <img
        src="@assets/img/red-aloisio.svg"
        alt="Notificação de pagamento atrasado"
        class="red-aloisio mt-2"
      >
    </div>
    <small class="fw-bold">
      {{ $t('blocked_access') }}
    </small>
  </div>
</template>

<script>
import moment from "moment";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      isCompanyBlocked: false
    };
  },
  mounted() {
    this.updateBlockedAt();
  },
  computed: {
    ...mapGetters("auth", {
      user: "getUser"
    }),
    isVisible() {
      const { company } = this.user;
      if (company && (!this.user.company.blocked_at || this.user.role.name === "supervisor")) return false;
      return this.isCompanyBlocked;
    }
  },
  methods: {
    updateBlockedAt() {
      const { company } = this.user;

      if (company && this.user.company.blocked_at) {
        const today = moment().unix();
        this.isCompanyBlocked = this.user.company.blocked_at < today;
      }
    }
  },
  watch: {
    user: {
      handler(data) {
        if (data) {
          this.updateBlockedAt();
        }
      },
      deep: true
    }
  }
};
</script>

<style scoped lang="scss">
.red-aloisio {
  height: 35px;
  width: 40px;
}
</style>
