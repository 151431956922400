<template>
  <button
    ref="tooltipStatus"
    class="btn fs-5 text-medium d-flex align-items-center justify-content-between fw-bold agent-info-status gap-2"
    :style="{
      'background-color': color,
      color: [$t('idle_capitalize'), $t('break_capitalize'), $t('return_requested'), 'Offline'].includes(title) ? '#1B1B2B' : '#FFFFFF'
    }"
  >
    <div class="d-flex align-items-center gap-2">
      {{ title }}
      <i
        v-show="isSpyMode"
        class="fe fe-eye"
      />
    </div>
    <span
      v-if="agent.status !== AgentStatus.OFFLINE"
      :class="{
        'text-danger':
          (agent.status == AgentStatus.ON_CALL && limitCallTime > 0 && agent.counter > limitCallTime * 60) ||
          (agent.status == AgentStatus.ON_WORK_BREAK && agent.counter > agent.work_break.minutes)
      }"
    >{{ convertSecondsToTime(agent.counter) }}
    </span>
  </button>
</template>

<script>
import { AgentStatus, convertSecondsToTime, Utils } from "@/utils";
import { Tooltip } from "bootstrap";

export default {
  name: "AgentTooltipStatus",
  data() {
    return {
      AgentStatus,
      tooltip: null
    };
  },

  mounted() {
    if (this.agent.status == 6) this.generateTooltipStatus();
  },

  props: {
    color: {
      type: String,
      default: "#111111",
      required: true
    },

    title: {
      type: String,
      default: "",
      required: true
    },

    limitCallTime: {
      type: Number,
      default: 0,
      required: true
    },

    agent: {
      type: Object,
      default: () => {}
    },

    isSpyMode: {
      type: Boolean,
      default: false
    }
  },

  methods: {
    timeFormat: Utils.timeFormat,
    convertSecondsToTime,
    generateTooltipStatus() {
      let tooltip = document.createElement("div");
      let tooltipItem = document.createElement("div");
      tooltipItem.innerHTML = this.agent.work_break.name;
      tooltip.appendChild(tooltipItem);

      this.tooltip = new Tooltip(this.$refs.tooltipStatus, {
        html: true,
        container: "body",
        title: tooltip,
        placement: "top"
      });
    }
  },

  watch: {
    "agent.status"(data) {
      if (data === 6) {
        this.generateTooltipStatus();
      } else if (this.tooltip !== null) {
        this.tooltip.dispose();
        this.tooltip = null;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.agent-info-status {
  padding-left: 8px;
  padding-right: 8px;
  width: 210px;
  height: 22px;
  border-radius: 4px;
  cursor: default;
}
</style>
