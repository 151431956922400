<template>
  <div
    class="offcanvas-body d-flex flex-column"
    style="gap: 1rem"
  >
    <action-component
      icon-bg="#D6E9D9"
      icon-class="fa-regular fa-question"
      icon-color="#015D0D"
      :name="$t('make_a_question')"
    />

    <div class="col-auto">
      <label
        class="form-label"
        for="message"
      >{{ $t('message_text') }}</label>
      <input
        id="message"
        v-model="textContent"
        :class="{'is-invalid': !textContent}"
        class="form-control"
        :placeholder="$t('what_is_your_question')"
        type="text"
      >
    </div>
    <div class="mt-auto" />
    <app-button
      :disabled="!textContent"
      @click="editAction"
    >
      {{ $t('capitalizes.save') }}
    </app-button>
    <app-button
      color="text-danger"
      @click="_deleteAction"
    >
      <span class="fa-regular fa-trash" />
      {{ $t('capitalizes.exclude') }}
    </app-button>
  </div>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from "vuex";

import ActionComponent from "@chatbot/pages/workflow/components/action-component.vue";
import AppButton from "@components/app-button.vue";

export default {
  name: "EditQuestion",

  components: {
    AppButton,
    ActionComponent
  },

  data() {
    return {
      textContent: ""
    }
  },

  mounted() {
    this.textContent = this.actionToEdit.text_content
  },

  computed: {
    ...mapGetters("chatbot/actions", {
      actionToEdit: "getActionToEdit"
    })
  },

  methods: {
    ...mapActions("chatbot/chatbots", ["fetchChatbot"]),
    ...mapActions("chatbot/actions", [
      "updateAction",
      "deleteAction"
    ]),
    ...mapMutations("chatbot/actions", [
      "setOffcanvasEditActionIsOpen",
      "setActionToEdit"
    ]),

    editAction() {
      this.updateAction({
        actionId: this.actionToEdit.id,
        payload: {
          text_content: this.textContent
        }
      }).then(() => {
        this.setOffcanvasEditActionIsOpen(false)
      })
    },

    _deleteAction() {
      this.deleteAction(this.actionToEdit.id).then(() => {
        this.setOffcanvasEditActionIsOpen(false)
        this.fetchChatbot(this.$route.params.id)
      })
    }
  }
}
</script>
