<template>
  <div
    class="card"
    v-if="!tourSteps?.finish_tour"
    style="margin-bottom: 32px"
  >
    <div class="d-flex justify-content-between align-items-center pt-4 ps-3 pe-3">
      <button
        class="btn mt-2"
        data-bs-toggle="collapse"
        data-bs-target="#collapseSteps"
        aria-expanded="false"
        aria-controls="collapseSteps"
        @click="toggleCollapse"
      >
        <i :class="chevronIcon" />
      </button>
      <div class="d-flex align-items-center">
        <p class="m-0 me-4">
          {{ $t("my_progress") }}
        </p>
        <div
          class="progress me-4"
          style="width: 228px"
        >
          <div
            class="progress-bar"
            role="progressbar"
            :style="'width: ' + progress + '%;'"
            :aria-valuenow="progress"
            aria-valuemin="0"
            aria-valuemax="100"
          />
        </div>
        <p class="m-0 me-4">
          {{ Math.round(progress) + '%' }}
        </p>
      </div>
    </div>
    <div class="card-body d-flex justify-content-between">
      <div class="d-flex flex-column justify-content-between">
        <div
          style="width: 350px;"
          v-html="$t('explore_crm')"
        />
        <div
          class="card m-0 mb-2"
          v-if="isExpanded"
        >
          <div class="help card-body d-flex justify-content-end flex-column align-items-end">
            <h4>
              {{ $t("still_have_questions") }}
            </h4>
            <button
              class="btn tx-gray"
              style="background-color: #E1E9F4; color: #677C92"
              @click="toggleIntercom"
            >
              {{ $t("access_our_help_center") }}
            </button>
          </div>
        </div>
      </div>
      <div class="w-100 ps-4">
        <div
          v-for="(step, index) in steps.slice(0,2)"
          :key="index"
          class="d-flex flex-column"
        >
          <div
            class="card m-0 mb-2"
            @click="step.onClick"
            :class="{ 'disabled-step': (!currentFunnel && step.needCurrentFunnel) || step.status}"
            type="button"
          >
            <div class="card-body d-flex justify-content-between align-items-center">
              <div class="d-flex align-items-center">
                <div
                  class="me-4 d-flex align-items-center justify-content-center bg-light text-primary fs-6 fw-medium"
                  style="border-radius: 16px; height: 24px; width: 24px; line-height: 16px;"
                >
                  {{ index + 1 }}
                </div>
                <div>
                  <h3
                    class="m-0 mb-2"
                    style="color: #373753"
                  >
                    {{ step.title }}
                  </h3>
                  <p
                    class="card-subtitle"
                    style="color: #677C92;
"
                  >
                    {{ step.content }}
                  </p>
                </div>
              </div>
              <button
                class="btn tx-blue-300"
                v-if="!step.status"
              >
                {{ step.textButton }}
                <i class="fe fe-arrow-right" />
              </button>
              <div v-else>
                <i
                  class="fa-sharp fa-solid fa-circle-check fa-xl"
                  style="color: #5CB868;"
                />
              </div>
            </div>
          </div>
        </div>
        <div
          v-for="(step, index) in steps.slice(2, steps.length)"
          :key="index"
          class=" flex-column show"
          id="collapseSteps"
        >
          <div
            class="card m-0 mb-2"
            @click="step.onClick"
            :class="{ 'disabled-step': (!currentFunnel && step.needCurrentFunnel) || step.status}"
            type="button"
          >
            <div class="card-body d-flex justify-content-between align-items-center">
              <div class="d-flex align-items-center">
                <div
                  class="me-4 d-flex align-items-center justify-content-center bg-light text-primary fs-6 fw-medium"
                  style="border-radius: 16px; height: 24px; width: 24px; line-height: 16px;"
                >
                  {{ index + 3 }}
                </div>
                <div>
                  <h3
                    class="m-0 mb-2"
                    style="color: #373753"
                  >
                    {{ step.title }}
                  </h3>
                  <p
                    class="card-subtitle"
                    style="color: #677C92;
        "
                  >
                    {{ step.content }}
                  </p>
                </div>
              </div>
              <button
                class="btn tx-blue-300"
                v-if="!step.status"
              >
                {{ step.textButton }}
                <i class="fe fe-arrow-right" />
              </button>
              <div v-else>
                <i
                  class="fa-sharp fa-solid fa-circle-check fa-xl"
                  style="color: #5CB868;"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="gradient position-absolute pe-4"
        v-if="!isExpanded"
      />
    </div>
  </div>
  <congrats-modal
    :current-funnel-id="currentFunnel?.id"
    :congrats-modal="congratsModal"
    @close="congratsModal = false"
  />
</template>

<script>
import {mapActions, mapGetters, mapMutations} from "vuex";
import CongratsModal from "@crm/pages/manager/crm-tour/components/congrats-modal.vue";

export default {
  components: {CongratsModal},
  emits: ['open-modal'],
  data() {
    return {
      isExpanded: true,
      showIntercom: false,
      congratsModal: false,
      steps: [
        { onClick: () => this.$emit('open-modal'), stepStatus: 'funnel', status: false, needCurrentFunnel: false},
        { onClick: () => this.$router.push({path:'/manager/users/new'}), stepStatus: 'new_users', status: false, needCurrentFunnel: false},
        { onClick: () => this.$router.push({path:'/manager/products-and-services'}), stepStatus: 'add_products', status: false, needCurrentFunnel: false},
        { onClick: () => this.$router.push({path:'/manager/qualifications'}), stepStatus: 'add_qualifications', status: false, needCurrentFunnel: false },
        { onClick: this.addCustomFields, stepStatus: 'add_custom_fields', status: false, needCurrentFunnel: true },
        { onClick: this.addFirstOpportunity, stepStatus: 'add_first_opportunity', status: false, needCurrentFunnel: true},
        { onClick: this.createActivity, stepStatus: 'add_first_activity', status: false, needCurrentFunnel: true},
        { onClick: this.knowCsvImport, stepStatus: 'import_contacts', status: false, needCurrentFunnel: true},
        { onClick: this.connectIntegration, stepStatus: 'know_integration', status: false, needCurrentFunnel: true}
      ]
    };
  },
  computed: {
    ...mapGetters("crm/tour", {
      tourSteps: "getTourSteps"}),
    ...mapGetters("crm/funnels", {
      currentFunnel: "getCurrentFunnel"
    }),
    progress() {
      return this.steps.filter(step => step.status).length / this.steps.length * 100;
    },
    chevronIcon() {
      return this.isExpanded ? 'fa-regular fa-chevron-up' : 'fa-regular fa-chevron-down';
    },
  },
  mounted() {

    const stepsTitle = this.$tm("crm_tour_steps_title");
    const stepsContent = this.$tm("crm_tour_steps_content");
    const stepButtonText = this.$tm("crm_tour_steps_button");
    this.steps.forEach((step, index) => {
      step.content = stepsContent[index];
      step.title = stepsTitle[index];
      step.textButton = stepButtonText[index];
    });
    if(!this.tourSteps){
      this.fetchTourSteps().then(() => {
        this.setStatus();
        if(!this.tourSteps.finish_tour) {
          const completeSteps = Object.entries(this.tourSteps).find(([key, value]) => key !== 'finish_tour' && value===false)
          if(!completeSteps){
            this.congratsModal = true;
          }
        }
      });
    } else {
      this.setStatus();
      if(!this.tourSteps.finish_tour) {
        const completeSteps = Object.entries(this.tourSteps).find(([key, value]) => key !== 'finish_tour' && value===false)
        if(!completeSteps){
          this.congratsModal = true;
        }
      }
    }
  },
  methods: {
    ...mapActions("crm/tour", ["fetchTourSteps", "updateTourSteps"]),
    ...mapMutations("crm/tour", ["setCurrentTab", "setOpenModal"]),

    toggleCollapse() {
      this.isExpanded = !this.isExpanded;
    },
    setStatus(){
      this.steps.forEach(obj => {
          obj.status = this.tourSteps[obj.stepStatus];
      });
    },

    addCustomFields(){
      this.setCurrentTab('form-inputs-tab')
      this.$router.push(`${this.currentFunnel.id}/settings`)
    },
    addFirstOpportunity(){
      this.$router.push(`${this.currentFunnel.id}/opportunity`)
    },
    createActivity(){
      this.setCurrentTab('activities')
      this.setOpenModal(true)
      this.$router.push(`${this.currentFunnel.id}`)
    },
    connectIntegration(){
      if(!this.tourSteps?.know_integration){
        this.updateTourSteps({know_integration: true})
      }
      this.setCurrentTab('integrations-tab')
      this.$router.push(`${this.currentFunnel.id}/settings`)
    },
    toggleIntercom() {
      this.showIntercom = !this.showIntercom;
      if (this.showIntercom) window.Intercom("show");
      else window.Intercom("hide");
    },
    knowCsvImport(){
      if(!this.tourSteps.import_contacts) {
        this.updateTourSteps({import_contacts: true})
      }
      this.$router.push(`${this.currentFunnel.id}/import`)
    }

  }
};
</script>

<style scoped>
.help{
  background-image: url("~@assets/img/plusinhaHelp.svg");
  background-repeat: no-repeat;
  height: 112px;
  width: 350px;
}
.progress {
  box-shadow: 0 2px 4px 0 rgba(103, 124, 146, 0.20) inset;
  background-color: #F0F4FA;
}
.progress-bar {
  border-radius: 16px;
  background: linear-gradient(90deg, #5CB868 0%, #65E775 100%);
  box-shadow: -1px -1px 1px 0 rgba(203, 255, 184, 0.30) inset;
}
.gradient {
  width:100%;
  height: 14rem;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.00) 0%, #FFF 95%);
}
.disabled-step {
  pointer-events: none;
  opacity: 0.5;
}
</style>
