<template>
  <hover-button
    ref="qualificationButton"
    @click="toggleDropdown"
    :icon="getQualificationDetails.icon"
    :keep-button-hovered="showQualifications"
    background-color="var(--bs-white)"
    :text="getQualificationDetails.text"
    :disabled="isQualificationDisabled"
    button-width="150px"
  />

  <dropdown-component
    :is-open="showQualifications"
    :button-rect="buttonRect"
    :title="getDropdownTitle"
    direction="right"
    :right-displacement="278"
  >
    <div class="overflow-auto card-qualifications-body">
      <qualification-dropdown
        @qualification-form-selected="event => (formSelected = event)"
        @hide-qualification-dropdown="toggleDropdown"
      />
    </div>
  </dropdown-component>
</template>

<script>
import { AgentStatus, CallStatus } from "@/utils";
import { mapActions, mapGetters, mapMutations } from "vuex";
import timer from "@/plugins/timer";
import HoverButton from "@dialer/agent/components/hover-button.vue";
import DropdownComponent from "@dialer/agent/components/dropdown-component.vue";
import QualificationDropdown from "@dialer/agent/components/top-bar/qualification/qualification-dropdown.vue";

export default {
  name: "QualificationComponent",
  data() {
    return {
      AgentStatus,
      showQualifications: false,
      buttonRect: null,
      formSelected: "qualification"
    };
  },

  components: {
    HoverButton,
    DropdownComponent,
    QualificationDropdown
  },

  mounted() {
    if (this.call?.status == CallStatus.CONNECTED) {
      this.toggleDropdown();
    }
  },

  computed: {
    ...mapGetters("agent/call", {
      call: "getCall",
    }),

    ...mapGetters("agent/qualification", {
      callQualificated: "getCallQualificated",
      manualCallACWQualified: "getManualCallACWQualified",
      loading: "getLoadingFlags"
    }),

    ...mapGetters("system", {
      status: "getStatus",
      isConsultHold: "isConsultHold",
      mode: "getMode",
      isManualTimeLimited: "isManualTimeLimited"
    }),

    ...mapGetters("agent/agent", {
      activeDropdownRight: "getActiveDropdownRight",
      campaign: "getCampaign"
    }),

    getDropdownTitle() {
      return this.$t(this.formSelected);
    },

    getQualificationDetails() {
      if (this.loading?.qualify || this.loading?.manualQualify) {
        return {
          text: `${this.$t("qualifying")}...`,
          icon: "fa-regular fa-clock"
        };
      }

      if (this.callQualificated || this.manualCallACWQualified) {
        return {
          text: this.$t("qualified"),
          icon: "fa-regular fa-check"
        };
      }

      return {
        text: this.$t("qualification"),
        icon: "fa-regular fa-thumbs-up"
      };
    },

    isQualificationDisabled() {
      const isLoggedAgentBeingConsulted = !this.call && this.status === AgentStatus.CONSULT_CONNECTED;

      return (
        this.loading?.qualify ||
        this.loading?.manualQualify ||
        this.callQualificated ||
        this.manualCallACWQualified ||
        this.isConsultHold ||
        isLoggedAgentBeingConsulted
      );
    }
  },

  methods: {
    ...mapActions("agent/agent", ["setActiveDropdownRight"]),
    ...mapActions("agent/qualification", ["setManualCallACWQualified"]),
    ...mapMutations("system", ["setStatus", "setTimeAsString", "setConsulting"]),
    ...mapMutations("agent/call", ["setManualCallACW", "setMailing", "setCall"]),

    toggleDropdown() {
      this.showQualifications = !this.showQualifications;
      if (this.showQualifications) {
        this.setActiveDropdownRight("QualificationComponent");
        this.calculateButtonRect();
        window.addEventListener("resize", this.calculateButtonRect);
      } else {
        window.removeEventListener("resize", this.calculateButtonRect);
      }
    },

    calculateButtonRect() {
      const rect = this.$refs.qualificationButton.$el.getBoundingClientRect();
      this.buttonRect = rect;
    }
  },

  watch: {
    activeDropdownRight(newVal) {
      if (newVal !== "QualificationComponent" && this.showQualifications) {
        this.toggleDropdown();
      }
    },

    status(newVal) {
      if(newVal && !this.callQualificated && this.call && !this.showQualifications) {
        this.toggleDropdown();
      }

      if (newVal !== AgentStatus.MANUAL_CALL_CONNECTED) {
        this.setManualCallACWQualified(false);
      }
    },

    callQualificated(callQualificated) {
      if (this.mode === "manual" && this.status === AgentStatus.ACW && callQualificated) {
        this.setStatus(AgentStatus.IDLE);
        this.setConsulting(false);
        this.setManualCallACW(null);
        this.setMailing(null);
        this.setCall(null);

        if (this.isManualTimeLimited) {
          let remaningTime = this.campaign.exit_manual_mode;
          remaningTime = remaningTime > 0 ? remaningTime : 1;
          timer.stop();
          timer.start({
            startValues: {
              seconds: remaningTime,
            },
            target: { seconds: 0 },
            countdown: true,
          });
        } else {
          this.setTimeAsString("00:00:00");
          timer.stop();
          timer.start({
            startValues: {
              seconds: 0,
            },
          });
        }
      }
    },
  },

  beforeUnmount() {
    window.removeEventListener("resize", this.calculateButtonRect);
  }
};
</script>

<style lang="scss" scoped>
.card-qualifications-body {
  max-height: calc(100vh - 150px);
  padding: 1.5rem 1rem;
}
</style>
