<template>
  <modal-component
    :center="true"
    :show="showActivityModal"
    custom-size="736px"
    @close="closeModal"
  >
    <template #content>
      <div class="modal-header">
        <h5 class="modal-title">
          {{ $t('new_activity') }}
        </h5>
        <button
          class="btn-close"
          type="button"
          @click="closeModal"
        />
      </div>
      <div class="modal-body p-0">
        <new-activity-card
          :initial-activity-tab="currentTab"
          :should-show-new-activity-text="false"
          :show-modal="showActivityModal"
          is-modal
        />
      </div>
    </template>
  </modal-component>
</template>

<script>
import ModalComponent from "@/components/modal-component.vue";
import NewActivityCard from "@crm/components/activity/form-activity/new-activity-card.vue";

export default {
  emits: ['close'],
  name: "ModalActivity",
  props: {
    showActivityModal: {
      type: Boolean,
      default: false
    },

    currentTab: {
      type: String,
      default: 'task'
    }
  },

  provide() {
    return {
      closeModal: this.closeModal
    }
  },

  methods: {
    closeModal() {
      this.$emit('close');
    }
  },

  components: {
    ModalComponent,
    NewActivityCard
  },
};
</script>
