import axios from "axios";

export default {
  fetchDetailsPartner({commit}, payload) {
    const { id } = payload;
    commit("startLoadingFlag", "fetchDetailsPartner");
    commit("setDetailsPartner", {});
    return new Promise((resolve, reject) => {
      axios
        .get(`/partners/${id}/dashboard/level`)
        .then(response => {
          commit("setDetailsPartner", response.data.data);
          resolve();
        })
        .catch(error => {
          commit('system/setErrorMessage', error.response.data, {root: true});
          reject();
        })
        .finally(() => {
          commit("stopLoadingFlag", "fetchDetailsPartner");
        });
    });
  },

  listPartners({commit, getters}, id) {
    commit("startLoadingFlag", "listPartners");
    commit("setListPartners", []);
    return new Promise((resolve, reject) => {
      axios
        .get(`/partners/${id}/dashboard/companies`,
          {
            params: {
              page: getters.getPartnerDetailsPagination.current_page
            }
          })
        .then(response => {
          commit("setListPartners", response.data.data);
          commit("setPartnerDetailsPagination", response.data.meta.pagination);
          resolve();
        })
        .catch(() => reject())
        .finally(() => {
          commit("stopLoadingFlag", "listPartners");
        });
    });
  },

  setPartnerDetailsCurrentPage({commit}, page) {
    commit("setPartnerDetailsCurrentPage", page);
  },

  listCompanyDetails({commit}, id) {
    commit("startLoadingFlag", "companyDetails");
    commit("setCompanyDetails", []);
    return new Promise((resolve, reject) => {
      axios
        .get(`/partners/company/${id}/details`)
        .then(response => {
          const subscriptions = response.data.data.subscriptions.map(subscription => ({
            ...subscription,
            product_items: subscription.product_items.data.filter((product) => product.status === 'ACTIVE')
          }))
          commit("setCompanyDetails", {
            ...response.data.data,
            subscriptions
          });
          resolve();
        })
        .catch(() => reject())
        .finally(() => {
          commit("stopLoadingFlag", "companyDetails");
        });
    });
  },

  requestWithdraw({commit}, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post("/partners/withdraw", payload)
        .then(() => {
          commit("setRequestWithdraw", payload);
          resolve();
        })
        .catch((error) => {
          reject(error.response.data.debug.message);
        })
    })
  }
};
