<template>
  <collapse-card
    id="contact"
    :title="$t('contact')"
    :errors="errors.contacts"
    :is-loading="isLoading"
    :expand="true"
  >
    <template #actionButton>
      <button
        aria-expanded="false"
        class="btn btn-link fs-6 p-0 border-0"
        data-bs-toggle="dropdown"
        type="button"
      >
        <i class="fa-regular fa-plus me-1" />
        {{ $t('add_capitalize') }}
      </button>
      <ul
        style="min-width: 128px; padding: 0.5rem"
        class="dropdown-menu dropdown-add-contact"
      >
        <li
          style="padding: 0.5rem"
          class="cursor-pointer fs-6 tx-text-200 rounded option lh-1"
          @click="addPhoneField"
        >
          <i
            style="margin-right: 0.5rem"
            class="fa-regular fa-phone"
          />
          <span> {{ $t('phone') }}</span>
        </li>
        <li
          style="padding: 0.5rem"
          class="cursor-pointer fs-6 tx-text-200 rounded option lh-1"
          @click="addEmailField"
        >
          <i
            style="margin-right: 0.5rem"
            class="fa-regular fa-envelope"
          />
          <span>{{ $t('capitalizes.email') }}</span>
        </li>
      </ul>
    </template>
    <template #content>
      <table
        style="border-collapse: separate; border-spacing: 0.5rem;"
        class="w-100"
      >
        <tr v-if="opportunity?.contacts[0]">
          <td>
            <label class="fs-6 label fw-medium">{{ $t('capitalizes.name') }}:</label>
          </td>
          <td class="w-100 ps-3">
            <div
              style="gap: 0.5rem"
              class="d-flex align-items-center w-100"
            >
              <name-channel
                class="col"
                :contact="opportunity.contacts[0]"
              />
            </div>
          </td>
        </tr>
        <tr
          v-for="(field, index) in contacts"
          :key="index"
        >
          <td v-if="field.type === 'name'">
            <label class="fs-6 label fw-medium">{{ $t('capitalizes.name') }}:</label>
          </td>
          <td
            class="w-100 ps-3"
            v-if="field.type === 'name'"
          >
            <action-input
              :is-creation="isCreation"
              v-model="contacts[index].value"
              input-type="text"
              :placeholder="$t('add_name')"
              @update:model-value="(val) => handleContacts(index, val)"
            />
          </td>
          <td v-if="field.type === 'phone'">
            <label class="fs-6 label fw-medium">{{ $t('phone') }}:</label>
          </td>
          <td
            class="w-100 ps-3"
            v-if="field.type === 'phone'"
          >
            <div
              style="gap: 0.5rem"
              class="d-flex align-items-center w-100"
            >
              <action-input
                :is-creation="isCreation"
                class="col"
                v-model="contacts[index].value"
                :placeholder="$t('add_phone')"
                enable-delete-action
                @delete-action="removeField(index)"
                mask="[
                '(##) ####-####',
                '(##) #####-####'
              ]"
                @update:model-value="(val) => handleContacts(index, val)"
              />
              <a
                href="#"
                target="_blank"
                rel="noopener noreferrer"
                class="btn btn-badge d-inline-flex justify-content-center align-items-center disabled"
              >
                <i class="fa-regular fa-phone-arrow-up-right" />
              </a>
              <a
                href="#"
                target="_blank"
                rel="noopener noreferrer"
                class="btn btn-badge d-inline-flex justify-content-center align-items-center disabled"
              >
                <i class="fa-kit fa-whatsapp" />
              </a>
            </div>
          </td>
          <td v-if="field.type === 'email'">
            <label class="fs-6 label fw-medium">{{ $t('capitalizes.email') }}:</label>
          </td>
          <td
            class="w-100 ps-3"
            v-if="field.type === 'email'"
          >
            <div
              style="gap: 0.5rem"
              class="d-flex align-items-center w-100"
            >
              <action-input
                :is-creation="isCreation"
                class="col"
                v-model="contacts[index].value"
                input-type="email"
                :placeholder="$t('add_email')"
                enable-delete-action
                @delete-action="removeField(index)"
                @update:model-value="(val) => handleContacts(index, val)"
              />
              <a
                href="#"
                target="_blank"
                rel="noopener noreferrer"
                class="btn btn-badge d-inline-flex justify-content-center align-items-center disabled"
              >
                <i class="fa-regular fa-envelope" />
              </a>
            </div>
          </td>
        </tr>
        <tr
          v-for="(phone, phoneIndex) in phoneChannels"
          :key="`phone-${phoneIndex+phoneChannels.length+phone.id}`"
        >
          <td>
            <label class="fs-6 label fw-medium">{{ $t('capitalizes.phone') }}:</label>
          </td>
          <td class="w-100 ps-3">
            <div
              style="gap: 0.5rem"
              class="d-flex align-items-center w-100"
            >
              <phone-chanel
                :key="phone.id"
                class="col"
                :contact-id="opportunity.contacts[0].id"
                :phone="phone"
              />
              <action-dropdown
                :fixed="true"
                :title="$t('select_channel_group')"
                icon="fa-kit fa-whatsapp"
                id="omni-chat"
                :blocked="!allowedInstances[0]?.instances"
              >
                <template
                  v-if="allowedInstances[0]?.instances"
                  #content
                >
                  <div
                    class="accordion"
                    id="instancesAccordion"
                  >
                    <channels-accordion-item
                      v-for="(channel , cIndex) in allowedInstances"
                      :key="`channel-${cIndex}`"
                      accordion-id="instancesAccordion"
                      :dividers="cIndex < allowedInstances.length - 1"
                      :instance="channel"
                      @select-instance="(instance) => startOmniChat(instance, phone)"
                    />
                  </div>
                </template>
              </action-dropdown>
              <action-button
                :blocked="true"
                icon="fa-regular fa-phone"
              />
            </div>
          </td>
        </tr>
        <tr
          v-for="(email, emailIndex) in emailChannels"
          :key="`email-${emailIndex+emailChannels.length+email.id}`"
        >
          <td>
            <label class="fs-6 label fw-medium">{{ $t('capitalizes.email') }}:</label>
          </td>
          <td class="w-100 ps-3">
            <div
              style="gap: 0.5rem"
              class="d-flex align-items-center w-100"
            >
              <email-channel
                :key="email.id"
                class="col"
                :contact-id="opportunity.contacts[0].id"
                :email="email"
              />

              <a
                :href="`mailto:${email.email}`"
                target="_blank"
                rel="noopener noreferrer"
                class="btn btn-badge d-inline-flex justify-content-center align-items-center"
              >
                <i class="fa-regular fa-envelope" />
              </a>
            </div>
          </td>
        </tr>
      </table>
      <div class="d-flex justify-content-end w-100">
        <app-button
          v-if="isShowOpportunity && !opportunity?.contacts.length"
          class="align-self-end btn-gray-blue"
          @click="addContact"
        >
          {{ $t('capitalizes.save') }}
        </app-button>
      </div>
    </template>
  </collapse-card>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from "vuex";
import {formatPhoneNumber} from "@/utils";
import AppButton from "@components/app-button.vue";
import NameChannel from "./name-channel.vue";
import PhoneChanel from "@crm/components/contact/contact-chanels/phone-chanel.vue";
import EmailChannel from "@crm/components/contact/contact-chanels/email-channel.vue";
import CollapseCard from "@crm/pages/opportunity/components/collapse-card.vue";
import ActionInput from "@crm/pages/opportunity/components/action-input.vue";
import ActionButton from "@crm/components/action-button.vue";
import ActionDropdown from "@crm/components/action-dropdown.vue";
import ChannelsAccordionItem from "@whatsapp/components/transfer-form/channels-accordion-item.vue";

export default {
  name: "ContactChanelsList",

  emits: ["updateContactInputs"],

  components: {
    ChannelsAccordionItem, ActionDropdown, ActionButton,
    ActionInput,
    CollapseCard,
    AppButton,
    NameChannel,
    EmailChannel,
    PhoneChanel
  },

  props: {
    isShowOpportunity: {
      default: false,
      type: Boolean
    },
    isCreation: {
      type: Boolean,
      default: false
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    currentFunnel: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      contacts: [],
      hovered: false,
      edit: false,
    };
  },

  computed: {
    ...mapGetters("crm/opportunities", {
      errors: "getErrors",
      opportunity: "getOpportunity"
    }),
    ...mapGetters("auth", {
      user: "getUser"
    }),
    ...mapGetters("whatsapp/instance", {
      allowedParams: "getAllowedParams",
      allowedInstances: "getAllowedInstances"
    }),

    emailChannels() {
      return this.opportunity?.contacts[0]?.emails;
    },

    phoneChannels() {
      return this.opportunity?.contacts[0]?.phones;
    }
  },

  created() {
    if (!this.isShowOpportunity) {
      this.setContacts();
    }
  },

  methods: {
    formatPhoneNumber,
    ...mapActions("crm/contacts", ["addEmailChannel", "addPhoneChanel"]),
    ...mapActions("crm/opportunities", ["createContactInOpportunity"]),
    ...mapActions('whatsapp/instance', ["findAllowedAgentsAndInstances", "fetchGroupChannels"]),
    ...mapMutations("whatsapp/instance", ["setAllowedParams"]),
    ...mapActions("whatsapp/chat", [
      "findChat",
    ]),
    ...mapMutations("whatsapp/chat", ["setEmptyChatContact"]),

    addPhoneField() {
      this.contacts.push({
        type: "phone",
        value: ""
      })
    },

    addEmailField() {
      this.contacts.push({
        type: "email",
        value: ""
      })
    },

    removeField(index) {
      this.contacts.splice(index, 1)
    },

    handleContacts(index, value) {
      this.$emit("updateContactInputs", this.contacts);
      if (!this.isShowOpportunity || !this.opportunity.contacts.length) {
        return
      }

      if (this.contacts[index].type === "phone") {
        this.addPhoneChanel({
          number: value,
          contact_id: this.opportunity.contacts[0].id
        }).then(() => {
          this.contacts.splice(index, 1)
        })
      }

      if (this.contacts[index].type === "email") {
        this.addEmailChannel({
          email: value,
          contact_id: this.opportunity.contacts[0].id
        }).then(() => {
          this.contacts.splice(index, 1)
        })
      }
    },

    addContact() {
      let payload = {
        name: this.contacts[0].value,
        company_id: this.user.company.id,
        contacts: this.contacts.slice(1).filter(contact => contact.value ? true : false)
      }
      this.createContactInOpportunity({
        opportunityId: this.opportunity.id,
        payload: payload
      }).then(() => {
        this.contacts = []
      })
    },

    toggleEdit(index) {
      this.edit = index
    },

    setContacts() {
      this.contacts = [
        {
          type: "name",
          value: ""
        },
        {
          type: "phone",
          value: ""
        },
        {
          type: "email",
          value: ""
        }
      ]
      this.$emit("updateContactInputs", this.contacts)
    },

    redirectToChat(contact, instance) {
      this.loading = true;
      this.findChat(`${this.contact(contact.number).number}${instance.id}`)
        .then(chat => {
          this.$router.push("/whatsapp/" + chat.id);
        })
        .catch(status => {
          if (status === 404) {
            let emptyChatContact = {number: this.contact(contact.number).number};
            emptyChatContact.instance = instance;

            this.setEmptyChatContact(emptyChatContact);

            this.$router.push("/whatsapp/empty-chat");
          }
        }).finally(() => {
        this.loading = false
      })
    },

    startOmniChat(instance, phone) {
      this.redirectToChat(phone, instance)
    },

    contact(number){
       number =
        number.length === 12
          ? number.slice(0, 4) + "9" + this.number.slice(4)
          : number;

      return { number: number }
    },
  },

  watch: {
    opportunity(item) {
      if (item && !item?.contacts?.length) {
        this.setContacts()
      }
    },

    currentFunnel: {
      handler() {
        this.fetchGroupChannels().then(() => {
          this.setAllowedParams({
            team: this.currentFunnel.team.id,
            all_company: 0
          })
          this.findAllowedAgentsAndInstances(this.allowedParams)
        })
      },
      once: true
    }
  }
};
</script>

<style lang="scss" scoped>
.label {
  color: #575778;
  white-space: nowrap;
}

.option {
  &:hover,
  &:focus,
  &:focus-visible {
    background-color: $color-gray-blue-300;
  }
}

.btn-badge {
  color: #575778;
  padding: 0.5rem;
  min-width: 32px;
  min-height: 32px;

  &:not(.disabled) {
    &:hover,
    &:focus,
    &:focus-visible {
      background-color: $color-gray-blue-300;
    }

    &:active {
      background-color: $color-blue-300;
      color: #fff;
    }
  }

  &.disabled {
    opacity: 0.4;
    pointer-events: none;
  }
}
</style>
