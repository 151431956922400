<template>
  <div
    id="kanbanColumns"
    aria-labelledby="kanbanColumnsToggle"
    class="d-flex tab-pane fade active show h-100"
    role="tabpanel"
    style="overflow-y: hidden"
  >
    <Container
      :style="`height: ${minHeight}px !important`"
      id="step-list-container"
      :drop-placeholder="{
        className: 'cards-drop-preview',
        animationDuration: '150',
        showOnTop: true,
      }"
      :get-child-payload="getColumnPayload(stepList)"
      class="d-flex gap-2"
      drag-handle-selector=".column-drag-handler"
      lock-axis="x"
      orientation="horizontal"
      @drop="onColumnDrop($event)"
    >
      <Draggable
        v-for="(step, index) in currentFunnel.steps"
        :key="index"
        :drag-not-allowed="step.order === 0"
        class="container-draggable"
      >
        <kanban-column
          :id="`panel-container-${step.id}`"
          :funnel-id="currentFunnel.id"
          :step="step"
          :total-steps="currentFunnel.steps.length"
          class="h-100"
          @new-opportunity="newOpportunity(step.id, currentFunnel.id)"
        >
          <template #body="{opportunities}">
            <Container
              :drop-placeholder="{
                className: 'drop-preview',
                animationDuration: '150',
                showOnTop: true,
              }"
              :get-child-payload="getCardPayload(step)"
              class="kanban-category w-100"
              drag-class="card-ghost"
              drop-class="card-ghost-drop"
              group-name="col"
              tabindex="0"
              @drop="onCardDrop(step, $event)"
              @scroll="scroll($event, step)"
              @drag-start="startDrag($event)"
              @drop-ready="(event) => dragCardDropReady(step, event)"
            >
              <Draggable
                v-for="(card, i) in opportunities"
                :key="i"
                :drag-not-allowed="lockDragCard || card.status !== 'active'"
              >
                <kanban-card
                  :id="`card-${card.id}`"
                  :ref="`card-${card.id}`"
                  :card="card"
                  :position="index"
                  :step-order="step.order"
                  @open-activity-modal="openActivityModal(card, step)"
                  @open-new-product-modal="openNewProductModal(card.id, step)"
                />
              </Draggable>
              <div
                v-if="!opportunitiesPagination[step.id]?.total && !opportunitiesLoading[step.id]"
                class="opportunity-empty flex-column justify-content-center align-items-center cursor-pointer"
                @click="newOpportunity(step.id, currentFunnel.id)"
              >
                <img
                  alt="Empty state das etapas"
                  src="@assets/img/empty-state-funel-steps.svg"
                >
                <p class="mb-0 tx-text-gray">
                  {{ $t('draggable_opportunity') }}
                </p>
              </div>
              <div v-if="opportunitiesLoading[step.id]">
                <card-loading
                  v-for="i in 15"
                  :key="i"
                  :height="94"
                  class="mb-3"
                />
              </div>
              <div
                id="mirror"
                ref="mirror"
                class="w-100"
                draggable="false"
              />
            </Container>
          </template>
        </kanban-column>
      </Draggable>
      <div
        v-if="isManager"
        class="d-flex flex-column col-auto new-step-container"
      >
        <div class="w-100 rounded-3 new-step-color" />

        <button
          v-if="!toggleNewColumnCard"
          ref="add-card"
          class="btn add-step p-4 d-flex justify-content-center align-items-center gap-3"
          @click="toggleNewColumnCard = true"
        >
          <i class="fa-regular fa-plus tx-text-gray" />
          <p class="mb-0 tx-text-gray fs-6">
            {{ $t('new_step_crm') }}
          </p>
        </button>
        <add-card
          v-if="toggleNewColumnCard"
          id="new-step-form"
          @cancel="closeNewStepDropdown"
          @submit="addNewStep"
        />
      </div>
    </Container>
  </div>

  <modal-activity
    :show-activity-modal="showActivityModal"
    @close="closeActivityModal"
  />

  <new-product-modal
    :fetch-products="true"
    :opportunity-id="productOpportunityId"
    :show-modal="showNewProductModal"
    @close="closeNewProductModal"
  />
</template>

<script>
import {mapActions, mapGetters, mapMutations} from "vuex";
import KanbanColumn from "./kanban-column.vue";
import KanbanCard from "./kanban-card.vue";
import AddCard from "./add-card.vue";
import {Container, Draggable} from "vue-dndrop";
import {checkScrollForPagination, elementObserverById} from "@/utils";
import CardLoading from "@components/card-loading.vue";
import ModalActivity from "@crm/components/activity/modals/modal-activity.vue";
import NewProductModal from "@crm/components/contact/products/new-product-modal.vue";

export default {
  name: "KanbanBoard",

  components: {
    NewProductModal,
    ModalActivity,
    CardLoading,
    AddCard,
    KanbanColumn,
    KanbanCard,
    Draggable,
    Container
  },

  data() {
    return {
      toggleNewColumnCard: false,
      cardElement: null,
      movingCard: null,
      showActivityModal: false,
      showNewProductModal: false,
      productOpportunityId: null,
      minHeight: null,
    }
  },

  computed: {
    ...mapGetters("crm/kanban", {
      isDraggingColumn: "getIsDraggingColumn",
      lockDragCard: "getLockDragCard",
      loadingFlags: "getLoadingFlags"
    }),
    ...mapGetters("crm/funnels", {
      currentFunnel: "getCurrentFunnel",
      selectedStep: "getSelectedStep",
    }),
    ...mapGetters("crm/opportunities", {
      opportunities: "getOpportunities",
      opportunitiesPagination: "getOpportunitiesPagination",
      opportunitiesLoading: "getOpportunitiesLoading",
    }),
    ...mapGetters("auth", {
      isManager: "isManager",
    }),

    stepList() {
      if (this.currentFunnel)
        return this.currentFunnel.steps
      return null
    },
  },

  mounted() {
    this.fetchGroupChannels();
    this.getHeight();
    window.addEventListener('resize', this.getHeight)
    this.fetchFunnelOpportunities(this.currentFunnel.steps)
    this.fetchPnS({is_active: 1});
  },

  unmounted() {
    window.removeEventListener('resize', this.getHeight)
  },

  methods: {
    checkScrollForPagination,
    ...mapActions("manager/products_and_services", ["fetchPnS",]),
    ...mapActions("crm/funnels", ["updateCurrentFunnel"]),
    ...mapActions("crm/kanban", [
      "createStep",
      "moveSteps"
    ]),
    ...mapActions("crm/opportunities", [
      "fetchFunnelOpportunities",
      "fetchStepOpportunities",
      "dropCards"
    ]),
    ...mapActions('whatsapp/instance', ["fetchGroupChannels"]),

    ...mapMutations('crm/funnels', ['setCurrentStepId', 'setSelectedStep']),
    ...mapMutations('crm/opportunities', ['setOpportunityId', 'setOpportunity']),

    addNewStep(payload) {
      if (this.loadingFlags.createStep) {
        return
      }
      this.createStep({
        ...payload,
        order: this.currentFunnel.steps.length + 1,
        funnel_id: this.currentFunnel.id
      }).then((step) => {
        this.closeNewStepDropdown()
        this.updateCurrentFunnel(step.funnel_id);
        this.fetchStepOpportunities({
          step: step,
          page: 1
        })
      })
    },

    newOpportunity(stepId, currentFunnelId) {
      this.setCurrentStepId(stepId);
      this.$router.push(`${currentFunnelId}/opportunity`)
    },

    onColumnDrop({removedIndex, addedIndex, payload}) {
      if (addedIndex !== null || removedIndex !== null) {
        if (addedIndex !== 0) {
          this.moveSteps({
            removedIndex,
            addedIndex,
            funnel_id: payload.funnel_id,
            step_id: payload.id
          });
        }
      }
    },

    getColumnPayload() {
      return (index) => {
        return this.currentFunnel.steps[index]
      }
    },

    getCardPayload(step) {
      return (index) => {
        return this.opportunities[step.id][index]
      }
    },

    startDrag(payload) {
      this.movingCard = payload
    },

    dragCardDropReady(step, {addedIndex}) {
      if (addedIndex - 1 === this.opportunities[step.id].length && this.cardElement) {
        this.cardElement.style.position = "relative"
      }
    },

    onCardDrop(targetStep, {addedIndex, removedIndex, payload}) {
      if (addedIndex !== null || removedIndex !== null) {
        const currentCard = JSON.parse(JSON.stringify(payload))
        this.dropCards({
          targetStep,
          currentCard,
          addedIndex,
          removedIndex,
        })
      }
    },

    scroll(event, step) {
      if (this.checkScrollForPagination(event)) {
        if (this.opportunitiesLoading[step.id]) {
          return
        }

        const currentPage = this.opportunitiesPagination[step.id].current_page
        const lastPage = this.opportunitiesPagination[step.id].last_page
        if (currentPage === lastPage) {
          return
        }

        this.fetchStepOpportunities({
          step: step,
          page: currentPage + 1
        })
      }
    },

    openActivityModal(card, step) {
      this.setOpportunityId(card.id);
      this.setOpportunity(card);
      this.showActivityModal = true
      this.setSelectedStep(step);
    },

    closeActivityModal() {
      this.setOpportunityId(null);
      this.setOpportunity(null);
      this.showActivityModal = false
      this.fetchStepOpportunities({
        step: this.selectedStep,
        page: this.opportunitiesPagination[this.selectedStep.id].current_page
      }).then(() => this.setSelectedStep(null));
    },

    openNewProductModal(id, step) {
      this.productOpportunityId = id;
      this.showNewProductModal = true;
      this.setSelectedStep(step);
    },

    closeNewProductModal(reloadStep = false) {
      this.showNewProductModal = false;

      if (reloadStep) {
        this.fetchStepOpportunities({
          step: this.selectedStep,
          page: this.opportunitiesPagination[this.selectedStep.id].current_page
        }).then(() => this.setSelectedStep(null));
      }
    },

    closeNewStepDropdown() {
      this.toggleNewColumnCard = false
    },

    getHeight() {
      elementObserverById('step-list-container').then((res) => {
        this.minHeight = (window.innerHeight - res.offsetTop) - 44;
      });
    }
  }
}
</script>

<style lang="scss" scoped>
.container-draggable {
  min-width: 256px;
}

#step-list-container {
  gap: 16px;
  overflow: scroll hidden;
  outline: none;
}

.card-ghost {
  transform: rotate(-5deg);
}

.kanban-category {
  padding: 0 !important;
  outline: none;

  &:hover {
    .opportunity-empty {
      display: flex;
    }
  }
}

.opportunity-empty {
  height: 146px;
  gap: 16px;
  background-image: url("~@assets/img/empty-slashed.png");
  background-size: contain;
  background-repeat: no-repeat;
  display: none;

  &:hover {
    background-image: url("~@assets/img/empty-slashed-hover.png");
  }

  p {
    font-size: 12px;
  }
}

.new-step-container {
  min-width: 256px;
  gap: 17px;
  padding: 0 8px;

  .new-step-color {
    height: 8px;
    background-color: $color-gray-blue-300;
  }

  .add-step {
    background-color: $color-gray-blue-300;

    &:hover {
      background-color: $color-gray-200;
    }
  }
}

</style>
