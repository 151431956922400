<template>
  <div class="container-fluid">
    <main-loading v-if="loadingFlags.fetchCampaignById" />
    <template v-else>
      <header-component
        :pretitle="$t('campaigns')"
        :title="campaign.name"
        redirect-link="/manager/campaign"
      >
        <template #buttons>
          <router-link
            v-if="user.role.name == 'manager'"
            class="btn btn-primary px-5"
            :to="`/manager/campaign/${campaign.id}/edit`"
            @click="amplitudeEvent"
          >
            {{ $t("configure_campaign") }}
          </router-link>
        </template>
      </header-component>
      <campaign-metrics :campaign-id="campaign.id" />
      <mailing-list
        v-if="isViewListAllowed()"
        :open-modal="this.openModalForm"
      />

      <div class="row">
        <agents-status
          @bolean-has-users-online="fillHasUsersOnline"
          :campaign="campaign"
        />
        <calls-status :campaign="campaign" />
      </div>
    </template>

    <div
      v-if="showToasts"
      class="toast align-items-center bottom-0 start-50 translate-middle-x border-0 me-0 my-2"
      data-bs-delay="600000"
      style="
            position: fixed;
            color: #444444;
            background: #FFCA33 !important;
            bottom: 2.5rem !important;
            width: 50%;
            "
      ref="toastAlert"
      role="alert"
    >
      <div class="d-flex me-0">
        <div
          class="toast-body me-0"
        >
          <div v-if="toastAmount <= 1">
            <div v-if="toastConditions.outOfSchedule">
              <span class="fe fe-clock" />
              {{ $t("time_toast_campaign") }}
            </div>
            <div v-if="user.company && parseFloat(user.company.balance) + parseFloat(user.company.credit_limit) <= 0">
              <span class="fe fe-dollar-sign" />
              {{ $t("credit_toast_campaign") }}
            </div>
            <div v-if="toastConditions.incompleteOrWithoutMailings">
              <span class="fe fe-check" />
              {{ $t("completed_toast_campaign") }}
            </div>
            <div v-if="toastConditions.pausedCampaign">
              <span class="fe fe-pause" />
              {{ $t("paused_toast_campaign") }}, <router-link :to="`/manager/campaign/${campaign.id}/edit`">
                {{ $t("click_here_reactive_toast_campaign") }}
              </router-link>
            </div>
            <div v-if="toastConditions.withoutMailings">
              <span class="fe fe-alert-triangle" />
              {{ $t("no_list_found_toast_campaign") }}
              <a
                href="#"
                @click="openModalForm = !openModalForm"
              >{{ $t("click_here_add_toast_campaign") }}</a>
            </div>
            <div v-if="toastConditions.offlineUsers">
              <span class="fe fe-power" />
              {{ $t("no_online_agent") }}
            </div>
          </div>
          <div
            v-if="toastAmount > 1"
            class="d-flex justify-content-between align-items-center ms-3"
          >
            <span class="fe fe-alert-triangle me-3" />
            <div>
              {{ $t("campaign_not_dialing") }}
              <ul class="mb-0 pb-0">
                <li

                  v-if="toastConditions.outOfSchedule"
                >
                  {{ $t("time_toast_campaign") }}
                </li>
                <li v-if="user.company && parseFloat(user.company.balance) + parseFloat(user.company.credit_limit) <= 0">
                  {{ $t("credit_toast_campaign") }}
                </li>
                <li

                  v-if="toastConditions.incompleteOrWithoutMailings"
                >
                  {{ $t("completed_toast_campaign") }}
                </li>
                <li

                  v-if="toastConditions.pausedCampaign"
                >
                  {{ $t("paused_toast_campaign") }}, <router-link
                    style="color: #444444; text-decoration: underline"
                    :to="`/manager/campaign/${campaign.id}/edit`"
                  >
                    <span>{{ $t("click_here_reactive_toast_campaign") }}</span>
                  </router-link>
                </li>
                <li

                  v-if="toastConditions.withoutMailings"
                >
                  {{ $t("no_list_found_campaign") }} <a
                    href="#"
                    style="color: #444444; text-decoration: underline"
                    @click="openModalForm = !openModalForm"
                  >{{ $t("click_here_add_toast_campaign") }}</a>
                </li>
                <li
                  v-if="toastConditions.offlineUsers"
                >
                  {{ $t("no_online_agent") }}
                </li>
              </ul>
            </div>
          </div>
        </div>
        <button
          type="button"
          data-test="manager_edit_campaign_button_close_toast"
          class="btn-close me-2 m-auto me-0"
          data-bs-dismiss="toast"
          aria-label="Close"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { Toast } from "bootstrap";
import moment from "moment";
import { mapActions, mapGetters, mapMutations } from "vuex";
import MainLoading from "@/components/main-loading.vue";
import MailingList from "./components/mailings-list.vue";
import CampaignMetrics from "./components/campaign-metrics.vue";
import AgentsStatus from "./components/agents-status.vue";
import CallsStatus from "./components/calls-status.vue";
import HeaderComponent from "@/components/header-component.vue";
import { AgentStatus } from "@/utils";

export default {
  data() {
    return {
      id: "",
      toastAlert: null,
      hasUsersOnline: false,
      hasIncompleteOr0WeightMailings: false,
      isOutSchedule: false,

      openModalForm: false,

      toastAmount: 0,
      toastConditions: {
        outOfSchedule: null,
        withoutBalance: null,
        incompleteOrWithoutMailings: null,
        pausedCampaign: null,
        withoutMailings: null,
        offlineUsers: null,
      },
      showToasts: false
    };
  },

  beforeMount() {
    let promises = [];

    promises.push(this.fetchAgentsByCampaignId(this.$route.params.id));
    promises.push(this.fetchCampaignById(this.$route.params.id));

    if(this.isViewListAllowed()){
      promises.push(this.findListOfLists({ id: this.$route.params.id }))
    }

    Promise.all(promises).then(() => {
      this.isOutOfSchedule();
      this.hasIncompleteMailingsOrWithoutWeight();
      this.calcHowManyToasts();
    });

    this.id = this.$route.params.id;
  },

  beforeUnmount() {
    this.clearCampaignsData();
  },

  components: {
    MainLoading,
    MailingList,
    AgentsStatus,
    CallsStatus,
    CampaignMetrics,
    HeaderComponent
  },

  computed: {
    ...mapGetters("manager/campaign", {
      campaign: "getCampaign",
      loadingFlags: "getLoadingFlags",
    }),

    ...mapGetters("manager/list", {
      listOfList: "getList",
      loadingFlagsList: "getLoadingFlags"
    }),

    ...mapGetters("manager/campaign/agents", {
      agents: "getAgents",
    }),

    ...mapGetters("auth", {
      user: "getUser"
    }),
  },

  methods: {
    ...mapActions("manager/campaign", [
      "fetchCampaignById",
      "clearCampaignsData",
    ]),

    ...mapActions("manager/campaign/agents", [
      "fetchAgentsByCampaignId",
    ]),

    ...mapActions("manager/list", ["findListOfLists"]),
    ...mapMutations("auth", ["trackEvents"]),

    amplitudeEvent() {
      this.trackEvents(`[Manager] Edit campaign`)
    },

    fillHasUsersOnline(payload){
      this.hasUsersOnline = payload
    },

    isOutOfSchedule() {
      const now = moment();
      const startTime = moment(this.campaign.start_time, "HH:mm");
      const endTime = moment(this.campaign.end_time, "HH:mm");

      this.isOutSchedule = !now.isBetween(startTime, endTime);
    },

    hasIncompleteMailingsOrWithoutWeight() {
      this.hasIncompleteOr0WeightMailings = this.listOfList.every(el => el.weight === 0 || el.percent < 100);
    },

    calcHowManyToasts() {
      this.toastConditions.outOfSchedule = this.isOutSchedule
      this.toastConditions.withoutBalance = parseFloat(this.user.company.balance) + parseFloat(this.user.company.credit_limit) <= 0
      this.toastConditions.pausedCampaign = this.campaign.paused == true
      this.toastConditions.offlineUsers = !Object.values(this.agents).some((el) => el.status !== AgentStatus.OFFLINE);
      if (this.isViewListAllowed()) {
        this.toastConditions.incompleteOrWithoutMailings = this.hasIncompleteOr0WeightMailings
        this.toastConditions.withoutMailings = this.listOfList.length == 0
      }

      for (const el in this.toastConditions) {
        if (this.toastConditions[el]) this.toastAmount++
      }
      if(this.toastAmount > 0) {
        this.showToasts = true;

        this.$nextTick(() => {
          this.toastAlert = new Toast(this.$refs["toastAlert"])
          this.toastAlert.show()
        })
      }
    },

    answered(answeredPercentage, answered) {
      if (answeredPercentage === 0 && answered === 0) return 0;
      return 100;
    },

    isViewListAllowed() {
      let containViewListPermission = this.user.permissions.find((p) => p.name == 'view.lists.in.campaign');
      return containViewListPermission || this.user.role.name == 'manager'
    }
  },
};
</script>
<style lang="scss" scoped>
.container-fluid {
  background: linear-gradient(180deg, #E4ECF7 0%,#E3ECF7 7.06%,
  #E4ECF6 14.74%, #EFF3F9 22.09%, #F7F8FB 28.11%,
  #F9FAFC 34.29%) no-repeat;
}
.tooltip-inner{
  background: #FFCA33 !important;
}
</style>
