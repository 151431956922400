<template>
  <div
    class="tab-pane fade"
    id="tab-omni"
    role="tabpanel"
    aria-labelledby="list-tab-omni"
  >
    <warmup-insights-ai-chats />
  </div>
</template>

<script>
import WarmupInsightsAiChats from "@dialer/manager/pages/insights-ai/dashboard/tabs/warmups/warmup-insights-ai-chats.vue";

export default {
  name: "TabOmni",
  components: {WarmupInsightsAiChats}
}
</script>