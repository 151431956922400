import { Portuguese } from "flatpickr/dist/l10n/pt.js";
import { Spanish } from "flatpickr/dist/l10n/es.js";
import { mapGetters } from "vuex";

export default {
  created() {
    this.config = this.mergeConfig(this.getLocale(this.language), this.config);
  },
  methods: {
    getLocale(language) {
      return {
        locale: {
          "pt": Portuguese,
          "es": Spanish,
        }[language],
      };
    },
    mergeConfig(defaultConfig, localConfig) {
      return { ...defaultConfig, ...localConfig };
    },
  },
  computed: {
    ...mapGetters("auth", {
      language: "getLanguage",
    }),
  },
};
