<template>
  <div
    :id="messageId"
    class="ballon-message clearfix card-size p-0"
    :class="{
      'not-from-me': !fromMe,
      'from-me justify-content-end': fromMe,
      'blink': messageIdFound && messageIdFound === messageId,
      error: error,
    }"
    style="max-width: 50%"
  >
    <div
      v-if="showMsgChannel && !currentChat.is_group"
      class="d-flex align-items-center tx-text-gray gap-2 mb-2"
      :class="{'justify-content-end': fromMe, 'justify-content-start': !fromMe}"
    >
      <span
        :class="messageChannel.icon"
      />
      <span>{{ messageChannel.channel }}</span>
    </div>
    <span
      v-if="messageData.from_chatbot"
      class="fal fa-robot fw-medium tx-text-gray"
    />
    <div>
      <div
        class="error-options float-end d-flex align-items-center"
        v-if="fromMe && error"
      >
        <span
          class="fe fe-refresh-cw"
          v-if="showResend"
          @click="$emit('resend', messageData)"
          @mouseout="showResend = false"
          data-test="resendMessage-button"
        />
        <span
          @mouseenter="showResend = true"
          class="fe fe-alert-circle"
          v-else
        />
      </div>
      <div
        class="b-content b7 d-flex flex-column"
        :class="{'is-sticker': isSticker}"
        :style="!deletedHover && isDeleted ? 'opacity: 50%' : 'opacity: 100%'"
        @mouseover="deletedHover = true"
        @mouseleave="deletedHover = false"
      >
        <span
          v-if="!currentChat?.is_group"
          :style="`font-size: 0.75rem; margin-bottom: 0.25rem; color: ${color}`"
          class="fw-medium"
        >{{ getRoleName }}</span>
        <span
          v-if="showDropdown"
          data-test="messageOptions-dropdown"
          class="fe fe-chevron-down ballon-menu text-end px-1"
          :class="{'remove-chevron-sticker': isSticker}"
          data-bs-toggle="dropdown"
        />
        <ul class="dropdown-menu p-3 ballon-options">
          <template
            v-for="item in menuItems"
            :key="item.label"
          >
            <li v-if="item.show">
              <div
                :class="['dropdown-item fs-5 px-3', item.class, { disabled: item.disabled }]"
                @click="handleClick(item)"
                :data-test="item.dataTest"
              >
                {{ item.label }}
              </div>
            </li>
          </template>
        </ul>
        <div
          v-if="isDeleted"
          class="fs-6"
        >
          <span class="fe fe-trash" />
          {{ $t('message_deleted') }}
        </div>
        <div
          v-else-if="isExternal"
          class="fs-6"
        >
          <span class="fe fe-send" />
          {{ $t('sent_external_device') }}
        </div>
        <div
          v-else-if="isForwarded"
          class="fs-6"
        >
          <span class="fe fe-corner-up-right" />
          {{ $t('forwarded') }}
        </div>
        <div
          v-if="currentChat?.is_group"
          class="name b5"
          style="margin-bottom: 4px; font-size: 0.75rem"
          :style="'color:' + color"
        >
          {{ name }}
        </div>

        <slot
          name="quote"
          :message="messageData"
        />

        <slot
          name="message"
          :message="messageData"
        />
        <div
          v-if="!isSticker && messageData.type == 'chat'"
          class="chat-bottom"
        >
          {{ formatedTime }}
          <span
            class="ms-1 fw-bold fe fe-clock"
            v-if="fromMe && !ack"
          />
          <span
            class="ms-1 fw-bold fe fe-check"
            v-else-if="fromMe && ack === 'server' && !isExternal"
          />
          <img
            v-else-if="(fromMe && ack === 'device') || isExternal"
            src="@assets/img/device.svg"
            alt="device"
            class="ms-1"
          >
          <img
            v-else-if="fromMe && ack == 'read' || ack == 'played'"
            src="@assets/img/read.svg"
            alt="read"
            class="ms-1"
          >
        </div>
        <div
          v-if="!isSticker && messageData.type != 'chat'"
          :class="!messageData.body && messageData.type == 'image' ? 'bottom-no-caption' : 'bottom'"
        >
          {{ formatedTime }}
          <span
            v-if="fromMe && !ack"
            class="ms-1 fw-bold fe fe-clock"
          />
          <span
            v-else-if="fromMe && ack == 'server'"
            class="ms-1 fw-bold fe fe-check"
          />
          <img
            v-else-if="fromMe && ack == 'device'"
            src="@assets/img/device.svg"
            alt="device"
            class="ms-1"
          >
          <img
            v-else-if="fromMe && ack == 'read' || ack == 'played'"
            src="@assets/img/read.svg"
            alt="read"
            class="ms-1"
          >
        </div>
        <div v-if="isSticker">
          <div class="time-sticker w-auto d-block">
            {{ formatedTime }}
          </div>
        </div>

        <span
          v-if="['audio', 'ptt'].includes(messageData.type) && messageData.audio_transcription"
          class="audio-transcription-bottom"
        >
          <div
            class="card-body py-2 px-2 mb-3 rounded-2"
            style="background-color: #F0F4FA;"
          >
            <span
              class="span-limit"
              style="width: 340px"
            >
              {{ transcriptionAudio }}
              <a
                v-if="this.messageData.audio_transcription.length >= 85"
                href="#"
                @click="showMoreOrSeeLessTranscription"
                class="fw-bold"
              >
                {{ showFullTranscription ? $t('see_less') : $t('see_more_capitalize') }}
              </a>
            </span>
          </div>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import {mapGetters} from "vuex";
import {Utils} from "@/utils";

export default {
  emits: ["resend", "download", "quote", "openModal", "delete", "hover"],

  props: {
    messageData: { required: true, type: Object },
    currentChat: { required: true, type: Object, },
    time: { default: null, type: Number },
    messageId: { required: true, type: String },
    name: { default: "", type: String },
    color: { default: "", type: String },
    ack: { default: "", type: String },
    fromMe: { default: true, type: Boolean },
    error: { default: false, type: Boolean },
    isForwarded: { default: false, type: Boolean },
    isExternal: { default: false, type: Boolean },
    isDeleted: { default: false, type: Boolean },
    isSticker: { default: false, type: Boolean },
    previousMsg:{ type: Object, default: () => {} },
    lockedSendMessage:{ type: Boolean, default: false }
  },

  data() {
    return {
      showResend: false,
      deletedHover: false,
      ackEnabledOptions: ["server", "device", "read", "played"],
      seedMoreOrSeeLessTranscription: " Ver mais",
      showFullTranscription: false,
      maxWidth: 360,
    };
  },

  computed: {
    ...mapGetters("auth", {
      user: "getUser",
      isManager: "isManager",
      isAgent: "isAgent"
    }),
    ...mapGetters("whatsapp/chat", {
      messageFound: "getMessageFound",
    }),

    getRoleName() {
      if(!this.messageData?.agent) {
        return  this.messageData.message_from;
      }

      if(this.messageData?.agent?.role?.data?.name) {
        return  `${this.$t(`capitalizes.${this.messageData?.agent?.role?.data?.name}`)} ${this.messageData.agent.name}`
      }

      return  `${this.$t(`capitalizes.${this.user.role.name}`)} ${this.user.name}`
    },

    showDropdown() {
      return this.menuItems.some(item => item.show) && !this.isDeleted && !this.currentChat.isReadOnly;
    },
    menuItems() {
      return [
        {
          label: this.$t('reply_message'),
          dataTest: 'replyMessage-dropdownItem',
          class: '',
          show: this.messageData?.instance?.type !== 'waba',
          disabled: (!this.fromMe && this.ackEnabledOptions.includes(this.ack)) || !this.messageId,
          onClick: () => this.$emit('quote', this.messageId),
        },
        {
          label: this.$t('forward_message'),
          dataTest: 'forwardMessage-dropdownItem',
          class: '',
          show: this.messageData?.instance?.type !== 'waba',
          disabled: (!this.fromMe && this.ackEnabledOptions.includes(this.ack)) || !this.messageId,
          onClick: () => this.$emit('openModal'),
        },
        {
          label: this.$t('delete_message'),
          dataTest: 'deleteMessage-dropdownItem',
          class: '',
          show: this.fromMe && this.messageData.instance && this.messageData.instance.type !== 'waba',
          disabled: this.disableDeleteMessageOption || !this.messageId,
          onClick: () => this.deleteMessage(this.messageData),
        },
        {
          label: this.$t('download')+ ' ' + (this.messageData.type === 'ptt' ? this.$t('audio') : this.$t('file')),
          dataTest: 'downloadFile-button',
          class: '',
          show: ['ptt', 'document', 'audio', 'video', 'image', 'voice'].includes(this.messageData.type),
          disabled: !this.messageData.media,
          onClick: () => this.$emit('download', this.messageData.media),
        },
      ];
    },

    transcriptionAudio() {
      if(this.showFullTranscription || this.messageData.audio_transcription.length <= 85){
        return this.messageData.audio_transcription;
      }
      return this.messageData.audio_transcription.substring(0, 85) + '...';
    },

    formatedTime() {
      return moment.unix(this.time).format("HH:mm");
    },

    messageIdFound () {
      if (!this.messageFound) return
      return this.messageFound.id || this.messageFound._id
    },

    disableDeleteMessageOption() {
      return (
          !this.ackEnabledOptions.includes(this.ack) ||
          this.dateDifferenceSoFar(this.time) > 48
      );
    },

    showMsgChannel(){
      if (!this.previousMsg){
        return true
      } else {
        if(this.previousMsg.type && ['transfer', 'internal-message', 'notification-message', 'snooze-message', 'template'].includes(this.previousMsg.type)){
          return true
        }
        if(this.previousMsg.fromMe !== this.fromMe){
          return true
        }
        return this.previousMsg?.instance?.type !== this.messageData?.instance?.type;
      }

    },

    messageChannel (){
      if(this.messageData.instance && this.messageData.instance.type === 'waba'){
        return {channel: "WABA", icon: "fa-kit fa-waba"}
      }

      if (this.messageData.instance && this.messageData.instance.type === 'messenger'){
        return {channel: "Messenger", icon: "fa-brands fa-facebook-messenger"}
      }

      return {channel: "WhatsApp 3C+", icon: "fa-kit fa-whatsapp"}
    },
  },

  watch: {
    deletedHover (value) {
      this.$emit('hover', value ? this.messageId : false)
    },
  },

  methods: {
    showMoreOrSeeLessTranscription() {
      this.seedMoreOrSeeLessTranscription = this.seedMoreOrSeeLessTranscription === ` ${this.$t('see_more_capitalize')}` ? ` ${this.$t('see_less')}` : ` ${this.$t('see_more_capitalize')}`;
      this.showFullTranscription = !this.showFullTranscription;
    },
    handleClick(item) {
      if (!item.disabled) {
        item.onClick();
      }
    },
    dateDifferenceSoFar: Utils.dateDifferenceSoFar,

    openModal(item) {
      this.$refs.forwardedModal.open(item);
    },
    deleteMessage (messageData) {
      this.$emit('delete', messageData)
      this.deletedHover = true
    },
  },
};
</script>

<style lang="scss" scoped>
.span-limit {
  display: block;
  word-wrap: break-word;
  white-space: pre-line;
}

.card-size {
  &.from-me {
    margin-left: auto;
  }
}
.ballon-message {
  color: $color-text-solid-black !important;
  margin-top: 4px !important;
  &.from-me + .not-from-me,
  &.not-from-me + .from-me,
  &.from-me + .whatsapp-note,
  &.not-from-me + .whatsapp-note {
    margin-top: 4px;
    .name + .message-text {
      padding-top: 5px !important;
    }
  }
  &.from-me + .from-me,
  &.not-from-me + .not-from-me {
    margin-top: 4px !important;
    .name {
      display: none;
    }
  }
  &.error {
    .b-content {
      max-width: calc(100% - 30px) !important;
    }
    .error-options {
      width: 30px;
      text-align: center;
      height: 100%;
      i,
      .fe {
        &.fe-alert-circle {
          color: $color-red-200;
        }
        &.fe-refresh-cw {
          color: $color-blue-300;
        }
        margin: auto !important;
        cursor: pointer;
      }
    }
  }
  .ballon-menu {
    position: absolute;
    top: calc(#{$ballon-padding} - 8px);
    right: calc(#{$ballon-padding} - 2px);
    cursor: pointer;
    opacity: 0;
    &:hover,
    &.show {
      opacity: 1;
    }
  }

  .ballon-options {
    padding: $whatsapp-spacing-1 !important;
    border-radius: $whatsapp-border-radius !important;
    .dropdown-item {
      padding: $whatsapp-spacing-1 !important;
      margin: $whatsapp-spacing-0 0;
      display: block;
      border-radius: $whatsapp-border-radius !important;
      cursor: pointer;
      &:hover {
        background-color: $color-gray-100 !important;
      }
    }
  }
  &.whatsapp-image + .whatsapp-image {
    .b-content {
      padding: 3px !important;
      padding-bottom: 3px !important;
    }
  }
  .is-sticker{
    border: none !important;
    background-color: transparent !important;
  }
  .b-content {
    max-width: 564px;
    word-wrap: break-word;
    white-space: pre-line;
    display: inline-block;
    padding: 6px;
    position: relative;
    font-weight: 400;
    font-size: 14px;
    .caption{
      margin-left: 3px;
    }
    .name{
      font-weight: 500 !important;
    }
  }
  .b-content:has(.message-text, .audio) {
    padding: $ballon-padding !important;
    padding-bottom: $ballon-padding-bottom !important;
  }
  .b-content:has(.message-contact) {
    padding: 14px !important;
  }
  .b-content:has(.file-message) {
    padding: 6px !important;
  }
  &.from-me {
    .b-content:has(.file-message) {
      width: 370px !important;
    }
  }
  &.not-from-me {
    .b-content:has(.file-message) {
      width: 330px !important;
    }
  }
  .time-sticker{
    background: $color-text-light;
    margin-top: 5px;
    right: 12px;
    bottom: 6px;
    font-size: 10px;
    padding: 6px;
    line-height: 10px;
    text-align: right;
    border-radius: $whatsapp-border-radius $whatsapp-border-radius $whatsapp-border-radius 0px !important;
    color: $color-text-100;

    width: fit-content !important;
    i {
      color: white;
      color: $color-text-100;
      &.read {
        color: $color-blue-300;
      }
    }
  }
  .bottom {
    position: absolute;
    right: 12px;
    bottom: 6px;
    font-size: 10px;
    line-height: 10px;
    text-align: right;
    color: $color-text-100;
    .fe {
      color: #888888;
    }
  }
  .audio-transcription-bottom {
    left: 12px;
    bottom: 6px;
    text-align: left;
    color: $color-text-solid-black;
    max-width: 360px;
    background: #F0F4FA;
  }
  .bottom-no-caption {
    position: absolute;
    right: 12px;
    bottom: 6px;
    font-size: 10px;
    line-height: 10px;
    text-align: right;
    color: $color-gray-100;
    .fe {
      color: #888888;
    }
  }
  &.from-me {
    .b-content {
      background-color: #D2DDEC;
      float: right !important;
      border: 1px solid #BBC6D5;
      border-radius: $ballon-radius $ballon-radius 0px $ballon-radius;
    }
  }
  &.not-from-me {
    .b-content {
      background-color: #FFFFFF;
      float: left !important;
      border: 1px solid #E1E9F4;
      border-radius: $ballon-radius $ballon-radius $ballon-radius 0px;
    }
  }
  .ballon-menu {
    position: absolute;
    width: 100%;
    max-width: 200px;
    height: 22px;
    right: 0;
    top: 0;
    border-radius:0px $whatsapp-border-radius 0px 0px;
    cursor: pointer;
    opacity: 0;
    &:hover, &.show {
      background: linear-gradient(195deg, rgba(110, 132, 163, 0.285) 8%, rgba(110, 132, 163, 0) 40%);
      opacity: 1;
    }
  }
  .remove-chevron-sticker{
    background: transparentize(#EAF1FB, 0.1) !important;
    border-radius: 50px !important;
    padding-left: 0px;
    min-width: unset;
    text-align: center;
  }
  .ballon-options {
    .dropdown-item {
      display: block;
      border-radius: $whatsapp-border-radius !important;
      cursor: pointer;
      &.dropdown-item-delete {
        &:hover {
          background-color:$color-hover-danger !important;
        }
      }
      &:hover {
        background-color: $color-gray-100 !important;
      }
    }
  }
}
.is-deleted {
  opacity: 100% !important;
}

.chat-bottom {
  position: absolute;
  padding-left: 8px;
  font-size: 10px;
  line-height: 10px;
  right: 6px;
  bottom: 6px;
  color: $color-text-100;
  .fe {
    color: #888888;
  }
}

.blink {
  animation: blink 3s ease-in-out;
}

@keyframes blink {
  50% {
    opacity: 0;
  }
}
</style>

