import { track } from "@/utils";

export default {
  startLoading: (state, flag) => (state.loadingFlags[flag] = true),
  stopLoading: (state, flag) => (state.loadingFlags[flag] = false),

  setLogoutCause: (state, cause) => (state.logoutCause = cause),
  setErrors(state, errors) {
    state.errors = errors;
  },
  setErrorMessage(state, errorMessage) {
    state.errorMessage = errorMessage;
  },
  clearErrors(state) {
    state.errors = {};
    state.errorMessage = "";
  },
  setUser(state, user) {
    state.user = user;
  },
  setUserProperty(state, { property, value }) {
    state.user[property] = value;
  },
  setIsRecentlyLogged(state) {
    state.isRecentlyLogged = true;
  },

  setNextDataUpdate(state, nextDataUpdate) {
    state.nextDataUpdate = nextDataUpdate;
  },

  endAllRealTimeSessions: () => console.log("Ending all real time sessions..."),
  setImpersonateUserBackup: (state, impersonateUserBackup) => (state.impersonateUserBackup = impersonateUserBackup),
  setImpersonate: (state, impersonate) => (state.impersonate = impersonate),
  refreshUser(state, user) {
    user.api_token = state.user.api_token;
    state.user = user;
  },
  setIncludes: (state, includes) => (state.includes = includes),
  setAllStates: (state, states) => (state.allStates = states),
  setCurrentCompany: (state, currentCompany) => (state.currentCompany = currentCompany),
  setUserLogin: (state, userLogin) => (state.userLogin = userLogin),

  trackEvents: (state, eventName) => {
    let user = state.user

    if (!user) return

    let role = user.role.name.charAt(0).toUpperCase() + user.role.name.slice(1);

    let company = null;

    if (role === "Admin") {
      company = state.currentCompany;
    } else {
      company = user.company;
    }

    let plan;

    switch (company.plan) {
      case 1:
        plan = "Ilimitado";
        break;
      case 2:
        plan = "Minutagem";
        break;
      case 3:
        plan = "Telefonia Externa";
        break;
      default:
        plan = "Unknown";
        break;
    }

    track(eventName, company.domain, company.id, {
      "event_name": eventName,
      "event_data": "any info",
      "company": {
        "id": company.id,
        "plan": plan,
        "name": company.name,
        "domain": company.domain,
        "licenses": {
          "ivr": company.ura_licenses ?? 0,
          "webphone": company.webphone_licenses ?? 0,
          "whatsapp_user": company.whatsapp_max_concurrent_logins ?? 0,
          "whatsapp_connections": company.whatsapp_licenses ?? 0,
          "dialer_agents": company.max_agents_login ?? 0
        }
      },
      "user": {
        "id": user.id,
        "name": user.name,
        "extension_number": user.extension_number ?? 0,
        "email": user.email,
        "role": role
      }
    });
  },

  setTwoFa: (state, twoFa) => (state.twoFa = twoFa),
  setPaymentModal(state, status) {
    state.showPaymentModal = status;
  },
};
