import axios from "axios";

export default {

  /**
   *
   * @param {*} param0
   */
  stopAgentsRealTimeUpdate({ commit }) {
    commit("setRealTimeCampaignContext", null);
    commit("resetAgentsStatusesCount");
    commit("setAgents", []);
  },

  /**
   *
   * @param {*} param0
   * @param {*} payload
   */
  startAgentsRealTimeUpdate({ commit }, payload) {
    commit("setRealTimeCampaignContext", payload.campaign);
    commit("resetAgentsStatusesCount");
    commit("setUpdateAgentsRealTimeActive", false);
    commit("setAgents", []);

    if(payload.campaign.id != undefined) {
      commit("startLoadingFlag", "startAgentsRealTimeUpdate");

      return new Promise((resolve, reject) => {
        axios.get('/campaigns/' + payload.campaign.id + '/agents/status')
          .then((response) => {
            commit("setAgents", response.data.data);
            commit("setUpdateAgentsRealTimeActive", true);
            resolve();
          }).catch(() => {
            commit("setRealTimeCampaignContext", null);
            reject();
          }).finally(() => {
            commit("stopLoadingFlag", "startAgentsRealTimeUpdate");
          });
      });
    }
  },

  /**
   *
   * @param {*} param0
   * @param {*} payload
   * @returns
   */
  startAllAgentsRealTimeUpdate({ commit }, payload) {
    commit("setRealTimeCampaignContext", payload.campaign);
    commit("resetAgentsStatusesCount");
    commit("setUpdateAgentsRealTimeActive", false);
    commit("setAgents", []);

      return new Promise((resolve, reject) => {
        axios.get("/agents/status/metrics/total", {
          params: {
            start_date: payload.start_date,
            end_date: payload.end_date,
            per_page: -1,
          }
        })
          .then((response) => {
            commit("setAgents", response.data.data);
            commit("setUpdateAgentsRealTimeActive", true);

            resolve();
          }).catch(() => {
            commit("setRealTimeCampaignContext", null);
            reject();
          });
      });
  },


  /**
   *
   * @param {*} param0
   * @param {*} payload
   */

  //find Calls Working From Currente Campaign
  stopUpdateRealTimeCalls({ commit }) {
    commit("setUpdateCallsInRealTimeActive", false);
    commit("setRealTimeCampaignContext", null);
    commit("resetCallsWorkingStatuses");
    commit("clearLastFinalizedCalls");
    commit("clearCallsWorkingStatuses");
  },

  /**
   *
   * @param {*} param
   * @returns
   */
  startUpdateRealTimeCalls({ commit }, payload) {
    commit("setUpdateCallsInRealTimeActive", false);
    commit("setRealTimeCampaignContext", payload.campaign);
    commit("resetCallsWorkingStatuses");
    commit("clearLastFinalizedCalls");

    return new Promise((resolve, reject) => {
      axios.get('/campaigns/' + payload.campaign.id + '/calls')
        .then((response) => {
          commit("setCallsWorkingFromCurrenteCampaign", { data: response.data.data });
          commit("setUpdateCallsInRealTimeActive", true);
          commit("setMailings", response.data.data)
          resolve();
        }).catch(() => {
          commit("setUpdateCallsInRealTimeActive", false);
          commit("setRealTimeCampaignContext", null);
          reject();
        })
    });
  },

  /**
   *
   * @param {*} param0
   * @param {*} payload
   * @returns
   */
  startUpdateAllRealTimeCalls({ commit }, payload) {
    commit("setUpdateCallsInRealTimeActive", false);
    commit("setRealTimeCampaignContext", payload.campaign);
    commit("resetCallsWorkingStatuses");
    commit("clearLastFinalizedCalls");

    return new Promise((resolve, reject) => {
      axios.get("/company/calls")
        .then((response) => {


          commit("setCallsWorkingFromCurrenteCampaign", { data: response.data.data });
          commit("setUpdateCallsInRealTimeActive", true);

          resolve();
        })
        .catch(() => {
          reject();
        });
    });
  }
}
