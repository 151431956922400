<template>
  <div
    class="modal"
    ref="temporaryUnlockModal"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-lg modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">
            {{ isAlreadyTriedTemporaryUnlock ? $t("we_need_your_attention") : $t("pending_payment") }}
          </h4>
        </div>
        <div
          class="col"
          style="padding: 24px 40px;"
        >
          <div
            v-if="loadingFlags.managerPermissionToUnlockCompany"
            class="d-flex align-items-center my-6"
          >
            <div
              class="mx-auto spinner-border text-secondary "
              role="status"
            >
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>
          <div v-if="stepView === 0 && !isAlreadyTriedTemporaryUnlock && !loadingFlags.managerPermissionToUnlockCompany">
            <div class="text-center mb-4">
              <span class="fs-2 fw-bold">
                {{ $t("payment_not_identified") }}
              </span>
            </div>
            <img
              v-if="!isAlreadyTriedTemporaryUnlock"
              src="@/assets/img/sad-pluszinha.svg"
              class="img-fluid mx-auto d-block pluszinha-sad mb-4"
              alt=""
            >
            <img
              v-else
              src="@/assets/img/aloisio/pluzinha_clock.svg"
              alt="image"
              class="img-fluid mx-auto d-block pluszinha-sad mb-4"
            >
            <div class="text-center mb-4">
              <span class="fs-4">
                {{
                  isAlreadyTriedTemporaryUnlock
                    ? $t("contact_financial_sector")
                    : $t("attach_payment")
                }}
              </span>
            </div>
            <div
              v-if="!isAlreadyTriedTemporaryUnlock && !loadingFlags.managerPermissionToUnlockCompany"
              class="mx-4 mb-5"
            >
              <file-upload
                @select-file="selectedFile"
                filter="image/jpeg, image/png, application/pdf, text"
                icon="fe fe-database"
              />
            </div>
          </div>
          <div
            v-else-if="stepView === 1"
            class="text-center"
          >
            <h2 class="mb-0">
              Seu comprovante está em analise
            </h2>
            <img
              class="my-4"
              src="@/assets/img/aloisio/pluzinha_clock.svg"
              alt="image"
            >
            <p class="mb-4">
              o sistema será liberado temporariamente
            </p>
          </div>
          <div
            v-if="isAlreadyTriedTemporaryUnlock"
            class="text-center"
          >
            <img
              class="my-4"
              src="@/assets/img/sad-pluszinha.svg"
              alt="image"
              width="140"
              height="104"
            >
            <h2 style="margin-bottom: 16px;">
              Parece que há um problema com o pagamento de sua assinatura
            </h2>
            <p
              class="mb-4 mx-auto"
              style="color:#373753;width: 690px"
            >
              Para continuar usando a 3C Plus sem interrupções, por favor, verifique seu
              <span
                class="fw-medium"
                style="color: #575778"
              >status de pagamento</span> ou
              <span
                class="fw-medium"
                style="color: #575778"
              >entre em contato</span>
              conosco para resolvermos isso juntos!
            </p>
          </div>
          <div>
            <div class="d-flex justify-content-center flex-column align-items-center">
              <app-button
                class="btn btn-primary col-6 mb-3"
                :loading="loading"
                :blocked="buttonClocked"
                @click="submit()"
              >
                {{ isAlreadyTriedTemporaryUnlock ? $t("contact_us") : textButton }}
              </app-button>
              <button
                v-if="isAlreadyTriedTemporaryUnlock"
                class="btn text-danger"
                @click="_logout()"
              >
                <i class="fa-regular fa-right-from-bracket" />
                {{ $t("exit_system") }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Modal } from "bootstrap";
import appButton from "@components/app-button";
import { mapActions, mapGetters, mapMutations } from "vuex";
import FileUpload from "@components/file-uploader";

export default {
  emits: ["company-was-unlocked"],
  data() {
    return {
      file: null,
      temporaryUnlockModal: null,
      payment_proof: null,
      fields: {
        name: ""
      },
      stepView: 0,
      textButton: this.$t("send_capitalize"),
      isAlreadyTriedTemporaryUnlock: false,
      showIntercom: false
    };
  },

  mounted() {
    if (this.user.company.blocked_at) {
      this.temporaryUnlockModal = new Modal(this.$refs.temporaryUnlockModal, {
        backdrop: "static",
        keyboard: false
      });
      this.temporaryUnlockModal.show();
    }
  },

  components: {
    appButton,
    FileUpload
  },

  computed: {
    ...mapGetters("admin/companies", {
      loadingFlags: "getLoadingFlags"
    }),
    ...mapGetters("manager/user", {
      errors: "getErrors",
      loading: "isLoading",
      showTemporaryUnlockModal: "getTemporaryUnlockModal"
    }),
    ...mapGetters("auth", {
      user: "getUser"
    }),
    isButtonDisabled() {
      for (const key in this.fields.file) if (!this.fields[key]) return true;
      return false;
    },
    buttonClocked() {
      if (!this.file && !this.isAlreadyTriedTemporaryUnlock) return true;
      else return false;
    }
  },

  unmounted() {
    if (this.temporaryUnlockModal) this.temporaryUnlockModal.dispose();
  },

  methods: {
    ...mapMutations("auth", ["setNextDataUpdate"]),

    ...mapActions("admin/companies", ["managerPermissionToUnlockCompany"]),
    ...mapActions("manager/user", ["findUserData"]),
    ...mapActions("auth", ["me", "logout"]),

    selectedFile(file) {
      this.file = file;
      this.payment_proof = file;
    },

    submit() {
      if (this.stepView === 1) {
        this.$emit("company-was-unlocked");
        this.temporaryUnlockModal.hide();
        return;
      }
      if (this.isAlreadyTriedTemporaryUnlock) {
        this.toggleIntercom();
        return;
      }
      if (this.file) {
        const formData = new FormData();
        formData.append("payment_proof", this.payment_proof);
        this.managerPermissionToUnlockCompany(formData)
          .then(async () => {
            await this.me();
            this.stepView++;
            this.textButton = "Ok";
          })
          .catch(errorMessage => {
            if (errorMessage === "Já foi feita uma tentativa de desbloqueio nessa empresa.") {
              this.isAlreadyTriedTemporaryUnlock = true;
            }
          });
      }
    },
    toggleIntercom() {
      this.showIntercom = !this.showIntercom;
      if (this.showIntercom) window.Intercom("show");
      else window.Intercom("hide");
    },
    _logout() {
      this.logout().then(() => {
        window.location.href = "/login";
      });
    }
  }
};
</script>
<style lang="scss" scoped>
.pluszinha-sad {
  width: 140px;
  height: 104px;
}
.modal{
  z-index: 1058 !important;
}
</style>
