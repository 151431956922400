<template>
  <div
    class="d-flex align-items-center"
    style="height: 160px"
  >
    <div
      class="card mb-0"
      style="min-width: 296px;"
    >
      <div
        class="card-body d-flex align-items-center"
        style="padding: 1rem"
      >
        <action-component
          :icon-class="iconClass"
          :name="name"
          icon-bg="#F7EAC4"
          icon-color="#754000"
        />
        <app-button
          class="btn-md col-auto"
          color="btn-gray-blue"
          @click="deleteAction"
        >
          <span class="fa-regular fa-close" />
        </app-button>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions} from "vuex";
import ActionComponent from "../action-component.vue";
import AppButton from "@components/app-button.vue";

export default {
  name: "CardDefaultNextAction",

  components: {AppButton, ActionComponent},

  props: {
    action: {
      type: Object,
      required: true
    },
  },

  computed: {
    iconClass() {
      switch (this.action.default_next_action) {
        case "back" :
          return "fa-regular fa-rotate-left"
        case "restart" :
          return "fa-regular fa-play"
        case "finish" :
          return "fa-regular fa-close"
        default :
          return ""
      }
    },

    name() {
      switch (this.action.default_next_action) {
        case "back" :
          return this.$t('back_capitalize')
        case "restart" :
          return this.$t('restart_chatbot')
        case "finish" :
          return this.$t('finalize_chatbot')
        default :
          return ""
      }
    }
  },


  methods: {
    ...mapActions("chatbot/actions", ["updateAction"]),

    deleteAction() {
      this.updateAction({
        actionId: this.action.id,
        payload: {
          default_next_action: ""
        }
      })
    }
  }
};
</script>
