<template>
  <div
    class="tab-pane fade"
    id="distribution"
    role="tabpanel"
  >
    <div class="card mt-5">
      <h2 class="card-header">
        {{ $t("action_ring_phone") }}
      </h2>

      <div class="card-body mt-4">
        <div class="row">
          <div class="col-6 d-flex align-items-center border-end">
            <input
              :value="1"
              v-model="phoneRingsAction"
              class="form-check-input me-4 mx-5"
              type="radio"
              id="extension-default"
              :class="'pe-0'"
              @input="
                $emit(
                  'update:actionWhenThePhoneRings',
                  parseInt($event.target.value)
                )
              "
            >
            <label
              for="extension-default"
              class="form-label cursor-pointer"
              @click="updatePhoneRing('extension-default')"
            >
              <h4>
                {{ $t("standard") }}
              </h4>
              <span class="text-muted text-medium">
                {{ $t("user_with_highest_priority") }}
              </span>
            </label>
          </div>

          <div class="col-6 d-flex align-items-center">
            <input
              :value="2"
              v-model="phoneRingsAction"
              class="form-check-input me-4 mx-5"
              type="radio"
              id="all-extensions"
              :class="'pe-0'"
              @input="
                $emit(
                  'update:actionWhenThePhoneRings',
                  parseInt($event.target.value)
                )
              "
            >
            <label
              for="all-extensions"
              class="form-label cursor-pointer"
              @click="updatePhoneRing('all-extensions')"
            >
              <h4>
                {{ $t("all_same_time") }}
              </h4>
              <span class="text-muted d-block texte-medium">
                {{
                  $t("fist_to_pick_up")
                }}
              </span>
            </label>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-6">
        <div class="card card-fill">
          <h2 class="card-header">
            {{ $t("max_waiting_time_in_call") }}
          </h2>
          <div class="row card-body mt-4">
            <div class="form-group">
              <app-input
                :label="$t('total_time_client_in_call')"
                :small-text="$t('in_minutes')"
                type="number"
                :min="0"
                :errors="errors.wait_time_on_call"
                v-model="callWaitTime"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-6">
        <div class="card card-fill">
          <h2 class="card-header">
            {{ $t("max_waiting_time_per_extension") }}
          </h2>
          <div class="row card-body mt-4">
            <div class="form-group">
              <app-input
                :label="$t('max_time_call_per_extension')"
                :small-text="$t('in_seconds')"
                type="number"
                :min="0"
                :errors="errors.wait_time_by_extension_number"
                v-model="extensionNumberWaitTime"
              />
            </div>
          </div>
        </div>
      </div>

      <!-- COMMENTS HIDE THE FORWARDING OPTIONS -->

      <!-- <div class="col-lg-12">
        <div class="card card-fill">
          <h2 class="card-header">
            Encaminhamento de ligação não atendida
          </h2>
          <div class="row card-body mt-4">
            <div class="form-group">
              <div class="row gap-4">
                <div
                  class="col-lg-4 row align-items-center mb-5 border-end cursor-pointer"
                >
                  <input
                    class="form-check-input ms-5 me-4"
                    :class="'pe-0'"
                    type="radio"
                    id="none"
                    value="nenhum"
                    v-model="type"
                    @input="clearForwardingTypes"
                  >
                  <label
                    class="col-10 col-xl cursor-pointer"
                    for="none"
                  >
                    <h4>
                      Nenhum
                    </h4>
                    <span class="text-muted d-block text-small">
                      As chamadas não atendidas serão encerradas
                    </span>
                  </label>
                </div>
                <div
                  class="col-lg-4 row align-items-center mb-5 border-end cursor-pointer"
                >
                  <input
                    class="form-check-input ms-5 me-4"
                    :class="'pe-0'"
                    type="radio"
                    id="ura"
                    value="ura"
                    v-model="type"
                    @input="clearForwardingTypes"
                  >
                  <label
                    class="col-10 col-xl cursor-pointer"
                    for="ura"
                  >
                    <h4>
                      URA
                    </h4>
                    <span class="text-muted d-block text-small">
                      As chamadas não atendidas serão encaminhadas para a URA
                      selecionada
                    </span>
                  </label>
                </div>
                <div
                  class="col-lg-4 row align-items-center mb-5 cursor-pointer"
                >
                  <input
                    class="form-check-input ms-5 me-4"
                    :class="'pe-0'"
                    type="radio"
                    id="ring-group"
                    value="ring_group"
                    v-model="type"
                    @input="clearForwardingTypes"
                  >
                  <label
                    class="col-10 col-xl cursor-pointer"
                    for="ring-group"
                  >
                    <h4>
                      Grupo de Ramais
                    </h4>
                    <span class="text-muted d-block text-small">
                      Chamadas não atendidas serão encaminhadas para o grupo
                      selecionado
                    </span>
                  </label>
                </div>
                <div
                  class="col-lg-4 d-flex row align-self-center align-items-center mb-5 cursor-pointer"
                >
                  <input
                    class="form-check-input ms-5 me-4 pe-0"
                    :class="'pe-0'"
                    type="radio"
                    id="receptiveQueues"
                    value="receptive_queue"
                    v-model="type"
                    @input="clearForwardingTypes"
                  >
                  <label
                    class="col-12 col-xl cursor-pointer"
                    for="receptiveQueues"
                  >
                    <h4>
                      Fila Receptiva
                    </h4>
                    <span class="text-muted d-block text-small">
                      Chamadas não atendidas serão encaminhadas para fila
                      selecionada
                    </span>
                  </label>
                </div>
              </div> -->
      <!-- <div class="card-footer">
                <div v-show="forwardingType === 'ura'">
                  <label class="form-label">
                    Selecione uma ura
                  </label>
                  <select-receptive-ivr
                    :errors="errors?.receptiveNumber?.receptive_ivr_id"
                    v-model="receptive_ivr"
                  />
                </div>
                <div v-show="forwardingType === 'ring_group'">
                  <label class="form-label">
                    Selecione o Grupo de Ramais
                  </label>
                  <select-ring-group
                    class="select-customized"
                    v-model="ring_group"
                  />
                </div>
                <div v-show="forwardingType === 'receptive_queue'">
                  <label class="form-label">
                    Selecione a Fila Receptiva
                  </label>
                  <select-receptive-queues
                    class="select-customized"
                    v-model="receptive_queue"
                  />
                </div>
              </div> -->
      <!-- </div> -->
      <!-- </div> -->
      <!-- </div> -->
      <!-- </div> -->
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import AppInput from "@/components/app-input.vue";

// COMMENTS HIDE THE FORWARDING OPTIONS
// import SelectReceptiveIvr from "@dialer/manager/components/select-receptive-ivr.vue";
// import SelectRingGroup from "@dialer/manager/components/select-ring-group.vue";
// import SelectReceptiveQueues from "@dialer/manager/components/select-receptive-queues.vue";


export default {
  data() {
    return {
      callWaitTime: 0,
      extensionNumberWaitTime: 0,
      phoneRingsAction: 0,

      // COMMENTS HIDE THE FORWARDING OPTIONS
      // type: "",
      // receptive_ivr: null,
      // ring_group: null,
      // receptive_queue: null,
    };
  },

  // COMMENTS HIDE THE FORWARDING OPTIONS

  emits: [
    "update:waitTimeOnCall",
    "update:waitTimeByExtensionNumber",
    "update:actionWhenThePhoneRings",
    //   "update:forwardingType",
    //   "update:receptiveQueue",
    //   "update:receptiveIvr",
    //   "update:ringGroup"
  ],

  props: {
    actionWhenThePhoneRings: {
      type: Number,
      default: 0,
    },
    waitTimeOnCall: {
      type: Number,
      default: 0,
    },
    waitTimeByExtensionNumber: {
      type: Number,
      default: 0,
    },

    // COMMENTS HIDE THE FORWARDING OPTIONS
    // forwardingType: {
    //   type: String,
    //   default: ""
    // },
    // receptiveQueue: {
    //   type: Object,
    //   default: null
    // },
    // receptiveIvr: {
    //   type: Object,
    //   default: null
    // },
    // ringGroup: {
    //   type: Object,
    //   default: null
    // },

  },

  components: {
    AppInput,
    // COMMENTS HIDE THE FORWARDING OPTIONS
    // SelectRingGroup,
    // SelectReceptiveIvr,
    // SelectReceptiveQueues,
  },

  computed: {
    ...mapGetters("manager/ring_group", {
      errors: "getErrors",
    }),
  },

  mounted() {
    this.callWaitTime = this.waitTimeOnCall / 60;
    this.extensionNumberWaitTime = this.waitTimeByExtensionNumber;
    this.phoneRingsAction = this.actionWhenThePhoneRings;

    // COMMENTS HIDE THE FORWARDING OPTIONS
    // if (this.receptiveIvr) {
    //   this.receptive_ivr = this.receptiveIvr;
    // }
    // if (this.ringGroup) {
    //   this.ring_group = this.ringGroup;
    // }
    // if (this.receptiveQueue) {
    //   this.receptive_queue = this.receptiveQueue;
    // }
    // if (this.forwardingType) {
    //   this.forwarding_type = this.forwardingType;
    // }
    // this.type = this.forwardingType;
  },

  methods: {
    ...mapActions("manager/ring_group", ["update", "create", "remove"]),

    updatePhoneRing(role) {
      this.phoneRingsAction = role === "extension-default" ? 1 : 2;
    },

    // COMMENTS HIDE THE FORWARDING OPTIONS
    // clearForwardingTypes (event) {
    //   this.$emit("update:forwardingType", event.target.value);
    //   this.$emit("update:receptiveIvr", null)
    //   this.$emit("update:ringGroup", null)
    //   this.$emit("update:receptiveQueue", null)
    // }
  },

  watch: {
    callWaitTime(data) {
      this.$emit("update:waitTimeOnCall", data);
    },
    extensionNumberWaitTime(data) {
      this.$emit("update:waitTimeByExtensionNumber", data);
    },
    phoneRingsAction(data) {
      this.$emit("update:actionWhenThePhoneRings", data);
    },
    // COMMENTS HIDE THE FORWARDING OPTIONS
    // receptive_ivr(data) {
    //   this.$emit("update:receptiveIvr", data);
    // },
    // ring_group(data) {
    //   this.$emit("update:ringGroup", data);
    // },
    // receptive_queue(data) {
    //   this.$emit("update:receptiveQueue", data);
    // },
  },
};
</script>
