import axios from "axios";
import { AgentStatus } from "@/utils.js";

export default {
  loadAllAgentsOnline({ commit }) {
    commit("startLoading", "loadAllAgentsOnline");

    return new Promise((resolve, reject) => {
      axios.get('/agents/online')
        .then((response) => {
          commit("setAllAgentsOnline", response.data.data);
          resolve();
        })
        .catch(() => {
          reject();
        })
        .finally(() => {
          commit("stopLoading", "loadAllAgentsOnline");
        });
    })
  },

  fetchAgentsByCampaignId({ commit }, campaignId) {
    commit("startLoading", "fetchAgentsByCampaignId");

    return new Promise((resolve, reject) => {
      axios.get(`/campaigns/${campaignId}/agents/status`)
        .then((response) => {
          commit("setAgents", response.data.data);
          resolve();
        })
        .catch(() => reject())
        .finally(() => commit("stopLoading", "fetchAgentsByCampaignId"));
    });
  },

  updateOrCreateAgentOnline({ commit, getters }, event) {
    const { agent } = event;
    if (getters.getAllAgentsOnline[agent.id]) {
      commit("updateAgentStatus", event);
    } else {
      commit("createAgentOnline", event);
    }
  },

  removeAgentOnline({ commit, getters }, event) {
    if (getters.getAllAgentsOnline[event.agent.id]) {
      commit("removeAgentOnline", event);
    }
  },

  updateAgentStatusInCampaign({ commit, getters, rootGetters }, event) {
    const { agent, campaignId, call } = event;
    const campaign = rootGetters['manager/campaign/getCampaign'];
    const agentById = getters.getAgents[agent.id];
    if(campaign && (campaign.id === campaignId && agentById)){
      if(event.agent.status === AgentStatus.ON_MANUAL_CALL) {
        if(!agentById.is_manual_call_qualified) {
          event.agent.status = AgentStatus.ACW;
        } else {
          agentById.is_manual_call_qualified = false; // reset manual call if agent goes back do manual
        }
      }

      commit("updateAgentStatusInCampaign", {
        agent: event.agent,
        campaign: campaign,
        call
      });
    }
  },

  setAgentWorkBreak({ commit, getters, rootGetters }, event) {
    const { agent, campaignId } = event;
    const campaign = rootGetters['manager/campaign/getCampaign'];
    if(campaign && campaign.id === campaignId && getters.getAgents[agent.id]) {
      commit('setAgentWorkBreak', event);
    }
  },

  setWorkBreakExitPending({ commit, getters }, { id, work_break_exit_pending }) {
    if(getters.getAgents[id]) {
      commit("setWorkBreakExitPending", { id, work_break_exit_pending});
    }
  },

  setAgentStatusInCampaignAsOffline({ commit, getters }, event) {
    const { agent } = event;
    if(getters.getAgents[agent.id]){
      commit("setAgentStatusInCampaignAsOffline", event);
    }
  },

  qualifyManualCallFromAgent({ commit, getters }, event) {
    const { user } = event;
    const agent = getters.getAgents[user.id];

    if(agent) {
      commit("setIsManualCallQualified", { agent: agent, isManualCallQualified: true});
    }
  },

  /**
   * This method does not refer to the MANUAL ACW, but to the time that the
   * agent stays in ACW after completing an manual call.
   */
  removeAgentFromAcwManual({ commit, getters }, event) {
    const { user } = event;
    const agent = getters.getAgents[user.id];

    if(agent && agent.is_manual_call_qualified & agent.status === AgentStatus.ACW) {
      agent.is_manual_call_qualified = false;
      agent.status = AgentStatus.ON_MANUAL_CALL;
      commit("updateAgentStatusInCampaign", { agent });
    }
  },

  clearAgentsData({ commit }) {
    commit("clearAgentsData");
  },
}
