<template>
  <select
    :class="{ 'form-select': true, 'is-invalid': errors }"
    :disabled="loadingFlags.findFilters"
    @input="$emit('update:modelValue', $event.target.value)"
    :value="modelValue"
  >
    <option
      :label="$t('none_f')"
      value=""
    />
    <option
      :label="item.name"
      v-for="item in feedbacks"
      :key="item.id"
      :value="item.id"
    />
  </select>
  <div class="invalid-feedback">
    {{ errors && errors[0] }}
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  emits: ["update:modelValue"],
  data() {
    return {
      feedbacks: []
    }
  },
  mounted() {
    this.setFiltersIncludes(["criterion_lists"]);
    this.findFilters();
  },

  computed: {
    ...mapGetters("manager/filters", {
      filters: "getFilters",
      loadingFlags: "getLoadingFlags",
    }),
  },

  props: {
    modelValue: {
      default: "",
      type: [Number, String],
    },

    errors: {
      type: Array,
      default: null,
    },
  },

  methods: {
    ...mapActions("manager/filters", [
      "findFilters",
      "setFiltersIncludes"
    ]),
  },

  watch: {
    filters: {
      handler(val) {
        if (val.criterion_lists) {
          this.feedbacks = val.criterion_lists;
        }
      },
      deep: true,
    },
  },
};
</script>
