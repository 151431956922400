<template>
  <video
    width="360"
    :src="media"
    type="video/mp4"
    :class="{'mb-3': !text}"
    controls
  />
  <div
    class="pe-5 b8 b1 caption"
    v-if="text"
  >
    <div
      class="me-3"
      :class="{ 'text-decoration-line-through': isDeleted }"
    >
      {{ text }}
    </div>
  </div>
</template>

<script>
export default {
  /**
   *
   */
  props: {
    /**
     *
     */
    text: {
      type: String,
      required: true,
    },
    /**
     *
     */
    media: {
      type: String,
      default: "",
    },
    /**
     * 
     */
    isDeleted: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.caption {
  max-width: 360px !important;
}

video {
  border-radius: ($whatsapp-border-radius - 4px);
  height: 320px !important;
  background-color: black;
}
</style>
