<template>
  <modal-component
    :background-blur="true"
    :center="true"
    :show="show"
    custom-size="316px"
    @close="$emit('close')"
  >
    <template #content>
      <div class="modal-body d-flex flex-column align-items-center">
        <i
          class="fa-regular fa-xmark align-self-end mb-3 cursor-pointer"
          @click="$emit('close')"
        />
        <p class="mb-4">
          {{ $t('unmark_as') }} <q class="fw-medium">{{ $t(getCustomizeTextForRestoreModal) }}</q>?
        </p>

        <div
          class="w-100 d-flex justify-content-center"
          style="gap: 16px"
        >
          <button
            class="btn mark-off-btn cancel d-flex justify-content-center align-items-center"
            @click="$emit('close')"
          >
            {{ $t('cancel') }}
          </button>
          <button
            class="btn mark-off-btn btn-primary d-flex justify-content-center align-items-center"
            @click="$emit('mark-off')"
          >
            {{ $t('unmark') }}
          </button>
        </div>
      </div>
    </template>
  </modal-component>
</template>

<script>
import ModalComponent from "@components/modal-component.vue";
import {mapGetters} from "vuex";

export default {
  name: "MarkOffOpportunityModal",

  components: {ModalComponent},

  emits: ['close', 'mark-off'],

  props: {
    show: {
      type: Boolean,
      default: false
    },
  },

  computed: {
    ...mapGetters("crm/opportunities", {
      opportunity: "getOpportunity",
    }),

    getCustomizeTextForRestoreModal() {
      return this.opportunity.status === 'lost'
        ? 'lost'
        : 'gain'
    }
  }
}
</script>

<style lang="scss" scoped>
.mark-off-btn {
  width: 118px;
  height: 32px;
  font-size: 12px;

  &.cancel {
    background-color: $color-gray-blue-300;
    color: $color-text-gray;

    &:hover {
      background-color: $color-gray-200;
    }
  }
}
</style>