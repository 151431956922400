<template>
  <div class="dropdown w-100">
    <button
      class="btn dropdown-toggle w-100 h-100 py-0 d-flex justify-content-between align-items-center"
      type="button"
      id="dropdownMenuButton1"
      data-bs-toggle="dropdown"
      aria-expanded="false"
      data-bs-auto-close="outside"
    >
      <span class="fs-6 lh-1">{{ dropdownLabel }}</span>
      <i class="fa-regular fa-chevron-down" />
    </button>
    <ul
      style="padding: 1rem;"
      class="dropdown-menu w-100"
      aria-labelledby="dropdownMenuButton1"
    >
      <li>
        <users-tab
          id="transfer-form"
          :current-user-id="user.id"
          :users="allowedAgents"
          @search="search"
          @role="updateRole"
          multi-select
          label="name"
          v-model="selectedUsers"
          :loading="loadingFlags.findAllowedAgentsAndInstances"
          hide-tabs
        />
      </li>
    </ul>
  </div>
</template>

<script>
import _ from "lodash";
import {mapGetters, mapActions, mapMutations} from "vuex";
import UsersTab from "@whatsapp/components/transfer-form/users-tab.vue";

export default {

  data() {
    return {
      allowedTabs: {
        agent: 0,
        supervisor: 0,
        manager: 0,
        all: () => {
          return (this.allowedTabs.agent + this.allowedTabs.supervisor + this.allowedTabs.manager);
        }
      },

      selectedUsers: [],
      selectedUsersAux: []
    }
  },

  computed: {
    ...mapGetters("whatsapp/chat", {
      currentChat: "getCurrentChat",
      groupAgents: "getGroupAgents",
      params: "getGroupAgentsParams"
    }),
    ...mapGetters("auth", {
      user: "getUser"
    }),
    ...mapGetters("whatsapp/instance", {
      allowedAgents: "getAllowedAgents",
      loadingFlags: "getLoadingFlags",
      allowedParams: "getAllowedParams"
    }),

    dropdownLabel() {
      if (this.selectedUsers.length === 1) {
        return this.selectedUsers[0].name;
      }
      if (this.selectedUsers.length < this.allowedTabs.all() ) {
        return `${this.$t('participants_uppercase')} ${this.selectedUsers.length}`
      }

      return this.$t('capitalizes.all');
    }
  },

  created() {
    if (this.currentChat[this.$route.params.id].team_id) {
      this.setAllowedParams({
        team: this.currentChat[this.$route.params.id].team_id,
        all_company: 0
      })
      this.findAllowedAgentsAndInstances(this.allowedParams).then(() => {
        this.allowedTabs[this.allowedParams.role] = this.allowedAgents.length
      })
    }
  },

  mounted() {
    if (!this.allowedAgents) {
      this.findAllowedAgentsAndInstances(this.allowedParams).then(() => {
        this.allowedTabs[this.allowedParams.role] = this.allowedAgents.length
      });
    }

    this.formatUsers(this.groupAgents).filter((agent) => {
      if (agent.is_group_responsible === true && agent?.role_name === 'agent') {
        this.selectedUsersAux.push(agent);
      }
    })

    this.selectedUsers = [...this.selectedUsersAux];
  },

  components: {
    UsersTab,
  },

  methods: {
    ...mapActions("whatsapp/chat", ["addResponsibleGroup", "findGroupAgents", "addFilters"]),
    ...mapActions("whatsapp/instance", ["findAllowedAgentsAndInstances"]),
    ...mapMutations("whatsapp/instance", ["setAllowedParams"]),

    updateRole(role) {
      let params  = {
        team: this.currentChat[this.$route.params.id].team_id,
        role,
        all_company: 0
      }

      if (role === 'manager') {
        params.team = null
      }

      this.setAllowedParams(params);

      this.findAllowedAgentsAndInstances(this.allowedParams).then(() => {
        this.allowedTabs[this.allowedParams.role] = this.allowedAgents.length
      })
    },

    formatUsers(list) {
      return list.map((item) => {
        return {
          id:item.agent_id,
          name: item.agent_name,
          extension: {
            extension_number: item.extension_number
          },
          is_group_responsible: item.is_group_responsible,
          role_name: item.role_name,
        }
      })
    },

    search: _.debounce(function (event) {
      this.setAllowedParams({
        search: event,
        all_company: 0
      })
      this.findAllowedAgentsAndInstances(this.allowedParams).then(() => {
        this.allowedTabs[this.allowedParams.role] = this.allowedAgents.length
      })
    }, 500)
  },

  watch: {
    selectedUsers: {
      handler(newUsers) {
        if(newUsers.length !== this.selectedUsersAux.length) {
          if(newUsers.length === this.groupAgents.length) {
            this.addResponsibleGroup({chat: this.currentChat[this.$route.params.id], agents: {"allow_all_agents": true}})

            return
          }
          const users = {};
          users.agents = newUsers.map(user => user.id || user.agent_id);

          this.addResponsibleGroup({chat: this.currentChat[this.$route.params.id], agents: users})
          this.selectedUsersAux = [];
        }
      },
      deep: true
    }
  }
};
</script>

<style lang="scss" scoped>
.dropdown {
  border-radius: $whatsapp-border-radius;
  background-color: $color-gray-blue-300;
  height: 29px;

  .dropdown-toggle {
    > i {
      transition: transform 0.25s ease;
    }

    &[aria-expanded="true"] {
      border-radius: 10px 10px 0 0;
      background-color: $color-blue-300;
      color: #FFFFFF;

      > i {
        transform: rotate(180deg);
      }
    }

    &::after {
      display: none;
    }
  }

  .dropdown-menu {
    transform: translate3d(0px, 28px, 0px) !important;
    border-radius: 0 0 10px 10px;
    max-height: 420px;
    overflow-y: auto;
  }
}
</style>
