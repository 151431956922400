import axiosOmni from "@/plugins/axios-omni.js";
import axiosCrm from "@crm/plugins/axios-config";
import {createAndDownloadFileFromBlob} from "@/utils";

export default {
  fetchChatsReportList({ commit, getters }) {
    commit('startLoadingFlags', 'fetchChatsReportList');
    commit('setChatsReportList', [])
    return new Promise((resolve, reject) => {
      axiosOmni.get('/whatsapp/reports/chats', {params: getters.getParams })
        .then((response) => {
          commit('setChatsReportList', response.data.data)
          commit('setPagination', response.data.meta.pagination)
          resolve();
        }).catch((error) => {
          commit('system/setErrorMessage', error.response.data, { root: true })
          reject();
        })
        .finally(() => {
          commit('stopLoadingFlags', 'fetchChatsReportList')
        });
    });
  },

  fetchSendCsvEmail({commit, getters}, payload){
    commit('startLoadingFlags', 'fetchSendCsvEmail')
    return new Promise((resolve, reject) => {
      axiosOmni.get(`/whatsapp/reports/chats/csv`, {params: {...getters.getParams, ...payload}})
      .then(() => {
        commit('system/setSuccessMessage', "success_message.send_csv", {root: true})
        resolve()
      })
      .catch((error) => {
        commit('system/setErrorMessage', error.response.data, { root: true })
        reject()
      })
      .finally(() => {
        commit('stopLoadingFlags', 'fetchSendCsvEmail')
      })
    })
  },

  fetchChatPdf({commit}, payload) {
    commit('startLoadingFlags', 'fetchChatPdf')
    return new Promise((resolve, reject) => {
      axiosOmni.get(`/whatsapp/reports/chat/${payload.id}/download`, {params: payload, responseType: 'arraybuffer'})
      .then((response) => {
        createAndDownloadFileFromBlob(response.data, "application/pdf", payload.number)
        resolve()
      })
      .catch(() => {
        reject()
      })
      .finally(() => {
        commit('stopLoadingFlags', 'fetchChatPdf')
      })
    })
  },

  fetchChatTimeline({commit}, {contactId, protocol}){
    commit("setChatLogs", {})
    commit('startLoadingFlags', 'fetchChatTimeline')
    const params = {
      protocol_number: protocol,
      origin: "WHATSAPP",
      per_page: -1
    }
    return new Promise((resolve, reject) => {
      axiosCrm.get(`/contact/${contactId}/contact_logs`, {params})
        .then((response) => {
          commit("setChatLogs", response.data)
          resolve()
        })
        .catch((error) => {
          commit('system/setErrorMessage', error.response.data, { root: true })
          reject()
        })
        .finally(() => {
          commit('stopLoadingFlags', 'fetchChatTimeline')
        })
    })
  },
}
