export default {
  isLoading: state => state.loading,
  getLoadingContext: state => state.loadingContext,
  getReceptiveQueue: state => state.receptiveQueue,
  getList: state => state.list,
  getFilters: state => state.filters,
  getPagination: (state) => state.pagination,
  getErrors: state => state.errors,
  getRegister: (state) => state.register,
  getParams: (state) =>
    Object.assign(state.pagination, state.filters, { includes: state.includes.join(",") }),
  getDataSelect: (state) => state.dataSelect,
  getConsultMetrics: (state) => state.consultMetrics,
  getQualificationsMetrics: (state) => state.qualificationsMetrics,
  getAgentsStatus: (state) => state.agentsStatus,
  getCalls: (state) => state.calls,
  getStatusCount: (state) => state.statusCount,
  getStatusCallsCount: (state) => state.statusCallsCount,

  getQueues: (state) => state.queues,
  getTotal: (state) => state.total,
  getActiveAgents: (state) => state.activeAgents,
  getActiveCalls: (state) => state.activeCalls,
  getReceptiveMetrics: (state) => state.receptiveMetrics,
}
