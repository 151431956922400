<template>
  <div
    class="tab-pane fade pt-4"
    id="tab-omni"
    role="tabpanel"
    aria-labelledby="list-tab-omni"
  >
    <card-loading
      v-if="loadingWhatsapp.fetchGroupChannels"
      style="height: 350px"
    />
    <div v-else>
      <div class="card">
        <div class="card-header">
          <div class="form-check form-switch">
            <input
              class="form-check-input"
              type="checkbox"
              role="switch"
              id="ai_chat_evaluation"
              v-model="fields.ai_chat_evaluation"
            >
            <label
              class="form-check-label"
              for="ai_chat_evaluation"
            >{{ $t('ai_evaluation_chats') }}</label>
          </div>
        </div>
        <div
          class="card-body"
          :class="fields.ai_chat_evaluation ? 'switch-on' : 'switch-off'"
        >
          <div class="d-flex flex-column gap-4">
            <div class="description d-flex justify-content-center align-items-center">
              <i
                class="fa-regular fa-microchip-ai fa-xl tx-blue-300"
                style="font-size: 24px"
              />
              <p
                v-html="$t('ai_evaluation_description')"
                class="m-0"
              />
            </div>
            <div class="d-flex flex-md-column gap-2">
              <p class="mb-2">
                {{ $t('channel_group') }}
              </p>
              <selects-components
                id="group_channel_ids"
                :current-selected="fields.group_channel_ids"
                :list-array="groupChannels"
                :multi-select-check="false"
                :multiple-select="true"
                search-name="name"
                :single-select="false"
                text-select="Selecione"
                @selected="handleSelected"
              />
            </div>

            <field-selector
              id="select-feedback"
              :select-filed-name="$t('select_feedback_group')"
              :current-selected="feedbacks.find(list => list.id === fields.criterion_list_id)?.name || ''"
              :list-array="feedbacks"
              :loading="loadingFeedbacks.loadFeedbacks"
              :multi-select-check="false"
              :description="$t('evaluation_criteria_description')"
              icon="fa-light fa-comments fs-5 tx-blue-300"
              search-name="name"
              :title="$t('evaluation_criteria_chats')"
              type-select="single-selected"
              @selected="({id}) => fields.criterion_list_id = id"
              :disabled="!fields.ai_chat_evaluation"
            />
          </div>
        </div>
      </div>
      <div class="w-100 d-flex flex-column justify-content-center align-items-center">
        <app-button
          class="col-5 mb-4"
          :loading="loadingFlags.updateAiChat"
          @click="submit"
        >
          {{ $t('capitalizes.save') }}
        </app-button>
        <button
          class="btn  col mb-3 tx-text-gray"
          @click="$router.push('/manager/insights-ai')"
          :disabled="loadingFlags.updateAiChat"
        >
          {{ $t('back_capitalize') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import AppButton from "@components/app-button.vue";

import FieldSelector from "@dialer/manager/pages/insights-ai/components/field-selector.vue";
import {mapActions, mapGetters} from "vuex";
import SelectsComponents from "@dialer/manager/pages/insights-ai/components/selects-component.vue";
import CardLoading from "@components/card-loading.vue";

export default {
  name: "TabOmni",

  components: {CardLoading, SelectsComponents, FieldSelector,  AppButton},

  data() {
    return{
      fields: {
        ai_chat_evaluation : false,
        group_channel_ids: [],
        criterion_list_id: null,
      }
    }
  },

  computed:{
    ...mapGetters("manager/insights", {
      loadingFlags: "getLoadingFlags",
    }),
    ...mapGetters("manager/criterion", {
      feedbacks: "getDataSelect",
      loadingFeedbacks: "getLoadingFlags"
    }),
    ...mapGetters("whatsapp/instance", {
      groupChannels: "getGroupChannels",
      loadingWhatsapp: "getLoadingFlags"
    }),
    ...mapGetters('auth', {
      user: 'getUser'
    }),

    company(){
      return this.user.company
    }
  },

  created () {
    this.loadFeedbacks();
    this.fetchGroupChannels().then(()=>{
      this.populateFields()
    })
  },

  methods: {
    ...mapActions("manager/criterion", ["loadFeedbacks"]),
    ...mapActions("whatsapp/instance", ["fetchGroupChannels"]),
    ...mapActions("manager/insights", ["updateAiChat"]),

    populateFields() {
      if (!this.company) {
        return
      }

      const keys = ['ai_chat_evaluation', 'criterion_list_id'];
      keys.forEach(key => this.fields[key] = this.company[key])

      if (!this.company.group_channel_ids?.length) {
        this.fields.group_channel_ids = this.groupChannels
      }
      else {
        this.fields.group_channel_ids = this.groupChannels.filter(group => this.company.group_channel_ids.includes(`${group.id}`))
      }
    },

    handleSelected(event) {
      const {type,index} = event
      type === 'remove' ?
        this.fields.group_channel_ids.splice(index, 1) :
        this.fields.group_channel_ids.push(event)
    },

    submit() {
      let params = {...this.fields}
      params.ai_chat_evaluation = params.ai_chat_evaluation? 1 : 0
      params.group_channel_ids = params.group_channel_ids.map(group => group.id)

      this.updateAiChat({
        companyId: this.company.id,
        params: params
      })
    }
  }
}
</script>

<style scoped lang="scss">
.description {
  background-image: url("~@assets/img/insights-ia-texture.png");
  border-radius: 10px;
  height: 55px;
  background-repeat: no-repeat;
  background-size: cover, cover;
  padding: 24px 16px;
}

.bg-tag {
  background-color: $color-gray-blue-300;
}

.sizing {
  width: 32px;
  height: 32px;
}

.description i {
  font-size: 23.111px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-right: 5px;
}

:deep(.multiselect) {
  .multiselect__tag {
    color: #1A4A89 !important;
    background: #E1E9F4 !important;
  }
  .multiselect__tag-icon {
    fill: #1A4A89 !important;
  }
}

.switch-off {
  pointer-events: none;
  opacity: 0.4;
}

.switch-on {
  pointer-events: auto;
  opacity: 1;
}
</style>
