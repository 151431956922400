<template>
  <hr
    class="navbar-divider my-3"
    v-if="suggestions.length > 0"
  >
  <div
    class="d-flex flex-column w-100"
    style="padding: 16px"
  >
    <div
      class="d-flex justify-content-between"
      v-if="suggestions.length > 0"
    >
      <p
        class="m-4 ms-0 text-start"
        style="color: #373753; font-weight: 500"
      >
        {{ isChat ? $t('actions_suggestions') : $t('or_use_one_of_our_suggestions') }}
      </p>
    </div>

    <div
      class="row justify-content-between"
      v-if="isChat && suggestions.length > 0"
    >
      <div
        v-for="(suggestion, index) in suggestions.slice(0,2)"
        :key="index"
        class="col"
      >
        <button
          class="suggestion p-3 d-flex align-items-center border-0 w-100 text-start"
          style="height: 32px"
          @click="submitQuestion(suggestion)"
          :disabled="loading.sendQuestion"
        >
          {{ suggestion }}
        </button>
      </div>
    </div>
    <div
      class="row justify-content-between"
      v-else-if="!isChat"
    >
      <div class="col-md-4">
        <div class="card">
          <p class="card-header fw-bolder border-0 pb-0">
            {{ $t('data') }}
          </p>
          <div
            class="card-body d-flex flex-column"
            style="gap: 0.5rem; padding: 16px"
          >
            <div
              v-for="(suggestion, index) in dataSuggestions"
              :key="index"
            >
              <button
                class="suggestion p-3 border-0 w-100 text-start"
                style="height: 70px"
                @click="submitQuestion(suggestion)"
                :disabled="loading.sendQuestion"
              >
                {{ suggestion }}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="card">
          <p class="card-header fw-bolder border-0 pb-0">
            {{ $t('opportunity') }}
          </p>
          <div
            class="card-body d-flex flex-column"
            style="gap: 0.5rem; padding: 16px"
          >
            <div
              v-for="(suggestion, index) in opportunitySuggestions"
              :key="index"
              class=""
            >
              <button
                class="suggestion p-3 border-0 w-100 text-start"
                style="height: 70px"
                @click="submitQuestion(suggestion)"
                :disabled="loading.sendQuestion"
              >
                {{ suggestion }}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="card">
          <p class="card-header fw-bolder border-0 pb-0">
            {{ $t('users_capitalize') }}
          </p>
          <div
            class="card-body d-flex flex-column"
            style="gap: 0.5rem; padding: 16px"
          >
            <div
              v-for="(suggestion, index) in userSuggestions"
              :key="index"
              class=""
            >
              <button
                class="suggestion p-3 border-0 w-100"
                style="text-align: left; height: 70px"
                @click="submitQuestion(suggestion)"
                :disabled="loading.sendQuestion"
              >
                {{ suggestion }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {mapActions, mapGetters} from "vuex";

export default {

  emits: ["suggestion"],
  props: {
    isChat: {
      type: Boolean,
      default: false
    },
    currentQuestion: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      dataSuggestions: [],
      opportunitySuggestions: [],
      userSuggestions: [],
      suggestions: []
    };
  },
  computed: {
    ...mapGetters("crm/funnels", {
      currentFunnel: "getCurrentFunnel"
    }),
    ...mapGetters("crm/ai_assistant", {
      loading: "getLoadingFlags"
    }),
  },
  mounted() {
    this.opportunitySuggestions = this.$tm('opportunity_suggestions');
    this.dataSuggestions = this.$tm('data_suggestions');
    this.userSuggestions = this.$tm('users_suggestions');

    this.suggestions = this.dataSuggestions.concat(this.opportunitySuggestions, this.userSuggestions);
    if(this.currentQuestion && this.isChat){
      this.removeSuggestion(this.currentQuestion)
    }
  },
  methods: {
    ...mapActions('crm/ai_assistant', [
      'sendQuestion'
    ]),
    submitQuestion(suggestion){
      const questionBody = {
        prompt: suggestion,
        funnel_id: this.currentFunnel.id
      }
      this.sendQuestion(questionBody)
      this.$emit('suggestion', suggestion)
      if(this.isChat){
        this.removeSuggestion(suggestion)
      }
    },
    removeSuggestion(suggestion) {
      this.suggestions = this.suggestions.filter(s => s !== suggestion)
    }
  }

}
</script>



<style scoped>

.suggestion{
  background-color: #F0F4FA;
  color: #1b1b2b;
  border-radius: 10px;
  font-size: 14px;
  line-height: 16px;
}
</style>