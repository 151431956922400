export default {
  loadingFlag: {
    crmQualification: false,
  },
  loading: false,
  errors: {},
  errorsQualification: {},
  lastCallIsqualificated: false,
  list: [],
  callQualificated: false,
  paginator: {},
  alert: null,
  register: null,
  qualificationRegister: [],
  qualificationPagination: {},

  dataSelect: null,
  qualificationListDataSelect: [],
  eachQualificationListDataSelect: [],
};
