<template>
  <div class="row">
    <div
      v-for="(item, index) in metricsData"
      :key="index"
      class="col-6 col-sm-6 col-md-6 col-lg-3"
    >
      <div class="card">
        <div class="card-body card-metric">
          <div class="row align-items-center">
            <div class="col-auto">
              <div class="avatar avatar-sm">
                <div
                  class="avatar-title rounded-circle"
                  :class="item.name"
                >
                  <span :class="item.iconClass" />
                </div>
              </div>
            </div>
            <div class="col">
              <h6 class="fw-normal lh-1">
                {{ $t(item.title) }}
              </h6>
              <span class="fw-medium text-large">
                {{ item.value || 0 }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters("manager/whatsapp", {
      metrics: "getWhatsappMetrics",
    }),

    metricsData(){
      return [
        {
          name: "total",
          iconClass: "fe fe-check",
          title: "list_metrics_total",
          value: this.metrics.total
        },
        {
          name: "active",
          iconClass: "fe fe-message-square",
          title: "list_metrics_active",
          value: this.metrics.active
        },
        {
          name: "queue",
          iconClass: "fe fe-minus",
          title: "list_metrics_in_queue",
          value: this.metrics.queue
        },
        {
          name: "snooze",
          iconClass: "fal fa-snooze",
          title: "list_metrics_on_hold",
          value: this.metrics.snooze
        },
      ]
    }
  },
};
</script>

<style scoped lang="scss">
.card-metric {
  padding: 2.25rem 1.5rem;
}

.total {
  background-color: rgba(136, 136, 136, 0.1);
  color: #888888;
}
.active {
  background-color: rgba(92, 184, 104, 0.1);
  color: #015D0D;
}
.queue {
  background-color: rgba(48, 87, 242, 0.1);
  color: $color-blue-500;
}
.snooze {
  background-color: rgba(230, 124, 11, 0.1);
  color: $color-orange-500;
}
</style>
