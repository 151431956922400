<template>
  <div class="card">
    <div class="card-header d-flex justify-content-between align-items-center">
      <h4 class="m-0">
        {{ $t('list_setting') }}
      </h4>
      <div class="d-flex gap-4">
        <div class="d-flex gap-2">
          <p class="m-0">
            {{ $t('selected_required_fields') }}:
          </p>
          <strong>{{ selectedRequiredInputs }}/{{ totalRequiredInputs }}</strong>
        </div>
        <div class="d-flex gap-2">
          <p class="m-0">
            {{ $t('phone_fields') }}:
          </p>
          <strong>{{ selectedContactInputs }}</strong>
        </div>
      </div>
    </div>
    <div class="table-responsive">
      <table class="table table-borderless card-table">
        <thead>
          <tr>
            <th>
              {{ $t('column') }}
            </th>
            <th>
              {{ $t('column_type') }}
            </th>
            <th
              v-for="index in preAdjustedCsv.data.slice(0,3).length"
              :key="index"
            >
              {{ $t('line') + " " + index }}
            </th>
          </tr>
        </thead>
        <tbody>
          <csv-validation-row-item
            v-for="index in preAdjustedCsv.header.length"
            :key="index"
            :index="index"
            :grouped-form-inputs="groupedFormInputs"
            @select="selectFieldHeader"
          />
        </tbody>
      </table>
    </div>
  </div>
  <div class="w-100 d-flex flex-column gap-3">
    <app-button
      class="w-100"
      :loading="loadingFlags.createValidateCsv"
      @click="submit"
    >
      {{ $t('capitalizes.save') }}
    </app-button>
  </div>
</template>

<script>
import CsvValidationRowItem from "../csv-validation-row-item.vue";
import AppButton from "@components/app-button.vue";
import {mapActions, mapGetters, mapMutations} from "vuex";
import _ from "lodash";

export default {
  name: "CsvValidation",

  components: {
    CsvValidationRowItem,
    AppButton,
  },

  data () {
    return {
      labels: []
    }
  },

  created() {
    this.fetchFormInputs(this.currentFunnel.id)
  },

  computed: {
    ...mapGetters('crm/imports', {
      preAdjustedCsv: "getPreAdjustedCsv",
      errors: "getErrors",
      loadingFlags: "getLoadingFlags"
    }),
    ...mapGetters('crm/funnels', {
      currentFunnel: 'getCurrentFunnel'
    }),
    ...mapGetters('crm/opportunities', {
      formInputs: 'getFormInputs'
    }),

    requiredInputsIds() {
      return this.formInputs.map((input)=>{
        if(input.is_required){
          return input.id
        }
      })
    },

    totalRequiredInputs() {
      return this.formInputs.reduce((total, input) => {
        return input.is_required ? total + 1 : total;
      }, 0);
    },

    selectedRequiredInputs() {
      return this.header.reduce((total, item) => {
        if(item.type === 'form_input_answer' && this.requiredInputsIds.includes(item.input_id)){
          return total + 1
        }
        return total;
      }, 0);
    },

    selectedContactInputs() {
      return this.header.reduce((total, item) => {
        return item.type_contact === 'phone' ? total + 1 : total;
      }, 0);
    },

    groupedFormInputs () {
      const formInputs = _.groupBy(this.formInputs, 'is_required')

      formInputs.contacts = [
        {
          id: 1,
          type: 'contact',
          label: 'Nome do contato',
          type_contact: 'name',
          is_required: true
        },
        {
          id: 2,
          type: 'contact',
          label: this.$t('phone'),
          type_contact: 'phone',
          is_required: true
        },
        {
          id: 3,
          type: 'contact',
          label: 'Email',
          type_contact: "email",
          is_required: false
        }
      ]

      formInputs.opportunity = [
        {
          id: 1,
          type: 'opportunity_name',
          label: 'Nome da oportunidade'
        },
      ]

      return formInputs
    },

    header () {
      return this.preAdjustedCsv.header.map(() => {
        return { is_active: true }
      })
    }
  },

  methods: {
    ...mapActions('crm/opportunities', [
      'fetchFormInputs'
    ]),
    ...mapActions('crm/imports', [
      'createValidateCsv',
      'clearCsvList'
    ]),
    ...mapMutations("crm/imports", ["setHeaderLabels"]),

    selectFieldHeader ({field, isActive ,index}) {
      this.setLabel(field, index)
      this.setHeader(field, isActive ,index)
    },

    setLabel(field, index) {
      field? this.labels[index -1] = field.label : this.labels.splice(index, 1)
    },

    setHeader(field, isActive, index) {
      if(!field) {
        this.labels.splice(index, 1)
        this.header[index - 1] = { is_active: isActive }
        return
      }

      let item = {}

      if(['phone', 'email', 'name'].includes(field.type_contact)) {
        item = {
          type: 'contact',
          type_contact: field.type_contact,
          is_active: isActive
        }
      } else if (field.type === 'opportunity_name') {
        item = {
          type: 'opportunity_name',
          is_active: isActive
        }
      } else {
        item = {
          type: 'form_input_answer',
          input_id: field.id,
          is_active: isActive
        }
      }

      this.header[index - 1] = item
    },

    submit () {
      this.createValidateCsv({
        formData: {
          separator: ',',
          delimiter: 'quotes',
          has_header: true,
          file_name: this.preAdjustedCsv.file_name,
          name: this.preAdjustedCsv.original_name,
          header: this.header,
          funnel_id: this.currentFunnel.id,
        }
      })
      this.setHeaderLabels(this.labels)
    },
  },
}
</script>