<template>
  <hover-button
    ref="workBreakButton"
    @click="toggleDropdown"
    :icon="getWorkBreakDetails.icon"
    :keep-button-hovered="showWorkBreak"
    :background-color="(isConsultHold || status === AgentStatus.CONSULT_CONNECTED) ? '#E1E9F4' : 'var(--color-interval)'"
    :text-color="(isConsultHold || status === AgentStatus.CONSULT_CONNECTED) ? '#677C92' : 'var(--color-text-200)'"
    :disabled="isConsultHold || status === AgentStatus.CONSULT_CONNECTED || scheduledWorkBreak"
    :text="getWorkBreakDetails.text"
  />

  <dropdown-component
    :is-open="showWorkBreak"
    :button-rect="buttonRect"
    :title="$t('capitalizes.break')"
    custom-width="290px"
  >
    <div class="overflow-auto card-work-break-body p-3">
      <div v-if="!loadingFlags.fetchWorkBreakIntervals">
        <div v-if="workBreakIntervals.length">
          <template
            v-for="interval in workBreakIntervals"
            :key="interval.id"
          >
            <div
              v-if="!interval.auto_start"
              class="work-break-item cursor-pointer pb-0"
              @click="enterInterval(interval)"
            >
              <div class="work-break">
                <div class="d-flex align-items-center gap-3">
                  <i class="fa-regular fa-clock tx-interval" />
                  {{ interval.name }}
                </div>
                <div class="tx-text-gray text-medium mb-2">
                  {{ formatSecondsToMMSS(interval.minutes * 60, true) }}
                </div>
              </div>
            </div>
          </template>
        </div>

        <div
          class="d-flex flex-column text-center mt-6"
          v-else
        >
          <h2 class="my-0">
            Oops...
          </h2>
          <img
            :src="require(`@/assets/img/aloisio/aloisioThinking.svg`)"
            class="align-self-center my-4"
            style="width: 68px;"
          >
          <p class="fs-6">
            {{ $t('no_break') }}
          </p>
        </div>
      </div>

      <div
        class="h-100 d-flex flex-column justify-content-center align-items-center"
        v-if="loadingFlags.fetchWorkBreakIntervals"
      >
        <div
          class="spinner-border mb-3 text-primary"
          role="loading"
        >
          <span class="visually-hidden">Loading...</span>
        </div>
        <p>{{ $t('loading_capitalize') }}...</p>
      </div>
    </div>
  </dropdown-component>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { formatSecondsToMMSS } from "@/utils.js";
import HoverButton from "@dialer/agent/components/hover-button.vue";
import DropdownComponent from "@dialer/agent/components/dropdown-component.vue";
import { AgentStatus } from "@/utils";

export default {
  name: "WorkBreakIntervals",
  data() {
    return {
      showWorkBreak: false,
      buttonRect: null,
      AgentStatus
    };
  },

  components: {
    HoverButton,
    DropdownComponent
  },

  computed: {
    ...mapGetters("agent/work-break", {
      workBreakIntervals: "getWorkBreakIntervals",
      loadingFlags: "getLoadingFlags",
      scheduledWorkBreak: "isScheduledWorkBreak",
    }),

    ...mapGetters("system", {
      status: "getStatus",
      isConsultHold: "isConsultHold"
    }),

    ...mapGetters("agent/agent", {
      activeDropdownLeft: "getActiveDropdownLeft"
    }),

    getWorkBreakDetails() {
      if (
        this.status === AgentStatus.ON_CALL ||
        this.status === AgentStatus.ACW ||
        this.status === AgentStatus.ON_MANUAL_CALL_ACW ||
        this.status === AgentStatus.CONSULT_CONNECTED
      ) {
        return {
          text: "",
          icon: "fa-regular fa-coffee"
        };
      }

      if (this.loadingFlags.scheduleWorkBreak) {
        return {
          text: `${this.$t('requesting')}...`,
          icon: "fa-regular fa-clock"
        };
      }

      if (this.scheduledWorkBreak) {
        return {
          text: `${this.$t('requested')}`,
          icon: "fa-regular fa-clock"
        };
      }

      return {
        text: `${this.$t('capitalizes.break')}`,
        icon: "fa-regular fa-coffee"
      };
    }
  },

  methods: {
    formatSecondsToMMSS,
    ...mapActions("agent/work-break", [
      "fetchWorkBreakIntervals",
      "scheduleWorkBreak"
    ]),
    ...mapActions("agent/agent", ["setActiveDropdownLeft"]),

    toggleDropdown() {
      this.showWorkBreak = !this.showWorkBreak;
      if (this.showWorkBreak) {
        this.setActiveDropdownLeft("WorkBreakIntervals");
        this.calculateButtonRect();
        this.fetchWorkBreakIntervals();
        window.addEventListener('resize', this.calculateButtonRect);
      } else {
        window.removeEventListener('resize', this.calculateButtonRect);
      }
    },

    calculateButtonRect() {
      const rect = this.$refs.workBreakButton.$el.getBoundingClientRect();
      this.buttonRect = rect;
    },

    enterInterval({ id }) {
      this.toggleDropdown();
      this.scheduleWorkBreak({ work_break_id: id });
    }
  },

  watch: {
    activeDropdownLeft(newVal) {
      if (newVal !== "WorkBreakIntervals" && this.showWorkBreak) {
        this.toggleDropdown();
      }
    }
  },

  beforeUnmount() {
    window.removeEventListener("resize", this.calculateButtonRect);
  }
}
</script>

<style lang="scss" scoped>
.card-work-break-body {
  height: 350px;
  > div:first-child {
    height: 320px;
    overflow-y: scroll;
  }

  .work-break-item {
    padding: 8px;

    &:hover {
      background-color: $color-gray-100;
      border-radius: 10px;
      border: none;
    }

    &:not(:last-child) {
      .work-break {
        border-bottom: 1px solid $color-gray-blue-300;
      }
    }
  }
}
</style>
