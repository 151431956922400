<template>
  <div
    class="toastbox"
    ref="toastbox"
    :style="{
      top: position == 'top' && 0,
      bottom: position == 'bottom' && 0,
      left: position == 'bottom' && '66px'
    }"
  >
    <div
      class="d-flex flex-column gap-3"
      :class="position == 'top' && 'toast-group-top'"
      id="toast-group"
    >
      <transition-group :name="position == 'top' ? 'slide-top' : 'slide-bottom'">
        <div
          v-for="toast, index in toasts"
          :key="toast.id"
          @mouseover="pauseTime(toast)"
          @mouseleave="continueTime(toast)"
        >
          <div
            class="toast d-flex align-items-start flex-column overflow-hidden justify-content-between"
            :ref="currentToast == toast && 'toast'"
            :style="{ opacity: toast.id == 'delete' ? 0 : 1 }"
          >
            <div
              class="d-flex w-100 ps-4 pe-2 py-3"
              style="height: calc(100% - 5px);"
            >
              <img
                :src="toast.img"
                style="height: 60px;"
              >
              <div
                class="text text-overflow ps-4 fs-5 lh-1"
                :ref="currentToast == toast && 'message'"
              >
                <h2
                  class="m-0 fs-3 py-2"
                  v-if="toast.customizedTitleMessage"
                >
                  {{ toast.customizedTitleMessage }}
                </h2>
                <h2
                  class="m-0 fs-2 py-2"
                  v-else
                >
                  {{ toast.title }}
                </h2>
                <span>{{ $t(toast.message) }}</span>
              </div>
              <button
                class="pe-auto btn-close position-absolute top-0 end-0 mt-2 me-2 fs-5"
                data-test="closeNotification-button"
                @click="deleteNotification(index)"
              />
            </div>
            <div
              class="bg-light w-100 "
              style="height: 5px;"
            >
              <div
                :class="{
                  'progress': currentToast == toast,
                }"
                :ref="currentToast == toast && 'progress'"
                :style="{backgroundColor: toast.color, height: '100%'}"
              />
            </div>
          </div>
        </div>
      </transition-group>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      success: {
        title: this.$t('notification_success'),
        img: require("@/assets/img/notification-pluszinha/success.svg"),
        color: "#5CB868"
      },
      info: {
        title: this.$t('notification_info'),
        img: require("@/assets/img/notification-pluszinha/info.svg"),
        color: "#2C7BE5"
      },
      warning: {
        title: this.$t('notification_attention'),
        img: require("@/assets/img/notification-pluszinha/warning.svg"),
        color: "#FFBD00"
      },
      error: {
        title: this.$t('notification_something_wrong'),
        img: require("@/assets/img/notification-pluszinha/error.svg"),
        color: "#E60B42"
      },
      time: {
        title: this.$t('notification_wait'),
        img: require("@/assets/img/notification-pluszinha/info.svg"),
        color: "#3057F2"
      },

      toasts: [],
      startTime: false,
      currentToast: {},
      timeoutId: '',
      timeToCloseNotification: 5000,
      toastOnHover: false,
    }
  },

  props: {
    position: {
      // top | bottom
      type: String,
      default: 'top'
    }
  },

  computed: {
    ...mapGetters("system", {
      customizedTitleMessage: "getCustomizedTitleMessage",
    }),
  },

  methods: {

    fire({type, message, custom_title}) {
      let toast = {
        id: Math.random(),
        ...this[type],
        message,
        customizedTitleMessage: this.customizedTitleMessage
      }

      if (custom_title) {
        toast.title = custom_title
      }

      this.startTime = true;

      if(this.position == 'top') this.toasts.unshift(toast);
      else this.toasts.push(toast);
    },

    deleteNotification(index) {
      this.toasts.splice(index, 1)
      clearTimeout(this.timeoutId)
      this.startTime = false

      this.modifyToastHeight("compression")
    },

    pauseTime(toast) {
      if(toast == this.currentToast) {
        if(!this.toastOnHover) {
          this.modifyToastHeight("expansion")
        }

        clearTimeout(this.timeoutId)
        const progress = this.$refs.progress
        progress.style.animationPlayState = 'paused';
      }
    },

    continueTime(toast) {
      if(toast == this.currentToast) {
        if(this.toastOnHover) {
          this.modifyToastHeight("compression")
        }

        const progress = this.$refs.progress
        progress.style.animationPlayState = 'running';

        let toastTotalWidth = 350
        let timeLeftInPercentage = parseFloat(window.getComputedStyle(progress).width) / toastTotalWidth
        let timeLeft =  timeLeftInPercentage * this.timeToCloseNotification

        this.timeoutId = setTimeout(() => {
          if(this.position == 'top') this.toasts.pop()
          else this.toasts.shift()

          this.startTime = false;
        }, timeLeft)
      }
    },

    modifyToastHeight(mode) {
      //type Imode = "expansion" | "compression"
      const toast = this.$refs.toast

      let toastHeightBefore = toast.offsetHeight

      const message = this.$refs.message

      if(mode == 'compression') {
        message.classList.add("text-overflow")
        this.toastOnHover = false
      } else {
        message.classList.remove("text-overflow")
        this.toastOnHover = true
      }

      let toastHeightAfter = toast.offsetHeight

      let increase = toastHeightBefore - toastHeightAfter

      const toastbox = this.$refs.toastbox
      let toastboxHeight = toastbox.offsetHeight

      toastbox.style.height  = toastboxHeight - increase + 'px'
    }
  },

  watch: {
    toasts: {
      handler() {
        const toastbox = this.$refs.toastbox

        if(this.position == 'top') this.currentToast = this.toasts[this.toasts.length - 1]
        else this.currentToast = this.toasts[0]

        if(this.toasts.length == 0)
          setTimeout(() => toastbox.style.display = 'none', 1000)
        else toastbox.style.display = 'flex'
      },
      deep: true
    },

    startTime(startTime) {
      if(!startTime && this.toasts.length > 0) {
        this.startTime = true;
      }
      if(startTime) {
        this.timeoutId = setTimeout(() => {
          if(this.position == 'top') this.toasts.pop()
          else this.toasts.shift()

          this.startTime = false;
        }, this.timeToCloseNotification)
      }
    }
  }
};
</script>
<style lang="scss" scoped>

$toastbox-width: 350px;
$toastbox-height: 160px;
$toast-width: 340px;
$toast-border-radius: 10px;

.toastbox {
  left: calc(50% - #{$toastbox-width} / 2);
  position: fixed;
  display: none;
  gap: 10px;
  align-items: center;
  flex-direction: column;
  overflow: hidden;
  width: $toastbox-width;
  height: $toastbox-height;
  padding: 10px;
  z-index: 1060;

  .toast-group-top {
    position: absolute;
    bottom: 0;
    margin-bottom: 10px;
  }

  .toast {
    width: $toast-width;
    background: #FFFFFF;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.16);
    border: none;
    border-radius: $toast-border-radius;
    position: relative;
    bottom: 0;
    overflow: hidden;

    .text {
      color: #737373;
    }
    .text-overflow {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3;
      overflow: hidden;
    }

    .progress {
      height: 100%;
      animation: progress 6s ease;
    }
  }

  .slide-bottom-move,
  .slide-bottom-enter-active,
  .slide-bottom-leave-active,
  .slide-top-move,
  .slide-top-enter-active,
  .slide-top-leave-active {
    transition: all 1s cubic-bezier(0.55, 0, 0.1, 1);
  }

  .slide-bottom-enter-from,
  .slide-bottom-leave-to {
    z-index: -1;
    transform: translateY(200%);
  }
  .slide-top-enter-from,
  .slide-top-leave-to {
    z-index: -1;
    transform: translateY(-200%);
  }
}

@keyframes progress {
  0% {
    width: 100%;
  }
  // ≃ 1s
  17% {
    width: 100%;
  }
  100% {
    width: 0;
  }
}

</style>
