<template>
  <div
    class="alert alert-light alert-csv d-flex align-items-center justify-content-between p-4 gap-4"
    role="alert"
  >
    <i class="fa-regular fa-triangle-exclamation fs-1" />
    <p class="m-0">
      {{ $t('before_file_import') }}<i class="fa-regular fa-gear" />
      {{
        $t('verify_required_fields_and_contact_field')
      }}
    </p>
  </div>
</template>

<script>
export default {
  name: "AlertImport"
}
</script>

<style scoped lang="scss">
.alert-csv{
  background-color: #E1E9F4;
  color: #1A4A89;
}
</style>
