<template>
  <tr>
    <td>
      <div
        class="form-check form-switch"
      >
        <input
          v-model="isActive"
          @input="handleSwitch"
          id="is_active"
          type="checkbox"
          role="switch"
          :disabled="selectedField?.is_required"
          class="form-check-input"
        >
        <label
          class="form-check-label ms-2"
          for="is_active"
        >
          {{ index }}
        </label>
      </div>
    </td>
    <td :class="{'row-disabled': !isActive}">
      <div
        class="dropdown-center"
        style="width: 310px"
      >
        <button
          aria-expanded="false"
          class="form-select d-flex"
          data-bs-toggle="dropdown"
          :disabled="!isActive"
          type="button"
        >
          <span v-if="selectedField">
            {{ selectedField.label }}
          </span>
          <span
            v-else
            class="text-black-50"
          >
            {{ $t('select') }}
          </span>
        </button>
        <div
          id="form-inputs-select"
          class="dropdown-menu overflow-y-scroll shadow-lg"
          style="width: 310px"
        >
          <ul class="px-3 mb-0">
            <li
              v-if="groupedFormInputs.contacts"
              class="list-inline fw-bold px-3"
            >
              Contatos
            </li>
            <li
              v-for="(customField, j) in groupedFormInputs.contacts"
              :key="j"
              :value="customField.id"
              class="dropdown-item cursor-pointer"
              @click="selectField(customField)"
            >
              {{ customField.label }}
              <span
                v-if="customField.is_required"
                class="tx-red-300"
              >*</span>
            </li>
          </ul>
          <ul class="px-3 mb-0">
            <li
              v-if="groupedFormInputs.true"
              class="list-inline fw-bold px-3"
            >
              {{ $t('required') }}
            </li>
            <li
              v-for="(requiredInput, i) in groupedFormInputs.true"
              :key="i"
              :value="requiredInput.id"
              class="dropdown-item cursor-pointer"
              @click="selectField(requiredInput)"
            >
              {{ requiredInput.label }}
              <span class="tx-red-300">*</span>
            </li>
          </ul>
          <ul class="px-3 mb-0">
            <li
              v-if="groupedFormInputs.opportunity"
              class="list-inline fw-bold px-3"
            >
              {{ $t('contacts') }}
            </li>
            <li
              v-for="(customField, j) in groupedFormInputs.opportunity"
              :key="j"
              :value="customField.id"
              class="dropdown-item cursor-pointer"
              @click="selectField(customField)"
            >
              {{ customField.label }}
            </li>
          </ul>
          <ul
            v-if="groupedFormInputs.false"
            class="px-3 mb-0"
          >
            <li
              class="list-inline fw-bold px-3"
            >
              {{ $t('customs') }}
            </li>
            <li
              v-for="(customField, j) in groupedFormInputs.false"
              :key="j"
              :value="customField.id"
              class="dropdown-item cursor-pointer"
              @click="selectField(customField)"
            >
              {{ customField.label }}
            </li>
          </ul>
        </div>
      </div>
    </td>
    <td
      v-for="(item, j) in preAdjustedCsv.data.slice(0,3)"
      :key="j"
      :class="{'row-disabled': !isActive}"
    >
      {{ item[index -1] || '' }}
    </td>
  </tr>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "CsvValidationRowItem",

  emits: ['select'],

  props: {
    index: {
      type: Number,
      required: true
    },
    groupedFormInputs: {
      type: Object,
      required: true
    }
  },

  data () {
    return {
      isActive: true,
      selectedField: null
    }
  },

  computed: {
    ...mapGetters('crm/imports', {
      preAdjustedCsv: "getPreAdjustedCsv",
      errors: "getErrors"
    }),
  },

  methods: {
    selectField (field) {
      if (this.selectedField?.funnel_id && field.funnel_id) {
        field = this.selectedField.id === field.id ? null : field
      }
      else if (this.selectedField?.type_contact && field.type_contact) {
        field = this.selectedField.id === field.id ? null : field
      }
      else if (this.selectedField?.type === 'opportunity_name' && field.type === 'opportunity_name') {
        field = null
      }

      this.selectedField = field

      this.$emit('select', {
        field,
        isActive: this.isActive,
        index: this.index
      })
    },

    handleSwitch(event){
      this.$emit('select', {
        field: this.selectedField,
        isActive: event.target.checked,
        index: this.index
      })
    }
  }
}
</script>

<style scoped lang="scss">
.row-disabled {
  opacity: 0.4;
}
</style>