<template>
  <div
    ref="offcanvas"
    class="offcanvas offcanvas-end"
    style="overflow-y: scroll; width: 660px !important"
  >
    <div class="offcanvas-header">
      <div
        class="offcanvas-title d-flex flex-column justify-content-center align-items-center w-100"
      >
        <h2 class="m-2">
          {{ $t("call_information") }}
        </h2>
        <span class="text-muted fs-3 text-center">
          {{ $t("mailing_info") }}
          <br>
          {{ $t("call_info") }}
        </span>
      </div>
      <button
        type="button"
        class="btn-close text-reset fs-1 mb-6"
        data-bs-dismiss="offcanvas"
        aria-label="Close"
      />
    </div>

    <div class="offcanvas-body text-start">
      <h3>{{ $t("abstract") }}</h3>
      <div v-if="call">
        <div class="row mb-5">
          <div class="col-12 col-xl-3 col-lg-6 mb-3">
            <small class="text-muted">{{ $t("agent_capitalize") }}</small><br>
            <small>{{ call.agent }}</small>
          </div>

          <div class="col-12 col-xl-3 col-lg-6 mb-3">
            <small class="text-muted">{{ $t("number") }}</small><br>
            <small>{{ formatPhoneNumber(call.number) }}</small>
          </div>

          <div class="col-12 col-xl-3 col-lg-6 mb-3">
            <small class="text-muted">{{ $t("date_capitalize") }}</small><br>
            <small>{{ formatDate(call.call_date) }}</small>
          </div>

          <div class="col-12 col-xl-3 col-lg-6 mb-3">
            <small class="text-muted">{{ $t("qualification") }}</small><br>
            <small>{{ call.qualification }}</small>
          </div>

          <div class="col-12">
            <small class="text-muted">
              {{ $t("call") }}
            </small>
            <audio
              v-if="updateAudio"
              preload="none"
              controls
              controlsList="nodownload"
              class="w-100 mt-2"
              ref="audio"
            >
              <source
                :src="call.recording"
                type="audio/mpeg"
              >
            </audio>
          </div>
        </div>
        <h3 v-if="call.qualification_note">
          {{ $t("comment") }}
        </h3>
        <div
          class="row mb-3"
          v-if="call.qualification_note"
        >
          <div class="col-12">
            <div class="card p-3 bg-gray-blue-200">
              <span class="w-100 tx-text-gray m-0">
                {{ call.qualification_note }}
              </span>
            </div>
          </div>
        </div>
        <h3 v-if="Object.values(call.mailing_data).length">
          {{ $t("mailing_data") }}
        </h3>
        <div
          class="row mb-5"
          v-if="Object.values(call.mailing_data).length"
        >
          <div class="col-12 mb-3">
            <small class="text-muted">{{ $t("identifier") }}</small><br>
            <small>{{ call.mailing_data.identifier }}</small>
          </div>

          <div
            v-for="(item, key) in call.mailing_data.data"
            :key="key"
            class="col-12 mb-3"
          >
            <small class="text-muted">{{ key }}</small><br>
            <small>{{ item }}</small>
          </div>
        </div>

        <h3>{{ $t("call_data") }}</h3>
        <div class="row">
          <div class="col-12 mb-3">
            <small class="text-muted">{{ $t("campaign_capitalize") }}</small><br>
            <small>{{ call.campaign }}</small>
          </div>

          <div class="col-12 col-xl-6 col-lg-6 mb-3">
            <small class="text-muted">{{ $t("detection_time_call") }}</small><br>
            <small>{{ call.amd_time }}</small>
          </div>

          <div class="col-12 col-xl-6 col-lg-6 mb-3">
            <small class="text-muted">{{ $t("time_in_waiting") }}</small><br>
            <small>{{ call.waiting_time }}</small>
          </div>

          <div class="col-12 col-xl-6 col-lg-6 mb-3">
            <small class="text-muted">{{ $t("conversation_time") }}</small><br>
            <small>{{ call.speaking_time }}</small>
          </div>

          <div class="col-12 col-xl-6 col-lg-6 mb-3">
            <small class="text-muted">{{ $t("post_service_time") }}</small><br>
            <small>{{ call.acw_time }}</small>
          </div>

          <div class="col-12 col-xl-6 col-lg-6 mb-3">
            <small class="text-muted">{{ $t("type_general") }}</small><br>
            <small>{{ call.phone_type == "mobile" ? "Móvel" : "Fixo" }}</small>
          </div>

          <div class="col-12 col-xl-6 col-lg-6 mb-3">
            <small class="text-muted">{{ $t("status_omni") }}</small><br>
            <small>{{ call.readable_status_text }}</small>
          </div>

          <div class="col-12 col-xl-6 col-lg-6 mb-3">
            <small class="text-muted">{{ $t("billed_time") }}</small><br>
            <small>{{ call.billed_time }}</small>
          </div>

          <div class="col-12 col-xl-6 col-lg-6 mb-3">
            <small class="text-muted">{{ $t("value") }}</small><br>
            <small>{{ call.billed_value }}</small>
          </div>

          <div class="col-12 col-xl-6 col-lg-6 mb-3">
            <small class="text-muted">{{ $t("qualification") }}</small><br>
            <small>{{ call.qualification }}</small>
          </div>

          <div class="col-12 col-xl-6 col-lg-6 mb-3">
            <small class="text-muted">{{ $t("post_call_detection") }}</small><br>
            <small>{{ call.readable_amd_status_text }}</small>
          </div>

          <div class="col-12 col-xl-6 col-lg-6 mb-3">
            <small class="text-muted">{{ $t("ending_call_cause") }}</small><br>
            <small>{{ call.readable_hangup_cause_text }}</small>
          </div>

          <div class="col-12 col-xl-6 col-lg-6 mb-3">
            <small class="text-muted">{{ $t("call_mode_capitalize") }}</small><br>
            <small>{{ getCallMode(call.mode) }}</small>
          </div>

          <div class="col-12">
            <div
              class="row"
              v-if="call.consults.length"
            >
              <div
                class="col-12 col-xl-6 col-lg-6 mb-3"
                v-for="(consult, index) in call.consults"
                :key="index"
              >
                <small class="text-muted">{{ $t("consult") }} {{ index + 1 }}</small><br>
                <button
                  class="btn btn-outline-secondary btn-sm"
                  @click="openURLOnOffcanvas(call.consult_ids[index])"
                >
                  {{ $t("access_consult") }}
                </button>
              </div>
            </div>
          </div>

          <div
            class="col-12 col-xl-6 col-lg-6 mb-3"
            v-if="call.is_transferred"
          >
            <small class="text-muted">{{ $t("transfer_capitalize") }}</small><br>
            <button
              class="btn btn-outline-secondary btn-sm"
              @click="openURLOnOffcanvas(call.transfer_id)"
            >
              {{ $t("access_transfer") }}
            </button>
          </div>

          <div
            class="col-12 col-xl-6 col-lg-6 mb-3"
            v-if="call.is_consult"
          >
            <small class="text-muted">{{ $t("original_call") }}</small><br>
            <button
              class="btn btn-outline-secondary btn-sm"
              @click="openURLOnOffcanvas(call.parent_id)"
            >
              {{ $t("access_previous_call") }}
            </button>
          </div>

          <div
            class="col-12 col-xl-6 col-lg-6 mb-3"
            v-if="call.is_transfer"
          >
            <small class="text-muted">{{ $t("original_consult") }}</small><br>
            <button
              class="btn btn-outline-secondary btn-sm"
              @click="openURLOnOffcanvas(call.consult_id)"
            >
              {{ $t("access_consult") }}
            </button>
          </div>

          <div
            class="col-12 col-xl-6 col-lg-6 mb-3"
            v-if="call.is_transfer"
          >
            <small class="text-muted">{{ $t("original_consult") }}</small><br>
            <button
              class="btn btn-outline-secondary btn-sm"
              @click="openURLOnOffcanvas(call.parent_id)"
            >
              {{ $t("access_consult") }}
            </button>
          </div>

          <div class="col-12 mb-3 overflow-ellipsis">
            <small class="text-muted">{{ $t("amd_record") }}</small><br>

            <audio
              v-if="updateAudio"
              preload="none"
              controls
              class="w-100"
            >
              <source
                :src="
                  call.recording_amd
                "
                type="audio/mpeg"
              >
            </audio>
          </div>

          <div
            class="col-12 mb-3 overflow-ellipsis"
            v-if="call.consult_cancelled"
          >
            <small class="text-muted">{{ $t("after_cancel_call_record") }}</small><br>

            <audio
              preload="none"
              controls
              class="w-100"
            >
              <source
                :src="
                  call.recording_after_consult_cancel
                "
                type="audio/mpeg"
              >
            </audio>
          </div>
        </div>
        <more-details :call="call" />
      </div>
    </div>
  </div>

  <div
    v-if="loadedCall"
    ref="loadedCallOffcanvas"
    class="offcanvas offcanvas-end"
    style="overflow-y: scroll; width: 660px !important"
  >
    <div class="offcanvas-header d-flex flex-column">
      <button
        type="button"
        class="btn-close text-reset fs-1"
        data-bs-dismiss="offcanvas"
        aria-label="Close"
      />
      <h2 class="offcanvas-title">
        {{ $t("call_information") }}
      </h2>
      <span class="text-muted fs-3">
        {{ $t("mailing_info") }}
        <br>
        {{ $t("call_info") }}
      </span>
    </div>

    <div class="offcanvas-body text-start">
      <h3>{{ $t("abstract") }}</h3>
      <div class="row mb-5">
        <div class="col-12 col-xl-3 col-lg-6 mb-3">
          <small class="text-muted">{{ $t("agent_capitalize") }}</small><br>
          <small>{{ loadedCall.agent }}</small>
        </div>

        <div class="col-12 col-xl-3 col-lg-6 mb-3">
          <small class="text-muted">{{ $t("number") }}</small><br>
          <small>{{ formatPhoneNumber(loadedCall.number) }}</small>
        </div>

        <div class="col-12 col-xl-3 col-lg-6 mb-3">
          <small class="text-muted">{{ $t("date_capitalize") }}</small><br>
          <small>{{ formatDate(loadedCall.date) }}</small>
        </div>

        <div class="col-12 col-xl-3 col-lg-6 mb-3">
          <small class="text-muted">{{ $t("qualification") }}</small><br>
          <small>{{ loadedCall.qualification }}</small>
        </div>

        <div class="col-12">
          <small class="text-muted ">{{ $t("call") }}</small><br>
          <audio
            preload="none"
            controls
            class="w-100"
          >
            <source
              :src="loadedCall.recording"
              type="audio/mpeg"
            >
          </audio>
        </div>
      </div>

      <h3 v-if="Object.values(loadedCall.mailing_data).length">
        {{ $t("mailing_data") }}
      </h3>
      <div
        class="row mb-5"
        v-if="Object.values(loadedCall.mailing_data).length"
      >
        <div class="col-12 mb-3">
          <small class="text-muted">{{ $t("identifier") }}</small><br>
          <small>{{ loadedCall.mailing_data.identifier }}</small>
        </div>

        <div
          v-for="(item, key) in loadedCall.mailing_data.data"
          :key="key"
          class="col-12 mb-3"
        >
          <small class="text-muted">{{ key }}</small><br>
          <small>{{ item }}</small>
        </div>
      </div>

      <h3>{{ $t("call_data") }}</h3>
      <div class="row mb-5">
        <div class="col-12 mb-3">
          <small class="text-muted">{{ $t("campaign_capitalize") }}</small><br>
          <small>{{ loadedCall.campaign }}</small>
        </div>

        <div class="col-12 col-xl-6 col-lg-6 mb-3">
          <small class="text-muted">{{ $t("detection_time_call") }}</small><br>
          <small>{{ loadedCall.amd_time }}</small>
        </div>

        <div class="col-12 col-xl-6 col-lg-6 mb-3">
          <small class="text-muted">{{ $t("time_in_waiting") }}</small><br>
          <small>{{ loadedCall.waiting_time }}</small>
        </div>

        <div class="col-12 col-xl-6 col-lg-6 mb-3">
          <small class="text-muted">{{ $t("conversation_time") }}</small><br>
          <small>{{ loadedCall.speaking_time }}</small>
        </div>

        <div class="col-12 col-xl-6 col-lg-6 mb-3">
          <small class="text-muted">{{ $t("post_service_time") }}</small><br>
          <small>{{ loadedCall.acw_time }}</small>
        </div>

        <div class="col-12 col-xl-6 col-lg-6 mb-3">
          <small class="text-muted">{{ $t("type_general") }}</small><br>
          <small>{{ loadedCall.phone_type == "mobile" ? $t("mobile") : $t("landline") }}</small>
        </div>

        <div class="col-12 col-xl-6 col-lg-6 mb-3">
          <small class="text-muted">{{ $t("status_omni") }}</small><br>
          <small>{{ loadedCall.readable_status_text }}</small>
        </div>

        <div class="col-12 col-xl-6 col-lg-6 mb-3">
          <small class="text-muted">{{ $t("billed_time") }}</small><br>
          <small>{{ loadedCall.billed_time }}</small>
        </div>

        <div class="col-12 col-xl-6 col-lg-6 mb-3">
          <small class="text-muted">{{ $t("value") }}</small><br>
          <small>{{ loadedCall.billed_value }}</small>
        </div>

        <div class="col-12 col-xl-6 col-lg-6 mb-3">
          <small class="text-muted">{{ $t("qualification") }}</small><br>
          <small>{{ loadedCall.qualification }}</small>
        </div>

        <div class="col-12 col-xl-6 col-lg-6 mb-3">
          <small class="text-muted">{{ $t("post_call_detection") }}</small><br>
          <small>{{ loadedCall.readable_amd_status_text }}</small>
        </div>

        <div class="col-12 col-xl-6 col-lg-6 mb-3">
          <small class="text-muted">{{ $t("ending_call_cause") }}</small><br>
          <small>{{ loadedCall.readable_hangup_cause_text }}</small>
        </div>

        <div class="col-12 col-xl-6 col-lg-6 mb-3">
          <small class="text-muted">{{ $t("call_mode_capitalize") }}</small><br>
          <small>{{ getCallMode(loadedCall.mode) }}
          </small>
        </div>

        <div class="col-12">
          <div
            class="row"
            v-if="loadedCall.consults.length"
          >
            <div
              class="col-12 col-xl-6 col-lg-6 mb-3"
              v-for="(consult, index) in loadedCall.consults"
              :key="index"
            >
              <small class="text-muted">{{ $t("consult") }} {{ index + 1 }}</small><br>
              <button
                class="btn btn-outline-secondary btn-sm"
                @click="openURLOnOffcanvas(loadedCall.consult_ids[index])"
              >
                {{ $t("access_consult") }}
              </button>
            </div>
          </div>
        </div>

        <div
          class="col-12 col-xl-6 col-lg-6 mb-3"
          v-if="loadedCall.is_transferred"
        >
          <small class="text-muted">{{ $t("transfer_capitalize") }}</small><br>
          <button
            class="btn btn-outline-secondary btn-sm"
            @click="openURLOnOffcanvas(loadedCall.transfer_id)"
          >
            {{ $t("access_transfer") }}
          </button>
        </div>

        <div
          class="col-12 col-xl-6 col-lg-6 mb-3"
          v-if="loadedCall.is_consult"
        >
          <small class="text-muted">{{ $t("original_call") }}</small><br>
          <button
            class="btn btn-outline-secondary btn-sm"
            @click="openURLOnOffcanvas(loadedCall.parent_id)"
          >
            {{ $t("access_previous_call") }}
          </button>
        </div>

        <div
          class="col-12 col-xl-6 col-lg-6 mb-3"
          v-if="loadedCall.is_transfer"
        >
          <small class="text-muted">{{ $t("original_consult") }}</small><br>
          <button
            class="btn btn-outline-secondary btn-sm"
            @click="openURLOnOffcanvas(loadedCall.consult_id)"
          >
            {{ $t("access_consult") }}
          </button>
        </div>

        <div
          class="col-12 col-xl-6 col-lg-6 mb-3"
          v-if="loadedCall.is_transfer"
        >
          <small class="text-muted">{{ $t("original_call") }}</small><br>
          <button
            class="btn btn-outline-secondary btn-sm"
            @click="openURLOnOffcanvas(loadedCall.parent_id)"
          >
            {{ $t("access_consult") }}
          </button>
        </div>

        <div class="col-12 mb-3 overflow-ellipsis">
          <small class="text-muted">{{ $t("amd_record") }}</small><br>

          <audio
            preload="none"
            controls
            class="w-100"
          >
            <source
              :src="
                loadedCall.recording_amd
              "
              type="audio/mpeg"
            >
          </audio>
        </div>

        <div
          class="col-12 mb-3 overflow-ellipsis"
          v-if="loadedCall.consult_cancelled"
        >
          <small class="text-muted">{{ $t("after_cancel_call_record") }}</small><br>

          <audio
            preload="none"
            controls
            class="w-100"
          >
            <source
              :src="
                loadedCall.recording_after_consult_cancel
              "
              type="audio/mpeg"
            >
          </audio>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Offcanvas } from "bootstrap";
import { formatPhoneNumber } from "@/utils";
import { mapActions, mapGetters } from "vuex";
import moment from "moment";

import MoreDetails from "./more-details.vue";
import { nextTick } from "vue";

export default {
  data() {
    return {
      offCanvas: null,
      updateAudio: false,
    };
  },

  emits: ["close", "blob"],

  computed: {
    ...mapGetters("auth", {
      user: "getUser",
    }),

    ...mapGetters("manager/calls-report", {
      loadedCall: "getLoadedCall",
    }),
  },

  mounted() {
    this.offCanvas = new Offcanvas(this.$refs.offcanvas);
    this.$refs.offcanvas.addEventListener("hide.bs.offcanvas", this.onHideOffcanvas);
  },

  beforeUnmount() {
    this.offCanvas.hide();
  },

  props: {
    call: {
      type: Object,
      default: () => null,
    },

    show: {
      type: Boolean,
      default: false,
    },

    map: {
      type: Object,
      default: () => {},
    },
    getCallMode: {
      type: Function,
      default: () => {},
    },

  },

  components: {
    MoreDetails,
  },

  methods: {
    formatPhoneNumber,

    ...mapActions("manager/calls-report", ["loadCallById"]),

    formatDate(date) {
      return moment(date)
          .format("DD/MM/YYYY [às] HH:mm");
    },

    openURLOnOffcanvas(url) {
      this.loadCallById({ id: url })
          .then(() => {
            let loadedCallOffCanvas = new Offcanvas(this.$refs.loadedCallOffcanvas);
            loadedCallOffCanvas.show();
          });
    },

    onHideOffcanvas() {
      this.$emit("close");
    },
  },

  watch: {
    show(data) {
      if (data) {
        this.offCanvas.show();
        nextTick()
            .then(() => this.updateAudio = true);
      } else {
        this.offCanvas.hide();
        this.updateAudio = false;
      }
    },
  },
};
</script>
