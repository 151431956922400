<script>
import AppButton from "@components/app-button.vue";
import SingleSelect from "@components/select/single-select.vue";
import DateRangeBtn from "@crm/pages/dashboard/components/date-range-btn.vue";

export default {
  name: "FiltersBar",
  components: {
    AppButton,
    SingleSelect,
    DateRangeBtn
  },

  data() {
    return {
      filtersButtons: [
        {
          label: this.$t("capitalizes.today"),
          value: 'days'
        },
        {
          label: this.$t("capitalizes.week"),
          value: 'week'
        },
        {
          label: this.$t("capitalizes.month"),
          value: 'month'
        },
        {
          label: this.$t("capitalizes.quarter"),
          value: 'quarter'
        },
        {
          label: this.$t("capitalizes.semester"),
          value: 'semester'
        },
        {
          label: this.$t("capitalizes.year"),
          value: 'year'
        },
        {
          label: this.$t("capitalizes.custom"),
          value: 'custom'
        },
      ]
    }
  },
}
</script>

<script setup>
import {ref, defineProps, defineEmits, computed, onMounted} from "vue";
import store from "@store";

defineProps({
  activeDateRange: {
    type: String,
    default: ""
  }
});

const startDate = ref('');
const endDate = ref('')

const emit = defineEmits([
  'filter-change',
  'agent-change',
]);

const currentFunnel = computed(() => store.getters["crm/funnels/getCurrentFunnel"])
const agents = computed(() => store.getters["crm/contacts/getTeamAgents"]);
const dateRange = computed(() => store.getters["crm/dashboard/getDateRange"])

onMounted(() => {
  store.dispatch("crm/contacts/fetchUsersByTeam", {
    teamId: currentFunnel.value.team.id,
  });
});

const activeFiltersModel = ref('');


function setCustomDate(range) {
  emit('filter-change', {
    startDate: startDate.value,
    endDate: endDate.value,
    range: range
  });
}

function setDateRange(range) {
  if (dateRange.value.value !== range) {
    emit('filter-change', range)
  }
}

function setAgent() {
  if (agents.value !== activeFiltersModel.value) {
    emit('agent-change', activeFiltersModel)
  }
}

</script>

<template>
  <div class="d-flex gap-4 justify-content-between align-items-center my-4">
    <div
      style="gap: 0.5rem"
      class="d-flex flex-wrap"
    >
      <div
        v-for="(filter, fIndex) in filtersButtons"
        :key="`filter-${fIndex}`"
        class="w-auto"
      >
        <date-range-btn
          v-if="filter.value === 'custom'"
          @close="setCustomDate(filter.value)"
          :placeholder="filter.label"
          :custom-class="activeDateRange !== filter.value ? 'btn-gray-blue' : 'btn-primary'"
          v-model:start="startDate"
          v-model:end="endDate"
        />

        <app-button
          v-else
          style="padding-left: 1rem; padding-right: 1rem"
          class="btn-sm"
          :color="activeDateRange !== filter.value ? 'btn-gray-blue' : 'btn-primary'"
          @click="setDateRange(filter.value)"
        >
          {{ filter.label }}
        </app-button>
      </div>
    </div>

    <div style="min-width: 308px;">
      <single-select
        select-height="32px"
        fontsize="14px"
        padding="5px 16px"
        :placeholder="$t('select')"
        :list-data="agents"
        label="name"
        custom-label-return="id"
        v-model="activeFiltersModel"
        @update:model-value="setAgent"
      />
    </div>
  </div>
</template>

<style scoped lang="scss">
.btn-sm {
  border: none;

  &:focus {
    box-shadow: none;
  }
}
</style>