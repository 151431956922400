<template>
  <div class="container-fluid">
    <div
      class="row"
      v-if="!$route.params.id || ($route.params.id && ringGroup)"
    >
      <div class="col-12">
        <header-component
          :pretitle="$t('uppercases.extension_groups')"
          :title="$route.params.id ? ringGroup.name : $t('new_extension_group')"
          redirect-link="/manager/ring-group"
        />
        <ul
          class="nav nav-tabs"
          id="nav-tab"
          role="tablist"
        >
          <li
            class="nav-item"
            role="presentation"
          >
            <button
              class="nav-link active"
              id="nav-general-tab"
              data-bs-toggle="tab"
              data-bs-target="#general"
              role="tab"
              aria-controls="general"
              aria-selected="true"
              ref="tab-general"
              @click="changeTabByName('general')"
              data-test="generalRingGroupTab-button"
            >
              {{ $t("general_capitalize") }}
            </button>
          </li>
          <li
            class="nav-item"
            role="presentation"
          >
            <button
              class="nav-link"
              id="nav-extensions-tab"
              data-bs-toggle="tab"
              data-bs-target="#extensions"
              role="tab"
              aria-controls="extensions"
              aria-selected="false"
              ref="tab-extensions"
              @click="changeTabByName('extensions')"
              data-test="extensionsRingGroupTab-button"
            >
              {{ $t("extensions") }}
            </button>
          </li>
          <li
            class="nav-item"
            role="presentation"
          >
            <button
              class="nav-link"
              id="nav-distribution-tab"
              data-bs-toggle="tab"
              data-bs-target="#distribution"
              role="tab"
              aria-controls="distribution"
              aria-selected="false"
              ref="tab-distribution"
              @click="changeTabByName('distribution')"
              data-test="distributionRingGroupTab-button"
            >
              {{ $t("distribution_mode") }}
            </button>
          </li>
        </ul>
        <div class="tab-content">
          <general-settings
            v-model:name="fields.name"
            v-model:extension-number="fields.extension_number"
            v-model:acw-timeout="fields.acw_timeout"
            v-model:audio="fields.audio"
            v-model:audio-path="fields.audio_path"
            v-model:original-audio-name="fields.original_audio_name"
            v-model:audio-delete="fields.audio_delete"
          />

          <extension-settings
            v-model:agent-skills="fields.agent_skills"
            :options="users_with_ring_group_access"
            v-model="fields.agent_skills"
          />

          <distribution-settings
            v-model:wait-time-on-call="fields.wait_time_on_call"
            v-model:wait-time-by-extension-number="
              fields.wait_time_by_extension_number
            "
            v-model:action-when-the-phone-rings="
              fields.action_when_the_phone_rings
            "
          />
          <!-- Por enquanto não vai existir redirectionamento -->
          <!-- v-model:forwarding-type="fields.forwardingType"
            v-model:receptive-queue="fields.receptiveQueue"
            v-model:receptive-ivr="fields.receptiveIvr"
            v-model:ring-group="fields.ringGroup" -->

          <div class="row justify-content-between">
            <div
              class="col-12 mt-4"
              v-if="currentTab < totalTabs"
            >
              <app-button
                color="btn btn-primary"
                class="w-100"
                @click="changeTab(true)"
              >
                {{ $t("next_capitalize") }}
              </app-button>
            </div>
            <div
              class="col-12 mt-4"
              v-if="currentTab === totalTabs"
            >
              <app-button
                color="btn btn-primary"
                class="w-100"
                @click="submit()"
                :loading="loadingFlags.updateRingGroup || loadingFlags.createRingGroup"
              >
                {{ $t("capitalizes.save") }}
              </app-button>
            </div>
            <div
              class="row"
              v-if="currentTab > 1"
            >
              <div
                class="mt-4"
                :class="(currentTab === totalTabs && $route.params.id) ? 'col-6' : 'col-12'"
              >
                <app-button
                  color="btn btn-light"
                  class="w-100"
                  @click="changeTab(false)"
                >
                  {{ $t("back_capitalize") }}
                </app-button>
              </div>
              <div
                class="col-6 mt-4"
                v-if="currentTab === totalTabs && $route.params.id"
              >
                <app-button
                  :loading="loadingFlags['delete_ring_group']"
                  class="w-100"
                  color="btn-outline-danger"
                  @click="remove"
                >
                  <span class="fe fe-trash me-2" />
                  {{ $t("delete_extension_group") }}
                </app-button>
              </div>
            </div>
          </div>
        </div>
        <br>
        <br>
      </div>
    </div>
  </div>
</template>

<script>
import { Tab } from "bootstrap";
import { mapActions, mapGetters, mapMutations } from "vuex";
import { convertSecondsToTime } from "@/utils.js";
import GeneralSettings from "./components/general-settings.vue";
import ExtensionSettings from "./components/extension-settings.vue";
import DistributionSettings from "./components/distribution-settings.vue";
import AppButton from "@components/app-button";
import HeaderComponent from "@/components/header-component.vue";

export default {
  data() {
    return {
      fields: {
        name: "",
        extension_number: 0,
        acw_timeout: "00:00:30",
        priority: 0,
        limit_call_time: 0,
        ivr_after_call_id: 0,
        audio_path: "",
        original_audio_name: "",
        audio: null,
        audio_delete: 0,
        wait_time_on_call: 0,
        wait_time_by_extension_number: 0,
        agent_skills: [
          {
            skill: 1,
            ring_group_users: [],
          },
        ],
        action_when_the_phone_rings: 1,
        // Por enquanto não vai ter redirecionamento
        // forwardingType: null,
        // receptiveQueue: null,
        // receptiveIvr: null,
        // ringGroup: null
      },
      tab_general: null,
      tab_extension: null,
      tab_distribution: null,
      currentTab: 1,
      totalTabs: 3,
      users_with_ring_group_access: [],
    };
  },

  components: {
    AppButton,
    HeaderComponent,
    GeneralSettings,
    ExtensionSettings,
    DistributionSettings,
  },

  computed: {
    ...mapGetters("manager/filters", {
      filters: "getFilters",
    }),

    ...mapGetters("auth", {
      user: "getUser",
    }),

    ...mapGetters("manager/ring_group", {
      ringGroup: "getRingGroup",
      loadingFlags: "getLoadingFlags",
      erros: "getErrors",
    }),

    tabs() {
      return [this.tab_general, this.tab_extension, this.tab_distribution];
    },
  },

  mounted() {
    if (this.$route.params.id) {
      this.findRingGroupById({ id: this.$route.params.id });
      this.findActiveAgents({ id: this.$route.params.id });
    }

    if (!this.$route.params.id) {
      this.createTabs();
    }

    this.setIncludes([
      "users_with_ring_group_access",
    ]);

    this.findFilters();
  },

  unmounted() {
    this.clearErrors();
  },
  methods: {
    ...mapActions("manager/ring_group", [
      "createRingGroup",
      "findRingGroupById",
      "updateRingGroup",
      "deleteRingGroup",
      "findActiveAgents",
    ]),

    ...mapActions("manager/filters", ["findFilters"]),
    ...mapActions("system", ["showConfirmationMessage"]),

    ...mapMutations("manager/filters", ["setIncludes"]),
    ...mapMutations("manager/ring_group", ["clearErrors"]),
    convertSecondsToTime,

    createTabs() {
      this.tab_general = new Tab(this.$refs["tab-general"]);
      this.tab_extension = new Tab(this.$refs["tab-extensions"]);
      this.tab_distribution = new Tab(this.$refs["tab-distribution"]);
    },

    changeTab(isNextTab) {
      let newTab = this.currentTab;
      isNextTab ? newTab++ : newTab--;
      this.currentTab = newTab;
      newTab--;
      this.tabs[newTab].show();
    },

    changeTabByName(tabName) {
      let index = 0;
      switch (tabName) {
        case "general":
          index = 0;
          break;
        case "extensions":
          index = 1;
          break;
        case "distribution":
          index = 2;
          break;
        default:
          break;
      }
      this.currentTab = index + 1;
      this.tabs[index].show();
    },

    getFormData() {
      const formData = new FormData();
      formData.append("name", this.fields.name);
      formData.append("extension_number", this.fields.extension_number);
      formData.append("priority", this.fields.priority);
      formData.append("wait_time_on_call", this.fields.wait_time_on_call * 60)
      formData.append(
          "wait_time_by_extension_number",
          this.fields.wait_time_by_extension_number,
      );
      formData.append(
          "action_when_the_phone_rings",
          this.fields.action_when_the_phone_rings,
      );

      // COMMENTS HIDE THE FORWARDING OPTIONS
      // if (this.fields.forwardingType !== "nenhum") {
      //   formData.append("forwarding_type", this.fields.forwardingType);
      // }
      // if (this.fields.receptiveQueue)
      //   formData.append("receptive_queue_id", this.fields.receptiveQueue.id);
      // if (this.fields.receptiveIvr)
      //   formData.append("receptive_ivr_id", this.fields.receptiveIvr.id);
      // if (this.fields.ringGroup)
      //   formData.append("ring_group_id", this.fields.ringGroup.id);
      // if (
      //   this.fields.ivr_after_call_id !== 0 &&
      //   this.fields.ivr_after_call_id !== null
      // ) {
      //   formData.append("ivr_after_call_id", this.fields.ivr_after_call_id);
      // }
      if (this.fields.audio) {
        formData.append("audio", this.fields.audio);
      }
      if (this.fields.audio_delete) {
        formData.append("audio_delete", 1);
      }
      const totalAgentsCount = this.fields.agent_skills.reduce(
          (previous, current) => previous + current.ring_group_users.length,
          0,
      );
      if (totalAgentsCount) {
        this.fields.agent_skills.forEach(agent_skill => {
          agent_skill.ring_group_users.forEach(({ id }) => {
            formData.append(`users[${agent_skill.skill}][]`, id);
          });
        });
      }
      return formData;
    },

    submit() {
      if (this.$route.params.id) {
        this.edit();
      } else {
        this.create();
      }
    },

    create() {
      let formData = this.getFormData();
      this.createRingGroup({ formData })
          .then((response) => {
                this.findRingGroupById({ id: response.id })
                    .then(() => {
                      this.$router.push(`/manager/ring-group/${response.id}/edit`);
                      this.tab_general.show();
                      this.currentTab = 1;
                      this.fields.audio = null;
                    })
              },
          )
    },

    edit() {
      let formData = this.getFormData();
      formData.append("_method", "PUT");
      this.updateRingGroup({
        formData,
        id: this.$route.params.id,
      })
          .then(() => {
            this.findRingGroupById({
              id: this.$route.params.id,
            });
          })
          .finally(() => {
            this.fields.audio = null;
            if (!this.erros) this.currentTab = 1;
            this.$router.push(`/manager/ring-group/${this.$route.params.id}/edit`);
          });
    },

    async remove() {
      let response = await this.showConfirmationMessage({
        title: this.$t("delete_extension_group_confirmation"),
        text: this.$t("group_deletion_cannot_be_undone"),
        type: "badWarning",
      });

      if (response.isConfirmed) {
        this.deleteRingGroup({ id: this.$route.params.id })
            .then(() => {
              this.$router.push("/manager/ring-group");
            });
      }
    },
  },

  watch: {
    ringGroup: {
      handler(data) {
        if (data) {
          this.$nextTick(() => {
            this.createTabs();
          });
          this.fields.name = data.name;
          this.fields.extension_number = data.extension.extension_number;
          this.fields.priority = data.priority;
          this.fields.limit_call_time = data.limit_call_time;
          this.fields.limit_call_time = data.limit_call_time;
          this.fields.ivr_after_call_id = data.ivr_after_call
              ? data.ivr_after_call.id
              : 0;
          this.fields.audio_path = data.audio_path;
          this.fields.original_audio_name = data.original_audio_name;
          this.fields.wait_time_on_call = data.wait_time_on_call;
          this.fields.wait_time_by_extension_number =
              data.wait_time_by_extension_number;
          this.fields.action_when_the_phone_rings =
              data.action_when_the_phone_rings;
          // COMMENTS HIDE THE FORWARDING OPTIONS
          // this.fields.forwardingType = data.forwarding.type == null ? "nenhum" : data.forwarding.type;
          // this.fields.receptiveQueue = data.receptive_queue;
          // this.fields.receptiveIvr = data.receptive_ivr;
          // this.fields.ringGroup = data.ring_group;

          if (data.ring_group_users.length) {
            let agentSkills = [];
            const createSkills = skill => {
              for (let i = agentSkills.length; i < skill; i++) {
                agentSkills.push({
                  skill: i + 1,
                  ring_group_users: [],
                });
              }
            };

            for (let user of data.ring_group_users) {
              createSkills(user.pivot_preference);
              agentSkills[user.pivot_preference - 1].ring_group_users.push(
                  user,
              );
            }
            this.fields.agent_skills = agentSkills;
          }
        }
      },
      deep: true,
    },

    filters: {
      handler(data) {
        if (data.users_with_ring_group_access) {
          this.users_with_ring_group_access = data.users_with_ring_group_access;
        }
      },
      deep: true,
    },
  },
};
</script>
