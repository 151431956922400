<template>
  <div class="main-content">
    <div class="container-fluid">
      <off-canvas-user ref="usersOffCanvas" />
      <div class="row justify-content-center">
        <header-component
          title="Usuários"
        >
          <template #buttons>
            <button
              v-if="[0,3].includes(this.user.settings.access)"
              @click="openOffCanvas()"
              class="btn btn-primary float-end px-6"
            >
              Adicionar Usuário
            </button>
          </template>
        </header-component>
        <div class="col-lg-12">
          <ul
            class="nav nav-tabs"
            id="myTab"
            role="tablist"
          >
            <li
              class="nav-item"
              role="presentation"
            >
              <button
                @click="this.activeTab = 'adminUsers'"
                class="nav-link active"
                style="font-size: 1.1rem"
                id="adminUsers-tab"
                data-bs-toggle="tab"
                data-bs-target="#adminUsers"
                type="button"
                role="tab"
                aria-controls="adminUsers"
                aria-selected="true"
                ref="tab-adminUsers"
              >
                Usuários
              </button>
            </li>
            <li
              class="nav-item"
              role="presentation"
            >
              <button
                @click="this.activeTab = 'levelUsers'"
                class="nav-link"
                style="font-size: 1.1rem"
                id="levelUsers-tab"
                data-bs-toggle="tab"
                data-bs-target="#levelUsers"
                type="button"
                role="tab"
                aria-controls="levelUsers"
                aria-selected="false"
                ref="tab-levelUsers"
              >
                Acessos
              </button>
            </li>
          </ul>
        </div>
        <div class="col-lg-12">
          <div class="tab-content">
            <div
              class="tab-pane fade show active"
              id="adminUsers"
              role="tabpanel"
              aria-labelledby="adminUsers"
            >
              <admin-users />
            </div>
            <div
              class="tab-pane fade"
              id="levelUsers"
              role="tabpanel"
              aria-labelledby="levelUsers"
            >
              <div class="containet-fluid">
                <div class="row mt-4">
                  <user-level-card
                    v-for="level in 3"
                    :key="level"
                    :level="level"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import AdminUsers from "./components/admin-users.vue";
import UserLevelCard from "./components/user-level-card.vue";
import OffCanvasUser from "./components/offcanvas-users.vue";
import HeaderComponent from "@/components/header-component.vue";

export default {
  data() {
    return {
      activeTab: "adminUsers"
    }
  },

  components: {
    AdminUsers,
    UserLevelCard,
    OffCanvasUser,
    HeaderComponent
  },

  computed: {
    ...mapGetters("auth", {
      user: "getUser",
    })
  },

  methods: {
    /**
     * open pages Users offCanvas
     */
    openOffCanvas() {
      this.$refs.usersOffCanvas.new();
    },
  },
}
</script>
