<template>
  <div class="col-auto b3 ps-0 d-flex align-items-center my-1 message-contact">
    <span class="avatar avatar-sm background-loading b7">
      <img
        src="@assets/img/avatars/profiles/nome-user.png"
        class="avatar-img rounded-circle"
      >
    </span>
    <div class="ms-2 d-flex flex-column pe-3 border-end">
      <span
        v-if="last_name"
        class="fs-5 fw-bold"
      > {{ last_name ? name + ' ' + last_name : name }}
      </span>
      <span
        v-else
        class="fs-5 fw-bold"
      > {{ displayName }}
      </span>
      <span
        class="fs-5 fw-normal"
      > {{ number_alias || number }}</span>
    </div>
    <div>
      <button
        v-if="number"
        class="btn btn-outline-primary btn-sm ms-3"
        data-test="newChatFromContactCard-button"
        style="border-radius: 6px !important;"
        @click="submit()"
        :disabled="loadingFlags.createNewChat"
      >
        {{ $t('send_message') }}
      </button>
      <div v-else>
        <span
          class="fw-light ms-3 fs-5"
        >
          {{ $t('contact_not_on_whats_p1') }}
        </span>
        <br>
        <span
          class="fw-light ms-3 fs-5"
        >
          {{ $t('contact_not_on_whats_p2') }}
        </span>
      </div>
    </div>
  </div>
</template>
<script>
import {mapActions, mapGetters} from 'vuex'
import {vCardParser} from "@/plugins/vCardParser.js"

export default {
  data() {
    return {
      name: "",
      displayName: null,
      last_name: null,
      number_alias: null,
      number: null,
    }
  },

  props: {
    body: {
      type: String,
      required: true,
    },
    instanceId: {
      type: Number,
      required: true,
    }
  },

  mounted() {
    this.handlerContact()
  },

  computed: {
    ...mapGetters("whatsapp/chat", {
      newChatInfo: "getNewChatInfo",
      loadingFlags: "getLoadingFlags",
    }),
  },

  methods: {
    ...mapActions("whatsapp/chat", ["createNewChat", "findInProgress", "resetInProgressCurrentPage"]),

    /**
     * format body prop to get name, number and number_alias of a contact
     *
     * exemple of body prop:
     * BEGIN:VCARD\nVERSION:3.0\nN:; NAME ;;;\nFN: NAME \nTEL;type=CELL;type=VOICE;waid= WHATSAPPID : NUMBER\nEND:VCARD
     */
    handlerContact() {
      let formatedContact = vCardParser.parse(this.body)[0]
      if(formatedContact.displayName) this.displayName = formatedContact.displayName
      if(formatedContact.name.name.length > 0) this.name = formatedContact.name.name
      if(formatedContact.name.surname.length > 0) this.last_name = formatedContact.name.surname
      if(formatedContact.telephone[0].valueInfo && formatedContact.telephone[0].valueInfo.waid) this.number = formatedContact.telephone[0].valueInfo.waid
      if(formatedContact.telephone[0].value) this.number_alias = formatedContact.telephone[0].value
    },

    submit() {
      this.createNewChat({
        fields: {
          instance_id: this.instanceId,
          number: this.number,
        },
      }).then((chatId) => {
        this.resetInProgressCurrentPage();
        this.findInProgress();
        this.$router.push({ path: "/whatsapp/" + chatId });
      });
    }
  },
}
</script>
