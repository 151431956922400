export default {
  getErrors: state => state.errors,
  getQuickMessages: state => state.quickMessages,
  getLoadingContext: state => state.loadingContext,
  /**
  * generate URL parans
  *
  * @param {*} state
  * @returns
  */
  getParams: (state) =>
    Object.assign(
      {
        'page': state.pagination.current_page,
        'per_page': state.pagination.per_page
      }
      , state.filters, { include: state.include.join(',') }),
}
