<template>
  <div class="w-25 container p-4 text-center mb-6">
    <img
      src="@/assets/img/sad-pluszinha.svg"
      alt="Pluszinha triste"
      class="mb-4"
    >
    <h4 class="fw-medium">
      {{ $t('something_wrong') }}
    </h4>
    <p class="m-0 fw-normal text-small p-0">
      {{ $t('something_wrong_description') }}
    </p>
  </div>
</template>

<style lang="scss" scoped>
.container {
  background-color: #FFFFFF;
  border-radius: 0.625rem !important;

  img {
    width: 99px;
  }

  h4 {
    color: #1B1B2B;
  }

  p {
    color: #373753;
  }
}
</style>
