<template>
  <div class="main-content pb-6">
    <div class="container-fluid">
      <div class="row">
        <div class="col">
          <header-component
            :pretitle="$t('settings_uppercase')"
            :title="$t('active_IVR')"
            redirect-link="/manager/voice-settings"
          >
            <template #buttons>
              <router-link
                class="header-button btn btn-primary float-end px-6"
                to="/manager/ivrs/new"
              >
                {{ $t('add_new_active_ivr') }}
              </router-link>
            </template>
          </header-component>
          <flash-message />

          <div class="col-lg-12">
            <div class="card">
              <div class="card-header">
                <search-Input @search="search" />
              </div>
              <div class="card-body p-0">
                <table class="table app-datatable m-0">
                  <thead>
                    <tr>
                      <th class="fw-normal w-25">
                        {{ $t('capitalizes.name') }}
                      </th>
                      <th
                        class="fw-normal w-25 text-start"
                      >
                        {{ $t('capitalizes.keyboard_keys') }}
                      </th>
                      <th class="fw-normal w-50">
                        {{ $t('uppercases.warning_audio') }}
                      </th>
                      <th />
                    </tr>
                  </thead>

                  <tbody>
                    <template v-if="loadingFlags.getList">
                      <loading-table-component
                        :columns="3"
                        :lines="15"
                        :list-width="[100, 50, 250]"
                      />
                    </template>

                    <tr v-else-if="!list.length">
                      <td
                        class="text-center text-muted"
                        colspan="4"
                      >
                        <span class="fal fa-alert-octagon" /> {{ $t('no_active_ivr_found') }}
                      </td>
                    </tr>

                    <tr
                      v-for="item of list"
                      v-else
                      :key="item.id"
                      class="cursor-pointer"
                      @click="$router.push(`/manager/ivrs/${item.id}/edit`)"
                      @mouseleave="unSelectItem()"
                      @mouseover="selectItem(item.id)"
                    >
                      <td>{{ item.name }}</td>

                      <td>
                        {{ item.keys.length }}
                      </td>

                      <td class="d-flex align-items-center">
                        <div class="text-center">
                          <short-player
                            :url="getFullAudioPath(item.audio)"
                            class="mx-2"
                          />
                          {{ item.original_audio_name }}
                        </div>
                      </td>
                      <td>
                        <span
                          :style="{
                            visibility:
                              item.id == selectedItem ? 'visible' : 'hidden'
                          }"
                          class="h5 far fa-chevron-right text-primary my-0 text-end"
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div v-if="!loadingFlags.getList">
                <div class="col-lg-12">
                  <paginator
                    v-if="!loadingFlags.getList"
                    :pagination="pagination"
                    @change-page="changePage"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from "vuex";

import searchInput from "@components/search-input";
import flashMessage from "@components/flash-message";
import paginator from "@components/app-paginator";
import shortPlayer from "@components/short-player.vue";
import LoadingTableComponent from "@dialer/manager/components/loading-table-component.vue";
import _ from "lodash";
import HeaderComponent from "@/components/header-component.vue";

export default {
  data() {
    return {
      player: null,
      audioPlaying: false,
      audioPlayContext: "",
      selectedItem: false
    };
  },

  mounted() {
    this.setFilters({
      search: ""
    });
    this.getList();
  },

  components: {
    flashMessage,
    searchInput,
    paginator,
    shortPlayer,
    LoadingTableComponent,
    HeaderComponent
  },

  computed: {
    ...mapGetters("manager/ivrs", {
      list: "getList",
      errors: "getErrors",
      loadingFlags: "getLoadingFlags",
      pagination: "getPagination"
    }),

    ...mapGetters("auth", {
      user: "getUser"
    })
  },

  methods: {
    ...mapActions("manager/ivrs", ["getList"]),
    ...mapMutations("manager/ivrs", ["setFilters", "setCurrentPage"]),

    getFullAudioPath(audio_path) {
      return (
        process.env.VUE_APP_BA12AS.replace("/api/v1", "") +
        audio_path +
        "&api_token=" +
        this.user.api_token
      );
    },

    changePage(page) {
      this.setCurrentPage(page);
      this.getList();
    },

    search: _.debounce(function (term) {
      this.setFilters({
        search: term
      });
      this.setCurrentPage(1);
      this.getList();
    }, 300),

    selectItem(item) {
      this.selectedItem = item;
    },

    unSelectItem() {
      this.selectedItem = false;
    }
  }
};
</script>

<style lang="scss">
.header-body.no-subtitle {
  .header-button {
    margin-top: 0.5px;
  }

  h1 {
    line-height: 42.2px;
  }
}

.spinner-border-sm {
  border-width: 0.1em !important;

  width: 13px;
  height: 13px;
  margin-top: -1px;
}

.header-body {
  .back {
    width: 65.5px;
  }
}
</style>
