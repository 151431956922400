<template>
  <div>
    <div class="row">
      <div class="col-md-6 col-lg-4 col-xl-3">
        <div class="form-group">
          <label class="form-label required">
            {{ $t('period') }}
          </label>
          <input-date-range
            :max-interval="31"
            v-model:start="filters.start_date"
            v-model:end="filters.end_date"
            :time-enabled="true"
            :seconds-enabled="true"
            format-date="d/m/Y H:i:S"
          />
        </div>
      </div>

      <div class="col-md-6 col-lg-4 col-xl-3">
        <div class="form-group">
          <label class="form-label">
            {{ $t('group_channel') }}
          </label>
          <select-whatsapp-group-channels
            @select-group-channels="value => (filters.group_channel_ids = value.join(','))"
          />
        </div>
      </div>

      <div class="col-md-6 col-lg-4 col-xl-3">
        <div class="form-group">
          <label class="form-label">
            {{ $t('agents_capitalize') }}
          </label>
          <select-whatsapp-agents
            @select-agent="(value) => filters.agents_ids = value.join(',') "
          />
        </div>
      </div>

      <div
        class="col-md-6 col-lg-4 col-xl-3"
        style="margin-top: 2rem"
      >
        <app-button
          class="w-100"
          @click="submit"
          :disabled="!filters.start_date || !filters.start_date || loadingFlags.fetchChatsReportList"
        >
          {{ $t('search_report') }}
        </app-button>
      </div>
    </div>

    <div
      class="col-12"
      style="margin: 1rem 0"
    >
      <div class="form-group">
        <a
          class="text-primary cursor-pointer"
          @click="showAdvancedFilters = !showAdvancedFilters"
        >
          {{ showAdvancedFilters? '- '+$t('capitalizes.hide') : '+ '+$t('capitalizes.hide') }} {{ $t('advanced_filters') }}.
        </a>
      </div>
    </div>

    <div
      class="row"
      v-show="showAdvancedFilters"
    >
      <div class="col-md-6 col-lg-4 col-xl-3">
        <div class="form-group">
          <label
            for="number"
            class="form-label"
          >
            {{ $t('number') }}
          </label>
          <input
            v-model="filters.number"
            id="number"
            type="text"
            class="form-control"
            v-maska
            data-maska="[
              '+## (##) ####-####',
              '+## (##) #####-####'
            ]"
            placeholder="+55 (00) 00000-0000"
          >
        </div>
      </div>

      <div class="col-md-6 col-lg-4 col-xl-3">
        <div class="form-group">
          <label class="form-label">
            {{ $t('channel') }}
          </label>
          <vue-multiselect
            v-model="filters.instance_id"
            id="channel"
            label="name"
            track-by="id"
            :placeholder="$t('select_channel')"
            :select-label="$t('click_or_enter_to_select')"
            :deselect-label="$t('click_or_enter_to_remove')"
            :selected-label="$t('selected_capitalize')"
            :multiple="true"
            :close-on-select="false"
            :preserve-search="true"
            :options="channels"
          />
        </div>
      </div>

      <div class="col-md-6 col-lg-4 col-xl-3">
        <div class="form-group">
          <label class="form-label">
            {{ $t('status_omni') }}
          </label>
          <select
            class="form-select"
            v-model="filters.status"
          >
            <option
              v-for="(item, index) in status"
              :key="index"
              :value="item.value"
            >
              {{ item.name }}
            </option>
          </select>
        </div>
      </div>

      <div class="col-md-6 col-lg-4 col-xl-3">
        <label class="form-label">
          {{ $t('qualification') }}
        </label>
        <select
          class="form-select"
          v-model="filters.qualification"
          :disabled="filters.group_channel_ids === ''"
        >
          <option value="">
            {{ $t('select_qualification') }}
          </option>
          <option
            v-for="qualification in qualifications"
            :key="qualification.id"
            :value="qualification.id"
          >
            {{ qualification.name }}
          </option>
        </select>
      </div>

      <div class="col-md-6 col-lg-4 col-xl-3">
        <div class="form-group">
          <label class="form-label">
            {{ $t('protocol') }}
          </label>
          <input
            type="text"
            class="form-control"
            v-model="filters.protocol_number"
            placeholder="12345678901234567"
          >
        </div>
      </div>

      <div class="col-md-6 col-lg-4 col-xl-3 d-flex align-items-center">
        <div class="form-check form-switch">
          <input
            type="checkbox"
            class="form-check-input"
            v-model="filters.transferred"
          >
          <label
            class="form-check-label"
          >
            {{ $t('only_transferred_chats') }}
          </label>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import InputDateRange from "@components/input-date-range.vue";
import SelectWhatsappAgents from "@dialer/manager/components/select-whatsapp-agents.vue";
import AppButton from "@components/app-button.vue";
import VueMultiselect from "vue-multiselect";

import {mapActions, mapGetters, mapMutations} from "vuex";
import SelectWhatsappGroupChannels from "@dialer/manager/components/select-whatsapp-group-channels.vue";

export default {
  name: "MenuSearch",

  emits:["searched"],

  components: {
    SelectWhatsappGroupChannels,
    SelectWhatsappAgents,
    AppButton,
    InputDateRange,
    VueMultiselect
  },

  data() {
    return {
      filters: {
        start_date: null,
        end_date: null,
        group_channel_ids: "",
        agents_ids: "",
        number: "",
        instance_id: [],
        status: "",
        qualification: "",
        protocol_number: null,
        transferred: false,
      },
      showAdvancedFilters: false,
    }
  },

  created() {
    this.fetchInstances()
    this.fetchQualifications()
  },

  computed: {
    ...mapGetters("manager/chats_report", {
      loadingFlags: "getLoadingFlags",
    }),
    ...mapGetters("manager/whatsapp_filters", {
      channels: "getInstances",
      groupChannels: "getGroupChannels",
      qualificationsData: "getQualifications"
    }),

    status() {
      return [
        {
          value: "",
          name: this.$t('all_dropdown')
        },
        {
          value: "IN_PROGRESS",
          name: this.$t('in_progress')
        },
        {
          value: "IN_QUEUE",
          name: this.$t('capitalizes.queue')
        },
        {
          value: "IN_SNOOZE",
          name: this.$t('list_metrics_on_hold')
        },
        {
          value: "FINISHED",
          name: this.$t('compĺeted')
        },
      ]
    },

    qualifications() {
      let idsArray = this.filters.group_channel_ids.split(',');
      let addedQualificationListsId = []
      let qualifications = []

      this.groupChannels.forEach((groupChannel)=> {
        if (idsArray.includes(groupChannel.id.toString())) {
          const listId = groupChannel.team.data.qualification_list_id
          if (!listId) {
            return
          }
          if (!addedQualificationListsId.includes(listId)) {
            addedQualificationListsId.push(listId)
            this.qualificationsData[listId].forEach((qualification)=>{
              qualifications.push(qualification)
            })
          }
        }
      })

      return qualifications
    }
  },

  methods: {
    ...mapMutations("manager/chats_report", ["setFilters", "setPagination"]),
    ...mapActions("manager/chats_report", ["fetchChatsReportList"]),
    ...mapActions("manager/whatsapp_filters", [
      "fetchInstances",
      "fetchQualifications"
    ]),

    submit() {
      const pagination = {
        per_page: 20,
        current_page: 1
      }

      this.setPagination(pagination)
      this.setFilters(this.parseFilters({...this.filters}))
      this.$emit("searched")
      this.fetchChatsReportList()
    },

    parseFilters(filters) {
      filters.number = filters.number.replace(/\D/g, '')
      filters.instance_id = filters.instance_id.map(channel => channel.id).join(',')

      for (const filter in filters) {
        if (!filters[filter]){
          delete filters[filter]
        }
      }

      return filters
    }
  }
}
</script>


<style scoped lang="scss">

</style>
