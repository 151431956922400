<template>
  <div class="cursor-pointer">
    <div
      v-if="type == 'image'"
      class="text-medium lh-1"
    >
      <span class="fe fe-image" />
      {{ $t('photo') }}
    </div>
    <span
      v-else-if="['ptt', 'audio'].includes(type)"
      class="text-medium lh-1"
    >
      <span
        :class="audio_fields.loading ? 'spinner-border spinner-border-sm text-secondary' : 'fe fe-mic text-muted'"
      />
      {{ audio_fields.loading ? "" : audio_fields.duration }}
    </span>

    <span
      v-else-if="type == 'video'"
      class="text-medium lh-1"
    >
      <span class="fe fe-video" />
      {{ $t('video') }}
    </span>

    <span
      v-else-if="type == 'document'"
      class="text-medium lh-1"
    >
      <span class="fe fe-file" />
      {{ body ? body : $t('document') }}
    </span>
    <span
      v-else-if="type == 'sticker'"
      class="text-medium lh-1"
    >
      <i class="fa-regular fa-note-sticky" />
      {{ $t('sticker') }}
    </span>

    <span
      class="text-medium lh-1"
      v-else-if="name"
    >
      <span class="fe fe-user" />
      {{ last_name ? name + ' ' + last_name : name }}
    </span>

    <span
      class="text-medium lh-1"
      v-else-if="displayName"
    >
      <span class="fe fe-user" />
      {{ displayName }}
    </span>

    <span
      v-if="type === 'template'"
      class="d-flex flex-row gap-2 align-items-center cursor-pointer quote-link rounded"
      @click="scrollToTargetMessage"
    >
      <img
        v-if="media && isValidImageUrl(media)"
        :src="media"
        alt="media"
        class="w-25"
      >
      <span>{{ body }}</span>
    </span>
    <div v-else>
      <ballon-quote>
        {{ body }}
      </ballon-quote>
    </div>
  </div>
</template>

<script>
import {vCardParser} from "@/plugins/vCardParser.js"
import {mapGetters} from "vuex";
import {isValidImageUrl} from "@/utils";
import BallonQuote from "@whatsapp/components/ballon-quote.vue";

export default {
  components: {BallonQuote},
  props: {
    msgId: { type: String, default: "", required: false },
    type: { type: String, default: "" },
    body: { type: String, default: "" },
    media: { type: String, default: "" },
  },

  data() {
    return {
      name: "",
      displayName: null,
      last_name: null,
      audio: null,
      audio_fields: {
        duration: "",
        loading: false,
      }
    };
  },

  computed: {
    ...mapGetters("auth", {
      user: "getUser",
    }),
  },

  mounted() {
    if (['ptt', 'audio'].includes(this.type)) {
      this.load();
    }

    if (this.type == "vcard") {
      this.handlerContact();
    }
  },

  unmounted() {
    if (['ptt', 'audio'].includes(this.type)) {
      this.audio.removeEventListener("canplay", this.canplay);
    }
  },

  methods: {
    isValidImageUrl,

    load() {
      // review the stream of cited message
      this.audio = new Audio();
      this.audio_fields.loading = true;
      if (this.media && this.media.includes("whatsapp/download")) {
        let url = process.env.VUE_APP_QW56YU + this.media;
        if (this.media.startsWith(process.env.VUE_APP_QW56YU)) {
          url = this.media
        }
        if (this.user && this.user.company)
          url = url.replace("://app.", "://" + this.user.company.domain + ".");
        fetch(url)
        .then((response) => response.arrayBuffer())
        .then((arrayBuffer) => {
          const blob = new Blob([arrayBuffer], {type: "audio/mp3"});
          this.audio.src = window.URL.createObjectURL(blob);
          this.audio_fields.loading = false;
        })
      } else {
        this.audio_fields.loading = false;
        this.audio_fields.duration = "Áudio"
      }
      this.audio.addEventListener("canplay", this.canplay);
    },

    canplay() {
      let minutes = Math.floor(this.audio.duration / 60);
      let seconds = Math.round(this.audio.duration % 60);
      this.audio_fields.duration =
        minutes.toString().padStart(2, "0") + ":" + seconds.toString().padStart(2, "0");
    },

    handlerContact() {
      let formatedContact = vCardParser.parse(this.body)[0]
      if(formatedContact.displayName) this.displayName = formatedContact.displayName
      if(formatedContact.name.name.length > 0) this.name = formatedContact.name.name
      if(formatedContact.name.surname.length > 0) this.last_name = formatedContact.name.surname
    },

    scrollToTargetMessage () {
      if (this.msgId && document.getElementById(`${this.msgId}`))
        document.getElementById(`${this.msgId}`).scrollIntoView({behavior: "smooth", block: "center" })
    }
  },

  watch: {
    media() {
      if (['ptt', 'audio'].includes(this.type)) {
        this.load();
      }
    },
    body() {
      if (this.type == "vcard") {
        this.handlerContact();
      }
    }
  }

};
</script>
<style lang="scss" scoped>
.quote-link {
  &:hover {
    background-color: $color-gray-100;
  }
}
</style>
