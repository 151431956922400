<template>
  <div
    class="tab-pane fade show mt-3"
    id="quick-message"
    role="tabpanel"
    aria-labelledby="home-tab"
  >
    <div class="col-lg-12">
      <div class="card">
        <div class="card-header">
          <div class="row align-items-center">
            <div class="col">
              <search-input @search="search" />
            </div>
            <div class="col-auto">
              <button
                class="btn btn-primary btn-sm rounded px-3"
                @click="openNewQuickMessageOffcanvas()"
              >
                {{ $t('add_message') }}
              </button>
              <new-message-offcanvas ref="newMessageOffcanvas" />
            </div>
          </div>
        </div>
        <div class="card-body p-0">
          <table class="table app-datatable m-0">
            <thead class="">
              <tr>
                <th class="fw-normal">
                  {{ $t('shortcut') }}
                </th>
                <th class="fw-normal">
                  {{ $t('message') }}
                </th>
                <th />
              </tr>
            </thead>

            <tbody>
              <template v-if="loading_context['quick_message_list']">
                <loading-table-component
                  :lines="15"
                  :columns="2"
                  :list-width="[50, 250, 50]"
                />
              </template>

              <tr
                v-if="
                  quick_message_list.length == 0 &&
                    !loading_context['quick_message_list']
                "
              >
                <td
                  class="text-center text-muted"
                  colspan="2"
                >
                  <span class="fe fe-alert-octagon" /> {{
                    $t('no_quick_messages_found')
                  }}
                </td>
              </tr>
              <template v-if="!loading_context['quick_message_list']">
                <tr
                  v-for="message in quick_message_list"
                  :key="message.id"
                >
                  <td class="text-muted">
                    {{ message.shortcut }}
                  </td>
                  <td>{{ message.message }}</td>
                  <td class="text-end">
                    <button
                      class="btn text-primary btn-sm"
                      @click="openEditQuickMessageOffcanvas(message)"
                    >
                      <span class="fe fe-edit" />
                    </button>

                    <button
                      class="btn text-danger btn-sm"
                      @click="removeQuickMessage(message.id)"
                    >
                      <span class="fe fe-trash" />
                    </button>
                  </td>
                </tr>
              </template>
            </tbody>
          </table>
        </div>

        <div
          class="col-lg-12"
          v-if="
            quick_message_list.length > 0 &&
              !loading_context['quick_message_list']
          "
        >
          <paginator
            :pagination="pagination"
            @change-page="changePage"
            v-if="!loading_context['quick_message_list']"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import _ from "lodash";

import SearchInput from "@components/search-input";
import Paginator from "@/components/app-paginator.vue";
import LoadingTableComponent from "@dialer/manager/components/loading-table-component.vue";

import NewMessageOffcanvas from "./new-message-offcanvas.vue";

export default {
  mounted() {
    this.loadWhatsappQuickMessageList({
      id: this.$route.params.id,
    });
  },
  components: {
    SearchInput,
    Paginator,
    LoadingTableComponent,
    NewMessageOffcanvas
  },
  computed: {
    ...mapGetters("manager/quick_messages", {
      loading_context: "getLoadingContext",
      quick_message_list: "getQuickMessageList",
      pagination: "getPagination"
    })
  },
  methods: {
    ...mapActions("manager/quick_messages", [
      "loadWhatsappQuickMessageList",
      "deleteQuickMessage"
    ]),
    ...mapActions("system", ["showConfirmationMessage"]),

    openNewQuickMessageOffcanvas() {
      this.$refs.newMessageOffcanvas.new();
    },
    openEditQuickMessageOffcanvas(message) {
      this.$refs.newMessageOffcanvas.edit(message);
    },
    async removeQuickMessage(id) {
      let response = await this.showConfirmationMessage({
        title: this.$t('delete_quick_message_question'),
        text: this.$t('delete_quick_message'),
        type: "badWarning"
      });

      if (response.isConfirmed) {
        this.deleteQuickMessage({
          instanceId: this.$route.params.id,
          id: id,
        }).then(() => this.loadWhatsappQuickMessageList({
          id: this.$route.params.id,
        }));
      }
    },
    search: _.debounce(function(term) {
      this.loadWhatsappQuickMessageList({
        id: this.$route.params.id,
        params: {
          search: term
        }
      });
    }, 300),
    changePage(page) {
      this.loadWhatsappQuickMessageList({
        id: this.$route.params.id,
        params: {
          page: page
        }
      });
    }
  }
};
</script>

<style></style>
