<template>
  <li
    class="d-flex align-items-center justify-content-between cursor-pointer"
    style="padding: 0.5rem 0"
  >
    <div class="d-flex align-items-center gap-2 fw-medium text-medium">
      <span
        class="fa-kit tx-text-gray"
        :class="icon[channel.type]"
      />
      {{ $t(channel.type) }}
    </div>
    <p class="m-0 text-small">
      {{ formatPhoneNumber(channel.phone) || '-' }}
    </p>
  </li>
</template>

<script>
import {formatPhoneNumber} from "@/utils";

export default {
  name: "ItemChannel",

  props: {
    channel: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      icon: {
        ultramsg: 'fa-whatsapp',
        waba: 'fa-waba'
      }
    }
  },

  methods: {
    formatPhoneNumber,
  }
}
</script>