import axios from "axios";

export default {
  /**
   * Retrieves all office hours that belongs to the
   * company.
   *
   * @param {Object} param0
   * @returns
   */
  findOfficeHours({ commit, getters }) {
    commit("startLoading");
    commit("setOfficeHours", []);

    return new Promise((resolve, reject) => {
      axios
        .get("/office_hours", { params: getters.getParams })
        .then(response => {
          commit("setPagination", response.data.meta.pagination);
          commit("setOfficeHours", response.data.data);
          resolve();
        })
        .catch(response => {
          reject({ message: response.response.data.detail });
        })
        .finally(() => {
          commit("stopLoading");
        });
    });
  },

  /**
   *
   * @param {*} param0
   * @param {*} payload
   */
  create({ commit }, payload) {
    const headers = { "Content-Type": "multipart/form-data" };

    commit("startLoading");
    commit("clearErrors");

    return new Promise((resolve, reject) => {
      axios
        .post("/office_hours", payload.formData, { headers })
        .then(() => {
          resolve({ message: "Horário de atendimento cadastrado com sucesso!" });
        })
        .catch(response => {
          response.response.status == 422 &&
            response.response.data.errors &&
            commit("setErrors", response.response.data.errors);
          commit("system/setErrorMessage", response.response.data.detail, { root: true });
          reject();
        })
        .finally(() => {
          commit("stopLoading");
        });
    });
  },

  /**
   *
   * @param {*} param0
   * @param {*} payload
   */
  update({ commit }, payload) {
    const headers = { "Content-Type": "multipart/form-data" };
    commit("startLoading");
    commit("clearErrors");
    return new Promise((resolve, reject) => {
      //TODO put
      axios
        .post("/office_hours/" + payload.id, payload.formData, { headers })
        .then(() => {
          resolve({ message: "Horário de atendimento modificado com sucesso!" });
        })
        .catch(response => {
          commit("system/setErrorMessage", response.response.data.detail, { root: true });
          response.response.status == 422 &&
            response.response.data.errors &&
            commit("setErrors", response.response.data.errors);
          reject();
        })
        .finally(() => {
          commit("stopLoading");
        });
    });
  },

  /**
   *
   * @param {*} param0
   * @param {*} payload
   */
  findById({ commit }, payload) {
    commit("startLoading");
    commit("setRegister", null);

    return new Promise((resolve, reject) => {
      axios
        .get("/office_hours/" + payload.id)
        .then(response => {
          commit("setRegister", response.data.data);
          resolve();
        })
        .catch(response => {
          reject({ message: response.response.data.detail });
        })
        .finally(() => {
          commit("stopLoading");
        });
    });
  },

  /**
   *
   * @param {*} param0
   * @param {*} payload
   */
  remove({ commit }, payload) {
    commit("startLoading");
    return new Promise((resolve, reject) => {
      axios
        .delete("/office_hours/" + payload.id)
        .then(() => {
          commit("system/setSuccessMessage", "Horário de atendimento removido com sucesso!", { root: true });
          commit("setRegister", null);
          resolve();
        })
        .catch(response => {
          commit("system/setErrorMessage", response.response.data.errors.name, { root: true });
          reject();
        })
        .finally(() => {
          commit("stopLoading");
        });
    });
  }
};
