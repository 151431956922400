import axiosOmni from "@/plugins/axios-omni.js"

export default {
  createStarterAction({commit}, payload) {
    commit("startLoading", "createAction")
    return new Promise((resolve, reject)=>{
      axiosOmni.post("/chatbot/actions", payload)
        .then(()=>{
          resolve()
        }).catch((error)=>{
          commit('system/setErrorMessage', error.response.data, { root: true })
          reject()
      }).finally(()=>{
        commit("stopLoading", "createAction")
      })
    })
  },

  createAction({commit, dispatch}, payload) {
    commit("startLoading", "createAction")
    return new Promise((resolve, reject)=>{
      axiosOmni.post("/chatbot/actions", payload)
        .then((response)=>{
          dispatch("setNewAction", response.data.data)
          resolve()
        }).catch((error)=>{
          commit('system/setErrorMessage', error.response.data, { root: true })
          reject()
      }).finally(()=>{
        commit("stopLoading", "createAction")
      })
    })
  },

  updateAction({commit, getters}, {actionId, payload}) {
    commit("startLoading", "updateAction")
    return new Promise((resolve, reject)=>{
      axiosOmni.patch(`/chatbot/actions/${actionId}`, payload)
        .then((response)=>{
          const action = response.data.data

          let actions = getters.getActions

          const actionIndex = actions.findIndex((el)=> el.id == action.id)
          actions[actionIndex] = action

          if(action.type === "multiple_choice_item"){
            const multipleChoiceIndex = actions.findIndex((el) => el.id === action.chatbot_action_id)
            const itemIndex = actions[multipleChoiceIndex].choices.data.findIndex((el) => el.id === action.id)
            actions[multipleChoiceIndex].choices.data[itemIndex] = action
          }

          commit("setActions", actions)
          resolve()
        }).catch((error)=>{
          commit('system/setErrorMessage', error.response.data, { root: true })
          reject()
      }).finally(()=>{
        commit("stopLoading", "updateAction")
      })
    })
  },

  deleteAction({commit}, actionId) {
    commit("startLoading", "deleteAction")
    return new Promise((resolve, reject)=>{
      axiosOmni.delete(`/chatbot/actions/${actionId}`)
        .then(()=>{
          commit("system/setSuccessMessage", "success_message.remove_step", { root: true })
          resolve()
        }).catch((error)=>{
          commit('system/setErrorMessage', error.response.data, { root: true })
          reject()
      }).finally(()=>{
        commit("stopLoading", "deleteAction")
      })
    })
  },

  fetchGroupChannels({ commit }) {
    commit("startLoading", "findGroupChannel");
    return new Promise((resolve, reject) => {
      axiosOmni
        .get("/group-channel/simplified-list", {params: {per_page: -1, status: 'active'}})
        .then(response => {
          commit("setGroupChannels", response.data.data.data);
          resolve();
        })
        .catch((error) => {
          commit('system/setErrorMessage', error.response.data, {root: true})
          reject();
        })
        .finally(() => {
          commit("stopLoading", "findGroupChannel");
        });
    });
  },

  setNewAction: ({commit, getters}, action) => {
    let actions = getters.getActions
    actions.push(action)

    if(action.type != "multiple_choice_item") {
      let previousAction = actions.find(el => el.id == action.previous_action_id)

      if (previousAction.type === "multiple_choice_item") {
        const previousMultiChoiceIndex = actions.findIndex(el => el.id == previousAction.chatbot_action_id)
        const j = actions[previousMultiChoiceIndex].choices.data.findIndex(choice => choice.expected_message_response === previousAction.expected_message_response)
        actions[previousMultiChoiceIndex].choices.data[j].next_action_id = action.id
      }

      const index = actions.findIndex(el => el.id == action.previous_action_id)
      actions[index].next_action_id = action.id
    }

    commit("setActions", actions)
  },
}
