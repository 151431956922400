<template>
  <div class="card">
    <div class="card-header">
      <div class="d-flex align-items-center gap-1rem">
        <button
          class="btn"
          data-bs-toggle="collapse"
          :data-bs-target="`#category-${index}-${item.name.replace(/[^a-zA-Z0-9]/g,'_')}`"
          role="button"
          :aria-expanded="ariaExpanded"
          :aria-controls="`category-${index}`"
        >
          <i :class="chevronIcon" />
        </button>
        <div class="form-check form-switch ps-0 d-flex align-items-center">
          <input
            class="form-check-input ms-0"
            type="checkbox"
            role="switch"
            :id="`services-${index}`"
            :checked="item.is_active"
            @click.prevent="handleIsActive"
          >
          <label
            style="margin-left: 1rem"
            class="form-check-label fw-medium line-clamp"
            :for="`services-${index}`"
            ref="tooltipName"
          >
            {{ item.name }}
          </label>
        </div>
        <span class="badge fs-6 fw-medium lh-1">{{ badge }}</span>
      </div>
      <div class="d-flex gap-1">
        <Transition name="bounce">
          <button
            v-if="ariaExpanded"
            @click="handleEdit"
            class="btn btn-edit"
          >
            <i class="fa-regular fa-pen" />
          </button>
        </Transition>

        <button
          class="btn btn-primary"
          @click="handleProductModal"
          type="button"
          :disabled="!blockAddProduct"
        >
          <i class="fa-regular fa-plus" />
        </button>
      </div>
    </div>

    <div
      class="collapse multi-collapse"
      :class="{show: this.index === 0}"
      :id="`category-${index}-${item.name.replace(/[^a-zA-Z0-9]/g,'_')}`"
      :ref="`collapse-${index}`"
    >
      <div
        style="padding-left: 5.125rem; padding-right: 1rem"
        class="overflow-hidden"
      >
        <table class="table w-100 mb-0">
          <thead class="thead-overflow">
            <tr>
              <th
                style="padding-left: 2.75rem"
                class="col-4"
              >
                <span class="list-sort text-muted fw-normal">
                  {{ $t('name_capitalize') }}
                </span>
              </th>
              <th class="col-2">
                <span class="list-sort text-muted fw-normal">
                  {{ $t('product_code_abbreviation') }}
                </span>
              </th>
              <th class="col-2">
                <span class="list-sort text-muted fw-normal">
                  {{ $t('price') }}
                </span>
              </th>
              <th class="col-4">
                <span class="list-sort text-muted fw-normal">
                  {{ $t('description') }}
                </span>
              </th>
            </tr>
          </thead>

          <tbody v-if="item.products.length">
            <product-component
              v-for="(product, productIndex) in item.products"
              :key="productIndex"
              :product="product"
              :show="ariaExpanded"
            />
          </tbody>
        </table>
      </div>

      <div
        v-if="!item.products.length"
        style="padding: 1rem 1rem 1.5rem 1rem;"
        class="d-flex flex-column align-items-center"
      >
        <p class="txt-text-gray">
          {{ $t('no_products_in_category') }}
        </p>

        <button
          style="width: 240px; font-size: 12px"
          class="btn btn-primary"
          @click="handleProductModal"
          :disabled="!blockAddProduct"
        >
          <i class="fa-regular fa-plus p-0" />
          <span>{{ $t('add_product') }}</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import ProductComponent from "@dialer/manager/pages/settings/products-and-services/components/product-component.vue";
import {mapActions, mapGetters} from "vuex";
import {generateTooltip} from "@/utils";

export default {
  name: "CategoryComponent",
  components: {ProductComponent},

  props: {
    index: {
      type: Number,
      required: true
    },
    item: {
      type: Object,
      required: true
    },
  },

  data() {
    return {
      ariaExpanded: false
    };
  },

  computed: {
    ...mapGetters("manager/teams", {
      teams: "getTeams",
      pagination: "getPagination"
    }),

    ...mapGetters("manager/products_and_services", {
      productData: "getProductData",
      categoryData: "getCategoryData",
    }),

    chevronIcon() {
      return this.ariaExpanded ? 'fa-regular fa-chevron-up' : 'fa-regular fa-chevron-down';
    },

    badge() {
      if (this.item.teams.length < this.pagination.total) {
        return `${this.item.teams.length} ${this.$tc('team_pluralization', this.item.teams.length)}`;
      }
      return this.$t('all_teams')
    },

    blockAddProduct() {
      return this.ariaExpanded && this.item.is_active;
    },

    firstIndex() {
      return this.index === 0;
    }
  },

  methods: {
    ...mapActions("manager/products_and_services", [
      "toggleModal",
      "toggleEdit",
      "handleProductData",
      "handleCategoryData",
      "toggleCategoryModal",
      "handleProductData",
      "updateCategory",
      "fetchCategories",
      "updateExclude",
    ]),
    ...mapActions("manager/teams", [
      "list",
    ]),

    generateTooltip,

    handleEdit() {
      this.toggleCategoryModal({
        title: this.$t('edit_category'),
        button: this.$t('capitalizes.save'),
        type: "category",
        actionName: "updateCategory",
        handleName: "handleCategoryData",
        data: {
          name: "",
          team_ids: [],
          is_active: true
        },
        dataState: "categoryDataState",
        exclude: {
          title: this.$t('deactivate_category'),
          name: this.item.name,
          actionName: "deleteCategory",
        }
      })

      this.toggleEdit(true)
      this.toggleModal(true)
      this.handleCategoryData(this.item)
    },


    handleProductModal() {
      this.handleProductData({
        ...this.productData,
        category_id: this.item.id
      })

      this.toggleCategoryModal({
        title: this.$t('new_product'),
        button: this.$t('create_new_product'),
        type: "product",
        actionName: "createProduct",
        handleName: "handleProductData",
        data: {
          name: "",
          code: "",
          description: null,
          currency: "",
          is_recurrent: false,
          price: 0,
          maximum_discount: 0,
          is_active: true,
          category_id: ""
        },
        dataState: "productDataState"
      })

      this.toggleModal(true)
    },

    handleIsActive() {
      this.toggleCategoryModal({
        data: {
          name: "",
          team_ids: [],
          is_active: true
        },
        dataState: "categoryData",
        exclude: {
          title: this.$t('deactivate_category'),
          name: this.item.name,
          actionName: "updateCategory",
        }
      })

      const data = {
        ...this.item,
        is_active: !this.item.is_active,
      }

      this.handleCategoryData(data)

      if (this.item.is_active) {
        this.updateExclude(true)
        return
      }

      this.updateCategory(this.categoryData).then(() => {
        this.fetchCategories(1)
        this.fetchCategories(0)
        this.updateExclude(false)
      })
    },

    toggleAriaExpanded() {
      this.ariaExpanded = !this.ariaExpanded
    }
  },

  mounted() {
    this.list();

    if (this.firstIndex) {
      this.ariaExpanded = true
    }

    if (this.item.name) {
      this.generateTooltip(
        this.item.name,
        this.$refs.tooltipName,
        'bottom'
      );
    }

    this.$refs[`collapse-${this.index}`].addEventListener('shown.bs.collapse', this.toggleAriaExpanded)

    this.$refs[`collapse-${this.index}`].addEventListener('hidden.bs.collapse', this.toggleAriaExpanded)
  },

  beforeUnmount() {
    this.$refs[`collapse-${this.index}`].removeEventListener('shown.bs.collapse', this.toggleAriaExpanded)

    this.$refs[`collapse-${this.index}`].removeEventListener('hidden.bs.collapse', this.toggleAriaExpanded)
  }
}
</script>

<style lang="scss" scoped>
.bounce-enter-active {
  animation: bounce-in 0.5s ease;
}

.bounce-leave-active {
  animation: bounce-in 0.5s ease reverse;
}

@keyframes bounce-in {
  0% {
    opacity: 0;
    right: -3rem;
  }
  100% {
    opacity: 1;
    right: 0;
  }
}

.line-clamp {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  word-break: break-word;
}

.gap-1rem {
  gap: 1rem;
}

.form-check-input {
  width: 1.75rem;
  min-width: 1.75rem;
  height: 1rem;
}

.card-body {
  padding: 1rem;
}

.badge {
  background-color: #E1E9F4;
  color: #1A4A89;
  padding: 4px 8px;
  font-style: normal;
  border-right: 8px;
}

.btn-edit {
  background-color: transparent;
  color: #677C92;
  position: relative;

  &:hover,
  &:focus,
  &:focus-visible {
    background-color: #E1E9F4;
  }
}


.thead-overflow {
  position: relative;

  &:before,
  &:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    background-color: #F0F4FA;
  }

  &:before {
    left: -100%;
  }

  &:after {
    right: -100%;
  }
}


.btn-primary {
  border: 0;

  &:disabled {
    background-color: #E1E9F4;
    color: #677C92;
  }
}
</style>