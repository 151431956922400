<template>
  <hover-button
    icon="fa-regular fa-right-from-bracket"
    @click="stopInterval"
    background-color="var(--bs-white)"
    :disabled="loadingFlags.stopInterval"
  />
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import HoverButton from "@dialer/agent/components/hover-button.vue";

export default {
  components: {
    HoverButton
  },

  computed: {
    ...mapGetters("system", {
      loadingFlags: "getLoadingFlags"
    })
  },

  methods: {
    ...mapActions("system", ["stopInterval"])
  }
};
</script>
