<template>
  <div class="container-fluid">
    <header-component
      :pretitle="$t('reports_capitalize')"
      :title="$t('agents_reports')"
      redirect-link="/manager/reports"
    />
    <form class="row align-items-center">
      <div class="col-lg-4">
        <label
          for="start-date"
          class="form-label"
        >
          {{ $t('period') }}
        </label>
        <input-date-range
          v-model:start="startDate"
          v-model:end="endDate"
          :time-enabled="true"
          :seconds-enabled="true"
          format-date="d/m/Y H:i:S"
        />
      </div>
      <div class="col-lg-4">
        <label
          for="campaign"
          class="form-label"
        >
          {{ $t('campaign_capitalize') }}
        </label>

        <!-- Search Select -->
        <select-campaign
          v-model="campaign"
        />
      </div>
      <div class="col-lg-2">
        <label class="form-label">&nbsp;</label>
        <div>
          <app-button
            class="form-control px-3"
            :blocked="!canSearch()"
            @click="submit()"
            :loading="
              loading.searchAgentsReport ||
                loading.searchQualifications ||
                loading.searchConnectedCallsData
            "
          >
            {{ $t('search_report') }}
          </app-button>
        </div>
      </div>
    </form>
    <ul
      class="nav nav-tabs mb-4"
      id="nav-tab"
      role="tablist"
    >
      <li class="nav-item">
        <button
          class="nav-link active"
          id="nav-time-tab"
          data-bs-toggle="tab"
          data-bs-target="#time"
          role="tab"
          aria-controls="time"
          aria-selected="true"
        >
          {{ $t("time") }}
        </button>
      </li>
      <li class="nav-item">
        <button
          class="nav-link"
          id="nav-qualifications-tab"
          data-bs-toggle="tab"
          data-bs-target="#qualifications"
          role="tab"
          aria-controls="qualifications"
          aria-selected="false"
        >
          {{ $t('qualifications_capitalize') }}
        </button>
      </li>
      <li class="nav-item">
        <button
          class="nav-link"
          id="nav-calls-tab"
          data-bs-toggle="tab"
          data-bs-target="#calls"
          role="tab"
          aria-controls="calls"
          aria-selected="false"
        >
          {{ $t('capitalizes.calls') }}
        </button>
      </li>
    </ul>
    <div class="tab-content">
      <div
        class="tab-pane fade show active pb-2"
        id="time"
        role="tabpanel"
      >
        <progress-chart
          v-if="reportList.length && !showSearchMessage"
          :title="$t('total_time_graph')"
          :format-ticks="secondsToHoursFormat"
          :labels="totalTimeLabels"
          :datasets="totalTimeDatasets"
        />
        <div
          class="card py-4"
          v-if="reportList.length == 0 && !showSearchMessage"
        >
          <div class="card-body text-center">
            {{ $t('no_info_found') }}
          </div>
        </div>
        <div
          class="card py-4"
          v-if="showSearchMessage"
        >
          <div class="card-body text-center">
            {{ $t('search_metrics') }}
          </div>
        </div>
        <table-component
          v-if="reportList.length && !showSearchMessage"
          :title="$t('total_time_table')"
          :table-footer="timeTableFooter"
          :footer-colors="totalTimeDatasets"
          :table-header="timeTableHeader"
          :table-content="timeTableContent"
          :download-table-function="downloadTable"
          :loading="loading.downloadTable"
          :query-params="{
            campaignId: this.campaign,
            startDate: this.startDate,
            endDate: this.endDate,
          }"
        />

        <table-component
          v-if="reportList.length && !showSearchMessage"
          :title="$t('average_time_table')"
          :table-footer="averageTableFooter"
          :footer-colors="totalTimeDatasets"
          :table-header="averageTableHeader"
          :table-content="averageTableContent"
        />
      </div>
      <div
        class="tab-pane fade"
        id="qualifications"
        role="tabpanel"
      >
        <progress-chart
          v-if="qualificationList.agents && !showSearchMessage"
          :title="$t('qualification_graph_per_agent')"
          :labels="qualificationLabels"
          :datasets="qualificationDatasets"
        />
        <div
          class="card py-4"
          v-if="!qualificationList.agents && !showSearchMessage"
        >
          <div class="card-body text-center">
            {{ $t('no_info_found') }}
          </div>
        </div>
        <div
          class="card py-4"
          v-if="showSearchMessage"
        >
          <div class="card-body text-center">
            {{ $t('search_metrics') }}
          </div>
        </div>
      </div>
      <div
        class="tab-pane fade"
        id="calls"
        role="tabpanel"
      >
        <progress-chart
          v-if="Object.values(callsDataset).length && callsDataset.labels.length && !showSearchMessage"
          :title="$t('capitalizes.connected_calls')"
          :labels="callsDataset.labels"
          :datasets="callsDataset.datasets"
        />

        <div
          class="card py-4"
          v-if="Object.values(callsDataset).length && !callsDataset.labels.length && !showSearchMessage"
        >
          <div class="card-body text-center">
            {{ $t('no_info_found') }}
          </div>
        </div>

        <div
          class="card py-4"
          v-if="showSearchMessage"
        >
          <div class="card-body text-center">
            {{ $t('search_metrics') }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { convertSecondsToTime } from "@/utils.js";

import TableComponent from "@dialer/manager/components/table-component.vue";
import ProgressChart from "@dialer/manager/components/progress-chart.vue";
import HeaderComponent from "@/components/header-component.vue";

import AppButton from "@/components/app-button.vue";
import InputDateRange from "@components/input-date-range";
import SelectCampaign from "@dialer/manager/components/select-campaign.vue";
// import VueMultiselect from "vue-multiselect";

import moment from "moment";
export default {
 data() {
    return {
      campaigns: {},
      campaign: '',
      showSearchMessage: true,
      startDate: "",
      endDate: "",

      agentNames: [],
      data: {
        idle: [],
        speaking: [],
        acw: [],
        manual: [],
        manual_acw: [],
        interval: [],
      },
      totalTimeLabels: [],
      totalTimeDatasets: [],

      temp: null,

      timeTableContent: [],
      timeTableFooter: {},
      timeTableHeader: [this.$t('uppercases.name'), this.$t('uppercases.calls'),this.$t('uppercases.idle'),this.$t('uppercases.talking'), this.$t('acronym.act'), this.$t('acronym.mact'), this.$t('uppercases.manual'), this.$t('breaks_uppercase'), this.$t('uppercases.logged_time'), this.$t('uppercases.working')],
      qualificationDatasets: [],
      qualificationLabels: [],

      averageTableContent: [],
      averageTableHeader: [this.$t('uppercases.name'), this.$t('uppercases.calls'), this.$t('uppercases.idle'), this.$t('uppercases.talking'), this.$t('acronym.act'), this.$t('acronym.mact'), this.$t('uppercases.manual'), this.$t('breaks_uppercase')],
      averageTableFooter: {},

      callsDataset: [],
    }
  },
  components: {
    TableComponent,
    ProgressChart,
    HeaderComponent,
    AppButton,
    InputDateRange,
    SelectCampaign
    // VueMultiselect
  },
  computed: {
    ...mapGetters("manager/filters", {
      filters: "getFilters"
    }),
    ...mapGetters("manager/agents_report", {
      reportList: "getReportList",
      qualificationList: "getQualificationList",
      errors: "getErrors",
      loading: 'getLoadingFlags',
      connectedCallsData: "getConnectedCallsData"
    }),
  },
  watch: {
    reportList(data) {
      if(data.length) {
        this.handleReportSubmit();
      }
    },
    qualificationList(data) {
      if(data.agents) {
        this.handleQualificationSubmit();
      }
    },
    connectedCallsData(data) {
      this.handleConnectedCallsData(data);
    },
    filters: {
      handler(data) {
        if(data.campaigns)
          this.campaigns = data.campaigns;
      },
      deep: true,
    }
  },
  methods: {
    convertSecondsToTime,
    ...mapActions("manager/agents_report", [
      "searchAgentsReport",
      "searchQualifications",
      "searchConnectedCallsData",
      "downloadTable"
    ]),
    ...mapActions("manager/filters", ["findFilters"]),
    canSearch() {
      return this.startDate && this.endDate;
    },
    secondsToHoursFormat(timeInSeconds) {
      let duration = moment.duration(timeInSeconds, 'seconds');

      let hours = String(Math.floor(duration.asHours())).padStart(2, '0');
      let minutes = String(duration.minutes()).padStart(2, '0');
      let seconds = String(duration.seconds()).padStart(2, '0');

      if (hours == '00') {
        return `${minutes}:${seconds}`;
      }

      return `${hours}:${minutes}:${seconds}`;
    },
    submit() {
      this.searchAgentsReport({
        campaignId: this.campaign,
        startDate: this.startDate,
        endDate: this.endDate,
      });

      this.searchQualifications({
        campaignId: this.campaign,
        startDate:  moment(this.startDate).format("YYYY-MM-DD") ,
        endDate: moment(this.endDate).format("YYYY-MM-DD"),
      });

      this.searchConnectedCallsData({
        campaign_id: this.campaign !== '' ? this.campaign : undefined,
        start_date: this.startDate,
        end_date: this.endDate,
        'trashed[0]': 'campaign'
      });

      this.showSearchMessage = false;
    },
    handleQualificationSubmit() {
      this.clearQualificationData();
      this.prepareQualificationDataset();
      this.populateQualificationDataset();
    },
    handleReportSubmit() {
      let contentTable = [];
      let averageTable = [];
      //let totalTime = {};
      let totalAvarageTime = {}
      this.timeTableFooter = {}
      this.clearTotalTimeData();

      this.reportList.forEach((element) => {
        this.agentNames.push(element.agent.name);
        this.data.idle.push(element.idle);
        this.data.speaking.push(element.speaking);
        this.data.acw.push(element.acw);
        this.data.manual.push(element.manual);
        this.data.manual_acw.push(element.manual_acw);
        this.data.interval.push(element.interval);

        const filteredElements = {
          name: element.agent.name,
          calls: element.calls,
          idle: this.secondsToHoursFormat(element.idle) + ' (' + Math.round((element.idle * 100) / element.total_logged) + '%)',
          speaking: this.secondsToHoursFormat(element.speaking) + ' (' + Math.round((element.speaking * 100) / element.total_logged) + '%)',
          acw: this.secondsToHoursFormat(element.acw) + ' (' + Math.round((element.acw * 100) / element.total_logged) + '%)',
          manual_acw: this.secondsToHoursFormat(element.manual_acw) + ' (' + Math.round((element.manual_acw * 100) / element.total_logged) + '%)',
          manual: this.secondsToHoursFormat(element.manual) + ' (' + Math.round((element.manual * 100) / element.total_logged) + '%)',
          interval: this.secondsToHoursFormat(element.interval) + ' (' + Math.round((element.interval * 100) / element.total_logged) + '%)',
          total_logged: this.secondsToHoursFormat(element.total_logged) + ' (' + Math.round((element.total_logged * 100) / element.total_logged) + '%)',
          workload: this.secondsToHoursFormat(element.workload) + ' (' + Math.round((element.workload * 100) / element.total_logged) + '%)',
        }

        const filteredAverageData = {
          name: element.agent.name,
          calls: element.calls,
          idle_average: this.secondsToHoursFormat(element.idle_average),
          speaking_average: this.secondsToHoursFormat(element.speaking_average),
          acw_average: this.secondsToHoursFormat(element.acw_average),
          manual_acw_average: this.secondsToHoursFormat(element.manual_acw_average) + ' ('+ element.manual_calls_acw +')',
          manual_average: this.secondsToHoursFormat(element.manual_average),
          interval_average: this.secondsToHoursFormat(element.interval_average) + ' ('+ element.interval_quantity +')',
        }

        this.timeTableFooter = {
          name: 'Total',
          calls: (this.timeTableFooter.calls || 0) + element.calls,
          idle: (this.timeTableFooter.idle || 0) + element.idle,
          speaking: (this.timeTableFooter.speaking || 0) + element.speaking,
          acw: (this.timeTableFooter.acw || 0) + element.acw,
          manual_acw: (this.timeTableFooter.manual_acw || 0) + element.manual_acw,
          manual: (this.timeTableFooter.manual || 0) + element.manual,
          interval: (this.timeTableFooter.interval || 0) + element.interval,
          total_logged: (this.timeTableFooter.total_logged || 0) + element.total_logged,
          workload: (this.timeTableFooter.workload || 0) + element.workload,
        };

        totalAvarageTime = {
          manual_calls_acw: (totalAvarageTime.manual_calls_acw || 0) + element.manual_calls_acw,
          manual_calls: (totalAvarageTime.manual_calls || 0) + element.manual_calls,
          interval_quantity: (totalAvarageTime.interval_quantity || 0) + element.interval_quantity,
        }

        contentTable.push(filteredElements);
        averageTable.push(filteredAverageData);
      });

      let tempData = [];

      for (const item in this.data) {
        tempData.push(Object.values(this.data[item]));
      }

      tempData.forEach((element, index) => {
        this.totalTimeDatasets[index].data = element;
      });

      this.timeTableContent = contentTable;
      this.averageTableContent = averageTable;
      this.totalTimeLabels = this.agentNames;

      this.averageTableFooter = {
        name: 'Total',
        calls: this.timeTableFooter.calls,
        idle_average: this.formatTimeAvarage('idle'),
        speaking_average: this.formatTimeAvarage('speaking'),
        acw_average: this.formatTimeAvarage('acw'),
        manual_acw_average: this.formatTimeAvarage('manual_acw') + ' ('+ totalAvarageTime.manual_calls_acw +')',
        manual_average: this.formatTimeAvarage('manual'),
        interval_average: this.formatTimeAvarage('interval') + ' ('+ totalAvarageTime.interval_quantity +')',
      }

      const totalTimeFooter = this.timeTableFooter.total_logged
      Object.keys(this.timeTableFooter).forEach((value) => {
        if (value !== 'name' && value !== 'calls') {
          const seconds = this.timeTableFooter[value]
          const formattedTime = this.secondsToHoursFormat(seconds)
          const percentage = Math.round((seconds * 100) / totalTimeFooter)
          this.timeTableFooter[value] = `${formattedTime} (${percentage}%)`
        }
      })

    },
    formatTimeAvarage(value) {
      const seconds = Math.round(this.timeTableFooter[value] / (this.timeTableFooter.calls ? this.timeTableFooter.calls : 1))
      const formattedTime = this.secondsToHoursFormat(seconds)
      return formattedTime
    },
    populateQualificationDataset() {
      for(const key in this.qualificationList.agents) {
        for(const index in this.qualificationDatasets) {
          let total = this.qualificationList.agents[key].qualifications[index] ? this.qualificationList.agents[key].qualifications[index].total: 0;
          this.qualificationDatasets[index].data.push(total);
        }
      }

      this.qualificationDatasets = Object.values(this.qualificationDatasets);
    },
    prepareQualificationDataset() {
      for (const element in this.qualificationList.agents) {
        this.qualificationLabels.push(this.qualificationList.agents[element].name);

        for (const item in this.qualificationList.agents[element].qualifications) {
          if(!this.qualificationDatasets[item]) {
            this.qualificationDatasets[item] = {
                label: this.qualificationList.agents[element].qualifications[item].name,
                color: this.qualificationList.agents[element].qualifications[item].color === '' ? '#e1e1e1' : this.qualificationList.agents[element].qualifications[item].color,
                backgroundColor: this.qualificationList.agents[element].qualifications[item].color,
              };
            this.qualificationDatasets[item].data = [];
          }
        }
      }
    },
    clearQualificationData() {
      this.qualificationLabels = [];
      this.qualificationDatasets = [];
    },
    clearTotalTimeData() {
      this.data = {
        idle: [],
        speaking: [],
        acw: [],
        manual: [],
        manual_acw: [],
        interval: [],
      };

      this.agentNames = [];

      this.totalTimeLabels = [];

      this.totalTimeDatasets = this.labelsTotalTime();
    },
    labelsTotalTime() {
      return [
        {
          label: this.$t("idle_capitalize"),
          color: "#C4C4C4",
          backgroundColor: "#C4C4C4",
          borderRadius: 0,
          data: []
        },
        {
          label: this.$t("capitalizes.talking"),
          color: "#5cb85c",
          backgroundColor: "#5cb85c",
          borderRadius: 0,
          data: []
        },
        {
          label: this.$t("acronym.act"),
          color: "#3075e3",
          backgroundColor: "#3075e3",
          borderRadius: 0,
          data: []
        },
        {
          label: this.$t("manual_capitalize"),
          color: "#de7912",
          backgroundColor: "#de7912",
          borderRadius: 0,
          data: []
        },
        {
          label: this.$t("acronym.mact"),
          color: "#863fb3",
          backgroundColor: "#863fb3",
          borderRadius: 0,
          data: []
        },
        {
          label: this.$t("break_capitalize"),
          color: "#ffc82c",
          backgroundColor: "#ffc82c",
          borderRadius: 0,
          data: []
        }
      ];
    },
    handleConnectedCallsData(callsData) {
      let data = {};
      data.datasets = [
        {
          label: this.$t('cpc_plus_conversion'),
          data: [],
          color: '#0a7fb9',
          backgroundColor: '#0a7fb9',
          radius: 0
        },
        {
          label: this.$t('crp'),
          data: [],
          color: '#5cb85c',
          backgroundColor: '#5cb85c',
          radius: 0
        },
        {
          label: this.$t('unknown_capitalize'),
          data: [],
          color: '#E60B42',
          backgroundColor: '#E60B42',
          radius: 0
        },
        {
          label: this.$t('others_f'),
          data: [],
          color: '#989898',
          backgroundColor: '#989898',
          radius: 0
        }
      ];

      data.labels = [];

      for (let index in callsData) {
        let item = callsData[index]

        data.labels.push(item.agent.name);
        data.datasets[0].data.push(item.converted);
        data.datasets[1].data.push(item.dmc);
        data.datasets[2].data.push(item.unknown);
        data.datasets[3].data.push(item.answered);
      }

      this.callsDataset = data;
    }
  },
  mounted() {
    this.findFilters();
  }
}

</script>

<style scoped lang="scss">
.header-body {
  border-bottom: none;
}

.rounded-calendar {
  border-top-right-radius: 0.375rem !important;
  border-bottom-right-radius: 0.375rem !important;
}

.table > :not(:first-child) {
  border-top: none;
}
</style>
