import axios from "axios";

export default {
  login({commit}, payload) {
    commit("startLoadingFlag", "login");

    return new Promise((resolve, reject) => {
      axios.post("/agent/login", payload)
      .then(() => {
        commit("auth/trackEvents", "[Agent] Entered Campaign", {root: true})
        commit("system/setMode", payload.mode, {root: true});
        resolve();
      })
      .catch((response) => {
        if (response.response.status == 401) {
          commit("auth/trackEvents", `[General] license limit Reached`, {root: true});
        }
        commit("system/setErrorMessage", response.response.data, {root: true})
        commit("stopLoading");
        commit("stopLoadingFlag", "login");
        reject();
      })
    });
  },

  findLoggedCampaign({commit}) {
    commit("startLoadingFlag", "findLoggedCampaign");

    return new Promise((resolve, reject) => {
      axios.get("/agent/loggedCampaign")
      .then((response) => {
        commit("setCampaign", response.data.data);
        resolve();
      })
      .catch((response) => {
        commit("system/setErrorMessage", response.response.data, {root: true})
        reject();
      })
      .finally(() => {
        commit("stopLoadingFlag", "findLoggedCampaign");
      });
    });
  },

  logout({commit}) {
    commit("startLoadingFlag", "logout");

    return new Promise((resolve, reject) => {
      axios.post("/agent/logout")
      .then(() => {
        commit("setCampaign", null);
        resolve();
      })
      .catch((response) => {
        commit("stopLoadingFlag", "logout");
        commit("system/setErrorMessage", response.response.data, { root: true })
        reject();
      })
    });
  },

  findAgentsOnline({commit}) {
    commit("startLoadingFlag", "findAgentsOnline");
    commit("setListAgentsOnline", []);

    return new Promise((resolve, reject) => {
      axios.get("/agents/online").then((response) => {
        commit("setListAgentsOnline", response.data.data);
        resolve();
      })
      .catch((response) => {
        commit("system/setErrorMessage", response.response.data, {root: true})
        reject();
      })
      .finally(() => {
        commit("stopLoadingFlag", "findAgentsOnline");
      });
    })
  },

  consultAgent({commit}, payload) {
    commit("startLoadingFlag", "consultAgent");

    return new Promise((resolve, reject) => {
      axios.post("/agent/consult", payload)
      .then(() => {
        resolve();
      })
      .catch((response) => {
        commit("stopLoadingFlag", "consultAgent");
        commit("system/setErrorMessage", response.response.data, {root: true})
        reject();
      })
    });
  },

  exitConsult({commit}) {
    commit("startLoadingFlag", "exitConsult");
    return new Promise((resolve, reject) => {
      axios.post("/agent/consult/exit")
      .then(() => {
        resolve();
      })
      .catch((response) => {
        commit("stopLoadingFlag", "exitConsult");
        commit("system/setErrorMessage", response.response.data, {root: true})
        reject();
      })
    });
  },

  cancelConsult({commit}) {
    commit("startLoadingFlag", "cancelConsult");
    return new Promise((resolve, reject) => {
      axios.post("/agent/consult/cancel")
      .then(() => {
        resolve();
      })
      .catch((response) => {
        commit("stopLoadingFlag", "cancelConsult");
        commit("system/setErrorMessage", response.response.data, {root: true});
        reject();
      });
    });
  },

  transferToAgent({commit}, payload) {
    commit("startLoadingFlag", "transferToAgent");

    return new Promise((resolve, reject) => {
      axios.post("/agent/consult/transfer", payload)
      .then(() => {
        resolve();
      })
      .catch((response) => {
        commit("stopLoadingFlag", "transferToAgent");
        commit("system/setErrorMessage", response.response.data, {root: true})
        reject();
      })
    });
  },

  findAgentFeedback({commit}) {
    commit("startLoadingFlag", "findAgentFeedback");
    return new Promise((resolve, reject) => {
      axios.get("/feedbacks/agent/stats").then((response) => {
        if (response.data.data.length) commit("setAgentStats", response.data.data[0]);
        resolve();
      })
      .catch((response) => {
        commit("system/setErrorMessage", response.response.data, {root: true})
        reject();
      })
      .finally(() => {
        commit("stopLoadingFlag", "findAgentFeedback");
      });
    })
  },

  fetchCalls({commit}, payload) {
    commit("startLoadingFlag", "fetchCalls");
    return new Promise((resolve, reject) => {
      axios.get("/calls", {params: payload})
      .then((response) => {
        commit("setCalls", response.data.data);
        resolve();
      })
      .catch((response) => {
        commit("system/setErrorMessage", response.response.data, {root: true})
        reject();
      })
      .finally(() => {
        commit("stopLoadingFlag", "fetchCalls")
      });
    });
  },

  fetchCallsTime({commit}, payload) {
    commit("clearErrors");
    commit("startLoadingFlag", "fetchCallsTime");
    return new Promise((resolve, reject) => {
      axios
      .get(`/campaigns/${payload.campaign}/agents/metrics/total?start_date=${payload.startDate}&end_date=${payload.endDate}`)
      .then(response => {
        commit("setTimeCalls", response.data.data);
        resolve();
      })
      .catch((response) => {
        commit("system/setErrorMessage", response.response.data, {root: true});
        reject();
      })
      .finally(() => {
        commit("stopLoadingFlag", "fetchCallsTime");
      });
    });
  },

  setActiveDropdownLeft({commit}, payload) {
    commit("setActiveDropdownLeft", payload);
  },

  setActiveDropdownRight({commit}, payload) {
    commit("setActiveDropdownRight", payload);
  },
}
