<template>
  <div class="my-4 h-100">
    <div class="mb-4 d-flex justify-content-between align-items-center">
      <h1 v-if="isAgent">
        {{ $t('hey') }}, {{ user.name }}
      </h1>
      <h6 class="text-muted ms-1">
        {{ getTodayDate() }}
      </h6>
      <div
        style="gap: 1rem"
        class="d-flex flex-wrap"
        v-if="!isAgent"
      >
        <span class="d-block">
          {{ $t("responsible") }}
        </span>
        <agent-dropdown
          id="activity-dashboard"
          @select-agents="filterByUsers"
        />
      </div>
    </div>
    <div class="d-flex gap-3">
      <div class="col-6">
        <card-loading
          v-if="loadingFlags.fetchStatisticsActivities"
          height="201.19"
          class="mb-3"
        />
        <status-card
          v-else
          :compact="true"
          :count="statistics.total_activities"
          :data-status="pendingActivities"
          :card-title="$t('agents_today_progress')"
          class="mb-3"
        >
          <template #body>
            <div class="row">
              <div class="d-flex gap-2">
                <h2>{{ statistics.completed_activities }}</h2>
                <span>{{ $t('of') }} {{ statistics.total_activities }} {{ $t('activities_lowercase') }}</span>
              </div>
            </div>
          </template>
        </status-card>
        <div class="card">
          <activity-card-header
            is-modal
            @current-tab="openActivityModal"
          />
        </div>
      </div>
      <div class="col-6">
        <card-loading
          v-if="loadingFlags.fetchStatisticsActivities"
          height="306"
        />
        <pending-activeties
          v-else
          :statistics="statistics"
        />
      </div>
    </div>
    <div class="col-12">
      <dashboard-activity-list-group :owners-ids="ownersIds" />
    </div>
  </div>

  <modal-activity
    :current-tab="currentTab"
    :show-activity-modal="showActivityModal"
    @close="showActivityModal = false"
  />
</template>
<script>
import StatusCard from "@dialer/manager/pages/dashboards/campaign/components/status-card.vue";
import {mapActions, mapGetters, useStore} from "vuex";
import ActivityCardHeader from "@crm/components/activity/form-activity/activity-card-header.vue";
import PendingActiveties from "@crm/components/activity/pending-activities.vue";
import DashboardActivityListGroup from "@crm/components/activity/list/dashboard-activity-list-group.vue";
import ModalActivity from "@crm/components/activity/modals/modal-activity.vue";
import AgentDropdown from "@crm/pages/manager/crm-settings/components/agent-dropdown.vue";
import CardLoading from "@components/card-loading.vue";

export default {
  components: {
    CardLoading,
    AgentDropdown,
    DashboardActivityListGroup,
    PendingActiveties,
    ActivityCardHeader,
    StatusCard,
    ModalActivity
  },

  data() {
    return {
      currentTab: null,
      showActivityModal: false,
      ownersIds: [],
      unsubscribe: () => {
      }
    }
  },

  computed: {
    ...mapGetters('crm/activities', {
      statistics: 'getActivityStatistics',
      loadingFlags:'getLoadingFlags'
    }),
    ...mapGetters("auth", {
      user: "getUser",
      isAgent: "isAgent"
    }),
    ...mapGetters("crm/tour", {
      openModal: "getOpenModal"
    }),
    pendingActivities() {
      return [
        {
          value: this.statistics.completed_activities,
          label: this.$t('concluded'),
          color: '#3057F2'
        },
        {
          value: this.statistics.pending_activities,
          label: this.$t('pending'),
          color: '#D2DDEC'
        },
      ]
    }
  },

  mounted() {
    this.setStatisticsActivitiesFilters({
      funnel_id: this.$route.params.id,
      owner_id: this.ownersIds
    })
    this.fetchStatisticsActivities()
    this.unsubscribe = useStore().subscribeAction((action) => {
      if (action.type === "crm/activities/closeModal") {
        this.showActivityModal = false
      }
    })
    if(this.openModal) {
      this.showActivityModal = true
      this.currentTab = 'task'
    }
  },

  beforeUnmount() {
    this.unsubscribe()
  },

  methods: {
    ...mapActions('crm/activities', [
      'fetchStatisticsActivities',
      'setStatisticsActivitiesFilters'
    ]),

    getTodayDate() {
      const date = new Date().toLocaleDateString('pt-BR', {
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric'
      });

      return date.charAt(0).toUpperCase() + date.slice(1);
    },

    openActivityModal(tab) {
      this.currentTab = tab;
      this.showActivityModal = true;
    },

    filterByUsers(users) {
      this.ownersIds = users;
      this.setStatisticsActivitiesFilters({
        funnel_id: this.$route.params.id,
        owner_id: this.ownersIds
      })
      this.fetchStatisticsActivities()
    }
  }
}
</script>
