import io from 'socket.io-client'
import {AgentStatus, CallMode, CallStatus, Utils} from "@/utils";
import timer from "@/plugins/timer";
import router from '@/router'
import audioPlayer from "@/plugins/audio-player";
import UserSocket from "@/websocket/userSocket";

export default (store) => {

  var socket = null;
  socket = io(process.env.VUE_APP_JK78OP, {
    transports: ['websocket'], query: {
      token:
        store.getters['auth/getUser'] ?
          store.getters['auth/getUser'].api_token :
          ''
    },
    forceNew: true,
    reconnection: true
  });

  const agentSocket = new UserSocket(
    store.getters["auth/getUser"],
    socket,
  );

  socket.on("disconnect", () => {
    console.log("Disconnect");
    store.commit("system/setSocketIOStatus", 'disconnected');
  });

  const setStatus = (status = '') => {
    store.commit("system/setStatus", status);
  }

  socket.on("connect_error", (error) => {
    console.log("[socket.io] connect error");
    const {message} = error;
    if (message.startsWith('Authentication error: cannot authenticate within the application with token')) {
      store.dispatch("auth/logout");
      router.push({
        name: 'login'
      });
    }
  });

  const startTimer = (seconds, countdown = false, target = undefined) => {
    let options = {
      startValues: {
        seconds
      },
      countdown
    };

    if (target !== undefined) {
      store.commit("system/setTimerTargetAchieved", false);
      options.target = {seconds: target}
    }

    store.commit("system/setTimeAsString", Utils.timeFormat(seconds));
    timer.stop();
    timer.start(options)
  }

  const isRingGroupCall = (event) => {
    const { campaign, campaignId } = event;
    if (campaign === null || campaignId === 0 || campaignId === null) {
      return true;
    }
    return false;
  }

  socket.on('connect', () => {
    store.commit("system/setSocketIOStatus", 'connected');
    console.log('[socket.io] connected!')
  });

  socket.on("reconnect", (attempt) => {
    store.commit("system/setSocketIOStatus", 'connected');
    console.log("[socket.io] reconnected, attemp:" + attempt)
  });

  socket.on("agent-login-failed", () => {
    store.commit("agent/campaign/stopLoadingFlag", "fetchCampaigns");
    store.commit("agent/agent/stopLoading");
    store.commit("system/setErrorMessage", "errors_message.join_campaign");
    store.commit("agent/call/setAgentCallStatus", "agent-login-failed");
  });

  socket.on("agent-was-logged-out", () => {
    store.commit("agent/agent/stopLoadingFlag", "logout");
    store.commit("agent/agent/setCampaign", null);
    store.commit("agent/ring_group/setConsultedAgentId", null);
    store.commit("system/setJsSipStatus", 'registered');
    store.commit("system/setMode", "");
    setStatus(AgentStatus.OFFLINE);
    timer.stop();
  });

  socket.on("agent-is-connected", (event) => {
    console.log("[agent-is-connected]", event);

    // Does nothing if the user is offline
    if(Number(event.status) === AgentStatus.OFFLINE) return;

    store.dispatch("agent/agent/findLoggedCampaign").then(() => {
      // IDLE
      if (event.status === AgentStatus.IDLE) {
        startTimer(event.status_time);
        setStatus(event.status);
        store.commit("system/setMode", "dialer");
        return;
      }

      const isAgentSpeaking =
      event.status === AgentStatus.ON_CALL ||
      (event.status === AgentStatus.ACW && event.manual_call_acw_status === null);

      // SPEAKING
      if (isAgentSpeaking) {

        startTimer(event.status_time);
        setStatus(event.status);
        store.commit("system/setMode", event.call.call_mode);

        store.commit("agent/call/setCall", event.call);
        store.commit("agent/call/setMailing", event.mailing);
        store.commit("agent/qualification/setList", event.qualification.qualifications);
        store.commit("agent/qualification/setCallQualificated", false);
        store.commit("system/setIntegrationURL", event.qualification.url);
        store.commit("agent/call/setMailing", event.mailing);
      }

      // If agent is in an receptive call
      if (isAgentSpeaking && event.call.call_mode === CallMode.RECEPTIVE) {
        store.commit("agent/agent/stopLoading");
      }

      // If agent is in an manual call
      if (isAgentSpeaking && event.call.call_mode === CallMode.MANUAL && store.getters['system/isManualTimeLimited']) {
        let remanningTime = store.getters['system/getTotalSeconds'];
        remanningTime = remanningTime > 0 ? remanningTime : 1;
        store.commit("system/setManualTimeout", remanningTime);
      }

      // Sets the user's schedules if it is in a manual call
      if (isAgentSpeaking && event.call.call_mode === CallMode.MANUAL && event.schedule) {
        store.commit("agent/call/setMailing", event.schedule.mailing);
        return;
      }

      // After call waiting
      const afterCallWaiting = event.status === AgentStatus.ACW && event.manual_call_acw_status === null;
      if(afterCallWaiting) {
        setStatus(event.status);
        store.commit("system/setMode", "dialer");

        store.commit("agent/call/stopLoading");
        store.commit("agent/agent/stopLoading");
        store.commit("system/setConsulting", false);

        let campaign = store.getters["agent/agent/getCampaign"];
        store.commit("system/setAcwTimeout", campaign.acw_timeout);

        if(event.acwTimeout > 0) {

          startTimer(event.acwTimeout, true, event.status_time);
        } else {
          startTimer(event.status_time);
        }
      }


      // Manual
      const manual = event.status === AgentStatus.ON_MANUAL_CALL;
      if (manual) {
        store.commit("system/setMode", "manual");
        setStatus(AgentStatus.IDLE);

        // Improve!
        let campaign = store.getters['agent/agent/getCampaign'];

        if (campaign.exit_manual_mode > 0) {
          store.commit("system/setManualTimeLimited", true);
          store.commit("system/setManualTimeout", campaign.exit_manual_mode);
          startTimer(campaign.exit_manual_mode, true, event.status_time);
        } else {
          store.commit("system/setManualTimeLimited", false);
          startTimer(event.status_time);
        }
      }


      // INTERVAL
      if (event.status === AgentStatus.ON_WORK_BREAK) {
        store.commit("agent/work-break/setIntervalActive", event.work_break);
        store.commit("system/setMode", "dialer");

        store.commit("agent/work-break/setExpired", false);
        store.commit("agent/work-break/stopLoading");
        store.commit("agent/work-break/setScheduledWorkBreak", false);
        store.commit("agent/work-break/setIntervalActive", event.work_break);
        startTimer(event.status_time, true);
        setStatus(AgentStatus.ON_WORK_BREAK);
      }

      // ON MANUAL CALL
      const onManualCall = event.status === AgentStatus.ON_MANUAL_CALL_CONNECTED;
      if (onManualCall) {
        store.commit("agent/call/stopLoading");
        store.commit("agent/call/setManualCallACW", event.call);
        store.commit("agent/call/setCall", event.call);
        store.commit("system/setMode", CallMode.MANUAL);
        store.commit("agent/qualification/setList", event.qualification.qualifications);
        store.commit("agent/qualification/setCallQualificated", false);

        startTimer(event.status_time);
        setStatus(AgentStatus.ON_CALL);

        if(event.schedule) {
          store.commit("agent/call/setMailing", event.schedule.mailing);
        }
      }


      // MANUAL CALL ACW
      const manualCallAcw = event.manual_call_acw_status;
      if(manualCallAcw) {
        store.commit("agent/call/stopLoading");
        store.commit("agent/call/setManualCallACW", event.manual_call_acw_data);
        store.commit("agent/call/setCall", event.call);
        store.commit("system/setMode", CallMode.DIALER);
        store.commit("agent/qualification/setList", event.qualification.qualifications);
        store.commit("agent/qualification/setCallQualificated", false);
        if(event.manual_call_acw_status === AgentStatus.ON_MANUAL_CALL_ACW) {
          setStatus(event.manual_call_acw_status);
        }

        if(event.manual_call_acw_status === AgentStatus.MANUAL_CALL_CONNECTED) {
          setStatus(AgentStatus.ON_CALL);
        }

        if(event.schedule) {
          store.commit("agent/call/setMailing", event.schedule.mailing);
        }
      }

    });
  });

  socket.on("agent-is-idle", () => {
    store.dispatch("auth/me");
    store.commit("agent/call/setAgentCallStatus", "agent-is-idle");
    store.commit("agent/call/stopLoading");
    store.commit("agent/call/stopLoadingFlag", "hangup");
    store.commit("agent/agent/stopLoadingFlag", "login");
    store.commit("auth/trackEvents", '[Agent] Agent is idle');
    store.commit("agent/agent/stopLoadingFlag", "exitConsult");


    if (store.getters['system/getMode'] == 'manual') { //end manual call
      let campaign = store.getters['agent/agent/getCampaign']
      if (!campaign) {
        store.dispatch("agent/agent/findLoggedCampaign").then(() => {
          campaign = store.getters['agent/agent/getCampaign'];
        })
      }
      if (store.getters['agent/qualification/getCallQualificated'] || !store.getters["agent/call/getCall"]) {
        if (store.getters['system/isManualTimeLimited']) {
          store.commit("system/setManualTimeLimited", true);
          store.commit("system/setManualTimeout", campaign.exit_manual_mode);
          startTimer(campaign.exit_manual_mode, true, 0);
        }
      } else {
        if (campaign.acw_timeout > 0) {
          startTimer(campaign.acw_timeout, true, 0);
          store.commit("system/setAcwTimeout", campaign.acw_timeout);
        } else {
          startTimer(0);
        }

        setStatus(AgentStatus.ACW);
        return;
      }
    } else {
      if (!store.getters['system/getMode']) {
        // First access after login from dialer
        store.commit("system/setMode", "dialer");
      }
      store.dispatch("agent/agent/findLoggedCampaign");
      startTimer(0);
    }

    store.commit("system/setConsulting", false);
    store.commit("agent/call/setManualCallACW", null);
    store.commit("agent/call/setMailing", null);
    store.commit("agent/call/setCall", null);
    setStatus(AgentStatus.IDLE);
  });

  socket.on("call-was-connected", event => {
    if (isRingGroupCall(event)) {
      store.dispatch(
        "agent/ring_group/updateCallStatusAfterCallPABXWasAnswered",
        event
      );

      return;
    }

    store.commit("agent/call/stopLoading");
    store.commit("agent/call/setCall", event.call);
    store.commit("agent/qualification/setList", event.qualification.qualifications);
    store.commit("agent/qualification/setCallQualificated", false);
    store.commit("system/setIntegrationURL", event.qualification.url);
    store.commit("agent/agent/setCampaign", event.campaign);
    setStatus(AgentStatus.ON_CALL);
    startTimer(0)

    if (event.call.call_mode == CallMode.RECEPTIVE) {
      store.commit("agent/agent/stopLoading");
      store.commit("agent/agent/setAgent", event.agent);
      let activeQueue = event.agent.receptive_queues.find((queue) => queue.id == event.call.queue_id);
      store.commit("agent/queue/setActiveQueue", activeQueue);

    } else if (event.call.call_mode == CallMode.MANUAL) {
      if (store.getters['system/isManualTimeLimited']) {
        let remanningTime = store.getters['system/getTotalSeconds'];
        remanningTime = remanningTime > 0 ? remanningTime : 1;
        store.commit("system/setManualTimeout", remanningTime);
      }
      if (event.schedule) {
        store.commit("agent/call/setMailing", event.schedule.mailing);
        return;
      }
    }

    store.commit("agent/call/setMailing", event.mailing);
  });

  socket.on("call-was-ended", () => {
    store.commit("system/setConsulting", false);
    store.commit("agent/call/setManualCallACW", null);
    store.commit("agent/call/setMailing", null);
    store.commit("agent/call/setCall", null);
  });

  socket.on("agent-entered-work-break", event => {
    store.commit("agent/work-break/setExpired", false);
    store.commit("agent/work-break/stopLoading");
    store.commit("agent/work-break/setScheduledWorkBreak", false);
    store.commit("agent/work-break/setIntervalActive", event.work_break);
    store.commit("agent/work-break/setReturnRequested", false);
    store.commit("system/setTimerTargetAchieved", false);
    setStatus(AgentStatus.ON_WORK_BREAK);
    if (event.work_break.minutes === 0) {
      startTimer(0);
      return
    }
    startTimer(60 * event.work_break.minutes, true);
  });

  socket.on("agent-enter-work-break-failed", () => {
    store.commit("agent/work-break/setIntervalActive", null);
    store.commit("agent/work-break/setScheduledWorkBreak", false);
    store.commit("system/setErrorMessage", "errors_message.start_interval");
    setStatus(AgentStatus.IDLE);
  });

  socket.on("agent-left-work-break", () => {
    store.commit("agent/work-break/setIntervalActive", null);
    store.commit("agent/work-break/setReturnRequested", false);
    store.commit("agent/work-break/stopLoading");
  });

  socket.on("work-break-has-been-scheduled-for-the-agent", () => {
    store.commit("system/setInfoMessage",  "work_break_warning_message", { root: true });
  })

  socket.on("agent-in-acw", event => {
    store.commit("agent/call/stopLoading");
    store.commit("agent/call/stopLoadingFlag", "hangup");
    store.commit("agent/agent/stopLoadingFlag", "transferToAgent");
    store.commit("system/setConsulting", false);
    const activeQueue = store.getters["agent/queue/getActiveQueue"];
    let acwTimeout = 0;

    if (activeQueue && activeQueue.acw_timeout) {
      acwTimeout = store.getters["agent/queue/getActiveQueue"].acw_timeout;
      store.commit("agent/agent/setAgent", {});
      store.commit("agent/queue/setActiveQueue", {});
    } else {
      acwTimeout = event.acwTimeout;
    }
    store.commit("system/setAcwTimeout", acwTimeout);
    setStatus(AgentStatus.ACW);
    timer.stop();
    if (acwTimeout > 0)
      startTimer(acwTimeout, true);
    else
      startTimer(0);
  });

  socket.on("agent-entered-manual", async () => {
    store.commit("system/setMode", "manual");
    store.commit("agent/call/setAgentCallStatus", "agent-entered-manual");

    setStatus(AgentStatus.IDLE);
    store.commit("system/stopLoading");
    store.commit("system/stopLoadingFlag", "enterManual");

    let campaign = store.getters['agent/agent/getCampaign']
    if (!campaign) { // agent is from whatsappManualCall
      await store.dispatch("agent/agent/findLoggedCampaign")
      campaign = store.getters['agent/agent/getCampaign']
    }
    if (campaign.exit_manual_mode > 0) {
      store.commit("system/setManualTimeLimited", true);
      store.commit("system/setManualTimeout", campaign.exit_manual_mode);
      startTimer(campaign.exit_manual_mode, true, 0);
    } else {
      store.commit("system/setManualTimeLimited", false);
      startTimer(0);
    }
  });

  socket.on("agent-left-manual", () => {
    store.commit("system/setMode", "dialer");
    setStatus(AgentStatus.IDLE);
    store.commit("system/stopLoadingFlag", "exitManual");
    startTimer(0);
  });

  socket.on("agent-leave-work-break-failed", () => {
    store.commit("system/setMode", "dialer");
    setStatus(AgentStatus.IDLE);
    store.commit("system/setErrorMessage", "errors_message.end_interval");
    store.commit("system/stopLoading");
  });

  socket.on("agent-failed-to-enter-manual", () => {
    store.commit("system/setErrorMessage", "errors_message.start_manual_mode");
    store.commit("system/stopLoading");
  });

  socket.on("agent-fail-leave-manual-mode", () => {
    store.commit("system/stopLoading");
    setStatus(AgentStatus.IDLE);
    store.commit("system/setErrorMessage", "errors_message.end_manual_mode");
  });

  socket.on("agent-entered-manual-acw", () => {
    store.commit("system/stopLoadingFlag", "enterManualCallACW");
    setStatus(AgentStatus.ON_MANUAL_CALL_ACW);
    startTimer(0);
  });

  socket.on("agent-left-manual-acw", (event) => {
    store.commit("system/stopLoadingFlag", "exitManualCallACW");
    store.commit("system/setMode", "dialer");
    if (event.agentStatus == AgentStatus.ACW)
      setStatus(AgentStatus.ACW);
    startTimer(0);
  });

  socket.on("manual-call-acw-connected", event => {
    store.commit("agent/call/stopLoading");
    store.commit("agent/call/setManualCallACW", event.call);
    startTimer(0);
    setStatus(AgentStatus.ON_CALL);
  });

  socket.on("manual-call-was-answered", (event) => {
    const { call } = event;
    if(isRingGroupCall({campaignId: call.campaign_id})) {
      store.dispatch(
        "agent/ring_group/updateCallStatusAfterCallPABXWasAnswered",
        event
      );

      return;
    }
    // improve setStatus and setMode
    store.commit("agent/call/setAgentCallStatus", "manual-call-was-answered");


  });

  socket.on("manual-call-was-qualified", () => {
    const manualCallACW = store.getters["agent/call/getManualCallACW"];
    if (manualCallACW) {
      store.commit("agent/qualification/setManualCallACWQualified", true);
      return;
    }

    store.commit("agent/qualification/setCallQualificated", true);
  })

  socket.on("manual-call-acw-disconnected", () => {
    store.commit("agent/call/stopLoading");
    store.commit("system/setStatus", "manual-acw");
    if (store.getters['agent/call/getManualCallACW'])
      store.commit("agent/call/setManualCallACW", null);
    setStatus(AgentStatus.ON_MANUAL_CALL_ACW);
  });

  socket.on("consult-connected", event => {
    if (isRingGroupCall(event)) {
      const { agent } = event;

      // If the user is the agent consulting
      if (store.getters['auth/getUser'].id !== agent.id) {
        store.commit("agent/ring_group/stopLoadingFlag", "consultAgent");
        store.commit("agent/ring_group/setCallStatus", CallStatus.CONSULT_CONNECTED);
      }
      return;
    }

    store.commit("agent/agent/stopLoadingFlag", "consultAgent");
    store.commit("agent/queue/stopLoadingFlag", "consultQueue");
    store.commit("system/setAgentInConsult", event.agent);
    store.commit("system/setConsultHold", false);

    if (store.getters['auth/getUser'].id !== event.agent.id) {
      store.commit("system/setConsulting", true);
    } else {
      store.commit("system/setConsulting", false);
      store.dispatch("system/setConsultingAgentName", event.call.agent_name);
    }

    setStatus(AgentStatus.CONSULT_CONNECTED);
    startTimer(0);
  });

  socket.on("consult-hold", () => {
    store.commit("agent/agent/stopLoading");
    store.commit("agent/agent/stopLoadingFlag", "consultAgent");
    store.commit("agent/queue/stopLoadingFlag", "consultQueue");
    store.commit("system/setConsultHold", true);
  });


  socket.on("consult-cancel-hold", () => {
    store.commit("agent/agent/stopLoading");
    store.commit("agent/agent/stopLoadingFlag", "consultAgent");
    store.commit("agent/agent/stopLoadingFlag", "exitConsult");
    store.commit("agent/agent/stopLoadingFlag", "cancelConsult");
    store.commit("agent/queue/stopLoadingFlag", "consultQueue");
    store.commit("system/setConsultHold", false);
    store.commit("agent/queue/setQueueInConsult", null);
    store.commit("system/setAgentInConsult", {});
  });

  socket.on("consult-exit", (event) => {
    if (
      isRingGroupCall({ campaignId: event.call.campaign_id }) &&
      store.getters["agent/ring_group/getConsultedAgentId"]
    ) {
      store.commit("agent/ring_group/stopLoadingFlag", "exitConsult");
      store.commit("system/setStatus", AgentStatus.ON_CALL, { root: true });
      store.commit("agent/ring_group/setCallStatus", CallStatus.CONNECTED);
      store.commit("agent/ring_group/setConsultedAgentId", null);
      return;
    }

    store.commit("agent/agent/stopLoadingFlag", "consultAgent");
    store.commit("agent/agent/stopLoadingFlag", "exitConsult");
    store.commit("agent/agent/stopLoadingFlag", "cancelConsult");
    store.commit("agent/agent/stopLoading");
    store.commit("system/setConsulting", false);
    store.commit("agent/queue/setQueueInConsult", null);
    store.commit("system/setAgentInConsult", {});
    if (store.getters['auth/getUser'].name === event.call.agent_name) {
      setStatus(AgentStatus.ON_CALL);
    } else {
      setStatus(AgentStatus.IDLE);
    }
  });

  socket.on("consult-end", () => {
    store.commit("agent/agent/stopLoadingFlag", "consultAgent");
    store.commit("agent/agent/stopLoadingFlag", "exitConsult");
    store.commit("agent/agent/stopLoadingFlag", "cancelConsult");
    store.commit("agent/agent/stopLoading");
    store.commit("system/setConsulting", false);
    store.commit("agent/queue/setQueueInConsult", null);
    store.commit("system/setAgentInConsult", {});
    setStatus(AgentStatus.IDLE);
  });

  socket.on("call-was-finished", () => {
    audioPlayer.play("busy-signal", 0.2);
  })

  socket.on("call-was-failed", (event) => {
    const { call } = event;
    const callPABX = store.getters["agent/ring_group/getCallPABX"];
    if (!!callPABX && isRingGroupCall({ campaignId: call.campaign_id })) {
      store.dispatch("agent/ring_group/hangupCallPABX");

    }
  })

  socket.on("internal-call-was-answered", () => {
    store.commit("agent/ring_group/setCallStatus", CallStatus.INTERNAL_CALL_ANSWERED);
  });

  socket.on("internal-call-was-refused", () => {
    store.dispatch("agent/ring_group/handlePABXCallEnded");
    store.dispatch("agent/ring_group/setShowToast", true);
  });

  // Whatsapp
  agentSocket.init();

  socket.on("new-whatsapp-ai-chat-evaluation-message", ({chat, message}) => {
    store.commit("whatsapp/chat/addRecentMessage", {message})
    store.commit("whatsapp/chat/updateChatMood", {chatId: chat.id , mood: message.body.mood})
  });

  return socket;
}
